import React from "react";

const CalendarIcon2 = ({ color }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26.0684 5.78027H6.06836C5.51607 5.78027 5.06836 6.22799 5.06836 6.78027V26.7803C5.06836 27.3326 5.51607 27.7803 6.06836 27.7803H26.0684C26.6206 27.7803 27.0684 27.3326 27.0684 26.7803V6.78027C27.0684 6.22799 26.6206 5.78027 26.0684 5.78027Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M22.0684 3.78027V7.78027" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M10.0684 3.78027V7.78027" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5.06836 11.7803H27.0684" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default CalendarIcon2;

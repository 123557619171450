import { FormLayout, Layout } from "@shopify/polaris";
import React from "react";
import TextField from "src/js/components/TextField";
import ResourcePicker from "src/js/components/resource_picker";

type FortnoxOfferInfoProps = {
	offer: Partial<FortnoxOfferType>;
	onChange: (field: FortnoxOfferTypeMutableKeys) => (value: string | boolean) => void;
	isSaving?: boolean;
};

const FortnoxOfferInfo = ({ offer, onChange, isSaving }: FortnoxOfferInfoProps) => {
	const disabled = !!offer?.OrderReference || isSaving || offer?.Cancelled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					<ResourcePicker
						params={{
							project_id: offer.project?.id,
						}}
						placeholder="Sök kund"
						caption="Välj kund"
						resource="customers"
						resourceName={{
							singular: "kund",
							plural: "kunder",
						}}
						item={offer?.customer}
						onChange={onChange("customer")}
						label="Kund"
						required
						disabled={disabled}
						label_handle="name"
						textAlign="left"
					/>
					<ResourcePicker
						params={{
							customer_id: offer.customer?.id,
						}}
						placeholder="Sök projekt"
						caption="Välj projekt"
						resource="projects"
						resourceName={{
							singular: "projekt",
							plural: "projekt",
						}}
						item={offer.project}
						onChange={onChange("project")}
						label="Projekt (valfritt)"
						disabled={disabled}
						label_handle="title"
						textAlign="left"
					/>
					<TextField disabled={disabled} label="Er referens" value={offer.YourReference} onChange={onChange("YourReference")} />
					<TextField disabled={disabled} label="Vår referens" value={offer.OurReference} onChange={onChange("OurReference")} />
					<TextField disabled={disabled} label="Leveransdatum" value={offer.DeliveryDate} onChange={onChange("DeliveryDate")} type="date" />
					<TextField disabled={disabled} label="Offertdatum" value={offer.OfferDate} onChange={onChange("OfferDate")} type="date" />
					<TextField disabled={disabled} label="Giltig till" value={offer.ExpireDate} onChange={onChange("ExpireDate")} type="date" />{" "}
					<TextField disabled={disabled} label="Eget referensnummer" value={offer.YourReferenceNumber} onChange={onChange("YourReferenceNumber")} />
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf>
				<FormLayout>
					<TextField disabled={disabled} label={"Adress"} value={offer.Address1} onChange={onChange("Address1")} />
					<TextField disabled={disabled} label={"Postnummer"} value={offer.ZipCode} onChange={onChange("ZipCode")} />
					<TextField disabled={disabled} label={"Ort"} value={offer.City} onChange={onChange("City")} />
					<TextField disabled={disabled} label={"Land"} value={offer.Country} onChange={onChange("Country")} />
					<TextField disabled={disabled} label={"Anteckning"} value={offer.Remarks} onChange={onChange("Remarks")} multiline={3} />

					{/* <TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryAddress1.label", "Leveransadress")}
						value={offer.DeliveryAddress1}
						onChange={onChange("DeliveryAddress1")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryAddress2.label", "Alternativ leveransadress")}
						value={offer.DeliveryAddress2}
						onChange={onChange("DeliveryAddress2")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryZipCode.label", "Postnummer")}
						value={offer.DeliveryZipCode}
						onChange={onChange("DeliveryZipCode")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryCity.label", "Ort")}
						value={offer.DeliveryCity}
						onChange={onChange("DeliveryCity")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DeliveryCountry.label", "Land")}
						value={offer.DeliveryCountry}
						onChange={onChange("DeliveryCountry")}
					/> */}
				</FormLayout>
			</Layout.Section>
		</Layout>
	);
};
export default FortnoxOfferInfo;

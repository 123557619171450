import React, { Component } from "react";
import { Page, PageActions, ChoiceList, Layout, Card, FormLayout, Select, Tabs, Checkbox, Stack, Tag, Button, Modal, Text } from "@shopify/polaris";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import TimeRangeModal from "src/js/components/TimeRangeModal.js";
import moment from "moment";
import { Link } from "react-router-dom";
import ResourcePicker from "../../components/resource_picker.js";
import SearchField from "../../components/search_field.js";
import TextField from "../../components/TextField";
import WorkOrderCreate from "../WorkOrders/create";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import Map from "../../components/map.js";
import API from "../../API";

class ProjectCreate extends Component {
	constructor(props) {
		super(props);
		const customer = props.location?.state?.customer || null;

		this.state = Object.assign(
			{
				work_types: store.getState().work_types,
				saving: false,
				form: {
					title: "",
					estimated_hours: "0",
					reference: "",
					description: "",
					tags: [],
					status: "active",

					work_order: {},
					customer,
					checkin: store.getState().account.auto_activate_checkin,
					work_types: store.getState().work_types.length == 1 ? [store.getState().work_types[0].id + ""] : [],
					users: [],
					groups: [],
					underentreprenorer: [],
					public: true,

					address: customer?.address || null,
					latitude: customer?.latitude || null,
					longitude: customer?.longitude || null,

					is_marked_as_should_invoice_time_reports: true,
					is_marked_as_should_invoice_supplier_invoices: true,
					is_marked_as_should_invoice_expense_reports: true,
				},
				selectedTab: 0,
			},
			props.location?.state || {}
		);

		this.tabs = [
			{
				id: "oversikt",
				content: "Översikt",
				accessibilityLabel: "oversikt",
				panelID: "oversikt",
			},
		];
	}

	updateForm(field, value) {
		this.state.form[field] = value;

		if (
			field === "is_marked_as_should_invoice_time_reports" ||
			field === "is_marked_as_should_invoice_expense_reports" ||
			field === "is_marked_as_should_invoice_supplier_invoices"
		)
			this.state.form[field] = !value;

		// if(field === "reference") {
		// 	this.state.form.work_order.reference = `${this.state.form.reference}-${this.state.form.work_order.reference}`;
		// }
		if (field === "reference" || field === "title") {
			this.state.form.label = `${this.state.form.title} ${this.state.form.reference ? `(${this.state.form.reference})` : ""}`;
		}
		// if ((field === "description" || field === "title") && this.state.form.work_order) {
		// 	if (!this.state.form.work_order.description) {
		// 		this.state.form.work_order.description = value;
		// 	}
		// 	if (!this.state.form.work_order.title) {
		// 		this.state.form.work_order.title = value;
		// 	}
		// }

		this.setState({ form: this.state.form });
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form, latitude: this.state.form.latitude, longitude: this.state.form.longitude }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/projects.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				if (result.data.work_order) {
					toastr.success("Projekt och arbetsorder skapades");
				} else {
					toastr.success("Projekt skapades");
				}
				this.props.history.replace("/admin/projects/" + result.data.project.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				console.error("error:", error);
				toastr.error(error);
			});
	}

	changeCustomer(customer) {
		this.state.form.customer = customer;
		this.state.form.hourly_rate = customer.hourly_rate;
		this.state.form.hourly_ob_rate = customer.hourly_ob_rate;
		this.state.latitude = customer.latitude;
		this.state.longitude = customer.longitude;
		this.state.form.address = customer.address;
		this.state.form.latitude = customer.latitude;
		this.state.form.longitude = customer.longitude;
		this.state.form.purcharge_price_surcharge = customer.purcharge_price_surcharge;
		this.state.form.hourly_rate = customer.hourly_rate;
		if (this.state.form.work_order) {
			this.state.form.work_order.address = customer.address;
			this.state.form.work_order.latitude = customer.latitude;
			this.state.form.work_order.longitude = customer.longitude;
		}
		this.setState({
			form: this.state.form,
		});
	}

	addUser(user) {
		var found = false;
		for (var i = 0; i < this.state.form.users.length; i++) {
			if (this.state.form.users[i].id == user.id) {
				this.state.form.users.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.users.push(user);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	addUnderentreprenor(underentreprenor) {
		var found = false;
		for (var i = 0; i < this.state.form.underentreprenorer.length; i++) {
			if (this.state.form.underentreprenorer[i].id == underentreprenor.id) {
				this.state.form.underentreprenorer.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.underentreprenorer.push(underentreprenor);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	addGroup(group) {
		var found = false;
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (this.state.form.groups[i].id == group.id) {
				this.state.form.groups.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.groups.push(group);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	maila(fromDate, toDate) {
		this.setState({
			mailListModalOpen: true,
			mailTimeRangeOpen: false,
			mailFromDate: fromDate,
			mailToDate: toDate,
		});
	}

	sendEmail() {
		this.setState({ saving: true });

		API.post(
			"/api/projects/" + this.state.id + "/ledger_checkins/email.json",
			{ email: this.state.email },
			{
				params: {
					checkin_at_min: moment(this.state.mailFromDate).format("YYYY-MM-DD"),
					checkin_at_max: moment(this.state.mailToDate).format("YYYY-MM-DD"),
				},
			}
		)
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("E-mail har skickats till " + this.state.email);
				this.setState({ mailListModalOpen: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		const activeTab = (this.tabs[this.state.selectedTab] || {}).id;
		return (
			<Page
				fullWidth
				title="Nytt projekt"
				backAction={{
					content: "Tillbaka",
					onAction: () => {
						this.props.history.goBack();
					},
				}}
				primaryAction={{ content: "Skapa projekt", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Tabs tabs={this.tabs} selected={this.state.selectedTab} onSelect={(selectedTab) => this.setState({ selectedTab })} />
				<Layout>
					<Layout.Section>
						{activeTab === "oversikt" && (
							<CardsInPageWrapper margin>
								<Layout>
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Projektinformation
										</Text>
										<Card sectioned>
											<FormLayout>
												<TextField
													label="Benämning *"
													value={this.state.form.title}
													onChange={this.updateForm.bind(this, "title")}
													autoComplete="off"
													maxLength={100}
												/>

												<TextField
													label="Projektnummer"
													value={this.state.form.reference}
													placeholder="Genereras automatiskt"
													onChange={this.updateForm.bind(this, "reference")}
													disabled
												/>

												<Select
													label="Status"
													options={[
														{ label: "Vilande", value: "sleep" },
														{ label: "Kommande", value: "pending" },
														{ label: "Aktiv", value: "active" },
														{ label: "Färdig", value: "done" },
														{ label: "Fakturerad", value: "invoiced" },
													]}
													onChange={this.updateForm.bind(this, "status")}
													value={this.state.form.status}
												/>

												<TextField
													type="date"
													label="Förväntat startdatum"
													value={this.state.form.start_at}
													onChange={this.updateForm.bind(this, "start_at")}
													max="9999-12-31"
												/>
												<TextField
													type="date"
													label="Förväntat slutdatum"
													value={this.state.form.end_at}
													onChange={this.updateForm.bind(this, "end_at")}
													max="9999-12-31"
												/>

												<TextField
													type="number"
													label="Förväntat antal timmar"
													value={this.state.form.estimated_hours}
													onChange={this.updateForm.bind(this, "estimated_hours")}
												/>

												<TextField
													multiline={4}
													label="Beskrivning"
													value={this.state.form.description}
													onChange={this.updateForm.bind(this, "description")}
												/>

												<Checkbox
													label={!store.getState().account.ledger_linked ? "Stämpelklocka" : "Stämpelklocka & Personalliggare"}
													checked={this.state.form.checkin}
													onChange={(value) => {
														this.updateForm("checkin", value);
														if (store.getState().account.ledger_linked) {
															this.updateForm("personell_ledger", value);
														}
													}}
												/>
												{this.state.form.checkin && (
													<Checkbox
														label="Incheck.skärm"
														checked={this.state.form.checkin_screen}
														onChange={this.updateForm.bind(this, "checkin_screen")}
													/>
												)}
												{this.state.form.checkin && (
													<Checkbox
														label="Kontrollera GPS för incheckning"
														checked={this.state.form.checkin_require_gps}
														onChange={this.updateForm.bind(this, "checkin_require_gps")}
													/>
												)}
												{this.state.form.checkin && (
													<Checkbox
														label="Kontrollera GPS för utcheckning"
														checked={this.state.form.checkout_require_gps}
														onChange={this.updateForm.bind(this, "checkout_require_gps")}
													/>
												)}

												{store.getState().account.has_personell_ledger && (
													<React.Fragment>
														{!store.getState().account.ledger_linked ? (
															<Checkbox
																label="Projektet har personalliggare"
																checked={this.state.form.personell_ledger}
																onChange={this.updateForm.bind(this, "personell_ledger")}
															/>
														) : this.state.form.checkin ? null : (
															<div>Incheckning är avaktiverad</div>
														)}
														{this.state.form.personell_ledger && (
															<div>
																<TextField
																	label="Skatteverkets identifikationsnr"
																	value={this.state.form.personell_ledger_id}
																	onChange={this.updateForm.bind(this, "personell_ledger_id")}
																	maxLength={13}
																/>
																<Button plain onClick={() => this.setState({ mailTimeRangeOpen: true })}>
																	Maila rapport
																</Button>
															</div>
														)}
													</React.Fragment>
												)}

												{this.state.form.created_by ? <small>Projektet skapades av: {this.state.form.created_by.name}</small> : null}
											</FormLayout>
										</Card>
									</Layout.Section>
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Kund
										</Text>
										<Card sectioned>
											<FormLayout>
												<ResourcePicker
													placeholder="Sök kund"
													caption="Välj kund"
													resourceName={{
														singular: "kund",
														plural: "kunder",
													}}
													resource="customers"
													item={this.state.form.customer}
													onChange={this.changeCustomer.bind(this)}
													label_handle="name"
												/>

												<SearchField
													label="Adress"
													resource="google_address"
													resourceName={{
														singular: " adress",
														plural: " adresser",
													}}
													onSelect={this.selectAddress.bind(this)}
													onChange={this.updateForm.bind(this, "address")}
													value={this.state.form.address}
												/>

												{this.state.form && this.state.form.address && (
													<div>
														{this.state.form && (this.state.form.latitude || this.state.form.longitude) && (
															<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} />
														)}
													</div>
												)}
											</FormLayout>
										</Card>

										<Card sectioned title="Underentreprenörer">
											<FormLayout>
												<SearchField
													resource="underentreprenorer"
													resourceName={{
														singular: "underentreprenör",
														plural: "underentreprenörer",
													}}
													label_handle="name"
													onSelect={this.addUnderentreprenor.bind(this)}
												/>
											</FormLayout>
											{this.state.form.underentreprenorer.length > 0 ? (
												<Card.Section>
													<Stack>
														{this.state.form.underentreprenorer.map((item, index) => (
															<Tag key={item.id || index} onRemove={this.addUnderentreprenor.bind(this, item)}>
																{item.name}
															</Tag>
														))}
													</Stack>
												</Card.Section>
											) : null}
										</Card>

										{this.state.work_types.length > 1 && (
											<Card title="Arbetstyper *" subdued sectioned>
												<ChoiceList
													title=""
													titleHidden
													allowMultiple
													choices={this.state.work_types.map((typ) => ({ value: typ.id + "", label: typ.title + " (" + typ.code + ")" }))}
													selected={this.state.form.work_types}
													onChange={this.updateForm.bind(this, "work_types")}
												/>
											</Card>
										)}

										{!this.state.work_types.length && (
											<Card title="Arbetstyper *" subdued sectioned>
												<p>
													Arbetstyper saknas. <Link to="/admin/settings/work-type">Skapa en arbetstyp</Link>
												</p>
											</Card>
										)}
										{this.state.form.status != "quote" ? (
											<Card sectioned title="Arbetsordern">
												<FormLayout>
													<Checkbox
														checked={this.state.form.public}
														onChange={this.updateForm.bind(this, "public")}
														label="Synligt för alla egna användare"
													/>

													{!this.state.form.public ? (
														<Card.Section title="Användare">
															<SearchField
																resource="users"
																resourceName={{
																	singular: "användare",
																	plural: "användare",
																}}
																label_handle="name"
																onSelect={this.addUser.bind(this)}
															/>
														</Card.Section>
													) : null}
													{!this.state.form.public && this.state.form.users?.length > 0 ? (
														<Card.Section>
															<Stack>
																{this.state.form.users.map((item, index) => (
																	<Tag key={item.id || index} onRemove={this.addUser.bind(this, item)}>
																		{item.name}
																	</Tag>
																))}
															</Stack>
														</Card.Section>
													) : null}
													{!this.state.form.public ? (
														<Card.Section title="Grupper">
															<SearchField
																resource="groups"
																resourceName={{
																	singular: "grupp",
																	plural: "grupper",
																}}
																label_handle="name"
																onSelect={this.addGroup.bind(this)}
															/>
														</Card.Section>
													) : null}
													{!this.state.form.public && this.state.form.groups.length > 0 ? (
														<Card.Section>
															<Stack>
																{this.state.form.groups.map((item, index) => (
																	<Tag key={item.id || index} onRemove={this.addGroup.bind(this, item)}>
																		{item.name}
																	</Tag>
																))}
															</Stack>
														</Card.Section>
													) : null}
												</FormLayout>
											</Card>
										) : null}
									</Layout.Section>
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Faktureringsinställningar
										</Text>
										<Card sectioned>
											<FormLayout>
												<TextField
													type="number"
													placeholder={
														!this.state.form.is_marked_as_should_invoice_time_reports ? "Gäller endast om tidrapporter ska vidarefaktureras" : ""
													}
													label="Timpris"
													value={!this.state.form.is_marked_as_should_invoice_time_reports ? "0" : this.state.form.hourly_rate ?? "0"}
													onChange={this.updateForm.bind(this, "hourly_rate")}
													disabled={!this.state.form.is_marked_as_should_invoice_time_reports}
													suffix="SEK"
												/>
												<TextField
													type="number"
													placeholder={
														!this.state.form.is_marked_as_should_invoice_expense_reports &&
														!this.state.form.is_marked_as_should_invoice_supplier_invoices
															? "Gäller vid vidarefakturering"
															: ""
													}
													label={
														"Påslag " +
														(this.state.form.is_marked_as_should_invoice_supplier_invoices ? "inköp " : "") +
														(this.state.form.is_marked_as_should_invoice_supplier_invoices &&
														this.state.form.is_marked_as_should_invoice_expense_reports
															? "och "
															: "") +
														(this.state.form.is_marked_as_should_invoice_expense_reports ? "utgifter " : "") +
														"(%)"
													}
													value={
														!this.state.form.is_marked_as_should_invoice_expense_reports &&
														!this.state.form.is_marked_as_should_invoice_supplier_invoices
															? ""
															: this.state.form.purcharge_price_surcharge ?? "0"
													}
													onChange={this.updateForm.bind(this, "purcharge_price_surcharge")}
													disabled={
														!this.state.form.is_marked_as_should_invoice_expense_reports &&
														!this.state.form.is_marked_as_should_invoice_supplier_invoices
													}
													suffix="%"
													max="100"
												/>
												<Checkbox
													checked={!this.state.form.is_marked_as_should_invoice_time_reports}
													onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_time_reports")}
													label="Tidrapporter ska ej faktureras"
												/>
												<Checkbox
													checked={!this.state.form.is_marked_as_should_invoice_expense_reports}
													onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_expense_reports")}
													label="Utgifter ska ej faktureras"
												/>
												<Checkbox
													checked={!this.state.form.is_marked_as_should_invoice_supplier_invoices}
													onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_supplier_invoices")}
													label="Leverantörsfakturor ska ej vidarefaktureras"
												/>
											</FormLayout>
										</Card>
									</Layout.Section>
								</Layout>
							</CardsInPageWrapper>
						)}
						{activeTab === "work_orders" && (
							<WorkOrderCreate project={this.state.form} onChange={this.updateForm.bind(this, "work_order")} form={this.state.form.work_order} />
						)}
					</Layout.Section>
				</Layout>
				<PageActions
					primaryAction={{
						content: "Skapa projekt",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>

				<TimeRangeModal
					open={this.state.mailTimeRangeOpen}
					onClose={() => this.setState({ mailTimeRangeOpen: false })}
					from={new Date()}
					to={new Date()}
					onChange={this.maila.bind(this)}
				/>

				<Modal
					title="Maila lista"
					open={this.state.mailListModalOpen}
					onClose={() => {
						this.setState({ mailListModalOpen: false });
					}}
					primaryAction={{
						content: "Skicka",
						onAction: () => {
							this.sendEmail();
						},
						loading: this.state.saving,
						disabled: !this.state.email,
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ mailListModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<TextField
							label="E-post"
							value={this.state.email}
							onChange={(value) => {
								this.setState({ email: value });
							}}
						/>
					</Modal.Section>
				</Modal>
			</Page>
		);
	}
}

export default ProjectCreate;

import { Component } from "react";
import { Page, Layout, Banner, Card, FormLayout, TextField, Stack, Modal } from "@shopify/polaris";
import Moment from "react-moment";

import ResourcePicker from "src/js/components/resource_picker.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ExpenseReportEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			showRemoveModal: false,
			saving: false,
			isRemoving: false,
			loading: true,
			parent_state: null,
		};
		if (props.location.state) {
			this.state.parent_state = props.location.state;
		}
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/expense_reports/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.expense_report });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/expense_reports/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				this.setState({ loading: false, saving: false, form: result.data.expense_report });
				toastr.success("Rapport ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeExpenseReport() {
		this.setState({ isRemoving: true });
		API.delete("/api/expense_reports/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ isRemoving: false });
				toastr.success("Rapport borttagen");
				this.props.history.replace("/admin/expense_reports");
			})
			.catch((error) => {
				this.setState({ isRemoving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	changeProject(project) {
		this.state.form.project = project;
		this.setState({ form: this.state.form });
	}

	changeExpense(expense) {
		this.state.form.expense = expense;
		this.state.form.cost = expense.purchase_price;
		this.setState({ form: this.state.form });
	}

	getChangeHandler(key) {
		return (value) => {
			this.state.form[key] = value;

			if ((key === "quantity" || key === "unit_price") && this.state.form.quantity && this.state.form.unit_price) {
				this.state.form.amount = this.state.form.quantity * this.state.form.unit_price;
				this.state.form.amount_to_invoice = this.state.form.amount * (this.state.form.surcharge / 100 + 1);
			} else if (key === "amount") {
				this.state.form.amount_to_invoice = (parseFloat(this.state.form.amount) * (this.state.form.surcharge / 100 + 1)).toFixed(0);
			} else if (key === "surcharge") {
				this.state.form.amount_to_invoice = this.state.form.surcharge
					? ((parseFloat(this.state.form.surcharge) / 100 + 1) * this.state.form.amount).toFixed(0)
					: 0;
			} else if (key === "amount_to_invoice") {
				this.state.form.surcharge = ((parseFloat(this.state.form.amount_to_invoice) / this.state.form.amount - 1) * 100).toFixed(0);
			}

			this.setState({ ...this.state.form });
		};
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		var disabled = false;

		return (
			<Page
				title={this.state.form?.expense?.title || ""}
				primaryAction={{ content: "Spara", disabled, loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={[
					{
						content: "Ta bort",
						destructive: true,
						disabled,
						loading: this.state.isRemoving,
						onAction: this.removeModal.bind(this),
					},
				]}
				backAction={
					this.props.location && this.props.location.state && this.props.location.state.workOrder
						? {
								content: `Arbetsorder (${this.props.location.state.workOrder.label})`,
								url: `/admin/work_orders/${this.props.location.state.workOrder.id}`,
						  }
						: this.state.parent_state && this.state.parent_state.project
						? { content: this.state.parent_state.project.title, url: "/admin/projects/" + this.state.parent_state.project.id }
						: { content: "Registrerade utgifter", url: "/admin/expense_reports" }
				}
			>
				{disabled ? (
					<div style={{ marginBottom: 20 }}>
						<Banner title="Denna rapportering går inte längre att ändra, be din arbetsledare att göra det åt dig" status="warning" />
					</div>
				) : null}
				<Layout>
					<Layout.AnnotatedSection title="Allmänt om registrering" description="Grundläggande information om registreringen">
						<Card sectioned>
							{this.state.form.project && <div>Projekt {this.state.form.project.title}</div>}
							<div>Användare: {this.state.form.user.name}</div>
							<div>
								Skapad: <Moment format="YYYY-MM-DD HH:mm">{this.state.form.created_at}</Moment>
							</div>
							{this.state.form.created_at != this.state.form.updated_at ? (
								<div>
									Ändrad: <Moment format="YYYY-MM-DD HH:mm">{this.state.form.updated_at}</Moment>
								</div>
							) : null}
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection description="Välj vilket projekt denna utigft ska tillhöra">
						<Card sectioned>
							<FormLayout>
								<ResourcePicker
									placeholder="Sök projekt"
									caption="Välj projekt"
									label="Projekt"
									resourceName={{
										singular: "projekt",
										plural: "projekt",
									}}
									resource="projects"
									item={this.state.form.project}
									onChange={this.changeProject.bind(this)}
									label_handle="title"
								/>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Registrerad utgift" description="Detta är tid rapporterad av användaren">
						<Card sectioned title={this.state.form.expense?.title || "Välj artikel"}>
							<FormLayout>
								<ResourcePicker
									placeholder="Sök artiklar"
									caption="Välj artiklar"
									label="Artikel"
									resourceName={{
										singular: "artiklar",
										plural: "artikel",
									}}
									resource="expenses"
									item={this.state.form.expense}
									onChange={this.changeExpense.bind(this)}
									label_handle="title"
								/>
								<Stack distribution="fillEvenly">
									<TextField
										label="Antal"
										disabled={disabled}
										type="number"
										value={this.state.form.quantity}
										onChange={this.getChangeHandler("quantity")}
									/>
									<TextField
										label={"Pris per " + (this.state.form.expense?.unit || "")}
										disabled={disabled}
										type="currency"
										suffix="kr"
										value={this.state.form.unit_price}
										onChange={this.getChangeHandler("unit_price")}
									/>
								</Stack>
								<Stack distribution="fillEvenly">
									<TextField label="Total kostnad" value={this.state.form.amount} onChange={this.getChangeHandler("amount")} suffix="kr" />

									<TextField label="Påslag %" value={this.state.form.surcharge} suffix="%" onChange={this.getChangeHandler("surcharge")} />
								</Stack>
								<TextField
									label="Att vidarefakturera"
									type="currency"
									suffix="kr"
									value={this.state.form.amount_to_invoice}
									onChange={this.getChangeHandler("amount_to_invoice")}
								/>
								<TextField multiLine label="Kommentar" value={this.state.form.comment} onChange={this.getChangeHandler("comment")} />
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort registrerad utgift?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.isRemoving,
						onAction: this.removeExpenseReport.bind(this),
					}}
				>
					<Modal.Section>Är du säker?</Modal.Section>
				</Modal>
			</Page>
		);
	}
}

export default ExpenseReportEdit;

import React from "react";
import { Button, Card, DropZone, HorizontalStack, Layout, Text, Tooltip, Truncate } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { store } from "../store";
import ImageThumbnail from "./ImageThumbnail";
import { ImageThumbnailsFlexWrapper } from "./StyledComponents";
import CardsInPageWrapper from "../styledComponents/CardsInPageWrapper";
import ImageCarousel from "./ImageCarousel";

export default class Uploads extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openFileDialog: false,
			files: props.files || [],
		};
	}

	openImageInCarousel(index = 0) {
		this.setState({ carouselIsOpen: true, carouselIndex: index });
	}

	closeCarousel(index) {
		this.setState({ carouselIsOpen: false, carouselIndex: null });
	}

	addPicture(f, event) {
		var data = event.target.result;
		var file = {
			id: null,
			name: f.name,
			title: f.name,
			size: f.size,
			type: f.type,
			url: data,
		};
		if (this.props.onClose) this.props.onClose();
		this.props.onUpload(file);
	}

	componentWillReceiveProps(props) {
		this.setState({ files: props.files, openFileDialog: props.open });
	}

	// openEdit(upload) {
	// 	this.setState({ open: true, upload });
	// }

	render() {
		const { files, openFileDialog } = this.state;

		const { images, uploads } = files.reduce(
			(acc, file, index) => {
				const extension = file.url.split(".").pop();
				if (["png", "jpg", "jpeg"].includes(extension) || ["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
					acc.images.push({ ...file, index });
				} else {
					acc.uploads.push({ ...file, index });
				}
				return acc;
			},
			{ images: [], uploads: [] }
		);

		return (
			<CardsInPageWrapper>
				{this.props.label}
				<div
					style={{
						position: "relative",
						width: this.props.width,
						height: this.props.height,
						display: this.props.hidden ? "none" : "initial",
					}}
				>
					<div style={{ marginBlock: "1rem" }}>
						<DropZone
							disabled={store.getState().user.read_only || this.props.disabled}
							outline
							openFileDialog={openFileDialog}
							accept={this.props.accept}
							size={this.props.size || "small"}
							type={this.props.type || "spreadsheet"}
							onDrop={(files, acceptedFiles, rejectedFiles) => {
								if (store.getState().user.read_only) return;

								for (var i = 0; i < acceptedFiles.length; i++) {
									var reader = new FileReader();
									reader.onload = this.addPicture.bind(this, acceptedFiles[i]);
									reader.readAsDataURL(acceptedFiles[i]);
								}
								this.setState({
									// eslint-disable-next-line react/no-unused-state
									rejectedFiles,
									// eslint-disable-next-line react/no-unused-state
									hasError: rejectedFiles.length > 0,
								});
							}}
							onFileDialogClose={() => {
								if (this.props.onClose) this.props.onClose();
								this.setState({ openFileDialog: false });
							}}
						>
							{this.props.width && files.length ? <img style={{ width: this.props.width }} src={files[0].url} alt="" /> : null}
							<DropZone.FileUpload actionTitle="Välj fil" actionHint="Eller dra och släpp en fil" />
						</DropZone>
					</div>
					<Layout>
						<Layout.Section>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Bilder
							</Text>
							<Card sectioned>
								<ImageThumbnailsFlexWrapper>
									{images.map((img, index) => {
										return (
											<ImageThumbnail
												key={img.id || img.index}
												upload={img}
												onDelete={!this.props.disabled ? () => this.props.onRemove(img.index) : undefined}
												onClick={this.openImageInCarousel.bind(this, index)}
											/>
										);
									})}
								</ImageThumbnailsFlexWrapper>
							</Card>
						</Layout.Section>
						<Layout.Section secondary>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Dokument
							</Text>
							<Card sectioned>
								<ImageThumbnailsFlexWrapper style={{ flexDirection: "column", gap: "0.25rem" }}>
									{uploads.map((upload) => {
										return (
											<ListItemWrapper key={upload.url || upload.index}>
												<a href={upload.url} target="black_">
													<Truncate>{upload.title || upload.comment}</Truncate>
												</a>
												<div className="buttons">
													<HorizontalStack gap="2">
														{/* {this.props.onChange && (
																<Tooltip content="Redigera">
																	<Button icon={EditMajor} onClick={this.openEdit.bind(this, upload)} />
																</Tooltip>
															)} */}
														{!this.props.disabled && this.props.onRemove && (
															<Tooltip content="Ta bort">
																<Button destructive icon={DeleteMajor} onClick={() => this.props.onRemove(upload.index)} />
															</Tooltip>
														)}
													</HorizontalStack>
												</div>
											</ListItemWrapper>
										);
									})}
								</ImageThumbnailsFlexWrapper>
							</Card>
						</Layout.Section>
					</Layout>
				</div>
				<ImageCarousel
					title="Bilder"
					images={images}
					open={this.state.carouselIsOpen}
					index={this.state.carouselIndex}
					onClose={this.closeCarousel.bind(this)}
				/>
			</CardsInPageWrapper>
		);
	}
}

Uploads.defaultProps = {
	hidden: true,
};

const ListItemWrapper = styled.div`
	transition: background-color 0.25s ease-in-out;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0.25rem 0.5rem;

	.buttons {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.25s ease-in-out;
	}

	&:hover {
		.buttons {
			opacity: 1;
			pointer-events: all;
		}

		background-color: var(--primary);
	}

	.Polaris-Icon {
		width: 1em;
		height: 1em;
	}
`;

import React, { useState } from "react";
import { Button, Popover } from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import styled from "styled-components";

const DropDown = ({ activator: propsActivator = null, children, activatorStyle = {} }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const activator = propsActivator ? (
		React.cloneElement(propsActivator, { onClick: handleOpen })
	) : (
		<Button plain icon={MobileVerticalDotsMajor} onClick={handleOpen} />
	);

	return (
		<Popover active={open} activator={<ActivatorWrapper style={activatorStyle}>{activator}</ActivatorWrapper>} onClose={handleClose} sectioned>
			{children}
		</Popover>
	);
};
export default DropDown;

const ActivatorWrapper = styled.div.attrs({ className: "dropdown activator-wrapper", tabindex: -1 })`
	transition: opacity 250ms;

	&:hover {
		opacity: 1;
	}
`;

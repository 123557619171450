import { useState } from "react";
import API from "src/js/API";
import Button from "src/js/components/Button";
import Modal from "src/js/components/modal";
import { toastr } from "src/js/components/toastr";

/* eslint-disable @typescript-eslint/no-unused-vars */
const AcceptButton = ({ upload, offerUserId, onSuccess, uniqueId }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOnClickPre = async () => {
		setIsModalOpen(true);
	};
	const handleOnClick = async () => {
		try {
			setIsLoading(true);
			await API.put(`/api/public/offer_user/${offerUserId}/accept.json`, { unique_id: uniqueId });

			toastr.success("Offerten accepterad");
			onSuccess();
			setIsModalOpen(false);
		} catch (error) {
			toastr.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Button primary onClick={handleOnClickPre}>
				Acceptera
			</Button>

			<Modal
				sectioned
				open={isModalOpen}
				onClose={handleClose}
				title="Acceptera offert"
				primaryAction={{
					content: "Acceptera",
					onAction: handleOnClick,
					loading: isLoading,
				}}
				secondaryActions={[
					{
						content: "Avbryt",
						onAction: handleClose,
					},
				]}
			>
				<p>Är du säker på att du vill acceptera offerten?</p>
			</Modal>
		</>
	);
};
export default AcceptButton;

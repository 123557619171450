/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import {
	Page,
	Tooltip,
	PageActions,
	Icon,
	RadioButton,
	Layout,
	Card,
	FormLayout,
	TextField,
	Select,
	Stack,
	Checkbox,
	Tabs,
	Text,
} from "@shopify/polaris";
import { QuestionMarkMajor } from "@shopify/polaris-icons";
import StyledButton from "src/js/styledComponents/StyledButton";
import MapIcon from "src/js/icons/MapIcon.js";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";
import SearchField from "../../components/search_field.js";
import Map from "../../components/map.js";
import ContactCreate from "../Contacts/create";
import { toastr } from "../../components/toastr";
import API from "../../API";

class CustomerCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			selectedTabId: "allmant",
			show_map: false,
			form: {
				name: "",
				customer_id: "",
				type: store.getState().account.customer_default_type,
				information: "",
				org_number: "",
				vat_number: "",
				payment_terms: store.getState().account.invoice_default_terms + "",
				invoice_method: store.getState().account.invoice_default_method,
				invoice_email: "",
				reminder_email: "",
				disabled: false,
				phone: "",
				email: "",
				show_vat: false,
				reverse_tax_liability: false,
				hourly_rate: "",
				purcharge_price_surcharge: store.getState().account.invoice_default_paslag + "",
				hourly_ob_rate: "",
				address: null,
				latitude: null,
				longitude: null,
				invoice_address: null,
				invoice_latitude: null,
				invoice_longitude: null,
				delivery_address: null,
				delivery_latitude: null,
				delivery_longitude: null,
				contacts: [],
			},
		};
		this.state.initialForm = Object.assign({}, this.state.form);
		this.ContactCreateRef = React.createRef();
		this.BigDataRef = React.createRef();
	}

	componentDidMount() {
		this.fetchDefaultArticle();
	}

	fetchDefaultArticle() {
		API.get("/api/expenses.json?q=ARB&sort=ID_ASC&limit=1")
			.then((result) => {
				if (result.data.expenses && result.data.expenses[0]) {
					// eslint-disable-next-line operator-assignment
					if (result.data.expenses?.[0]?.price) this.state.form.hourly_rate = result.data.expenses[0].price;
					this.setState({ form: this.state.form });
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		if (this.state.form.contacts) {
			const contacts = this.state.form.contacts.map((c) => {
				delete c.parent;
				return c;
			});
			this.state.form.contacts = contacts;
		}

		this.setState({ saving: true });
		store.dispatch({ type: "IS_LOADING", loading: true });
		API.post("/api/customers.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "IS_LOADING", loading: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Kund skapades");
				this.props.history.replace("/admin/customers/" + result.data.customer.id);
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	addAddressToEmptyAddresses(address) {
		if (address) {
			if (!this.state.form.address) {
				this.selectAddress(address);
			}
			if (!this.state.form.invoice_address) {
				this.selectInvoiceAddress(address);
			}
			if (!this.state.form.delivery_address) {
				this.selectDeliveryAddress(address);
			}
		}
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState(
			// eslint-disable-next-line react/no-unused-state
			{ form: this.state.form, latitude: this.state.form.latitude, longitude: this.state.form.longitude },
			this.addAddressToEmptyAddresses.bind(this, address)
		);
	}

	selectInvoiceAddress(address) {
		this.state.form.invoice_address = address.formatted_address;
		this.state.form.invoice_latitude = address.geometry.location.lat;
		this.state.form.invoice_longitude = address.geometry.location.lng;
		this.setState(
			{
				form: this.state.form,
				// eslint-disable-next-line react/no-unused-state
				invoice_latitude: this.state.form.invoice_latitude,
				invoice_longitude: this.state.form.invoice_longitude,
			},
			this.addAddressToEmptyAddresses.bind(this, address)
		);
	}

	selectDeliveryAddress(address) {
		this.state.form.delivery_address = address.formatted_address;
		this.state.form.delivery_latitude = address.geometry.location.lat;
		this.state.form.delivery_longitude = address.geometry.location.lng;
		this.setState(
			{
				form: this.state.form,
				delivery_latitude: this.state.form.delivery_latitude,
				delivery_longitude: this.state.form.delivery_longitude,
			},
			this.addAddressToEmptyAddresses.bind(this, address)
		);
	}

	onItemsFetched(items) {
		this.setState({ visibleItems: items });
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({ params });
	}

	renderCell(item, column) {
		if (column.handle == "NAME") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name || `${item.first_name || ""} ${item.last_name || ""}`}</h3>
					</div>
				</div>
			);
		}

		if (column.handle == "PHONE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.phone}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "EMAIL") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.email}</h3>
					</div>
				</div>
			);
		}

		return column.handle;
	}

	bulkRemove() {
		const contacts = this.BigDataRef.current.getItems();
		const filtered = contacts.filter((c) => !this.state.selectedItems.includes(c.id));
		this.BigDataRef.current.setItems(filtered);
		this.BigDataRef.current.clearSelection();
		this.setState({ selectedItems: [] });
		this.setState({ contacts: filtered });
	}

	render() {
		const tabs = [
			{
				id: "allmant",
				content: "Grunduppgifter",
				accessibilityLabel: "Grunduppgifter",
				panelID: "grunduppgifter",
			},
			{
				id: "contacts",
				content: "Kontakter",
				panelID: "contacts",
			},
		];

		const filters = [
			{
				key: "active",
				label: "Aktiv",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				handle: "NAME",
				label: "Namn",
				sortable: true,
				type: "text",
			},
			{
				handle: "PHONE",
				label: "Telefon",
				sortable: true,
				type: "text",
			},
			{
				handle: "EMAIL",
				label: "Email",
				sortable: true,
				type: "text",
			},
		];

		var promotedBulkActions = [
			{
				content: "Ta bort",
				onAction: this.bulkRemove.bind(this),
				loading: this.state.loadingRemoving,
				disabled: this.state.loadingRemoving,
			},
		];

		return (
			<Page
				fullWidth
				title="Ny kund"
				backAction={{ content: "Kunder", url: "/admin/customers" }}
				primaryAction={{ content: "Skapa", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.Section>
						<Tabs
							tabs={tabs}
							selected={tabs.findIndex((tab) => this.state.selectedTabId == tab?.id)}
							onSelect={(v) => {
								const tab = tabs[v];
								this.setState({ selectedTabId: tab?.id });
							}}
						/>
						{this.state.selectedTabId == "allmant" && (
							<CardsInPageWrapper>
								<Layout>
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Företagsinformation
										</Text>
										<Card sectioned>
											<FormLayout>
												<Stack>
													<RadioButton
														label="Företag"
														checked={this.state.form.type === "company"}
														id="company"
														name="type"
														onChange={(v) => {
															(this.state.form.type = "company"),
																(this.state.form.show_vat = false),
																(this.state.form.payment_terms = "30"),
																this.setState({ form: this.state.form });
														}}
													/>
													<RadioButton
														label="Privatperson"
														id="optional"
														name="type"
														checked={this.state.form.type === "private_person"}
														onChange={(v) => {
															(this.state.form.type = "private_person"),
																(this.state.form.show_vat = true),
																(this.state.form.payment_terms = "10"),
																this.setState({ form: this.state.form });
														}}
													/>
												</Stack>

												<Stack distribution="fillEvenly">
													<TextField label="Namn" value={this.state.form.name} onChange={this.updateForm.bind(this, "name")} />
													<TextField label="Kundnummer" value={this.state.form.customer_id} placeholder="Genereras automatiskt" disabled />
												</Stack>
												{this.state.form.type == "company" ? (
													<Stack distribution="fillEvenly">
														<TextField label="Org.nr" value={this.state.form.org_number} onChange={this.updateForm.bind(this, "org_number")} />
														<TextField
															label="Momsregistreringsnummer"
															value={this.state.form.vat_number}
															onChange={this.updateForm.bind(this, "vat_number")}
														/>
													</Stack>
												) : (
													<Stack distribution="fillEvenly">
														<TextField label="Personnummer" value={this.state.form.org_number} onChange={this.updateForm.bind(this, "org_number")} />
													</Stack>
												)}
												<Stack distribution="fillEvenly">
													<TextField label="Telefon" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} />
													<TextField label="E-post" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} />
												</Stack>
												<Stack distribution="fillEvenly">
													<TextField
														label="Information"
														value={this.state.form.information}
														multiline={4}
														onChange={this.updateForm.bind(this, "information")}
													/>
												</Stack>
												<Checkbox label="Kunden är inaktiv" checked={this.state.form.disabled} onChange={this.updateForm.bind(this, "disabled")} />
											</FormLayout>
										</Card>
									</Layout.Section>{" "}
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Adresser
										</Text>

										<Card sectioned>
											<FormLayout>
												<SearchField
													label="Besöksadress"
													value={this.state.form.address}
													resource="google_address"
													resourceName={{
														singular: "adress",
														plural: "adresser",
													}}
													onSelect={this.selectAddress.bind(this)}
													suffix={
														this.state.form.address && (
															<Tooltip content="Visa karta">
																<StyledButton
																	plain
																	onClick={() => {
																		this.setState({ show_address_map: !this.state.show_address_map });
																	}}
																>
																	<MapIcon />
																</StyledButton>
															</Tooltip>
														)
													}
												/>

												{this.state.form.address && this.state.show_address_map && (
													<div style={{ marginTop: 20 }}>
														<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} zoom={12} height={500} />
													</div>
												)}

												<SearchField
													label="Faktureringsadress"
													value={this.state.form.invoice_address}
													resource="google_address"
													resourceName={{
														singular: "adress",
														plural: "adresser",
													}}
													onSelect={this.selectInvoiceAddress.bind(this)}
													suffix={
														this.state.form.invoice_address && (
															<Tooltip content="Visa karta">
																<StyledButton
																	plain
																	onClick={() => {
																		this.setState({ show_invoice_address_map: !this.state.show_invoice_address_map });
																	}}
																>
																	<MapIcon />
																</StyledButton>
															</Tooltip>
														)
													}
												/>

												{this.state.form.invoice_address && this.state.show_invoice_address_map && (
													<div style={{ marginTop: 20 }}>
														<Map latitude={this.state.form.invoice_latitude} longitude={this.state.form.invoice_longitude} zoom={12} height={500} />
													</div>
												)}

												<SearchField
													label="Leveransadress"
													value={this.state.form.delivery_address}
													resource="google_address"
													resourceName={{
														singular: "adress",
														plural: "adresser",
													}}
													onSelect={this.selectDeliveryAddress.bind(this)}
													suffix={
														this.state.form.delivery_address && (
															<Tooltip content="Visa karta">
																<StyledButton
																	plain
																	onClick={() => {
																		this.setState({ show_delivery_address_map: !this.state.show_delivery_address_map });
																	}}
																>
																	<MapIcon />
																</StyledButton>
															</Tooltip>
														)
													}
												/>

												{this.state.form.delivery_address && this.state.show_delivery_address_map && (
													<div style={{ marginTop: 20 }}>
														<Map latitude={this.state.form.delivery_latitude} longitude={this.state.form.delivery_longitude} zoom={12} height={500} />
													</div>
												)}
											</FormLayout>
										</Card>
									</Layout.Section>
									<Layout.Section oneThird>
										<Text as="h3" variant="headingMd" fontWeight="bold">
											Faktureringsinställningar
										</Text>

										<Card sectioned>
											<FormLayout>
												<TextField
													type="number"
													label="Tim pris"
													value={this.state.form.hourly_rate + ""}
													onChange={this.updateForm.bind(this, "hourly_rate")}
												/>
												<TextField
													suffix={
														<Tooltip content="Ange det standardpåslag som önskas på inköp av varor / leverantörsfakturor">
															<Icon source={QuestionMarkMajor} />
														</Tooltip>
													}
													type="number"
													label="Påslag inköp (%)"
													value={this.state.form.purcharge_price_surcharge + ""}
													onChange={this.updateForm.bind(this, "purcharge_price_surcharge")}
												/>
												<TextField
													suffix="dagar"
													type="number"
													label="Betalningsvillkor"
													value={this.state.form.payment_terms}
													onChange={this.updateForm.bind(this, "payment_terms")}
												/>

												<Select
													label="Faktureringsmetod"
													options={[
														{ label: "E-post", value: "epost" },
														{ label: "Post", value: "post" },
													]}
													onChange={this.updateForm.bind(this, "invoice_method")}
													value={this.state.form.invoice_method}
												/>
												<TextField
													label="E-post för fakturor"
													value={this.state.form.invoice_email}
													onChange={this.updateForm.bind(this, "invoice_email")}
												/>
												<TextField
													label="E-post för påminnelser"
													value={this.state.form.reminder_email}
													onChange={this.updateForm.bind(this, "reminder_email")}
												/>

												{this.state.form.type == "company" && (
													<Checkbox
														label="Omvänd skattskyldighet"
														checked={this.state.form.reverse_tax_liability}
														onChange={this.updateForm.bind(this, "reverse_tax_liability")}
													/>
												)}
											</FormLayout>
										</Card>
									</Layout.Section>
								</Layout>
							</CardsInPageWrapper>
						)}

						{this.state.selectedTabId == "contacts" && (
							<IndexTable
								ref={this.BigDataRef}
								setRefreshHandler={(refreshHandler) => {
									// eslint-disable-next-line react/no-unused-class-component-methods
									this.refresh = refreshHandler;
								}}
								history={this.props.history}
								title="Kontakter"
								primaryAction={{
									content: "Ny kontakt",
									onAction: () => {
										if (this.ContactCreateRef.current) this.ContactCreateRef.current.onToggle();
									},
								}}
								savedSearchHandle="contacts"
								// resourceUrl={`/api/contacts.json?parent_id=${this.state.id}`}
								resourceHandle="contacts"
								resourceName={{
									singular: "kontakt",
									plural: "kontakter",
								}}
								onItemsFetched={this.onItemsFetched.bind(this)}
								onSelectionChange={this.onSelectionChange.bind(this)}
								selectedItems={this.state.selectedItems || []}
								onUpdateParams={this.updateParams.bind(this)}
								renderCell={this.renderCell.bind(this)}
								filters={filters}
								columns={columns}
								defaultSort="LAST_ACTIVITY_DESC"
								promotedBulkActions={promotedBulkActions}
								// onClickRow={this.gotoContact.bind(this)}

								noPagination
								limit={1000}
								items={this.state.form.contacts || []}
							/>
						)}
					</Layout.Section>
				</Layout>
				<ContactCreate
					parentDisabled
					parent={this.state.form}
					ref={this.ContactCreateRef}
					asModal
					noCreate
					onCreate={(contact) => {
						contact.id = Date.now();
						if (!this.state.form.contacts) {
							this.state.form.contacts = [contact];
						} else {
							this.state.form.contacts.push(contact);
						}
						// this.BigDataRef.current.addItem(contact);

						this.setState({ form: this.state.form });
					}}
				/>
				<PageActions
					primaryAction={{
						content: "Spara",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default CustomerCreate;

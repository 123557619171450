import React, { Component } from "react";
import { Popover, Icon, Spinner, Stack, OptionList, KeypressListener, EventListener } from "@shopify/polaris";
import axios from "axios";

import { SearchMajor, CirclePlusMajor } from "@shopify/polaris-icons";
import { store } from "../store";
import TextField from "./TextField";
import API from "../API";
import { toastr } from "./toastr";

class SearchField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			limit: 25,
			value: props.value || "",
			tags: props.tags ? props.tags : false,
			active: false,
			loading: true,
			resource: props.resource,
			resource_handle: props.resource_handle,
			timeout: null,
			cancelToken: null,
			results: [],
			id_handle: props.id_handle ? props.id_handle : "id",
			label_handle: props.label_handle ? props.label_handle : "title",
		};
		this.clearTimeout = null;
		this.clearTimeout2 = null;
	}

	componentWillReceiveProps(props) {
		this.setState({ value: props.value });
		console.debug(this.state.selection);
	}

	update(value) {
		if (value && String(value).substr(-1) == ",") {
			this.commaEvent();
			value = "";
		}
		this.cancelRequest();
		clearTimeout(this.state.timeout);
		var newState = { value, loading: true, timeout: setTimeout(this.doSearch.bind(this), 300) };
		if (!this.state.active) {
			newState.active = true;
		}
		this.setState(newState);
		if (this.props.onChange) this.props.onChange(value);
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	doSearch() {
		const CancelToken = axios.CancelToken;
		var query = this.state.value;
		var params = Object.assign(this.props.params || {}, {
			q: query,
			offset: 0,
			limit: this.state.limit,
		});
		if (this.state.resource == "categories") {
			params.type = "custom";
		}

		if (this.state.resource == "google_address") {
			API.get("/api/google/addresses.json", {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					this.setState({ loading: false, results: result.data, cancelToken: null, active: true });
				})
				.catch((error) => {
					toastr.error(error);
				});
		} else {
			API.get("/api/" + this.state.resource + (this.state.resource.indexOf(".json") >= 0 ? "" : ".json"), {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					let resourceHandle = this.state.resource_handle;
					if (!resourceHandle) {
						var pcs = this.state.resource.split("/");
						var handle = pcs[pcs.length - 1];
						pcs = handle.split(".");
						resourceHandle = pcs[0];
					}
					this.setState({ loading: false, results: result.data[resourceHandle], cancelToken: null, active: true });
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	selectAddress(item) {
		this.setState({ value: item.title });
		const CancelToken = axios.CancelToken;

		API.get("/api/google/addresses/" + item.id + ".json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
		})
			.then((result) => {
				this.props.onSelect(result.data);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	selectItem(selection) {
		var selectedId = selection[0];
		var item = null;
		if (selectedId === null) {
			item = {};
			item[this.state.id_handle] = null;
			item[this.state.label_handle] = this.state.value;
		} else {
			for (var i = 0; i < this.state.results.length; i++) {
				if (this.state.results[i][this.state.id_handle] == selectedId) {
					item = this.state.results[i];
					break;
				}
			}
		}

		this.setState({
			value: "",
			active: false,
			loading: false,
			results: [],
			selection: [],
		});
		if (this.state.resource == "google_address") {
			this.selectAddress(item);
		} else {
			this.props.onSelect(item);
		}
	}

	blur() {
		/*
      May be buggy!
    */
		// if (this.props.onBlur) {
		// 	this.clearTimeout = setTimeout(this.props.onBlur.bind(this), 1000);
		// }
		// this.clearTimeout2 = setTimeout(this.setState.bind(this, { active: false }), 1000);
	}

	focus() {
		if (this.clearTimeout) {
			clearTimeout(this.clearTimeout);
			this.clearTimeout = null;
		}
		if (this.clearTimeout2) {
			clearTimeout(this.clearTimeout2);
			this.clearTimeout2 = null;
		}

		this.update(this.state.value);
		this.handleFocus();
	}

	handleFocus(event) {
		if (event?.key) this.state.value += event.key;
		if (document.activeElement !== this.activatorWrapperRef?.querySelector("input")) this.setState({ active: true });

		setTimeout(() => {
			if (document.activeElement !== this.activatorWrapperRef?.querySelector("input")) {
				this.activatorWrapperRef?.querySelector("input")?.focus();
			}
		}, 0);
		setTimeout(() => {
			if (document.activeElement !== this.activatorWrapperRef?.querySelector("input")) {
				this.activatorWrapperRef?.querySelector("input")?.focus();
			}
		}, 100);
	}

	onKeyDown(event) {
		if ((event.key.length === 1 && /^[A-Za-z]*$/.test(event.key)) || ["å", "ä", "ö"].includes(event.key.toLowerCase())) this.handleFocus(event);
	}

	closePopover() {
		this.setState({ active: !this.state.active });

		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	enterEvent() {
		if (this.state.tags) {
			var tag = this.state.value.trim();
			this.cancelRequest();
			var item = {};
			item[this.state.id_handle] = null;
			item[this.state.label_handle] = tag;
			this.setState({ value: "", active: false, selection: [] });
			this.props.onSelect(item);
		}
	}

	commaEvent() {
		if (this.state.tags) {
			var tag = this.state.value.trim();
			if (tag.substr(-1) == ",") {
				tag = tag.substr(0, tag.length - 1);
			}
			if (!tag) {
				return;
			}
			this.cancelRequest();
			var item = {};
			item[this.state.id_handle] = null;
			item[this.state.label_handle] = tag;
			this.setState({ value: "", active: false, selection: [] });
			this.props.onSelect(item);
		}
	}

	in_results() {
		for (var i = 0; i < this.state.results.length; i++) {
			if (this.state.results[i][this.state.label_handle].toLowerCase() == this.state.value.toLowerCase()) {
				return true;
			}
		}
		return false;
	}

	render() {
		const activator = (
			<div
				ref={(ref) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					this.activatorWrapperRef = ref;
				}}
				style={{
					pointerEvents: this.props.readOnly ? "none" : "auto",
				}}
			>
				<TextField
					labelHidden={this.props.labelHidden}
					placeholder={this.props.placeholder}
					label={this.props.label}
					prefix={this.props.prefix === undefined ? <Icon source={SearchMajor} /> : this.props.prefix}
					autoFocus={this.props.autoFocus}
					autoComplete={false}
					onFocus={this.focus.bind(this)}
					onBlur={this.blur.bind(this)}
					error={this.props.error}
					type="search"
					value={this.state.value}
					onChange={this.update.bind(this)}
					disabled={!!store.getState().user.read_only || this.props.disabled}
					required={this.props.required}
					suffix={this.props.suffix}
					readOnly={this.props.readOnly}
					onClearButtonClick={this.props.onClearButtonClick}
					clearButton={this.props.clearButton}
				/>
			</div>
		);

		var options = [];
		if (this.props.clearOption) {
			options.push({
				value: null,
				label: this.props.clearOption,
			});
		}
		if (this.props.allowCreate || (this.state.tags && this.state.value && !this.in_results())) {
			if (this.state.tags) {
				options.push({
					value: null,
					label: (
						<Stack>
							<Icon source={CirclePlusMajor} /> <div>Lägg till {this.state.value}</div>
						</Stack>
					),
				});
			} else {
				options.push({
					value: null,
					label: (
						<Stack>
							<Icon source={CirclePlusMajor} /> <div>Skapa ny {this.props.resourceName?.singular}</div>
						</Stack>
					),
				});
			}
		}
		if (this.state.results) {
			for (var i = 0; i < this.state.results.length; i++) {
				options.push({
					value: this.state.results[i][this.state.id_handle],
					label: this.props.renderLabel ? this.props.renderLabel(this.state.results[i]) : this.state.results[i][this.state.label_handle],
				});
			}
		}

		var pcs = this.state.resource.split("/");
		var handle = pcs[pcs.length - 1];
		pcs = handle.split(".");
		// var resourceHandle = pcs[0];

		return (
			<Popover
				active={this.state.active}
				fixed={this.props.fixed}
				preventAutofocus
				autofocusTarget="none"
				fullWidth={this.props.fullWidth}
				activator={activator}
				onClose={this.closePopover.bind(this)}
			>
				<div className={`searchfield ${this.props.className || ""}`}>
					<KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} />
					<KeypressListener handler={this.closePopover.bind(this)} keyCode={27} />
					<KeypressListener handler={this.closePopover.bind(this)} keyCode={9} />
					<EventListener event="keydown" handler={this.onKeyDown.bind(this)} />
					{this.state.loading ? (
						<div className="searchfield-loader" style={{ textAlign: "center", paddingTop: 10 }}>
							<Spinner size="small" color="teal" />
						</div>
					) : options.length > 0 ? (
						<OptionList onChange={this.selectItem.bind(this)} options={options} selected={this.state.selected} />
					) : (
						<div style={{ padding: 10, textAlign: "center" }}>Inga {this.props.resourceName?.plural} hittades</div>
					)}
				</div>
			</Popover>
		);
	}
}

export default SearchField;

SearchField.defaultProps = {
	fullWidth: true,
};

/* eslint-disable react/jsx-props-no-spreading */
import { withRouter } from "react-router-dom";
import React, { useRef } from "react";
import { Page as PolarisPage } from "@shopify/polaris";
import type { MenuActionDescriptor, PageProps as PolarisPageProps } from "@shopify/polaris";
// import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ActionsBar from "./ActionsBar";

export type SecondaryAction = {
	confirmation?: {
		title?: string | null;
		content?: string | null | React.ReactNode;
		confirm?: string | null;
		cancel?: string | null;
	};
	isGroup?: boolean;
	enabled?: boolean;
	actions?: SecondaryAction[];
} & MenuActionDescriptor;

type PageProps = {
	secondaryActions?: SecondaryAction[];
	history?: any;
} & PolarisPageProps;

const Page = withRouter(
	({
		children,
		secondaryActions,
		actionGroups,
		primaryAction: propsPrimaryAction,
		title,
		backAction: propsBackAction,
		history,
		...rest
	}: PageProps) => {
		const ref = useRef<HTMLDivElement>(null);
		const [isLoadingPrimaryAction, setIsLoadingPrimaryAction] = React.useState(false);

		const handlePrimaryAction = async () => {
			const action = propsPrimaryAction as any;
			setIsLoadingPrimaryAction(true);
			try {
				if (React.isValidElement(propsPrimaryAction)) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					/* @ts-ignore */
					await propsPrimaryAction.props.onClick();
				} else {
					await action?.onAction();
				}
			} catch (error) {
				console.error("Page primaryAction error:", error);
			}
			setIsLoadingPrimaryAction(false);
		};
		const primaryActionProps = !propsPrimaryAction
			? undefined
			: React.isValidElement(propsPrimaryAction)
			? React.cloneElement(propsPrimaryAction, {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					/* @ts-ignore */
					onClick: handlePrimaryAction,
					loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading,
			  })
			: { ...(propsPrimaryAction as any), onAction: handlePrimaryAction, loading: isLoadingPrimaryAction || (propsPrimaryAction as any)?.loading };

		const backAction = propsBackAction || (history && history?.length > 1 ? { content: "Tillbaka", onAction: () => history.goBack() } : undefined);

		return (
			<Wrapper ref={ref}>
				<PolarisPage title={title} primaryAction={primaryActionProps} backAction={backAction} {...rest}>
					<ActionsBar secondaryActions={secondaryActions} actionGroups={actionGroups} style={{ opacity: 0.75, marginBottom: "0.8rem" }} />
					{children}
				</PolarisPage>
			</Wrapper>
		);
	}
);

export default (props: PageProps) => {
	return <Page {...props} />;
};

const Wrapper = styled.div`
	& > .Polaris-Page > .Polaris-Box {
		padding-block-end: calc(
			var(
					--pc-box-padding-block-end-xl,
					var(
						--pc-box-padding-block-end-lg,
						var(--pc-box-padding-block-end-md, var(--pc-box-padding-block-end-sm, var(--pc-box-padding-block-end-xs)))
					)
				) / 2
		) !important;
	}
	.Polaris-Button {
		--p-color-icon-interactive: #ffffff;
		--p-color-icon-interactive-hover: var(--accent);

		.Polaris-Icon {
			display: flex;
			align-items: center;
		}

		&,
		* {
			transition: color 100ms, fill 100ms;
		}

		&.Polaris-Button--plain:hover,
		&.Polaris-Button--plain:active {
			&:not(.Polaris-Button--destructive) {
				--color-primary: var(--accent);
				.Polaris-Button__Icon {
					--p-color-icon-interactive: var(--accent);
				}
			}

			&.Polaris-Button--destructive {
				--color-primary: var(--red);
				.Polaris-Button__Icon {
					--p-color-icon-interactive: var(--red);
				}
			}
		}

		/* &:not(.Polaris-Button--destructive) &.Polaris-Button--plain:hover {
			.Polaris-Button__Text {
				--color-primary: var(--accent);
				color: var(--accent);
			}
		} */

		/* &.Polaris-Button--destructive &.Polaris-Button--plain:hover {
			.Polaris-Button__Text {
				color: red;
			}
		} */
	}
`;

import { Button, Tooltip } from "@shopify/polaris";
import { DeleteMajor, EditMajor } from "@shopify/polaris-icons";
import { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import Truncate from "./Truncate";

class ImageThumbnail extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// onChange(field, value) {
	// 	this.props.onChange(field, value);
	// }

	getUrl() {
		if (!this.props.upload) return null;

		switch (this.props.upload.type) {
			default:
				if (this.props.upload.url?.endsWith(".pdf")) return null;
				return this.props.upload.url;
		}
	}

	render() {
		const { upload, children, onDelete, onEdit, onClick } = this.props;
		const url = this.getUrl();

		return (
			<Wrapper>
				<PopupDrawer>
					{onEdit && (
						<Tooltip content="Redigera">
							<Button icon={EditMajor} onClick={onEdit} />
						</Tooltip>
					)}
					{onDelete && (
						<Tooltip content="Ta bort fil">
							<Button destructive icon={DeleteMajor} onClick={onDelete} />
						</Tooltip>
					)}
				</PopupDrawer>
				<div>
					<Tooltip content="Öppna bild">
						{upload.thumbnail?.url || url ? (
							<Image onClick={() => (onClick ? onClick() : window.open(upload.url))} src={upload.thumbnail?.url || url} />
						) : (
							<EmptyImage onClick={() => (onClick ? onClick() : window.open(upload.url))}>
								<span>Saknar bild</span>
							</EmptyImage>
						)}
					</Tooltip>

					<p
						style={{
							fontSize: "0.75rem",
							color: "var(--color-secondary)",
						}}
					>
						{moment(upload.created_at).format("YYYY-MM-DD HH:mm")}
					</p>
					<Truncate numberOfLines={2}>{upload.title || upload.comment}</Truncate>

					{/* <Checkbox
						checked={upload.private}
						label="Privat (syns endast för admin)"
						onChange={this.onChange.bind(this, "private")}
						disabled={disabled || !this.props.onChange}
					/> */}
				</div>

				{children}
			</Wrapper>
		);
	}
}
export default ImageThumbnail;

const PopupDrawer = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	gap: 0.5rem;
	transform: translate(0%, 0%);
	opacity: 0;
	pointer-events: none;
	transition: transform 250ms, opacity 150ms;
	z-index: 2;
	background: var(--tertiary);
	border-radius: 500px;

	&:hover {
		transform: translate(-25%, -25%);
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}
`;

const Wrapper = styled.div`
	width: max-content;
	min-width: 200px;
	max-width: 200px;
	display: flex;
	flex-direction: column;
	box-shadow: var(--p-shadow-md);
	/* padding: 0.5rem; */
	justify-content: space-between;
	position: relative;

	&:hover ${PopupDrawer} {
		transform: translate(-25%, -25%);
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	.Polaris-Icon {
		width: 1em;
		height: 1em;
	}
`;

const Image = styled.img`
	height: 100px;
	cursor: pointer;

	width: 100%;
	object-fit: cover;
`;

const EmptyImage = styled.div`
	height: 100px;
	cursor: pointer;

	width: 100%;
	object-fit: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--tertiary);
	color: var(--color-secondary);
	font-size: 0.75rem;
`;

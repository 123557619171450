import { Button, FormLayout, Icon, Modal, TextField, VerticalStack } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import API from "src/js/API";
import styled from "styled-components";
import SearchField from "../search_field";
import UserTag from "../UserTag";
import { toastr } from "../toastr";
import { ChatMajor } from "@shopify/polaris-icons";

type Form = {
	users: { id: number; name: string }[];
	message: string;
	// [key: string]: any;
};

const SendMessageModal = () => {
	const defaultForm: Form = { users: [], message: "" };
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState<Form>(defaultForm);

	const handleMessage = useCallback((message) => setForm((c) => ({ ...c, message })), []);
	const handleAddUser = useCallback((user) => setForm((c) => ({ ...c, users: [...c.users, user] })), []);
	const handleRemoveUser = useCallback(
		(index: number) =>
			setForm((c) => {
				const users = [...c.users].filter((_, i) => i !== index);
				return { ...c, users };
			}),
		[]
	);

	const handleOpen = () => {
		setForm(defaultForm);
		setOpen(true);
	};
	const handleClose = () => {
		setForm(defaultForm);
		setOpen(false);
	};
	const handleSend = () => {
		const ids = form.users.map((user) => user.id);

		setLoading(true);
		API.post("/api/users/notify.json", { ...form, ids })
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				handleClose();
				toastr.success("Meddelande skickat");
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Button onClick={handleOpen}>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<InnerWrapper>
					Skicka meddelande
					<Icon source={ChatMajor} />
				</InnerWrapper>
			</Button>
			<Modal
				title="Skicka meddelande"
				open={open}
				onClose={handleClose}
				secondaryActions={[
					{
						content: "Stäng",
						onAction: handleClose,
					},
				]}
				primaryAction={{
					content: "Skicka meddelande",
					onAction: handleSend,
					loading,
					disabled: form.users.length === 0 || form.message.length === 0,
				}}
			>
				<Modal.Section>
					<FormLayout>
						<SearchField
							label="Lägg till personer"
							placeholder="Välj önskade användare"
							resource="users.json?enabled=1"
							resourceHandle="users"
							resource_handle="users"
							resourceName={{
								singular: "användare",
								plural: "användare",
							}}
							label_handle="name"
							onSelect={handleAddUser}
						/>

						<VerticalStack gap="2">
							{form.users.map((user, index) => (
								<UserTag key={index} user={user} onRemove={() => handleRemoveUser(index)} />
							))}
						</VerticalStack>

						<TextField
							value={form.message}
							onChange={handleMessage}
							autoComplete="false"
							label="Meddelande"
							multiline={4}
							helpText="Detta meddelande skickas i appen som en privat notis till alla kontakter du angivit."
							showCharacterCount
							maxLength={1800}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		</>
	);
};
export default SendMessageModal;

const InnerWrapper = styled.div`
	display: flex;
	gap: 8px;
	width: max-content;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
`;

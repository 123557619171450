/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import { fabric } from "fabric";
import Toolbar from "./Toolbar";
import Canvas from "./Canvas";
import { toastr } from "../../../components/toastr";
import Skeleton1Col from "../../../components/skeleton_1col";
import API from "../../../API";

fabric.Object.NUM_FRACTION_DIGITS = 10;
fabric.Textbox.prototype._wordJoiners = /[\n|" "]/;

fabric.CustomImage = fabric.util.createClass(fabric.Image, {
	type: "custom-image",
	initialize(element, options) {
		this.callSuper("initialize", element, options);
		if (options) this.set("id", options.id);
		if (options) this.set("numRows", options.numRows);
	},
	toObject() {
		return fabric.util.object.extend(this.callSuper("toObject"), { id: this.id, numRows: this.numRows });
	},
});
fabric.CustomImage.fromObject = function (object, callback) {
	fabric.util.loadImage(object.src, function (img) {
		img.crossOrigin = "anonymous";
		if (callback) callback(new fabric.CustomImage(img, object));
	});
};
fabric.CustomImage.async = true;

class FreeEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { pages: [] },
			id: props.id || props.match.params.id,
			height: 955.85,
			width: 677,
			originalHeight: 955.85,
			originalWidth: 677,
			zoom: 1,
			activePage: this.getParamPage(props),
		};
		this.canvases = [];
		this.positions = { left: this.state.height / 2, top: this.state.width / 2, originX: "center", originY: "center" };
	}

	componentDidMount() {
		this.fixSize();
		this.fetch();

		document.addEventListener("paste", this.pasteEvent.bind(this));
		document.addEventListener("copy", this.copyEvent.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener("paste", this.pasteEvent.bind(this));
		document.removeEventListener("copy", this.copyEvent.bind(this));
	}

	componentWillReceiveProps(props) {
		if (this.state.id !== props.id) {
			this.setState({ id: props.id }, () => this.fetch(true));
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getCanvases() {
		return this.canvases;
	}

	fixSize() {
		if (this.props.dynamicWidth) {
			const extraPadding = this.props.extraPadding || 0;

			const padding =
				parseInt(window.getComputedStyle(document.querySelector(".editor-scroll__inner"), null).getPropertyValue("padding-left")) +
				parseInt(window.getComputedStyle(document.querySelector(".editor-scroll__inner"), null).getPropertyValue("padding-right"));
			const width = document.querySelector(".editor-scroll").clientWidth - 2 - padding - extraPadding;
			const height = width * (955.85 / 677);
			this.setState({ width, height, originalHeight: height, originalWidth: width });
			this.positions = Object.assign(this.positions, { left: height / 2, top: width / 2 });
		}
	}

	copy() {
		const copies = [];
		if (this.state.activeObjects) {
			this.state.activeObjects.forEach((object) => {
				copies.push(object);
			});
		}

		this.setState({ copies });
	}

	pasteEvent(e) {
		if (!this.state.activeObjects || !this.state.activeObjects.length) {
			navigator.clipboard.readText().then((clipText) => {
				if (clipText) this.addText(clipText);
			});
		}
	}

	copyEvent(e) {
		this.copy();
	}

	duplicateObject() {
		const objects = this.state.activeObjects && this.state.activeObjects.length ? this.state.activeObjects : this.state.copies;

		if (objects && objects.length) {
			if (objects.length > 1) {
				const clones = [];
				objects.forEach((object) => {
					object.clone((obj) => {
						obj.set({
							left: obj.left + 10,
							top: obj.top + 10,
							evented: true,
						});

						clones.push(obj);
					});
				});
				var group = new fabric.Group(clones);
				this.canvases[this.state.activePage].canvas.add(group);
				this.canvases[this.state.activePage].canvas.setActiveObject(group);
				this.setActiveObject([group]);
			} else if (objects && objects[0]) {
				objects[0].clone((obj) => {
					obj.set({
						left: obj.left + 10,
						top: obj.top + 10,
						evented: true,
					});

					this.canvases[this.state.activePage].canvas.add(obj);
					obj.setCoords();
					this.canvases[this.state.activePage].canvas.setActiveObject(obj);
					this.setActiveObject([obj]);
				});
			}
			this.setState({ copies: objects });
		}
	}

	getParamPage(props = this.props) {
		if (props.location) {
			const params = new URLSearchParams(props.location.search);
			if (params.get("page") !== null && params.get("page") !== undefined) return Math.max(params.get("page") - 1, 0);
		}
		return 0;
	}

	fetch(silent) {
		this.setState((c) => ({ loading: !silent ? true : c.loading, rnd: Math.random() }));

		API.get(`/api/pdf_templates/${this.state.id}.json`, { params: { variables: this.props.variables || null, rowId: this.props.rowId } })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				if (result.data.pdf_template) {
					this.setState(
						{
							form: result.data.pdf_template,
							lastFontFamily: result.data.pdf_template.last_font_family,
							loading: false,
							rnd: Math.random(),
						},
						() => {
							if (!silent && !this.props.quote) {
								const scrollTo = `cw${this.state.activePage}`;
								this.scrollTo(scrollTo);
							}
						}
					);
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.error("error:", error);
				toastr.error(error);
				console.debug(this.state.rnd);
			});
	}

	onClickoutside(e) {
		if (e.target.className === "editor-scroll") {
			if (this.canvases && this.canvases[this.state.activePage]) {
				this.canvases[this.state.activePage].canvas.discardActiveObject();
			}
		}
	}

	addPage(callback) {
		if (!this.state.form.pages) {
			this.state.form.pages = [{ data: null, tempId: Date.now() }];
		} else {
			this.state.form.pages.push({ data: null, tempId: Date.now() });
		}
		this.setState({ form: this.state.form }, () => {
			// setTimeout(() => {
			if (callback && typeof callback === "function") callback();
			// }, 100);
		});
	}

	addText(text) {
		const ele = new fabric.Textbox(text || "Text", {
			left: 2,
			right: 2,
			top: 2,
			fontSize: this.state.lastFontSize || 20,
			fontFamily: this.state.lastFontFamily || "Times New Roman",
			// splitByGrapheme: true,
			dynamicMinWidth: this.state.originalWidth - 4,
			minWidth: this.state.originalWidth - 4,
			width: this.state.originalWidth - 4,
			lockScalingY: true,
			lockSkewingY: true,
		});
		this.canvases[this.state.activePage].canvas.add(ele);
		this.canvases[this.state.activePage].canvas.setActiveObject(ele);
		this.setActiveObject([ele]);
		ele.enterEditing();
		ele.selectAll();
	}

	addLine() {
		const width = 300;
		const height = 20;
		const ele = new fabric.Line(
			[width, 0, height, 0],
			Object.assign(this.positions, {
				stroke: "rgb(0,0,0)",
				strokeWidth: 2,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	addAnything(obj, options = {}) {
		var svgData =
			`<svg xmlns="http://www.w3.org/2000/svg" width="${options.width || this.state.originalWidth}" height="${options.height}" >` +
			'<foreignObject width="100%" height="100%">' +
			`<div xmlns="http://www.w3.org/1999/xhtml">` +
			obj +
			"</div>" +
			"</foreignObject>" +
			"</svg>";
		const dataUri = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
		const img = new Image();
		img.onload = () => {
			var imgInstance = new fabric.CustomImage(
				img,
				Object.assign(
					{
						width: options.width || this.state.originalWidth,
						height: options.height,
						id: "table",
					},
					options
				)
			);

			this.canvases[this.state.activePage].canvas.add(imgInstance);
			this.canvases[this.state.activePage].canvas.setActiveObject(imgInstance);
			this.setActiveObject([imgInstance]);
			this.canvases[this.state.activePage].canvas.renderAll();
		};
		img.src = dataUri;
	}

	addRect() {
		const width = 30;
		const height = 30;
		const ele = new fabric.Rect(
			Object.assign(this.positions, {
				height,
				width,
				fill: "transparent",
				stroke: "rgb(0,0,0)",
				strokeWidth: 5,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	addCircle() {
		const width = 50;
		const height = 50;
		const ele = new fabric.Circle(
			Object.assign(this.positions, {
				radius: 50,
				height,
				width,
				fill: "transparent",
				stroke: "rgb(0,0,0)",
				strokeWidth: 5,
			})
		);
		this.canvases[this.state.activePage].canvas.add(ele);
	}

	zoom(direction = "in") {
		this.setState(
			({ height, width, zoom }) => {
				if (typeof direction == "number") {
					return { height: this.state.originalHeight * direction, width: this.state.originalWidth * direction, zoom: direction };
				}
				const v = 1.2;
				if (direction === "out") {
					return { height: height / v, width: width / v, zoom: zoom / v };
				}
				if (direction === "in") {
					return { height: height * v, width: width * v, zoom: zoom * v };
				}
			},
			() => {
				if (this.canvases && this.canvases.length) {
					this.canvases
						.filter((i) => i)
						.forEach(({ canvas } = {}) => {
							canvas.setZoom(this.state.zoom);
							canvas.setHeight(this.state.height);
							canvas.setWidth(this.state.width);
						});
				}
			}
		);
	}

	addImage(urls, style) {
		if (!urls || !Array.isArray(urls) || !urls.length) return null;
		urls.forEach((url) => {
			new fabric.Image.fromURL(url, (img) => {
				if (style && typeof style === "function") {
					const s = style(img);
					if (s) img.set(style(img));
				} else if (style && typeof style === "object") {
					img.set(style);
				} else {
					const { height, width } = img.getOriginalSize() || {};
					const aspectRatio = width / height;

					if ((height > this.state.height * 0.8 || width > this.state.width * 0.8) && aspectRatio === 1) {
						img.scaleToWidth(this.state.width * 0.8);
					} else if (height > this.state.height * 0.8 && aspectRatio < 1) {
						img.scaleToHeight(this.state.height * 0.8);
					} else if (width > this.state.width * 0.8 && aspectRatio > 1) {
						img.scaleToWidth(this.state.width * 0.8);
					}
				}
				img.crossOrigin = "anonymous";
				this.canvases[this.state.activePage].canvas.add(img);
				// this.canvases[this.state.activePage].canvas.renderAll();
			});
		});
	}

	setActiveObject(activeObjects) {
		this.setState({ activeObjects });
	}

	setActivePage(v) {
		this.setState({ activePage: v });
	}

	scrollTo(scrollTo) {
		if (scrollTo) {
			setTimeout(() => {
				const element = document.getElementById(scrollTo);
				const yOffset = -175;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: "smooth" });
			}, 1);
		}
	}

	gotoNextPage() {
		if (this.state.activePage + 1 >= this.canvases.length) return null;
		this.setState(
			(c) => ({ activePage: c.activePage + 1 }),
			() => {
				const scrollTo = `cw${this.state.activePage}`;
				this.scrollTo(scrollTo);
			}
		);
	}

	gotoPrevPage() {
		if (this.state.activePage === 0) return null;
		this.setState(
			(c) => ({ activePage: c.activePage - 1 }),
			() => {
				const scrollTo = `cw${this.state.activePage}`;
				this.scrollTo(scrollTo);
			}
		);
	}

	removePage(index) {
		setTimeout(() => {
			this.state.form.pages.splice(index, 1);
			this.setState({ form: this.state.form });
		}, 100);
	}

	clearCanvas() {
		this.canvases[this.state.activePage].canvas.getObjects().forEach((obj) => {
			this.canvases[this.state.activePage].canvas.remove(obj);
		});
	}

	duplicatePage(index) {
		const canvas = JSON.stringify(this.canvases[index].canvas);
		this.state.form.pages.splice(index, 0, { raw_data: canvas });
		this.setState({ form: this.state.form });
	}

	movePageUp(index) {
		if (index === 0) return null;
		const curr = this.state.form.pages[index];
		this.state.form.pages[index] = this.state.form.pages[index - 1];
		this.state.form.pages[index - 1] = curr;

		const currCanvas = this.canvases[index];
		this.canvases[index] = this.canvases[index - 1];
		this.canvases[index - 1] = currCanvas;

		this.setState({ form: this.state.form });
	}

	movePageDown(index) {
		if (index >= this.state.form.pages.length - 1) return null;

		const curr = this.state.form.pages[index];
		this.state.form.pages[index] = this.state.form.pages[index + 1];
		this.state.form.pages[index + 1] = curr;

		const currCanvas = this.canvases[index];
		this.canvases[index] = this.canvases[index + 1];
		this.canvases[index + 1] = currCanvas;

		this.setState({ form: this.state.form });
	}

	save(name) {
		const pages = this.state.form.pages.map((page = {}, index) => {
			try {
				const { canvas } = this.canvases[index] || {};

				const canvasJSON = JSON.stringify(canvas);
				const canvasSVG = canvas.toSVG();

				return Object.assign(page, { data: canvasSVG.toString(), raw_data: canvasJSON });
			} catch (error) {
				console.error("error:", error);
				return null;
			}
		});

		if (this.props.onSave) {
			this.props.onSave(pages, name);
			return;
		}

		this.setState({ saving: true });

		API.put(`/api/pdf_templates/${this.state.id}.json`, { pages, lastFontFamily: this.state.lastFontFamily })
			.then((result) => {
				this.setState({ saving: false });

				if (result.data.error) {
					this.setState({ saving: false });

					return;
				}

				toastr.success("Uppdaterade PDF");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		if (this.state.loading) return <Skeleton1Col />;

		return (
			<div className="editor">
				{!this.props.disableEditing && (
					<Toolbar
						history={this.props.history}
						id={this.state.id}
						print={this.props.print}
						setZoom={this.zoom.bind(this)}
						addPage={this.addPage.bind(this)}
						addText={this.addText.bind(this)}
						addImage={this.addImage.bind(this)}
						addLine={this.addLine.bind(this)}
						addRect={this.addRect.bind(this)}
						addCircle={this.addCircle.bind(this)}
						addAnything={this.addAnything.bind(this)}
						originalHeight={this.state.originalHeight}
						zoomIn={this.zoom.bind(this, "in")}
						zoomOut={this.zoom.bind(this, "out")}
						zoom={this.state.zoom}
						activeObjects={this.state.activeObjects}
						activePageNr={this.state.activePage}
						canvas={this.canvases[this.state.activePage]}
						gotoNextPage={this.gotoNextPage.bind(this)}
						gotoPrevPage={this.gotoPrevPage.bind(this)}
						form={this.state.form}
						canvases={this.canvases}
						onSave={this.save.bind(this)}
						requireName={this.props.requireName}
						saving={this.state.saving || this.props.saving}
						addLastFontSize={(v) => {
							this.setState({ lastFontSize: v });
						}}
						addLastFontFamily={(v) => {
							this.setState({ lastFontFamily: v });
						}}
						setActiveObject={this.setActiveObject.bind(this)}
						duplicateObject={this.duplicateObject.bind(this)}
					/>
				)}

				<div
					className="editor-scroll"
					style={this.props.dynamicWidth ? { height: this.state.originalHeight } : {}}
					onClick={this.onClickoutside.bind(this)}
				>
					<div className="editor-scroll__inner">
						{this.state.form.pages &&
							this.state.form.pages.map((page, index) => {
								return (
									<Canvas
										setActiveObject={this.setActiveObject.bind(this)}
										height={this.state.height}
										width={this.state.width}
										ref={(ref) => {
											this.canvases[index] = ref;
										}}
										onUnmount={() => {
											// this.canvases.splice(index, 1);
										}}
										key={page.id || page.tempId}
										index={index}
										page={page}
										setActivePage={this.setActivePage.bind(this, index)}
										removePage={this.removePage.bind(this, index)}
										clearCanvas={this.clearCanvas.bind(this)}
										duplicatePage={this.duplicatePage.bind(this, index)}
										movePageUp={this.movePageUp.bind(this, index)}
										movePageDown={this.movePageDown.bind(this, index)}
										setZoom={this.zoom.bind(this)}
										disableEditing={this.props.disableEditing}
									/>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
}
export default FreeEditor;

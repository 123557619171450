import { Icon } from "@shopify/polaris";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../icons/Home";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import IntersectionObserverElement from "./IntersectionObserverElement";

class MainMenuSection extends Component {
	constructor(props) {
		super(props);
		this.state = { search: "" };
		this.ref = React.createRef();
		this.secondaryRef = React.createRef();
	}

	getTriggerIcon() {
		const { icon } = this.props;
		if (!icon) return <HomeIcon />;
		if (React.isValidElement(icon)) return icon;
		return <Icon source={icon} />;
	}

	getIcon(icon) {
		if (!icon) return null;
		if (React.isValidElement(icon)) return icon;
		return <Icon source={icon} />;
	}

	onClick(url, onClick) {
		if (onClick) onClick();
		if (url) this.props.history.push(url);
		// requestAnimationFrame(() => {
		// 	this.setState({ rnd: Math.random() });
		// });
	}

	onSearchChange(e) {
		this.setState({ search: e });
	}

	render() {
		const { items, title, label, actions, children, id, secondaryItemsStyle, favorites } = this.props;

		return (
			<div ref={this.ref} id={id} className={`mainmenu-item ${this.props.className || ""}`}>
				{this.props.url ? (
					<Link to={this.props.url} className="mainmenu-item-trigger" data-content={title} onClick={this.props.onClick} style={{}}>
						{this.getTriggerIcon()}
						<span className="mainmenu-item-trigger_title">{title}</span>
					</Link>
				) : (
					<span className="mainmenu-item-trigger" data-content={title} onClick={this.props.onClick} style={{}}>
						{this.getTriggerIcon()}
						<span className="mainmenu-item-trigger_title">{title}</span>
					</span>
				)}

				<div ref={this.secondaryRef} className="mainmenu-item__secondary noscroll">
					<div className="mainmenu-item__secondary__header">
						<span className="mainmenu-item__secondary__header__title">{title || label}</span>
						<div className="mainmenu-item__secondary__actions" />
					</div>
					{(items || (actions && !!actions.length)) && (
						<MainMenuSectionSearch onChange={this.onSearchChange.bind(this)} value={this.state.search} actions={actions} />
					)}
					{favorites && !!favorites.length && (
						<div
							className="mainmenu-item__secondary__items"
							style={Object.assign({}, secondaryItemsStyle || {}, { borderBottom: "1px solid rgb(255,255,255,0.25)", marginBottom: "2.6rem" })}
						>
							{favorites &&
								favorites
									.filter((i) => {
										if (!this.state.search) return true;
										return (i.label || i.title || i.url).toLowerCase().includes(this.state.search.toLowerCase());
									})
									.map((i, index) => {
										// eslint-disable-next-line no-unused-vars
										const { label, title, icon, url, active_url: activeUrl, badge, onClick, style } = i;

										const itemUrl = activeUrl || url;
										const className = itemUrl && this.props.location && this.props.location.pathname.indexOf(itemUrl) >= 0 ? "active" : "";

										return (
											<div
												className={`mainmenu-item__secondary__item ${className}`}
												style={style || {}}
												onClick={this.onClick.bind(this, url, onClick)}
												key={index}
											>
												<div>
													{this.getIcon(icon)}
													{label || title || url || "-"}
												</div>
											</div>
										);
									})}
						</div>
					)}
					<div className="mainmenu-item__secondary__items" style={secondaryItemsStyle}>
						{children}
						{items &&
							items
								.filter((i) => {
									if (!this.state.search) return true;
									return (i.label || i.title || i.url).toLowerCase().includes(this.state.search.toLowerCase());
								})
								.map((i, index) => {
									// eslint-disable-next-line no-unused-vars
									const { label, title, icon, url, active_url: activeUrl, badge, onClick, style } = i;
									const itemUrl = activeUrl || url;
									const className = itemUrl && this.props.location && this.props.location.pathname.indexOf(itemUrl) >= 0 ? "active" : "";

									return (
										<div
											className={`mainmenu-item__secondary__item ${className}`}
											style={style || {}}
											onClick={this.onClick.bind(this, url, onClick)}
											key={index}
										>
											<div>
												{this.getIcon(icon)}
												{label || title || url || "-"}
											</div>
										</div>
									);
								})}
						<IntersectionObserverElement
							parent={this.secondaryRef.current}
							hideShadow
							onChange={(intersecting) => {
								// const shadow = this.ref.current.closest("bottom-scroll-shadow");
								const shadow = document.querySelector(`#${this.props.id}-shadow`);
								if (shadow) {
									if (intersecting) {
										shadow.classList.remove("visible");
										shadow.classList.add("invisible");
									} else if (this.ref.current.classList.contains("active")) {
										shadow.classList.remove("invisible");
										shadow.classList.add("visible");
									}
								}
							}}
							shadowStyle={{ width: this.ref.current && this.ref.current.clientWidth }}
						/>
					</div>
				</div>
				<div id={`${this.props.id}-shadow`} className="bottom-scroll-shadow" />
			</div>
		);
	}
}

export default MainMenuSection;

import React, { useEffect, useState } from "react";
import { Page, Banner, Stack, Tabs } from "@shopify/polaris";
import { store } from "../../store";
// import EconomyDashboard from "./EconomyDashboard";
// import ProjectMap from "./ProjectMap";
// import LocationDashboard from "./LocationDashboard";
import ProjectDashboard from "./ProjectDashboard";
// import QuotesDashboard from "./QuotesDashboard";
import type { DashboardTab } from "./types";
import { DashboardContainer, DashboardHeader, DashboardBody } from "./styledComponents";
import OverviewDashboard from "./overview";

type DashboardProps = {
	history: any;
	location: any;
};

const Dashboard = ({ history, location }: DashboardProps) => {
	const generateTabs = (): DashboardTab[] => {
		const tabs = [
			{
				id: "overview",
				content: "Översikt",
				path: "/admin/dashboard#overview",
				info: <OverviewDashboard history={history} />,
			},
			// {
			// 	id: "economy",
			// 	content: "Ekonomi",
			// 	path: "/admin/dashboard",
			// 	info: <EconomyDashboard history={history} />,
			// },
		];
		// tabs.push({
		// 	id: "timereports",
		// 	content: "Tidrapporteringar",
		// 	path: "/admin/dashboard#timereports",
		// 	info: <TimeReportDashboard history={history} />,
		// });

		// if (!store.getState().account.ue) {
		// 	tabs.push({
		// 		id: "projectmap",
		// 		content: "Arbetsorderkarta",
		// 		path: "/admin/dashboard#map",
		// 		info: <ProjectMap history={history} />,
		// 	});
		// }

		// if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
		// 	tabs.push({
		// 		id: "location",
		// 		content: "Positionering",
		// 		path: "/admin/dashboard#location",
		// 		info: <LocationDashboard history={history} />,
		// 	});
		// }

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
			tabs.push({
				id: "project",
				content: "Projektöversikt",
				path: "/admin/dashboard#project",
				info: <ProjectDashboard history={history} />,
			});
		}

		// if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
		// 	tabs.push({
		// 		id: "quotes",
		// 		content: "Offertstatisik",
		// 		path: "/admin/dashboard#quotes",
		// 		info: <QuotesDashboard history={history} />,
		// 	});
		// }

		return tabs;
	};
	const getSelectedTabFromHash = () => {
		const tabs = generateTabs();
		const defaultTab = tabs[0];
		if (location) {
			const hash = new URL(document.URL).hash.replace("#", "");
			const tab = (() => {
				switch (hash) {
					case "map":
						return tabs.find((tab) => tab.path.split("#")[1] === "map");
					case "timereports":
						return tabs.find((tab) => tab.path.split("#")[1] === "timereports");
					case "location":
						return tabs.find((tab) => tab.path.split("#")[1] === "location");
					default:
						return tabs.find((tab) => String(tab.id) === String(hash));
				}
			})();

			return tab?.id || defaultTab?.id;
		}

		return defaultTab?.id;
	};
	const tabs: DashboardTab[] = generateTabs();
	const [selectedTab, setSelectedTab] = useState(getSelectedTabFromHash());

	useEffect(() => {}, []);
	const tab = tabs.find((tab) => tab.id === selectedTab);

	if (store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0 && store.getState().user.roles.indexOf("ROLE_ADMIN") <= 0) {
		return (
			<Page fullWidth>
				<Banner title="Tips" status="warning">
					För att rapportera tid klickar du på plustecknet i den vänstra menyn.
				</Banner>
			</Page>
		);
	}
	return (
		<DashboardContainer>
			<DashboardHeader>
				<Stack wrap={false}>
					<Stack.Item fill>
						<Tabs
							tabs={tabs}
							selected={tabs.findIndex((tab) => tab.id === selectedTab) || 0}
							onSelect={(v) => {
								const path = tabs[v].path;
								if (path) {
									history.replace(path);
								}

								setSelectedTab(tabs[v].id);
							}}
						/>
					</Stack.Item>
				</Stack>
			</DashboardHeader>

			<DashboardBody>{tab?.info}</DashboardBody>
		</DashboardContainer>
	);
};

export default Dashboard;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import PdfViewer from "src/js/components/PdfViewer2";
import Skeleton1Col from "src/js/components/skeleton_1col";
import { getPDFBlobURL } from "./Utilities";

type FortnoxPDFViewProps = {
	history?: any;
	pdf: any;
	loading: boolean;
	width?: string;
};

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const FortnoxPDFView = ({ history, pdf, loading, width }: FortnoxPDFViewProps) => {
	return <Wrapper>{pdf ? <PdfViewer src={getPDFBlobURL(pdf)} width={width} /> : loading && <Skeleton1Col />}</Wrapper>;
};

export default FortnoxPDFView;

const Wrapper = styled.div`
	gap: 1rem;
	display: flex;
	justify-content: center;

	.Polaris-Card {
		height: max-content;
	}
`;

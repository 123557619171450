import { EmptyState } from "@shopify/polaris";
import React from "react";
import IndexTable from "src/js/components/IndexTable";
import ReactMoment from "react-moment";
import Badge from "src/js/components/Badge";

const FortnoxInvoiceIndex = ({ history, project }) => {
	const columns = [
		{
			label: "Datum",
			handle: "DATE",
			render: (invoice) => {
				return (
					<ReactMoment locale="sv" format="YYYY-MM-DD">
						{invoice.InvoiceDate}
					</ReactMoment>
				);
			},
		},
		{
			label: "Status",
			handle: "STATUS",
			render: (invoice) => {
				if (invoice.Cancelled) {
					return <Badge status="warning">Avbruten</Badge>;
				}

				if (invoice.Booked) {
					return <Badge status="success">Bokförd</Badge>;
				}

				if (invoice.Sent) {
					return <Badge status="info">Skickad</Badge>;
				}

				return <Badge>Ej skickad</Badge>;
			},
		},
		{
			label: "Belopp",
			handle: "TOTAL",
			render: (invoice) => {
				return `${invoice.Total}kr`;
			},
		},
		{
			label: "Faktnr",
			handle: "ID",
			render: (invoice) => {
				return `#${invoice.DocumentNumber}`;
			},
		},

		// {
		// 	label: "",
		// 	handle: "ACTIONS",
		// 	render: (invoice) => {
		// 		return (
		// 			<ButtonGroup>
		// 				<Button onClick={() => window.open(`/admin/fortnox/invoices/${invoice.DocumentNumber}/preview`)}>Öppna faktura</Button>
		// 				{/* <Button onClick={() => window.open(`/admin/fortnox/invoices/${invoice.DocumentNumber}/print`)}>Skriv ut</Button> */}
		// 			</ButtonGroup>
		// 		);
		// 	},
		// },
	];

	const handleCreateInvoice = () => {
		history.push(`/admin/invoices/new`, { project });
	};

	return (
		<IndexTable
			// selectable={false}
			idHandle="DocumentNumber"
			primaryAction={{
				content: "Ny faktura",
				onAction: handleCreateInvoice,
			}}
			onClickRow={(invoice) => {
				history.push(`/admin/invoices/${invoice.DocumentNumber}`, { project });
			}}
			title="Fakturor"
			history={history}
			resourceUrl="/api/external_invoices.json"
			resourceHandle="invoices"
			resourceName={{
				singular: "faktura",
				plural: "fakturor",
			}}
			params={{
				project_id: project && project.id,
			}}
			columns={columns}
			noHeader
			// noPagination
			emptyState={
				<EmptyState
					heading="Kunde inte hitta några fakturor"
					action={{ content: "Skapa", onAction: handleCreateInvoice }}
					image="/assets/images/empty_state/NoResults.png"
				>
					<p>Men du kan alltid skapa en ny med knappen nedan</p>
				</EmptyState>
			}
		/>
	);
};
export default FortnoxInvoiceIndex;

import React, { Component } from "react";

class Money extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	money(amt, moneyFormat) {
		var amount = amt / 100;
		var result;
		var pattern = /\{\{\s*(\w+)\s*\}\}/;

		function addSpaces(money) {
			money = money.replace(/(\d+)(\d{3}[\. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[\. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[\. ]?)/g, "$1 $2");
			return money;
		}

		switch (moneyFormat.match(pattern)[1]) {
			case "amount":
				result = addSpaces(parseFloat(amount).toFixed(2).toString().replace(".00", ""));
				break;
			case "fixed_amount":
				result = addSpaces(parseFloat(amount).toFixed(0).toString().replace(".00", ""));
				break;
			default:
				result = amount;
				break;
		}
		return moneyFormat.replace(pattern, result);
	}

	render() {
		var moneyFormat = "{{amount}} " + this.props.currency;

		if (this.props.currency == "SEK") {
			moneyFormat = "{{amount}}:-";
		} else if (this.props.currency == "USD") {
			// eslint-disable-next-line no-template-curly-in-string
			moneyFormat = "${{amount}}";
		} else if (this.props.currency == "EUR") {
			moneyFormat = "€{{amount}}";
		} else if (this.props.currency == "GBP") {
			moneyFormat = "£{{amount}}";
		}

		return <span style={this.props.style}>{this.money(this.props.amount, moneyFormat)}</span>;
	}
}
export default Money;

import { Component } from "react";
import { FormLayout, TextField, Stack, Modal } from "@shopify/polaris";
import ResourcePicker from "./resource_picker.js";
import { toastr } from "./toastr.js";
import API from "../API";

class ExpenseReportModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			surcharge: props.project.purcharge_price_surcharge,
			unit_price: 0,
			comment: "",
			quantity: 1,
			expense: null,
			lock_user: props.lock_user,
			user_id: props.user_id,
			user_name: props.user_name,
			project: props.project,
			work_order_id: props.work_order_id,
			saving: false,
		};
	}

	saveReport() {
		this.setState({ saving: true });
		API.post("/api/expense_reports.json", {
			project: this.state.project,
			workorder_id: this.state.work_order_id,
			expense: this.state.expense,
			amount: this.state.amount,
			amount_to_invoice: this.state.amount_to_invoice,
			unit_price: this.state.unit_price,
			user_id: this.state.user_id,
			quantity: this.state.quantity,
			comment: this.state.comment,
		})
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Utgiftsrapport skapad");
				if (this.props.onComplete) this.props.onComplete(result.data.expense_report);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	changeUser(user) {
		this.setState({ user_id: user.id, user_name: user.name });
	}

	changeExpense(expense) {
		this.setState({
			expense,
			unit_price: expense.price,
			amount: expense.price * this.state.quantity,
			amount_to_invoice: this.state.project.purcharge_price_surcharge
				? expense.price * this.state.quantity * (this.state.project.purcharge_price_surcharge / 100 + 1)
				: 0,
		});
	}

	getChangeHandler(key) {
		return (value) => {
			this.state[key] = value;

			if ((key === "quantity" || key === "unit_price") && this.state.quantity && this.state.unit_price) {
				this.state.amount = this.state.quantity * this.state.unit_price;
				this.state.amount_to_invoice = this.state.amount * (this.state.surcharge / 100 + 1);
			} else if (key === "amount") {
				this.state.amount_to_invoice = (parseFloat(this.state.amount) * (this.state.surcharge / 100 + 1)).toFixed(0);
			} else if (key === "surcharge") {
				this.state.amount_to_invoice = this.state.surcharge ? ((parseFloat(this.state.surcharge) / 100 + 1) * this.state.amount).toFixed(0) : 0;
			} else if (key === "amount_to_invoice") {
				this.state.surcharge = ((parseFloat(this.state.amount_to_invoice) / this.state.amount - 1) * 100).toFixed(0);
			}

			this.setState({ ...this.state });
		};
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title="Rapportera utgift"
				secondaryActions={[
					{
						content: "Close",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: "Rapportera",
					disabled: !this.state.amount,
					loading: this.state.saving,
					onAction: this.saveReport.bind(this),
				}}
			>
				{!this.state.lock_user ? (
					<Modal.Section>
						<ResourcePicker
							label="Person"
							placeholder="Sök person"
							caption="Välj person"
							resource="users"
							resourceName={{
								singular: "användare",
								plural: "användare",
							}}
							item={this.state.user_id ? { id: this.state.user_id, name: this.state.user_name } : null}
							onChange={this.changeUser.bind(this)}
							label_handle="name"
						/>
					</Modal.Section>
				) : null}
				<Modal.Section>
					<FormLayout>
						<ResourcePicker
							label="Utgift"
							placeholder="Sök artikel"
							caption="Välj artikel"
							resource="expenses"
							params={{
								project_id: this.state.project.id,
								// work_order_id: this.state.work_order_id,
							}}
							resourceName={{
								singular: "artikel",
								plural: "artiklar",
							}}
							item={this.state.expense}
							onChange={this.changeExpense.bind(this)}
							label_handle="title"
						/>
						{this.state.expense && (
							<Stack distribution="fillEvenly">
								<TextField label="Antal" type="number" value={this.state.quantity} onChange={this.getChangeHandler("quantity")} />
								<TextField
									label={"Pris per " + this.state.expense.unit}
									type="currency"
									suffix="kr"
									value={this.state.unit_price}
									onChange={this.getChangeHandler("unit_price")}
								/>
								<TextField label="Total kostnad" value={this.state.amount} onChange={this.getChangeHandler("amount")} suffix="kr" />

								<TextField label="Påslag %" value={this.state.surcharge} suffix="%" onChange={this.getChangeHandler("surcharge")} />

								<TextField
									label="Att vidarefakturera"
									type="currency"
									suffix="kr"
									value={this.state.amount_to_invoice}
									onChange={this.getChangeHandler("amount_to_invoice")}
								/>
							</Stack>
						)}
						{this.state.expense && (
							<TextField
								multiLine
								label="Kommentar"
								value={this.state.comment}
								onChange={(value) => {
									this.setState({ comment: value });
								}}
							/>
						)}
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default ExpenseReportModal;

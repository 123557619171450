/* eslint-disable react/jsx-props-no-spreading */
import { ActionList, ActionListItemDescriptor, Button, MenuGroupDescriptor, Popover } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import ActionGroup from "./ActionGroup";
import { SecondaryAction } from "./Page";
import ActionButton from "./ActionButton";

type ActionsBarProps = {
	secondaryActions?: SecondaryAction[];
	actionGroups?: (MenuGroupDescriptor & {
		enabled?: boolean;
		actions?: (SecondaryAction | ActionListItemDescriptor)[];
	})[];
	style?: any;
};

type HiddenStateType = { [key: string | number]: boolean };

const ActionsBar = ({ secondaryActions, actionGroups, style }: ActionsBarProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [hiddenState, setHiddenState] = useState<HiddenStateType>({});

	const items = useMemo(() => {
		return [...(secondaryActions || []), ...(actionGroups || []).map((group) => ({ ...group, isGroup: true }))].filter((a) => {
			if (a.isGroup || a.actions) return "enabled" in a ? a.enabled : a.actions?.filter((i) => !("enabled" in i) || i.enabled)?.length;

			return !("enabled" in a) || a.enabled;
		});
	}, [secondaryActions, actionGroups]);

	const handleIntersection = useCallback((entries) => {
		setHiddenState((c) =>
			entries.reduce(
				(acc: HiddenStateType, entry: any) => {
					if (!entry.target.dataset.targetid) return acc;
					return {
						...acc,
						[entry.target.dataset.targetid]: entry.intersectionRatio < 1,
					};
				},

				c
			)
		);
	}, []);

	const observer = useMemo<IntersectionObserver>(() => {
		return new IntersectionObserver(handleIntersection, {
			root: ref.current,
			threshold: [0, 0.5, 1],
		});
	}, [handleIntersection]);

	useEffect(() => {
		// console.log("--useEffect:");
		// Wondering if the element is being removed from the DOM and then re-added, if that would cause the observer to not be observing it anymore
		// if the elements are just being updated, then it should be fine
		// but if more elements are added or removed, then the observer should be updated

		if (ref.current) {
			Array.from(ref.current.children)?.forEach((element: any) => {
				if (element.dataset.targetid) {
					element.classList.add("observed");
					observer.observe(element);
				} else if (element.children?.[0]?.dataset?.targetid) {
					element.children[0].classList.add("observed");
					observer.observe(element.children[0]);
				}
			});
		}

		return () => {
			observer?.disconnect();
		};
	}, [items?.length, observer]);
	// }, [ref.current, items, observer]);
	// }, [ref.current, ref.current?.children?.length, observer]);

	if (!items?.length) return null;

	const hiddenItems = items.filter((item: any, index) => hiddenState[item.isGroup ? `group-${index}` : `button-${index}`]);

	return (
		<Wrapper style={style}>
			<InnerWrapper ref={ref}>
				{items?.map((item: any, index) => {
					if (item.isGroup || item.actions) {
						return (
							<Item data-targetid={`group-${index}`} key={item.id || index} className={hiddenState[`group-${index}`] ? "hidden" : "visible"}>
								<ActionGroup {...item} key={item.title} data-targetid={item.title} />
							</Item>
						);
					} else {
						return (
							<Item data-targetid={`button-${index}`} key={item.id || index} className={hiddenState[`button-${index}`] ? "hidden" : "visible"}>
								<ActionButton item={item} />
							</Item>
						);
					}
				})}
			</InnerWrapper>
			<OverflowMenu items={hiddenItems} />
		</Wrapper>
	);
};
export default ActionsBar;

const OverflowMenu = ({ items }) => {
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	const handleToggle = () => {
		setOpen((c) => !c);
	};

	if (!items?.length) return null;

	const actionItems = items.filter((item) => !item.isGroup);
	const sectionItems = items.filter((item) => item.isGroup).map((item) => ({ ...item, items: item.actions }));

	return (
		<Popover
			active={open}
			activator={
				<OverflowButton>
					<Button onClick={handleToggle} plain removeUnderline icon={MobileVerticalDotsMajor} />
				</OverflowButton>
			}
			autofocusTarget="first-node"
			onClose={handleClose}
		>
			<PopoverInnerWrapper>
				<ActionList onActionAnyItem={handleClose} actionRole="menuitem" items={actionItems} sections={sectionItems} />
			</PopoverInnerWrapper>
		</Popover>
	);
};

const Wrapper = styled.div`
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: flex;
	gap: 2rem;
	position: relative;
	margin-right: 2rem;

	img {
		width: 20px;
		height: 20px;
		fill: #ffffff;
	}
`;
const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	gap: 2rem;
	position: relative;
	/* width: 100%; */

	& > * {
		min-width: fit-content;
	}

	.Polaris-Button:active .Polaris-Button__Content {
		background-color: transparent;
	}

	.Polaris-Button__Text {
		font-weight: 600;
	}

	.Polaris-Button--plain {
		&,
		&:hover,
		&:focus {
			background-color: transparent;
		}
	}
	.Polaris-Button--plain.Polaris-Button--destructive {
		color: var(--p-interactive-critical);

		.Polaris-Icon svg {
			fill: var(--p-interactive-critical);
		}
	}

	.Polaris-Button--plain.Polaris-Button--primary {
		color: var(--accent);

		.Polaris-Icon svg {
			fill: var(--accent);
		}
	}
`;

const Item = styled.div.attrs<{ visible: boolean }>(() => {})`
	/* order: 0; */

	&.hidden {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
		/* order: 100; */
	}
`;

const OverflowButton = styled.div`
	position: absolute;
	right: -2rem;
	top: 0;
`;

const PopoverInnerWrapper = styled.div`
	.Polaris-ActionList__Title {
		/* text-align: center; */
		padding-top: 1.2rem;
	}

	.Polaris-Button {
		--p-color-icon-interactive: #ffffff;
		--p-color-icon-interactive-hover: var(--accent);
	}
`;

import { Icon } from "@shopify/polaris";
import { CircleCancelMinor, SearchMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import MainMenuSectionActions from "./MainMenuSectionActions";

class MainMenuSectionSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange(e) {
		this.props.onChange(e.target.value || "");
	}

	render() {
		const { actions } = this.props;
		return (
			<div className="mainmenu-item__secondary__search-actions_wrapper">
				<div className="Polaris-TopBar-SearchField mainmenu-item__secondary__searchbar" style={this.props.style}>
					<input
						value={this.props.value}
						onChange={this.onChange.bind(this)}
						autoFocus
						placeholder="Sök…"
						className="Polaris-TopBar-SearchField__Input"
					/>
					<span className="Polaris-TopBar-SearchField__Icon">
						<Icon source={SearchMinor} />
					</span>
					{this.props.value ? (
						<button type="button" aria-label="" className="Polaris-TopBar-SearchField__Clear" onClick={this.onChange.bind(this)}>
							<Icon source={CircleCancelMinor} />
						</button>
					) : null}
					<div className="Polaris-TopBar-SearchField__Backdrop" />
				</div>
				{actions && actions.length && <MainMenuSectionActions actions={actions} />}
			</div>
		);
	}
}
export default MainMenuSectionSearch;

/* eslint-disable react/jsx-props-no-spreading */
import { store } from "src/js/store";
import React, { useLayoutEffect, useState } from "react";
import { Checkbox, Page } from "@shopify/polaris";
import Card from "src/js/components/Card";
import TimeReportsIndex from "../../TimeReports";
import SupplierInvoicesIndex from "../../SupplierInvoices";
import SupplierInvoicesRowsIndex from "../../SupplierInvoices/SupplierInvoicesRowsIndex";
import ExpenseReportsIndex from "../../ExpenseReports";
import QuoteIndex from "../../Quotes";
import QuoteRowsIndex from "../../Quotes/QuoteRowsIndex";
import FortnoxOffersIndex from "../../Fortnox/Offers/List/FortnoxOffersIndex";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import Modal from "src/js/components/modal";

type InvoiceBasisViewsProps = {
	tab: any;
	history: any;
	location: any;
	onClose?: () => void;
	onChange?: (data: any) => void;
	invoice: any;
	selectOnRow?: boolean;
	project?: any;
};

const InvoiceBasisViews = ({
	tab,
	history,
	location,
	onClose,
	onChange,
	invoice,
	selectOnRow = true,
	project: propsProject,
}: InvoiceBasisViewsProps) => {
	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [options, setOptions] = useState<any>({});
	const [selectedOffer, setSelectedOffer] = useState<FortnoxOfferType | null>(null);
	const [isLoadingCreatingInvoiceFromOffer, setIsLoadingCreatingInvoiceFromOffer] = useState(false);

	const handleCreateInvoiceFromOffer = async () => {
		try {
			setIsLoadingCreatingInvoiceFromOffer(true);
			const result = await API.put(`/api/fortnox/offers/${selectedOffer!.DocumentNumber}/invoice.json`);
			const invoice = result.data;
			if (invoice.DocumentNumber) {
				toastr.success("Faktura skapad");
				history.push(`/admin/invoices/${invoice.DocumentNumber}`);
			}

			return result;
		} catch (error) {
			toastr.error(error);
		} finally {
			setIsLoadingCreatingInvoiceFromOffer(false);
		}
	};

	useLayoutEffect(() => {
		switch (tab?.id) {
			case "time_reports":
				setOptions({ group: true });
				break;

			default:
				setOptions({});
				break;
		}

		setSelectedItems([]);
	}, [tab?.id]);

	const getOptionsChangeHandler = (key) => (value) => {
		setOptions((prev) => ({ ...prev, [key]: value }));
	};

	const project = invoice?.project || propsProject;

	const getSharedProps = (tab) => ({
		history,
		location,
		titleHidden: selectOnRow,
		onSelectionChangeInclRow: (items) => {
			if (tab?.id === "supplierinvoices" && !options.displayRows) {
				setSelectedItems(items?.flatMap((item) => item.supplier_invoice_rows));
				return;
			}
			setSelectedItems(items);
		},
		project,
		selectOnRow,
		fixedParams: {
			project_id: project?.id,
			is_not_invoiced_and_should_be_invoiced: 1,
			// invoiced: 0, // deprecated

			exclude_row_ids: (() => {
				if ("displayRows" in options && !options?.displayRows) {
					return invoice?.InvoiceRows?.flatMap((row) => row[tab?.id])
						?.map((t) => t?.id)
						.filter(Boolean);
				}
			})(),
			exclude_ids: (() => {
				if (!("displayRows" in options) || options?.displayRows) {
					return invoice?.InvoiceRows?.flatMap((row) => row[tab?.id])
						?.map((t) => t?.id)
						.filter(Boolean);
				}

				// switch (tab?.id) {
				// 	case "time_reports":
				// 		return invoice?.InvoiceRows?.flatMap((row) => row.time_reports)
				// 			?.map((t) => t?.id)
				// 			.filter(Boolean);
				// 	case "supplier_invoice_rows":
				// 		return invoice?.InvoiceRows?.flatMap((row) => row.supplier_invoice_rows)
				// 			?.map((t) => t?.id)
				// 			.filter(Boolean);
				// 	case "expense_reports":
				// 		return invoice?.InvoiceRows?.flatMap((row) => row.expense_reports)
				// 			?.map((t) => t?.id)
				// 			.filter(Boolean);
				// 	default:
				// 		return [];
				// }
			})(),
		},
	});

	const content = (() => {
		switch (tab?.id) {
			case "time_reports":
				return (
					<TimeReportsIndex
						{...getSharedProps(tab)}
						types={["project", "work_order"]}
						extraHeader={
							selectOnRow && (
								<Card sectioned style={{ marginBottom: "1rem" }}>
									<Checkbox label="Gruppera" checked={options.group} onChange={getOptionsChangeHandler("group")} />
								</Card>
							)
						}
					/>
				);
			case "supplier_invoice_rows": {
				const header = (
					<Card sectioned style={{ marginBottom: "1rem" }}>
						<Checkbox label="Visa rader" checked={options.displayRows} onChange={getOptionsChangeHandler("displayRows")} />
					</Card>
				);

				if (options?.displayRows) {
					return <SupplierInvoicesRowsIndex {...getSharedProps(tab)} extraHeader={header} />;
				}
				return <SupplierInvoicesIndex {...getSharedProps(tab)} extraHeader={header} />;
			}
			case "expense_reports":
				return (
					<ExpenseReportsIndex
						{...getSharedProps(tab)}
						extraHeader={
							selectOnRow && (
								<Card sectioned style={{ marginBottom: "1rem" }}>
									<Checkbox label="Gruppera" checked={options.group} onChange={getOptionsChangeHandler("group")} />
								</Card>
							)
						}
					/>
				);
			case "quote_rows": {
				if (store.getState().account.fortnox_order_module) {
					return (
						<>
							<FortnoxOffersIndex
								project={project}
								history={history}
								fixedParams={{
									project: project?.reference,
									filter: "ordernotcreated",
									loading: isLoadingCreatingInvoiceFromOffer,
								}}
								onClickRow={(item) => {
									setSelectedOffer(item);
								}}
							/>

							<Modal
								title="Skapa faktura"
								open={!!selectedOffer}
								onClose={() => setSelectedOffer(null)}
								sectioned
								primaryAction={{
									content: "Skapa faktura",
									onAction: handleCreateInvoiceFromOffer,
									loading: isLoadingCreatingInvoiceFromOffer,
								}}
								secondaryActions={[
									{
										content: "Stäng",
										onAction: () => setSelectedOffer(null),
									},
								]}
							>
								<p>Är du säker på att du vill skapa en faktura ifrån offert #{selectedOffer?.DocumentNumber} ?</p>
							</Modal>
						</>
					);
				}

				const header = (
					<Card sectioned style={{ marginBottom: "1rem" }}>
						<Checkbox label="Visa rader" checked={options.displayRows} onChange={getOptionsChangeHandler("displayRows")} />
					</Card>
				);

				if (options?.displayRows) {
					return <QuoteRowsIndex {...getSharedProps(tab)} extraHeader={header} />;
				}
				return <QuoteIndex {...getSharedProps(tab)} extraHeader={header} />;
			}
			default:
				return <p>none found</p>;
		}
	})();

	const handleSave = () => {
		onChange?.({ key: tab.id, items: selectedItems, options });
		onClose?.();
	};

	return (
		<Page
			title={`Välj "${tab?.content}"`}
			titleHidden={!selectOnRow}
			fullWidth
			backAction={
				onClose && {
					content: "Tillbaka",
					onAction: onClose,
				}
			}
			primaryAction={
				onChange && {
					content: "Spara",
					onAction: handleSave,
					disabled: !selectedItems.length,
				}
			}
		>
			{content}
		</Page>
	);
};
export default InvoiceBasisViews;

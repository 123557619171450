import { Component } from "react";
import { Page, PageActions, Layout, Card, Badge, Modal, FormLayout, TextField, Banner } from "@shopify/polaris";
import { toastr } from "../../components/toastr";

import SkeletonProvider from "../../components/skeleton_provider.js";
import API from "../../API";

class ExpenseEdit extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, saving: false, loading: true };
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/expenses/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.expense });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/expenses/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Utgift ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	removeItem() {
		this.setState({ saving: true });
		API.delete("/api/expenses/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Specialtid borttagen");
				this.props.history.replace("/admin/special_times");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		if (!this.state.form) {
			return <Banner title="Kunde inte hitta tid" status="critical" />;
		}

		return (
			<Page
				title={this.state.form.title}
				titleMetadata={this.state.form.favorite ? <Badge status="success">Favorit</Badge> : null}
				backAction={{ content: "Specialtider", url: "/admin/special_times" }}
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Information" description="Fyll i uppgifter som namn här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort specialtid?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeItem.bind(this),
					}}
				>
					<Modal.Section>Är du säker? Alla registreringar kopplade till den här användaren kommer att tas bort</Modal.Section>
				</Modal>
				<PageActions
					primaryAction={{
						content: "Spara",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
					secondaryActions={[
						{
							content: "Ta bort",
							destructive: true,
							loading: this.state.saving,
							onAction: this.removeModal.bind(this),
						},
					]}
				/>
			</Page>
		);
	}
}

export default ExpenseEdit;

import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Badge, FormLayout, TextField } from "@shopify/polaris";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ExpenseCreate extends Component {
	constructor(props) {
		super(props);
		this.state = { saving: false, form: { title: "", unit: "tim", favorite: true, time: true, supplier: "", sku: "", price: 0, purchase_price: 0 } };
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/expenses.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Specialtid skapades");
				this.props.history.replace("/admin/special_times/" + result.data.expense.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<Page
				title="Ny specialtid"
				titleMetadata={this.state.form.favorite ? <Badge status="success">Favorit</Badge> : null}
				backAction={{ content: "Specialtider", url: "/admin/special_times" }}
				primaryAction={{ content: "Skapa specialtid", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Information" description="Fyll i uppgifter som namn här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: "Skapa specialtid",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default ExpenseCreate;

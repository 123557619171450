import { Modal } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

type Image = {
	url: string;
	[key: string]: any;
};
type ImageCarousel = {
	images: Image[];
	open: boolean;
	index?: number;
	title?: string;
	onClose: () => void;
};

const ImageCarousel = ({ images, open, onClose, index, title }: ImageCarousel) => {
	const imagesArray = images.map((image) => ({
		original: image.url,
		thumbnail: image.url,
	}));

	return (
		<Modal open={open} onClose={onClose} title={title || "Bilder"} large>
			<Modal.Section>
				<Wrapper>
					<ImageGallery
						items={imagesArray}
						showBullets
						showIndex
						startIndex={index}
						onClick={(e) => {
							try {
								const url = e.target.attributes.src.value;
								window.open(url, url);
							} catch (e) {
								console.error("Failed to open image", e);
							}
						}}
					/>
				</Wrapper>
			</Modal.Section>
		</Modal>
	);
};
export default ImageCarousel;
const Wrapper = styled.div`
	&&& {
		.image-gallery-content .image-gallery-slide .image-gallery-image {
			cursor: pointer;
			max-height: calc(100vh - 250px);
		}
	}
`;

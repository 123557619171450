import { Component } from "react";
import Money from "../../components/Money.js";
import IndexTable from "../../components/IndexTable";

class FortnoxExpenseIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderCell(item, column) {
		switch (column.handle) {
			case "ArticleNumber":
				return <b>{item.ArticleNumber}</b>;
			case "Description":
				return item.Description;
			case "SalesPrice":
				return (
					<>
						<Money amount={item.SalesPrice * 100} currency="SEK" />/ {item.Unit}
					</>
				);
			case "PurchasePrice":
				return <Money amount={item.PurchasePrice * 100} currency="SEK" />;
			default:
				return null;
		}
	}

	render() {
		var filters = [
			{
				key: "favorite",
				label: "Favorit",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				label: "Artikelnummer",
				handle: "ArticleNumber",
				sortable: true,
			},
			{
				label: "Beskrivning",
				handle: "Description",
			},
			{
				label: "Pris",
				handle: "SalesPrice",
			},
			{
				label: "Inköpspris",
				handle: "PurchasePrice",
			},
		];

		return (
			<div>
				<IndexTable
					columns={columns}
					history={this.props.history}
					onClickRow={(item) => {
						this.props.history.push("/admin/expenses/" + item.ArticleNumber);
					}}
					defaultSort="articlenumber_descending"
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Artikelregister"
					primaryAction={{
						content: "Ny artikel",
						onAction: () => {
							this.props.history.push("/admin/expenses/new");
						},
					}}
					savedSearchHandle="expenses"
					resourceUrl="/api/fortnox/articles"
					resourceHandle="expenses"
					resourceName={{
						singular: "artikel",
						plural: "artiklar",
					}}
					renderCell={this.renderCell.bind(this)}
					filters={filters}
				/>
			</div>
		);
	}
}

export default FortnoxExpenseIndex;

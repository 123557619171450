/* eslint-disable max-classes-per-file */
import { Modal, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import $ from "jquery";

class NameModal extends Component {
	constructor(props) {
		super(props);
		this.state = { name: "" };
	}

	onSave() {
		this.props.onSave(this.state.name);
	}

	render() {
		return (
			<Modal
				title="Ange namn för pdf"
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[
					{
						content: "Stäng",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: "Spara",
					disabled: !this.state.name,
					loading: this.props.saving,
					onAction: this.onSave.bind(this),
				}}
			>
				<Content name={this.state.name} onChange={(name) => this.setState({ name })} />
			</Modal>
		);
	}
}

class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		$(".pdf-name__modal").closest("[data-portal-id]").addClass("modal-top");
	}

	render() {
		const { onChange, name } = this.props;
		return (
			<div className="pdf-name__modal">
				<Modal.Section>
					<TextField value={name} onChange={onChange} />
				</Modal.Section>
			</div>
		);
	}
}

export default NameModal;

import React from "react";

const DocumentIcon = ({ color = "var(--color-primary)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.65415 4.07736C6.02922 3.70229 6.53793 3.49158 7.06836 3.49158H19.0684C19.3336 3.49158 19.5879 3.59693 19.7755 3.78447L26.7755 10.7845C26.963 10.972 27.0684 11.2264 27.0684 11.4916V27.4916C27.0684 28.022 26.8576 28.5307 26.4826 28.9058C26.1075 29.2809 25.5988 29.4916 25.0684 29.4916H7.06836C6.53793 29.4916 6.02922 29.2809 5.65415 28.9058C5.27907 28.5307 5.06836 28.022 5.06836 27.4916V5.49158C5.06836 4.96114 5.27907 4.45244 5.65415 4.07736ZM18.6541 5.49158L7.06836 5.49158L7.06836 27.4916H25.0684V11.9058L18.6541 5.49158Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.0684 3.49158C19.6206 3.49158 20.0684 3.93929 20.0684 4.49158V10.4916H26.0684C26.6206 10.4916 27.0684 10.9393 27.0684 11.4916C27.0684 12.0439 26.6206 12.4916 26.0684 12.4916H19.0684C18.5161 12.4916 18.0684 12.0439 18.0684 11.4916V4.49158C18.0684 3.93929 18.5161 3.49158 19.0684 3.49158Z"
			fill={color}
		/>
	</svg>
);

export default DocumentIcon;

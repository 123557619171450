import React, { Component } from "react";

class Table extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	fixWdiths() {
		if (this.ref.current) {
			Array.from(this.ref.current.querySelectorAll("th")).forEach((th) => {
				const tds = this.ref.current.querySelectorAll("td");
				if (tds)
					[...(tds || [])].forEach((td) => {
						td.style.width = `${th.clientWidth}px`;
					});
			});
		}
	}

	render() {
		// this.fixWdiths();

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<table ref={this.ref} className={`table_wrapper ${this.props.className || ""}`} {...this.props}>
				{this.props.children}
			</table>
		);
	}
}
export default Table;

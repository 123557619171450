import { Component } from "react";
import { Modal, DatePicker, ChoiceList, Checkbox, FormLayout, Tag, Stack, Select, TextField } from "@shopify/polaris";
import moment from "moment";
import { store } from "../store";
import SearchField from "./search_field.js";

class TimeRangeModal extends Component {
	constructor(props) {
		super(props);

		var viewDate = new Date();
		viewDate.setMonth(viewDate.getMonth() - 1);

		this.state = {
			attest: false,
			includeUE: true,
			userGroupSelection: "all",
			currentWeek: this.currentWeek(new Date()) + "",
			custom: false,
			projects: [],
			groups: [],
			users: [],
			// eslint-disable-next-line react/no-unused-state
			caption: "Idag",
			temporaryCaption: "",
			month: viewDate.getMonth(),
			year: viewDate.getFullYear(),
			selected: {
				start: props.from,
				end: props.to,
			},
			from: this.getYmd(props.from),
			to: this.getYmd(props.to),
			selectedValue: "today",
			extraReportSelection: [],
			exportOnlyNew: this.props.exportonlynew,
		};
	}

	componentDidMount() {
		if (this.props.weeks) {
			this.handleWeekChange(this.state.currentWeek);
		}
	}

	componentWillReceiveProps(props) {
		if (props.open != this.props.open) {
			this.setState({ extraReportSelection: [] });
		}
	}

	isValidDate(d) {
		return d instanceof Date && !Number.isNaN(d);
	}

	updateFromDate(value) {
		this.setState({ from: value });

		var from = new Date(value);
		var to = new Date(this.state.to);

		if (this.isValidDate(from) && this.isValidDate(to)) {
			this.setRange(from, to, false);
			this.setState({ custom: true, selectedValue: "custom", temporaryCaption: "custom" });
		}
	}

	updateToDate(value) {
		this.setState({ to: value });

		var from = new Date(this.state.from);
		var to = new Date(value);

		if (this.isValidDate(from) && this.isValidDate(to)) {
			this.setRange(from, to, false);
			this.setState({ custom: true, selectedValue: "custom", temporaryCaption: "custom" });
		}
	}

	handleSelectChange(value) {
		if (value == "today") {
			this.setRange(new Date(), new Date());
			this.setState({ temporaryCaption: "Today" });
		} else if (value == "this_week") {
			const curr = new Date();
			const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
			const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

			this.setRange(firstday, lastday);
			this.setState({ temporaryCaption: "Denna veckan" });
		} else if (value == "last_week") {
			const curr = new Date();
			const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
			firstday.setDate(firstday.getDate() - 7);
			const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
			lastday.setDate(lastday.getDate() - 7);

			this.setRange(firstday, lastday);

			this.setState({ temporaryCaption: "Förra veckan" });
		} else if (value == "this_month") {
			const x = new Date();
			x.setDate(1);
			x.setMonth(x.getMonth());

			const y = new Date();
			y.setMonth(y.getMonth() + 1);
			y.setDate(0);
			this.setRange(x, y);
			this.setState({ temporaryCaption: "Denna månaden" });
		} else if (value == "last_month") {
			const x = new Date();
			x.setDate(1);
			x.setMonth(x.getMonth() - 1);

			const y = new Date();
			y.setDate(0);
			this.setRange(x, y);
			this.setState({ temporaryCaption: "Förra månaden" });
		} else if (value == "all_time") {
			const x = new Date("January 1, 2000 00:00:00");

			const y = new Date();
			this.setRange(x, y);
			this.setState({ temporaryCaption: "All tid" });
		} else {
			const from = moment().locale("sv").set("week", value).startOf("week").toDate();
			const to = moment().locale("sv").set("week", value).endOf("week").toDate();
			this.setRange(from, to);
			this.setState({ temporaryCaption: "Custom" });
		}
		this.setState({ selectedValue: value, custom: false });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	setRange(from, to, updInput) {
		var selected = {
			start: from,
			end: to,
		};

		if (updInput === false) {
			this.setState({ selected });
		} else {
			this.setState({ from: this.getYmd(from), to: this.getYmd(to), selected });
		}
	}

	handleRangeChange(value) {
		this.setRange(value.start, value.end);
		this.setState({ custom: true, selectedValue: "custom", temporaryCaption: "custom" });
	}

	handleMonthChange(month, year) {
		this.setState({
			month,
			year,
		});
	}

	changeRange() {
		// eslint-disable-next-line react/no-unused-state
		this.setState({ caption: this.state.temporaryCaption, currentFromDate: this.state.selected.start, currentToDate: this.state.selected.end });

		if (this.props.project) {
			this.props.onChange(
				this.state.projects,
				this.state.selected.start,
				this.state.selected.end,
				this.state.extraReportSelection,
				this.state.includeUE,
				this.state.attest
			);
		} else if (this.props.usergroup) {
			if (this.state.userGroupSelection == "groups") {
				this.props.onChange(
					this.state.groups,
					[],
					this.state.selected.start,
					this.state.selected.end,
					this.state.extraReportSelection,
					this.state.exportOnlyNew,
					this.state.attest
				);
			} else if (this.state.userGroupSelection == "users") {
				this.props.onChange(
					[],
					this.state.users,
					this.state.selected.start,
					this.state.selected.end,
					this.state.extraReportSelection,
					this.state.exportOnlyNew,
					this.state.attest
				);
			} else {
				this.props.onChange(
					[],
					[],
					this.state.selected.start,
					this.state.selected.end,
					this.state.extraReportSelection,
					this.state.exportOnlyNew,
					this.state.attest
				);
			}
		} else {
			this.props.onChange(this.state.selected.start, this.state.selected.end, this.state.extraReportSelection, this.state.attest);
		}
	}

	addProject(item) {
		this.state.projects.push(item);
		this.setState({ projects: this.state.projects });
	}

	removeProject(index) {
		this.state.projects.splice(index, 1);
		this.setState({ projects: this.state.projects });
	}

	currentWeek(d) {
		return moment(d).isoWeek();
	}

	addGroup(item) {
		this.state.groups.push(item);
		this.setState({ groups: this.state.groups });
	}

	removeGroup(index) {
		this.state.groups.splice(index, 1);
		this.setState({ groups: this.state.groups });
	}

	addUser(item) {
		this.state.users.push(item);
		this.setState({ users: this.state.users });
	}

	removeUser(index) {
		this.state.users.splice(index, 1);
		this.setState({ users: this.state.users });
	}

	handleWeekChange(weekNum) {
		var now = new Date();
		var currentWeek = this.currentWeek(now);
		this.setState({ currentWeek: weekNum + "" });

		var diff = parseInt(currentWeek) - parseInt(weekNum);

		var curr = new Date();
		var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
		firstday.setDate(firstday.getDate() - diff * 7);
		var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
		lastday.setDate(lastday.getDate() - diff * 7);

		this.setRange(firstday, lastday);
	}

	render() {
		const { month, year, selected } = this.state;

		let options = [
			{ label: "Idag", value: "today" },
			{ label: "Denna veckan", value: "this_week" },
			{ label: "Förra veckan", value: "last_week" },
			{ label: "Denna månaden", value: "this_month" },
			{ label: "Förra månaden", value: "last_month" },
			{ label: "All tid", value: "all_time" },
		];

		if (this.props.weeks) {
			options = [];
			var now = new Date();
			var currentWeek = this.currentWeek(now);

			for (var week = currentWeek; week >= 1; week--) {
				options.push({ label: "Vecka " + week, value: week + "" });
			}
		}

		if (this.state.custom) {
			options.unshift({ label: "Egen", value: "custom" });
		}

		var reportOptions = [
			/* These values does not work in backend. { label: "Kommentarer", value: "comments" }, */
			{ label: "Utgifter/specialtider", value: "expenses" },
		];

		if (this.props.project) {
			// reportOptions.push({label: 'Användare', value: 'users'});
		} else {
			/* reportOptions.push({ label: "Projekt", value: "projects" });
			reportOptions.push({ label: "In- & utstämplingar", value: "timestamps" });
			reportOptions.push({ label: "Resor till/från jobb", value: "mileage" }); */
		}

		return (
			<Modal
				title="Välj tidsperiod"
				open={this.props.open}
				onClose={this.props.onClose}
				primaryAction={{
					content: "Välj",
					loading: this.props.loading,
					disabled:
						(this.props.project && this.state.projects.length < 1) ||
						(this.props.usergroup && this.state.userGroupSelection == "users" && this.state.users.length < 1) ||
						(this.props.usergroup && this.state.userGroupSelection == "groups" && this.state.groups.length < 1),
					onAction: this.changeRange.bind(this),
				}}
				secondaryActions={[
					{
						content: "Stäng",
						onAction: this.props.onClose,
					},
				]}
			>
				{this.props.project ? (
					<Modal.Section>
						<SearchField
							placeholder="Välj önskade projekt"
							resource="projects"
							resourceName={{
								singular: "projekt",
								plural: "projekt",
							}}
							onSelect={this.addProject.bind(this)}
						/>
						<Stack>
							{this.state.projects.map((project, index) => (
								<Tag key={project.id || index} onRemove={this.removeProject.bind(this, index)}>
									{project.title}
								</Tag>
							))}
						</Stack>
					</Modal.Section>
				) : null}
				{this.props.project && store.getState().account.has_underentreprenorer ? (
					<Modal.Section>
						<Checkbox
							checked={this.state.includeUE}
							onChange={(value) => {
								this.setState({ includeUE: value });
							}}
							label="Inkludera registreringar från underentreprenörer"
						/>
					</Modal.Section>
				) : null}
				{!this.props.noreport ? (
					<Modal.Section>
						<ChoiceList
							allowMultiple
							title="Extra rapporter"
							choices={reportOptions}
							selected={this.state.extraReportSelection}
							onChange={(value) => {
								this.setState({ extraReportSelection: value });
							}}
						/>
					</Modal.Section>
				) : null}
				{this.props.usergroup ? (
					<Modal.Section>
						<ChoiceList
							title="Vilka användare"
							choices={[
								{ label: "Alla användare", value: "all" },
								{
									label: "Utvalda användare",
									value: "users",
									renderChildren: (isSelected) =>
										isSelected && (
											<div>
												<SearchField
													placeholder="Välj önskade användare"
													resource="users.json?enabled=1"
													resourceHandle="users"
													resource_handle="users"
													resourceName={{
														singular: "användare",
														plural: "användare",
													}}
													label_handle="name"
													onSelect={this.addUser.bind(this)}
												/>
												<Stack>
													{this.state.users.map((user, index) => (
														<Tag key={user.id || index} onRemove={this.removeUser.bind(this, index)}>
															{user.name}
														</Tag>
													))}
												</Stack>
											</div>
										),
								},
								{
									label: "Utvalda grupper",
									value: "groups",
									renderChildren: (isSelected) =>
										isSelected && (
											<div>
												<SearchField
													placeholder="Välj önskade grupper"
													resource="groups"
													resourceName={{
														singular: "grupp",
														plural: "grupper",
													}}
													label_handle="name"
													onSelect={this.addGroup.bind(this)}
												/>
												<Stack>
													{this.state.groups.map((group, index) => (
														<Tag key={group.id || index} onRemove={this.removeGroup.bind(this, index)}>
															{group.name}
														</Tag>
													))}
												</Stack>
											</div>
										),
								},
							]}
							selected={this.state.userGroupSelection}
							onChange={(value) => {
								this.setState({ userGroupSelection: value });
							}}
						/>
					</Modal.Section>
				) : null}
				{!this.props.timestamp ? (
					<Modal.Section>
						<FormLayout>
							<Select label="Period" options={options} onChange={this.handleSelectChange.bind(this)} value={this.state.selectedValue} />
							<Stack distribution="fillEvenly">
								<TextField label="Från" onChange={this.updateFromDate.bind(this)} value={this.state.from} />
								<TextField label="Till" onChange={this.updateToDate.bind(this)} value={this.state.to} />
							</Stack>
							<DatePicker
								month={month}
								year={year}
								weekStartsOn={1}
								onChange={this.handleRangeChange.bind(this)}
								onMonthChange={this.handleMonthChange.bind(this)}
								selected={selected}
								allowRange
								multiMonth
							/>
						</FormLayout>
					</Modal.Section>
				) : null}
				{this.props.timestamp ? (
					<Modal.Section>
						<FormLayout>
							<Select label="Vecka" options={options} onChange={this.handleWeekChange.bind(this)} value={this.state.currentWeek} />
						</FormLayout>
					</Modal.Section>
				) : null}
				{this.props.exportonlynew ? (
					<Modal.Section>
						<FormLayout>
							<Checkbox
								checked={this.state.exportOnlyNew}
								onChange={(value) => {
									this.setState({ exportOnlyNew: value });
								}}
								label="Exportera endast registreringar som inte har exporterats"
							/>
						</FormLayout>
					</Modal.Section>
				) : null}

				{this.props.attest ? (
					<Modal.Section>
						<FormLayout>
							<Checkbox
								checked={this.state.attest}
								onChange={(value) => {
									this.setState({ attest: value });
								}}
								label="Attestera tider"
							/>
						</FormLayout>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}
export default TimeRangeModal;

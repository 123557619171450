import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Badge, Modal, FormLayout, Tag, TextField, Select, Stack, Checkbox } from "@shopify/polaris";
import { store } from "../../store";
import SearchField from "../../components/search_field.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ExpenseEdit extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, saving: false, loading: true, units: [], form: {} };
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
			if (store.getState().account.fortnox_connection) {
				this.fetchUnits();
			}
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		if (store.getState().account.fortnox_connection) {
			this.fetchUnits();
		}
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/expenses/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.expense || {} });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchUnits() {
		API.get("/api/fortnox/units").then((response) => {
			this.setState({ units: response.data.units });
		});
	}

	addTag(item) {
		this.state.form.tags.push(item);
		this.setState({ form: this.state.form });
	}

	removeTag(index) {
		this.state.form.tags.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/expenses/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Utgift ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	removeItem() {
		this.setState({ saving: true });
		API.delete("/api/expenses/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Artikel borttagen");
				this.props.history.replace("/admin/expenses");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error((error && error.response && error.resource.data && error.response.data.message) || "");
			});
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		return store.getState().account.fortnox_connection ? (
			<Page
				backAction={{ content: "Tillbaka", onAction: () => this.props.history.goBack() }}
				title={this.state.form.Description}
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Artikelinformation" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.Description} onChange={this.updateForm.bind(this, "Description")} />
									<TextField disabled label="Artnr" value={this.state.form.ArticleNumber} />
									<Select
										label="Enhet"
										value={this.state.form.Unit}
										options={this.state.units.map((unit) => ({ value: unit.Code, label: unit.Description }))}
										onChange={this.updateForm.bind(this, "Unit")}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Prisuppgifter" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField
										suffix="kr"
										type="currency"
										label="Inköpspris"
										value={this.state.form.PurchasePrice + ""}
										onChange={this.updateForm.bind(this, "PurchasePrice")}
									/>
									<TextField
										suffix="kr"
										type="currency"
										label="Pris"
										value={this.state.form.SalesPrice + ""}
										onChange={this.updateForm.bind(this, "SalesPrice")}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
			</Page>
		) : (
			<Page
				title={this.state.form.title}
				titleMetadata={this.state.form.favorite ? <Badge status="success">Favorit</Badge> : null}
				backAction={{ content: "Artikelregister", url: "/admin/expenses" }}
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Artikelinformation" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
									<TextField label="Artnr" value={this.state.form.sku} onChange={this.updateForm.bind(this, "sku")} />
									<TextField label="Leverantör" value={this.state.form.supplier} onChange={this.updateForm.bind(this, "supplier")} />
									<TextField label="Enhet" value={this.state.form.unit} onChange={this.updateForm.bind(this, "unit")} />
									<Checkbox
										checked={this.state.form.favorite}
										label="Favorit (syns som snabbval vid rapportering)"
										onChange={this.updateForm.bind(this, "favorite")}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
						<Card title="Taggar" subdued sectioned>
							<SearchField
								resource="expenses/tags"
								resourceName={{
									singular: "tagg",
									plural: "taggar",
								}}
								onSelect={this.addTag.bind(this)}
								tags
							/>
							{this.state.form.tags.length ? (
								<Stack>
									{this.state.form.tags.map((tag, index) => {
										return (
											<Tag key={tag.id || index} onRemove={this.removeTag.bind(this, index)}>
												{tag.title}
											</Tag>
										);
									})}
								</Stack>
							) : null}
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Prisuppgifter" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField
										suffix="kr"
										type="currency"
										label="Inköpspris"
										value={this.state.form.purchase_price + ""}
										onChange={this.updateForm.bind(this, "purchase_price")}
									/>
									<TextField
										suffix="kr"
										type="currency"
										label="Pris"
										value={this.state.form.price + ""}
										onChange={this.updateForm.bind(this, "price")}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort artikel?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeItem.bind(this),
					}}
				>
					<Modal.Section>Är du säker? Alla registreringar kopplade till den här användaren kommer att tas bort</Modal.Section>
				</Modal>
				<PageActions
					primaryAction={{
						content: "Spara",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
					secondaryActions={[
						{
							content: "Ta bort",
							destructive: true,
							loading: this.state.saving,
							onAction: this.removeModal.bind(this),
						},
					]}
				/>
			</Page>
		);
	}
}

export default ExpenseEdit;

import { debounce } from "lodash";
import CryptoJS from "crypto-js";
import { store } from "./store";
import API from "./API";

const data = {};

Object.defineProperty(String.prototype, "translateString", {
	value() {
		const handle = CryptoJS.SHA1(String(this));
		if (String(this) == "") {
			// eslint-disable-next-line no-throw-literal
			throw "empty string";
		}
		if (!store.getState().user.translation[handle]) {
			data[handle] = {
				string: String(this),
				translation: String(this),
				system: "web",
			};
			addUndefindString();
		}
		return store.getState().user.translation[handle] ? store.getState().user.translation[handle].translation : String(this);
	},
	enumerable: false,
});

const addUndefindString = debounce(() => {
	API.put("/api/language.json", data);
}, 100);

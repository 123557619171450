import React from "react";

const People4Icon = ({ color = "var(--textColor)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" filltype="stroke" fill="none">
		<path
			d="M16.0684 23.0837C18.8298 23.0837 21.0684 20.8452 21.0684 18.0837C21.0684 15.3223 18.8298 13.0837 16.0684 13.0837C13.3069 13.0837 11.0684 15.3223 11.0684 18.0837C11.0684 20.8452 13.3069 23.0837 16.0684 23.0837Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M24.5684 15.0838C25.733 15.0818 26.8821 15.352 27.9238 15.8729C28.9655 16.3938 29.8711 17.1508 30.5684 18.0838"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.56836 18.0838C2.26561 17.1508 3.17121 16.3938 4.21293 15.8729C5.25466 15.352 6.40368 15.0818 7.56836 15.0838"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.86816 27.5837C9.52669 26.2351 10.5508 25.0985 11.8237 24.3035C13.0967 23.5085 14.5673 23.0869 16.0682 23.0869C17.569 23.0869 19.0397 23.5085 20.3126 24.3035C21.5856 25.0985 22.6096 26.2351 23.2682 27.5837"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.56851 15.0837C6.80932 15.0845 6.06558 14.8692 5.4242 14.463C4.78282 14.0568 4.27029 13.4764 3.9465 12.7897C3.6227 12.103 3.50102 11.3384 3.59566 10.5851C3.6903 9.83183 3.99736 9.12104 4.48095 8.5358C4.96455 7.95055 5.60472 7.51501 6.32665 7.28008C7.04858 7.04514 7.82247 7.02051 8.55788 7.20907C9.29329 7.39762 9.95986 7.79156 10.4797 8.34487C10.9995 8.89818 11.3512 9.58801 11.4935 10.3337"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.6436 10.3337C20.7859 9.58801 21.1375 8.89818 21.6574 8.34487C22.1772 7.79156 22.8438 7.39762 23.5792 7.20907C24.3146 7.02051 25.0885 7.04514 25.8104 7.28008C26.5323 7.51501 27.1725 7.95055 27.6561 8.5358C28.1397 9.12104 28.4468 9.83183 28.5414 10.5851C28.6361 11.3384 28.5144 12.103 28.1906 12.7897C27.8668 13.4764 27.3542 14.0568 26.7129 14.463C26.0715 14.8692 25.3278 15.0845 24.5686 15.0837"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default People4Icon;

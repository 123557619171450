import React, { useCallback, useMemo, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { useEventListener } from "src/js/hooks/useEventListener";
import { store } from "src/js/store";
import FortnoxReconnectModal from "./FortnoxReconnectModal";
import { getCookie } from "src/js/Utilities";

export const DEFAULT_SCOPES = [
	"invoice",
	"article",
	"settings",
	"customer",
	"payment",
	"price",
	"supplier",
	"supplierinvoice",
	"salary",
	"project",
	"bookkeeping",
	"connectfile",
	"inbox",
	"archive",
];

export const ORDER_SCOPES = ["order", "offer"];

// connectFortnox() {
// 	const centerX = (window.screen.availWidth - 425) / 2;
// 	const centerY = (window.screen.availHeight - 700) / 2;
// 	const params = new URLSearchParams();
// 	params.append("token", getCookie("token"));
// 	Object.keys(this.state.params).forEach((key) => {
// 		params.append(key, this.state.params[key]);
// 	});

// 	// eslint-disable-next-line react/no-unused-class-component-methods
// 	this.fortnoxWindow = window.open(
// 		`/api/fortnox/connect?${params.toString()}`,
// 		"connectWindow",
// 		`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=425,height=700,left=${centerX},top=${centerY}`
// 	);
// 	window.addEventListener("message", this.onWindowReciveMessageEvent);
// }

// onWindowReciveMessage(event) {
// 	console.info("event:", event);
// 	const { data } = event;
// 	if (data.success) {
// 		toastr.success("Klar!", "Du är nu ansluten till fortnox!");
// 		this.setState({ fortnox_connection: true }, () =>
// 			store.dispatch({
// 				type: "UPDATE_ACCOUNT",
// 				account: Object.assign(store.getState().account, { fortnox_connection: this.state.fortnox_connection }),
// 			})
// 		);
// 	} else {
// 		toastr.error("Försök igen eller kontakta support.");
// 		console.error(data);
// 	}
// 	window.removeEventListener("message", this.onWindowReciveMessageEvent);
// }

export type FortnoxGlobalContextProps = {
	openWindow: (params: { url: string; params: any }) => void;
	handleReconnect: () => void;
	handleDiconnect: () => void;
	t?: any;
};

const FortnoxGlobalContext = React.createContext<FortnoxGlobalContextProps>({
	openWindow: () => {},
	handleReconnect: () => {},
	handleDiconnect: () => {},
});

const FortnoxGlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [reconnectModalIsOpen, setReconnectModalIsOpen] = React.useState<boolean>(false);
	const blockReconnectModal = useRef<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getScopesArray = (authentication = store.getState().account.fortnox_integration.authentication) => {
		return authentication?.scope?.split(" ");
	};

	const openWindow = useCallback(({ url, params }) => {
		const scopes = params.scopes || DEFAULT_SCOPES;
		delete params.scopes;

		const urlSearchParams = new URLSearchParams(params);

		scopes.forEach((scope) => {
			urlSearchParams.append("scopes[]", scope);
		});

		urlSearchParams.append("token", getCookie("token") as string);

		const newUrl = `${url}?${urlSearchParams.toString()}`;

		if ([3, 419].includes(store.getState().user.id)) {
			console.info("newUrl:", newUrl);
		}

		window.location.href = newUrl;
	}, []);

	const handleDiconnect = useCallback(async () => {
		const result = await API.delete(`/api/fortnox.json`);
		if (result?.data?.integration) {
			toastr.success(`${result.data.integration.title} ${"Fortnox har kopplats bort"}`);
			const response = await API.get("/api/account.json");
			if (response.data) store.dispatch({ type: "SET_ACCOUNT", account: response.data });

			return true;
		}
	}, []);

	// const getScopes = (type: string | null) => {
	// 	switch (type) {
	// 		case "order":
	// 			return "customer order offer article invoice print settings";
	// 		case "invoice":
	// 			return "customer invoice article invoice print settings";
	// 		default:
	// 			return store.getState().account.fortnox_integration?.authentication?.scopes || "customer invoice article invoice print settings";
	// 	}
	// };

	const handleReconnect = useCallback(() => {
		const previousScopes =
			store.getState().account?.fortnox_integration?.authentication?.scope ||
			store.getState().account?.fortnox_integration?.previous_authentication?.scope;
		// const previousScopesArray = previousScopes?.split(" ");

		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: previousScopes?.split(" ") || DEFAULT_SCOPES,
			},
		});
	}, [openWindow]);

	const handleOpenReconnectModal = useCallback(() => {
		if (blockReconnectModal.current) return;

		setReconnectModalIsOpen(true);
	}, []);

	useEventListener("fortnoxReconnect" as any, handleOpenReconnectModal, window as any);

	const value: FortnoxGlobalContextProps = useMemo(
		() => ({
			openWindow,
			handleReconnect,
			handleDiconnect,
		}),
		[openWindow, handleReconnect, handleDiconnect]
	);

	return (
		<FortnoxGlobalContext.Provider value={value as FortnoxGlobalContextProps}>
			{children}

			<FortnoxReconnectModal
				open={reconnectModalIsOpen}
				onClose={() => {
					blockReconnectModal.current = true;
					setReconnectModalIsOpen(false);
				}}
				handleReconnect={handleReconnect}
			/>
		</FortnoxGlobalContext.Provider>
	);
};

const mapStateToProps = (state: any) => ({
	account: state.account,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedFortnoxGlobalContextProvider: React.ComponentType<{ children: React.ReactNode } & PropsFromRedux> =
	connector(FortnoxGlobalContextProvider);

export default FortnoxGlobalContext;
export { ConnectedFortnoxGlobalContextProvider as FortnoxGlobalContextProvider };

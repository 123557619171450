/* eslint-disable camelcase */
import { Button, Icon, Select } from "@shopify/polaris";
import { DeleteMajor, EditMajor, MobileAcceptMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import Money from "../../components/Money";
import PopupMenu from "../../components/PopupMenu";
import Table from "../../components/Table";
import TextField from "../../components/TextField";
import { store } from "../../store";
import ArticleSelector from "./ArticleSelector";

export const calculateSubtotal = (row) => {
	if (!row || !row.count || !row.a_price) return 0;
	return row.count * row.a_price * ((100 - (row.discount || 0)) / 100);
};
class QuoteRowsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: null,
		};

		this.tableRef = React.createRef();
		this.renderRow = (row = {}, index) => {
			const editing = index === this.state.edit;
			const { article, description, unit, count, a_price, rotrut, discount, comment } = row || {};

			return editing || (!Object.keys(row).length && !Number.isInteger(this.state.edit))
				? [
						<tr key={`row-edit-${index}-content`} id={`row-edit-${index}-content`} className="Quote-Table-Row Quote-Table-Edit-Row">
							<td>
								{/* <TextField value={article} onChange={this.onChange.bind(this, "article", index)} /> */}
								<ArticleSelector
									labelHidden
									value={article}
									fortnox={store.getState().account.fortnox_connection}
									onChange={this.onChange.bind(this, "article", index)}
									onSelect={(v) => {
										row.article = v.sku;
										row.a_price = v.price;
										row.unit = v.unit;
										row.description = v.title;

										this.props.setRow(row, index);
										setTimeout(() => {
											const row = document.querySelector(`#row-${index}`);
											const nextElement = row && row.querySelector("input[value=''], textarea[value='']");
											if (nextElement) nextElement.focus();
										}, 0);
									}}
									disabled={this.props.disabled}
								/>
							</td>
							<td>
								{store.getState().account.fortnox_connection ? (
									<ArticleSelector
										labelHidden
										value={description}
										fortnox={store.getState().account.fortnox_connection}
										description
										onChange={this.onChange.bind(this, "description", index)}
										onSelect={(v) => {
											row.article = v.sku;
											row.a_price = v.price;
											row.unit = v.unit;
											row.description = v.title;

											this.props.setRow(row, index);
											setTimeout(() => {
												const row = document.querySelector(`#row-${index}`);
												const nextElement = row && row.querySelector("input[value=''], textarea[value='']");
												if (nextElement) nextElement.focus();
											}, 0);
										}}
										disabled={this.props.disabled}
									/>
								) : (
									<TextField disabled={this.props.disabled} value={description} onChange={this.onChange.bind(this, "description", index)} multiline />
								)}
							</td>
							<td>
								<TextField disabled={this.props.disabled} value={unit} onChange={this.onChange.bind(this, "unit", index)} />
							</td>
							<td>
								<TextField disabled={this.props.disabled} value={count} type="number" onChange={this.onChange.bind(this, "count", index)} />
							</td>
							<td>
								<TextField
									disabled={this.props.disabled}
									value={a_price}
									type="number"
									onChange={this.onChange.bind(this, "a_price", index)}
									suffix="kr"
								/>
							</td>
							<td width="105">
								<TextField
									disabled={this.props.disabled}
									type="number"
									suffix="%"
									value={discount}
									onChange={this.onChange.bind(this, "discount", index)}
								/>
							</td>
							<td>
								<Select
									label="Rot/Rut"
									labelInline
									disabled={this.props.disabled}
									options={[
										{
											value: "",
											label: "Inget",
										},
										{
											value: "rot",
											label: "Rot",
										},
										{
											value: "rut",
											label: "Rut",
										},
									]}
									value={rotrut}
									onChange={this.onChange.bind(this, "rotrut", index)}
								/>
							</td>
							<td>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Money style={{ whiteSpace: "nowrap", flex: 1 }} amount={(row.subtotal || calculateSubtotal(row)) * 100} currency="SEK" />
									{row && typeof row === "object" && Object.keys(row).some((i) => i) && !this.props.disabled && (
										<Button plain icon={MobileAcceptMajor} onClick={() => this.setState({ edit: null })} />
									)}
								</div>

								{/* <TextField multiline value={title5} onChange={()=>props.addRow("subtotal", index)} /> */}
							</td>
						</tr>,
						<tr key={`row-edit-${index}-comment`} id={`row-edit-${index}-comment`} className="Quote-Table-Row Quote-Table-Edit-Row-Comment">
							<td colSpan={8}>
								<TextField
									placeholder="Kommentar"
									fullWidth
									disabled={this.props.disabled}
									value={comment}
									onChange={this.onChange.bind(this, "comment", index)}
									multiline
								/>
							</td>
						</tr>,
				  ]
				: [
						<tr
							key={`row-${index}-content`}
							id={`row-${index}-content`}
							className={`Quote-Table-Row Quote-Table-Row__${comment ? "With-Comment" : "Standard"}`}
						>
							<td>{article}</td>
							<td>{description}</td>
							<td>{unit || "-"}</td>
							<td>{count}</td>
							<td>{a_price}</td>
							<td width="105">{discount}</td>
							<td>{rotrut ? rotrut.capitalize() : "-"}</td>
							<td>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Money style={{ whiteSpace: "nowrap", flex: 1 }} amount={(row.subtotal || calculateSubtotal(row)) * 100} currency="SEK" />
									{row && typeof row === "object" && Object.keys(row).some((i) => i) && !this.props.disabled && (
										<PopupMenu
											items={[
												{
													content: (
														<React.Fragment>
															<Icon source={EditMajor} />
															Redigera
														</React.Fragment>
													),
													onAction: () => this.setState({ edit: index }),
												},
												{
													content: (
														<React.Fragment>
															<Icon source={DeleteMajor} color="red" />
															Ta bort
														</React.Fragment>
													),
													onAction: () => this.props.removeRow(index),
												},
											]}
										/>
									)}
								</div>

								{/* <TextField multiline value={title5} onChange={()=>props.addRow("subtotal", index)} /> */}
							</td>
						</tr>,
						comment && (
							<tr key={`row-${index}-comment`} id={`row-${index}-comment`} className="Quote-Table-Row Quote-Table-Comment-Row">
								<td colSpan={1} />
								<td colSpan={7}>{comment}</td>
							</tr>
						),
				  ];
		};
	}

	componentDidMount() {
		if (this.props.form && this.props.form.rows && this.props.form.rows.length <= 2) {
			this.setState({ edit: 0 });
		}
	}

	onChange(field, index, v) {
		this.setState({ edit: index });
		this.props.addRow(field, index, v);
	}

	onKeyDown(e) {
		if (e.key === "Enter") {
			const ownTd = e.target.closest("td");
			const nextSibling = ownTd && ownTd.nextSibling;
			const newElement = nextSibling && nextSibling.querySelector("input, textarea");
			if (newElement) {
				if (newElement.tagName === "TEXTAREA") {
					e.preventDefault();
					e.stopPropagation();
				}

				newElement.focus();
			} else {
				const ownTr = e.target.closest("tr");
				const nextSibling = ownTr && ownTr.nextSibling;
				const newElement = nextSibling && nextSibling.querySelector("input, textarea");
				if (newElement) {
					if (newElement.tagName === "TEXTAREA") {
						e.preventDefault();
						e.stopPropagation();
					}
					newElement.focus();
				}
			}
		}
	}

	render() {
		return (
			<Table ref={this.tableRef} onKeyDown={this.onKeyDown.bind(this)}>
				<thead>
					<tr>
						<th>Artikel *</th>
						<th>Beskrivning</th>
						<th>Enhet</th>
						<th>Antal</th>
						<th>Á-pris</th>
						{/* <th width="105">Rot/Rut</th> */}
						<th>Rabatt</th>
						{/* <th>Delsumma</th> */}
						<th>Rot/Rut</th>
						<th>Radsumma</th>
					</tr>
				</thead>
				<tbody>
					{this.props.form.rows &&
						this.props.form.rows
							.filter((row) => (this.props.disabled ? Object.keys(row).some((key) => row[key]) : true))
							.map((row, index) => {
								return this.renderRow(row, index);
							})}
					{/* {this.renderRow({}, (this.props.form.rows && this.props.form.rows.length) || 0)} */}
				</tbody>
			</Table>
		);
	}
}
export default QuoteRowsTable;

import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import Modal from "src/js/components/modal";
import TextField from "src/js/components/TextField";
import { FormLayout } from "@shopify/polaris";
import SendAsEmailModal from "../SendAsEmailModal";
import useQuery from "src/js/hooks/useQuery";

type SetFormFunction = (form: Partial<FortnoxOfferType> | null | ((prevForm: Partial<FortnoxOfferType>) => Partial<FortnoxOfferType>)) => void;

type FortnoxOfferContextProviderProps = {
	id?: string;
	match?: any;
	history: any;
	initialData?: Partial<FortnoxOfferType> | null;
	children: React.ReactNode;
};

type FortnoxOfferContextValue = {
	offer: FortnoxOfferType | null;
	form: Partial<FortnoxOfferType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	pdf: any;
	isFetchingPDF: boolean;
	handleSave: (offer: FortnoxOfferType) => any;
	handleOpenRowSelectModal: () => void;
	handleSendToScrive: () => void;
	createOrderFromOffer: () => any;
	createInvoiceFromOffer: () => any;
	handleCancelOffer: () => any;
	handleSetAsSentOffer: () => any;
	handleSendOfferAsEmail: () => any;
	isSaving: boolean;
	handleSendOfferAsEmailForConfirmation: () => any;
	handleSetAsAccepted: () => any;
};

const FortnoxOfferContext = React.createContext({} as FortnoxOfferContextValue);

export const FortnoxOfferContextProvider = ({ id: propsId, match, history, children, initialData = null }: FortnoxOfferContextProviderProps) => {
	const [form, setForm] = useState<Partial<FortnoxOfferType> | null>(initialData);
	const id = propsId || match.params.id;
	const [isSaving, setIsSaving] = useState(false);
	const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
	const [emailConfirmationModalIsOpen, setEmailConfirmationModalIsOpen] = useState(false);
	const [emailConfirmationModalIsLoading, setEmailConfirmationModalIsLoading] = useState(false);

	const queryClient = useQueryClient();
	// Order
	const fetch = useCallback(async () => {
		if (!id) return initialData;
		try {
			const res = await API.get(`/api/fortnox/offers/${id}.json`);
			return res.data;
		} catch (error) {
			toastr.error(error);
			return initialData || null;
		}
	}, [id, initialData]);

	const queryKey = [id && `fortnox_offer-${id}`].filter(Boolean);

	const {
		data: offer = null,
		isFetching,
		refetch: refreshOffer,
	}: UseQueryResult<FortnoxOfferType | null> = useQuery({
		queryKey,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.data || initialData || null,
		enabled: !!id,
	});

	useEffect(() => {
		if (offer) setForm(offer);
	}, [offer]);
	// PDF
	const fetchPDF = useCallback(async () => {
		try {
			const res = await API.get(`/api/fortnox/offers/${id}/download.pdf`);

			return res.data;
		} catch (error) {
			toastr.error(error);
			return null;
		}
	}, [id]);
	const {
		data: pdf = null,
		isFetching: isFetchingPDF,
		refetch: refreshPDF,
	}: UseQueryResult<any> = useQuery({
		queryKey: [id && `fortnox_offer-${id}_pdf`].filter(Boolean),
		queryFn: fetchPDF,
		refetchOnWindowFocus: false,
		enabled: !!(offer?.DocumentNumber && id),
	});

	const saveFunction = useCallback(
		async (offer) => {
			const offerId = offer?.DocumentNumber || id;
			if (offerId) {
				offer.DocumentNumber = offerId;
			}
			const mutableOffer = offer;

			const endpoint = offerId ? `/api/fortnox/offers/${offerId}.json` : `/api/fortnox/offers.json`;

			const res = await (offerId ? API.put(endpoint, { ...mutableOffer }) : API.post(endpoint, { ...mutableOffer }));

			const successMessage = offerId ? "Offert uppdaterad" : "Offert skapad";

			toastr.success(successMessage);

			if (!offerId) {
				history.replace(`/admin/quotes/${res.data.DocumentNumber}`, {
					data: res.data,
				});
			}

			return res;
		},
		[id, history]
	);

	const update = async ({ offer }) => {
		const response = await saveFunction(offer);
		return response?.data;
	};

	const mutation = useMutation({
		mutationFn: update,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			if (id) refreshPDF();
		},
	});

	const handleSave = useCallback(
		async (offer: FortnoxOfferType) => {
			try {
				if (!offer) {
					throw new Error("no offer");
				}

				setIsSaving(true);
				return await mutation.mutateAsync({ offer });
			} catch (e: any) {
				if (!e?.response) throw e;
				toastr.error(e);
			} finally {
				setIsSaving(false);
			}
		},
		[mutation]
	);

	const cancel = async ({ offer }) => {
		try {
			const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/cancel.json`);
			toastr.success("Offert makulerad");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return offer;
		}
	};
	const mutationCancelOffer = useMutation({
		mutationFn: cancel,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			if (id) refreshPDF();
		},
	});

	const handleCancelOffer = useCallback(async () => {
		return await mutationCancelOffer.mutateAsync({ offer });
	}, [offer, mutationCancelOffer]);

	const setAsAccepted = async ({ offer }) => {
		try {
			await API.put(`/api/offer_user/${offer.DocumentNumber}/accept.json`);
			toastr.success("Offerten markerad som accepterad");
		} catch (error) {
			toastr.error(error);
			return offer;
		}
	};
	const mutationSetAsAccepted = useMutation({
		mutationFn: setAsAccepted,
		onSuccess: () => {
			refreshOffer();
		},
	});

	const handleSetAsAccepted = useCallback(async () => {
		return await mutationSetAsAccepted.mutateAsync({ offer });
	}, [offer, mutationSetAsAccepted]);

	const setAsSent = async ({ offer }) => {
		try {
			const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/externalprint.json`);
			toastr.success("Offerten markerad som skickad");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return offer;
		}
	};
	const mutationSetAsSentOffer = useMutation({
		mutationFn: setAsSent,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			if (id) refreshPDF();
		},
	});

	const handleSetAsSentOffer = useCallback(async () => {
		return await mutationSetAsSentOffer.mutateAsync({ offer });
	}, [offer, mutationSetAsSentOffer]);

	const handleSendOfferAsEmailPre = useCallback(async () => {
		setEmailModalIsOpen(true);
	}, []);

	const sendAsEmail = async ({ offer }) => {
		try {
			const res = await API.get(`/api/fortnox/offers/${offer.DocumentNumber}/email.json`);
			toastr.success("Offerten skickad via email");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return offer;
		}
	};
	const mutationSendOfferAsEnail = useMutation({
		mutationFn: sendAsEmail,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			if (id) refreshPDF();
		},
	});

	const handleSendOfferAsEmail = useCallback(async () => {
		return await mutationSendOfferAsEnail.mutateAsync({ offer });
	}, [offer, mutationSendOfferAsEnail]);

	const sendAsEmailForConfirmation = async ({ offer }) => {
		setEmailConfirmationModalIsLoading(true);
		try {
			const res = await API.post(`/api/fortnox/offers/${offer.DocumentNumber}/send_for_confirmation.json`, {
				email: form?.EmailInformation?.EmailAddressTo,
			});
			toastr.success("Offerten skickad via email för signering");
			setEmailConfirmationModalIsOpen(false);
			return res.data;
		} catch (error) {
			toastr.error(error);
			return offer;
		} finally {
			setEmailConfirmationModalIsLoading(false);
		}
	};

	const mutationSendOfferAsEnailForConfirmation = useMutation({
		mutationFn: sendAsEmailForConfirmation,
		onSuccess: () => {
			refreshOffer();
		},
	});

	const handleSendOfferAsEmailForConfirmation = useCallback(async () => {
		return await mutationSendOfferAsEnailForConfirmation.mutateAsync({ offer });
	}, [offer, mutationSendOfferAsEnailForConfirmation]);

	const handleSendOfferAsEmailForConfirmationPre = useCallback(async () => {
		setEmailConfirmationModalIsOpen(true);
	}, []);

	const createOrderFromOffer = useCallback(async () => {
		if (!offer?.DocumentNumber) {
			toastr.error("no offer");
			return;
		}

		try {
			const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/order.json`);

			refreshOffer();
			toastr.success("Order skapad");
			history.push(`/admin/fortnox/orders/${res.data.DocumentNumber}`);

			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);

			return null;
		}
	}, [offer?.DocumentNumber, history, refreshOffer]);

	const createInvoiceFromOffer = useCallback(async () => {
		if (!offer?.DocumentNumber) {
			toastr.error("no offer");
			return;
		}

		try {
			const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/invoice.json`);

			refreshOffer();
			toastr.success("Faktura skapad");
			history.push(`/admin/invoices/${res.data.DocumentNumber}`);

			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);

			return null;
		}
	}, [offer?.DocumentNumber, history, refreshOffer]);

	const value: any = useMemo(
		() => ({
			form,
			setForm,
			isFetching,
			isFetchingPDF,
			pdf,
			id,
			handleSave,
			offer,
			createOrderFromOffer,
			createInvoiceFromOffer,
			handleSetAsSentOffer,
			handleCancelOffer,
			handleSendOfferAsEmail: handleSendOfferAsEmailPre,
			isSaving,
			handleSendOfferAsEmailForConfirmation: handleSendOfferAsEmailForConfirmationPre,
			handleSetAsAccepted,
		}),
		[
			isFetching,
			id,
			form,
			isFetchingPDF,
			pdf,
			handleSave,
			setForm,
			offer,
			createOrderFromOffer,
			createInvoiceFromOffer,
			handleSetAsSentOffer,
			handleCancelOffer,
			handleSendOfferAsEmailPre,
			isSaving,
			handleSendOfferAsEmailForConfirmationPre,
			handleSetAsAccepted,
		]
	);

	return useMemo(
		() => (
			<FortnoxOfferContext.Provider value={value}>
				<SendAsEmailModal
					offer={offer as FortnoxOfferType}
					type="offer"
					open={!!emailModalIsOpen}
					loading={isSaving}
					onClose={() => setEmailModalIsOpen(false)}
					EmailInformation={form?.EmailInformation}
					onSend={async (EmailInformation: EmailInformation) => {
						const newForm = { EmailInformation };

						const res = !offer?.Cancelled ? await handleSave(newForm as FortnoxOfferType) : true;
						if (res) {
							const response = await handleSendOfferAsEmail();
							setEmailModalIsOpen(false);
							return response;
						}
					}}
				/>

				<Modal
					title="Skicka offert för signering"
					open={!!emailConfirmationModalIsOpen}
					onClose={() => setEmailConfirmationModalIsOpen(false)}
					sectioned
					primaryAction={{
						content: "Skicka",
						onAction: handleSendOfferAsEmailForConfirmation,
						loading: emailConfirmationModalIsLoading,
					}}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: () => setEmailConfirmationModalIsOpen(false),
						},
					]}
				>
					<FormLayout>
						<p>Är du säker på att du vill skicka offerten för signering?</p>

						<TextField
							label="E-postadress"
							value={form?.EmailInformation?.EmailAddressTo}
							onChange={(value: string) => {
								setForm((prevForm) => ({ ...prevForm, EmailInformation: { ...prevForm?.EmailInformation, EmailAddressTo: value } } as any));
							}}
						/>
					</FormLayout>
				</Modal>

				{children}
			</FortnoxOfferContext.Provider>
		),
		[
			value,
			children,
			form,
			handleSave,
			emailModalIsOpen,
			handleSendOfferAsEmail,
			isSaving,
			offer,
			emailConfirmationModalIsOpen,
			handleSendOfferAsEmailForConfirmation,
			emailConfirmationModalIsLoading,
		]
	);
};
export default FortnoxOfferContext;

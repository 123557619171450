import React, { Component } from "react";
import { Card, Stack, Avatar, Link, Caption, Icon, Button, TextStyle } from "@shopify/polaris";
import Moment from "react-moment";
import nl2br from "react-nl2br";
import { CalendarMajor, CancelSmallMinor, NoteMajor, OrdersMajor } from "@shopify/polaris-icons";
import { store } from "../store";
import RemoveModal from "./remove_modal.js";
import trans from "../trans.js";
import { toastr } from "./toastr";
import API from "../API";

class TimeLineEvent extends Component {
	constructor(props) {
		super(props);
		this.state = { showRemoveModal: false };
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	gotoUrl(url) {
		window.open(url);
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	removeComment() {
		this.setState({ saving: true });
		API.delete("/api/comments/" + this.props.event.id + ".json", { params: {} })
			.then((result) => {
				this.setState({ showRemoveModal: false });
				this.props.onRemove();
				toastr.success(trans("comments.actions.removed_comment"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<div className="timeline-row">
				{!this.props.event.other ? (
					<div className="timeline-card-wrapper">
						<Card sectioned>
							<Stack distribution="fill">
								{this.props.event.user ? (
									<Avatar source={this.props.event.user.avatar} name={this.props.event.user.name} />
								) : (
									<div style={{ width: 40 }}>
										<div className="timeline-ball" />
									</div>
								)}
								<div className="timeline-content">
									{!store.getState().user.read_only ? (
										<Button plain onClick={this.removeModal.bind(this)}>
											<Icon source={CancelSmallMinor} />
										</Button>
									) : null}
									<div>
										{this.props.event.user ? this.props.event.user.name : null}
										<Caption>
											<TextStyle variation="subdued">
												<Moment locale="sv" fromNow>
													{this.props.event.created_at}
												</Moment>
											</TextStyle>
										</Caption>
									</div>
									{nl2br(this.props.event.comment)}
								</div>
							</Stack>
						</Card>
						{!store.getState().user.read_only ? (
							<RemoveModal
								open={this.state.showRemoveModal}
								resourceName={{
									singular: trans("comments.singular"),
									plural: trans("comments.plural"),
								}}
								numItems={1}
								onClose={this.closeRemoveModal.bind(this)}
								loading={this.state.saving}
								onConfirm={this.removeComment.bind(this)}
							/>
						) : null}
					</div>
				) : (
					<div className="timeline-new">
						<Stack distribution="fill">
							<div style={{ width: 40 }}>
								<div className="timeline-ball" />
							</div>
							<div className="timeline-content">
								{this.props.event.opportunity ? (
									<div>
										<Stack spacing="extraTight">
											<Icon source={OrdersMajor} />{" "}
											<span>
												{this.props.event.old_id ? "Ändrad" : "Ny"}:{this.props.event.title} [{this.props.event.type}]
											</span>{" "}
											{this.props.event.new_id ? null : <Link onClick={this.props.onEdit}>ändra</Link>}
										</Stack>
										<Stack spacing="extraTight">
											<Icon source={NoteMajor} color="inkLightest" />
											<TextStyle variation="subdued">{nl2br(this.props.event.content)}</TextStyle>
										</Stack>
									</div>
								) : null}
								{this.props.event.task ? (
									<div>
										<Stack spacing="extraTight">
											<Icon source={CalendarMajor} /> <span>Nytt Uppdrag [{this.props.event.type}]</span>{" "}
											{this.props.event.complete_at ? null : <Link onClick={this.props.onEdit}>hantera</Link>}
										</Stack>
										<Stack spacing="extraTight">
											<Icon source={NoteMajor} color="inkLightest" />
											<TextStyle variation="subdued">{nl2br(this.props.event.content)}</TextStyle>
										</Stack>
									</div>
								) : null}

								{this.props.event.task_complete ? (
									<div>
										<Stack spacing="extraTight">
											<Icon source={CalendarMajor} /> <span>Avslutat uppdrag</span>
										</Stack>
										<Stack spacing="extraTight">
											<Icon source={NoteMajor} color="inkLightest" />
											<TextStyle variation="subdued">{nl2br(this.props.event.comment)}</TextStyle>
										</Stack>
									</div>
								) : null}
							</div>
							<div style={{ textAlign: "right" }}>
								<TextStyle variation="subdued">
									<Moment locale="sv" fromNow>
										{this.props.event.created_at}
									</Moment>
								</TextStyle>
							</div>
						</Stack>
					</div>
				)}
			</div>
		);
	}
}
export default TimeLineEvent;

import React, { Component } from "react";
import { Card, Page, Stack, Icon } from "@shopify/polaris";
import { NavLink } from "react-router-dom";
import routes from "../../routes.js";

class SettingsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Page title="Inställningar">
				<Card sectioned>
					<Stack distribution="fillEvenly">
						{routes.map((prop, key) => {
							if (!prop.settings || !prop.description) {
								return null;
							}
							return (
								<NavLink to={prop.path} key={key} className="settings-item">
									<div className="icon-wrapper">
										<Icon source={prop.icon} />
									</div>
									<div>
										<p>{prop.name}</p>
										<span>{prop.description}</span>
									</div>
								</NavLink>
							);
						})}
					</Stack>
				</Card>
			</Page>
		);
	}
}

export default SettingsIndex;

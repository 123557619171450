import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { Icon, Popover } from "@shopify/polaris";
import { NoteMajor } from "@shopify/polaris-icons";
import { Draggable } from "react-beautiful-dnd";
import { FolderIcon, SunIcon } from "../../icons";
import Colors from "../../Colors";

class EventRow extends Component {
	constructor(props) {
		super(props);
		this.state = { tooltipOpen: false };
		this.ref = React.createRef();
	}

	getColor(event) {
		switch (event.type) {
			case "project":
				return "#3892DB";
			case "absense":
				return Colors.purple;
			case "comment":
				return "#878787";
			default:
				return "#3892DB";
		}
	}

	getIcon() {
		if (!this.props.event) return null;
		switch (this.props.event.type) {
			case "project":
				return <FolderIcon />;
			case "absense":
				return <SunIcon />;
			case "comment":
				return <Icon source={NoteMajor} />;
			default:
				return null;
		}
	}

	render() {
		const { onClick, event, index, view } = this.props;
		const isSameDay = moment(event.start_at).isSame(event.end_at, "day");

		return (
			<Draggable key={event.id} draggableId={String(event.id)} index={index} isDragDisabled={!isSameDay}>
				{(dragProvided) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
						<Popover
							active={this.state.tooltipOpen}
							onClose={() => this.setState({ tooltipOpen: false })}
							activator={
								<Row
									ref={this.ref}
									onMouseEnter={(e) => {
										this.setState({ tooltipOpen: true });
									}}
									onMouseLeave={(e) => this.setState({ tooltipOpen: false })}
									backgroundColor={this.getColor(event)}
									key={event.id}
									onClick={onClick}
									className={`${view}`}
								>
									<EventTitle>
										{this.getIcon()}
										{event.title}
									</EventTitle>
								</Row>
							}
						>
							<ToolTip className="event-tooltip" left={this.state.left} backgroundColor={this.getColor(event)}>
								<h1>
									{this.getIcon()}
									{event.title}
								</h1>
								{/* {event.type === "project" && <LabelValue label={"Project: "} value={event.project && event.project.title} />} */}

								{/* {event.type === "kommentar" && <span>Project: {event.project && event.project.title}</span>} */}
								<LabelContainer>
									{event.type === "absense" && <LabelValue label="Frånvaro: " value={event.absense_type} />}
									<LabelValue label="Start datum: " value={moment(event.start_at).format("YYYY-MM-DD HH:mm")} />
									<LabelValue label="Slut datum: " value={moment(event.end_at).format("YYYY-MM-DD HH:mm")} />
									{event.comment && <LabelValue label="Kommentar: " value={event.comment} />}
								</LabelContainer>

								{/* <DateWrapper>
							<span className="value">{moment(event.start_at).format("YYYY-MM-DD HH:mm")}</span>
							<span>{"=>"}</span>
							<span className="value">{moment(event.end_at).format("YYYY-MM-DD HH:mm")}</span>
						</DateWrapper> */}
							</ToolTip>
						</Popover>
					</div>
				)}
			</Draggable>
		);
	}
}
export default EventRow;

const LabelValue = ({ label, value }) => {
	return (
		<LabelValueWrapper>
			<Label>{label}</Label>
			<Value>{value}</Value>
		</LabelValueWrapper>
	);
};

const LabelValueWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
`;
const Label = styled.b`
	color: rgb(255, 255, 255);
`;
const Value = styled.span`
	color: rgb(255, 255, 255);
`;

const Row = styled.div`
	background-color: ${({ backgroundColor }) => backgroundColor};
	min-height: 2.4rem;
	width: 100%;
	color: #ffffff;
	border-radius: 0.25rem;
	padding: 0.25rem;
	align-items: center;
	display: flex;
	position: relative;
	font-size: 0.9em;
	margin: 0.25rem 0;
	overflow: hidden;

	&.month {
		max-height: 50px;
		align-items: flex-start;
		text-overflow: ellipsis;
		/* white-space: nowrap; */
		svg {
			display: none;
		}
	}

	&.week {
	}

	.Polaris-Icon {
		margin: 0;
	}

	svg:not([filltype="stroke"]) {
		fill: #ffffff;
		margin: 0;
	}

	svg[filltype="stroke"] path {
		transition: stroke 250ms;
		stroke: #ffffff;
		margin: 0;
	}
	svg[filltype="strokeFill"] path {
		transition: stroke 250ms;
		fill: #ffffff;
		margin: 0;
	}
`;
const EventTitle = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	word-break: break-all;

	/* max-height: ; */

	svg {
		height: 1.25em;
		min-height: 1.25em;
		width: 1.25em;
		min-width: 1.25em;
	}
`;
const ToolTip = styled.div`
	z-index: 2;
	/* position: absolute; */
	padding: 1rem;
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: #ffffff;
	/* top: 3.2rem; */
	/* left: ${({ left }) => left || 0}px; */
	border-radius: 0.35rem;
	min-width: 100%;
	width: max-content;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

	h1 {
		font-size: 1.2rem;
		font-weight: bold;
		display: flex;
		align-items: center;
		gap: 1rem;

		svg {
			height: 1.25em;
			min-height: 1.25em;
			width: 1.25em;
			min-width: 1.25em;
		}
	}
`;

const LabelContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

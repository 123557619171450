import { ButtonGroup, Text } from "@shopify/polaris";
import PdfViewer from "src/js/components/PdfViewer2";
import styled from "styled-components";
import useQuery from "src/js/hooks/useQuery";
import API from "src/js/API";
import Banner from "src/js/components/banner";
import moment from "moment";
import AcceptButton from "./AcceptButton";
import DeclineButton from "./DeclineButton";

const UploadOfferView = ({ upload }) => {
	const urlParams = new URLSearchParams(window.location.search);
	const offerUserId = urlParams.get("offer_user");
	const uniqueId = urlParams.get("unique_id");

	const { data: offerUser, refetch } = useQuery({
		queryFn: async () => {
			const res = await API.get(`/api/public/offer_user/${offerUserId}.json`);
			return res.data?.offer_user;
		},
		queryKey: ["offer_user", offerUserId],
		enabled: !!offerUserId,
	});

	return (
		<Wrapper>
			<Header>
				<Text as="h1" variant="headingLg">
					Acceptera/neka offert {upload?.quote?.id}
				</Text>

				{offerUser?.accepted_at && (
					<Banner style={{ width: 500 }} status="success" title="Offerten är accepterad">
						Offerten är accepterad av dig den {moment(offerUser.accepted_at).format("YYYY-MM-DD HH:mm")}
					</Banner>
				)}

				{offerUser?.declined_at && (
					<Banner style={{ width: 500 }} status="critical" title="Offerten är nekad">
						Offerten är nekad av dig den {moment(offerUser.declined_at).format("YYYY-MM-DD HH:mm")}
					</Banner>
				)}
			</Header>
			<ButonsWrapper>
				<ButtonGroup spacing="loose">
					<DeclineButton upload={upload} offerUserId={offerUserId} onSuccess={refetch} uniqueId={uniqueId} />
					<AcceptButton upload={upload} offerUserId={offerUserId} onSuccess={refetch} uniqueId={uniqueId} />
				</ButtonGroup>
			</ButonsWrapper>
			<PdfViewer src={upload?.url} />
		</Wrapper>
	);
};
export default UploadOfferView;

const Wrapper = styled.div`
	padding: 1rem;
	/* display: flex;
	justify-content: center;
	flex-direction: column; */
`;

const Header = styled.div`
	padding: 2rem;
	/* margin: auto;
	width: max-content; */

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

const ButonsWrapper = styled.div`
	padding: 1rem;
	position: sticky;
	top: 0;
	background-color: var(--p-color-bg-app);
	/* margin: auto;
	width: max-content; */

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

import moment from "moment";
import React from "react";
import { TableVirtuoso } from "react-virtuoso";
import API from "src/js/API";
import Colors, { colorToRgba } from "src/js/Colors";
import useQuery from "src/js/hooks/useQuery";
import styled, { css } from "styled-components";
import { DashboardSectionTitle } from "../styledComponents";
import Spinner from "src/js/components/Spinner";
import { LoadingCss } from "src/js/GlobalStyles";

const AcceptedOffersWithoutProjectList = ({ history }) => {
	const { data, isFetching } = useQuery({
		queryFn: async () => {
			const res = await API.get(`/api/offers/accepted_without_project.json`);
			return res?.data?.offers || [];
		},
		enabled: true,
		queryKey: ["offers", "offers_accepted_without_project"],
		initialData: [],
	});

	const handleOnClick = (offer) => {
		history.push(`/admin/quotes/${offer.DocumentNumber}`);
	};

	const renderRow = (index: number, item: any) => {
		const lastOfferUser = item.offer_users?.at(-1);
		const email = lastOfferUser?.email;
		const offerUserCounts = item.offer_users?.length;
		const date = lastOfferUser?.accepted_at && moment(lastOfferUser?.accepted_at).format("YYYY-MM-DD HH:mm");

		return (
			<>
				<td style={{ cursor: "pointer" }}>{item?.DocumentNumber && <b>#{item?.DocumentNumber}</b>}</td>
				<td style={{ cursor: "pointer" }}>
					<b>{item?.CustomerName} </b>
					{item?.CustomerNumber && <b>({item?.CustomerNumber})</b>}
				</td>
				<td style={{ width: "125px" }}>
					<p style={{ justifyContent: "center", whiteSpace: "nowrap" }}>
						{email}
						{offerUserCounts > 1 ? ` +${offerUserCounts - 1}` : ""}
					</p>
				</td>
				<td style={{ width: "50px" }}>
					<p style={{ justifyContent: "center", whiteSpace: "nowrap" }}>{date}</p>
				</td>
			</>
		);
	};

	const items = [...data, ...(isFetching ? Array.from(Array(1)).map(() => ({ loading: true, CustomerName: <Spinner size="small" /> })) : [])];

	return (
		<Wrapper>
			<DashboardSectionTitle>Obehandlade accepterade offerter</DashboardSectionTitle>

			<TableVirtuoso
				width="100%"
				style={{ flex: 1, height: "calc(100% - 40px)" }}
				data={items}
				computeItemKey={(_, item) => {
					return item?.DocumentNumber;
				}}
				itemContent={renderRow}
				components={{
					TableRow: ({ children, item, style, ...props }) => {
						return (
							<TrWrapper
								title={`Klicka för att öppna offert #${item?.DocumentNumber}`}
								key={item.id}
								{...props}
								onClick={() => {
									handleOnClick(item);
								}}
								style={{ ...style, cursor: "pointer" }}
								loading={item.loading}
							>
								{children}
							</TrWrapper>
						);
					},
					EmptyPlaceholder: () => {
						return <EmptyWrapper>0 obehandlade offerter</EmptyWrapper>;
					},
				}}
				fixedFooterContent={() => {
					return null;
				}}
			/>
		</Wrapper>
	);
};
export default AcceptedOffersWithoutProjectList;

const Wrapper = styled.div.attrs({ className: "AcceptedOffersWithoutProjectList" })`
	position: relative;
	border-radius: var(--p-border-radius-2);
	background-color: ${Colors.secondary};
	box-shadow: var(--p-shadow-md);
	outline: var(--p-border-width-1) solid transparent;
	padding: var(--p-space-5);
	height: 100%;
	font-weight: 700;

	table {
		width: 100%;
	}
`;

const EmptyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

const TrWrapper = styled.tr<{ loading?: boolean }>`
	font-size: 12px;

	${(props) =>
		props.loading &&
		css`
			background-color: ${colorToRgba(Colors["color-primary"], 0.1)};

			${LoadingCss}
		`}

	td {
		padding: 0.5rem;
	}

	p {
		font-size: 0.95em;
		color: ${colorToRgba(Colors["color-primary"], 0.7)};
	}
`;

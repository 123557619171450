import React, { Component } from "react";
import { store } from "../../../store";

// This is not used anymore, this was used to create the twig
class QuotePDFHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { company_name: companyName, id, revision } = this.props.quote || {};

		return (
			<div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "7px" }}>
				<h1 style={{ fontSize: "40px", fontWeight: "bold", lineHeight: "40px" }}>{companyName || store.getState().account.name}</h1>
				<div>
					<p style={{ fontSize: "16px", lineHeight: "16px" }}>Offert</p>
					<p style={{ fontSize: "16px", lineHeight: "16px" }}>
						Nr: {id}
						{revision != 1 && `-${revision}`}
					</p>
				</div>
			</div>
		);
	}
}
export default QuotePDFHeader;

import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			latitude: props.latitude,
			longitude: props.longitude,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.latitude != this.state.latitude || nextProps.longitude != this.state.longitude) {
			this.state.latitude = nextProps.latitude;
			this.state.longitude = nextProps.longitude;
			// this.setState({ latitude: nextProps.latitude, longitude: nextProps.longitude });
			return true;
		}
		return false;
	}

	render() {
		const MyMapComponent = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap defaultZoom={this.props.zoom ? this.props.zoom : 8} defaultCenter={{ lat: this.props.latitude, lng: this.props.longitude }}>
					{props.isMarkerShown && <Marker position={{ lat: this.props.latitude, lng: this.props.longitude }} />}
				</GoogleMap>
			))
		);
		return (
			<MyMapComponent
				isMarkerShown
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCoDwig5ZiU2hMEGqBhIXU_w8-pKU4EFl8&v=3.exp&libraries=geometry,drawing,places"
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: this.props.height ? this.props.height : 200 }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		);
	}
}

export default Map;

import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Banner, DatePicker, FormLayout, TextField, Stack, Checkbox, Spinner, Modal, Text } from "@shopify/polaris";
import moment from "moment";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import { getTypeLabel } from "src/js/Utilities";
import { toastr } from "../../components/toastr";
import { store } from "../../store";

import SkeletonProvider from "../../components/skeleton_provider.js";
import ResourcePicker from "../../components/resource_picker.js";
import TimePicker from "../../components/time_picker.js";
import MapWithMultipleMarkers2 from "../../components/MapWithMultipleMarkers2";
import API from "../../API";

class TimeReportEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			hours: "0",
			ob_hours: "0",
			showRemoveModal: false,
			saving: false,
			loading: true,
			parent_state: null,
		};
		if (props.location.state) {
			this.state.parent_state = props.location.state;
		}
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	getViewTitle(view) {
		var items = [
			{ label: "Maskin", value: "machine" },
			{ label: "Arbetad tid", value: "project" },
			{ label: getTypeLabel("photo"), value: "photo" },
			{ label: getTypeLabel("divergence"), value: "divergence" },
			{ label: getTypeLabel("expense"), value: "expense" },
			{ label: getTypeLabel("sick"), value: "sick" },
			{ label: getTypeLabel("vab"), value: "vab" },
			{ label: getTypeLabel("shorttime"), value: "shorttime" },
			{ label: getTypeLabel("parenting"), value: "parenting" },
			{ label: getTypeLabel("vacation"), value: "vacation" },
			{ label: getTypeLabel("service_free"), value: "service_free" },
			{ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" },
			{ label: getTypeLabel("birth_free"), value: "birth_free" },
			{ label: getTypeLabel("comp_free"), value: "comp_free" },
			{ label: getTypeLabel("weekend_salary"), value: "weekend_salary" },
		];

		for (var i = 0; i < items.length; i++) {
			if (items[i].value == view) {
				return items[i].label;
			}
		}
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/time_reports/" + id + ".json")
			.then((result) => {
				const d = new Date(result.data.time_report.checkin_at);
				const minutes = result.data.time_report.minutes;
				if (minutes) {
					if (minutes >= 60) {
						if (minutes % 60 == 0) {
							this.state.hours = minutes / 60;
						} else {
							const mins = minutes % 60;
							const hours = (minutes - mins) / 60;
							this.state.hours = hours + "." + mins;
						}
					} else {
						this.state.hours = "0." + minutes;
					}
				}

				var obminutes = result.data.time_report.ob_minutes;
				if (obminutes) {
					if (obminutes >= 60) {
						if (obminutes % 60 == 0) {
							this.state.ob_hours = obminutes / 60;
						} else {
							const mins = obminutes % 60;
							const hours = (obminutes - mins) / 60;
							this.state.ob_hours = hours + "." + mins;
						}
					} else {
						this.state.ob_hours = "0." + obminutes;
					}
				}

				var obminutes1 = result.data.time_report.ob1;
				if (obminutes1) {
					if (obminutes1 >= 60) {
						if (obminutes1 % 60 == 0) {
							this.state.ob1_hours = obminutes1 / 60;
						} else {
							const mins = obminutes1 % 60;
							const hours = (obminutes1 - mins) / 60;
							this.state.ob1_hours = hours + "." + mins;
						}
					} else {
						this.state.ob1_hours = "0." + obminutes1;
					}
				}

				var obminutes2 = result.data.time_report.ob2;
				if (obminutes2) {
					if (obminutes2 >= 60) {
						if (obminutes2 % 60 == 0) {
							this.state.ob2_hours = obminutes2 / 60;
						} else {
							const mins = obminutes2 % 60;
							const hours = (obminutes2 - mins) / 60;
							this.state.ob2_hours = hours + "." + mins;
						}
					} else {
						this.state.ob2_hours = "0." + obminutes2;
					}
				}

				var obminutes3 = result.data.time_report.ob3;
				if (obminutes3) {
					if (obminutes3 >= 60) {
						if (obminutes3 % 60 == 0) {
							this.state.ob3_hours = obminutes3 / 60;
						} else {
							const mins = obminutes3 % 60;
							const hours = (obminutes3 - mins) / 60;
							this.state.ob3_hours = hours + "." + mins;
						}
					} else {
						this.state.ob3_hours = "0." + obminutes3;
					}
				}

				var overtimeMinutes = result.data.time_report.overtime_minutes;
				if (overtimeMinutes) {
					if (overtimeMinutes >= 60) {
						if (overtimeMinutes % 60 == 0) {
							this.state.overtime_hours = overtimeMinutes / 60;
						} else {
							const mins = overtimeMinutes % 60;
							const hours = (overtimeMinutes - mins) / 60;
							this.state.overtime_hours = hours + "." + mins;
						}
					} else {
						this.state.overtime_hours = "0." + overtimeMinutes;
					}
				}

				this.setState({
					hours: this.state.hours + "",
					ob_hours: this.state.ob_hours + "",
					ob1_hours: this.state.ob1_hours + "",
					ob2_hours: this.state.ob2_hours + "",
					ob3_hours: this.state.ob3_hours + "",
					overtime_hours: this.state.overtime_hours ?? 0.0 + "",
					hourly_invoicable_rate: result.data.time_report.hourly_invoicable_rate,
					amount_to_invoice: result.data.time_report.amount_to_invoice,
					resor_i_tjanst: result.data.time_report.resor_i_tjanst + "",
					resor_i_tjanst_karra: result.data.time_report.resor_i_tjanst_karra + "",
					bodpeng: result.data.time_report.bodpeng + "",
					hojdtillagg: result.data.time_report.hojdtillagg + "",
					dagliga_resor: result.data.time_report.dagliga_resor + "",
					loading: false,
					tid_eller_pengar: result.data.time_report.tid_eller_pengar,
					selected: { start: new Date(result.data.time_report.checkin_at), end: new Date(result.data.time_report.checkin_at) },
					month: d.getMonth(),
					year: d.getFullYear(),
					from_time: moment(result.data.time_report.checkin_at).format("HH:mm"),
					to_time: moment(result.data.time_report.checkout_at).format("HH:mm"),
					saving: false,
					form: result.data.time_report,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	convertToHours(string) {
		if (!string.includes(".")) return Number(string);
		const [hours, minutes] = string.split(".");
		return Number(hours) + Number(minutes) / 60;
	}

	getChangeHandler(key) {
		const hours = this.convertToHours(this.state.hours) + this.convertToHours(this.state.ob_hours) + this.convertToHours(this.state.overtime_hours);

		if (key === "hourly_invoicable_rate" || key === "hours" || key === "ob_hours" || key == "overtime_hours") {
			this.state.amount_to_invoice = this.state.hourly_invoicable_rate ? parseFloat(this.state.hourly_invoicable_rate * hours).toFixed(0) : 0;
		} else if (key === "amount_to_invoice") {
			this.state.hourly_invoicable_rate = parseFloat(this.state.amount_to_invoice / hours).toFixed(0);
		}

		this.setState({ ...this.state });
	}

	updateForm(field, value) {
		if (field === "machine" && !value.id) {
			delete this.state.form.machine;
			this.setState({ form: this.state.form });
			return;
		}

		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		var start = new Date(this.state.selected.start);
		start.setHours(0, 0, 0, 0);
		var end = new Date(this.state.selected.end);
		end.setHours(23, 59, 59, 0);

		if (
			store.getState().user.roles.indexOf("ROLE_ADMIN") < 0 &&
			store.getState().user.roles.indexOf("ROLE_MANAGER") < 0 &&
			moment().format("YYYY-MM-DD") != moment(this.state.selected.start).format("YYYY-MM-DD")
		) {
			toastr.error("Vänligen kontakta närmsta chef för att göra en ändring.");
			return;
		}

		this.setState({ saving: true });

		API.put("/api/time_reports/" + this.state.id + ".json", {
			whole_day: this.state.form.whole_day,
			hours: this.state.hours,
			ob_hours: this.state.ob_hours,
			ob1_hours: this.state.ob1_hours,
			ob2_hours: this.state.ob2_hours,
			ob3_hours: this.state.ob3_hours,
			overtime_hours: this.state.overtime_hours,
			resor_i_tjanst: this.state.resor_i_tjanst,
			resor_i_tjanst_karra: this.state.resor_i_tjanst_karra,
			bodpeng: this.state.bodpeng,
			tid_eller_pengar: this.state.tid_eller_pengar,
			hojdtillagg: this.state.hojdtillagg,
			dagliga_resor: this.state.dagliga_resor,
			cost: this.state.form.cost,
			comment: this.state.form.comment,
			machine: this.state.form.machine,
			hourly_invoicable_rate: this.state.hourly_invoicable_rate,
			amount_to_invoice: this.state.amount_to_invoice,
			work_order_id: this.state.form.type == "weekend_salary" ? this.state.form.work_order.id : null,
			checkin_at: start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " " + this.state.from_time,
			checkout_at: end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " " + this.state.to_time,
		})
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				var d = new Date(result.data.time_report.checkin_at);
				this.setState({
					loading: false,
					selected: { start: new Date(result.data.time_report.checkin_at), end: new Date(result.data.time_report.checkin_at) },
					month: d.getMonth(),
					year: d.getFullYear(),
					from_time: moment(result.data.time_report.checkin_at).format("HH:mm"),
					to_time: moment(result.data.time_report.checkout_at).format("HH:mm"),
					saving: false,
					form: result.data.time_report,
				});
				toastr.success("Rapport ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeTemplate() {
		if (
			store.getState().user.roles.indexOf("ROLE_ADMIN") < 0 &&
			store.getState().user.roles.indexOf("ROLE_MANAGER") < 0 &&
			moment().format("YYYY-MM-DD") != moment(this.state.form.checkin_at).format("YYYY-MM-DD")
		) {
			toastr.error("Vänligen kontakta närmsta chef för att göra en ändring.");
			return;
		}

		this.setState({ saving: true });
		API.delete("/api/time_reports/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Rapport borttagen");

				this.props.history.goBack();
				// this.props.history.replace("/admin/time_reports");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		var disableDatesBefore = null;
		var disabled = false;
		const showMap = Boolean(this.state.form.report_type == "checkin" && (this.state.form.longitude || this.state.form.checkout_longitude));

		return (
			<Page
				fullWidth
				title={"Tidrapport " + this.state.form.id}
				primaryAction={
					store.getState().user.read_only
						? null
						: store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || !store.getState().account.disable_editing_group_leader
						? {
								content: "Uppdatera",
								disabled,
								loading: this.state.saving,
								onAction: this.saveForm.bind(this),
						  }
						: null
				}
				backAction={{
					content: "Tillbaka",
					onAction: () => {
						this.props.history.goBack();
					},
				}}
			>
				{disabled ? (
					<div style={{ marginBottom: 20 }}>
						<Banner title="Denna rapportering går inte längre att ändra, be din arbetsledare att göra det åt dig" status="warning" />
					</div>
				) : null}
				<CardsInPageWrapper>
					<Layout>
						<Layout.Section oneThird={showMap} oneHalf={!showMap}>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Allmänt om registrering
							</Text>
							<Text as="p" variant="bodyMd">
								Grundläggande information om registreringen
							</Text>
							<Card sectioned>
								<FormLayout>
									<TextField value={this.state.form.user.name} label="Användaren" disabled />
									<TextField value={this.getViewTitle(this.state.form.type)} label="Typ" disabled />
									{this.state.form.created_at != this.state.form.updated_at && (
										<TextField value={moment(this.state.form.updated_at).format("YYYY-MM-DD HH:mm")} label="Uppdaterad" disabled />
									)}
									<TextField
										value={`${moment(this.state.form.created_at).format("YYYY-MM-DD HH:mm")} ${
											this.state.form.created_by ? `av ${this.state.form.created_by?.name}` : ""
										}`}
										label="Skapad"
										disabled
									/>
									{this.state.form.attest_at && this.state.form.attest_user && this.state.form.attest_user.name && (
										<TextField
											value={`${this.state.form.attest_user.name} ${moment(this.state.form.attest_at).format("YYYY-MM-DD HH:mm")}`}
											label="Attesterad av"
											disabled
										/>
									)}
									{this.state.form.is_exported &&
										this.state.form.exported_at &&
										this.state.form.exported_by_user &&
										this.state.form.exported_by_user.name && (
											<TextField
												value={`${this.state.form.exported_by_user.name} ${moment(this.state.form.exported_at).format("YYYY-MM-DD HH:mm")}`}
												label="Exporterad av"
												disabled
											/>
										)}
									{this.state.form.export && this.state.form.export_at && (
										<TextField value={moment(this.state.form.export_at).format("YYYY-MM-DD HH:mm")} label="Exporterad till Agresso" disabled />
									)}
									{this.state.form.type == "project" && (
										<TextField
											value={this.state.form.work_order && this.state.form.work_order.project ? this.state.form.work_order.project.full_label : "–"}
											label="Projekt"
											disabled
										/>
									)}
									{this.state.form.type == "project" && (
										<TextField value={this.state.form.work_order ? this.state.form.work_order.full_label : "–"} label="Arbetsorder" disabled />
									)}
									{/* {this.state.form.type == "project" && this.state.form && this.state.form.work_order && this.state.form.work_order_area && (
										<Card>
											<div style={{ height: 300 }}>
												<MapWithKml
													myLat={this.state.form.work_order_area.latitude}
													myLng={this.state.form.work_order_area.longitude}
													workOrder={this.state.form.work_order}
													areas={[this.state.form.work_order_area]}
													showMarker
													height="100%"
													zIndexValue="1"
												/>
											</div>
										</Card>
									)} */}
								</FormLayout>
							</Card>
						</Layout.Section>
						<Layout.Section oneThird={showMap} oneHalf={!showMap}>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Registrerad tid
							</Text>
							<Text as="p" variant="bodyMd">
								Detta är tid rapporterad av användaren
							</Text>
							<Card sectioned>
								<DatePicker
									disabled={disabled}
									month={this.state.month}
									year={this.state.year}
									weekStartsOn={1}
									onChange={(value) => {
										this.setState({ selected: value });
									}}
									onMonthChange={(month, year) => {
										this.setState({ month, year });
									}}
									selected={this.state.selected}
									multiMonth={false}
									allowRange={false}
									disableDatesAfter={new Date()}
									disableDatesBefore={disabled ? new Date() : disableDatesBefore}
								/>
							</Card>

							<Card sectioned>
								<FormLayout />
								{this.state.form.type != "project" ? (
									<FormLayout>
										<Checkbox
											checked={this.state.form.whole_day}
											disabled={disabled}
											onChange={(val) => {
												this.state.form.whole_day = !this.state.form.whole_day;
												this.setState({ form: this.state.form });
											}}
											label="Hela dagen"
										/>
									</FormLayout>
								) : null}
								{!this.state.form.whole_day ? (
									this.state.form.report_type != "hours" ? (
										<FormLayout>
											<Stack distribution="fillEvenly">
												<TextField
													disabled={disabled}
													value={this.state.from_time}
													onChange={(value) => {
														this.setState({ from_time: value });
													}}
													label="Från tid"
													type="time"
												/>
												<TextField
													disabled={disabled}
													value={this.state.to_time}
													onChange={(value) => {
														this.setState({ to_time: value });
													}}
													label="Till tid"
													type="time"
												/>
											</Stack>
										</FormLayout>
									) : (
										<FormLayout>
											<TimePicker
												label="Timmar"
												value={this.state.hours + ""}
												onChange={(value) => {
													this.state.hours = value;
													this.getChangeHandler("hours");
												}}
												hours
												disabled={this.state.form.type == "weekend_salary"}
											/>
											<Stack distribution="fillEvenly">
												{this.state.form.type == "weekend_salary" ? (
													<ResourcePicker
														label="Arbetsorder"
														placeholder="Sök Arbetsorder"
														caption="Välj Arbetsorder"
														resource="work_orders.json"
														resourceHandle="work_orders"
														label_handle="full_label"
														resourceName={{
															singular: "arbetsorder",
															plural: "arbetsorder",
														}}
														item={this.state.form.work_order}
														onChange={(workOrder) => {
															this.state.form.work_order = workOrder;
															this.setState({ form: this.state.form });
														}}
													/>
												) : null}

												<TimePicker
													label="OB Timmar"
													value={this.state.ob_hours + ""}
													onChange={(value) => {
														this.state.ob_hours = value;
														this.getChangeHandler("ob_hours");
													}}
													hours
													disabled={this.state.form.type == "weekend_salary"}
												/>
												<TimePicker
													label="Övertidstimmar"
													value={this.state.overtime_hours + ""}
													onChange={(value) => {
														this.state.overtime_hours = value;
														this.getChangeHandler("overtime_hours");
													}}
													hours
													disabled={this.state.form.type == "weekend_salary"}
												/>
											</Stack>
											<Stack distribution="fillEvenly">
												<TextField
													type="number"
													suffix="SEK"
													label="Att vidarefakturera"
													value={this.state.amount_to_invoice}
													onChange={(value) => {
														this.state.amount_to_invoice = value;
														this.getChangeHandler("amount_to_invoice");
													}}
												/>
											</Stack>
										</FormLayout>
									)
								) : null}
								<FormLayout>
									<div style={{ marginTop: "20px" }} />
									<TextField label="Lönekostnad inkl avgifter" value={this.state.form.cost + ""} onChange={this.updateForm.bind(this, "cost")} />
									<TextField label="Kommentar" value={this.state.form.comment} onChange={this.updateForm.bind(this, "comment")} multiline={4} />
								</FormLayout>
							</Card>
						</Layout.Section>
						{showMap && (
							<Layout.Section oneThird>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Position
								</Text>
								<Text as="p" variant="bodyMd">
									Här visas vart personen befann sig vid in- och utcheckning. Av integritetsskäl spårar vi bara positionen exakt vid den tidpunkt man
									trycker på checka in/ut{" "}
								</Text>

								<Card sectioned>
									{!this.state.loading ? (
										<MapWithMultipleMarkers2 showMarker height="300px" zIndexValue="1" items={this.state.form} showCheckOut />
									) : (
										<Spinner size="small" color="teal" />
									)}
								</Card>
							</Layout.Section>
						)}
					</Layout>
				</CardsInPageWrapper>
				<PageActions
					primaryAction={
						store.getState().user.read_only
							? null
							: store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || !store.getState().account.disable_editing_group_leader
							? {
									content: "Uppdatera",
									disabled,
									loading: this.state.saving,
									onAction: this.saveForm.bind(this),
							  }
							: null
					}
					secondaryActions={
						store.getState().user.read_only
							? null
							: store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || !store.getState().account.disable_editing_group_leader
							? [
									{
										content: "Ta bort",
										destructive: true,
										disabled,
										loading: this.state.saving,
										onAction: this.removeModal.bind(this),
									},
							  ]
							: null
					}
				/>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort registrerad tid?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeTemplate.bind(this),
					}}
				>
					<Modal.Section>Är du säker?</Modal.Section>
				</Modal>
			</Page>
		);
	}
}

export default TimeReportEdit;

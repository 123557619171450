import { Button, FormLayout } from "@shopify/polaris";
import React, { useMemo } from "react";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { ColumnHeaderWrapper, ColumnHeader } from "../styles";
import FortnoxOfferRow from "./FortnoxOfferRow";

const FortnoxOfferRows = ({ offer, onChange, isSaving }) => {
	const handleChangeForm = (index: number, row: FortnoxOfferRowType | null) => {
		const rows = [...(offer.OfferRows || [])];
		if (!row) {
			rows.splice(index, 1);
		} else {
			rows[index] = row;
		}

		onChange("OfferRows")(rows);
	};

	const totalRow = useMemo(() => {
		const sum = offer?.OfferRows?.reduce((acc, row) => {
			return acc + parseFloat(String(row.Quantity || 0)) * parseFloat(String(row.Price || 0));
		}, 0);

		return {
			ArticleNumber: "",
			Description: "",
			Quantity: 0,
			Unit: "",
			Price: 0,
			Total: sum,
			sum,
		};
	}, [offer?.OfferRows]) as FortnoxOfferRowType;

	const disabled = !!offer?.OrderReference || isSaving || offer?.Cancelled;
	const rows = offer?.OfferRows || [];

	return (
		<FormLayout>
			<ColumnHeaderWrapper>
				<ColumnHeader>Artikelnr</ColumnHeader>
				<ColumnHeader>Benämning</ColumnHeader>
				<ColumnHeader>Antal</ColumnHeader>
				<ColumnHeader>Enhet</ColumnHeader>
				<ColumnHeader>À-Pris</ColumnHeader>
				<ColumnHeader>Summa</ColumnHeader>
			</ColumnHeaderWrapper>
			{rows?.map((row: FortnoxOfferRowType, index: number) => (
				<FortnoxOfferRow disabled={disabled} offer={offer} key={index} row={row} index={index} onChange={handleChangeForm} />
			))}
			{!disabled && <FortnoxOfferRow key={rows?.length} offer={offer} row={null} index={rows?.length} onChange={handleChangeForm} />}

			<FortnoxOfferRow
				prefix={
					!disabled && (
						<div>
							<Button
								onClick={() => {
									handleChangeForm(rows.length, {
										ArticleNumber: "",
										Description: "",
										Quantity: 0,
										Unit: "",
										Price: 0,
										Total: 0,
										sum: 0,
									} as FortnoxOfferRowType);
								}}
								primary
								icon={CirclePlusMajor}
							/>
						</div>
					)
				}
				plain
				disabled
				offer={offer}
				row={totalRow as FortnoxOfferRowType}
			/>
		</FormLayout>
	);
};
export default FortnoxOfferRows;

import React from "react";
import { DropZone } from "@shopify/polaris";
import { store } from "../store";
import Attachment from "./Attachment";

export default class Upload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openFileDialog: false,
			files: props.files || [],
		};
	}

	addPicture(f, event) {
		var data = event.target.result;
		var file = {
			id: null,
			name: f.name,
			title: f.name,
			size: f.size,
			type: f.type,
			url: data,
		};
		if (this.props.onClose) this.props.onClose();
		this.props.onUpload(file);
	}

	componentWillReceiveProps(props) {
		this.setState({ files: props.files, openFileDialog: props.open });
	}

	render() {
		const { files, openFileDialog } = this.state;

		return (
			<div>
				{this.props.label}
				<div style={{ position: "relative", width: this.props.width, height: this.props.height, display: this.props.hidden ? "none" : "initial" }}>
					<DropZone
						disabled={store.getState().user.read_only || this.props.disabled}
						outline
						openFileDialog={openFileDialog}
						accept={this.props.accept}
						size={this.props.size || "small"}
						type={this.props.type || "spreadsheet"}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							if (store.getState().user.read_only) return;

							for (var i = 0; i < acceptedFiles.length; i++) {
								var reader = new FileReader();
								reader.onload = this.addPicture.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
							this.setState({
								// eslint-disable-next-line react/no-unused-state
								rejectedFiles,
								// eslint-disable-next-line react/no-unused-state
								hasError: rejectedFiles.length > 0,
							});
						}}
						onFileDialogClose={() => {
							if (this.props.onClose) this.props.onClose();
							this.setState({ openFileDialog: false });
						}}
					>
						{this.props.width ? (
							files.length ? (
								<img
									style={{ width: this.props.width, position: "absolute", inset: 0, height: "100%", objectFit: "cover" }}
									src={files[0].url}
									alt=""
								/>
							) : null
						) : null}
						<DropZone.FileUpload actionTitle="Välj fil" actionHint="Eller dra och släpp en fil" />
					</DropZone>
					<div style={{ padding: "1rem" }}>
						{files &&
							!!files?.length &&
							files.map((file, index) => {
								if (!file.title && !file.filename) return null;
								return <Attachment key={index} index={index} file={file} removeFile={!this.props.disabled && this.props.removeFile} />;
							})}
					</div>
				</div>
			</div>
		);
	}
}

Upload.defaultProps = {
	hidden: true,
};

import React, { useEffect, useState, useMemo, useCallback } from "react";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import useQuery from "src/js/hooks/useQuery";
import SendAsEmailModal from "../../Fortnox/SendAsEmailModal";

type FortnoxInvoiceContextValueType = {
	form: any;
	getChangeHandler: (key: string) => (value: any) => void;
	isLoading: boolean;
	id: string;
	basisStatistics: any;
	isLoadingBasisStatistics: boolean;
	handleSave: (invoice?: FortnoxInvoiceType) => void;
	isSaving: boolean;
	handleCancel: () => void;
	isCanceling: boolean;
	PDF: any;
	handleSendInvoiceAsEmailPre: () => void;
	handleSetAsSentInvoice: () => void;
	handleSendInvoiceAsEInvoice: () => void;
	handleBookkeepInvoice: () => void;
	handleCreditInvoice: () => void;
};

const FortnoxInvoiceContext = React.createContext(null as unknown as FortnoxInvoiceContextValueType);

const FortnoxInvoiceProvider = ({ children, ...props }) => {
	const id = props.match?.params?.id;
	const [form, setForm] = useState<any>({
		project: props?.location?.state?.project,
	});
	// const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isCanceling, setIsCanceling] = useState(false);
	const [isLoadingBasisStatistics, setIsLoadingBasisStatistics] = useState(false);
	const [originlBasisStatistics, setBasisOriginlStatistics] = useState<any>({});
	const [PDF, setPDF] = useState<any>(null);
	const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);

	const queryClient = useQueryClient();

	const fetchInvoice = useCallback(async () => {
		if (!id) return;

		try {
			const response = await API.get(`/api/external_invoices/${id}.json`);
			setForm(response.data);

			return response.data;
		} catch (error) {
			toastr.error(error);
			return null;
		}
	}, [id]);

	const queryKey = [id && `fortnox_invoice_${id}`].filter(Boolean);
	const { data: invoice = null, isFetching: isLoading }: UseQueryResult<FortnoxInvoiceType | null> = useQuery({
		queryKey,
		queryFn: fetchInvoice,
		refetchOnWindowFocus: false,
		initialData: {
			project: props?.location?.state?.project,
		},
		enabled: !!id,
	});

	const fetchBasisStatistics = useCallback(async () => {
		if (!form?.project?.id) return;
		setIsLoadingBasisStatistics(true);
		const response = await API.get(`/api/projects/${form?.project?.id}/invoice_data.json`);
		setBasisOriginlStatistics(response.data.statistics);
		setIsLoadingBasisStatistics(false);
	}, [form?.project?.id]);

	// useEffect(() => {
	// 	fetchBasisStatistics();
	// }, [fetchBasisStatistics]);

	const fetchInvoicePDF = useCallback(async () => {
		if (!id) return;

		try {
			const response = await API.get(`/api/external_invoices/${id}/download.json`);
			setPDF(response.data);
			return response.data;
		} catch (error) {
			toastr.error(error);
		}
	}, [id]);

	const refresh = useCallback(async () => {
		fetchBasisStatistics();
		fetchInvoicePDF();
	}, [fetchBasisStatistics, fetchInvoicePDF]);

	useEffect(() => {
		if (invoice) {
			setForm(invoice);
			refresh();
		}
	}, [invoice, refresh]);

	const saveFunction = useCallback(
		async ({ invoice = form } = { invoice: form }) => {
			try {
				if (invoice.DocumentNumber) {
					const response = await API.put(`/api/external_invoices/${invoice.DocumentNumber}.json`, invoice);
					setForm(response.data);
					toastr.success("Fakturan uppdaterad");

					return response;
				} else {
					const response = await API.post(`/api/external_invoices.json`, invoice);
					setForm(response.data);
					toastr.success("Fakturan skapad");
					props.history.push(`/admin/invoices/${response.data.DocumentNumber}`);

					return response;
				}
			} catch (error) {
				toastr.error(error);
			}
		},
		[form, props.history]
	);

	const update = async ({ invoice }) => {
		const response = await saveFunction(invoice);
		return response?.data;
	};

	const mutation = useMutation({
		mutationFn: update,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});

	const handleSave = useCallback(
		async (invoice: FortnoxInvoiceType = form) => {
			try {
				if (!invoice) {
					throw new Error("no invoice");
				}

				setIsSaving(true);
				return await mutation.mutateAsync({ invoice });
			} catch (e: any) {
				if (!e?.response) throw e;
				toastr.error(e);
			} finally {
				setIsSaving(false);
			}
		},
		[mutation, form]
	);

	const handleCancel = useCallback(async () => {
		setIsCanceling(true);
		try {
			if (!form.DocumentNumber) return;

			const response = await API.delete(`/api/external_invoices/${form.DocumentNumber}.json`, form);
			toastr.success("Fakturan makulerad");

			setForm(response.data);

			props.history.goBack();
		} catch (error) {
			toastr.error(error);
		} finally {
			setIsCanceling(false);
		}
	}, [form, props.history]);

	const getChangeHandler = useCallback((key: string) => {
		return (value) => {
			setForm((c) => {
				const newForm = { ...(c || {}), [key]: value };

				return newForm;
			});
		};
	}, []);

	const basisStatistics = useMemo(() => {
		const stats = Object.entries(originlBasisStatistics).reduce((acc, [key, obj]: any) => {
			const unsavedItems =
				form?.InvoiceRows?.filter((r) => !r.RowId)
					?.flatMap((row: any) => row?.[key])
					?.filter(Boolean) || [];
			const unsavedValue = unsavedItems.reduce((acc: number, item: any) => {
				return acc + parseFloat(String(item.amount_to_invoice || item.cost || item.subtotal || 0));
			}, 0);

			return {
				...acc,
				[key]: {
					...(obj as Record<string, unknown>),
					total: obj.total - unsavedValue,
					count: obj.count - unsavedItems.length,
				},
			};
		}, {} as Record<string, unknown>);

		return stats;
	}, [originlBasisStatistics, form?.InvoiceRows]);

	const handleSendInvoiceAsEmailPre = useCallback(async () => {
		setEmailModalIsOpen(true);
	}, []);

	const sendAsEmail = async ({ invoice }) => {
		try {
			const res = await API.get(`/api/fortnox/invoices/${invoice.DocumentNumber}/email.json`);
			toastr.success("Fakturan skickad via email");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return invoice;
		}
	};
	const mutationSendInvoiceAsEmail = useMutation({
		mutationFn: sendAsEmail,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});
	const handleSendInvoiceAsEmail = useCallback(async () => {
		return await mutationSendInvoiceAsEmail.mutateAsync({ invoice });
	}, [invoice, mutationSendInvoiceAsEmail]);

	const setAsSent = async ({ invoice }) => {
		try {
			const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/externalprint.json`);
			toastr.success("Fakturan markerad som skickad");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return invoice;
		}
	};
	const mutationSetAsSentInvoice = useMutation({
		mutationFn: setAsSent,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});
	const handleSetAsSentInvoice = useCallback(async () => {
		return await mutationSetAsSentInvoice.mutateAsync({ invoice });
	}, [invoice, mutationSetAsSentInvoice]);

	const sendInvoiceAsEInvoice = async ({ invoice }) => {
		try {
			const res = await API.get(`/api/fortnox/invoices/${invoice.DocumentNumber}/einvoice.json`);
			toastr.success("Fakturan markerad som skickad");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return invoice;
		}
	};
	const mutationSendInvoiceAsEInvoice = useMutation({
		mutationFn: sendInvoiceAsEInvoice,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});
	const handleSendInvoiceAsEInvoice = useCallback(async () => {
		return await mutationSendInvoiceAsEInvoice.mutateAsync({ invoice });
	}, [invoice, mutationSendInvoiceAsEInvoice]);

	const bookKeep = async ({ invoice }) => {
		try {
			const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/bookkeep.json`);
			toastr.success("Fakturan bokförd");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return invoice;
		}
	};
	const mutationBookKeepInvoice = useMutation({
		mutationFn: bookKeep,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});
	const handleBookkeepInvoice = useCallback(async () => {
		return await mutationBookKeepInvoice.mutateAsync({ invoice });
	}, [invoice, mutationBookKeepInvoice]);

	const credit = async ({ invoice }) => {
		try {
			const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/credit.json`);
			toastr.success("Fakturan krediterad");
			return res.data;
		} catch (error) {
			toastr.error(error);
			return invoice;
		}
	};
	const mutationCreditInvoice = useMutation({
		mutationFn: credit,
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});
	const handleCreditInvoice = useCallback(async () => {
		return await mutationCreditInvoice.mutateAsync({ invoice });
	}, [invoice, mutationCreditInvoice]);

	const contextValue = useMemo(
		() => ({
			form,
			getChangeHandler,
			isLoading,
			id,
			basisStatistics,
			isLoadingBasisStatistics,
			handleSave,
			isSaving,
			handleCancel,
			isCanceling,
			PDF,
			handleSendInvoiceAsEmailPre,
			handleSetAsSentInvoice,
			handleSendInvoiceAsEInvoice,
			handleBookkeepInvoice,
			handleCreditInvoice,
		}),
		[
			form,
			getChangeHandler,
			isLoading,
			id,
			basisStatistics,
			isLoadingBasisStatistics,
			handleSave,
			isSaving,
			handleCancel,
			isCanceling,
			PDF,
			handleSendInvoiceAsEmailPre,
			handleSetAsSentInvoice,
			handleSendInvoiceAsEInvoice,
			handleBookkeepInvoice,
			handleCreditInvoice,
		]
	);

	return (
		<FortnoxInvoiceContext.Provider value={contextValue}>
			<SendAsEmailModal
				invoice={form}
				loading={isSaving}
				type="invoice"
				open={!!emailModalIsOpen}
				onClose={() => setEmailModalIsOpen(false)}
				EmailInformation={form?.EmailInformation}
				onSend={async (EmailInformation: EmailInformation) => {
					const newForm = { EmailInformation };

					try {
						const res = !form?.Cancelled ? await handleSave(newForm as FortnoxInvoiceType) : true;
						if (res) {
							const response = await handleSendInvoiceAsEmail();
							setEmailModalIsOpen(false);
							return response;
						}
					} catch (error) {
						console.error("error:", error);
					}
				}}
			/>
			{children}
		</FortnoxInvoiceContext.Provider>
	);
};

export { FortnoxInvoiceContext, FortnoxInvoiceProvider };

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonGroup, FormLayout, Layout, Tabs } from "@shopify/polaris";
import { CancelMajor, CreditCardCancelMajor, EmailMajor, ExternalMinor, ReceiptMajor, SendMajor, VocabularyMajor } from "@shopify/polaris-icons";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Page from "src/js/components/page";
import Card from "src/js/components/Card";
import TextField from "src/js/components/TextField";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import styled from "styled-components";
import SearchField from "src/js/components/SearchField";
import Button from "src/js/components/Button";
import Spinner from "src/js/components/Spinner";
import FortnoxInvoiceRows from "./FortnoxInvoiceRows";
import { FortnoxInvoiceContext, FortnoxInvoiceProvider } from "./FortnoxInvoiceContext";
import InvoiceBasisViews from "./InvoiceBasisViews";
import { convertBasisToFortnoxInvoiceRows } from "./Utilities/convertBasisToFortnoxInvoiceRows";
import getBasisTabs from "./Utilities/getBasisTabs";
import Header from "./Header";
import FortnoxPDFView from "../../Fortnox/FortnoxPDFView";

const FortnoxInvoice = ({ history, location }) => {
	const {
		form,
		getChangeHandler,
		isLoading,
		isLoadingBasisStatistics,
		basisStatistics,
		handleSave,
		isSaving,
		handleCancel,
		isCanceling,
		PDF,
		handleSendInvoiceAsEmailPre,
		handleSetAsSentInvoice,
		handleSendInvoiceAsEInvoice,
		handleBookkeepInvoice,
		handleCreditInvoice,
	} = useContext(FortnoxInvoiceContext);
	const [selectedInvoiceBasisTab, setSelectedInvoiceBasisTab] = useState(null);
	const invoiceBasisTabs = getBasisTabs(form).filter((tab) => !("enable" in tab) || tab.enable);

	const handleBasisTabChange = useCallback((selectedTab) => setSelectedInvoiceBasisTab(selectedTab), []);
	const [selectedTabId, setSelectedTabId] = useState("info");

	const handleBasisChange = useCallback(
		({ key, items, options }) => {
			// const currentItems = form[key] || [];
			// const newItems = [...currentItems, ...items];
			// getChangeHandler(key)(newItems);

			const currentInvoiceRows = form?.InvoiceRows || [];
			const convertedItems = convertBasisToFortnoxInvoiceRows(key, items, options);

			getChangeHandler("InvoiceRows")([...currentInvoiceRows, ...convertedItems]);
		},
		[form, getChangeHandler]
	);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "info",
					content: "Info",
					render: (
						<Layout>
							<Layout.Section>
								<Card sectioned>
									<Layout>
										<Layout.Section>
											<SearchField
												onClearButtonClick={getChangeHandler("project")}
												clearButton
												placeholder="Sök projekt"
												caption="Välj project"
												resource="projects.json"
												resourceName={{
													singular: "Projekt",
													plural: "Projekt",
												}}
												onChange={getChangeHandler("project")}
												label="Projekt"
												label_handle="full_label"
												textAlign="left"
												value={form?.project?.full_label || form?.project?.title}
												fullWidth={false}
												suffix={form?.project?.id && <Button plain icon={ExternalMinor} url={`/admin/projects/${form?.project?.id}`} />}
											/>
										</Layout.Section>
									</Layout>
									<FormLayout>
										<Layout>
											<Layout.Section oneHalf>
												<FormLayout>
													<TextField label="OCR" value={form?.OCR} onChange={getChangeHandler("OCR")} />
													<TextField label="Er referens" value={form?.YourReference} onChange={getChangeHandler("YourReference")} />
													<TextField label="Vår referens" value={form?.OurReference} onChange={getChangeHandler("OurReference")} />
													<TextField label="Fakturadatum" value={form?.InvoiceDate} onChange={getChangeHandler("InvoiceDate")} />
													<TextField label="Förfallodatum" value={form?.DueDate} onChange={getChangeHandler("DueDate")} />
													<TextField label="Ert ordernummer" value={form?.YourOrderNumber} onChange={getChangeHandler("YourOrderNumber")} />
												</FormLayout>
											</Layout.Section>
											<Layout.Section oneHalf>
												<FormLayout>
													<TextField label={"Adress"} value={form.Address1} onChange={getChangeHandler("Address1")} />
													<TextField label={"Postnummer"} value={form.ZipCode} onChange={getChangeHandler("ZipCode")} />
													<TextField label={"Ort"} value={form.City} onChange={getChangeHandler("City")} />
													<TextField label={"Land"} value={form.Country} onChange={getChangeHandler("Country")} />
													<TextField label={"Anteckning"} value={form.Remarks} onChange={getChangeHandler("Remarks")} multiline={3} />
												</FormLayout>
											</Layout.Section>
										</Layout>
									</FormLayout>
								</Card>
							</Layout.Section>
							<Layout.Section>
								<Card sectioned>
									<FortnoxInvoiceRows
										isSaving={false}
										invoice={form}
										onChange={getChangeHandler}
										// onRemove={onRemove}
									/>
									{form?.project && (
										<ButtonsContainer>
											<ButtonGroup>
												{invoiceBasisTabs.map((tab, index) => {
													const { content, id } = tab;
													return (
														<Button
															key={id}
															primary={basisStatistics[id]?.total > 0}
															tooltip={tab?.tooltip}
															disabled={!!tab?.disabled || basisStatistics[id]?.total <= 0}
															// disabled={basisStatistics[id]?.total <= 0}
															onClick={() => handleBasisTabChange(tab)}
														>
															{content}

															{isLoadingBasisStatistics ? (
																<Spinner size="small" style={{ marginLeft: "1ch" }} />
															) : (
																((
																	<>
																		{basisStatistics?.[id] && "total" in basisStatistics[id] && <span>{`${basisStatistics[id]?.total || 0}kr`}</span>}
																		{!!basisStatistics[id]?.total && <span>{`(${basisStatistics[id]?.count || 0}st)`}</span>}
																	</>
																) as any)
															)}
														</Button>
													);
												})}
											</ButtonGroup>
										</ButtonsContainer>
									)}
									{/* <Tabs tabs={invoiceBasisTabs} selected={selectedInvoiceBasisTabIndex} /> */}
								</Card>
							</Layout.Section>
						</Layout>
					),
				},
				{
					id: "pdf",
					content: "PDF",
					render: <FortnoxPDFView history={history} pdf={PDF} loading={isLoading} />,
					// render: null,
					enabled: !!form?.DocumentNumber,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, form, isLoading, PDF, invoiceBasisTabs, isLoadingBasisStatistics, basisStatistics, handleBasisTabChange, getChangeHandler]
	);
	const handleTabChange = useCallback(
		(index) => {
			const tab = tabs[index];

			setSelectedTabId(tab?.id);
		},
		[tabs]
	);

	if (selectedInvoiceBasisTab !== null) {
		return (
			<InvoiceBasisViews
				tab={selectedInvoiceBasisTab}
				history={history}
				location={location}
				onClose={() => {
					setSelectedInvoiceBasisTab(null);
				}}
				onChange={handleBasisChange}
				invoice={form}
			/>
		);
	}

	const tabIndex = tabs.findIndex((t) => t.id === selectedTabId);
	const tab = tabs[tabIndex];

	const sendActions = [
		{
			content: "Skicka som e-post",
			onAction: handleSendInvoiceAsEmailPre,
			icon: EmailMajor,
			enabled: !!form?.DocumentNumber,
		},
		{
			content: "Skicka som e-invoice",
			onAction: handleSendInvoiceAsEInvoice,
			icon: ReceiptMajor,
			enabled: !!form?.DocumentNumber,
			confirmation: {
				title: "Skicka som e-invoice",
				content: "Är du säker på att du vill skicka fakturan som e-invoice?",
				confirm: "Skicka som e-invoice",
				cancel: "Avbryt",
			},
		},
		{
			content: "Markera som skickad",
			icon: SendMajor,
			onAction: handleSetAsSentInvoice,
			enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.Sent),
			confirmation: {
				title: "Markera som skickad",
				content: "Är du säker på att du vill markera fakturan som skickad?",
				confirm: "Markera som skickad",
				cancel: "Avbryt",
			},
		},
	];

	return (
		<Page
			title={(isLoading ? <Spinner size="small" /> : form?.DocumentNumber ? `Fortnox faktura #${form?.DocumentNumber}` : "Skapa faktura") as string}
			fullWidth
			// primaryAction={{
			// 	content: "Spara",
			// 	loading: isSaving,
			// 	onAction: handleSave,
			// }}
			primaryAction={
				<Button
					primary
					onClick={() => handleSave()}
					connectedDisclosure={{
						actions: sendActions,
					}}
					loading={isSaving}
					disabled={!form || form?.Cancelled}
				>
					{form?.DocumentNumber ? "Spara" : "Skapa"}
				</Button>
			}
			secondaryActions={[
				{
					content: "Makulera fakturan",
					icon: CancelMajor,
					onAction: handleCancel,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled),
					destructive: true,
					confirmation: {
						title: "Makulera Fakturan",
						content: "Är du säker på att du vill makulera fakturan?",
						confirm: "Makulera Fakturan",
						cancel: "Avbryt",
					},
				},
				{
					content: "Bokför",
					icon: VocabularyMajor,
					onAction: handleBookkeepInvoice,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.Booked),
					confirmation: {
						title: "Bokför Fakturan",
						content: "Är du säker på att du vill bokföra fakturan?",
						confirm: "Bokför Fakturan",
						cancel: "Avbryt",
					},
				},
				{
					content: "Kreditera fakturan",
					icon: CreditCardCancelMajor,
					onAction: handleCreditInvoice,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.CreditInvoiceReference && form?.Booked),
					confirmation: {
						title: "Kreditera Fakturan",
						content: "Är du säker på att du vill kreditera fakturan?",
						confirm: "Kreditera Fakturan",
						cancel: "Avbryt",
					},
				},

				{
					title: "Skicka",
					icon: SendMajor,
					actions: sendActions,
				},
			].filter((a) => !("enabled" in a) || a.enabled)}
		>
			<Wrapper loading={isLoading}>
				<Header invoice={form} tabs={tabs} selected={tabIndex} onSelect={handleTabChange} />

				{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
			</Wrapper>
		</Page>
	);
};

export default (props) => {
	return (
		<FortnoxInvoiceProvider {...props}>
			<FortnoxInvoice {...props} />
		</FortnoxInvoiceProvider>
	);
};

const Wrapper = styled.div<{ loading: boolean }>`
	transition: opacity 250ms;

	opacity: ${({ loading }) => (loading ? 0.5 : 1)};
	pointer-events: ${({ loading }) => (loading ? "none" : "all")};
`;

const ButtonsContainer = styled.div`
	margin-top: 1rem;

	.Polaris-Button__Text {
		display: flex;
		gap: 0.5ch;
	}

	/* .Polaris-Layout:not(.Polaris-Layout__Section--oneHalf) {
	} */
`;

import { Component } from "react";
import { Badge, Stack } from "@shopify/polaris";
import Moment from "react-moment";

import { store } from "src/js/store/index.js";
import Money from "../../components/Money.js";
import IndexTable from "../../components/IndexTable";

class ExpenseIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderCell(item, column) {
		switch (column.handle) {
			case "TITLE":
				return (
					<>
						<b>{item.title}</b> {item.favorite && <Badge status="success">Favorit</Badge>}
					</>
				);
			case "CREATED_AT":
				return (
					<Moment locale="sv" format="YYYY-MM-DD">
						{item.created_at}
					</Moment>
				);
			case "PRICE":
				return <Money amount={item.price * 100} currency="SEK" />;
			case "PURCHASE_PRICE":
				return <Money amount={item.purchase_price * 100} currency="SEK" />;
			case "TAGS":
				return (
					<Stack spacing="tight">
						{item.tags?.map((tag, index) => {
							return <Badge key={tag.id || index}>{tag.title}</Badge>;
						})}
					</Stack>
				);
			default:
				return null;
		}
	}

	render() {
		var filters = [
			{
				key: "favorite",
				label: "Favorit",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				label: "Titel",
				handle: "TITLE",
				sortable: !store.getState().account.fortnoxConnection,
			},
			{
				label: "Pris",
				handle: "PRICE",
				sortable: !store.getState().account.fortnoxConnection,
			},
			{
				label: "Inköpspris",
				handle: "PURCHASE_PRICE",
				sortable: !store.getState().account.fortnoxConnection,
			},
			{
				label: "Taggar",
				handle: "TAGS",
			},
			{
				label: "Skapad",
				handle: "CREATED_AT",
				sortable: !store.getState().account.fortnoxConnection,
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					columns={columns}
					renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.props.history.push("/admin/expenses/" + item.id);
					}}
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Artikelregister"
					primaryAction={{
						content: "Ny artikel",
						onAction: () => {
							this.props.history.push("/admin/expenses/new");
						},
					}}
					savedSearchHandle="expenses"
					resourceUrl="/api/expenses.json?time=0"
					resourceHandle="expenses"
					resourceName={{
						singular: "artikel",
						plural: "artiklar",
					}}
					filters={filters}
				/>
			</div>
		);
	}
}

export default ExpenseIndex;

/* eslint-disable react/jsx-props-no-spreading */
import { ButtonProps as PolarisButtonProps, Button as PolarisButton, Tooltip } from "@shopify/polaris";
import React from "react";

type ButtonProps = {
	tooltip?: string;
} & PolarisButtonProps;

const Button = ({ loading, children, tooltip, ...props }: ButtonProps) => {
	if (tooltip) {
		return (
			<Tooltip content={tooltip}>
				<PolarisButton loading={loading} {...props}>
					{children}
				</PolarisButton>
			</Tooltip>
		);
	}

	return (
		<PolarisButton loading={loading} {...props}>
			{children}
		</PolarisButton>
	);
};
export default Button;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
	TextField,
	IndexFilters as PolarisIndexFilters,
	useSetIndexFiltersMode,
	Select,
	SortButtonChoice,
	OptionList,
	FormLayout,
	Stack,
	Tag,
	DatePicker,
	Modal,
	Button,
	ChoiceList,
} from "@shopify/polaris";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IndexFiltersProps as PolarisIndexFiltersProps, AlphaTabProps, AppliedFilterInterface } from "@shopify/polaris";
import { useState, useCallback, useImperativeHandle, forwardRef } from "react";
import { debounce } from "lodash";
import { useDeepCompareCallback, useDeepCompareEffect } from "use-deep-compare";
import moment from "moment";
import { store } from "../../store";
import API from "../../API";
import { toastr } from "../toastr";
import {
	filterArrayToObject,
	getGroupFromId,
	getProjectFromId,
	getUserFromId,
	isEmpty,
	isSameSearch,
	polarisSortValueTosSortValue,
	sortValueToPolarisSortValue,
} from "./Utilities";
import SavedSearchModal from "./SavedSearchModal";
import type { SavedSearch, SavedSearchFilter, Filters, AppliedFilters } from "./types";
import SearchField from "../search_field";
import OnMount from "../OnMount";
import { UsersFilter } from "./filters";

type IndexFiltersProps = {
	defaultSavedSearches?: SavedSearch[] | any;
	onChange?: any;
	filters?: Filters;
	appliedFilters?: AppliedFilters;
	onChangeFilters?: any;
	handle?: any;
	resource?: any;
	search?: any;
	onSearchChange?: any;
	loading?: any;

	onSortChange?: any;
	sortValue?: any;
	sortOptions?: any;
	resourceName?: any;
	defaultFilters?: SavedSearchFilter[];
	handleFiltersClearAll: () => void;
	selected?: number;
};

type DatePickerOptions = {
	open: boolean;
	filter?: any;
	month?: number;
	year?: number;
	selection?: any;
};

const IndexFilters = forwardRef(
	(
		{
			defaultSavedSearches: propsDefaultSavedSearches = [],
			onChange,
			filters = [],
			appliedFilters = [],
			onChangeFilters,
			handle: propsHandle,
			resource,
			search,
			onSearchChange,
			loading: parentLoading,
			onSortChange,
			sortValue,
			sortOptions,
			defaultFilters,
			handleFiltersClearAll,
			selected: propsSelected,
		}: IndexFiltersProps,
		ref
	) => {
		const defaultSavedSearches: SavedSearch[] | any = [
			{
				id: "all",
				content: "Alla",
				title: "Alla",
				accessibilityLabel: "Alla",
				panelID: "all-tab",
				filters: defaultFilters || [],
			},
			...(propsDefaultSavedSearches || []),
		];
		const handle = propsHandle || resource;
		const [savedSeaches, setSavedSeaches] = useState<SavedSearch[]>(defaultSavedSearches || []);
		const [loading, setLoading] = useState(false);
		const [savingView, setSavingView] = useState(false);
		const [selected, setSelected] = useState(propsSelected || 0);
		const [datePickerOptions, setDatePickerOptions] = useState<DatePickerOptions>({
			open: false,
			filter: null,
			month: new Date().getMonth(),
			year: new Date().getFullYear(),
			selection: null,
		});
		const [filterObject, setFilterObject] = useState(filterArrayToObject(appliedFilters));
		const [viewInEdit, setViewInEdit] = useState<SavedSearch | null>(null);
		const [projects, setProjects] = useState<any>({});

		const setSortChange = (value: (SortButtonChoice["value"] | string)[]) => {
			onSortChange(polarisSortValueTosSortValue(value?.[0]));
		};

		const loadSavedSearches = useDeepCompareCallback(
			(handle: string) => {
				setLoading(true);
				API.get("/api/saved_searches/" + handle + ".json")
					.then((result) => {
						setSavedSeaches([].concat(defaultSavedSearches || [], result.data.saved_searches));
						// this.updateSelection(this.state.filters, this.state.search, this.state.sorting);
					})
					.catch((error) => {
						toastr.error(error);
					})
					.finally(() => {
						setLoading(false);
					});
			},
			[defaultSavedSearches]
		);

		const onSelectView = (index: number) => {
			setSelected(index);
			onChange(savedSeaches[index]);
		};

		const deleteView = (index: number, id: number | string) => {
			API.delete("/api/saved_searches/" + id + ".json")
				.then(() => {
					const newSavedSearches = [...savedSeaches];
					newSavedSearches.splice(index, 1);
					setSavedSeaches(newSavedSearches);
					onSelectView(Math.max(0, index - 1));
				})
				.catch((error) => {
					toastr.error(error);
				});
		};

		useDeepCompareEffect(() => {
			loadSavedSearches(handle);
			const matchedSearchFromUrlIndex = savedSeaches.findIndex((savedSearch) => isSameSearch(savedSearch, appliedFilters, search, sortValue));

			if (matchedSearchFromUrlIndex !== -1) {
				onSelectView(matchedSearchFromUrlIndex);
				// onChange(savedSeaches[matchedSearchFromUrlIndex]);
			}
		}, [handle, loadSavedSearches]);

		useDeepCompareEffect(() => {
			const filterObj = filterArrayToObject(appliedFilters);
			setFilterObject(filterObj);
		}, [appliedFilters]);

		const tabs = savedSeaches.map((item, index) => ({
			content: item.title,
			index,
			onAction: () => {},
			// id: `${item}-${index}`,
			id: item.id,
			isLocked: index === 0,
			viewNames: [],
			actions:
				index === 0 || defaultSavedSearches?.find((defaultItem: SavedSearch) => item.id === defaultItem.id)
					? []
					: [
							{
								type: "edit",
								content: "Redigera",
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								onAction: (title: string) => {
									setViewInEdit(item);
								},
							},
							{
								type: "delete",
								content: "Ta bort",
								onAction: () => {},
								onPrimaryAction: async (): Promise<boolean> => {
									deleteView(index, item.id);
									return true;
								},
							},
					  ],
		}));

		const onCreateNewView = async (title: string) => {
			setSavingView(true);
			API.post("/api/saved_searches/" + handle + ".json", {
				title,
				filters: appliedFilters,
				search: search || "",
				// sorting: this.state.sorting,
			})
				.then((result) => {
					setSavedSeaches([...savedSeaches, result.data.saved_search]);
				})
				.catch((error) => {
					toastr.error(error);
				})
				.finally(() => {
					setSavingView(false);
				});

			return true;
		};

		const { mode, setMode } = useSetIndexFiltersMode();
		const onHandleCancel = () => {
			// setMode("view");
		};

		const onHandleSave = async () => {
			const view = savedSeaches[selected];
			setSavingView(true);
			API.put(`/api/saved_searches/${view.id}.json`, { ...view, filters: appliedFilters, search: search || "" })
				.then(() => {
					if (view) {
						const index = savedSeaches.findIndex((savedSearch) => savedSearch.id === view.id);
						if (index >= 0) {
							const newSavedSearches = [...savedSeaches];
							newSavedSearches[index] = view;
							setSavedSeaches(newSavedSearches);
						}
					}
				})
				.catch((error) => {
					toastr.error(error);
				})
				.finally(() => {
					setSavingView(false);
				});

			return true;
		};

		const handleChangeFilters = useCallback(
			(filters) => {
				const arr = Object.entries(filters)
					.map(([key, value]) => ({ key, value }))
					.filter(({ value }: any) => !isEmpty(value));

				onChangeFilters(arr);
			},
			[onChangeFilters]
		);
		const handleChangeFiltersDebounced = debounce((filters) => handleChangeFilters(filters), 300);

		const handleSetValue = useCallback(
			(filter: any, value: any, delayed = false) => {
				const { key, type } = filter || {};

				if (type == "singlearray") {
					value = value[0];
				} else if (type == "dateSelector" && (value == "custom" || value.substring(0, 1) == "2" || value.substring(0, 1) == "1")) {
					let now = new Date();
					let fromDate = new Date();
					let toDate = new Date();
					toDate.setDate(toDate.getDate() + 7);
					if (value.substring(0, 1) == "2" || value.substring(0, 1) == "1") {
						if (filter.range) {
							const pcs = value.split(" - ");
							now = new Date(pcs[0]);
							fromDate = new Date(pcs[0]);
							toDate = new Date(pcs[1]);
						} else {
							now = new Date(value);
							toDate = new Date(value);
							fromDate = new Date(value);
						}
					}

					setDatePickerOptions({
						open: true,
						month: now.getMonth(),
						year: now.getFullYear(),
						filter,
						selection: {
							start: fromDate,
							end: toDate,
						},
					});

					return;
					//
				} else if (type == "tag" || type == "contact_tag") {
					value = value.title;
				} else if (type == "resource" || type == "user" || type == "group") {
					value = value.id;
				} else if (type == "project") {
					setProjects((c: any) => ({ ...c, [value.id]: value }));
					value = value.id;
				}
				const filters = { ...filterObject };
				filters[key] = value;
				setFilterObject(filters);
				if (delayed) {
					handleChangeFiltersDebounced(filters);
				} else {
					handleChangeFilters(filters);
				}
			},
			[handleChangeFilters, handleChangeFiltersDebounced, filterObject]
		);

		const primaryAction: PolarisIndexFiltersProps["primaryAction"] =
			selected <= (defaultSavedSearches?.length || 0) - 1
				? {
						type: "save-as",
						onAction: onCreateNewView,
						disabled: savingView,
						loading: savingView,
				  }
				: {
						type: "save",
						onAction: onHandleSave,
						disabled: savingView,
						loading: savingView,
				  };

		const handleRemoveFilter = useCallback(
			(key: string) => {
				const filters = { ...filterObject };
				delete filters[key];
				setFilterObject(filters);
				handleChangeFilters(filters);
			},
			[filterObject, handleChangeFilters]
		);

		const transformFiltersToComponents = (filters) => {
			return filters.map((filter) => {
				// const value: any = appliedFilters.find((appliedFilter) => appliedFilter?.key === filter.key)?.value;
				const value: any = filterObject[filter?.key];

				if (filter.type === "select") {
					return {
						...filter,
						filter: (
							// <Select
							// 	label={filter.label}
							// 	labelHidden
							// 	options={filter.options}
							// 	onChange={(value) => handleSetValue(filter, value)}
							// 	value={appliedFilters.find((appliedFilter) => appliedFilter?.key === filter.key)?.value}
							// />

							<OptionList options={filter.options} selected={value ? [value] : []} onChange={(value) => handleSetValue(filter, value?.[0])} />
						),
					};
				} else if (filter.type === "date") {
					return {
						...filter,
						hideClearButton: true,
						filter: (
							<>
								<OnMount
									onMount={() => {
										setDatePickerOptions({
											open: true,
											month: new Date(value || undefined).getMonth(),
											year: new Date(value || undefined).getFullYear(),
											filter,
											selection: {
												start: new Date(value || undefined),
												end: new Date(value || undefined),
											},
										});
									}}
								/>
								<Button
									plain
									removeUnderline
									onClick={() => {
										setDatePickerOptions({
											open: true,
											month: new Date(value || undefined).getMonth(),
											year: new Date(value || undefined).getFullYear(),
											filter,
											selection: {
												start: new Date(value || undefined),
												end: new Date(value || undefined),
											},
										});
									}}
								>
									{value ? moment(value).format("YYYY-MM-DD") : "Välj datum"}
								</Button>
							</>
						),
					};
				} else if (filter.type === "dateSelector") {
					const options = getDateOptions(filter);
					// filter.type = "singlearray";
					return {
						...filter,
						filter: (
							<div style={{ marginLeft: "-1.6rem", marginRight: "-1.6rem" }}>
								<OptionList options={options} selected={value ? [value] : []} onChange={(value) => handleSetValue(filter, value?.[0])} />
							</div>
						),
					};
				} else if (filter.type === "textField") {
					return {
						...filter,
						filter: (
							<FormLayout>
								<Select
									label=""
									labelHidden
									options={[
										{ label: "Är lika med", value: "equals" },
										{ label: "Innehåller", value: "contains" },
										{ label: "Börjar med", value: "starts_with" },
										{ label: "Slutar med", value: "ends_with" },
									]}
								/>
								<TextField label="" labelHidden autoComplete="" value={value} onChange={(v) => handleSetValue(filter, v, true)} />
							</FormLayout>
						),
					};
				} else if (filter.type === "contact_tag") {
					return {
						...filter,
						filter: (
							<Stack vertical>
								<SearchField
									className="filter-search"
									resource="contacts/tags"
									inline
									autoFocus
									onSelect={(value: any) => handleSetValue(filter, value)}
									resourceName={{ singular: "tagg", plural: "taggar" }}
									resource_handle="tags"
									id_handle="title"
									label_handle="title"
								/>
								{value ? <Tag onRemove={() => handleRemoveFilter(filter.key)}>{value}</Tag> : null}
							</Stack>
						),
					};
				} else if (filter.type === "groupuser") {
					return {
						...filter,
						filter: (
							<Stack vertical>
								<SearchField
									className="filter-search"
									inline
									autoFocus
									resource="usergroups.json?enabled=1"
									items={store.getState().groups.concat(store.getState().users)}
									onSelect={(v) => {
										if (v.is_group == "1" || v.is_group == 1) {
											handleSetValue({ key: "group_id", type: "resource" }, v);
											handleRemoveFilter("user_id");
										} else {
											handleSetValue({ key: "user_id", type: "resource" }, v);
											handleRemoveFilter("group_id");
										}
									}}
									resourceName={{ singular: "användare/grupp", plural: "användare/grupper" }}
									resource_handle="usergroups"
									id_handle="id"
									label_handle="fullname"
								/>
								{value ? <Tag onRemove={() => handleRemoveFilter("user_id")}>{getUserFromId(value).fullname}</Tag> : null}
								{value ? <Tag onRemove={() => handleRemoveFilter("group_id")}>{getGroupFromId(value).fullname}</Tag> : null}
							</Stack>
						),
					};
				} else if (filter.type === "group") {
					return {
						...filter,
						filter: (
							<Stack vertical>
								<SearchField
									className="filter-search"
									inline
									autoFocus
									resource="groups.json?enabled=1"
									items={store.getState().groups}
									onSelect={(value: any) => handleSetValue(filter, value)}
									resourceName={{ singular: "grupp", plural: "grupper" }}
									resource_handle="groups"
									id_handle="id"
									label_handle="fullname"
								/>
								{value ? <Tag onRemove={() => handleRemoveFilter("group_id")}>{getGroupFromId(value).fullname}</Tag> : null}
							</Stack>
						),
					};
				} else if (filter.type === "user") {
					return {
						...filter,
						filter: (
							<Stack vertical>
								<SearchField
									className="filter-search"
									inline
									autoFocus
									resource="users.json?enabled=1"
									items={store.getState().users}
									onSelect={(value: any) => handleSetValue(filter, value)}
									resourceName={{ singular: "användare", plural: "användare" }}
									resource_handle="users"
									id_handle="id"
									label_handle="name"
								/>
								{value ? <Tag onRemove={() => handleRemoveFilter("user_id")}>{getUserFromId(value)?.name}</Tag> : null}
							</Stack>
						),
					};
				} else if (filter.type === "users") {
					return {
						...filter,
						filter: <UsersFilter value={value} onChange={(value) => handleSetValue(filter, value)} />,
					};
				} else if (filter.type === "project") {
					return {
						...filter,
						filter: (
							<Stack vertical>
								<SearchField
									className="filter-search"
									inline
									autoFocus
									resource="projects.json"
									onSelect={(value: any) => handleSetValue(filter, value)}
									resourceName={{ singular: "projekt", plural: "projekt" }}
									resource_handle="projects"
									id_handle="id"
									label_handle="title"
								/>
								{value ? <Tag onRemove={() => handleRemoveFilter("project_id")}>{getProjectFromId(value, projects)?.title}</Tag> : null}
							</Stack>
						),
					};
				} else if (filter.type === "boolean") {
					return {
						...filter,
						filter: (
							<ChoiceList
								title={filter.label}
								choices={[
									{ label: "Ja", value: "1" },
									{ label: "Nej", value: "0" },
								]}
								selected={value ? [value] : []}
								onChange={(value) => handleSetValue(filter, value?.[0])}
							/>
						),
					};
				}

				return null;
			});
		};

		const disambiguatedAppliedFilters = Object?.entries(filterObject)?.map(([key, value]: any) => {
			const appliedFilter = appliedFilters.find((filter) => filter.key === key);
			return {
				...(appliedFilter || {}),
				key,
				label: disambiguateLabel(key, value),
				onRemove: () => handleRemoveFilter(key),
			};
		}) as unknown as AppliedFilterInterface[];

		useImperativeHandle(ref, () => ({
			changeTab: (id: string) => {
				return onChange(savedSeaches.find((savedSearch: SavedSearch) => savedSearch.id == id));
			},
			changeTabByIndex: (index: number) => {
				return onChange(savedSeaches[index]);
			},
			setSelectedTabIndex: (index: number) => {
				return setSelected(index);
			},
		}));

		return (
			<>
				<PolarisIndexFilters
					sortOptions={sortOptions?.map((option) => ({ ...option, value: sortValueToPolarisSortValue(option.value) }))}
					sortSelected={[sortValueToPolarisSortValue(sortValue)]}
					queryValue={search}
					queryPlaceholder="Sök.."
					onQueryChange={onSearchChange}
					onQueryClear={onSearchChange}
					onSort={setSortChange}
					primaryAction={primaryAction}
					cancelAction={{
						onAction: onHandleCancel,
						disabled: false,
						loading: false,
					}}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					tabs={tabs}
					selected={selected}
					onSelect={onSelectView}
					canCreateNewView
					onCreateNewView={onCreateNewView}
					filters={transformFiltersToComponents(filters)}
					appliedFilters={disambiguatedAppliedFilters}
					onClearAll={handleFiltersClearAll}
					mode={mode}
					setMode={setMode}
					loading={loading || parentLoading}
				/>

				<SavedSearchModal
					open={!!viewInEdit}
					view={viewInEdit}
					onClose={(view?: any) => {
						setViewInEdit(null);

						if (view) {
							const index = savedSeaches.findIndex((savedSearch) => savedSearch.id === view.id);
							if (index >= 0) {
								const newSavedSearches = [...savedSeaches];
								newSavedSearches[index] = view;
								setSavedSeaches(newSavedSearches);
							}
						}
					}}
				/>

				<Modal
					open={datePickerOptions?.open}
					onClose={() => {
						setDatePickerOptions({ open: false });
					}}
					title="Annan period"
					primaryAction={{
						content: "Välj",
						onAction: () => {
							const filters = { ...filterObject };

							if (datePickerOptions?.filter.range) {
								const startKey = datePickerOptions?.filter?.minKey;
								const endKey = datePickerOptions?.filter?.maxKey;

								if (datePickerOptions?.filter?.minKey) {
									filters[startKey] = moment(datePickerOptions?.selection.start).format("YYYY-MM-DD");
									filters[endKey] = moment(datePickerOptions?.selection.end).format("YYYY-MM-DD");
								} else {
									filters[datePickerOptions?.filter?.key] += " - " + moment(datePickerOptions?.selection.end).format("YYYY-MM-DD");
								}
							} else {
								filters[datePickerOptions?.filter?.key] = moment(datePickerOptions?.selection.start).format("YYYY-MM-DD");
							}

							setFilterObject(filters);
							handleChangeFilters(filters);
							setDatePickerOptions({ open: false });
						},
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								setDatePickerOptions({ open: false });
							},
						},
					]}
				>
					<Modal.Section>
						{datePickerOptions?.filter ? (
							<DatePicker
								month={datePickerOptions?.month || new Date().getMonth()}
								year={datePickerOptions?.year || new Date().getFullYear()}
								weekStartsOn={1}
								onChange={(selection) => {
									setDatePickerOptions((c) => ({
										...c,
										selection,
									}));
								}}
								onMonthChange={(month, year) => {
									setDatePickerOptions((c) => ({
										...c,
										month,
										year,
									}));
								}}
								selected={datePickerOptions?.selection}
								disableDatesAfter={datePickerOptions?.filter.future || datePickerOptions?.filter.range ? undefined : new Date()}
								disableDatesBefore={datePickerOptions?.filter.future && !datePickerOptions?.filter.range ? new Date() : undefined}
								multiMonth={!!datePickerOptions?.filter.range}
								allowRange={!!datePickerOptions?.filter.range}
							/>
						) : null}
					</Modal.Section>
				</Modal>
			</>
		);

		function getDateOptions(filter) {
			let options: any = [];
			if (filter.future) {
				options = [
					{ value: "past_week_and_earlier", label: "förra veckan och tidigare" },
					{ value: "this_week", label: "denna veckan" },
					{ value: "next_week", label: "nästa vecka" },
					{ value: "this_month", label: "denna månaden" },
					{ value: "next_month", label: "nästa månad" },
				];
			} else {
				options = [
					{ value: "this_week", label: "denna veckan" },
					{ value: "last_week", label: "förra veckan" },
					{ value: "this_month", label: "denna månaden" },
					{ value: "last_month", label: "förra månaden" },
					{ value: "last_30_days", label: "senaste 30 dagarna" },
					{ value: "last_90_days", label: "senaste 90 dagarna" },
				];
			}
			const appliedFilter = appliedFilters.find((appliedFilter) => appliedFilter.key == filter.key);

			if (appliedFilter && (appliedFilter?.value?.substring(0, 1) == "2" || appliedFilter?.value?.substring(0, 1) == "1")) {
				options.push({ value: appliedFilter?.value, label: (filter.operatorText ? filter.operatorText + " " : "") + (appliedFilter?.value || "") });
			} else {
				options.push({ value: "custom", label: "annan period" });
			}

			return options;
		}

		function disambiguateLabel(key: string, value: string | any[]) {
			const filter = filters.find((filter) => filter.key == key);
			if (!filter) return null;

			if (key == "user_id") {
				let title = "";

				for (let i = 0; i<value.length; i++) {
					const user = getUserFromId(value[i]);
					
					if (user) {
						title += user.name;
						
						if (i < value.length - 1) {
							title += ", ";
						}
					}
				}

				value = title;

			} else if (key == "group_id") {
				const group = getGroupFromId(value);
				if (group) {
					value = group.fullname || group.name;
				}
			} else if (key == "project_id") {
				value = getProjectFromId(value, projects)?.title || value;
			} else if (filter.key == key) {
				if (filter.type == "select") {
					for (let s = 0; s < filter.options.length; s++) {
						if (filter.options[s].value == value) {
							if (filter.options[s].labelPlain) {
								value = filter.options[s].labelPlain;
							} else {
								value = filter.options[s].label;
							}
							break;
						}
					}
				} else if (filter.type == "dateSelector") {
					const options: any = getDateOptions(filter);
					for (let s = 0; s < options.length; s++) {
						if (options[s].value == value) {
							if (options[s].labelPlain) {
								value = options[s].labelPlain;
							} else {
								value = options[s].label;
							}
							break;
						}
					}
				}

				return `${filter.label} ${filter.operatorText ? " " + filter.operatorText : ""} ${value}`;
			}

			let keyLabel = key;

			if (key == "user_id") {
				keyLabel = "Medarbetare";
			}
			if (key == "group_id") {
				keyLabel = "Grupp";
			}
			if (key == "project_id") {
				keyLabel = "Projekt";
			}
			return `${keyLabel}: ${value}`;
		}
	}
);
export default IndexFilters;

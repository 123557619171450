/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from "react";
import IndexTable from "src/js/components/IndexTable";
import BulkInvoiceModal from "../Invoices/BulkInvoiceModal";
import getInvoicedStatusColumn from "../Invoices/Fortnox/Utilities/getInvoicedStatusColumn";

type SupplierInvoicesIndexProps = {
	extraHeader?: React.ReactNode;
	onSelectionChangeInclRow?: (items: any[]) => void;
	titleHidden?: boolean;
	backAction?: any;
	onClickRow?: (item: any) => void;
	history?: any;
	location?: any;
	project?: any;
	selectOnRow?: boolean;
	fixedParams?: any;
};

const SupplierInvoicesIndex = ({
	extraHeader,
	onSelectionChangeInclRow,
	titleHidden,
	backAction,
	onClickRow,
	history,
	location,
	project,
	selectOnRow,
	fixedParams,
}: SupplierInvoicesIndexProps) => {
	const refresh = useRef<any>(null);
	const ref = useRef<any>(null);

	const [isOpenInvoiceStatusModal, setIsOpenInvoiceStatusModal] = React.useState(false);

	const handleOpenInvoiceStatusModal = () => {
		setIsOpenInvoiceStatusModal(true);
	};

	const handleCloseInvoiceStatusModal = () => {
		setIsOpenInvoiceStatusModal(false);
	};

	const promotedBulkActions = [
		{
			content: "Ändra faktureringsstatus",
			onAction: handleOpenInvoiceStatusModal,
		},
	];
	const columns = [
		{
			handle: "ID",
			label: "ID",
			render: (item) => {
				return item.id;
			},
		},
		{
			handle: "PROJECT",
			label: "Projekt",
			render: (item) => {
				return item.project.full_label;
			},
		},
		{
			handle: "COUNT_ROWS",
			label: "Antal rader",
			render: (item) => {
				return item.supplier_invoice_rows.length;
			},
		},
		getInvoicedStatusColumn(),
	];

	return (
		<>
			<IndexTable
				ref={ref}
				selectOnRow={selectOnRow}
				extraHeader={extraHeader}
				onSelectionChangeInclRow={onSelectionChangeInclRow}
				titleHidden={titleHidden}
				backAction={backAction}
				onClickRow={(item) => {
					if (onClickRow) {
						onClickRow(item);
					}

					history.push(`/admin/supplier_invoices/${item.fortnox_invoice_id}`);
				}}
				setRefreshHandler={(refreshHandler) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					refresh.current = refreshHandler;
				}}
				history={history}
				title="Leverantörsfakturor"
				savedSearchHandle="supplier_invoices"
				resourceUrl="/api/supplier_invoices.json"
				params={{
					...(fixedParams || {}),
					project_id: project && project.id,
				}}
				resourceHandle="supplier_invoices"
				// filters={filters}
				resourceName={{
					singular: "registrering",
					plural: "registreringar",
				}}
				columns={columns}
				promotedBulkActions={promotedBulkActions}
			/>
			<BulkInvoiceModal
				type="supplier_invoices"
				ids={ref?.current?.getSelection()}
				open={isOpenInvoiceStatusModal}
				onClose={handleCloseInvoiceStatusModal}
				onSuccess={() => {
					handleCloseInvoiceStatusModal();
					refresh.current();
				}}
			/>
		</>
	);
};
export default SupplierInvoicesIndex;

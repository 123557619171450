// See color variables in variables.scss
import Colors from "../css/variables.scss";

export default Colors;

export const RGBToHex = (rgb) => {
	// Choose correct separator
	if (rgb.includes("#")) return rgb;
	const sep = rgb.indexOf(",") > -1 ? "," : " ";
	// Turn "rgb(r,g,b)" into [r,g,b]
	rgb = rgb.substr(4).split(")")[0].split(sep);

	let r = (+rgb[0]).toString(16);
	let g = (+rgb[1]).toString(16);
	let b = (+rgb[2]).toString(16);

	if (r.length == 1) r = "0" + r;
	if (g.length == 1) g = "0" + g;
	if (b.length == 1) b = "0" + b;

	return "#" + r + g + b;
};

Colors.random = () => {
	const keys = Object.keys(Colors).filter(
		(key) =>
			typeof Colors[key] === "string" &&
			![
				"white",
				"textColor",
				"grey",
				"primary",
				"subdued",
				"secondary",
				"secondary-50",
				"tertiary",
				"color-dark",
				"color-primary",
				"accent",
				"accent-hover",
				"accent-subdued",
			].includes(key)
	);
	var key = keys[Math.floor(Math.random() * keys.length)];

	return Colors[key];
};

export const colorToRgba = (color = "rgb(255,255,255)", alpha = 1) => {
	if (!color.includes("a") && color.includes("rgb")) {
		return color.replace(")", `, ${alpha})`).replace("rgb", "rgba");
	}
	if (color.includes("#")) {
		const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16));
		return `rgba(${r},${g},${b},${alpha})`;
	}
	return color;
};

import { Component } from "react";
import { Badge } from "@shopify/polaris";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";

class LanguageSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const filters = [
			{
				key: "verified",
				label: "Vefifierade",
				type: "select",
				options: [
					{ label: "Ja", value: 1 },
					{ label: "Nej", value: 0 },
				],
			},
		];

		const columns = [
			{
				label: "Språk",
				handle: "LANGUAGE",
				sortable: true,
				render: (item) => {
					return <b>{item.language}</b>;
				},
			},
			{
				label: "Verifierad",
				handle: "VERIFIED",
				render: (item) => {
					return item.verified ? <Badge status="success">Verifierad</Badge> : <Badge status="critical">Ej verifierad</Badge>;
				},
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					columns={columns}
					history={this.props.history}
					onClickRow={(item) => {
						this.props.history.push("/admin/settings/languages/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Språk"
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Nytt språk",
									onAction: () => {
										this.props.history.push("/admin/settings/languages/new");
									},
							  }
					}
					savedSearchHandle="languages"
					resourceUrl="/api/languages.json"
					resourceHandle="languages"
					resourceName={{
						singular: "språk",
						plural: "språk",
					}}
					filters={filters}
					defaultSavedSearches={[
						{
							title: "Verifierade",
							filters: [
								{
									key: "verified",
									value: 1,
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_active",
						},
					]}
				/>
			</div>
		);
	}
}

export default LanguageSettings;

import { Component } from "react";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";

export default class WorkTypeIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const columns = [
			{
				handle: "TITLE",
				label: "Benämning",
				sortable: true,
			},
			{
				handle: "CODE",
				label: "Kod",
				sortable: true,
			},
		];

		return (
			<IndexTable
				defaultSort="ID_DESC"
				history={this.props.history}
				columns={columns}
				onClickRow={(item) => {
					var url = "/admin/settings/work_type/" + item.id;
					this.props.history.push(url);
				}}
				setRefreshHandler={(refreshHandler) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					this.refresh = refreshHandler;
				}}
				title="Arbetstyper"
				primaryAction={
					store.getState().user.read_only
						? null
						: {
								content: "Ny arbets typ",
								onAction: () => {
									this.props.history.push("/admin/settings/work_type/new");
								},
						  }
				}
				savedSearchHandle="work_types"
				resourceUrl="/api/work_types.json"
				resourceHandle="work_types"
				resourceName={{
					singular: "Arbetstyp",
					plural: "Arbetstyper",
				}}
				selectedItems={this.state.selectedItems}
			/>
		);
	}
}

import { Sheet, Icon, TextField, Thumbnail, FormLayout, Button, Page, TextStyle, ResourceItem } from "@shopify/polaris";
import moment from "moment";
import React, { Component } from "react";
import { ArrowRightMinor, ViewMajor, MobileCancelMajor } from "@shopify/polaris-icons";
import $ from "jquery";
import EditorView from "../views/Pdf/Editor/EditorView";
import { store } from "../store";
import { toastr } from "./toastr";
import API from "../API";

export default class PdfTemplateSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			template: props.template,
			variables: {},
			templates: [],
		};
		this.EditorViewRef = React.createRef();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getPages() {
		if (this.EditorViewRef.current) {
			const pagesAsSvg = this.EditorViewRef.current.getCanvases().reduce((acc, page) => {
				acc.push(page.canvas.toSVG());

				return acc;
			}, []);

			return pagesAsSvg;
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	refreshPreview() {
		this.updateVariables(() => {
			this.EditorViewRef.current.fetch(true);
		});
	}

	componentDidMount() {
		if (this.state.template.variables.length < 1) {
			this.preview();
		} else {
			this.updateVariables();
		}
		console.debug(this.state.loading);
	}

	componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}

		if (props.open && !this.state.open) {
			this.setState(
				{
					variables: {},
					previewing: false,
					template: props.template,
				},
				() => {
					if (!this.state.template) {
						this.fetchTemplates();
					} else {
						if (this.state.template.variables.length < 1) {
							this.preview();
						} else {
							this.updateVariables();
						}
					}
				}
			);
		} else if (this.props.liveUpdate) {
			this.setState(
				{
					template: props.template,
					previewing: false,
				},
				() => {
					if (this.state.template.variables.length < 1) {
						this.preview();
					} else {
						this.updateVariables();
					}
				}
			);
		} else if (this.state.template != props.template) {
			this.setState(
				{
					template: props.template,
				}
				// this.refreshPreview.bind(this)
			);
		}
	}

	fetchTemplates() {
		this.setState({ loading: true });
		API.get("/api/pdf_templates.json", {})
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					return;
				}
				this.setState({ templates: result.data.pdf_templates });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	onVariableChange(key, value) {
		this.state.variables[key] = value;
		this.setState({ variables: this.state.variables });
	}

	preview() {
		this.setState({ previewing: true });
	}

	print(pages, name) {
		if (this.props.save) {
			this.setState({ printing: true });
			API.post(
				"/api/pdf_templates/" + this.state.template.id + "/save.json",
				{
					pages: pages.map((page) => {
						return page.data;
					}),
					name,
				},
				{
					params: this.state.variables,
				}
			)
				.then((result) => {
					this.setState({ printing: false });
					if (result.data.error) {
						return;
					}
					if (this.props.onSave) this.props.onSave(result.data.upload);
					if (this.props.onClose) this.props.onClose();
				})
				.catch((error) => {
					this.setState({ printing: false });
					toastr.error(error);
				});
		} else {
			this.setState({ printing: true });
			API.post(
				"/api/pdf_templates/" + this.state.template.id + "/print.pdf",
				{
					pages: pages.map((page) => {
						return page.data;
					}),
				},
				{
					responseType: "blob",
				}
			)
				.then((result) => {
					const url = window.URL.createObjectURL(new Blob([result.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", this.state.template.title + ".pdf"); // or any other extension
					document.body.appendChild(link);
					link.click();
					this.setState({ printing: false });
					if (this.props.onClose) this.props.onClose();
				})
				.catch((error) => {
					this.setState({ printing: false });
					toastr.error(error);
				});
		}
	}

	selectTemplate(template) {
		this.setState({ template });
		this.state.template = template;
		if (template.variables.length < 1) {
			this.preview();
		} else {
			this.updateVariables();
		}
	}

	updateVariables(callback) {
		if (!this.state.template.variables) return null;

		for (var i = 0; i < this.state.template.variables.length; i++) {
			var handle = this.state.template.variables[i];
			this.state.variables[handle] = "";
		}
		if (this.state.template.variables.indexOf("Dagens datum") >= 0) {
			this.state.variables["Dagens datum"] = moment().format("YYYY-MM-DD");
		}
		if (this.state.template.variables.indexOf("Användarens namn") >= 0) {
			this.state.variables["Användarens namn"] = store.getState().user.name;
		}
		if (this.state.template.variables.indexOf("Användarens telefonnr") >= 0) {
			this.state.variables["Användarens telefonnr"] = store.getState().user.phone;
		}
		if (this.state.template.variables.indexOf("Användarens e-post") >= 0) {
			this.state.variables["Användarens e-post"] = store.getState().user.email;
		}
		if (this.props.contact) {
			var contact = null;
			var company = null;
			if (this.props.contact.is_company) {
				company = this.props.contact;
			} else {
				contact = this.props.contact;
				if (contact.parent && contact.parent.is_company) {
					company = contact.parent;
				}
			}

			if (company) {
				if (this.state.template.variables.indexOf("Företagsnamn") >= 0) {
					this.state.variables["Företagsnamn"] = company.name;
				}
				if (this.state.template.variables.indexOf("Organisationsnummer") >= 0) {
					this.state.variables.Organisationsnummer = company.orgnr;
				}
				if (this.state.template.variables.indexOf("Företagets epost") >= 0) {
					this.state.variables["Företagets epost"] = company.email;
				}
				if (this.state.template.variables.indexOf("Företagets telefon") >= 0) {
					this.state.variables["Företagets telefon"] = company.phone;
				}
			}
			if (contact) {
				if (this.state.template.variables.indexOf("Förnamn efternamn") >= 0) {
					this.state.variables["Förnamn efternamn"] = contact.name;
				}
				if (this.state.template.variables.indexOf("Epost") >= 0) {
					this.state.variables.Epost = contact.email;
				}
				if (this.state.template.variables.indexOf("Telefon") >= 0) {
					this.state.variables.Telefon = contact.phone;
				}
			}
		}

		if (this.props.quote) {
			const quote = this.props.quote;

			if (this.state.template.variables.indexOf("Offert title") >= 0) {
				this.state.variables["Offert title"] = quote.title;
			}
			if (this.state.template.variables.indexOf("Offert projekt address") >= 0) {
				this.state.variables["Offert projekt address"] = quote.address;
			}
			if (this.state.template.variables.indexOf("Offert reference") >= 0 && quote.reference) {
				this.state.variables["Offert reference"] = quote.reference.name;
			}
			if (this.state.template.variables.indexOf("Offert kund") >= 0 && quote.customer) {
				this.state.variables["Offert kund"] = quote.customer.name;
			}
			if (this.state.template.variables.indexOf("Offert kontakt person") >= 0 && quote.contact) {
				this.state.variables["Offert kontakt person"] = quote.contact.name;
			}
			if (this.state.template.variables.indexOf("Offert notering") >= 0) {
				this.state.variables["Offert notering"] = quote.note;
			}
			if (this.state.template.variables.indexOf("Offert inledning") >= 0) {
				this.state.variables["Offert inledning"] = quote.introduction;
			}
			if (this.state.template.variables.indexOf("Offert avslutning") >= 0) {
				this.state.variables["Offert avslutning"] = quote.closure;
			}
		}
		this.setState(
			(c) => ({ variables: this.state.variables, previewing: this.props.directPreview ? true : c.previewing }),
			() => {
				if (callback && typeof callback === "function") callback();
			}
		);
	}

	componentDidUpdate() {
		$(".editor-sheet-close-btn").closest("[data-portal-id]").addClass("sheet-top");
	}

	render() {
		/*
			<Modal
				large
				title={this.state.template ? "Skriv in variablernas värde" : "Välj PDF mall"}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[{ content: "Stäng", onAction: this.props.onClose }]}
				primaryAction={
					this.state.template
						? this.props.save
							? { content: "Skapa och spara PDF", icon: SaveMinor, loading: this.state.printing, onAction: this.print.bind(this) }
							: { content: "Skriv ut", icon: PrintMajor, loading: this.state.printing, onAction: this.print.bind(this) }
						: null
				}
			>
		*/

		const content = (
			<div
				style={{ paddingTop: 20, overflow: "auto", width: "100%", height: "100%" }}
				className={this.state.template && this.state.previewing ? "embedded-editor bg" : "embedded-editor"}
				onClick={(e) => e.stopPropagation()}
			>
				{this.state.template && !this.state.previewing ? (
					<Page title="Fyll i värden">
						<div style={{ paddingTop: 20 }}>
							<FormLayout>
								{this.state.template &&
									this.state.template.variables &&
									this.state.template.variables.map((val, index) => {
										const value = this.state.variables && this.state.variables[val];
										return (
											<div key={index} className="varible-row">
												<span className="key">{val}</span>
												<Icon source={ArrowRightMinor} />
												<span className="value">
													<TextField value={value} onChange={this.onVariableChange.bind(this, val)} />
												</span>
											</div>
										);
									})}
								<Button primary icon={ViewMajor} onClick={this.preview.bind(this)}>
									Förhandsgranska
								</Button>
							</FormLayout>
						</div>
					</Page>
				) : null}
				{this.state.template && this.state.previewing ? (
					<EditorView
						ref={this.EditorViewRef}
						print={!this.props.save}
						saving={this.state.printing}
						onSave={this.print.bind(this)}
						requireName={this.props.requireName}
						id={this.state.template.id}
						variables={this.state.variables}
						rowId={this.props.row && this.props.row.id}
						dynamicWidth={this.props.dynamicWidth}
						disableEditing={this.props.disableEditing}
						quote={this.props.quote}
					/>
				) : null}
				{!this.state.template ? (
					<Page title="Välj mall">
						{this.state.templates.map((template, index) => {
							return (
								<ResourceItem
									key={template.id || index}
									media={<Thumbnail source={template.thumbnail_url} />}
									onClick={this.selectTemplate.bind(this, template)}
								>
									<h3>
										<TextStyle variation="strong">{template.title}</TextStyle>
									</h3>
								</ResourceItem>
							);
						})}
					</Page>
				) : null}
			</div>
		);

		if (this.props.noSheet) return content;

		return (
			<Sheet open={this.props.open} onClose={this.props.onClose}>
				<div
					className="editor-sheet-close-btn"
					style={{
						position: "absolute",
						top: 10,
						right: 10,
						zIndex: 400002,
					}}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
				</div>
				{content}
			</Sheet>
		);
	}
}

import { EmptyState } from "@shopify/polaris";
import React, { Component } from "react";
import moment from "moment";
import { ProfileMajor } from "@shopify/polaris-icons";
import IndexTable from "../../components/IndexTable";
import { toastr } from "../../components/toastr";
import API from "../../API";

class Pdf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
		};
	}

	renderCell(item, column) {
		if (column.handle == "TITLE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
					</div>
				</div>
			);
		} else if (column.handle == "CREATED_AT") {
			return item.created_at ? moment(item.created_at).locale("sv").calendar() : "–";
		} else if (column.handle == "UPDATED_AT") {
			return item.updated_at ? moment(item.updated_at).locale("sv").calendar() : "–";
		}
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	bulkRemove() {
		this.setState({ removing: true });
		API.put("/api/pdf_templates/bulk_remove.json", { ids: this.state.selectedItems })
			.then((result) => {
				this.setState({ removing: false, selectedItems: null });
				this.refresh();
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	gotoPdf(item) {
		item.path = "/admin/pdf_templates/" + item.id;
		item.variables = [];
		this.props.history.push("/admin/pdf_templates/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: "PDFs",
					item,
				},
			],
		});
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}
		this.setState({ firstFetch: false });
	}

	render() {
		if (this.state.empty) {
			return (
				<div>
					<EmptyState
						heading="Inga PDF Mallar"
						action={{
							content: "Skapa PDF Mall",
							onAction: () => this.props.history.push("/admin/pdf_templates/new"),
						}}
						image="/assets/images/empty_state/PDF.png"
					>
						<p>Du har inga PDF Mallar att visa</p>
					</EmptyState>
				</div>
			);
		}

		const columns = [
			{
				handle: "TITLE",
				label: "Namn",
				sortable: true,
				type: "text",
			},
			{
				handle: "UPDATED_AT",
				label: "Senast aktivitet",
				sortable: true,
				type: "text",
			},
			{
				handle: "CREATED_AT",
				label: "Skapad",
				sortable: true,
				type: "text",
			},
		];

		const bulkActions = [
			{
				items: [{ icon: ProfileMajor, content: "Ta bort PDFs", onAction: this.bulkRemove.bind(this), loading: this.state.removing }],
			},
		];

		return (
			<IndexTable
				history={this.props.history}
				setRefreshHandler={(refreshHandler) => {
					this.refresh = refreshHandler;
				}}
				renderMedia={(item) => {
					return <img src={item.thumbnail_url} style={{ objectFit: "cover", height: "65px" }} alt="" />;
				}}
				title="PDF mallar"
				primaryAction={{
					content: "Ny PDF",
					onAction: () => this.props.history.push("/admin/pdf_templates/new"),
				}}
				savedSearchHandle="pdf_mallar"
				resourceUrl="/api/pdf_templates.json"
				onItemsFetched={this.onItemsFetched.bind(this)}
				resourceHandle="pdf_templates"
				onSelectionChange={this.onSelectionChange.bind(this)}
				resourceName={{
					singular: "PDF mall",
					plural: "PDF mallar",
				}}
				selectedItems={this.state.selectedItems}
				renderCell={this.renderCell.bind(this)}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				onClickRow={this.gotoPdf.bind(this)}
				promotedBulkActions={[]}
				bulkActions={bulkActions}
			/>
		);
	}
}
export default Pdf;

import React, { useCallback, useMemo, useState } from "react";
import { store } from "src/js/store";
import { Tabs, Text } from "@shopify/polaris";
import styled from "styled-components";
// import InvoiceIndex from "../Invoices";
import FortnoxInvoiceIndex from "../Invoices/Fortnox/FortnoxInvoiceIndex";
import getBasisTabs from "../Invoices/Fortnox/Utilities/getBasisTabs";
import InvoiceBasisViews from "../Invoices/Fortnox/InvoiceBasisViews";

const EconomyView = ({ history, project }) => {
	const fortnoxConnection = store.getState().account.fortnox_connection;
	const [selectedTabId, setSelectedTabId] = useState("invoices");

	const tabs = useMemo(
		() =>
			[
				{
					id: "invoices",
					content: "Fakturor",
					enabled: fortnoxConnection,
				},
				...getBasisTabs(project),
			].filter((t) => !("enabled" in t) || t.enabled),
		[project, fortnoxConnection]
	);

	const handleTabChange = useCallback(
		(selectedTabIndex) => {
			const tab = tabs[selectedTabIndex];
			setSelectedTabId(tab?.id);
		},
		[tabs]
	);

	return (
		<Wrapper>
			<Text as="h2" variant="headingMd">
				Här visas fakturor och ej faktuerade underlag
			</Text>
			<Tabs tabs={tabs} onSelect={handleTabChange} selected={tabs.findIndex((t) => t.id === selectedTabId)} />
			{selectedTabId === "invoices" && (
				<>
					{/* {!fortnoxConnection && (
						// Change to a FortnoxInvoiceIndex
						<InvoiceIndex
							title="Fakturor"
							project={project}
							history={history}
						/>
					)} */}

					{fortnoxConnection && <FortnoxInvoiceIndex project={project} history={history} />}
				</>
			)}

			{selectedTabId !== "invoices" && (
				<InvoiceBasisViews
					tab={tabs.find((t) => t.id === selectedTabId)}
					history={history}
					location={{}}
					invoice={null}
					selectOnRow={false}
					project={project}
				/>
			)}
		</Wrapper>
	);
};
export default EconomyView;

const Wrapper = styled.div`
	margin-top: 0.5rem;

	.Polaris-Tabs {
		margin-top: 0.5rem;
	}
`;

import React, { Component } from "react";
import { Modal } from "@shopify/polaris";

class RemoveModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// if (this.props.numItems === 1) {
		// 	var title = trans("Ta bort %type%", { "%type%": this.props.resourceName.singular });
		// 	if (this.props.objectName) {
		// 		var description = trans("Ta bort %object%", { "%object%": this.props.objectName });
		// 	} else {
		// 		var description = trans("Ta bort %type%", { "%type%": this.props.resourceName.singular });
		// 	}
		// } else {
		// 	var title = trans("Ta bort %types%", { "%types%": this.props.resourceName.plural });
		// 	var description = trans("Ta bort %num% %types%?", { "%num%": this.props.numItems, "%types%": this.props.resourceName.plural });
		// }

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title="Är du säker?"
				secondaryActions={[
					{
						content: "Stäng",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					destructive: true,
					content: "Ta bort",
					loading: this.props.loading,
					onAction: this.props.onConfirm,
				}}
			>
				<Modal.Section>
					<div>Detta går inte att ångra</div>
				</Modal.Section>
			</Modal>
		);
	}
}
export default RemoveModal;

import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const DashboardContainer = styled.div`
	padding: 0 var(--p-space-6);
	/* height: calc(100% - 9rem); */
	height: 100%;
	display: grid;
	grid-template-areas: "header" "body";
	grid-template-rows: max-content auto;
	/* overflow: hidden; */

	& > .Polaris-Card .Polaris-Card__Section {
		padding: 0;
	}
`;
export const DashboardHeader = styled.div`
	padding-block: var(--p-space-6);
	grid-area: header;

	.Polaris-Tabs[role="tablist"] {
		padding: 6px;
	}
`;

export const DashboardSectionTitle = styled.h3`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: var(--p-space-5);
`;

export const DashboardBody = styled.div<{ height?: number | string }>`
	/* height: ${({ height }) => height || "calc(100% - 113px)"}; */
	grid-area: body;
	flex: 1;

	& > * {
		height: calc(100% - 1rem);
	}
`;

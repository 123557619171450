import React from "react";
import styled from "styled-components";
// import StatsGraph from "./statsGraph";
import StatsBoxes from "./StatsBoxes";
import ActivityLog from "./ActivityLog";
import AcceptedOffersWithoutProjectList from "./AcceptedOffersWithoutProjectList.";

type OverviewDashboardProps = {
	history: any;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OverviewDashboard = ({ history }: OverviewDashboardProps) => {
	return (
		<Wrapper>
			{/* <StatsGraph history={history} /> */}
			<AcceptedOffersWithoutProjectList history={history} />
			<StatsBoxes history={history} />
			<ActivityLog history={history} />
		</Wrapper>
	);
};
export default OverviewDashboard;

const Wrapper = styled.div`
	display: grid;
	grid-template-areas:
		"graph boxes"
		"log log";
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	grid-template-rows: 1fr 1fr;

	@media screen and (max-width: 1100px) {
		grid-template-areas: "graph" "boxes" "log" "tasks";
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, 1fr);
	}

	.AcceptedOffersWithoutProjectList {
		grid-area: graph;
	}

	/* .stats-graph {
		grid-area: graph;
	} */

	.stats-boxes {
		grid-area: boxes;
	}

	.activity-log {
		grid-area: log;
	}

	.Polaris-LegacyCard,
	.Polaris-LegacyCard__Section {
		height: 100%;
	}

	& > * > .Polaris-LegacyCard > .Polaris-LegacyCard__Section {
		padding: 0;
	}
`;

/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import { Badge, Button, ButtonGroup, FormLayout, Heading, Layout, Modal, Page, PageActions, Select, Tabs } from "@shopify/polaris";
import styled from "styled-components";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import debounce from "lodash/debounce";
import { MaximizeMinor } from "@shopify/polaris-icons";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import Uploads from "src/js/components/Uploads";
import Card from "src/js/components/Card";
import ResourcePicker from "../../components/resource_picker";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import TotalCost from "./TotalCost";
import SearchField from "../../components/search_field";
import Map from "../../components/map";
import QuotePDF from "./PDF/QuotePDF";
import { store } from "../../store";
import QuoteHistory from "./QuoteHistory";
import QuoteTable from "./QuoteTable";
import QuoteRowEditor from "./QuoteRowEditor";
import PreviewPdfModal from "../../components/PreviewPdfModal";
import API from "../../API";

class QuoteEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			selectedTab: 0,
			form: this.getInitialForm(props),
			isOpenSendFormModal: false,
			page: 0,
		};
		this.ref = React.createRef();
		this.requiredFields = [
			{ field: "title", label: "Namn" },
			{ field: "customer", label: "Kund" },
			{ field: "reference", label: "Referens" },
		];
		this.leftWrapperRef = React.createRef();
		this.PreviewWrapperRef = React.createRef();
		this.refreshPdfBounced = debounce(this.refreshPdf.bind(this), 1000, { maxWait: 3000, leading: false, trailing: true });
		this.onHashChange = this.handleHashChange.bind(this);
		this.tabs = this.setTabs();
	}

	setTabs() {
		const tabs = [
			{
				id: "info",
				content: "Info",
				panelID: "info",
			},
			{
				id: "rader",
				content: "Rader",
				panelID: "Rader",
			},
			{
				id: "inledning/avslutning",
				content: "Inledning/Avslutning",
				panelID: "inledning/avslutning",
			},
			{
				id: "uploads",
				content: `Bilagor & Dokument ${this.state.form && this.state.form.uploads ? `(${this.state.form.uploads.length})` : ""}`,
				panelID: "uploads",
			},
			{
				id: "history",
				content: `Historik`,
				panelID: "history",
			},
		];

		this.tabs = tabs;
		return tabs;
	}

	getInitialForm(props) {
		if (props.quote) return props.quote;
		if (props.location && props.location.state && props.location.state.quote) {
			return Object.assign(props.location.state.quote, {
				email: this.getEmailFromContactOrCustomer(props),
				rows: [].concat(
					props.location.state.quote.rows || [],
					Array.from(Array(1)).map(() => ({}))
				),
			});
		}

		return {
			rows: Array.from(Array(1)).map(() => ({})),
		};
	}

	getEmailFromContactOrCustomer(props) {
		if (props.location && props.location.state && props.location.state.quote) {
			if (props.location.state.quote.contact) return props.location.state.quote.contact.email;
			if (props.location.state.quote.customer) return props.location.state.quote.customer.email;
		}
	}

	componentDidMount() {
		this.fetch();

		window.addEventListener("hashchange", this.HashChange);
		this.onHashChange();
		console.debug(this.state.previewLoading);
	}

	handleHashChange() {
		const hash = new URL(document.URL).hash.replace("#", "");
		// const tabIndex = this.tabs.findIndex((t) => t.id === hash);
		const index = this.tabs.findIndex((t) => t.id === hash);
		this.setState({ selectedTab: index > 0 ? index : 0 }, () => {
			if (!index) {
				const url = new URL(document.URL);
				url.hash = `${this.tabs[0].id}`;
				window.history.replaceState(null, null, url);
			}
		});
	}

	componentWillReceiveProps(props) {
		if ((props.match && props.match.params.id) !== (this.props.match && this.props.match.params.id)) {
			this.fetch(props.match.params.id);
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		window.removeEventListener("hashchange", this.HashChange);
	}

	fetch(quoteId) {
		const id = quoteId || (this.props.quote && this.props.quote.id) || (this.props.match && this.props.match.params.id);
		if (!id) return null;
		API.get(`/api/quotes/${id}.json`)
			.then((result) => {
				if (result.data.error) {
					return;
				}

				if (!result.data.quote.rows) {
					result.data.quote.rows = Array.from(Array(1)).map(() => ({}));
				} else if (result.data.quote.rows) {
					result.data.quote.rows.push({});
				}
				result.data.quote.email = this.getEmailFromContactOrCustomer(this.props);

				this.setState(
					{
						form: result.data.quote,
						isOpenSendFormModal: Boolean(result.data.quote && !result.data.quote.popup_shown),
					},
					() => {
						this.setTabs();
						this.refreshPdf();
					}
				);

				this.interval = setInterval(this.refreshPdf.bind(this), 20 * 1000);
			})
			.catch((error) => {
				console.error(error);
				toastr.error(error);
			});
	}

	saveForm() {
		this.setState({ saving: true });

		try {
			// eslint-disable-next-line dot-notation
			if (this.state.form.rows) this.state.form.rows = this.state.form.rows.filter((i) => i && (i["article"] || i["description"]));
		} catch (error) {
			this.setState({ saving: false });
		}

		API.put(`/api/quotes/${this.state.form.id}.json`, this.state.form)
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					return;
				}
				toastr.success("Offert uppdaterad");
				if (!result.data.quote.rows) {
					result.data.quote.rows = Array.from(Array(1)).map(() => ({}));
				} else if (result.data.quote.rows) {
					result.data.quote.rows.push({});
				}

				this.setState({ saving: false, form: result.data.quote }, () => {
					this.setTabs();
					this.refreshPdf();
				});
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	refreshPdf() {
		this.setState({ previewLoading: true });

		API.post(`/api/quotes/${this.state.form.id}/preview.json`, Object.assign(this.state.form, { page: this.state.page }))
			.then((result) => {
				if (result.data.error) {
					this.setState({ previewLoading: false });
					return;
				}
				if (this.PreviewWrapperRef.current) {
					this.PreviewWrapperRef.current.innerHTML =
						result.data.header_html +
						result.data.quote_html +
						'<div style="position:absolute;bottom:0px;left:0px;right:0px;">' +
						result.data.footer_html +
						"</div>";
				}
				this.setState({
					vat: result.data.vat,
					grand_total: result.data.grand_total,
					subtotal: result.data.subtotal,
					oresavrundning: result.data.oresavrundning,
				});
				this.setState({ previewLoading: false });
			})
			.catch((error) => {
				this.setState({ previewLoading: false });
				toastr.error(error);
			});
	}

	sendForm() {
		const missingFields = this.requiredFields && this.requiredFields.filter(({ field } = {}) => !this.state.form[field]);
		if (missingFields && missingFields.length) {
			toastr.warning(`Väligen fyll i ${missingFields.map((f) => f.label).join(", ")}.`);
			return;
		}

		// eslint-disable-next-line dot-notation
		if (this.state.form.rows) this.state.form.rows = this.state.form.rows.filter((i) => i && (i["article"] || i["description"]));

		this.setState({ sending: true });
		// this.state.form.net_price = calculateTotal(this.state.form.rows);
		// this.state.form.gross_price = calculateTotalGross(this.state.form.rows);
		this.state.form.pdfHtml = ReactDOMServer.renderToStaticMarkup(<QuotePDF quote={this.state.form} />);

		this.refreshPdf();
		this.state.form.status = "sent";

		API.put(`/api/quotes/${this.state.form.id}/send.json`, this.state.form)
			.then((result) => {
				if (result.data.error) {
					this.setState({ sending: false });
					return;
				}

				toastr.success("Offert skickad");
				this.setState({ sending: false, isOpenSendFormModal: false, form: result.data.quote });
				// this.setState({ sending: false, form: result.data.quote, isOpenSendFormModal: false });
			})
			.catch((error) => {
				this.setState({ sending: false });
				toastr.error(error);
			});
	}

	setPopupShown() {
		API.post(`/api/quotes/${this.state.form.id}/popup/shown.json`, this.state.form).then((response) => {
			this.setState({ form: response.data.quote });
		});
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	setRow(row, index) {
		if (!this.state.form.rows) {
			this.state.form.rows = [];
		}
		// const position = typeof index === "number" ? index : this.state.form.rows.length;

		this.state.form.rows[index] = row;
		// if (this.state.form.rows.every((r) => Object.keys(r).length)) {
		// if (this.state.form.rows.filter((r) => !Object.keys(r).length).length < 2) {
		// 	this.state.form.rows.push({});
		// }
		if (index === this.state.form.rows.length - 1) {
			this.state.form.rows.push({});
		}
		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	updateForm(field, value) {
		if (field === "customer" && value) {
			this.state.form.customer = value;
			if (!this.state.form.payment_terms || this.state.form.payment_terms === (this.state.form.customer && this.state.form.customer.payment_terms)) {
				this.state.form.payment_terms = value.payment_terms;
			}
			if (
				!this.state.form.extra_percentage ||
				this.state.form.extra_percentage === (this.state.form.customer && this.state.form.customer.extra_percentage)
			) {
				this.state.form.extra_percentage = value.purcharge_price_surcharge;
			}
			if (!this.state.form.address || this.state.form.address === (this.state.form.customer && this.state.form.customer.address)) {
				this.state.form.address = value.address;
				this.state.form.latitude = value.latitude;
				this.state.form.longitude = value.longitude;
			}
		}

		if (field === "extra_percentage") {
			// this.state.form[field] = Math.min(100, Math.max(value, 0));
			if (value <= 100) this.state.form[field] = value;
		} else {
			this.state.form[field] = value;
		}

		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	addUploads(value) {
		if (!this.state.form.uploads) {
			this.state.form.uploads = [value];
		} else {
			this.state.form.uploads.push(value);
		}

		this.setState({ form: this.state.form });
	}

	removeUpload(index) {
		this.state.form.uploads.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	addRow(field, index, value) {
		if (!this.state.form.rows) {
			this.state.form.rows = [];
		}
		const prm = { [field]: value };

		this.state.form.rows[index] = Object.assign(this.state.form.rows[index] || {}, prm);

		// if (this.state.form.rows.every((r) => Object.keys(r).length)) {
		// if (this.state.form.rows.filter((r) => !Object.keys(r).length).length < 2) {
		// 	this.state.form.rows.push({});
		// }
		if (index === this.state.form.rows.length - 1) {
			this.state.form.rows.push({});
		}
		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	removeRow(index) {
		this.state.form.rows.splice(index, 1);
		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	onTabChange(tabIndex) {
		const tab = this.tabs[tabIndex];
		const hash = tab ? `#${tab.id}` : "";
		const url = new URL(document.URL);
		url.hash = `${hash}`;
		window.history.replaceState(null, null, url);

		this.setState({ selectedTab: tabIndex });
	}

	openSendFormModal() {
		if (this.state.form.contact) {
			this.state.form.email = this.state.form.contact.email;
		} else if (this.state.form.customer) {
			this.state.form.email = this.state.form.customer.email;
		}
		this.setState({ isOpenSendFormModal: true, form: this.state.form });
	}

	closeSendFormModal() {
		this.state.form.email = null;
		if (this.state.form && !this.state.form.popup_shown) {
			this.setPopupShown();
		}
		this.setState({ isOpenSendFormModal: false, form: this.state.form });
	}

	unlockForEdit() {
		this.setState({ unlockLoading: true });
		API.put(`/api/quotes/${this.state.form.id}/unlock.json`, this.state.form)
			.then((result) => {
				if (result.data.error) {
					this.setState({ unlockLoading: false });
					return;
				}

				if (!result.data.quote.rows) {
					result.data.quote.rows = Array.from(Array(1)).map(() => ({}));
				} else if (result.data.quote.rows) {
					result.data.quote.rows.push({});
				}

				toastr.success("Offert uplåst");
				this.setState({ unlockLoading: false, form: result.data.quote, confirmUnlockModal: false });
			})
			.catch((error) => {
				this.setState({ unlockLoading: false });
				toastr.error(error);
			});
	}

	openConfirmUnlockModal() {
		this.setState({ confirmUnlockModal: true });
	}

	closeConfirmUnlockModal() {
		this.setState({ confirmUnlockModal: false });
	}

	openAcceptModal() {
		this.setState({ acceptModal: true });
	}

	closeAcceptModal() {
		this.setState({ acceptModal: false });
	}

	acceptQuote() {
		this.setState({ acceptLoading: true });
		API.put(`/quote/${this.state.form.id}/accept.json`, { user: store.getState().user })
			.then((result) => {
				if (result.data.error) {
					this.setState({ acceptLoading: false });
					return;
				}
				this.setState({ acceptModal: false, acceptLoading: false, form: result.data.quote });
			})
			.catch((error) => {
				this.setState({ acceptLoading: false });
				console.error("error:", error);
				toastr.error(error);
			});
	}

	copyQuoteNew() {
		const quote = JSON.parse(JSON.stringify(this.state.form));
		delete quote.logs;
		delete quote.id;
		delete quote.updated_at;
		delete quote.created_at;
		delete quote.revision;
		delete quote.popup_shown;
		delete quote.pdf_url;

		quote.rows.forEach((row) => {
			delete row.id;
			delete row.created_at;
			delete row.updated_at;
		});
		this.props.history.push({ pathname: `/admin/quotes/new`, quote });
	}

	render() {
		const primaryAction = {
			content: "Spara",
			loading: this.state.saving,
			onAction: this.saveForm.bind(this),
			disabled:
				this.state.saving ||
				this.state.sending ||
				this.state.removing ||
				!this.state.form ||
				!this.state.form.id ||
				(this.state.form && this.state.form.sent_at),
		};
		// const secondaryActions = { content: "Ta bort", loading: this.state.removing, onAction: this.removeForm.bind(this) };

		const tabs = this.tabs;

		const selectedTabId = tabs[this.state.selectedTab] && tabs[this.state.selectedTab].id;
		const locked = !this.state.form || this.state.form.locked || this.state.form.status === "sent";

		const badge = (() => {
			switch (this.state.form.status) {
				case "declined":
					return <Badge status="critical">Nekad {moment(this.state.form.declined_at).format("YYYY-MM-DD")}</Badge>;
				case "sent":
					return <Badge status="info">Skickad {moment(this.state.form.sent_at).format("YYYY-MM-DD")}</Badge>;
				case "accepted":
					return <Badge status="success">Accepterad {moment(this.state.form.accepted_at).format("YYYY-MM-DD")}</Badge>;
				default:
					return null;
			}
		})();
		// const totalCost = calculateTotal(this.state.form.rows);
		// this.state.form.net_price = totalCost;
		const revision = this.state.form.revision && <Badge>Revision: {this.state.form.revision}</Badge>;
		const metadata = (
			<React.Fragment>
				{badge}
				{revision}
			</React.Fragment>
		);

		const content = (
			<React.Fragment>
				<div style={{ marginBottom: "var(--p-space-5)" }}>
					{this.state.form.status !== "accepted" && (
						<ButtonGroup>
							{this.state.form.status === "draft" && (
								<Button primary onClick={this.openSendFormModal.bind(this)} loading={this.state.sending}>
									Skicka till kund
								</Button>
							)}
							{this.state.form.status === "sent" && (
								<Button primary onClick={this.openSendFormModal.bind(this)} loading={this.state.sending}>
									Skicka igen
								</Button>
							)}
							{(this.state.form.status === "declined" || this.state.form.status === "sent") && (
								<Button primary loading={this.state.unlockLoading} onClick={this.openConfirmUnlockModal.bind(this)}>
									Lås upp för redigering
								</Button>
							)}
							{store.getState().user.roles.includes("ROLE_ADMIN") && (
								<Button primary loading={this.state.acceptLoading} onClick={this.openAcceptModal.bind(this)}>
									Godkänn offert
								</Button>
							)}
							{store.getState().user.roles.includes("ROLE_ADMIN") && (
								<Button primary loading={this.state.copyLoading} onClick={this.copyQuoteNew.bind(this)}>
									Kopiera offert
								</Button>
							)}
							{this.props.noPage && metadata}
						</ButtonGroup>
					)}
					{this.state.form.status === "accepted" && (
						<Button primary onClick={this.openSendFormModal.bind(this)} loading={this.state.sending}>
							Skicka igen
						</Button>
					)}
				</div>

				<Layout>
					<Layout.Section>
						<Tabs tabs={tabs} onSelect={this.onTabChange.bind(this)} selected={this.state.selectedTab} />
						<div ref={this.leftWrapperRef}>
							{selectedTabId === "info" && (
								<CardsInPageWrapper margin>
									<Layout>
										<Layout.Section oneHalf>
											<Card sectioned>
												<FormLayout>
													<TextField
														label="Namn"
														value={this.state.form.title}
														onChange={this.updateForm.bind(this, "title")}
														required
														disabled={locked}
													/>

													<ResourcePicker
														placeholder="Sök referens"
														caption="Välj våran referens"
														resource="users"
														resourceName={{
															singular: "användare",
															plural: "användare",
														}}
														item={this.state.form.reference}
														onChange={this.updateForm.bind(this, "reference")}
														label="Vår referens"
														required
														disabled={locked}
														textAlign="left"
														label_handle="name"
													/>
													<ResourcePicker
														placeholder="Sök kund"
														caption="Välj kund"
														resource="customers"
														resourceName={{
															singular: "kund",
															plural: "kunder",
														}}
														item={this.state.form.customer}
														onChange={this.updateForm.bind(this, "customer")}
														label="Kund"
														required
														disabled={locked}
														label_handle="name"
														textAlign="left"
													/>
													<SearchField
														label="Adress"
														resource="google_address"
														resourceName={{
															singular: "adress",
															plural: "adresser",
														}}
														onSelect={this.selectAddress.bind(this)}
														onChange={this.updateForm.bind(this, "address")}
														value={this.state.form.address}
														disabled={locked}
														required
													/>
													{this.state.form && this.state.form.address && (
														<div>
															<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} />
														</div>
													)}
												</FormLayout>
											</Card>
										</Layout.Section>
										<Layout.Section oneHalf>
											<Card sectioned>
												<FormLayout>
													<Select
														label="Status"
														options={[
															{ label: "Utkast", value: "draft" },
															{ label: "Skickad", value: "sent" },
															{ label: "Nekad", value: "declined" },
															{ label: "Accepterad", value: "accepted" },
														]}
														onChange={this.updateForm.bind(this, "status")}
														value={this.state.form.status}
														disabled
													/>
													<ResourcePicker
														placeholder="Sök kontaktperson"
														caption="Välj kontaktperson"
														resource={`contacts.json${this.state.form.customer ? `?customer_id=${this.state.form.customer.id}` : ""}`}
														resourceName={{
															singular: "kund",
															plural: "kunder",
														}}
														item={this.state.form.contact}
														onChange={this.updateForm.bind(this, "contact")}
														label="Kontaktperson"
														disabled={locked || !this.state.form.customer}
														textAlign="left"
														label_handle="name"
													/>
													<TextField
														label="Beskrivning"
														multiline
														value={this.state.form.note}
														onChange={this.updateForm.bind(this, "note")}
														disabled={locked}
													/>

													<TextField
														label="Giltighetstid"
														value={this.state.form.valid_time}
														onChange={this.updateForm.bind(this, "valid_time")}
														required
														suffix="dagar"
													/>
													<TextField
														label="Betalningsvillkor"
														value={this.state.form.payment_terms}
														onChange={this.updateForm.bind(this, "payment_terms")}
														required
														suffix="dagar"
													/>
													<TextField
														label="Extraarbete timkostnad"
														value={this.state.form.extra_hour_cost}
														onChange={this.updateForm.bind(this, "extra_hour_cost")}
														required
														type="number"
														suffix="kr"
													/>
													<TextField
														label="Påslag inköp (%)"
														value={this.state.form.extra_percentage}
														onChange={this.updateForm.bind(this, "extra_percentage")}
														required
														type="number"
														max="100"
														suffix="%"
													/>
												</FormLayout>
											</Card>
										</Layout.Section>
									</Layout>
								</CardsInPageWrapper>
							)}
							{selectedTabId === "rader" && (
								<>
									<div style={{ paddingBlock: "var(--p-space-5)" }}>
										<Button
											primary
											onClick={() => {
												this.setState({ rowModalIsOpen: true });
											}}
										>
											Lägg till artikel
										</Button>
									</div>
									<QuoteRowEditor
										open={this.state.rowModalIsOpen}
										onClose={() => {
											this.setState({ rowModalIsOpen: false, data: { row: {}, index: null } });
										}}
										data={this.state.data}
										onOnSaveRow={(row, index) => {
											if (this.state.form && !this.state.form.rows) {
												this.state.form.rows = [];
											}
											if (!Number.isInteger(index)) {
												this.state.form.rows.push(row);
											} else {
												this.state.form.rows[index] = row;
											}
											this.setState({ form: this.state.form, data: { row: {}, index: null }, rowModalIsOpen: false }, () =>
												this.refreshPdfBounced.bind(this)
											);
										}}
										disabled={locked}
										// removeRow={this.removeRow.bind(this)}
									/>

									<Card title="Offertrader" sectioned background>
										<QuoteTable
											rows={((this.state.form && this.state.form.rows) || []).filter((row) =>
												Object.keys(row).some((key) => row[key] && Object.keys(row).length)
											)}
											onRowEdit={(row, index) => {
												this.setState({ data: { row, index }, rowModalIsOpen: true });
											}}
											removeRow={this.removeRow.bind(this)}
											disabled={locked}
										/>
									</Card>
								</>
							)}

							{selectedTabId === "inledning/avslutning" && (
								<Card sectioned>
									<TextField
										multiline={5}
										label="Inledning"
										value={this.state.form.introduction}
										onChange={this.updateForm.bind(this, "introduction")}
										disabled={locked}
									/>
									<TextField
										multiline={5}
										label="Avslutning"
										value={this.state.form.closure}
										onChange={this.updateForm.bind(this, "closure")}
										disabled={locked}
									/>
								</Card>
							)}
							{selectedTabId === "uploads" && (
								<Card sectioned>
									<Uploads
										files={this.state.form.uploads || []}
										hidden={false}
										onUpload={this.addUploads.bind(this)}
										onRemove={this.removeUpload.bind(this)}
										disabled={locked}
									/>
								</Card>
							)}
							{selectedTabId === "history" && (
								<Card sectioned>
									<QuoteHistory quote={this.state.form} />
								</Card>
							)}
							{selectedTabId != "rader" && selectedTabId !== "history" && (
								<TotalCost
									vat={this.state.vat}
									oresavrundning={this.state.oresavrundning}
									grand_total={this.state.grand_total}
									subtotal={this.state.subtotal}
								/>
							)}
							{selectedTabId != "rader" && selectedTabId !== "history" && (
								<div style={{ textAlign: "right" }}>
									<Button
										onClick={() => {
											this.setState({ selectedTab: 1 });
										}}
									>
										Ändra rader
									</Button>
								</div>
							)}
						</div>
					</Layout.Section>
				</Layout>

				<PreviewPdfModal open={this.state.previewPdf} form={this.state.form} onClose={() => this.setState({ previewPdf: false })} />

				<Modal
					title={`Lås upp ${`${this.state.form.title} (${this.state.form.revision})`}?`}
					open={this.state.confirmUnlockModal}
					onClose={this.closeConfirmUnlockModal.bind(this)}
					primaryAction={{
						content: "Lås upp",
						onAction: this.unlockForEdit.bind(this),
						loading: this.state.unlockLoading,
					}}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: this.closeConfirmUnlockModal.bind(this),
						},
					]}
				>
					<Modal.Section>
						<Heading element="h1">Är du säker du vill låsa upp {`${this.state.form.title} (${this.state.form.revision})`}?</Heading>
						<br />
						<p>
							Om du väljer att låsa upp offerten för redigering kommer nuvarande version av offerten att avaktiveras och kunden kan inte längre
							godkänna den
						</p>
					</Modal.Section>
				</Modal>
				<Modal
					title={`Acceptera offert "${`${this.state.form.title} (${this.state.form.revision})`}"?`}
					open={this.state.acceptModal}
					onClose={this.closeAcceptModal.bind(this)}
					primaryAction={{
						content: "Acceptera",
						onAction: this.acceptQuote.bind(this),
						loading: this.state.acceptLoading,
					}}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: this.closeAcceptModal.bind(this),
						},
					]}
				>
					<Modal.Section>
						<Heading element="h1">Är du säker du vill acceptera offerten "{`${this.state.form.title} (${this.state.form.revision})`}"?</Heading>
					</Modal.Section>
				</Modal>
				<Modal
					title="Skicka offert till kund"
					open={this.state.isOpenSendFormModal}
					onClose={this.closeSendFormModal.bind(this)}
					primaryAction={{
						content: "Skicka",
						onAction: this.sendForm.bind(this),
						loading: this.state.sending,
						disabled: !this.state.form.email,
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeSendFormModal.bind(this),
						},
					]}
				>
					<Modal.Section>
						<TextField label="Email" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} required />
					</Modal.Section>
				</Modal>
			</React.Fragment>
		);

		if (this.props.noPage) {
			return content;
		}

		const backAction = (() => {
			if (this.props.location && this.props.location.state && this.props.location.state.project) {
				return { content: `Projekt (${this.props.location.state.project.title})`, url: `/admin/projects/${this.props.location.state.project.id}` };
			}
			if (this.props.location && this.props.location.state && this.props.location.state.customer) {
				return { content: `Kund (${this.props.location.state.customer.name})`, url: `/admin/customers/${this.props.location.state.customer.id}` };
			}
			if (!this.props.quote) {
				return { content: "Offerter", url: "/admin/quotes" };
			}
			return undefined;
		})();
		return (
			<div className="Quote-Page Quote-Action-Edit" ref={this.ref}>
				<Page
					fullWidth
					title={`Offert: ${this.state.form.title || ""}`}
					backAction={backAction}
					primaryAction={primaryAction}
					secondaryActions={[
						{
							icon: MaximizeMinor,
							content: "Förhandsgranska offert",
							onAction: () => this.setState({ previewPdf: true }),
						},
					]}
					// secondaryActions={[secondaryActions]}
					titleMetadata={metadata}
				>
					{content}

					<PageActions primaryAction={primaryAction} />
				</Page>
			</div>
		);
	}
}
export default QuoteEdit;

export const WidthWrapper = styled.div`
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;

	& > * {
		min-width: ${({ width }) => `${width}px`};
	}
`;

import { store } from "src/js/store";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getBasisTabs = (invoice: FortnoxInvoiceType | null = null) => {
	return [
		{
			id: "time_reports",
			content: "Tidrapporter",
		},
		{
			id: "expense_reports",
			content: "Utgifter",
		},
		{
			id: "supplier_invoice_rows",
			content: "Leverantörsfakturor",
		},
		{
			id: "quote_rows",
			content: "Offerter",
			disabled: invoice?.OrderReference || invoice?.OfferReference || (store.getState().account.fortnox_order_module && invoice?.InvoiceRows?.length),
			tooltip:
				invoice?.OrderReference || invoice?.OfferReference
					? "Det går inte att ändra offerter eller ordrar på en faktura som är kopplad till en order eller offert."
					: store.getState().account.fortnox_order_module &&
					  invoice?.InvoiceRows?.length &&
					  "Det går inte att välja fortnox offert när det redan finns fakturarader.",
			// enable: !store.getState().account.fortnox_order_module,
		},
	];
};
export default getBasisTabs;

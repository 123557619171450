const getPolarisTranslations = () => {
	return {
		locale: "sv",
		Polaris: {
			DatePicker: {
				previousMonth: "Föregående",
				nextMonth: "Nästa",
				months: {
					january: "januari",
					february: "februari",
					march: "mars",
					april: "april",
					may: "maj",
					june: "juni",
					july: "juli",
					august: "augusti",
					september: "september",
					october: "oktober",
					november: "november",
					december: "december",
				},
				daysAbbreviated: {
					monday: "mån",
					tuesday: "tis",
					wednesday: "ons",
					thursday: "tor",
					friday: "fre",
					saturday: "lör",
					sunday: "sön",
				},
			},
			DropZone: { FileUpload: { actionTitleImage: "Ladda upp bild", actionHintImage: "eller dra och sl\u00e4pp bild h\u00e4r" } },
			Common: { undo: "\u00e5ngra" },
			Filters: {
				filter: "Sök",
				cancel: "avbryt",
				done: "klar",
				noFiltersApplied: "Inga filter",
				clear: "rensa",
				clearLabel: "rensa",
				clearAllFilters: "rensa alla filter",
				moreFilters: "Fler filter",
			},
			ResourceList: {
				loading: "Laddar {resource}",
				showing: "Visar {itemsCount} {resource}",
				defaultItemPlural: "artiklar",
				defaultItemSingular: "artikel",
				sortingLabel: "Sortera p\u00e5",
				selected: "{selectedItemsCount} markerade",
				allItemsSelected: "Alla {itemsLength}+ {resourceNamePlural} \u00e4r markerade.",
				selectAllItems: "Markera alla {itemsLength}+ {resourceNamePlural}",
				emptySearchResultTitle: "Inga {resourceNamePlural} hittades",
				emptySearchResultDescription: "Prova att \u00e4ndra filter eller s\u00f6kning",
				selectButtonText: "Markera",
				a11yCheckboxDeselectAllSingle: "Avmarkera {resourceNameSingular}",
				a11yCheckboxSelectAllSingle: "Markera {resourceNameSingular}",
				a11yCheckboxDeselectAllMultiple: "Avmarkera alla {itemsLength} {resourceNamePlural}",
				a11yCheckboxSelectAllMultiple: "Markera alla {itemsLength} {resourceNamePlural}",
				ariaLiveSingular: "{itemsLength} artikel",
				ariaLivePlural: "{itemsLength} artiklar",
				BulkActions: { actionsActivatorLabel: "\u00c5tg\u00e4rd", moreActionsActivatorLabel: "Fler \u00e5tg\u00e4rder" },
				FilterCreator: {
					filterButtonLabel: "Filter",
					selectFilterKeyPlaceholder: "V\u00e4lj ett filter\u2026",
					addFilterButtonLabel: "L\u00e4gg till filter",
					showAllWhere: "Visa alla {resourceNamePlural} med:",
				},
				Filters: {
					filter: "Sök",
					cancel: "avbryt",
					done: "klar",
					noFiltersApplied: "Inga filter",
					clear: "rensa",
					clearLabel: "rensa",
					clearAllFilters: "rensa alla filter",
					moreFilters: "Fler filter",
				},
				FilterControl: { textFieldLabel: "S\u00f6k {resourceNamePlural}" },
				FilterValueSelector: { selectFilterValuePlaceholder: "V\u00e4lj ett filter\u2026" },
				DateSelector: {
					dateFilterLabel: "V\u00e4lj ett v\u00e4rde",
					dateValueLabel: "Datum",
					dateValuePlaceholder: "YYYY-MM-DD",
					SelectOptions: {
						PastWeek: "senaste veckan",
						PastMonth: "senaste månaden",
						PastQuarter: "senaste 3 månaderna",
						PastYear: "senaste året",
						ComingWeek: "next week",
						ComingMonth: "next month",
						ComingQuarter: "in the next 3 months",
						ComingYear: "in the next year",
						OnOrBefore: "till och med",
						OnOrAfter: "från och med",
					},
					FilterLabelForValue: {
						past_week: "in the last week",
						past_month: "in the last month",
						past_quarter: "in the last 3 months",
						past_year: "in the last year",
						coming_week: "next week",
						coming_month: "next month",
						coming_quarter: "in the next 3 months",
						coming_year: "in the next year",
						on_or_before: "till och med {date}",
						on_or_after: "från och med {date}",
					},
				},
			},
			Avatar: {
				label: "Avatar",
				labelWithInitials: "Avatar med initialer {initials}",
			},
			Autocomplete: {
				spinnerAccessibilityLabel: "Laddar",
				ellipsis: "{content}...",
			},
			Badge: {
				PROGRESS_LABELS: {
					incomplete: "Ofullständig",
					partiallyComplete: "Delvis slutförd",
					complete: "Slutförd",
				},
				STATUS_LABELS: {
					info: "Info",
					success: "Lyckades",
					warning: "Varning",
					attention: "Observera",
					new: "Nytt",
					critical: "Kritisk",
				},
				progressAndStatus: "{statusLabel} {progressLabel}",
			},
			Button: {
				spinnerAccessibilityLabel: "Laddar",
				connectedDisclosureAccessibilityLabel: "Relaterade åtgärder",
			},

			ContextualSaveBar: {
				save: "Spara",
				discard: "Avfärda",
			},
			DataTable: {
				sortAccessibilityLabel: "sortera {direction} efter",
				navAccessibilityLabel: "Skrolla tabellen {direction} en kolumn",
				totalsRowHeading: "Totalt",
				totalRowHeading: "Totalt",
			},

			DiscardConfirmationModal: {
				title: "Ignorera alla ändringar som inte har sparats",
				message: "Om du ignorerar ändringar så raderar du alla ändringar som du har gjort efter att du sparade senast.",
				primaryAction: "Ignorera ändringar",
				secondaryAction: "Fortsätt redigera",
			},

			EmptySearchResult: {
				altText: "Tomma sökresultat",
			},
			Frame: {
				skipToContent: "Gå vidare till innehåll",
				Navigation: {
					closeMobileNavigationLabel: "Stäng navigering",
				},
				navigationLabel: "Navigering",
			},
			ActionMenu: {
				RollupActions: {
					rollupButton: "Visa åtgärder",
				},
				Actions: {
					moreActions: "Fler åtgärder",
				},
			},

			Modal: {
				iFrameTitle: "markering av brödtext",
				modalWarning: "Dessa nödvändiga egenskaper saknas från Modal: {missingProps}",
			},
			Pagination: {
				previous: "Föregående",
				next: "Nästa",
				pagination: "Paginering",
			},
			ProgressBar: {
				negativeWarningMessage: "Värden som skickas till framstegspropagering bör inte vara negativa. Återställning {progress} till 0.",
				exceedWarningMessage: "Värden som skickas till framsteget ska inte överstiga 100. Ställer in {progress} till 100.",
			},

			SkeletonPage: {
				loadingLabel: "Sidan laddar",
			},
			Tabs: {
				toggleTabsLabel: "Fler vyer",
				newViewAccessibilityLabel: "Skapa ny vy",
				newViewTooltip: "Skapa vy",
				Tab: {
					rename: "Döp om vy",
					duplicate: "Duplicera vy",
					edit: "Redigera vy",
					editColumns: "Redigera kolumner",
					delete: "Radera vy",
					copy: "Kopia av {name}",
					deleteModal: {
						title: "Radera vy?",
						description: "Du kan inte ångra detta. {viewName}-visning kommer inte längre vara tillgänglig i din admin.",
						cancel: "Avbryt",
						delete: "Radera vy",
					},
				},
				RenameModal: {
					title: "Byt namn på vy",
					label: "Namn",
					cancel: "Avbryt",
					create: "Spara",
					errors: {
						sameName: "En vy med detta namn finns redan. Välj ett annat namn.",
					},
				},
				DuplicateModal: {
					title: "Duplicera vy",
					label: "Namn",
					cancel: "Avbryt",
					create: "Skapa vy",
					errors: {
						sameName: "En vy med detta namn finns redan. Välj ett annat namn.",
					},
				},
				CreateViewModal: {
					title: "Skapa ny vy",
					label: "Namn",
					cancel: "Avbryt",
					create: "Skapa vy",
					errors: {
						sameName: "En vy med detta namn finns redan. Välj ett annat namn.",
					},
				},
			},
			Tag: {
				ariaLabel: "Ta bort {children}",
			},
			TextField: {
				characterCount: "{count} tecken",
				characterCountWithMaxLength: "{count} av {limit} tecken har använts",
			},
			TopBar: {
				toggleMenuLabel: "Växla menyn",
				SearchField: {
					clearButtonLabel: "Rensa",
					search: "Sök",
				},
			},
			MediaCard: {
				popoverButton: "Åtgärder",
				dismissButton: "Avvisa",
			},
			VideoThumbnail: {
				playButtonA11yLabel: {
					default: "Spela video",
					defaultWithDuration: "Spela video som är {duration} lång",
					duration: {
						hours: {
							other: {
								only: "{hourCount} timmar",
								andMinutes: "{hourCount} timmar och {minuteCount} minuter",
								andMinute: "{hourCount} timmar och {minuteCount} minut",
								minutesAndSeconds: "{hourCount} timme, {minuteCount} minuter och {secondCount} sekunder",
								minutesAndSecond: "{hourCount} timmar, {minuteCount} minuter och {secondCount} sekund",
								minuteAndSeconds: "{hourCount} timmar, {minuteCount} minut och {secondCount} sekunder",
								minuteAndSecond: "{hourCount} timmar, {minuteCount} minut och {secondCount} sekund",
								andSeconds: "{hourCount} timme och {secondCount} sekunder",
								andSecond: "{hourCount} timmar och {secondCount} sekund",
							},
							one: {
								only: "{hourCount} timme",
								andMinutes: "{hourCount} timme och {minuteCount} minuter",
								andMinute: "{hourCount} timme och {minuteCount} minut",
								minutesAndSeconds: "{hourCount} timme, {minuteCount} minuter och {secondCount} sekunder",
								minutesAndSecond: "{hourCount} timme, {minuteCount} minuter och {secondCount} sekund",
								minuteAndSeconds: "{hourCount} timme, {minuteCount} minut och {secondCount} sekunder",
								minuteAndSecond: "{hourCount} timme, {minuteCount} minut och {secondCount} sekund",
								andSeconds: "{hourCount} timme och {secondCount} sekunder",
								andSecond: "{hourCount} timme och {secondCount} sekund",
							},
						},
						minutes: {
							other: {
								only: "{minuteCount} minuter",
								andSeconds: "{minuteCount} minuter och {secondCount} sekunder",
								andSecond: "{minuteCount} minuter och {secondCount} sekund",
							},
							one: {
								only: "{minuteCount} minut",
								andSeconds: "{minuteCount} minut och {secondCount} sekunder",
								andSecond: "{minuteCount} minut och {secondCount} sekund",
							},
						},
						seconds: {
							other: "{secondCount} sekunder",
							one: "{secondCount} sekund",
						},
					},
				},
			},
			Loading: {
				label: "Fält för att ladda sida",
			},
			TooltipOverlay: {
				accessibilityLabel: "Verktygstips: {label}",
			},
			IndexProvider: {
				defaultItemSingular: "Artikel",
				defaultItemPlural: "Artiklar",
				allItemsSelected: "Alla {itemsLength}+ {resourceNamePlural} har valts",
				selected: "{selectedItemsCount} har valts",
				a11yCheckboxDeselectAllSingle: "Avmarkera {resourceNameSingular}",
				a11yCheckboxSelectAllSingle: "Välj {resourceNameSingular}",
				a11yCheckboxDeselectAllMultiple: "Avmarkera alla {itemsLength} {resourceNamePlural}",
				a11yCheckboxSelectAllMultiple: "Markera alla {itemsLength} {resourceNamePlural}",
			},
			IndexTable: {
				emptySearchTitle: "Inga {resourceNamePlural} hittades",
				emptySearchDescription: "Prova att byta filter eller sökord",
				onboardingBadgeText: "Nytt",
				resourceLoadingAccessibilityLabel: "Laddar {resourceNamePlural}",
				selectAllLabel: "Välj alla {resourceNamePlural}",
				selected: "{selectedItemsCount} har valts",
				undo: "Ångra",
				selectAllItems: "Välj alla {itemsLength}+ {resourceNamePlural}",
				selectItem: "Välj {resourceName}",
				selectButtonText: "Välj",
				sortAccessibilityLabel: "sortera {direction} efter",
			},
			Page: {
				Header: {
					rollupActionsLabel: "Visa åtgärder för {title}",
				},
			},
			FullscreenBar: {
				back: "Tillbaka",
				accessibilityLabel: "Lämna helskärmsläget",
			},
			FilterPill: {
				clear: "Rensa",
			},
			IndexFilters: {
				searchFilterTooltip: "Sök och filtrera (F)",
				searchFilterAccessibilityLabel: "Sök och filtrera resultat",
				sort: "Sortera dina resultat",
				addView: "Lägg till en ny vy",
				newView: "Anpassad sökning",
				SortButton: {
					ariaLabel: "Sortera resultatet",
					tooltip: "Sortera",
					title: "Sortera efter",
					sorting: {
						asc: "Stigande",
						desc: "Fallande",
						az: "A–Ö",
						za: "Ö–A",
					},
				},
				UpdateButtons: {
					cancel: "Avbryt",
					update: "Uppdatera",
					save: "Spara",
					saveAs: "Spara som",
					modal: {
						title: "Spara vy som",
						label: "Namn",
						sameName: "En vy med detta namn finns redan. Välj ett annat namn.",
						save: "Spara",
						cancel: "Avbryt",
					},
				},
			},
		},
		frame: {
			routes: {
				dashboard: "Dashboard",
				products: "Produkter",
				pricing: "Priser",
				inventory: "Lager",
				categories: "Kategorier",
				listings: "Listningar",
				orders: "Ordrar",
				dropshipping_catalog: "Dropshipping produkter",
				sales_channels: "S\u00e4ljkanaler",
				settings: "Inst\u00e4llningar",
				customers: "Kunder",
				fulfillments: "Leveranser",
			},
			user: { log_out: "Logga ut" },
			search: { placeholder: "S\u00f6k" },
		},
	};
};
export default getPolarisTranslations;

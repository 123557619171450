import React from "react";

const List2Icon = ({ color = "#FFD66B" }) => (
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.9023 16H27.9023" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M16.9023 8H27.9023" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M16.9023 24H27.9023" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.4023 6L8.06484 10L5.90234 8" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.4023 14L8.06484 18L5.90234 16" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.4023 22L8.06484 26L5.90234 24" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default List2Icon;

import React, { useEffect, useRef, useState } from "react";

import "./login.scss";
import styled from "styled-components";
import { Checkbox } from "@shopify/polaris";
import { AddCookie } from "../Utilities";

import logoTextImage from "./images/T24_logo_text.png";
import API from "../API";
import { store } from "../store";
import { getSubdomain } from "../API/utils";
import { toastr } from "../components/toastr";
import BASE_URL from "../API/BASE_URL";
import Colors from "../Colors";

const backgroundColor = Colors.primary;
const buttonColor = Colors.accent;

const Login = ({ history }) => {
	const params = new URLSearchParams(window.location.search);
	const [loading, setLoading] = useState();
	const [error, setError] = useState(String(params.get("error_msg")) === "undefined" ? null : params.get("error_msg"));
	const [rememberMeChecked, setRememberMeChecked] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [handle, setHandle] = useState(getSubdomain(window.location.href));
	const usernameRef = useRef();
	const accountIdRef = useRef();
	const passwordRef = useRef();

	// const navigate = useNavigate();

	useEffect(() => {
		setHandle(getSubdomain(window.location.href));
	}, []);

	useEffect(() => {
		// const searchParams = new URLSearchParams(document.location.search);
		window.history.replaceState({}, document.title, "/admin/authentication/login");
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		const accountId = accountIdRef.current?.value;
		const username = usernameRef.current?.value;
		const password = passwordRef.current?.value;

		API.post(`${BASE_URL}/api/authentication/auth.json`, {
			username: accountId ? `${username}||${accountId}` : username,
			password,
			acc_id: accountId || params.get("acc_id") || params.get("accountId") || null,
		})
			.then(async (result) => {
				if (result.data.error) {
					setError(result.data.error);
					return;
				}

				AddCookie("token", result.data.api_key);

				store.dispatch({ type: "SET_ACCOUNT", account: result.data.account });
				store.dispatch({ type: "SET_USER", user: result.data.user });

				const url = `/admin`;
				history.push(url);
				// window.location.assign(url);
				return result.data;
			})
			.catch((error) => {
				toastr.error(error);
				setError(error?.response?.data?.message || error?.message);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// return (
	// 	<Container>
	// 		<div className="wrapper_left" />
	// 		<div className="wrapper_right" />
	// 	</Container>
	// );

	// eslint-disable-next-line no-unreachable
	return (
		<Container>
			<div className="left_wrapper">
				<div className="form_wrapper" style={{ maxWidth: "unset" }}>
					<a className="logo_link" href="https://www.tidrapport24.se">
						<img alt="" className="logo" src={logoTextImage} />
					</a>

					<div>
						<h1 className="form_title">Välkommen tillbaka</h1>
						<p style={{ marginTop: "5px" }} className="helptext">
							Vänligen fyll i dina uppgifter
						</p>
					</div>
					<form id="loginform" className="form_wrapper" aria-label="form" onSubmit={onSubmit}>
						{!handle && (
							<div>
								<p className="label">Konto ID</p>
								<input type="text" name="text" className="form_input" ref={accountIdRef} placeholder="Konto ID.." />
							</div>
						)}
						<div>
							<p className="label">Användarnamn</p>
							<input type="text" name="username" className="form_input" ref={usernameRef} placeholder="Användarnamn.." />
						</div>
						<div>
							<p className="label">Lösenord</p>
							<input className="form_input" type="password" name="password" ref={passwordRef} placeholder="Lösenord.." />
						</div>

						<div className="flex_wrapper">
							<div style={{ display: "flex", gap: "0.5rem", color: "#ffffff", alignItems: "center" }}>
								<Checkbox
									label="Kom ihåg mig"
									checked={rememberMeChecked}
									onChange={(v) => {
										setRememberMeChecked(v);
									}}
								/>
							</div>
							<a className="helptext" href={`${BASE_URL}/admin/authentication/resetting/request`}>
								Glömt lösenord
							</a>
						</div>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<button className="form_button light" type="submit" id="_submit" name="_submit" loading={String(loading)}>
								<div className="spinner">
									<div />
									<div />
									<div />
									<div />
								</div>

								<span>Logga in</span>
							</button>
						</div>

						{error && <div className="invalid form-error">{error}</div>}
					</form>
				</div>
			</div>

			<div className="right_wrapper" />
		</Container>
	);
};
export default Login;

const Container = styled.div`
	font-size: 13px;
	height: 100vh;
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Helvetica Neue, sans-serif;

	background-image: url("/assets/t24-login-background.png");
	background-position: center center;
	background-size: cover;
	background-color: ${backgroundColor};
	color: #fff;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	* {
		box-sizing: border-box;
	}

	.left_wrapper {
		padding: 1rem;
		display: grid;
		place-items: center;
		justify-content: center;
		align-items: center;
		/* background-color: ${backgroundColor}; */
		flex: 1;
		transition: width 500ms;
	}
	.right_wrapper {
	}

	@media (max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
		background-image: none;

		.right_wrapper {
			display: none;
		}
	}

	.flex_wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0.5rem;
	}

	.helptext {
		margin: 0;
		color: #ffffff80;
		font-size: 0.9em;
	}

	.loader,
	.loader:after {
		border-radius: 50%;
		width: 1em;
		height: 1em;
	}
	.loader {
		font-size: 10px;
		position: relative;
		text-indent: -9999em;
		border-top: 1.1em solid rgba(255, 255, 255, 0.2);
		border-right: 1.1em solid rgba(255, 255, 255, 0.2);
		border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
		border-left: 1.1em solid #ffffff;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-animation: load8 1.1s infinite linear;
		animation: load8 1.1s infinite linear;
	}
	@-webkit-keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.sk-cube-grid {
		width: 40px;
		height: 40px;
		margin: 100px auto;
	}

	.sk-cube-grid .sk-cube {
		width: 33%;
		height: 33%;
		background-color: #333;
		float: left;
		-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
		animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	}
	.sk-cube-grid .sk-cube1 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
	.sk-cube-grid .sk-cube2 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
	.sk-cube-grid .sk-cube3 {
		-webkit-animation-delay: 0.4s;
		animation-delay: 0.4s;
	}
	.sk-cube-grid .sk-cube4 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}
	.sk-cube-grid .sk-cube5 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
	.sk-cube-grid .sk-cube6 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}
	.sk-cube-grid .sk-cube7 {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}
	.sk-cube-grid .sk-cube8 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}
	.sk-cube-grid .sk-cube9 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}

	@-webkit-keyframes sk-cubeGridScaleDelay {
		0%,
		70%,
		100% {
			-webkit-transform: scale3D(1, 1, 1);
			transform: scale3D(1, 1, 1);
		}
		35% {
			-webkit-transform: scale3D(0, 0, 1);
			transform: scale3D(0, 0, 1);
		}
	}

	@keyframes sk-cubeGridScaleDelay {
		0%,
		70%,
		100% {
			-webkit-transform: scale3D(1, 1, 1);
			transform: scale3D(1, 1, 1);
		}
		35% {
			-webkit-transform: scale3D(0, 0, 1);
			transform: scale3D(0, 0, 1);
		}
	}
	.form-error {
		color: #ff6b6b;
		background-color: lighten(#ff6b6b, 20);
		border-radius: 5px;
		padding: 10px 15px;
		display: inline-block;
		font-size: 1em;
		font-weight: bold;
		word-wrap: break-word;
	}

	.spinner {
		position: relative;
		width: 24px;
		height: 24px;

		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 24px;
			height: 24px;
			/* margin: 3px; */
			border: 3px solid #fff;
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: #fff transparent transparent transparent;
		}
		div:nth-child(1) {
			animation-delay: -0.45s;
		}
		div:nth-child(2) {
			animation-delay: -0.3s;
		}
		div:nth-child(3) {
			animation-delay: -0.15s;
		}

		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	.buttons_wrapper {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
	}

	.flex {
		display: flex;
		align-items: center;

		&.space-between {
			justify-content: space-between;
		}
	}

	.form_button {
		border: none;
		color: #061d20;

		span {
			color: #061d20;
		}

		.spinner {
			display: none;
		}

		&[loading="true"] {
			.spinner {
				display: inline-block;
			}
		}

		a {
			color: #ffffff;
			&:hover {
				color: #ffffff;
			}
		}

		&.fullWidth {
			width: 100%;
			justify-content: center;
		}

		&:not(.plain) {
			padding: 12.5px 25px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
			background-color: ${buttonColor};
			font-weight: 500;

			&.light {
				background-color: ${buttonColor};
				color: #010520;
			}
		}
		&.plain {
			padding: 2px;
			box-shadow: none;
			background-color: transparent;
		}

		width: max-content;
		/* border-radius: 20%/50%; */
		border-radius: 50px;
		color: #ffffff;
		cursor: pointer;
		transition: opacity 250ms, filter 250ms, background-color 250ms;
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.Polaris-Icon {
			fill: #ffffff;
			height: 1.35rem;
			width: 1.35rem;
		}
		&:disabled {
			cursor: initial;
			filter: brightness(0.5);
			pointer-events: none;
		}

		&:hover:not(:disabled) {
			background-color: lighten(#02f4c4, 1.2);
			// filter: brightness(1.25);
		}
	}

	.form_title {
		color: #ffffff;

		font-size: 30px;
		font-weight: 500;
		line-height: 35px;
	}

	.form_input {
		padding: 10px;
		border-radius: 0.5rem;
		border: none;
		width: 100%;
		background-color: #ffffff;
		color: rgb(0, 0, 0);

		&::placeholder {
			color: rgb(150, 150, 150);
			font-size: 1.03em;
		}
	}

	.handle_wrapper {
		display: inline-block;
		position: relative;

		&::after {
			position: absolute;
			right: 1em;
			transition: all 0.05s ease-in-out;
			height: 100%;
			margin: auto;
			display: flex;
			top: 0;
			align-items: center;
		}

		/* handle Firefox (arrows always shown) */
		@supports (-moz-appearance: none) {
			&::after {
				right: 2em;
			}
		}

		.form_input {
			height: 35px;
		}
		// &::after {
		// 	content: "${({ site }) => site}";
		// }
	}

	.form_wrapper {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		// max-width: 400px;
		// padding: 1rem;
		min-width: 400px;
		width: 400px;

		@media (max-width: 400px) {
			min-width: 100%;
		}

		a {
			color: #ffffff;
		}

		.Polaris-TextField__Backdrop {
			border-radius: 50px;
		}

		.form_wrapper {
			width: 100%;
		}
	}

	.logo_link {
		margin-top: -100px;
		margin-bottom: 50px;
	}

	.logo {
		object-fit: contain;
		height: 50px;
		width: 100%;
	}

	input[type="checkbox"] {
		height: 1.75rem;
		width: 1.75rem;
		border-radius: 3px;
		// appearance: none;
		// -webkit-appearance: none;
	}

	.text {
		font-size: 1.1em;
		font-weight: 500;
	}
	.boxes {
		display: flex;
		flex-wrap: wrap;
		gap: 4rem;

		@media (max-width: 1450px) {
			display: none;
		}

		.box {
			width: 200px;

			h2 {
				font-weight: bold;
				font-size: 1.4rem;
				margin: 1rem 0;
			}

			p {
				line-height: 1.5em;
				font-size: 1.1rem;
			}
		}
	}

	// .slide {
	// 	opacity: 0;
	// 	pointer-events: none;
	// 	transition: opacity 500ms;
	// 	flex-direction: column;

	// 	position: absolute;
	// 	inset: 0;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;

	// 	&.active {
	// 		opacity: 1;
	// 		pointer-events: all;
	// 	}

	// 	&.second {
	// 		.form_wrapper {
	// 			width: 90%;
	// 			max-width: 90%;
	// 		}

	// 		@media (max-width: 1000px) {
	// 			.form_wrapper {
	// 				width: 100%;
	// 				max-width: 100%;
	// 				padding: 0;
	// 				position: absolute;
	// 				bottom: 0;
	// 			}
	// 		}
	// 	}
	// }

	input[type="checkbox"] {
		filter: sepia(0.1) hue-rotate(312deg) brightness(1.35);
	}

	p.label {
		color: #ffffff;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	p {
		margin: 0;
	}
`;

import React, { Component } from "react";
import { Page, PageActions, Layout, Card, FormLayout, TextField, Checkbox } from "@shopify/polaris";
import { toastr } from "../../components/toastr";
import API from "../../API";

class UnderentreprenorCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			form: {
				name: "",
				company_number: "",
				email: "",
				phone: "",
				allow_time_registration: false,
			},
		};
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	toggleAllowTimeRegistration() {
		this.state.form.allow_time_registration = !this.state.form.allow_time_registration;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		if (!this.state.form.company_number) {
			toastr.error("Orgnr måste vara ifyllt");
			return;
		}
		if (!this.state.form.phone) {
			toastr.error("Telefonnummer måste vara ifyllt");
			return;
		}
		this.setState({ saving: true });
		API.post("/api/underentreprenorer.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Inbjudan skickades");
				this.props.history.replace("/admin/underentreprenorer");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<Page
				title="Bjud in underentreprenör"
				backAction={{ content: "Underentreprenörer", url: "/admin/underentreprenorer" }}
				primaryAction={{ content: "Bjud in", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection
						title="Uppgifter om entreprenör"
						description="Du betalar endast för aktiva personer hos underentreprenören, dvs finns det en tidregistrering den månaden räknas kontot som aktivt"
					>
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Företagsnamn" value={this.state.form.name} onChange={this.updateForm.bind(this, "name")} />
									<TextField
										label="Organisationsnummer"
										value={this.state.form.company_number}
										onChange={this.updateForm.bind(this, "company_number")}
									/>
									<TextField label="E-mail" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} />
									<TextField label="Telefonnummer" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} />
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection title="Tidregistrering" description="Hantera vad underentreprenören får göra">
						<Card>
							<Card.Section>
								<FormLayout>
									<Checkbox checked disabled label="Tillåt in- och utcheckning i personalliggare" />
									<Checkbox
										checked={this.state.form.allow_time_registration}
										label="Tillåt vanlig tidrapportering"
										onChange={this.toggleAllowTimeRegistration.bind(this)}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: "Bjud in",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default UnderentreprenorCreate;

import React, { useCallback, useMemo, useState } from "react";
import TimeReportModal from "src/js/components/TimeReportModal";
import { store } from "src/js/store";

type TimeReportModalPropsType = Omit<TimeReportModalProps, "open" | "onClose" | "onComplete">;

type TimeReportContextType = {
	isOpen: boolean;
	openTimeReportModal: () => void;
	closeTimeReportModal: () => void;
	handleSetOptions: (options: TimeReportModalPropsType) => void;
};

const TimeReportContext = React.createContext({} as TimeReportContextType);

const TimeReportContextProivder = ({ children }) => {
	const defaultOptions = useMemo(() => {
		return {
			user_name: store.getState().user.name,
			lock_user: store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0,
			user_id: store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0 ? store.getState().user.id : null,
		};
	}, []);

	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState<TimeReportModalPropsType>(defaultOptions as TimeReportModalPropsType);

	const handleSetOptions = useCallback(
		(newOptions = {}) => {
			setOptions({ ...defaultOptions, ...newOptions } as TimeReportModalPropsType);
		},
		[defaultOptions]
	);

	const openTimeReportModal = useCallback(() => {
		setIsOpen(true);
	}, []);

	const closeTimeReportModal = useCallback(() => {
		setIsOpen(false);
		setOptions(defaultOptions as TimeReportModalPropsType);
	}, [defaultOptions]);

	const state = useMemo(
		() => ({
			isOpen,
			openTimeReportModal,
			closeTimeReportModal,
			handleSetOptions,
		}),
		[isOpen, openTimeReportModal, closeTimeReportModal, handleSetOptions]
	);

	const props: TimeReportModalProps = {
		open: isOpen,
		onClose: closeTimeReportModal,
		onComplete: closeTimeReportModal,
		...options,
	};

	return (
		<TimeReportContext.Provider value={state}>
			<TimeReportModal {...props} />
			{children}
		</TimeReportContext.Provider>
	);
};
export default TimeReportContext;

export { TimeReportContextProivder };

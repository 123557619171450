import { Tabs } from "@shopify/polaris";
import Banner from "src/js/components/banner";
import React from "react";

import { Wrapper } from "./styles";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	invoice: Partial<FortnoxInvoiceType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
};

const Header = ({ invoice, tabs, onSelect, selected }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<Wrapper>
			{invoice?.Cancelled && (
				<Banner status="warning" title={"Faktura makulerad"}>
					{"Denna faktura är makulerad"}
				</Banner>
			)}
			{invoice?.Sent && (
				<Banner status="success" title={"Faktura skickad"}>
					{"Denna faktura är skickad"}
				</Banner>
			)}
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</Wrapper>
	);
};
export default Header;

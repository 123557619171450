import { Stack, TextField } from "@shopify/polaris";
import React, { Component } from "react";

export default class TranslationRow extends Component {
	render() {
		return (
			<Stack distribution="fillEvenly">
				<TextField label={this.props.first ? "Svenska" : ""} disabled value={this.props.string} />
				<TextField
					error={this.props.error}
					label={this.props.first}
					value={this.props.translation}
					onChange={(value) => this.props.onChange(value)}
					disabled={this.props.disabled}
				/>
			</Stack>
		);
	}
}

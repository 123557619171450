import React from "react";

const FolderOpenIcon = ({ color = "var(--textColor)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.56836 26.3854V8.38538C4.56836 8.12016 4.67372 7.86581 4.86125 7.67827C5.04879 7.49073 5.30314 7.38538 5.56836 7.38538H12.2309C12.4471 7.38627 12.6573 7.45635 12.8309 7.58538L16.3059 10.1854C16.4794 10.3144 16.6896 10.3845 16.9059 10.3854H25.5684C25.8336 10.3854 26.0879 10.4907 26.2755 10.6783C26.463 10.8658 26.5684 11.1202 26.5684 11.3854V14.3854"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.56836 26.3854L8.34336 15.0729C8.40916 14.8729 8.53642 14.6987 8.70701 14.5753C8.87759 14.4518 9.08279 14.3854 9.29336 14.3854H29.1809C29.339 14.3854 29.4949 14.4228 29.6357 14.4948C29.7765 14.5667 29.8983 14.6709 29.991 14.799C30.0837 14.9271 30.1447 15.0754 30.1691 15.2316C30.1934 15.3879 30.1803 15.5477 30.1309 15.6979L26.5684 26.3854H4.56836Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default FolderOpenIcon;

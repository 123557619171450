import React, { Component } from "react";
import SplashLoadingScreen from "../SplashLoadingScreen";
import { RemoveCookie } from "../Utilities";

class Logout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		console.info("Logout");
		RemoveCookie("token");

		this.props.history.replace("/admin/authentication/login");
	}

	render() {
		return <SplashLoadingScreen text="Logging out.." />;
	}
}
export default Logout;

/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { store } from "./store";

import API from "./API";
import App from "./app";
import SplashLoadingScreen from "./SplashLoadingScreen.tsx";
import "../css/app.scss";
import { toastr } from "./components/toastr";

class PreApp extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		this.fetchInitialStates();
	}

	async getAction({ url, callback = () => {} } = {}) {
		const result = await API.get("/api" + url).then((result) => {
			if (result.data.error) {
				return;
			}
			callback(result);
			return result.data;
		});

		return result;
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	async fetchInitialStates() {
		if (store.getState().account?.company) {
			document.title = `${store.getState().account.company} - T24`;
		}

		this.fetchRequiredInitialStates().then(() => {
			if (store.getState().user?.locale) {
				this.props.i18n.changeLanguage(store.getState().user.locale, () => {
					// eslint-disable-next-line no-console
					console.log(`Preapp Changed to language "${store.getState().user.locale}"`);
				});
			}
		});
		this.fetchNonRequiredInitialStates();
	}

	async fetchRequiredInitialStates() {
		await Promise.all([
			this.getAction({
				url: "/me.json",
				callback: (result) => {
					if (result.data) {
						store.dispatch({ type: "SET_USER", user: result.data });
					}
				},
			}),
			this.getAction({
				url: "/account.json",
				callback: (result) => {
					if (result.data) {
						if (result.data?.company) {
							document.title = `${result.data?.company} - T24`;
						}

						store.dispatch({ type: "SET_ACCOUNT", account: result.data });
					}
				},
			}),
		])
			.then(() => {
				// console.log("fetchRequiredInitialStates done");
				this.setState({ loading: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	async fetchNonRequiredInitialStates() {
		await Promise.all([
			this.getAction({
				url: "/users.json",
				callback: (result) => result.data.users && store.dispatch({ type: "SET_USERS", users: result.data.users }),
			}),
			this.getAction({
				url: "/accounts.json",
				callback: (result) => result.data.accounts && store.dispatch({ type: "SET_ACCOUNTS", accounts: result.data.accounts }),
			}),
			this.getAction({
				url: "/work_types.json",
				callback: (result) => {
					return result.data.work_types && store.dispatch({ type: "SET_WORK_TYPES", work_types: result.data.work_types });
				},
			}),
			this.getAction({
				url: "/user_groups.json",
				callback: (result) => {
					return result.data.user_groups && store.dispatch({ type: "SET_GROUPS", groups: result.data.user_groups });
				},
			}),
			this.getAction({
				url: "/holidays.json",
				callback: (result) => {
					return result.data.holidays && store.dispatch({ type: "SET_HOLIDAYS", holidays: result.data.holidays });
				},
			}),
		])
			.then((res) => {
				// console.log("fetchNonRequiredInitialStates done");
			})
			.catch((error) => {
				toastr.error(error);
				// const { code, message } = error?.response?.data || {};
				// window.location.href = encodeURI(`/admin/authentication/login?error_code=${code}&error_msg=${message}`);
			});
	}

	render() {
		// return <SplashLoadingScreen />;
		// eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-no-useless-fragment
		return <>{this.state.loading ? <SplashLoadingScreen /> : <App {...this.props} />}</>;
	}
}

export default PreApp;

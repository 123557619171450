import { Icon, OptionList, Popover } from "@shopify/polaris";
import { SelectMinor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import styled from "styled-components";
import Badge from "../Badge";

type Option = {
	label: string;
	value: string;
	color?: string;
};

type MultiSelectProps = {
	options: Option[];
	selected?: string[];
	value?: string[];
	onChange: (value: string[]) => void;
	placeholder?: string | React.ReactNode;
};

const MultiSelect = ({ options, selected, value, onChange, placeholder = "Välj.." }: MultiSelectProps) => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const selectedValues = selected || value || [];
	const selectedOptions = options.filter((option) => selectedValues.includes(option.value));

	const activator = (
		<ActivatorButton onClick={handleOpen}>
			{selectedOptions?.slice(0, 3)?.map((o) => (
				<Badge key={o.value} color={o?.color}>
					{o.label}
				</Badge>
			))}

			{selectedOptions.length > 3 && <Badge>+{selectedOptions.length - 3}</Badge>}

			{!selectedValues?.length && placeholder}
			<Icon source={SelectMinor} />
		</ActivatorButton>
	);
	return (
		<Popover active={open} activator={activator} onClose={handleClose}>
			<OptionList onChange={onChange} options={options} selected={selectedValues} allowMultiple />
		</Popover>
	);
};
export default MultiSelect;

const ActivatorButton = styled.button`
	display: flex;
	padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-2)
		calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-3);
	align-items: center;
	gap: var(--p-space-1);

	background-color: var(--tertiary);
	border: none;
	color: var(--color-primary);

	.Polaris-Icon {
		svg {
			fill: var(--p-color-icon);
		}
	}
`;

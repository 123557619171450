import { Component } from "react";
import { Page, PageActions, Layout, Card, Badge, FormLayout, Tag, TextField, Stack, Checkbox } from "@shopify/polaris";
import SearchField from "../../components/search_field.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class FortnoxExpenseCreate extends Component {
	constructor(props) {
		super(props);
		this.state = { saving: false, form: { title: "", tags: [], unit: "st", time: false, supplier: "", sku: "", price: "", purchase_price: "" } };
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/expenses.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Artikel skapades");
				this.props.history.replace("/admin/expenses/" + result.data.expense.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	addTag(item) {
		this.state.form.tags.push(item);
		this.setState({ form: this.state.form });
	}

	removeTag(index) {
		this.state.form.tags.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	render() {
		return (
			<Page
				title="Ny artikel"
				titleMetadata={this.state.form.favorite ? <Badge status="success">Favorit</Badge> : null}
				backAction={{ content: "Artikelregister", url: "/admin/expenses" }}
				primaryAction={{ content: "Skapa artikel", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Artikelinformation" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
									<TextField label="Artnr" value={this.state.form.sku} onChange={this.updateForm.bind(this, "sku")} />
									<TextField label="Leverantör" value={this.state.form.supplier} onChange={this.updateForm.bind(this, "supplier")} />
									<TextField label="Enhet" value={this.state.form.unit} onChange={this.updateForm.bind(this, "unit")} />
									<Checkbox
										checked={this.state.form.favorite}
										label="Favorit (syns som snabbval vid rapportering)"
										onChange={this.updateForm.bind(this, "favorite")}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
						<Card title="Taggar" subdued sectioned>
							<SearchField
								resource="expenses/tags"
								resourceName={{
									singular: "tagg",
									plural: "taggar",
								}}
								onSelect={this.addTag.bind(this)}
								tags
							/>
							{this.state.form.tags.length ? (
								<Stack>
									{this.state.form.tags.map((tag, index) => {
										return (
											<Tag key={tag.id || index} onRemove={this.removeTag.bind(this, index)}>
												{tag.title}
											</Tag>
										);
									})}
								</Stack>
							) : null}
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Prisuppgifter" description="Fyll i uppgifter som namn och leverantör här">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField
										suffix="kr"
										type="currency"
										label="Inköpspris"
										value={this.state.form.purchase_price}
										onChange={this.updateForm.bind(this, "purchase_price")}
									/>
									<TextField suffix="kr" type="currency" label="Pris" value={this.state.form.price} onChange={this.updateForm.bind(this, "price")} />
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: "Skapa artikel",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default FortnoxExpenseCreate;

import { Card, FormLayout, Layout, Page, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { toastr } from "../../components/toastr";
import API from "../../API";

export default class CreateWorkType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			form: {
				title: null,
				code: null,
			},
		};
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/work_types.json", this.state.form)
			.then((response) => {
				if (response.data.error) {
					toastr.error(response.data.error);
					this.setState({ saving: false });
				}
				if (response.data.work_type) {
					this.setState({ form: response.data.work_type, saving: false });
					toastr.success("Arbetstypen skapades!");
					this.props.history.replace("/admin/settings/work-type");
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			})
			.then(() => {
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Page
				title="Ny arbetstyp"
				backAction={{ content: "Arbetstyper", onAction: () => this.props.history.goBack() }}
				primaryAction={{ content: "Spara arbetstyp", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<FormLayout>
								<TextField label="Benämning" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
								<TextField label="kod" value={this.state.form.code} onChange={this.updateForm.bind(this, "code")} />
							</FormLayout>
						</Card>
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}

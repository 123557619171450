import { FormLayout, Card, Heading, Layout, Modal, Page, PageActions, Stack } from "@shopify/polaris";
import React, { Component } from "react";
import ResourcePicker from "../../components/resource_picker";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ContactEdit extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, form: {} };
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		console.debug(this.state.loading);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/contacts/" + id + ".json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false, saving: false });
				}
				this.setState({ loading: false, saving: false, form: result.data.contact || {} });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/contacts/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				this.setState({ form: result.data.contact });
				toastr.success("Kontakt uppdaterad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	remove() {
		this.setState({ removing: true });
		API.delete(`/api/contacts/${this.state.form.id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ removing: false });
					return;
				}
				if (result.data && !this.props.asModal) {
					this.props.history.replace("/admin/contacts");
				}
				this.setState({ removing: false });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	openModal(modal) {
		this.setState({ [modal]: true });
	}

	closeModal(modal) {
		this.setState({ [modal]: false });
	}

	render() {
		return (
			<Page
				fullWidth
				title={this.state.form.name}
				backAction={{ content: "Kontakter", url: "/admin/contacts" }}
				primaryAction={this.state.form && this.state.form.id && { content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				{this.state.form && this.state.form.id && (
					<React.Fragment>
						<Layout>
							<Layout.Section>
								<Card sectioned>
									<FormLayout>
										<Stack distribution="fillEvenly">
											<TextField required label="Förnamn" value={this.state.form.first_name} onChange={this.updateForm.bind(this, "first_name")} />
											<TextField required label="Efternamn" value={this.state.form.last_name} onChange={this.updateForm.bind(this, "last_name")} />
										</Stack>
										<Stack distribution="fillEvenly">
											<TextField label="Email" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} type="email" />
											<TextField label="Telefonnr" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} type="tel" />
										</Stack>
										<ResourcePicker
											placeholder="Sök kund"
											caption="Välj kund"
											resource="customers"
											resourceName={{
												singular: "kund",
												plural: "kunder",
											}}
											item={this.state.form.parent}
											onChange={this.updateForm.bind(this, "parent")}
											label="Kund"
											required
											disabled={this.props.parentDisabled}
											label_handle="name"
											textAlign="left"
										/>
									</FormLayout>
								</Card>
								<Card sectioned>
									<FormLayout>
										<TextField label="Adress" value={this.state.form.address} onChange={this.updateForm.bind(this, "address")} />
										<Stack distribution="fillEvenly">
											<TextField label="Postnummer" value={this.state.form.zip} onChange={this.updateForm.bind(this, "zip")} />
											<TextField label="Stad" value={this.state.form.city} onChange={this.updateForm.bind(this, "city")} />
										</Stack>
									</FormLayout>
								</Card>
							</Layout.Section>
						</Layout>
						<PageActions
							primaryAction={{
								content: "Spara",
								loading: this.state.saving,
								onAction: this.saveForm.bind(this),
							}}
							secondaryActions={{
								content: "Ta bort",
								loading: this.removing,
								onAction: this.openModal.bind(this, "removeModal"),
								destructive: true,
							}}
						/>
					</React.Fragment>
				)}
				<Modal
					title={`Ta bort "${this.state.form.name}"`}
					open={this.state.removeModal}
					onClose={this.closeModal.bind(this, "removeModal")}
					primaryAction={{
						content: "Ta bort",
						destructive: true,
						onAction: this.remove.bind(this),
						loading: this.state.removing,
					}}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: this.closeModal.bind(this, "removeModal"),
						},
					]}
				>
					<Modal.Section>
						<Heading element="h2">Är du säker du vill ta bort kontakten &quot;{this.state.form.name}&quot;</Heading>
					</Modal.Section>
				</Modal>
			</Page>
		);
	}
}
export default ContactEdit;

import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Card, DatePicker, Icon, Popover, TextField } from "@shopify/polaris";
import { CalendarMajor } from "@shopify/polaris-icons";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import BarChart from "src/js/components/BarChart";
import Colors from "../../Colors";
// import TextField from "../../components/TextField";
import ProjectDashboardRankList from "./ProjectDashboardRankList";
import { toastr } from "../../components/toastr";
import API from "../../API";

const moment = extendMoment(Moment);

class ProjectDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stats: {},
			endYear: moment().format("YYYY") * 1,
			endMonth: moment().format("MM") * 1 - 1,
			startYear: moment().format("YYYY") * 1,
			startMonth: moment().format("MM") * 1 - 1,
			start: moment().subtract(1, "month").format("YYYY-MM-DD"),
			end: moment().format("YYYY-MM-DD"),
		};
	}

	componentDidMount() {
		this.onFetch();
	}

	onFetch() {
		const params = {
			from: moment(this.state.start).format("YYYY-MM-DD"),
			to: moment(this.state.end).format("YYYY-MM-DD"),
		};

		const diff = moment(this.state.end).diff(moment(this.state.start), "d");
		const range = moment.range(moment(this.state.start), moment(this.state.end));

		const rangeBy = (() => {
			if (diff > 31 * 12) {
				return "year";
			}

			if (diff > 31 * 7) {
				return "month";
			}

			if (diff > 31) {
				return "week";
			}

			return "day";
		})();

		const { format, labelFormat, dateLabel } = (() => {
			switch (rangeBy) {
				case "year":
					return { format: "YYYY", labelFormat: "YYYY", dateLabel: "År" };
				case "month":
					return { format: "YYYY-MM", labelFormat: "MMM", dateLabel: "Månad" };
				case "week":
					return { format: "week", labelFormat: "week", dateLabel: "Vecka" };
				case "day":
				default:
					return { format: "YYYY-MM-DD", labelFormat: "M/D", dateLabel: "Datum" };
			}
		})();

		const labels = [
			...new Set(
				Array.from(range.by(rangeBy)).map((day) => ({
					id: format === "week" ? day.isoWeek() : String(day.format(format)),
					label: format === "week" ? day.locale("sv").isoWeek() : day.locale("sv").format(labelFormat).capitalize(),
				}))
			),
		];

		// this.setState({ labels });

		API.get("/api/projects/time_stats.json", {
			params,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const projectColors = result.data.timereports.reduce((acc, stat) => {
					if (!acc[stat.project_id]) {
						acc[stat.project_id] = Colors.random();
					}
					return acc;
				}, {});

				const stats = Object.assign(result.data, {
					timereports: result.data.timereports.map((v) => {
						return Object.assign(v, { color: projectColors[v.project_id] });
					}),
				});

				const series = stats.timereports
					? Object.values(
							stats.timereports.reduce((acc, { total_minutes: totalMinutes, date, project_id: projectId, title, color }) => {
								const index = labels.findIndex(
									({ id }) => String(id) === (format === "week" ? String(moment(date).isoWeek()) : String(moment(date).format(format)))
								);
								if (index < 0) return acc;

								const key = `${date}-${projectId}`;
								const data = (acc[key] && acc[key].date) || Array.from(Array(labels.length)).map(() => null);
								data[index] = ((totalMinutes * 1) / 60).toFixed() * 1;

								const dateX = format === "week" ? String(moment(date).isoWeek()) : String(moment(date).format(format));
								return Object.assign(acc, {
									[key]: {
										name: title,
										data,
										date: `${dateX} ${dateX != date ? `(${date})` : ""}`,
										dateLabel,
										color,
										project_id: projectId,
										dataLabels: {
											formatter() {
												if (this.y < 3) return null;
												return this.y;
											},
											color: Colors["color-primary"],
										},
									},
								});
							}, {})
					  )
					: [];

				const options = {
					chart: {
						type: "column",
						// height: Math.max(250, (window.innerHeight - 400) / 2),
					},
					title: {
						text: null,
						// align: "left",
					},
					xAxis: {
						categories: labels.map(({ label }) => label),
						lineColor: "transparent",
					},
					yAxis: {
						min: 0,
						title: {
							text: "Timmar",
						},
						stackLabels: {
							enabled: true,
							style: {
								fontWeight: "bold",
								color: Colors.textColor,
								textOutline: "none",
							},
						},
					},
					legend: {
						enabled: false,
						align: "left",
						x: 70,
						verticalAlign: "top",
						y: 70,
						floating: false,
						// backgroundColor: "blue",
						borderColor: "#CCC",
						borderWidth: 0,
						shadow: false,
					},
					tooltip: {
						// headerFormat: "<b>{point.x}</b><br/>",
						headerFormat: "<b>{point.series.options.date}</b><br/>",
						pointFormat: "{series.name}: {point.y} tim<br/>",
						// pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
						formatter() {
							return `
					<b>${this.series.name}</b>
					<br/>Timmar: <b>${this.y} tim</b>
					<br/>Procent: <b>${((this.y / this.total) * 100).toFixed()}</b>
					<br/>${dateLabel}: ${this.series.options.date}
					`;
						},
					},
					plotOptions: {
						column: {
							stacking: "normal",
							dataLabels: {
								enabled: true,
							},
						},
						series: {
							// cursor: "pointer",
							events: {
								click: (event) => {
									// console.debug("event:", event);
								},
							},
							borderRadius: 5,
						},
					},
					series: series.length
						? series
						: [
								{
									name: "Ingen data",
									data: [0],
								},
						  ],
				};

				const total =
					stats &&
					stats.timereports &&
					stats.timereports.reduce((acc, timereport) => {
						return acc + parseInt(timereport.total_minutes / 60);
					}, 0);

				const minutesGroupByProject =
					stats.timereports &&
					stats.timereports.reduce((acc, stat) => {
						// const project = {
						// 	name: stat.title,
						// 	total_minutes: ((acc[stat.project_id] && acc[stat.project_id].total_minutes) || 0) + stat.total_minutes * 1,
						// 	color: stat.color,
						// 	project_id: stat.project_id,
						// };
						return Object.assign(acc, {
							[stat.project_id]: {
								name: stat.title,
								total_minutes: ((acc[stat.project_id] && acc[stat.project_id].total_minutes) || 0) + stat.total_minutes * 1,
								color: stat.color,
								project_id: stat.project_id,
								// sliced: true,
							},
						});
					}, {});

				const size = 250;
				const pieOptions = {
					chart: {
						type: "pie",
						width: size * 1.1,
						height: size * 1.1,

						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
					},
					title: {
						text: null,
						align: "left",
					},
					plotOptions: {
						pie: {
							shadow: false,
							center: ["50%", "50%"],
							size,
							width: size,
							height: size,

							borderWidth: 6,
							borderColor: null,
							slicedOffset: 0,

							dataLabels: {
								connectorWidth: 1,
							},
						},

						series: {
							cursor: "pointer",
							events: {
								click: (event) => {
									this.props.history.push(`/admin/projects/${event.point.project_id}#timereports`);
								},
							},
						},
					},
					tooltip: {
						valueSuffix: "%",
						formatter() {
							return `
					<b>${this.point.name}</b>
					<br/>Timmar: <b>${this.y} tim</b>
					<br/>Procent: <b>${this.point.percentage.toFixed()}%</b>`;
						},
					},
					series: [
						{
							name: "Projects",
							innerSize: "50%",
							ignoreHiddenPoint: false,
							data:
								minutesGroupByProject &&
								Object.values(minutesGroupByProject)
									.map((stat, index, arr) => {
										return [
											{
												y: ((stat.total_minutes * 1) / 60).toFixed() * 1,
												name: stat.name,
												color: stat.color,
												project_id: stat.project_id,
												sliced: true,
											},
											{ y: total / arr.length / 10, visible: false },
										];
									})
									.flat(),

							dataLabels: {
								formatter() {
									if (this.percentage < 10) return null;
									return this.percentage.toFixed() + "%";
								},
								color: Colors["color-primary"],
								connectorWidth: 1,
								distance: -30,
							},
						} || {},
					],
				};

				this.setState({
					options,
					pieOptions,
					total,
					prevStart: params.from,
					prevEnd: params.to,
					stats,
				});
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	render() {
		return (
			<CardsInPageWrapper className="project_dashboard">
				<div className="datefields" style={{ display: "flex", gap: "1rem" }}>
					<Popover
						preventAutofocus
						autofocusTarget="none"
						active={this.state.dateModalIsOpen}
						activator={
							<div
								onClick={() => {
									this.setState({ dateModalIsOpen: true });
								}}
							>
								<TextField
									prefix={<Icon source={CalendarMajor} />}
									style={{ width: 200 }}
									type="date"
									value={this.state.start}
									onChange={(start) => {
										this.setState({ start });
									}}
									max="9999-12-31"
									// onFocus={(e) => {
									// 	console.debug("e:", e);
									// 	setTimeout(() => {
									// 		e.target.focus();
									// 	}, 1000);
									// }}
								/>
							</div>
						}
						onClose={() => {
							this.setState({ dateModalIsOpen: false });
							if (
								(this.state.prevStart !== this.state.start || this.state.prevEnd !== this.state.end) &&
								moment(this.state.start).isValid() &&
								moment(this.state.end).isValid()
							) {
								this.onFetch();
							} else {
								this.setState({ end: this.state.prevEnd, start: this.state.prevStart });
							}
						}}
					>
						<Popover.Section>
							<DatePicker
								month={this.state.startMonth}
								year={this.state.startYear}
								onMonthChange={(startMonth, startYear) => {
									this.setState({ startMonth, startYear });
								}}
								weekStartsOn={1}
								onChange={({ start }) => {
									this.setState({ start: moment(start).format("YYYY-MM-DD") }, () => {
										if (!moment(start).isSame(moment(this.state.start))) this.onFetch();
									});
								}}
								// onMonthChange={(month, year) => {
								// 	this.setState({ month, year });
								// }}
								selected={new Date(this.state.start)}
								// multiMonth={true}
								// disableDatesBefore={new Date(this.state.start)}
							/>
						</Popover.Section>
					</Popover>
					<Popover
						preventAutofocus
						autofocusTarget="none"
						active={this.state.dateModalIsOpenEnd}
						activator={
							<div
								onClick={() => {
									this.setState({ dateModalIsOpenEnd: true });
								}}
							>
								<TextField
									prefix={<Icon source={CalendarMajor} />}
									style={{ width: 200 }}
									type="date"
									value={this.state.end}
									onChange={(end) => {
										this.setState({ end });
									}}
									max="9999-12-31"
									// onFocus={(e) => {
									// 	setTimeout(() => {
									// 		e.target.focus();
									// 	}, 1000);
									// }}
								/>
							</div>
						}
						onClose={() => {
							this.setState({ dateModalIsOpenEnd: false });
							if (
								(this.state.prevStart !== this.state.start || this.state.prevEnd !== this.state.end) &&
								moment(this.state.start).isValid() &&
								moment(this.state.end).isValid()
							) {
								this.onFetch();
							} else {
								this.setState({ end: this.state.prevEnd, start: this.state.prevStart });
							}
						}}
					>
						<Popover.Section>
							<DatePicker
								// allowRange
								month={this.state.endMonth}
								year={this.state.endYear}
								onMonthChange={(endMonth, endYear) => {
									this.setState({ endYear, endMonth });
								}}
								weekStartsOn={1}
								onChange={({ end }) => {
									this.setState({ end: moment(end).format("YYYY-MM-DD") }, () => {
										if (!moment(end).isSame(moment(this.state.end))) this.onFetch();
									});
								}}
								// onMonthChange={(month, year) => {
								// 	this.setState({ month, year });
								// }}
								selected={new Date(this.state.end)}
								// multiMonth={true}
								// disableDatesBefore={new Date(this.state.start)}
							/>
						</Popover.Section>
					</Popover>
				</div>

				<div className="graph">
					<Card sectioned>
						<BarChart options={this.state.options} />
						{/* <HighchartsReact highcharts={Highcharts} options={this.state.options} /> */}
					</Card>
				</div>
				<div className="pie">
					<Card sectioned>
						<div className="pie_inner">
							<div style={{ position: "relative" }}>
								<p style={{ fontWeight: 500, opacity: 0.5 }}>Totalt</p>
								<b style={{ fontSize: 30 }}>{this.state.total} tim</b>
							</div>

							<HighchartsReact highcharts={Highcharts} options={this.state.pieOptions} />
							{/* the snippet below is just used to center the chart above so its equal spacing on the left/right based on the text length */}
							<div style={{ visibility: "hidden" }}>
								<p>Totalt</p>
								<b style={{ fontSize: 30 }}>{this.state.total} tim</b>
							</div>
						</div>
					</Card>
				</div>

				<ProjectDashboardRankList stats={this.state.stats} history={this.props.history} />
			</CardsInPageWrapper>
		);
	}
}
export default ProjectDashboard;

import { ActionList, Popover, Spinner, TextField } from "@shopify/polaris";
import { debounce } from "lodash";
import React, { Component } from "react";
import axios from "axios";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ArticleSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.handleSearch = debounce(this.search.bind(this), 1000, { maxWait: 3000, leading: false, trailing: true });
		this.actionListRef = React.createRef();
		this.ref = React.createRef();
	}

	onClickOutside(event) {
		if (this.actionListRef.current) {
			if (!this.actionListRef.current.contains(event.target)) {
				this.onClose();
			}
		}
	}

	componentDidMount() {
		document.addEventListener("mouseup", this.onClickOutside.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener("mouseup", this.onClickOutside.bind(this));
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	search(q = "") {
		this.cancelRequest();
		const CancelToken = axios.CancelToken;
		const params = { q };

		if (this.props.fortnox) {
			params.type = this.props.description ? "description" : "article";
		}

		this.setState({ loading: true });
		API.get("/api/expenses.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					return;
				}
				this.setState({ expenses: result.data.expenses, active: true, loading: false }, () => {
					document.querySelector("body").setAttribute("scrollLocked", true);
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	onFocus() {
		this.handleSearch.cancel();
		this.search(this.props.value);
	}

	onBlur() {
		this.cancelRequest();
		this.handleSearch.cancel();
	}

	onClose() {
		this.setState({ active: false }, () => {
			document.querySelector("body").setAttribute("scrollLocked", false);
		});
		this.cancelRequest();
	}

	onChange(v) {
		this.props.onChange(v);
		this.handleSearch(v);
	}

	onSelect(i) {
		this.props.onChange(i.title);
		this.props.onSelect(i, this.ref && this.ref.current);
		this.onClose();
	}

	onKeyDown(e) {
		if (e.key === "Enter") {
			e.target.click();
			e.preventDefault();
			e.stopPropagation();
		}

		const textField = this.ref.current.querySelector("input");
		if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			if (this.actionListRef && this.actionListRef.current) {
				if (e.target === textField && e.key === "ArrowDown") {
					const firstItem = this.actionListRef.current.querySelector("li");
					if (firstItem) {
						const button = firstItem.querySelector("button");
						if (button) button.focus();
					}
					return;
				}

				const sibling = e.key === "ArrowUp" ? "previousSibling" : "nextSibling";

				const li = e.target.closest("li");
				const nextSibling = li && li[sibling];
				if (nextSibling) {
					const button = nextSibling.querySelector("button");
					if (button) button.focus();
				} else if (e.key === "ArrowUp" && textField) {
					textField.focus();
				}
			}
		}
	}

	render() {
		return (
			<div ref={this.ref} style={{ position: "relative" }} onKeyDown={this.onKeyDown.bind(this)}>
				<Popover
					activator={
						<TextField
							labelHidden={this.props.labelHidden}
							label={this.props.label || "Artikel"}
							placeholder={this.props.placeholder || "Artikel"}
							onFocus={this.onFocus.bind(this)}
							onBlur={this.onBlur.bind(this)}
							value={this.props.value}
							onChange={this.onChange.bind(this)}
							autoComplete={false}
							suffix={this.state.loading && <Spinner size="small" />}
							disabled={this.props.disabled}
						/>
					}
					active={this.state.active}
					onClose={() => this.setState({ active: false })}
					preventAutofocus
					autofocusTarget="none"
				>
					<div ref={this.actionListRef}>
						{/* <SearchItemsWrapper ref={this.actionListRef} className="action_list Polaris-ActionList"> */}
						{/* <ul className="Polaris-ActionList__Actions">
							{this.state.expenses.map((i) => {
								return (
									<li key={}>
										<button className="Polaris-ActionList__Item" onClick={this.onSelect.bind(this, i)}>
											<div className="Polaris-ActionList__Content">
												{i.sku} - {i.title}
											</div>
										</button>
									</li>
								);
							})}
						</ul> */}
						<ActionList
							items={
								this.state.expenses && this.state.expenses.length
									? this.state.expenses.map((i) => ({
											content: `${i.sku} - ${i.title}`,
											onAction: this.onSelect.bind(this, i),
									  }))
									: [{ content: "Inga resultat.." }]
							}
						/>
						{/* </SearchItemsWrapper> */}
					</div>
				</Popover>
			</div>
		);
	}
}
export default ArticleSelector;

import React, { Component } from "react";
import {
	Page,
	Tooltip,
	PageActions,
	Icon,
	RadioButton,
	Layout,
	Card,
	FormLayout,
	TextField,
	Select,
	Checkbox,
	Tabs,
	Modal,
	Heading,
	InlineError,
	Text,
	HorizontalStack,
} from "@shopify/polaris";
import { QuestionMarkMajor } from "@shopify/polaris-icons";

import MapIcon from "src/js/icons/MapIcon.js";
import StyledButton from "src/js/styledComponents/StyledButton";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import SkeletonProvider from "../../components/skeleton_provider.js";
import SearchField from "../../components/search_field.js";
import Map from "../../components/map.js";
import IndexTable from "../../components/IndexTable";
import ContactCreate from "../Contacts/create";
import { toastr } from "../../components/toastr";
import OfferIndexShared from "../OfferIndexShared";
import ProjectIndex from "../Projects";
import API from "../../API";

class CustomerEdit extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign(
			{
				showRemoveModal: false,
				show_map: false,
				selectedTabId: "allmant",
				id: props.match.params.id,
				saving: false,
				loading: true,
				offset: 0,
				limit: 10,
				items: [],
				selectedItems: [],
				params: {},
				tags: [],
				categories: [],
				visibleItems: [],
				form: {},
			},
			props.location.state
		);
		this.ContactCreateRef = React.createRef();
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/customers/" + id + ".json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false, saving: false });
				}
				this.setState({ loading: false, saving: false, form: result.data.customer || {} });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/customers/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Kund ändrad");
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form });
	}

	selectInvoiceAddress(address) {
		this.state.form.invoice_address = address.formatted_address;
		this.state.form.invoice_latitude = address.geometry.location.lat;
		this.state.form.invoice_longitude = address.geometry.location.lng;
		this.setState({
			form: this.state.form,
			// eslint-disable-next-line react/no-unused-state
			invoice_latitude: this.state.form.invoice_latitude,
			// eslint-disable-next-line react/no-unused-state
			invoice_longitude: this.state.form.invoice_longitude,
		});
	}

	selectDeliveryAddress(address) {
		this.state.form.delivery_address = address.formatted_address;
		this.state.form.delivery_latitude = address.geometry.location.lat;
		this.state.form.delivery_longitude = address.geometry.location.lng;
		this.setState({
			form: this.state.form,
			// eslint-disable-next-line react/no-unused-state
			delivery_latitude: this.state.form.delivery_latitude,
			// eslint-disable-next-line react/no-unused-state
			delivery_longitude: this.state.form.delivery_longitude,
		});
	}

	gotoContact(item) {
		var url = "/admin/contacts/" + item.id;
		this.props.history.push(url);
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	bulkRemove() {
		this.setState({ loadingRemoving: true });

		API.put(`/api/customer/${this.state.id}/contacts/bulk_remove.json`, { ids: this.state.selectedItems })
			.then((result) => {
				if (result.data.error) {
					this.setState({ loadingRemoving: false });
					return;
				}
				this.setState({ loadingRemoving: false });
				if (this.refresh) this.refresh();
			})
			.catch((error) => {
				console.error("error:", error);
				// toastr.error(error);
				this.setState({ loadingRemoving: false });
			});
	}

	renderCell(item, column) {
		if (column.handle == "NAME") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name}</h3>
					</div>
				</div>
			);
		}

		if (column.handle == "PHONE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.phone}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "EMAIL") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.email}</h3>
					</div>
				</div>
			);
		}

		return column.handle;
	}

	remove() {
		this.setState({ removing: true });
		API.delete(`/api/customers/${this.state.form.id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ removing: false, removeError: result.data.error });
					return;
				}

				toastr.success("Kund borttagen");
				if (result.data && !this.props.asModal) {
					this.props.history.replace("/admin/customers");
				}
				this.setState({ removing: false });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	openModal(modal) {
		this.setState({ [modal]: true });
	}

	closeModal(modal) {
		this.setState({ [modal]: false });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		const tabs = [
			{
				id: "allmant",
				content: "Allmänt",
				accessibilityLabel: "allmant",
				panelID: "allmant",
			},
			{
				id: "contacts",
				content: "Kontakter",
				panelID: "contacts",
			},
			{
				id: "projects",
				content: "Projekt",
				panelID: "projects",
			},
			{
				id: "offerts",
				content: "Offerter",
				panelID: "offerts",
			},
		];

		const filters = [
			{
				key: "active",
				label: "Aktiv",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				handle: "NAME",
				label: "Namn",
				sortable: true,
				type: "text",
			},
			{
				handle: "PHONE",
				label: "Telefon",
				sortable: true,
				type: "text",
			},
			{
				handle: "EMAIL",
				label: "Email",
				sortable: true,
				type: "text",
			},
		];

		var promotedBulkActions = [
			{
				content: "Ta bort",
				onAction: this.bulkRemove.bind(this),
				loading: this.state.loadingRemoving,
				disabled: this.state.loadingRemoving,
			},
		];

		return (
			<Page
				fullWidth
				title={this.state.form.name}
				backAction={{ content: "Kunder", url: "/admin/customers" }}
				primaryAction={this.state.form && this.state.form.id && { content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={[
					{
						content: "Skapa ny offert",
						onAction: () => {
							this.props.history.replace({ pathname: "/admin/quotes/new", state: { customer: this.state.form } });
						},
					},
				]}
			>
				{this.state.form && this.state.form.id && (
					<>
						<Layout>
							<Layout.Section>
								<Tabs
									tabs={tabs}
									selected={tabs.findIndex((tab) => this.state.selectedTabId == tab?.id)}
									onSelect={(v) => {
										const tab = tabs[v];
										this.setState({ selectedTabId: tab?.id });
									}}
								/>

								{this.state.selectedTabId == "allmant" && (
									<CardsInPageWrapper>
										<Layout>
											<Layout.Section oneThird>
												<Text as="h3" variant="headingMd" fontWeight="bold">
													Företagsinformation
												</Text>
												<Card sectioned>
													<FormLayout>
														<HorizontalStack gap="5">
															<RadioButton
																label="Företag"
																checked={this.state.form.type === "company"}
																id="company"
																name="type"
																disabled
																onChange={(v) => {
																	this.state.form.type = "company";
																	this.state.form.show_vat = false;
																	this.state.form.payment_terms = "30";
																	this.setState({ form: this.state.form });
																}}
															/>
															<RadioButton
																label="Privatperson"
																id="optional"
																name="type"
																disabled
																checked={this.state.form.type === "private_person"}
																onChange={(v) => {
																	this.state.form.type = "private_person";
																	this.state.form.show_vat = true;
																	this.state.form.payment_terms = "10";
																	this.setState({ form: this.state.form });
																}}
															/>
														</HorizontalStack>

														<Checkbox
															label="Kunden är inaktiv"
															checked={this.state.form.disabled}
															onChange={this.updateForm.bind(this, "disabled")}
														/>

														<TextField label="Namn" value={this.state.form.name} onChange={this.updateForm.bind(this, "name")} />
														<TextField label="Kundnummer" value={this.state.form.customer_id} placeholder="Genereras automatiskt" disabled />

														{this.state.form.type == "company" ? (
															[
																<TextField
																	key="org_number"
																	label="Org.nr"
																	value={this.state.form.org_number}
																	onChange={this.updateForm.bind(this, "org_number")}
																/>,
																<TextField
																	key="vat_number"
																	label="Momsregistreringsnummer"
																	value={this.state.form.vat_number}
																	onChange={this.updateForm.bind(this, "vat_number")}
																/>,
															]
														) : (
															<TextField
																label="Personnummer"
																value={this.state.form.org_number}
																onChange={this.updateForm.bind(this, "org_number")}
															/>
														)}
														<TextField label="Telefon" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} />
														<TextField label="E-post" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} />

														<TextField
															label="Information"
															value={this.state.form.information}
															multiline={4}
															onChange={this.updateForm.bind(this, "information")}
														/>
													</FormLayout>
												</Card>
											</Layout.Section>

											<Layout.Section oneThird>
												<Text as="h3" variant="headingMd" fontWeight="bold">
													Adresser
												</Text>

												<Card sectioned>
													<FormLayout>
														<SearchField
															label="Besöksadress"
															value={this.state.form.address}
															resource="google_address"
															resourceName={{
																singular: "adress",
																plural: "adresser",
															}}
															onSelect={this.selectAddress.bind(this)}
															suffix={
																this.state.form.address && (
																	<Tooltip content="Visa karta">
																		<StyledButton
																			plain
																			onClick={() => {
																				this.setState({ show_address_map: !this.state.show_address_map });
																			}}
																		>
																			<MapIcon />
																		</StyledButton>
																	</Tooltip>
																)
															}
														/>

														{this.state.form.address && this.state.show_address_map && (
															<div style={{ marginTop: 20 }}>
																<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} zoom={12} height={500} />
															</div>
														)}

														<SearchField
															label="Faktureringsadress"
															value={this.state.form.invoice_address}
															resource="google_address"
															resourceName={{
																singular: "adress",
																plural: "adresser",
															}}
															onSelect={this.selectInvoiceAddress.bind(this)}
															suffix={
																this.state.form.invoice_address && (
																	<Tooltip content="Visa karta">
																		<StyledButton
																			plain
																			onClick={() => {
																				this.setState({ show_invoice_address_map: !this.state.show_invoice_address_map });
																			}}
																		>
																			<MapIcon />
																		</StyledButton>
																	</Tooltip>
																)
															}
														/>

														{this.state.form.invoice_address && this.state.show_invoice_address_map && (
															<div style={{ marginTop: 20 }}>
																<Map
																	latitude={this.state.form.invoice_latitude}
																	longitude={this.state.form.invoice_longitude}
																	zoom={12}
																	height={500}
																/>
															</div>
														)}

														<SearchField
															label="Leveransadress"
															value={this.state.form.delivery_address}
															resource="google_address"
															resourceName={{
																singular: "adress",
																plural: "adresser",
															}}
															onSelect={this.selectDeliveryAddress.bind(this)}
															suffix={
																this.state.form.delivery_address && (
																	<Tooltip content="Visa karta">
																		<StyledButton
																			plain
																			onClick={() => {
																				this.setState({ show_delivery_address_map: !this.state.show_delivery_address_map });
																			}}
																		>
																			<MapIcon />
																		</StyledButton>
																	</Tooltip>
																)
															}
														/>

														{this.state.form.delivery_address && this.state.show_delivery_address_map && (
															<div style={{ marginTop: 20 }}>
																<Map
																	latitude={this.state.form.delivery_latitude}
																	longitude={this.state.form.delivery_longitude}
																	zoom={12}
																	height={500}
																/>
															</div>
														)}
													</FormLayout>
												</Card>
											</Layout.Section>
											<Layout.Section oneThird>
												<Text as="h3" variant="headingMd" fontWeight="bold">
													Faktureringsinställningar
												</Text>

												<Card sectioned>
													<FormLayout>
														<TextField
															type="number"
															label="Tim pris"
															value={this.state.form.hourly_rate + ""}
															onChange={this.updateForm.bind(this, "hourly_rate")}
														/>
														<TextField
															suffix={
																<Tooltip content="Ange det standardpåslag som önskas på inköp av varor / leverantörsfakturor">
																	<Icon source={QuestionMarkMajor} />
																</Tooltip>
															}
															type="number"
															label="Påslag inköp (%)"
															value={this.state.form.purcharge_price_surcharge + ""}
															onChange={this.updateForm.bind(this, "purcharge_price_surcharge")}
														/>
														<TextField
															suffix="dagar"
															type="number"
															label="Betalningsvillkor"
															value={this.state.form.payment_terms}
															onChange={this.updateForm.bind(this, "payment_terms")}
														/>

														<Select
															label="Faktureringsmetod"
															options={[
																{ label: "E-post", value: "epost" },
																{ label: "Post", value: "post" },
															]}
															onChange={this.updateForm.bind(this, "invoice_method")}
															value={this.state.form.invoice_method}
														/>
														<TextField
															label="E-post för fakturor"
															value={this.state.form.invoice_email}
															onChange={this.updateForm.bind(this, "invoice_email")}
														/>
														<TextField
															label="E-post för påminnelser"
															value={this.state.form.reminder_email}
															onChange={this.updateForm.bind(this, "reminder_email")}
														/>

														{this.state.form.type == "company" && (
															<Checkbox
																label="Omvänd skattskyldighet"
																checked={this.state.form.reverse_tax_liability}
																onChange={this.updateForm.bind(this, "reverse_tax_liability")}
															/>
														)}
													</FormLayout>
												</Card>
											</Layout.Section>
										</Layout>
									</CardsInPageWrapper>
								)}

								{this.state.selectedTabId === "contacts" && (
									<Card sectioned>
										<IndexTable
											setRefreshHandler={(refreshHandler) => {
												this.refresh = refreshHandler;
											}}
											history={this.props.history}
											title="Kontakter"
											primaryAction={{
												content: "Ny kontakt",
												onAction: () => {
													if (this.ContactCreateRef.current) this.ContactCreateRef.current.onToggle();
												},
											}}
											savedSearchHandle="contacts"
											resourceUrl={`/api/contacts.json?parent_id=${this.state.id}`}
											resourceHandle="contacts"
											resourceName={{
												singular: "kontakt",
												plural: "kontakter",
											}}
											onSelectionChange={this.onSelectionChange.bind(this)}
											selectedItems={this.state.selectedItems}
											renderCell={this.renderCell.bind(this)}
											filters={filters}
											columns={columns}
											defaultSort="LAST_ACTIVITY_DESC"
											promotedBulkActions={promotedBulkActions}
											onClickRow={this.gotoContact.bind(this)}
										/>
									</Card>
								)}

								{this.state.selectedTabId === "projects" && (
									<Card sectioned>
										<ProjectIndex customer={this.state.form} history={this.props.history} />
									</Card>
								)}

								{this.state.selectedTabId === "offerts" && <OfferIndexShared customer={this.state.form} history={this.props.history} />}
							</Layout.Section>
						</Layout>
						<ContactCreate parent={this.state.form} ref={this.ContactCreateRef} asModal onCreate={() => this.refresh()} />
						<PageActions
							primaryAction={{
								content: "Spara",
								loading: this.state.saving,
								onAction: this.saveForm.bind(this),
							}}
							secondaryActions={[
								{
									content: "Ta bort",
									loading: this.removing,
									onAction: this.openModal.bind(this, "removeModal"),
									destructive: true,
								},
							]}
						/>
						<Modal
							title={`Ta bort "${this.state.form.name}"`}
							open={this.state.removeModal}
							onClose={this.closeModal.bind(this, "removeModal")}
							primaryAction={{
								content: "Ta bort",
								destructive: true,
								onAction: this.remove.bind(this),
								loading: this.state.removing,
							}}
							secondaryActions={[
								{
									content: "Avbryt",
									onAction: this.closeModal.bind(this, "removeModal"),
								},
							]}
						>
							<Modal.Section>
								<Heading element="h2">Är du säker du vill ta bort kund &quot;{this.state.form.name}&quot;</Heading>
								{this.state.removeError && <InlineError message={this.state.removeError} />}
							</Modal.Section>
						</Modal>
					</>
				)}
			</Page>
		);
	}
}

export default CustomerEdit;

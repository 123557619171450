import { useContext } from "react";
import TimeReportContext from "../views/TimeReports/TimeReportContext";

const useTimeReport = (options?: TimeReportModalProps | null) => {
	const { isOpen, openTimeReportModal, closeTimeReportModal, handleSetOptions } = useContext(TimeReportContext);

	const handleOpenWithOptions = (opt: TimeReportModalProps) => {
		handleSetOptions({ ...(opt || {}), ...(options || {}) });
		openTimeReportModal();
	};

	const handleOpen = () => {
		if (options) handleSetOptions(options);
		openTimeReportModal();
	};

	return {
		isOpen,
		handleOpen,
		handleOpenWithOptions,
		handleClose: closeTimeReportModal,
		handleSetOptions,
	};
};
export default useTimeReport;

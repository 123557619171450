import React from "react";
import { store } from "../store";
import FortnoxOffersIndex from "./Fortnox/Offers/List/FortnoxOffersIndex";
import QuoteIndex from "./Quotes";

const OfferIndexShared = ({ ...props }) => {
	const fortnoxConnection = store.getState().account.fortnox_connection;
	const fortnoxOrderModule = fortnoxConnection && store.getState().account.fortnox_order_module;

	if (fortnoxConnection && fortnoxOrderModule) {
		return <FortnoxOffersIndex {...props} />;
	}

	return <QuoteIndex {...props} />;
};
export default OfferIndexShared;

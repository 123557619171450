import { Component } from "react";
import IndexTable from "../../components/IndexTable";

class ContactIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	goToContact(item) {
		var url = "/admin/contacts/" + item.id;
		this.props.history.push(url);
	}

	renderCell(item, column) {
		if (column.handle == "NAME") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "CUSTOMER") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.parent && item.parent.name}</h3>
					</div>
				</div>
			);
		}

		if (column.handle == "PHONE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.phone}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "EMAIL") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.email}</h3>
					</div>
				</div>
			);
		}

		return column.handle;
	}

	render() {
		// const sortOptions = [
		// 	{ label: "Namn (A-Ö)", value: "TITLE_ASC" },
		// 	{ label: "Namn (Ö-A)", value: "TITLE_DESC" },
		// 	{ label: "Skapad (nyast till äldst)", value: "ID_DESC" },
		// 	{ label: "Skapad (äldst till nyast)", value: "ID_ASC" },
		// ];

		const filters = [
			{
				key: "active",
				label: "Aktiv",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				handle: "NAME",
				label: "Namn",
				sortable: true,
				type: "text",
			},
			{
				handle: "CUSTOMER",
				label: "Kund",
				sortable: true,
				type: "text",
			},
			{
				handle: "EMAIL",
				label: "Email",
				sortable: true,
				type: "text",
			},
			{
				handle: "PHONE",
				label: "Telefon",
				sortable: true,
				type: "text",
			},
		];

		return (
			<IndexTable
				setRefreshHandler={(refreshHandler) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					this.refresh = refreshHandler;
				}}
				history={this.props.history}
				title="Kontakter"
				primaryAction={{
					content: "Ny kontakt",
					onAction: () => {
						this.props.history.push("/admin/contacts/new");
					},
				}}
				savedSearchHandle="contacts"
				resourceUrl="/api/contacts.json"
				resourceHandle="contacts"
				resourceName={{
					singular: "kontakt",
					plural: "kontakter",
				}}
				renderCell={this.renderCell.bind(this)}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				onClickRow={this.goToContact.bind(this)}
				filters={filters}
			/>
		);
	}
}
export default ContactIndex;

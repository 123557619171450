import { Component } from "react";
import { Page, Thumbnail, Layout, Card, Button, FormLayout, TextField, Select, ResourceList } from "@shopify/polaris";
import moment from "moment";

import SkeletonProvider from "../../components/skeleton_provider.js";
import TimePicker from "../../components/time_picker.js";

import TimeLine from "../../components/TimeLine.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class IncidentEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hours: 0,
			id: props.match.params.id,
			saving: false,
			loading: true,
			form: {},
		};
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/incident/" + id + ".json")
			.then((result) => {
				var minutes = result.data.incident.minutes;
				if (minutes) {
					if (minutes >= 60) {
						if (minutes % 60 == 0) {
							this.state.hours = minutes / 60;
						} else {
							var mins = minutes % 60;
							var hours = (minutes - mins) / 60;
							this.state.hours = hours + "." + mins;
						}
					} else {
						this.state.hours = "0." + minutes;
					}
				}

				this.setState({
					loading: false,
					saving: false,
					form: result.data.incident,
					hours: this.state.hours,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/incidents.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				this.setState({ form: result.data.incident });
				toastr.success("Tillbudet uppdaterat");
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	clickPhoto(item) {
		window.open(item);
	}

	downloadImages() {
		window.open("/api/incidents/pictures/" + this.state.id + ".zip");
	}

	render() {
		if (this.state.loading || !this.state.form) {
			return <SkeletonProvider />;
		}

		var actions = [];

		// actions.push({content: <Stack spacing="extraTight"><Icon source="import" /><div>Ladda upp KML</div></Stack>, onAction: () => {this.setState({openKmlUpload:true})} });

		const backAction = (() => {
			if (this.props.location && this.props.location.state && this.props.location.state.workOrder) {
				return {
					content: `Arbetsorder (${this.props.location.state.workOrder.label})`,
					url: `/admin/work_orders/${this.props.location.state.workOrder.id}`,
				};
			}

			return { content: "Tillbud", url: "/admin/incident_reporting" };
		})();

		return (
			<Page
				title={this.state.form && this.state.form.id ? "Tillbud #" + this.state.form.id : null}
				backAction={backAction}
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={actions}
			>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<FormLayout>
								<TextField disabled label="Arbetsorder" value={this.state.form.work_order ? this.state.form.work_order.full_label : ""} />
								<TextField disabled label="Plats" value={this.state.form.location ? this.state.form.location : null} />
								<TimePicker label="Timmar" value={this.state.hours + ""} disabled hours />
								<TextField disabled label="Arbetsmoment" value={this.state.form.task ? this.state.form.task : null} />
								<TextField
									disabled
									label="Anställd"
									value={
										this.state.form && this.state.form.user ? this.state.form.user.name + " (" + this.state.form.user.employee_number + ")" : null
									}
								/>
								<TextField
									disabled
									type="datetime-local"
									max="9999-12-31T23:59"
									label="När inträffade händelsen/situationen?"
									value={this.state.form.occured_at.replace(" ", "T")}
									onChange={this.updateForm.bind(this, "occured_at")}
								/>
								<TextField
									disabled
									multiline
									label="Vad hände? Beskriv händelsen eller situationen"
									value={this.state.form.description}
									onChange={this.updateForm.bind(this, "description")}
								/>
								{this.state.form.created_at && this.state.form.created_by && this.state.form.created_by.name ? (
									<small>
										Skapades {moment(this.state.form.created_at).format("YYYY-MM-DD HH:mm")} av{this.state.form.created_by.name}
									</small>
								) : null}
								{this.state.form.updated_at && this.state.form.updated_by && this.state.form.updated_by.name ? (
									<small>
										Uppdaterades senast {moment(this.state.form.updated_at).format("YYYY-MM-DD HH:mm")} av{this.state.form.updated_by.name}
									</small>
								) : null}
							</FormLayout>
						</Card>

						<TimeLine handle={"incident" + this.state.id} tasks={[]} opportunities={[]} object={this.state.form} resource="incident" />
					</Layout.Section>
					<Layout.Section secondary>
						<Card sectioned title="Status">
							<FormLayout>
								<Select
									options={[
										{ label: "Ej hanterad", value: "pending" },
										{ label: "Uppmärksammad", value: "noticed" },
										{ label: "Pågående", value: "ongoing" },
										{ label: "Avslutad", value: "done" },
									]}
									onChange={this.updateForm.bind(this, "status")}
									value={this.state.form.status}
								/>
								{this.state.form.uploads.length ? (
									<Card title="Foto">
										{this.state.form.uploads.map((upload, index) => (
											<ResourceList.Item
												key={upload.id || index}
												media={<Thumbnail source={upload.url} />}
												onClick={this.clickPhoto.bind(this, upload.url)}
											/>
										))}
										<ResourceList.Item>
											<Button onClick={this.downloadImages.bind(this)}> Ladda ner alla bilder </Button>
										</ResourceList.Item>
									</Card>
								) : null}
							</FormLayout>
						</Card>

						<Card sectioned title="Länkar till arbetsmiljöverket">
							<a href="https://anmalarbetsskada.se/P2/allvarligolycka" target="_blank" rel="noreferrer">
								Allvarlig arbetsolycka
							</a>
							<br />
							<a href="https://anmalarbetsskada.se/Laf/arbetsolycka" target="_blank" rel="noreferrer">
								Arbetsolycka
							</a>
							<br />
							<a href="https://anmalarbetsskada.se/p2/tillbud" target="_blank" rel="noreferrer">
								Allvarligt tillbud
							</a>
							<br />
						</Card>
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}

export default IncidentEdit;

/* eslint-disable camelcase */
import Colors from "src/js/Colors";

export const getContractFilters = () => {
	return [
		{
			label: "Aktiv",
			value: "active",
		},
		{
			label: "Inaktiv",
			value: "inactive",
		},
		{
			label: "Avslutad",
			value: "finished",
		},
	];
};

export const getInvoiceFilters = () => {
	return [
		{
			label: "Avbryten",
			value: "cancelled",
		},
		{
			label: "Betald",
			value: "fullypaid",
		},
		{
			label: "Obetald",
			value: "unpaid",
		},
		{
			label: "Obetald försenad",
			value: "unpaidoverdue",
		},
		{
			label: "Ej bokförd",
			value: "unbooked",
		},
	];
};

export const getOrderFilters = () => {
	return [
		{
			label: "Avbryten",
			value: "cancelled",
		},
		{
			label: "Utgången",
			value: "expired",
		},
		{
			label: "Faktura skapad",
			value: "invoicecreated",
		},
		{
			label: "Faktura ej skapad",
			value: "invoicenotcreated",
		},
	];
};
export const getOfferFilters = () => {
	return [
		{
			label: "Avbryten",
			value: "cancelled",
		},
		{
			label: "Utgången",
			value: "expired",
		},
		{
			label: "Färdig",
			value: "completed",
		},
		{
			label: "Ej färdig",
			value: "notcompleted",
		},
		{
			label: "Order skapad",
			value: "ordercreated",
		},
		{
			label: "Order ej skapad",
			value: "ordernotcreated",
		},
	];
};

export const getPDFBlobURL = (base64: any): string | null => {
	if (!base64) return null;
	const binaryBlob = atob(base64);
	const byteArray = new Uint8Array(binaryBlob.length);
	for (let i = 0; i < binaryBlob.length; i++) {
		byteArray[i] = binaryBlob.charCodeAt(i);
	}

	const blob = new Blob([byteArray], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);

	return url;
};
export const downloadPDF = (pdf, filename) => {
	const url = getPDFBlobURL(pdf);
	if (!url) return;

	const a = window.document.createElement("a");
	a.href = url;
	a.download = filename;
	window.document.body.appendChild(a);

	a.click();
	window.document.body.removeChild(a);
	URL.revokeObjectURL(url);
};

export const getFortnoxContractStatus = (contract: FortnoxContractType) => {
	switch (contract?.Status) {
		case "ACTIVE":
			return { label: "Aktiv", color: Colors.green };
		case "INACTIVE":
			return { label: "Inaktiv", color: Colors.grey };
		case "FINISHED":
			return { label: "Avslutad", color: Colors.purple };
		default:
			return {
				label: "",
				color: null,
			};
	}
};

import { ActionList, Icon } from "@shopify/polaris";
import { ChevronDownMinor, DeleteMajor } from "@shopify/polaris-icons";
import React, { useEffect } from "react";
import TextField from "src/js/components/TextField";
import DropDown from "src/js/components/dropdown";
import SearchField from "src/js/components/search_field";
import { FortnoxRowWrapper } from "../styles";

type FortnoxOfferRowProps = {
	row: FortnoxOfferRowType | null;
	offer: FortnoxOfferType;
	index?: number;
	onChange?: (index: number, row: FortnoxOfferRowType | null) => void;
	plain?: boolean;
	disabled?: boolean;
	prefix?: React.ReactNode;
};

const FortnoxOfferRow = ({ offer, row, index, onChange = () => {}, disabled: propsDisabled, plain, prefix }: FortnoxOfferRowProps) => {
	const [form, setForm] = React.useState<FortnoxOfferRowType | null>(row);

	useEffect(() => {
		setForm(row);
	}, [row]);

	const handleChange =
		(field: string, property = "") =>
		(value: any) => {
			const newRow = { ...(form || {}) };

			if (field === "ArticleNumber") {
				const newForm = {
					...newRow,
					ArticleNumber: value.ArticleNumber,
					Description: value.Description,
					Quantity: value.Quantity,
					Unit: value.Unit,
					Price: value.Price || value.SalesPrice,
				} as FortnoxOfferRowType;

				setForm(newForm);
				if (index !== undefined) onChange(index, newForm);
			} else {
				newRow[field] = property ? value[property] : value;
				setForm(newRow as FortnoxOfferRowType);
			}
		};

	const handleOnBlur = () => {
		if (form && index !== undefined) {
			onChange(index, form);
		}
	};

	const sum = form?.sum || parseFloat(String(form?.Quantity || 0)) * parseFloat(String(form?.Price || 0));
	const disabled = (propsDisabled ? true : undefined) as any;
	return (
		<FortnoxRowWrapper onBlur={handleOnBlur} plain={plain}>
			{prefix || (
				<SearchField
					disabled={disabled}
					resource="fortnox/articles.json"
					onSelect={handleChange("ArticleNumber")}
					id_handle="ArticleNumber"
					label_handle="ArticleNumber"
					searchHandle="articlenumber"
					resource_handle="Articles"
					resourceName={{ plural: "artiklar", singular: "artikel" }}
					value={form?.ArticleNumber}
					renderLabel={(item) => `${item.ArticleNumber} - ${item.Description}`}
					suffix={
						!disabled && (
							<span style={{ cursor: "pointer" }}>
								<Icon source={ChevronDownMinor} />
							</span>
						)
					}
				/>
			)}
			<TextField disabled={disabled} onChange={handleChange("Description")} value={String(form?.Description || "")} multiline />
			<TextField
				disabled={disabled}
				value={plain && !form?.Quantity ? "" : String(form?.Quantity || 0)}
				onChange={handleChange("Quantity")}
				suffix={form?.Unit}
			/>
			<SearchField
				disabled={disabled}
				prefix={ChevronDownMinor}
				resource="fortnox/units.json"
				onSelect={handleChange("Unit", "Code")}
				id_handle="Code"
				label_handle="Code"
				resource_handle="Units"
				searchHandle={null}
				resourceName={{ plural: "enheter", singular: "enhet" }}
				value={form?.Unit}
			/>
			<TextField
				disabled={disabled}
				value={plain && !form?.Price ? "" : String(form?.Price || 0)}
				onChange={handleChange("Price")}
				suffix={offer.Currency}
			/>
			<TextField disabled={disabled} value={String(sum || 0)} suffix={offer.Currency} />
			<DropDown
				activatorStyle={
					!row || disabled
						? {
								opacity: 0,
								pointerEvents: "none",
						  }
						: {}
				}
			>
				<ActionList
					items={[
						{
							content: "Ta bort",
							destructive: true,
							icon: DeleteMajor,
							onAction: () => index !== undefined && onChange(index, null),
						},
					]}
				/>
			</DropDown>
		</FortnoxRowWrapper>
	);
};
export default FortnoxOfferRow;

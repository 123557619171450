import { Card } from "@shopify/polaris";
import React, { useContext } from "react";
import Page from "src/js/components/page";
import { DeleteMajor, OrdersMajor, RefreshMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { store } from "src/js/store";
import Banner from "src/js/components/banner";
import API from "src/js/API";
import Colors, { colorToRgba } from "src/js/Colors";
import useQuery from "src/js/hooks/useQuery";
import { StyledFortnoxSettings, StyledFortnoxSettingsInnerCard } from "./styles";
import SettingsItem from "./SettingsItem";
import FortnoxGlobalContext, { DEFAULT_SCOPES, FortnoxGlobalContextProps, ORDER_SCOPES } from "../ContextProvider/FortnoxGlobalContext";

const FortnoxSettings = () => {
	const { openWindow, handleReconnect, handleDiconnect } = useContext<FortnoxGlobalContextProps>(FortnoxGlobalContext);
	// const [syncCustomerData, setSyncCustomerData] = React.useState<any>({
	// 	pages: 0,
	// 	page: 1,
	// });

	const { data: account, refetch: refreshAccount } = useQuery({
		queryKey: [`account_${store.getState().account?.id}`],
		queryFn: async () => {
			const result = await API.get("/api/account.json");
			// if (result.data) store.dispatch({ type: "SET_ACCOUNT", account: result.data });
			return result.data || null;
		},
		enabled: !!store.getState().account?.id,
		initialData: store.getState().account,
		refetchOnWindowFocus: true,
	});

	const onDisconnect = async () => {
		await handleDiconnect();
		refreshAccount();
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getScopesArray = (integration = account?.fortnox_integration) => {
		return integration?.authentication?.scope?.split(" ");
	};

	const getScopes = (type: string | null) => {
		const scopeString = (() => {
			switch (type) {
				case "order":
					return [...DEFAULT_SCOPES, ...ORDER_SCOPES];
				case "invoice":
					return DEFAULT_SCOPES;
				default:
					return account?.fortnox_integration?.authentication?.scopes?.split(" ") || DEFAULT_SCOPES;
			}
		})();

		return scopeString;
	};

	const handleAddOrderModule = () => {
		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: getScopes("order"),
			},
		});
	};

	const handleAddFortnox = () => {
		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: getScopes("invoice"),
			},
		});
	};

	// const handleSyncFortnoxCustomersToContacts = async (page = 1) => {
	// 	const params = { page };

	// 	setSyncCustomerData((c) => ({ ...c, loading: true }));
	// 	// return new Promise<void>((resolve, reject) => {
	// 	// 	setTimeout(() => {
	// 	// 		resolve();
	// 	// 	}, 5000);
	// 	// });

	// 	return API.post("/api/fortnox/customers/sync.json", {}, { params })
	// 		.then(async (result) => {
	// 			setSyncCustomerData((c) => ({ ...c, pages: result.data.pages, page: result.data.page }));

	// 			if (result.data.pages > syncCustomerData.page) {
	// 				return await handleSyncFortnoxCustomersToContacts(syncCustomerData.page + 1);
	// 			} else {
	// 				toastr.success("Kontakter synkade");
	// 				setSyncCustomerData((c) => ({ ...c, loading: false, pages: 0, page: 1 }));
	// 				history.push("/admin/contacts/companies");

	// 				return result.data;
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 			setSyncCustomerData((c) => ({ ...c, loading: false, pages: 0, page: 1 }));
	// 		});
	// };

	const items = [
		{
			title: "Lägg till ordermodul",
			description: "Lägg till ordermodul för hantering av ordrar och offerter",
			icon: OrdersMajor,
			onAction: handleAddOrderModule,
			backgroundColor: colorToRgba(Colors.blue, 0.15),
			enabled: getScopesArray() && !getScopesArray()?.includes("order"),
		},
		{
			title: "Återanslut",
			description: "Återanslut till Fortnox, vi har tappat kontakten med Fortnox.",
			icon: RefreshMajor,
			onAction: handleReconnect,
			backgroundColor: colorToRgba(Colors.red, 0.15),
			enabled: account?.fortnox_integration?.authentication?.removed || !account?.fortnox_integration?.authentication?.access_token,
		},
		// {
		// 	title: "Synka/importera kunder ifrån Fortnox",
		// 	description: "Synka/importera kunder ifrån Fortnox",
		// 	icon: CustomersMajor,
		// 	onAction: handleSyncFortnoxCustomersToContacts,
		// 	enabled: !!account?.fortnox_integration,

		// 	confirmation: {
		// 		title: "Synka kunder",
		// 		content: (
		// 			<>
		// 				<p>{"Synka/importera kunder ifrån Fortnox"}</p>

		// 				{syncCustomerData.loading && (
		// 					<ProgressBar progress={syncCustomerData.pages ? (syncCustomerData.page / syncCustomerData.pages) * 100 : 0} size="medium" />
		// 				)}
		// 			</>
		// 		),
		// 		confirm: "Synka",
		// 		cancel: "Avbryt",
		// 	},
		// },
		{
			title: "Koppla bort Fortnox",
			description: "Koppla bort Fortnox. Du kan ansluta igen när som helst.",
			icon: DeleteMajor,
			onAction: onDisconnect,
			enabled: !!account?.fortnox_integration,
			backgroundColor: colorToRgba(Colors.red, 0.25),
			destructive: true,

			confirmation: {
				destructive: true,
				title: "Koppla bort Fortnox",
				content: <p>{"Säker ni vill koppla bort Fortnox? Du kan ansluta igen när som helst."}</p>,
				confirm: "Koppla bort Fortnox",
				cancel: "Avbryt",
			},
		},
	].filter((item) => !("enabled" in item) || item.enabled);

	const sections = [
		{
			title: "Inställningar",
			items,
		},
		// {
		// 	title: "Vyer",
		// 	items: [
		// 		{
		// 			title: "Fakturor",
		// 			description: "Lista för fakturor",
		// 			icon: ReceiptMajor,
		// 			path: "/admin/fortnox/invoices",
		// 		},
		// 		{
		// 			title: "Ordrar",
		// 			description: "Lista för ordrar",
		// 			icon: OrdersMajor,
		// 			path: "/admin/fortnox/orders",
		// 			enabled: getScopesArray()?.includes("order"),
		// 		},
		// 		{
		// 			title: "Offerter",
		// 			description: "Lista för offerter",
		// 			icon: BlogMajor,
		// 			path: "/admin/fortnox/offers",
		// 			enabled: getScopesArray()?.includes("order"),
		// 		},
		// 		{
		// 			title: "Avtalsfakturering",
		// 			description: "Lista för avtalsfaktureringar",
		// 			icon: LegalMajor,
		// 			path: "/admin/fortnox/contracts",
		// 		},
		// 	].filter((item) => !("enabled" in item) || item.enabled),
		// },
	];

	return (
		<Page title={"Fortnox inställningar och vyer"} fullWidth>
			{!account?.fortnox_integration ? (
				<Banner
					title={"Anslut till Fortnox"}
					status="warning"
					actions={[
						{
							content: "Anslut till Fortnox",
							onAction: handleAddFortnox,
						},
					]}
				>
					<p>{"Du har inte anslutit till Fortnox än. Klicka på knappen nedan för att ansluta."}</p>
				</Banner>
			) : (
				<StyledFortnoxSettings>
					{sections
						?.filter((s) => s.title || s.items?.length)
						.map((section, key) => {
							return (
								<Card key={key} title={section.title}>
									<StyledFortnoxSettingsInnerCard>
										{section?.items.map((item: any, key) => {
											return <SettingsItem key={key} item={item} />;
										})}
									</StyledFortnoxSettingsInnerCard>
								</Card>
							);
						})}
				</StyledFortnoxSettings>
			)}
		</Page>
	);
};

export default connect((state: any) => ({
	account: state.account,
}))(FortnoxSettings);

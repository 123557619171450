import { Component } from "react";
import { Badge } from "@shopify/polaris";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";
import TimeRangeModal from "../../components/TimeRangeModal.js";

class DeviationIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderCell(item, column) {
		if (column.handle === "TITLE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.description}</h3>
						{item.accident && <Badge status="attention">Olycka</Badge>}
						{item.avvikelse && <Badge>Avvikelse</Badge>}
					</div>
				</div>
			);
		}
		if (column.handle === "MINUTES") {
			return `${item.minutes} min`;
		}
		if (column.handle === "USER") {
			return item.user?.name || item.user?.email;
		}
		if (column.handle === "WORKORDER") {
			return item.work_order?.title;
		}
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();
		var filters = [
			{
				key: "date_span",
				label: "Datum",
				minKey: "date_span_min",
				maxKey: "date_span_max",
				dateOptionType: "past",
				type: "dateSelector",
			},
			{
				label: "Start datum",
				key: "date_span_min",
				type: "date",
			},
			{
				label: "Slut datum",
				key: "date_span_max",
				type: "date",
			},
		];

		const columns = [
			{
				label: "Titel",
				handle: "TITLE",
				sortable: true,
			},
			{
				label: "Tid",
				handle: "MINUTES",
				sortable: true,
			},
			{
				label: "Användare",
				handle: "USER",
				sortable: true,
			},
			{
				label: "Arbetsorder",
				handle: "WORKORDER",
				sortable: true,
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					columns={columns}
					history={this.props.history}
					onClickRow={(item) => {
						this.props.history.push(`/deviation_reporting/${item.id}`);
					}}
					renderCell={this.renderCell.bind(this)}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{
										content: "Exportera",
										onAction: () => {
											this.setState({ changeTimePeriodModalOpen: true });
										},
									},
							  ]
							: []
					}
					title={!this.props.workOrder && "Registrerade avvikelser"}
					savedSearchHandle="accidents"
					resourceUrl="/api/incidents.json?avvikelse=1"
					resourceHandle="incidents"
					resourceName={{
						singular: "avvikelse",
						plural: "avvikelser",
					}}
					params={{
						project_id: this.props.project_id,
					}}
					filters={filters}
					noTopPagination={this.props.workOrder}
				/>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					// onChange={this.exportCurrentProjects.bind(this)}
				/>
			</div>
		);
	}
}

export default DeviationIndex;

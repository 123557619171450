import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { TableVirtuoso } from "react-virtuoso";
import moment from "moment";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { Spinner, Tooltip } from "@shopify/polaris";
import { store } from "src/js/store";
import Colors, { colorToRgba } from "../../../Colors";
import { DashboardSectionTitle } from "../styledComponents";
import ProfileAvatar from "../../../components/ProfileAvatar";
import Truncate from "../../../components/Truncate";

type ActivityLogProps = { history: any; limit?: number };
type Log = {
	resource_id: number;
	resource_type: string;
	user: any;
	id: number;
	created_at: string;
	title: string;
};
type Logs = Log[];

const ActivityLog = ({ history, limit = 100 }: ActivityLogProps) => {
	const [logs, setLogs] = useState<Logs>([]);
	const [offset, setOffset] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const ref = useRef(null);

	const handleFetchAndSetLogs = useCallback(
		async (offset = 0) => {
			try {
				setLoading(true);
				const data = await API.get("/api/activity_logs.json", { params: { limit, offset } }).then((res) => res.data);
				if (data?.error) {
					console.error("data.data.error:", data.data.error);
					toastr.error(data.data.error);
					return;
				}

				if (offset) {
					setOffset(offset);
					setLogs((c) => [...c, ...(data?.activity_logs || [])]);
				} else {
					setLogs(data?.activity_logs || []);
					setOffset(0);
				}
				setCount(data?.count);
				setLoading(false);
			} catch (error) {
				console.error("error:", error);
				toastr.error(error);
			}
		},
		[limit]
	);

	const loadMore = () => {
		handleFetchAndSetLogs(offset + limit);
	};

	useEffect(() => {
		handleFetchAndSetLogs();

		// setLogs([
		// 	...[...Array(100)].map((_, i) => ({
		// 		user: store.getState().user,
		// 		id: i,
		// 		title: "Skapade en ny faktura",
		// 		date: "2021-01-01 09:30",
		// 	})),
		// 	{
		// 		user: store.getState().user,
		// 		id: 999,
		// 		title: "END Skapade en ny faktura",
		// 		date: "2021-01-01 09:30",
		// 	},
		// ]);

		return () => {};
	}, [handleFetchAndSetLogs]);

	const getOnClick = (log: Log) => {
		const { resource_id: resourceId, resource_type: resourceType } = log;
		const { url = null, state = null } = (() => {
			switch (resourceType) {
				case "Quote":
					return { url: `/admin/quotes/${resourceId}` };
				case "Customer":
					return { url: `/admin/customers/${resourceId}` };
				case "Contact":
					return { url: `/admin/contacts/${resourceId}` };
				case "Project":
					return { url: `/admin/projects/${resourceId}` };
				case "PlanningEvent":
					return { url: `/admin/planning/${resourceId}` };
				default:
					return {};
			}
		})();

		if (url)
			return () => {
				history.push(url, state);
			};
		return undefined;
	};

	const renderRow = (index: number, log: Log) => {
		const date = moment(log.created_at).format("YYYY-MM-DD");
		const time = moment(log.created_at).format("HH:mm");
		const user = store.getState().users.find((u: any) => u.id === log.user?.id);
		const userString = `(${user?.name})\\((${user?.id})\\)`;
		const onClick = getOnClick(log);
		// const title = log.title.replace(`${user?.name}(${user?.id})`, ``);
		const regexPattern = new RegExp(userString, "g");
		const title = log.title.replace(regexPattern, "<b>$1($2)</b>");
		return (
			<>
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
				<td style={{ cursor: onClick ? "pointer" : "initial" }} onClick={onClick}>
					<Title>
						<ProfileAvatar size="small" user={user} />
						<Tooltip content={log.title}>
							<Truncate dangerouslySetInnerHTML={{ __html: title }}>
								{/* <b>
									{user.name}({user.id})
								</b> */}
								{/* {title} */}
							</Truncate>
						</Tooltip>
					</Title>
				</td>
				<td style={{ width: "125px" }}>
					<p style={{ justifyContent: "center", whiteSpace: "nowrap" }}>{date}</p>
				</td>
				<td style={{ width: "50px" }}>
					<p style={{ justifyContent: "center", whiteSpace: "nowrap" }}>{time}</p>
				</td>
			</>
		);
	};

	return (
		<Wrapper ref={ref}>
			<DashboardSectionTitle>Aktivitetslogg</DashboardSectionTitle>

			<TableVirtuoso
				width="100%"
				style={{ flex: 1, height: "calc(100% - 40px)" }}
				data={logs}
				computeItemKey={(_, log) => {
					return log.id;
				}}
				itemContent={renderRow}
				components={{
					// TableRow: ({ children, item, style, ...rest }) => {
					// 	return (
					// 		<tr
					// 			key={item.id}
					// 			data-index={rest["data-index"]}
					// 			data-item-index={rest["data-item-index"]}
					// 			data-known-size={rest["data-known-size"]}
					// 			className={String(this.state.selectedRow?.id) === String(item?.id) ? "selected" : ""}
					// 			onClick={() => {
					// 				this.props.setSelectorRow(item);
					// 			}}
					// 			style={{ ...style, cursor: "pointer" }}
					// 		>
					// 			{children}
					// 		</tr>
					// 	);
					// },
					EmptyPlaceholder: () => {
						return <React.Fragment>empty</React.Fragment>;
					},

					TableFoot: () => {
						if (offset + limit >= count) return null;

						return (
							<LoadMoreWrapper>
								<td colSpan={3}>
									<div>
										<LoadingButton onClick={loadMore} style={{ textAlign: "center" }} loading={loading}>
											<div>Ladda mer {loading && <Spinner size="small" />}</div>
										</LoadingButton>
									</div>
								</td>
							</LoadMoreWrapper>
						);
					},
				}}
				fixedFooterContent={() => {
					return null;
				}}
			/>
		</Wrapper>
	);
};
export default ActivityLog;

const Wrapper = styled.div.attrs({ className: "activity-log" })`
	position: relative;
	border-radius: var(--p-border-radius-2);
	background-color: ${Colors.secondary};
	box-shadow: var(--p-shadow-md);
	outline: var(--p-border-width-1) solid transparent;
	padding: var(--p-space-5);
	height: 100%;
	font-weight: 700;

	table {
		width: 100%;

		td {
			padding: var(--p-space-2);
			font-size: 12px;

			&:first-of-type {
				span {
					color: ${Colors["color-secondary"]};
				}

				& > div > span:first-child {
					min-width: 24px;
					width: 24px;
				}
			}

			&:not(:first-of-type) span {
				color: ${colorToRgba(Colors["color-primary"], 0.7)};
			}
		}
	}
`;

const LoadingButton = styled.button<{ loading: boolean }>`
	width: 100%;
	background-color: transparent;
	outline-color: transparent;
	border: none;
	color: ${Colors["color-secondary"]};
	font-weight: 600;
	transition: color 250ms;
	display: flex;
	justify-content: center;
	align-items: center;

	& > div {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.Polaris-Spinner {
		position: absolute;
		right: 0;
		transform: translate(125%, 0);
	}

	&:not([disabled]) {
		cursor: pointer;
	}

	&:hover:not([disabled]) {
		color: ${Colors["color-primary"]};
	}

	${({ loading }) => {
		if (loading) {
			return css`
				color: ${Colors["color-primary"]};
				cursor: initial;
				pointer-events: none;
			`;
		}
	}}
`;

const LoadMoreWrapper = styled.tr``;
const Title = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 1rem;
	font-weight: 500;

	&&&&&& {
		&,
		span {
			color: ${Colors["color-subdued"]};
		}
	}

	b {
		color: ${Colors["color-secondary"]};
	}
`;

// import { ClockMajor } from "@shopify/polaris-icons";
import React from "react";
import Button from "src/js/components/Button";
import useTimeReport from "src/js/hooks/useTimeReport";
import { SimplePlusIcon } from "src/js/icons";

const TimeReportOpenModalButton = ({ options = null }) => {
	const { handleOpen } = useTimeReport(options);

	const handleOpenTimeReportModal = async () => {
		handleOpen();

		// const url = window.location.href;
		// const projectId = url.match(/projects\/(\d+)/)?.[1];
		// if (projectId) {
		// 	const result = await API.get(`/api/projects/${projectId}.json`);
		// 	if (result.data.project) {
		// 		const project = result.data.project;
		// 		// handleOpen();
		// 		handleOpenWithOptions({ project });
		// 	}
		// }
	};

	return (
		<>
			<Button icon={<SimplePlusIcon color="var(--pc-button-text)" />} onClick={handleOpenTimeReportModal} primary>
				Ny tidregistrering
			</Button>
		</>
	);
};
export default TimeReportOpenModalButton;

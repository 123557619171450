/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import {
	Page,
	PageActions,
	Layout,
	Card,
	FormLayout,
	Icon,
	TextField,
	ResourceList,
	Avatar,
	Select,
	Stack,
	OptionList,
	Checkbox,
	Modal,
	Badge,
	ChoiceList,
	Button,
	ButtonGroup,
} from "@shopify/polaris";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { ChevronLeftMinor, ChevronRightMinor, DisputeMinor } from "@shopify/polaris-icons";
import { colorToRgba } from "src/js/Colors";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import SkeletonProvider from "../../components/skeleton_provider.js";
import SearchField from "../../components/search_field.js";
import TimeRangeModal from "../../components/TimeRangeModal.js";
import TimePicker from "../../components/time_picker.js";
import TimeReportModal from "../../components/TimeReportModal.js";
import TimeReportRow from "../TimeReportRow.js";
import API from "../../API";
import { ColumnHeader, Event, Wrapper } from "./Schedule";

class UserEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flex_adjustment: "",
			showFlexModal: false,
			showRemoveModal: false,
			showTimeReportModal: false,
			flexTimeRangeModalOpen: false,
			showRemoveEventModal: false,
			selectedRemoveOption: "all",
			editingEvent: null,
			repeat_holidays: false,
			recurring: "weekly",
			type: "work",
			events: [],
			id: props.match.params.id,
			from_time: store.getState().account.workday_start,
			to_time: store.getState().account.workday_end,
			weekdays: [],
			dates: [],
			loading: true,
			saving: false,
			showCreateEventModal: false,
			time_reports: [],
			languages: [],
		};
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		var monday = this.getMonday(new Date());
		var sunday = this.getSunday(new Date());
		this.fetchEvents(this.state.id, monday, sunday);
		this.fetchLanguages();
	}

	getMonday(d) {
		var day = d.getDay();
		var diff = d.getDate() - day + (day == 0 ? -6 : 1);
		return new Date(d.setDate(diff));
	}

	getSunday(d) {
		return this.nextDate(0);
	}

	nextDate(dayIndex) {
		var today = new Date();
		today.setDate(today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1);
		return today;
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/users/" + id + ".json")
			.then((result) => {
				this.setState(
					{
						loading: false,
						saving: false,
						// eslint-disable-next-line react/no-unused-state
						adjustments: result.data.adjustments,
						form:
							result.data.user && result.data.user.days_of_no_notification
								? result.data.user
								: Object.assign(result.data.user, { days_of_no_notification: {}, project_subscriptions: [] }),
					},
					() => {
						this.fetchReports();
					}
				);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/users/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				store.dispatch({ type: "UPDATE_USER", user: result.data.user });
				toastr.success("User updated");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	toggleRole(role) {
		var formState = this.state.form;

		if (this.state.form.roles.indexOf(role) >= 0) {
			if (role == "ROLE_ADMIN") {
				formState.roles = [];
			}

			var roles = [];
			for (var i = 0; i < this.state.form.roles.length; i++) {
				if (this.state.form.roles[i] != role) {
					roles.push(this.state.form.roles[i]);
				}
			}
			formState.roles = roles;
		} else {
			formState.roles.push(role);
		}
		this.setState({ form: formState });
	}

	subscribe(project) {
		var found = false;
		for (var i = 0; i < this.state.form.project_subscriptions.length; i++) {
			if (project.id == this.state.form.project_subscriptions[i].id) {
				found = i;
				break;
			}
		}
		if (found === false) {
			this.state.form.project_subscriptions.push(project);
		} else {
			this.state.form.project_subscriptions.splice(found, 1);
		}
		this.setState({ form: this.state.form });
	}

	removeSubscription(project) {
		var arr = [];
		for (var i = 0; i < this.state.form.project_subscriptions.length; i++) {
			if (this.state.form.project_subscriptions[i].id != project.id) {
				arr.push(this.state.form.project_subscriptions[i]);
			}
		}
		this.state.form.project_subscriptions = arr;
		this.setState({ form: this.state.form });
	}

	toggleGroup(group) {
		var found = false;
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (group.id == this.state.form.groups[i].id) {
				found = i;
				break;
			}
		}

		if (found === false) {
			group.admin = false;
			if (this.state.form.groups.length < 1) {
				group.main = true;
			} else {
				group.main = false;
			}
			this.state.form.groups.push(group);
		} else {
			this.state.form.groups.splice(found, 1);
		}
		this.setState({ form: this.state.form });
	}

	rangeChange(range) {
		let fromDate = null;
		let toDate = null;
		if (range.start) {
			fromDate = range.start;
			toDate = range.end;
		} else {
			fromDate = null;
			toDate = null;
			for (var i = 0; i < range.length; i++) {
				if (!fromDate) {
					fromDate = range[i];
				}
				toDate = range[i];
			}
		}
		this.fetchEvents(this.state.id, fromDate, toDate);
	}

	getWeekdayName(weekDay) {
		if (weekDay == 0) {
			return "Söndag";
		} else if (weekDay == 1) {
			return "Måndag";
		} else if (weekDay == 2) {
			return "Tisdag";
		} else if (weekDay == 3) {
			return "Onsdag";
		} else if (weekDay == 4) {
			return "Torsdag";
		} else if (weekDay == 5) {
			return "Fredag";
		} else if (weekDay == 6) {
			return "Lördag";
		}
	}

	leadingZero(val) {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	}

	handleSelect(selection) {
		var dates = [];
		var weekDays = [];
		for (var i = 0; i < selection.slots.length; i++) {
			var ymd =
				selection.slots[i].getFullYear() +
				"-" +
				this.leadingZero(selection.slots[i].getMonth() + 1) +
				"-" +
				this.leadingZero(selection.slots[i].getDate());
			if (dates.indexOf(ymd) < 0) {
				dates.push(ymd);
			}
			if (weekDays.indexOf(this.getWeekdayName(selection.slots[i].getDay())) < 0) {
				weekDays.push(this.getWeekdayName(selection.slots[i].getDay()));
			}
		}
		let toTime = null;
		let fromTime = null;
		if (dates.length > 1) {
			toTime = store.getState().account.workday_end;
			fromTime = store.getState().account.workday_start;
		} else {
			toTime = this.leadingZero(selection.end.getHours()) + ":" + this.leadingZero(selection.end.getMinutes());
			fromTime = this.leadingZero(selection.start.getHours()) + ":" + this.leadingZero(selection.start.getMinutes());
		}

		this.setState({
			to_time: toTime,
			from_time: fromTime,
			weekdays: weekDays,
			dates,
			showCreateEventModal: true,
			editingEvent: null,
		});
	}

	closeEventModal() {
		this.setState({ showCreateEventModal: false });
	}

	saveWorkTime() {
		this.setState({ saving: true });
		if (this.state.editingEvent) {
			API.put("/api/work_times/" + this.state.editingEvent.id + ".json", {
				repeat_holidays: this.state.repeat_holidays,
				recurring: this.state.recurring,
				type: this.state.type,
				time_start: this.state.from_time,
				time_end: this.state.to_time,
				dates: this.state.dates,
			})
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("Arbetstid ändrades");
					this.closeEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/work_times.json", {
				user_id: this.state.id,
				repeat_holidays: this.state.repeat_holidays,
				recurring: this.state.recurring,
				type: this.state.type,
				time_start: this.state.from_time,
				time_end: this.state.to_time,
				dates: this.state.dates,
			})
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("Arbetstid skapades");
					this.closeEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	fetchEvents(id, fromDate, toDate) {
		this.setState({ viewFrom: fromDate, viewTo: toDate });
		var from = fromDate.getFullYear() + "-" + this.leadingZero(fromDate.getMonth() + 1) + "-" + this.leadingZero(fromDate.getDate());
		var to = toDate.getFullYear() + "-" + this.leadingZero(toDate.getMonth() + 1) + "-" + this.leadingZero(toDate.getDate());
		API.get("/api/users/" + id + "/work_times.json", {
			params: { from, to },
		})
			.then((result) => {
				this.setState({ events: this.parseEvents(result.data.work_times) });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	reloadEvents() {
		this.fetchEvents(this.state.id, this.state.viewFrom, this.state.viewTo);
	}

	parseEvents(events) {
		for (var i = 0; i < events.length; i++) {
			if (events[i].type == "lunch") {
				events[i].title = "L";
			} else if (events[i].type == "work") {
				events[i].title = "A";
			} else if (events[i].type == "flex") {
				events[i].title = "F";
			}
			events[i].start = new Date(events[i].event_start_at);
			events[i].end = new Date(events[i].event_end_at);

			const color = (() => {
				switch (events[i].type) {
					case "lunch":
						return "#8B5CF6";
					case "flex":
						return "#cc6933";
					case "work":
					default:
						return "#0EA5E9";
				}
			})();
			events[i].color = color;
		}
		return events;
	}

	removeEventModal() {
		this.setState({ showCreateEventModal: false, showRemoveEventModal: true });
	}

	closeRemoveEventModal() {
		this.setState({ showRemoveEventModal: false });
	}

	editEvent(item) {
		var dates = [];
		var weekDays = [];
		var start = new Date(item.start_at);
		var end = new Date(item.end_at);
		var ymd = start.getFullYear() + "-" + this.leadingZero(start.getMonth() + 1) + "-" + this.leadingZero(start.getDate());
		if (dates.indexOf(ymd) < 0) {
			dates.push(ymd);
		}
		if (weekDays.indexOf(this.getWeekdayName(start.getDay())) < 0) {
			weekDays.push(this.getWeekdayName(start.getDay()));
		}

		var toTime = this.leadingZero(end.getHours()) + ":" + this.leadingZero(end.getMinutes());
		var fromTime = this.leadingZero(start.getHours()) + ":" + this.leadingZero(start.getMinutes());

		this.setState({
			selectedRemoveOption: "all",
			editingEvent: item,
			repeat_holidays: item.repeat_holidays,
			recurring: item.recurring ? item.recurring : "single",
			type: item.type,
			to_time: toTime,
			from_time: fromTime,
			weekdays: weekDays,
			dates,
			showCreateEventModal: true,
		});
	}

	removeWorkTime() {
		this.setState({ saving: true });
		if (this.state.editingEvent.recurring && this.state.selectedRemoveOption != "all") {
			if (this.state.selectedRemoveOption == "this") {
				var skipDays = this.state.editingEvent.skip_days;
				var date =
					this.state.editingEvent.start.getFullYear() +
					"-" +
					this.leadingZero(this.state.editingEvent.start.getMonth() + 1) +
					"-" +
					this.leadingZero(this.state.editingEvent.start.getDate());
				skipDays.push(date);
				API.put("/api/work_times/" + this.state.editingEvent.id + ".json", { skip_days: skipDays })
					.then((result) => {
						this.setState({ saving: false });
						if (result.data.error) {
							return;
						}
						toastr.success("Arbetstid ändrades");
						this.closeRemoveEventModal();
						this.reloadEvents();
					})
					.catch((error) => {
						this.setState({ saving: false });
						toastr.error(error);
					});
			} else if (this.state.selectedRemoveOption == "future") {
				API.put("/api/work_times/" + this.state.editingEvent.id + ".json", { expire_at: this.state.editingEvent.event_start_at })
					.then((result) => {
						this.setState({ saving: false });
						if (result.data.error) {
							return;
						}
						toastr.success("Arbetstid ändrades");
						this.closeRemoveEventModal();
						this.reloadEvents();
					})
					.catch((error) => {
						this.setState({ saving: false });
						toastr.error(error);
					});
			}
		} else {
			API.delete("/api/work_times/" + this.state.editingEvent.id + ".json")
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("Arbetstid borttagen");
					this.closeRemoveEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	setGroupAdmin(group, value) {
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (this.state.form.groups[i].id == group.id) {
				this.state.form.groups[i].admin = value;
			}
		}
		this.setState({ form: this.state.form });
	}

	setMainGroup(group, value) {
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (this.state.form.groups[i].id == group.id) {
				this.state.form.groups[i].main = true;
			} else {
				this.state.form.groups[i].main = false;
			}
		}
		this.setState({ form: this.state.form });
	}

	removeGroup(group) {
		var arr = [];
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (this.state.form.groups[i].id != group.id) {
				arr.push(this.state.form.groups[i]);
			}
		}
		this.state.form.groups = arr;
		this.setState({ form: this.state.form });
	}

	renderItem(item) {
		const url = "/admin/groups/" + item.id;
		const media = <Avatar customer size="medium" name={item.name} />;

		var shortcutActions = [];

		if (!item.admin) {
			shortcutActions.push({ content: "Gruppledare", onAction: this.setGroupAdmin.bind(this, item, true) });
		} else {
			shortcutActions.push({ content: "Arbetare", onAction: this.setGroupAdmin.bind(this, item, false) });
		}

		if (!item.main) {
			shortcutActions.push({ content: "Huvudgrupp", onAction: this.setMainGroup.bind(this, item, true) });
		}

		shortcutActions.push({ content: "Ta bort", destructive: true, onAction: this.removeGroup.bind(this, item) });

		return (
			<ResourceList.Item id={item.id} media={media} url={url} shortcutActions={store.getState().user.read_only ? null : shortcutActions}>
				<div>{item.name}</div>
				<Stack>
					{item.admin ? <Badge>Gruppledare</Badge> : null}
					{!item.main ? <Badge>Extra</Badge> : null}
				</Stack>
			</ResourceList.Item>
		);
	}

	renderSubscriptionItem(item) {
		const url = "/admin/projects/" + item.id;

		var shortcutActions = [];

		shortcutActions.push({ content: "Ta bort", destructive: true, onAction: this.removeSubscription.bind(this, item) });

		return (
			<ResourceList.Item id={item.id} url={url} shortcutActions={shortcutActions}>
				<div>{item.label}</div>
			</ResourceList.Item>
		);
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	printFlexReport() {
		this.setState({ flexTimeRangeModalOpen: true });
	}

	closeFlexTimeRangeModal() {
		this.setState({ flexTimeRangeModalOpen: false });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printRange(fromDate, toDate, extraReports) {
		this.closeTimeRangeModal();
		window.open(
			"/api/users/" + this.state.id + "/report.pdf?from=" + this.getYmd(fromDate) + "&to=" + this.getYmd(toDate) + "&extras=" + extraReports.join(",")
		);
	}

	reportTime() {
		this.setState({ showTimeReportModal: true });
	}

	closeTimeReportModal() {
		// sessionStorage.removeItem('selectedUser')
		this.setState({ showTimeReportModal: false });
	}

	printFlexRange(fromDate, toDate, extraReports) {
		this.closeFlexTimeRangeModal();
		window.open(
			"/api/users/" +
				this.state.id +
				"/report_flex.pdf?from=" +
				this.getYmd(fromDate) +
				"&to=" +
				this.getYmd(toDate) +
				"&extras=" +
				extraReports.join(",")
		);
	}

	sendInstallSms() {
		API.get("/api/users/" + this.state.id + "/install_link.json")
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("SMS skickat");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	removeItem() {
		this.setState({ saving: true });
		API.delete("/api/users/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "REMOVE_USER", user: result.data.user });
				toastr.success("Användaren borttagen");
				this.props.history.replace("/admin/users");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	closeFlexModal() {
		this.setState({ showFlexModal: false });
	}

	adjustFlex() {
		this.setState({ saving: true });
		API.post("/api/users/" + this.state.id + "/flex.json", { adjustment: this.state.flex_adjustment })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Flex nollställd");
				this.state.form.current_flex = result.data.current_flex;
				this.setState({ form: this.state.form });
				this.closeFlexModal();
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	resetFlex() {
		this.setState({ saving: true });
		API.post("/api/users/" + this.state.id + "/reset_flex.json")
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Flex nollställd");
				this.state.form.current_flex = 0;
				this.setState({ form: this.state.form });
				this.closeFlexModal();
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	renderTimeReport(item) {
		return (
			<TimeReportRow
				showProject={false}
				showWorkorderProject
				showCost={false}
				showAo={false}
				showType={false}
				showUser={false}
				onClick={() => {
					this.props.history.push("/admin/time_reports/" + item.id, {
						project: this.state.form,
						work_order: item.work_order,
					});
				}}
				item={item}
			/>
		);
	}

	fetchReports(id = this.state.id) {
		this.setState({ loadingReports: true });
		API.get("/api/time_reports.json", { params: { user_id: id, limit: 10 } })
			.then((result) => {
				this.setState({
					loadingReports: false,
					// eslint-disable-next-line react/no-unused-state
					totalMinutes: result.data.sum,
					// eslint-disable-next-line react/no-unused-state
					totalObMinutes: result.data.sum_ob,
					reportCount: result.data.count,
					// totalCost: result.data.sum_cost,
					time_reports: result.data.time_reports,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchLanguages() {
		API.get("/api/languages.json").then((response) => {
			this.setState({ languages: response.data.languages });
		});
	}

	showAllReports() {
		this.props.history.push("/admin/time_reports", { user: this.state.form });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	eventPropGetter(event, start, end, selected) {
		const style = {
			backgroundColor: selected ? event.color : colorToRgba(event.color, 0.75),
			borderRadius: "3px",
			opacity: 0.8,
			border: "0px",
			transition: "all 0.2s ease-in-out",
			borderLeft: `3px solid ${event.color}`,
			color: "#ffffff",
			"var(--color)": event.color,
		};

		return { style };
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();
		const accountOptions = store
			.getState()
			.accounts.map((acc, key) => ({ value: acc.id, disabled: acc.id == store.getState().account.id, label: acc.company }));

		const roleOptions = [{ value: "ROLE_USER_WEB", label: "Kunna rapportera tid via webben" }];

		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		let modalActions = [];
		if (this.state.editingEvent) {
			modalActions = [
				{
					destructive: true,
					content: "Ta bort",
					onAction: this.removeEventModal.bind(this),
				},
				{
					content: "Close",
					onAction: this.closeEventModal.bind(this),
				},
			];
		} else {
			modalActions = [
				{
					content: "Close",
					onAction: this.closeEventModal.bind(this),
				},
			];
		}
		moment.locale("sv");
		const localizer = momentLocalizer(moment);

		return (
			<Page
				title={this.state.form.first_name + " " + this.state.form.last_name}
				backAction={{ content: "Användare", url: "/admin/users" }}
				primaryAction={store.getState().user.read_only ? null : { content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={[
					{
						content: (
							<Stack spacing="extraTight">
								<Icon source={DisputeMinor} />
								<div>Skicka installations SMS</div>
							</Stack>
						),
						onAction: this.sendInstallSms.bind(this),
					},
					store.getState().user.roles.includes("ROLE_ADMIN") && {
						content: "Registrera tid",
						onAction: this.reportTime.bind(this),
					},
					{
						content: "Skriv ut tidrapport",
						onAction: this.printFlexReport.bind(this),
					},
				]}
			>
				<Layout>
					<Layout.AnnotatedSection
						title="Grunduppgifter"
						description="Här anges grundläggande information om personen. Lösenordet är krypterat vilket innebär att om användaren glömt sitt lösenord måste ni ändra och spara in ett nytt. Genom att avaktivera kontot behålls alla historik men användaren hindras från att kunna logga in."
					>
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Användarnamn" value={this.state.form.username} onChange={this.updateForm.bind(this, "username")} disabled />
									<Stack distribution="fillEvenly">
										<TextField label="Förnamn *" value={this.state.form.first_name} onChange={this.updateForm.bind(this, "first_name")} />
										<TextField label="Efternamn *" value={this.state.form.last_name} onChange={this.updateForm.bind(this, "last_name")} />
									</Stack>
									<TextField label="E-mail" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} />
									<TextField label="Mobilnummer" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} />
									<TextField
										type="password"
										label="Lösenord ( för app och adminsystem )"
										value={this.state.form.password}
										onChange={this.updateForm.bind(this, "password")}
									/>
									<Select
										label="Språk"
										value={this.state.form.language ? `${this.state.form.language.id}` : null}
										options={this.state.languages.map((item) => ({
											label: item.language,
											value: `${item.id}`,
										}))}
										placeholder="Välj språk"
										onChange={(value) => {
											const language = this.state.languages.find((language) => language.id == value);
											this.state.form.language = language;
											this.setState({ form: this.state.form });
										}}
									/>

									<Checkbox
										checked={this.state.form.enabled}
										onChange={(val) => {
											this.state.form.enabled = val;
											this.setState({ form: this.state.form });
										}}
										label="Användaren är aktiv"
									/>
								</FormLayout>
							</Card.Section>
							{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue ? (
								<Card.Section title="Rättigheter">
									<FormLayout>
										<Checkbox
											checked={this.state.form.roles.indexOf("ROLE_ADMIN") >= 0}
											label="Användaren är admin"
											onChange={this.toggleRole.bind(this, "ROLE_ADMIN")}
										/>
										<Checkbox
											checked={this.state.form.no_time_reporting}
											onChange={(val) => {
												this.state.form.no_time_reporting = val;
												this.setState({ form: this.state.form });
											}}
											label="Användaren behöver INTE rapportera tid"
										/>
										<Checkbox
											checked={this.state.form.read_only}
											label="Endast läsrättigheter"
											onChange={(val) => {
												this.state.form.read_only = val;
												this.setState({ form: this.state.form });
											}}
										/>
										<Checkbox
											checked={this.state.form.exlude_from_planning}
											label="Exludera från planering"
											onChange={(val) => {
												this.state.form.exlude_from_planning = val;
												this.setState({ form: this.state.form });
											}}
										/>

										{this.state.form.roles.indexOf("ROLE_ADMIN") < 0 ? (
											<OptionList
												onChange={this.updateForm.bind(this, "roles")}
												options={roleOptions}
												selected={this.state.form.roles}
												allowMultiple
											/>
										) : null}
									</FormLayout>
								</Card.Section>
							) : null}
						</Card>
					</Layout.AnnotatedSection>

					{!store.getState().account.ue ? (
						<Layout.AnnotatedSection title="Övriga uppgifter" description="Här anges övriga inställningar för personen.">
							<Card sectioned>
								<FormLayout>
									<TextField
										label="Timlön inkl avgifter"
										value={this.state.form.hourly_rate + ""}
										onChange={this.updateForm.bind(this, "hourly_rate")}
									/>
									<TextField
										label="OB vardag lön inkl avgifter"
										value={this.state.form.hourly_ob_rate + ""}
										onChange={this.updateForm.bind(this, "hourly_ob_rate")}
									/>
									<TextField
										label="OB helg lön inkl avgifter"
										value={this.state.form.hourly_ob2_rate + ""}
										onChange={this.updateForm.bind(this, "hourly_ob2_rate")}
									/>
									<TextField
										label="Timlön övertid inkl avgifter"
										value={this.state.form.hourly_overtime_rate}
										onChange={this.updateForm.bind(this, "hourly_overtime_rate")}
									/>
									<TextField
										label="Anställningsnummer"
										value={this.state.form.employee_number + ""}
										onChange={this.updateForm.bind(this, "employee_number")}
									/>
									<Select
										label="Timanställd"
										options={[
											{ label: "Nej", value: "0" },
											{ label: "Ja", value: "1" },
										]}
										onChange={this.updateForm.bind(this, "hourly_employee")}
										value={this.state.form.hourly_employee + ""}
									/>
									<TextField
										label="Kod till central incheckningsstation"
										value={this.state.form.checkin_code + ""}
										onChange={this.updateForm.bind(this, "checkin_code")}
									/>
									<small>Denna kod används vid använding av en central incheckningsstation</small>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>
					) : null}

					<Layout.AnnotatedSection title="Tidregistreringar" description="Regsitrera tid">
						<div className="user-time_reports">
							<Card
								title="Tidregistreringar"
								actions={
									this.state.loadingReports
										? null
										: [
												{
													content: "Visa alla " + this.state.reportCount + " registreringar",
													onAction: this.showAllReports.bind(this),
												},
												store.getState().user.roles.includes("ROLE_ADMIN") && {
													content: <div className="Polaris-Button Polaris-Button--primary">Registrera tid</div>,
													onAction: this.reportTime.bind(this),
												},
										  ]
								}
							>
								<ResourceList
									resourceName={{
										singular: "rapportering",
										plural: "rapporteringar",
									}}
									items={this.state.time_reports}
									renderItem={this.renderTimeReport.bind(this)}
									loading={this.state.loadingReports}
								/>
							</Card>
						</div>
					</Layout.AnnotatedSection>

					{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue ? (
						<Layout.AnnotatedSection title="Prenumerationer" description="Lägg till eller ta bort projektrapporter som användaren prenumererar på.">
							<Card sectioned title="Projekt">
								<Card.Section>
									<SearchField
										placeholder="Sök efter projekt att lägga till"
										resource="projects"
										resourceName={{ singular: "grupp", plural: "grupper" }}
										label_handle="label"
										onSelect={this.subscribe.bind(this)}
									/>
								</Card.Section>
								<ResourceList
									resourceName={{
										singular: "projekt",
										plural: "projekt",
									}}
									items={this.state.form.project_subscriptions}
									renderItem={this.renderSubscriptionItem.bind(this)}
								/>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{store.getState().account.has_personell_ledger ? (
						<Layout.AnnotatedSection
							title="Personalliggare"
							description="För att föra personalliggare till skatteverket krävs ett id-nummer för personalen"
						>
							<Card sectioned>
								<FormLayout>
									<TextField
										label="Personnr/Samordnnr/Annat nr"
										value={this.state.form.id_number}
										onChange={this.updateForm.bind(this, "id_number")}
									/>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{!store.getState().account.ue ? (
						<Layout.AnnotatedSection title="Grupper" description="En användare kan tillhöra flera grupper">
							<Card sectioned title="Grupper">
								<Card.Section>
									<SearchField
										placeholder="Sök efter grupper att lägga till"
										resource="groups"
										resourceName={{ singular: "grupp", plural: "grupper" }}
										label_handle="name"
										onSelect={this.toggleGroup.bind(this)}
									/>
								</Card.Section>
								<ResourceList
									resourceName={{
										singular: "grupp",
										plural: "grupper",
									}}
									items={this.state.form.groups}
									renderItem={this.renderItem.bind(this)}
								/>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{accountOptions.length > 1 ? (
						<Layout.AnnotatedSection title="Accounts" description="Users can be connected to multiple accounts">
							<Card sectioned>
								<OptionList
									onChange={this.updateForm.bind(this, "accounts")}
									options={accountOptions}
									selected={this.state.form.accounts}
									allowMultiple
								/>
							</Card>
						</Layout.AnnotatedSection>
					) : null}

					{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue ? (
						<Layout.AnnotatedSection
							title="Arbetstider"
							id="schedule"
							description={
								this.state.form.have_schedule ? (
									<ChoiceList
										title="Arbetad tid utöver schemalagd arbetstid räknas som"
										choices={[
											{ label: "Flex", value: "flex" },
											{ label: "Övertid", value: "overtime" },
											{ label: "Inget", value: "none" },
										]}
										selected={this.state.form.excess_time}
										onChange={(value) => {
											this.state.form.excess_time = value[0];
											this.setState({ form: this.state.form });
										}}
									/>
								) : (
									<div>
										Här bestämmer du vad som är ordinarie arbetstider för användaren. Detta används bara vid behov av automatisk uträkning av flex
										eller övertid.
									</div>
								)
							}
						>
							<Card sectioned>
								<Checkbox
									checked={this.state.form.have_schedule}
									onChange={(val) => {
										this.state.form.have_schedule = val;
										this.setState({ form: this.state.form });
									}}
									label="Användaren har ett schema"
								/>

								{this.state.form.have_schedule ? (
									<div style={{ height: "unset" }}>
										<Calendar
											components={{
												week: {
													header: (props) => {
														return (
															<ColumnHeader>
																<p className="date-name">{moment(props.date).format("dddd").capitalize()}</p>

																<p className="date">{moment(props.date).format("DD")}</p>
															</ColumnHeader>
														);
													},
												},
												toolbar: ({ onNavigate, date, label }) => {
													return (
														<Wrapper>
															<h3>
																Vecka {moment(date).isoWeek()} {moment(date).year()}
															</h3>
															<p className="period">Period: {label}</p>

															<ButtonGroup spacing="extraTight">
																<Button
																	icon={ChevronLeftMinor}
																	onClick={() => {
																		onNavigate("PREV", date);
																	}}
																/>
																<Button disabled>Vecka</Button>
																<Button
																	icon={ChevronRightMinor}
																	onClick={() => {
																		onNavigate("NEXT", date);
																	}}
																/>
															</ButtonGroup>
														</Wrapper>
													);
												},
											}}
											selectable
											step={30}
											defaultView={Views.WEEK}
											onRangeChange={this.rangeChange.bind(this)}
											localizer={localizer}
											events={this.state.events}
											onSelectEvent={this.editEvent.bind(this)}
											onSelectSlot={this.handleSelect.bind(this)}
											views={{ week: true }}
											eventPropGetter={this.eventPropGetter.bind(this)}
											messages={{ today: false, previous: <Icon source={ChevronLeftMinor} />, next: <Icon source={ChevronRightMinor} /> }}
										/>
									</div>
								) : null}
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue ? (
						<Layout.AnnotatedSection
							title="Undantag för notiser vid missad tidregistrering"
							description={
								<div>
									Här bestämmer du vilka dagar användaren <u>inte</u> skall få några notiser vid missad tidregistrering. Inga notiser skickas på
									lördagar och söndagar.
								</div>
							}
						>
							<Card sectioned>
								<FormLayout>
									<Checkbox
										checked={this.state.form.days_of_no_notification && this.state.form.days_of_no_notification.monday}
										onChange={(val) => {
											this.state.form.days_of_no_notification.monday = val;
											this.setState({ form: this.state.form });
										}}
										label="Måndag"
									/>
									<Checkbox
										checked={this.state.form.days_of_no_notification && this.state.form.days_of_no_notification.tuesday}
										onChange={(val) => {
											this.state.form.days_of_no_notification.tuesday = val;
											this.setState({ form: this.state.form });
										}}
										label="Tisdag"
									/>
									<Checkbox
										checked={this.state.form.days_of_no_notification && this.state.form.days_of_no_notification.wednesday}
										onChange={(val) => {
											this.state.form.days_of_no_notification.wednesday = val;
											this.setState({ form: this.state.form });
										}}
										label="Onsdag"
									/>
									<Checkbox
										checked={this.state.form.days_of_no_notification && this.state.form.days_of_no_notification.thursday}
										onChange={(val) => {
											this.state.form.days_of_no_notification.thursday = val;
											this.setState({ form: this.state.form });
										}}
										label="Torsdag"
									/>
									<Checkbox
										checked={this.state.form.days_of_no_notification && this.state.form.days_of_no_notification.friday}
										onChange={(val) => {
											this.state.form.days_of_no_notification.friday = val;
											this.setState({ form: this.state.form });
										}}
										label="Fredag"
									/>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
				</Layout>
				<Modal
					open={this.state.showCreateEventModal}
					onClose={this.closeEventModal.bind(this)}
					title={this.state.editingEvent ? "Ändra arbetstid" : "Lägg till arbetstid"}
					secondaryActions={modalActions}
					primaryAction={{
						content: this.state.editingEvent ? "Spara" : "Lägg till",
						loading: this.state.saving,
						onAction: this.saveWorkTime.bind(this),
					}}
				>
					<Modal.Section>
						<div style={{ marginTop: "-1.25rem", paddingBottom: "1rem" }}>
							{this.state.weekdays.map((weekday, key) => (
								<Badge key={key}>{weekday}</Badge>
							))}
						</div>
						<FormLayout>
							<ChoiceList
								title=""
								titleHidden
								choices={[
									{ label: "Arbetstid", value: "work" },
									{ label: "Flextid", value: "flex" },
									{ label: "Lunchtid", value: "lunch" },
								]}
								selected={this.state.type}
								onChange={(value) => {
									this.setState({ type: value[0] });
								}}
							/>
							<Stack distribution="fillEvenly">
								<TimePicker
									label="Från klockan"
									placeholder="Välj klockslag"
									value={this.state.from_time}
									onChange={(value) => {
										this.setState({ from_time: value });
									}}
								/>
								<TimePicker
									label="Från klockan"
									placeholder="Välj klockslag"
									value={this.state.to_time}
									onChange={(value) => {
										this.setState({ to_time: value });
									}}
								/>
							</Stack>
							<ChoiceList
								title=""
								titleHidden
								choices={[
									{ label: "Upprepa inte", value: "single" },
									{ label: "Upprepa varje vecka", value: "weekly" },
									{ label: "Upprepa varannan vecka", value: "biweekly" },
								]}
								selected={this.state.recurring}
								onChange={(value) => {
									this.setState({ recurring: value[0] });
								}}
							/>
							<Checkbox
								checked={this.state.repeat_holidays}
								onChange={(val) => {
									this.setState({ repeat_holidays: !this.state.repeat_holidays });
								}}
								label="Upprepa även på helgdagar"
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showRemoveEventModal}
					onClose={this.closeRemoveEventModal.bind(this)}
					title="Ta bort arbetstid"
					secondaryActions={[
						{
							content: "Close",
							onAction: this.closeRemoveEventModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeWorkTime.bind(this),
					}}
				>
					<Modal.Section>
						{this.state.editingEvent && this.state.editingEvent.recurring ? (
							<ChoiceList
								title=""
								titleHidden
								choices={[
									{ label: "Ta bort alla", value: "all" },
									{ label: "Ta endast bort denna", value: "this" },
									{ label: "Ta bort denna och kommande dagar", value: "future" },
								]}
								selected={this.state.selectedRemoveOption}
								onChange={(value) => {
									this.setState({ selectedRemoveOption: value });
								}}
							/>
						) : (
							"Är du säker på att du vill ta bort denna arbetstid?"
						)}
					</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort användare?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeItem.bind(this),
					}}
				>
					<Modal.Section>Är du säker? Alla registreringar kopplade till den här användaren kommer att tas bort</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showFlexModal}
					onClose={this.closeFlexModal.bind(this)}
					title="Justera flexsaldo"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeFlexModal.bind(this),
						},
						{
							content: "Nollställ",
							loading: this.state.saving,
							destructive: true,
							onAction: this.resetFlex.bind(this),
						},
					]}
					primaryAction={{
						content: "Justera",
						loading: this.state.saving,
						disabled: !this.state.flex_adjustment,
						onAction: this.adjustFlex.bind(this),
					}}
				>
					<Modal.Section>
						<TextField
							type="number"
							label="Justering (anges i antalet minuter)"
							value={this.state.flex_adjustment}
							onChange={(val) => {
								this.setState({ flex_adjustment: val });
							}}
						/>
					</Modal.Section>
				</Modal>
				<PageActions
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Spara",
									loading: this.state.saving,
									onAction: this.saveForm.bind(this),
							  }
					}
					secondaryActions={
						store.getState().user.read_only
							? []
							: [
									{
										content: "Ta bort",
										destructive: true,
										disabled: !(store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0),
										loading: this.state.saving,
										onAction: this.removeModal.bind(this),
									},
							  ]
					}
				/>
				<TimeReportModal
					open={this.state.showTimeReportModal}
					onClose={this.closeTimeReportModal.bind(this)}
					onComplete={(item) => {
						this.closeTimeReportModal();
					}}
					user_id={this.state.id}
					user_name={this.state.form.username}
					lock_user
				/>

				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
					weeks
				/>
				<TimeRangeModal
					open={this.state.flexTimeRangeModalOpen}
					onClose={this.closeFlexTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printFlexRange.bind(this)}
				/>
			</Page>
		);
	}
}

export default UserEdit;

import React, { useRef } from "react";
import Moment from "react-moment";

import IndexTable from "src/js/components/IndexTable";
import { store } from "src/js/store";
import Badge from "src/js/components/Badge";

type FortnoxOffersIndexProps = {
	history?: any;
	project?: any;
	customer?: any;
	fixedParams?: any;
	onClickRow?: (item: any) => void;
};

const FortnoxOffersIndex = ({ history, project, customer, fixedParams, onClickRow }: FortnoxOffersIndexProps) => {
	const refresh = useRef<any>(null);

	const filters = [
		{
			key: "filter",
			label: "Status",
			operatorText: "är",
			type: "select",
			options: [
				// { label: "Skickad", value: "sent" },
				{ label: "ej accepterad", value: "ordernotcreated" },
				{ label: "accepterad", value: "ordercreated" },
			],
		},
		{
			key: "sent",
			label: "Status",
			operatorText: "är",
			type: "select",
			options: [
				// { label: "Skickad", value: "sent" },
				{ label: "ej skickad", value: 0 },
				{ label: "skickad", value: 1 },
			],
		},
		{
			key: "start_at_period",
			label: "Förväntat startdatum",
			type: "select",
			options: [
				{ label: "Nästa månad", value: "next_month" },
				{ label: "Denna månad", value: "this_month" },
			],
		},
	];

	const promotedBulkActions = [];

	const columns = [
		{
			handle: "DATE",
			label: "Datum",
			sortable: true,
			type: "text",
			render: (item) => {
				return (
					<Moment locale="sv" format="YYYY-MM-DD">
						{item.OfferDate}
					</Moment>
				);
			},
		},
		{
			handle: "DOCUMENTNUMBER",
			label: "Offert nummer",
			sortable: true,
			type: "text",
			render: (item) => {
				return item.DocumentNumber;
			},
		},
		{
			handle: "CUSTOMER",
			label: "Kund",
			sortable: true,
			type: "text",
			render: (item) => {
				return item.CustomerName;
			},
		},
		{
			handle: "STATUS",
			label: "Status",
			sortable: true,
			type: "text",
			render: (item) => {
				if (item.OrderReference) {
					return (
						<Badge status="success" key="status" progress={100}>
							Fakturerad
						</Badge>
					);
				}

				if (item.Sent) {
					return (
						<Badge key="status" progress={50}>
							Skickad
						</Badge>
					);
				} else {
					return (
						<Badge key="status" status="critical">
							Ej skickad
						</Badge>
					);
				}
			},
		},
	];
	return (
		<div>
			<IndexTable
				limit={10}
				idHandle="DocumentNumber"
				setRefreshHandler={(refreshHandler) => {
					refresh.current = refreshHandler;
				}}
				history={history}
				title="Offert"
				primaryAction={
					store.getState().user.read_only
						? null
						: {
								content: "Ny offert",
								onAction: () => {
									history.push({
										pathname: "/admin/quotes/new",
										state: {
											customer,
											project,
										},
									});
								},
						  }
				}
				defaultSort="documentnumber_descending"
				defaultSavedSearches={[
					{
						title: "Skickade",
						filters: [
							{
								key: "sent",
								value: 1,
							},
						],
						search: "",
						id: "default_sent",
					},
					{
						title: "Ej skickade",
						filters: [
							{
								key: "sent",
								value: 0,
							},
						],
						search: "",
						id: "default_not_sent",
					},
					{
						title: "Accepterade",
						filters: [
							{
								key: "filter",
								value: "ordercreated",
							},
						],
						search: "",
						id: "default_accepted",
					},
					{
						title: "Ej accepterade",
						filters: [
							{
								key: "filter",
								value: "ordernotcreated",
							},
						],
						search: "",
						id: "default_not_accepted",
					},
				]}
				savedSearchHandle="quotes"
				// resourceUrl={`/api/quotes.json${this.props.customer ? `?customer_id=${this.props.customer.id}` : ""}`}
				resourceUrl="/api/fortnox/quotes"
				resourceHandle="quotes"
				resourceName={{
					singular: "offert",
					plural: "offerter",
				}}
				params={{
					...(fixedParams || {}),
					project: project?.reference,
					customernumber: customer?.customer_id,
				}}
				filters={filters}
				onClickRow={(item) => {
					if (onClickRow) {
						return onClickRow(item);
					}
					history.push("/admin/quotes/" + item.DocumentNumber);
				}}
				columns={columns}
				promotedBulkActions={promotedBulkActions}
			/>
		</div>
	);
};

export default FortnoxOffersIndex;

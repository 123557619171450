import { Button, Card, Checkbox, Modal, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import ImageThumbnail from "src/js/components/ImageThumbnail";
import ImageCarousel from "../../components/ImageCarousel";
// import ImageThumbnail from "../../components/ImageThumbnail";
import { ImageThumbnailsFlexWrapper } from "../../components/StyledComponents";
import { toastr } from "../../components/toastr";
import Upload from "../../components/Upload";
import API from "../../API";

export default class WorkOrderUploadSection extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	openUpload() {
		this.setState({ openUpload: true });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	onOpen() {
		this.setState({ open: true });
	}

	onClose() {
		this.setState({ open: false, upload: null });
	}

	onSave(upload) {
		this.setState({ loading: true });
		if (!upload.id) {
			upload.title = upload.name || "Ny fil";
			upload.private = true;
		}
		upload.work_order = this.props.workOrder;
		upload.work_order_id = this.props.workOrder && this.props.workOrder.id;
		upload.project_id = this.props.project && this.props.project.id;

		API.put(`/api/work_orders/${this.props.workOrder.id}/upload.json`, { uploads: [upload] })
			.then((result) => {
				if (result.data.error) {
					return;
				}

				if (upload.id) {
					toastr.success("Uppladning uppdaterad");
					if (this.props.onChange && result.data.uploads) {
						this.props.onChange(result.data.uploads[0]);
						this.onClose();
					}
				} else {
					toastr.success("Uppladning klar");
					if (this.props.onAddUpload && result.data.uploads) {
						this.props.onAddUpload(result.data.uploads[0]);
						this.onClose();
					}
				}
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	onRemove() {
		this.setState({ removing: true });
		API.delete(`/api/uploads/${this.state.upload.id}.json`, { upload: this.state.upload })
			.then((result) => {
				if (result.data.error) {
					return;
				}

				if (result.data.upload) {
					toastr.success("Uppladning borttagen");
					if (this.props.onRemove && result.data.upload) {
						this.props.onRemove(this.state.upload);
						this.onClose();
					}
				}
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	onChange(field, value) {
		if (!this.state.upload) {
			this.state.upload = {};
		}
		this.state.upload[field] = value;
		this.setState({ upload: this.state.upload });
	}

	openEdit(upload) {
		this.setState({ open: true, upload: JSON.parse(JSON.stringify(upload)) });
	}

	// openImageInCarousel(index) {
	// 	this.setState({ carouselIsOpen: true, carouselIndex: index });
	// }

	closeCarousel(index) {
		this.setState({ carouselIsOpen: false, carouselIndex: null });
	}

	render() {
		const { disabled } = this.props;

		return (
			<Wrapper>
				<Card.Section
					title={
						!this.props.hideTitle && this.props.workOrder.label
						// <Link
						// 	to={{
						// 		pathname: `/admin/work_orders/${this.props.workOrder.id}`,
						// 		state: {
						// 			project: this.state.form,
						// 			selectedTab: 2,
						// 		},
						// 	}}
						// >
						// 	{this.props.workOrder.label}
						// </Link>
					}
					sectioned
					actions={this.props.onAddUpload ? [{ content: "Ladda upp", onAction: this.openUpload.bind(this), loading: this.state.loading }] : null}
				>
					<Upload
						files={[]}
						width={400}
						height={200}
						open={this.state.openUpload}
						onUpload={this.onSave.bind(this)}
						accept="application/pdf"
						type="file"
						onClose={() => {
							this.setState({ openUpload: false });
						}}
					/>

					<ImageThumbnailsFlexWrapper>
						{this.props.uploads &&
							this.props.uploads.map((upload, index) => {
								return (
									<ImageThumbnail
										// onClick={this.openImageInCarousel.bind(this, index)}
										key={upload.url || index}
										upload={upload}
										onEdit={this.openEdit.bind(this, upload)}
									/>
								);
							})}
						{(!this.props.uploads || !this.props.uploads.length) && <p>Inga dokument..</p>}
					</ImageThumbnailsFlexWrapper>
					<Modal
						title={this.state.upload && this.state.upload.id ? `Redigera ${this.state.upload.title}` : "Ladda upp nytt dokument"}
						open={this.state.open}
						onClose={this.onClose.bind(this)}
						primaryAction={{
							content: "Spara",
							onAction: this.onSave.bind(this, this.state.upload),
							loading: this.state.loading,
							disabled: !this.state.upload,
						}}
						secondaryActions={[
							{
								content: "Avbryt",
								onAction: this.onClose.bind(this),
							},
						].concat(
							this.state.upload && this.state.upload.id
								? [
										{
											content: "Ta bort",
											onAction: this.onRemove.bind(this),
											loading: this.state.removing,
											destructive: true,
										},
								  ]
								: []
						)}
					>
						<Modal.Section>
							{/* {this.state.upload && <ImageThumbnail upload={this.state.upload} />} */}
							{(!this.state.upload || !this.state.upload.id) && <Button onClick={this.openUpload.bind(this)}>Välj dokument</Button>}
							<TextField label="Titel" onChange={this.onChange.bind(this, "title")} value={this.state.upload && this.state.upload.title} />
							{this.state.upload && (
								<Checkbox
									checked={this.state.upload.private}
									label="Privat (syns endast för admin)"
									onChange={this.onChange.bind(this, "private")}
									disabled={disabled || !this.props.onChange}
								/>
							)}
						</Modal.Section>
					</Modal>

					<ImageCarousel
						title={`${this.props.workOrder.label} - Bilder`}
						images={this.props.uploads}
						open={this.state.carouselIsOpen}
						index={this.state.carouselIndex}
						onClose={this.closeCarousel.bind(this)}
					/>
				</Card.Section>
			</Wrapper>
		);
	}
}

export const Wrapper = styled.div`
	.Polaris-Card__SectionHeader {
		.Polaris-Button.Polaris-Button--plain {
			background: var(--accent);
			color: var(--color-dark);
			text-decoration: none;
			padding: calc((2.25rem - var(--p-font-line-height-2) - var(--p-space-05)) / 2) var(--p-space-4);

			&:hover {
				background: var(--accent-hover);
				border-color: var(--accent-hover);
			}
		}
	}
`;

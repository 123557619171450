import React, { useRef } from "react";
import Colors from "src/js/Colors";
import { currencyFormatter } from "src/js/Utilities";
import Badge from "src/js/components/Badge";
import IndexTable from "src/js/components/IndexTable";

const FortnoxSupplierinvoices = ({ history }) => {
	const refresh = useRef<any>(null);
	const ref = useRef<any>(null);

	const columns = [
		{
			handle: "ID",
			label: "Id",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.GivenNumber;
			},
		},
		{
			handle: "SUPPLIER_NR",
			label: "Leverantörsnr",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.SupplierNumber;
			},
		},
		{
			handle: "SUPPLIER_NAME",
			label: "Namn",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.SupplierName;
			},
		},
		{
			handle: "INVOICE_NR",
			label: "fakturanr",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.InvoiceNumber;
			},
		},
		{
			handle: "STATUS",
			label: "Status",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				const status = (() => {
					switch (item.external_invoice?.status) {
						case "pending":
							return {
								label: "Påbörjad",
								color: Colors.yellow,
							};
						case "completed":
							return {
								label: "Klar",
								color: Colors.green,
							};
						default:
							return {
								label: "Ej påbörjad",
								color: Colors.red,
							};
					}
				})();

				const { label, color } = item.Total !== "0" ? status : { label: "Klar", color: Colors.green };
				return <Badge color={color}>{label}</Badge>;
			},
		},
		{
			handle: "INVOICE_DATE",
			label: "Fakturadatum",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.InvoiceDate;
			},
		},
		{
			handle: "DUE_DATE",
			label: "Förfallodatum",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.DueDate;
			},
		},
		{
			handle: "T0TAL",
			label: "Totalt",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return currencyFormatter({ value: item.Total });
			},
		},
		{
			handle: "FINAL_PAY_DATE",
			label: "Slutbetald",
			render: (item: SimpleFortnoxSupplierInvoice) => {
				return item.FinalPayDate;
			},
		},
	];

	return (
		<IndexTable
			title="Leverantörsfakturor ifrån Fortnox"
			columns={columns}
			history={history}
			onClickRow={(item: SimpleFortnoxSupplierInvoice) => {
				history.push("/admin/supplier_invoices/" + item.GivenNumber, { invoice: item });
			}}
			defaultSort="_DESC"
			setRefreshHandler={(refreshHandler) => {
				// eslint-disable-next-line react/no-unused-class-component-methods
				refresh.current = refreshHandler;
			}}
			ref={ref}
			savedSearchHandle="supplier_invoices"
			resourceUrl="/api/fortnox/supplier_invoices.json"
			resourceHandle="SupplierInvoices"
			resourceName={{
				singular: "leverantörsfaktura",
				plural: "leverantörsfakturor",
			}}
		/>
	);
};
export default FortnoxSupplierinvoices;

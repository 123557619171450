/* eslint-disable no-unused-vars */
import { Modal, DatePicker, Stack, ChoiceList, Checkbox, FormLayout, TextField, Select, Breadcrumbs, HorizontalStack } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { store } from "../store";
import ResourcePicker from "./resource_picker.js";
import TimePicker from "./time_picker.js";
import ExpenseReportModal from "./ExpenseReportModal.js";
import { toastr } from "./toastr";
import API from "../API";
import { getTypeLabel } from "../Utilities";

const SimpleTimeReportEditModal = ({ timereport = {}, open, onClose, onDelete, onComplete }) => {
	const [form, setForm] = useState(timereport || {});
	const [datePickerOptions, setDatePickerOptions] = useState({});
	const [loading, setLoading] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isAttesting, setIsAttesting] = useState(false);

	const fetchTimeReport = useCallback((id) => {
		setLoading(true);
		API.get("/api/time_reports/" + id + ".json")
			.then((result) => {
				setForm({
					...result.data.time_report,
					hours: minutesToHours(result.data.time_report.minutes),
					ob_hours: minutesToHours(result.data.time_report.ob_minutes),
					ob1_hours: minutesToHours(result.data.time_report.ob1),
					ob2_hours: minutesToHours(result.data.time_report.ob2),
					ob3_hours: minutesToHours(result.data.time_report.ob3),
				});

				setLoading(false);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}, []);

	const handleDelete = useCallback(async () => {
		setIsRemoving(true);

		try {
			await API.delete("/api/time_reports/" + form.id + ".json").then((result) => {
				toastr.success("Tidrapporten togs bort");
				onDelete(result.data.time_report);
			});
		} catch (error) {
			toastr.error(error);
		}

		setIsRemoving(false);
	}, [form?.id, onDelete]);

	const handleSave = useCallback(async () => {
		setIsSaving(true);

		try {
			await API.put("/api/time_reports/" + form.id + ".json", form).then((result) => {
				toastr.success("Tidrapporten uppdaterad");
				onComplete(result.data.time_report);
			});
		} catch (error) {
			toastr.error(error);
		}

		setIsSaving(false);
	}, [form, onComplete]);

	const handleAttest = useCallback(async () => {
		setIsAttesting(true);

		try {
			await API.put("/api/time_reports/" + form.id + "/attest.json", form).then((result) => {
				toastr.success("Tidrapporten attesterad");
				onComplete(result.data.time_report);
			});
		} catch (error) {
			toastr.error(error);
		}

		setIsAttesting(false);
	}, [form, onComplete]);

	useEffect(() => {
		setForm({ ...(timereport || {}), hours: 0, ob_hours: 0, ob1_hours: 0, ob2_hours: 0, ob3_hours: 0 });
		setDatePickerOptions({
			year: moment(timereport?.date).year(),
			month: moment(timereport?.date).month(),
			selected: { start: moment(timereport?.checkin_at).toDate(), end: moment(timereport?.checkout_at).toDate() },
		});
		if (timereport?.id) fetchTimeReport(timereport?.id);
	}, [timereport, fetchTimeReport]);

	const minutesToHours = (minutes) => {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60;
			} else {
				const mins = minutes % 60;
				const hours = (minutes - mins) / 60;
				return hours + "." + mins;
			}
		} else {
			return "0." + minutes;
		}
	};

	const getViewTitle = (view) => {
		const items = [
			{ label: "Maskin", value: "machine" },
			{ label: "Arbetad tid", value: "project" },
			{ label: getTypeLabel("photo"), value: "photo" },
			{ label: getTypeLabel("divergence"), value: "divergence" },
			{ label: getTypeLabel("expense"), value: "expense" },
			{ label: getTypeLabel("sick"), value: "sick" },
			{ label: getTypeLabel("vab"), value: "vab" },
			{ label: getTypeLabel("shorttime"), value: "shorttime" },
			{ label: getTypeLabel("parenting"), value: "parenting" },
			{ label: getTypeLabel("vacation"), value: "vacation" },
			{ label: getTypeLabel("service_free"), value: "service_free" },
			{ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" },
			{ label: getTypeLabel("birth_free"), value: "birth_free" },
			{ label: getTypeLabel("comp_free"), value: "comp_free" },
			{ label: getTypeLabel("weekend_salary"), value: "weekend_salary" },
		];

		return items.find((item) => item.value == view)?.label;
	};

	const handleChangeCost = useCallback((value) => setForm((c) => ({ ...c, cost: value })), [setForm]);
	const handleChangeComment = useCallback((value) => setForm((c) => ({ ...c, comment: value })), [setForm]);
	const handleChangeObHours = useCallback((value) => setForm((c) => ({ ...c, ob_hours: value })), [setForm]);
	const handleChangeHours = useCallback((value) => setForm((c) => ({ ...c, hours: value })), [setForm]);
	const handleChangeWorkOrder = useCallback((value) => setForm((c) => ({ ...c, work_order: value })), [setForm]);
	const handleChangeFromTime = useCallback((value) => setForm((c) => ({ ...c, from_time: value })), [setForm]);
	const handleChangeToTime = useCallback((value) => setForm((c) => ({ ...c, to_time: value })), [setForm]);
	const handleChangeWholeDay = useCallback((value) => setForm((c) => ({ ...c, whole_day: value })), [setForm]);

	const disabled = false;

	return (
		<Modal
			title="Redigera tid registering"
			open={open}
			onClose={onClose}
			secondaryActions={[
				{
					content: "Ta bort",
					onAction: handleDelete,
					destructive: true,
					loading: isRemoving,
				},
				{
					content: "Stäng",
					onAction: onClose,
				},
				{
					content: "Acceptera",
					onAction: handleAttest,
					loading: isAttesting,
				},
			]}
			primaryAction={{
				content: "Spara",
				onAction: handleSave,
				loading: isSaving,
			}}
		>
			<Modal.Section>
				<FormLayout>
					<DatePicker
						disabled={disabled}
						month={datePickerOptions.month}
						year={datePickerOptions.year}
						weekStartsOn={1}
						onChange={(value) => {
							setDatePickerOptions((c) => ({ ...c, selected: value }));
						}}
						onMonthChange={(month, year) => {
							setDatePickerOptions((c) => ({ ...c, month, year }));
						}}
						selected={datePickerOptions.selected}
						multiMonth={false}
						allowRange={false}
						disableDatesAfter={new Date()}
					/>

					{form.type != "project" && (
						<FormLayout>
							<Checkbox checked={form.whole_day} disabled={disabled} onChange={handleChangeWholeDay} label="Hela dagen" />
						</FormLayout>
					)}
					{!form.whole_day &&
						(form.report_type != "hours" ? (
							<FormLayout>
								<Stack distribution="fillEvenly">
									<TextField disabled={disabled} value={form.from_time} onChange={handleChangeFromTime} label="Från tid" type="time" />
									<TextField disabled={disabled} value={form.to_time} onChange={handleChangeToTime} label="Till tid" type="time" />
								</Stack>
							</FormLayout>
						) : (
							<FormLayout>
								<Stack distribution="fillEvenly">
									{form.type == "weekend_salary" && (
										<ResourcePicker
											label="Arbetsorder"
											placeholder="Sök Arbetsorder"
											caption="Välj Arbetsorder"
											resource="work_orders.json"
											resourceHandle="work_orders"
											label_handle="full_label"
											resourceName={{
												singular: "arbetsorder",
												plural: "arbetsorder",
											}}
											item={form.work_order}
											onChange={handleChangeWorkOrder}
										/>
									)}
									<TimePicker label="Timmar" value={form.hours + ""} onChange={handleChangeHours} hours disabled={form.type == "weekend_salary"} />

									<TimePicker
										label="OB Timmar"
										value={form.ob_hours + ""}
										onChange={handleChangeObHours}
										hours
										disabled={form.type == "weekend_salary"}
									/>
								</Stack>
							</FormLayout>
						))}

					<TextField label="Lönekostnad inkl avgifter" value={form.cost + ""} onChange={handleChangeCost} />
					<TextField label="Kommentar" value={form.comment} onChange={handleChangeComment} multiline={4} />
				</FormLayout>
			</Modal.Section>

			<Modal.Section>
				<FormLayout>
					<TextField value={form.user?.name} label="Användaren" disabled />
					<TextField value={getViewTitle(form.type)} label="Typ" disabled />
					{form.created_at != form.updated_at && <TextField value={moment(form.updated_at).format("YYYY-MM-DD HH:mm")} label="Uppdaterad" disabled />}
					<TextField
						value={`${moment(form.created_at).format("YYYY-MM-DD HH:mm")} ${form.created_by ? `av ${form.created_by?.name}` : ""}`}
						label="Skapad"
						disabled
					/>
					{form.attest_at && form.attest_user && form.attest_user.name && (
						<TextField value={`${form.attest_user.name} ${moment(form.created_at).format("YYYY-MM-DD HH:mm")}`} label="Attesterad av" disabled />
					)}
					{form.export && form.export_at && (
						<TextField value={moment(form.export_at).format("YYYY-MM-DD HH:mm")} label="Exporterad till Agresso" disabled />
					)}
					{form.type == "project" && (
						<TextField value={form.work_order && form.work_order.project ? form.work_order.project.full_label : "–"} label="Projekt" disabled />
					)}
					{form.type == "project" && <TextField value={form.work_order ? form.work_order.full_label : "–"} label="Arbetsorder" disabled />}
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
};
export default SimpleTimeReportEditModal;

import { ActionListItemDescriptor, Button, Popover } from "@shopify/polaris";
import React, { useState } from "react";
import styled from "styled-components";
import ActionButton from "./ActionButton";

type ActionGroupProps = {
	title: string;
	icon?: any;
	actions: ActionListItemDescriptor[];
	loading?: boolean;
	disabled?: boolean;
	primary?: boolean;
	destructive?: boolean;
};

const ActionGroup = ({ title, icon, actions, loading, disabled, primary, destructive }: ActionGroupProps) => {
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	const handleToggle = () => {
		setOpen((c) => !c);
	};

	if (actions?.length === 1)
		return (
			<ActionButton
				// eslint-disable-next-line react/jsx-props-no-spreading
				item={{
					...actions[0],
					onAction: async () => {
						if (actions[0].onAction) await actions[0].onAction();
						handleClose();
					},
				}}
			/>
		);

	return (
		<Popover
			active={open}
			activator={
				<Button
					plain
					destructive={destructive}
					primary={primary && !destructive}
					disclosure={open ? "up" : "down"}
					onClick={handleToggle}
					loading={loading}
					disabled={disabled}
					icon={icon}
					removeUnderline
				>
					{title}
				</Button>
			}
			autofocusTarget="first-node"
			onClose={handleClose}
		>
			<ListWrapper
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{actions.map((action, index) => {
					return (
						<ActionButton
							key={action.id || index}
							// eslint-disable-next-line react/jsx-props-no-spreading
							item={{
								...action,
								onAction: async () => {
									if (action.onAction) await action.onAction();
									handleClose();
								},
							}}
						/>
					);
				})}
			</ListWrapper>

			{/* <ActionList onActionAnyItem={handleClose} actionRole="menuitem" items={actions} /> */}
		</Popover>
	);
};
export default ActionGroup;

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.2rem;
	& > .Polaris-Button {
		margin: 0;
		justify-content: start;

		/* &.Polaris-Button--plain:focus:not(:active):not(.Polaris-Button--destructive):focus {
			&:not(:hover) {
				color: var(--text-color);

				.Polaris-Icon svg {
					fill: var(--text-color);
				}
			}
		}

		&:not(.Polaris-Button--destructive):not(:active):hover {
			color: #5ac57d;

			.Polaris-Icon svg {
				fill: #5ac57d;
			}
		} */
	}

	& > .Polaris-Button:not(:first-child) {
		padding-block: 1rem;
	}
	& > .Polaris-Button:first-child {
		padding-bottom: 1rem;
	}

	.Polaris-Button {
		--p-color-icon-interactive: #ffffff;
		--p-color-icon-interactive-hover: var(--accent);
	}

	&,
	* {
		transition: color 100ms, fill 100ms;
	}

	.Polaris-Button--plain:hover {
		&:not(.Polaris-Button--destructive) {
			--color-primary: var(--accent);

			.Polaris-Button__Icon {
				--p-color-icon-interactive: var(--accent);
			}
		}

		&.Polaris-Button--destructive {
			--color-primary: var(--red);
			.Polaris-Button__Icon {
				--p-color-icon-interactive: var(--red);
			}
		}
	}
`;

import { ChoiceList, FormLayout } from "@shopify/polaris";
import React, { useState } from "react";
import API from "src/js/API";
import Modal from "src/js/components/modal";
import { toastr } from "src/js/components/toastr";

const BulkInvoiceModal = ({ open, onClose, ids, onSuccess, type }) => {
	const [selected, setSelected] = useState([]);
	const [isSaving, setIsSaving] = useState(false);

	const handleSetSelected = (value) => {
		setSelected(value);
	};

	const options = [
		{
			value: "1",
			label: "Fakturerad",
			statusMessage: {
				success: "Markerade som faktureade",
			},
		},
		{
			value: "2",
			label: "Ej fakturerad/Ska faktureras",
			statusMessage: {
				success: "Markerade som ej faktureade",
			},
		},
		{
			value: "3",
			label: "Ska ej fakturas",
			statusMessage: {
				success: "Markerade som ska ej faktureras",
			},
		},
	];

	const getData = () => {
		const s = selected[0];
		switch (parseInt(s)) {
			case 1:
				return { is_marked_as_invoiced: true };
			case 2:
				return { is_marked_as_invoiced: false, is_marked_as_should_be_invoiced: true };
			case 3:
				return { is_marked_as_should_be_invoiced: false, is_marked_as_invoiced: false };
			default:
				return {};
		}
	};

	const getStatusMessage = (key) => {
		return options.find((o) => o.value == selected[0])?.statusMessage[key];
	};

	const getPath = () => {
		switch (type) {
			case "time_reports":
				return "/api/time_reports.json";
			case "expense_reports":
				return "/api/expense_reports.json";
			case "quotes":
				return "/api/quotes.json";
			case "quote_rows":
				return "/api/quote_rows.json";
			case "supplier_invoice_rows":
				return "/api/supplier_invoice_rows.json";
			case "supplier_invoices":
				return "/api/supplier_invoices.json";
			default:
				return "";
		}
	};

	const handleSave = async () => {
		try {
			const path = getPath();
			const data = getData();
			if (!ids?.length) {
				throw new Error("Inga rader valda");
				return;
			}

			setIsSaving(true);

			const response = await API.put(path, {
				ids,
				data,
			});

			toastr.success(getStatusMessage("success"));
			const refreshEvent = new CustomEvent("refreshProjectStats");
			window.dispatchEvent(refreshEvent);
			onSuccess(response?.data);
		} catch (e) {
			toastr.error(e);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Modal
			sectioned
			open={open}
			onClose={onClose}
			title="Ändra faktueringsstatus"
			primaryAction={{
				content: "Spara",
				onAction: handleSave,
				loading: isSaving,
				disabled: !selected.length,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: onClose,
				},
			]}
		>
			<FormLayout>
				<ChoiceList title="Status" titleHidden onChange={handleSetSelected} choices={options} selected={selected} />
			</FormLayout>
		</Modal>
	);
};
export default BulkInvoiceModal;

import { Component } from "react";
import { Stack, Icon } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import { ExportMinor } from "@shopify/polaris-icons";
import ResourcePicker from "../../components/resource_picker.js";
import IndexTable from "../../components/IndexTable";
import BulkInvoiceModal from "../Invoices/BulkInvoiceModal";
import getInvoicedStatusColumn from "../Invoices/Fortnox/Utilities/getInvoicedStatusColumn";

class ExpenseReportsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project: props?.location?.state?.project || props.project || null,
			params: {},
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.project && this.props.project !== nextProps.project) {
			this.setState({ project: nextProps.project });
		}
	}

	updateParams(params) {
		this.setState({ params });
	}

	changeProject(item) {
		this.setState({ project: item });
	}

	exportExcel() {
		var params = Object.assign(this.state.params, {});
		if (this.state.project) {
			params.project_id = this.state.project.id;
		}
		
		Object.keys(params).forEach(key => {
			if (params[key] === undefined || params[key] === '') {
				delete params[key];
			}
		});

		window.open("/api/expense_reports/export.xls?" + httpBuildQuery(params));
	}

	goToExpenseReport(item) {
		var url = "/admin/expense_reports/" + item.id;
		this.props.history.push(url, { workOrder: this.props.workOrder });
	}

	handleOpenInvoiceStatusModal() {
		this.setState({ invoiceModalIsOpen: true });
	}

	handleCloseInvoiceStatusModal() {
		this.setState({ invoiceModalIsOpen: false });
	}

	render() {
		const promotedBulkActions = [
			{
				content: "Ändra faktureringsstatus",
				loading: this.state.loadingInvoicing,
				onAction: this.handleOpenInvoiceStatusModal.bind(this),
			},
		];
		var filters = [
			{
				key: "created_at",
				label: "Skapad",
				minKey: "created_at_min",
				maxKey: "created_at_max",
				dateOptionType: "past",
				type: "dateSelector",
			},
			{
				label: "Start datum",
				key: "created_at_min",
				type: "date",
			},
			{
				label: "Slut datum",
				key: "created_at_max",
				type: "date",
			},
		];

		const columns = [
			{
				handle: "TITLE",
				label: "Titel",
				sortable: true,
				type: "text",
				render: (item) => item.expense?.title,
			},
			{
				handle: "COST",
				label: "Kostnad",
				sortable: true,
				type: "text",
				render: (item) => item.amount + " kr",
			},
			{
				handle: "AMOUNT_TO_INVOICE",
				label: "Att vidarefakturera",
				sortable: true,
				type: "text",
				render: (item) => item.amount_to_invoice + " kr",
			},
			{
				handle: "USER",
				label: "Användare",
				sortable: true,
				type: "text",
				render: (item) => item.user && item.user.name,
			},
			{
				handle: "PROJECT",
				label: "Projekt",
				sortable: true,
				type: "text",
				render: (item) => item.project && item.project.title,
			},
			getInvoicedStatusColumn(),
		];

		return (
			<div>
				<IndexTable
					selectOnRow={this.props.selectOnRow}
					extraHeader={this.props.extraHeader}
					onSelectionChangeInclRow={this.props.onSelectionChangeInclRow}
					titleHidden={this.props.titleHidden}
					backAction={this.props.backAction}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					ref={(ref) => {
						this.indexTableRef = ref;
					}}
					title={this.props.title || (!this.props.workOrder && "Registrerade utgifter")}
					params={{
						...(this.props.fixedParams || {}),
						work_order_id: this.props.workOrder && this.props.workOrder.id,
						project_id: this.state.project && this.state.project.id,
					}}
					savedSearchHandle="expense_reports"
					resourceUrl="/api/expense_reports.json"
					primaryAction={{
						content: "Ny utgift",
						onAction: () => {
							this.props.history.push("/admin/expense_reports/new", {
								project: this.state.project,
							});
						},
					}}
					secondaryActions={[
						{
							content: (
								<Stack spacing="extraTight">
									<Icon source={ExportMinor} />
									<div>Exportera till excel</div>
								</Stack>
							),
							onAction: this.exportExcel.bind(this),
						},
					]}
					history={this.props.history}
					resourceHandle="expense_reports"
					filters={filters}
					resourceName={{
						singular: "utgift",
						plural: "utgifter",
					}}
					selector={
						!this.props.workOrder &&
						!this.props.project && (
							<ResourcePicker
								placeholder="Sök projekt"
								caption="Alla projekt"
								resource="projects"
								resourceName={{
									singular: "projekt",
									plural: "projekt",
								}}
								item={this.state.project ? this.state.project : null}
								onChange={this.changeProject.bind(this)}
							/>
						)
					}
					columns={columns}
					onUpdateParams={this.updateParams.bind(this)}
					onClickRow={this.goToExpenseReport.bind(this)}
					noTopPagination={this.props.workOrder}
					promotedBulkActions={promotedBulkActions}
					onResultFetched={this.props.onResultFetched}
				/>

				<BulkInvoiceModal
					type="expense_reports"
					ids={this.indexTableRef?.getSelection()}
					open={this.state.invoiceModalIsOpen}
					onClose={this.handleCloseInvoiceStatusModal.bind(this)}
					onSuccess={() => {
						this.handleCloseInvoiceStatusModal();
						this.refresh();
					}}
				/>
			</div>
		);
	}
}

export default ExpenseReportsIndex;

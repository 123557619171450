/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import { Button, Form, Select, TextField, Tooltip, ButtonGroup } from "@shopify/polaris";
import { CircleUpMajor, CircleDownMajor, DeleteMajor } from "@shopify/polaris-icons";
import { RGBToHex } from "../../../Colors";
import FormatBoldIcon from "../../../icons/FormatBoldIcon";
import FormatItalicIcon from "../../../icons/FormatItalicIcon";
import FormatSizeIcon from "../../../icons/FormatSizeIcon";

class ActiveObjectToolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fonts = [
			{ label: "Times New Roman", value: "Times New Roman" },
			{ label: "Arial", value: "Arial" },
			{ label: "Helvetica", value: "Helvetica" },
			{ label: "Lucida Console", value: "Lucida Console" },
			{ label: "Lucida Grande", value: "Lucida Grande" },
			{ label: "Geneva", value: "Geneva" },
			{ label: "Tahoma", value: "Tahoma" },
			{ label: "Interstate", value: "Interstate" },
			{ label: "Minion Pro", value: "Minion Pro" },
			{ label: "Myriad Pro", value: "Myriad Pro" },
		].sort((a, b) => a.label.localeCompare(b.label));
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keydown.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keydown.bind(this));
	}

	keydown(e) {
		switch (e.key) {
			case "Delete":
				this.remove();
				e.preventDefault();
				e.stopPropagation();
				break;
			case "Escape":
				this.props.canvas.canvas.discardActiveObject();
				e.preventDefault();
				e.stopPropagation();
				break;
			default:
				break;
		}
	}

	setColor(value) {
		this.props.activeObjects.forEach((ele) => {
			const type = ele.get("type");
			switch (type) {
				case "text":
				case "i-text":
				case "textbox":
					ele.set("fill", value);
					break;
				case "rect":
				case "line":
				case "circle":
					ele.set("stroke", value);
					break;
				default:
					break;
			}
		});
	}

	setBold() {
		const enabled = this.props.activeObjects[0].fontWeight === "bold";
		this.props.activeObjects.forEach((el) => {
			el.fontWeight = enabled ? "" : "bold";
		});
	}

	setItalic() {
		const enabled = this.props.activeObjects[0].fontStyle === "italic";
		this.props.activeObjects.forEach((el) => {
			el.fontStyle = enabled ? "" : "italic";
		});
	}

	setUnderline() {
		// this.props.canvas.canvas.getActiveObject().set("textDecoration", "underline");
		// this.props.canvas.canvas.getActiveObjects().forEach((el) => {
		// 	el.set("textDecoration", false ? "" : "underline");
		// 	el.textDecoration = false ? "" : "underline";
		// });

		const enabled = this.props.activeObjects[0].textDecoration === "underline";
		this.props.activeObjects.forEach((el) => {
			el.textDecoration = enabled ? "" : "underline";
		});
	}

	update(key, value) {
		switch (key) {
			case "color":
				this.setColor(value);
				break;
			case "underline":
				this.setUnderline();
				break;
			case "bold":
				this.setBold();
				break;
			case "italic":
				this.setItalic();
				break;
			case "fontFamily":
				this.setFontFamily(value);
				break;
			case "fontSize":
				this.setFontSize(value);
				break;
			default:
				break;
		}
		this.props.canvas.canvas.renderAll();
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	remove() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.remove(ele);
		});
	}

	bringUp() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.bringToFront(ele);
		});
	}

	bringDown() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.sendToBack(ele);
		});
	}

	setFontFamily(font) {
		this.props.addLastFontFamily(font);
		this.props.activeObjects.forEach((ele) => {
			ele.set("fontFamily", font);
		});
	}

	setFontSize(size) {
		this.props.addLastFontSize(size);
		this.props.activeObjects.forEach((ele) => {
			ele.set("fontSize", parseInt(size));
		});
	}

	render() {
		if (!this.props.activeObjects || !this.props.activeObjects[0]) return null;
		const ele = this.props.activeObjects[0];
		const type = ele.get("type");

		const color = (() => {
			switch (type) {
				case "text":
				case "i-text":
				case "textbox":
					return ele.get("fill");
				case "rect":
				case "line":
				case "circle":
					return ele.get("stroke");
				default:
					return "transparent";
			}
		})();

		return (
			<div className="editor__toolbar__active-object">
				<input type="color" id="color" name="color" onChange={(v) => this.update("color", v.target.value)} value={RGBToHex(color)} />
				{["i-text", "text", "textbox"].includes(type) && (
					<React.Fragment>
						{/* <Button onClick={this.update.bind(this, "underline")}>underline</Button> */}
						<FontSizeChanger value={ele.get("fontSize")} onChange={this.update.bind(this, "fontSize")} />
						<ButtonGroup>
							<ToolTipButton enabled={!!ele.get("fontWeight")} tooltip="Bold" image={<FormatBoldIcon />} onClick={this.update.bind(this, "bold")} />
							<ToolTipButton
								enabled={!!ele.get("fontStyle")}
								image={<FormatItalicIcon />}
								tooltip="Italic"
								onClick={this.update.bind(this, "italic")}
							/>
						</ButtonGroup>
						<Select value={this.props.activeObjects[0].fontFamily} options={this.fonts} onChange={(v) => this.update("fontFamily", v)} />
					</React.Fragment>
				)}
				<ButtonGroup>
					<ToolTipButton tooltip="Flytta fram" icon={CircleUpMajor} onClick={this.bringUp.bind(this)} />
					<ToolTipButton tooltip="Flytta bak" icon={CircleDownMajor} onClick={this.bringDown.bind(this)} />
				</ButtonGroup>
				<ToolTipButton className="remove-object-button" tooltip="Ta bort objekt" icon={DeleteMajor} destructive onClick={this.remove.bind(this)} />
			</div>
		);
	}
}
export default ActiveObjectToolbar;

const ToolTipButton = (props) => {
	return (
		<Tooltip content={props.tooltip}>
			<span className={(props.image ? "Polaris-Button--image-btn" : "") + (" " + props.className)}>
				<Button pressed={props.enabled} {...props}>
					{props.image}
				</Button>
			</span>
		</Tooltip>
	);
};

class FontSizeChanger extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { value, onChange } = this.props;

		const size = this.state.fontSize === null || this.state.fontSize === undefined ? value : this.state.fontSize;

		return (
			<span className="font-size__wrapper">
				<Form onSubmit={() => onChange(this.state.fontSize)}>
					<TextField
						name="font-size"
						value={String(size)}
						onChange={(v) => this.setState({ fontSize: v })}
						inputMode="numeric"
						prefix={<FormatSizeIcon />}
						// type="number"
						connectedRight={
							<Select
								value={String(size)}
								onChange={onChange}
								placeholder={String(size)}
								options={["7", "8", "9", "10", "11", "12", "13", "14", "18", "24", "36", "48", "64", "72", "96"].map((v) => ({
									label: v,
									value: v,
								}))}
							/>
						}
					/>
				</Form>
			</span>
		);
	}
}

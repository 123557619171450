import moment from "moment";
import { store } from "src/js/store";
import { Holiday } from "./types";

export const getHoliday = (date: Date): boolean => {
	return store.getState().holidays.find((holiday: Holiday) => holiday.date === moment(date).format("YYYY-MM-DD"));
};

export const getViewTitle = (view: string): string => {
	switch (view) {
		case "month":
			return "Månad";
		case "week":
			return "Vecka";
		case "day":
			return "Dag";
		case "agenda":
			return "Agenda";
		default:
			return view;
	}
};
export const getViewHeaderTitle = ({ view, date, start, end }: { view: string; date: any; start?: string; end?: string }): string => {
	switch (view) {
		case "month":
			return `${moment(date).format("MMMM")} ${moment(date).year()}`;
		case "week":
			return `${getViewTitle(view)} ${moment(date).format("MM")} ${moment(date).year()}`;
		case "day":
			return `${moment(date).format("dddd")} ${moment(date).format("DD")} ${moment(date).format("MMM")}`;
		case "agenda":
			return `${moment(start).format("YYYY-MM-DD")} - ${moment(end).format("YYYY-MM-DD")} `;
		default:
			return view;
	}
};

/* eslint-disable camelcase */
import { Button, FormLayout, Layout, Page, PageActions, Select, Tabs } from "@shopify/polaris";
import { MaximizeMinor } from "@shopify/polaris-icons";
import debounce from "lodash/debounce";
import React, { Component } from "react";
import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import Card from "src/js/components/Card";
import ResourcePicker from "../../components/resource_picker";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import Uploads from "../../components/Uploads";
import TotalCost from "./TotalCost";
import Map from "../../components/map";
import SearchField from "../../components/search_field";
import { store } from "../../store";
import QuoteTable from "./QuoteTable";
import QuoteRowEditor from "./QuoteRowEditor";
import PreviewPdfModal from "../../components/PreviewPdfModal";
import API from "../../API";

class QuoteCreate extends Component {
	constructor(props) {
		super(props);
		const project = props?.location?.state?.project;
		const quote = props.location && props.location.quote;
		const customer = props?.location?.state?.customer || (project && project.customer);
		this.state = {
			saving: false,
			selectedTab: 0,
			form: Object.assign(
				{
					status: "draft",
					rows: [{}],
					customer,
					project,
					reference: store.getState().user,
					valid_time: 30,
					address: customer && customer.address,
					longitude: customer && customer.longitude,
					latitude: customer && customer.latitude,
					payment_terms: (customer && customer.payment_terms) || 0,
					extra_hour_cost: (customer && customer.hourly_rate) || 0,
					extra_percentage: (customer && customer.purcharge_price_surcharge) || 0,
					variation: props?.location?.state?.variation || false,
				},
				quote || {}
			),
			page: 0,
		};

		if (customer) {
			this.fetchContacts(customer);
		}
		this.ref = React.createRef();
		this.leftWrapperRef = React.createRef();
		this.PreviewWrapperRef = React.createRef();
		this.requiredFields = [
			{ field: "title", label: "Namn" },
			{ field: "customer", label: "Kund" },
			{ field: "reference", label: "Referens" },
		];
		this.refreshPdfBounced = debounce(this.refreshPdf.bind(this), 1000, { maxWait: 3000, leading: false, trailing: true });
		this.tabs = [
			{
				id: "info",
				content: "Info",
				panelID: "info",
			},
			{
				id: "rader",
				content: "Rader",
				panelID: "Rader",
			},
			{
				id: "inledning/avslutning",
				content: "Inledning/Avslutning",
				panelID: "inledning/avslutning",
			},
			{
				id: "uploads",
				content: `Bilagor & Dokument ${this.state.form.uploads ? `(${this.state.form.uploads.length})` : ""}`,
				panelID: "uploads",
			},
		];
		this.onHashChange = this.handleHashChange.bind(this);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		window.removeEventListener("hashchange", this.HashChange);
	}

	componentDidMount() {
		this.refreshPdf();
		if (!this.props.location || !this.props.location.quote) this.fetchDefaultTimeArticle();
		this.interval = setInterval(this.refreshPdf.bind(this), 20 * 1000);

		window.addEventListener("hashchange", this.HashChange);
		this.onHashChange();
	}

	handleHashChange() {
		const hash = new URL(document.URL).hash.replace("#", "");
		// const tabIndex = this.tabs.findIndex((t) => t.id === hash);
		const index = this.tabs.findIndex((t) => t.id === hash);
		this.setState({ selectedTab: index > 0 ? index : 0 }, () => {
			if (!index) {
				const url = new URL(document.URL);
				url.hash = `${this.tabs[0].id}`;
				window.history.replaceState(null, null, url);
			}
		});
	}

	fetchDefaultTimeArticle() {
		API.get("/api/expenses.json?q=ARB&sort=ID_ASC&limit=1")
			.then((result) => {
				// eslint-disable-next-line no-self-assign
				// result.data.expenses[0].price = result.data.expenses[0].price;
				// eslint-disable-next-line react/no-unused-state
				// this.setState({
				// 	originalPrice: result.data.expenses && result.data.expenses[0] && result.data.expenses[0].price,
				// 	article: result.data.expenses[0],
				// });
				this.state.data = {
					row: {
						article: "ARB",
						a_price:
							this.state.form?.customer?.hourly_rate ||
							this.props.location?.state?.customer?.hourly_rate ||
							(result.data.expenses && result.data.expenses[0] && result.data.expenses[0].price) ||
							null,
						description: "Arbetad tid",
						unit: "tim",
					},
					index: 0,
				};
				this.state.form.rows = [this.state.data.row, {}];
				this.setState({ form: this.state.form, data: this.state.data });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	saveForm() {
		const missingFields = this.requiredFields && this.requiredFields.filter(({ field } = {}) => !this.state.form[field]);
		if (missingFields && missingFields.length) {
			toastr.warning(`Väligen fyll i ${missingFields.map((f) => f.label).join(", ")}.`);
			return;
		}

		if (!this.state.form.payment_terms) {
			this.state.form.payment_terms = 30;
		}
		if (!this.state.form.extra_percentage) {
			this.state.form.extra_percentage = 0;
		}
		this.state.form.auto_created = this.state.form.variation;

		this.setState({ saving: true });

		// eslint-disable-next-line dot-notation
		if (this.state.form.rows) this.state.form.rows = this.state.form.rows.filter((i) => i && (i["article"] || i["description"]));

		API.post("/api/quotes.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					return;
				}
				if (this.props.location && this.props.location.state && this.props.location.state.customer) {
					// this.props.history.push({
					// 	pathname: `/admin/customers/${this.props.location.state.customer.id}`,
					// 	state: {
					// 		selectedTab: 7,
					// 	},
					// });
					if (this.state.form.variation) {
						toastr.success(`ÄTA skapad med kund ${this.props.location.state.customer.name}`);
					} else {
						toastr.success(`Offert skapad med kund ${this.props.location.state.customer.name}`);
					}
				} else if (result.data.quote) {
					if (this.state.form.variation) {
						toastr.success("ÄTA skapad");
					} else {
						toastr.success("Offert skapad");
					}
				}

				if (result.data.quote && !this.state.form.variation) {
					this.props.history.push({
						pathname: `/admin/quotes/${result.data.quote.id}`,
						state: { quote: result.data.quote, project: this.props.location && this.props.location.state && this.props.location.state.project },
					});
				} else {
					this.props.history.push({
						pathname: `/admin/projects/${this.state.form.project.id}`,
					});
				}
				this.setState({ saving: false }, this.refreshPdf.bind(this));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	refreshPdf() {
		if (this.state.form.rows)
			this.state.form.rows = this.state.form.rows.map((row) => {
				return row;
			});
		API.post(`/api/quotes/preview.json`, Object.assign(this.state.form, { page: this.state.page }))
			.then((result) => {
				if (result.data.error) {
					return;
				}
				if (this.PreviewWrapperRef.current) {
					this.PreviewWrapperRef.current.innerHTML = result.data.header_html + result.data.quote_html + result.data.footer_html;
				}
				this.setState({
					vat: result.data.vat,
					grand_total: result.data.grand_total,
					subtotal: result.data.subtotal,
					oresavrundning: result.data.oresavrundning,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchContacts(customer) {
		API.get("/api/contacts.json?customer_id=" + customer.id)
			.then((result) => {
				if (result.data.contacts.length == 1) {
					this.state.form.contact = result.data.contacts[0];
					this.setState({ form: this.state.form });
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		if (field === "customer" && value) {
			this.state.form.customer = value;
			this.fetchContacts(value);
			if (!this.state.form.payment_terms || this.state.form.payment_terms === (this.state.form.customer && this.state.form.customer.payment_terms)) {
				this.state.form.payment_terms = value.payment_terms;
			}
			if (this.state.form.customer?.hourly_rate) this.state.form.hourly_rate = this.state.form.customer.hourly_rate;

			if (
				!this.state.form.extra_percentage ||
				this.state.form.extra_percentage === (this.state.form.customer && this.state.form.customer.extra_percentage)
			) {
				this.state.form.extra_percentage = value.purcharge_price_surcharge;
			}
			if (!this.state.form.address || this.state.form.address === (this.state.form.customer && this.state.form.customer.address)) {
				this.state.form.address = value.address;
				this.state.form.latitude = value.latitude;
				this.state.form.longitude = value.longitude;
			}
		}

		if (field === "extra_percentage") {
			// this.state.form[field] = Math.min(100, Math.max(value, 0));
			if (value <= 100) this.state.form[field] = value;
		} else {
			this.state.form[field] = value;
		}

		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	addUploads(value) {
		if (!this.state.form.uploads) {
			this.state.form.uploads = [value];
		} else {
			this.state.form.uploads.push(value);
		}

		this.setState({ form: this.state.form });
	}

	removeUpload(index) {
		this.state.form.uploads.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form });
	}

	removeRow(index) {
		this.state.form.rows.splice(index, 1);
		this.setState({ form: this.state.form }, this.refreshPdfBounced.bind(this));
	}

	onTabChange(tabIndex) {
		const tab = this.tabs[tabIndex];
		const hash = tab ? `#${tab.id}` : "";
		const url = new URL(document.URL);
		url.hash = `${hash}`;
		window.history.replaceState(null, null, url);

		this.setState({ selectedTab: tabIndex });
	}

	render() {
		const primaryAction = {
			content: this.state.form && this.state.form.variation ? "Skapa ÄTA" : "Skapa offert",
			loading: this.state.saving,
			onAction: this.saveForm.bind(this),
		};

		const fortnox_connection = store.getState().account.fortnox_connection;
		const fortnox_order_module = fortnox_connection && store.getState().account.fortnox_order_module;

		const tabs = this.tabs;

		const selectedTabId = tabs[this.state.selectedTab] && tabs[this.state.selectedTab].id;
		const locked = this.state.form.locked;

		return (
			<div className="Quote-Page Quote-Action-Create" ref={this.ref}>
				<Page
					fullWidth
					title={this.state.form && this.state.form.variation ? "Ny ÄTA" : "Ny offert"}
					backAction={{
						content: "Tillbaka",
						onAction: () => {
							this.props.history.goBack();
						},
					}}
					primaryAction={primaryAction}
					secondaryActions={
						!fortnox_order_module
							? [
									{
										icon: MaximizeMinor,
										content: "Förhandsgranska offert",
										onAction: () => this.setState({ previewPdf: true }),
									},
							  ]
							: []
					}
				>
					<Layout>
						<Layout.Section>
							<Tabs tabs={tabs} onSelect={this.onTabChange.bind(this)} selected={this.state.selectedTab} />
							<div ref={this.leftWrapperRef}>
								{selectedTabId === "info" && (
									<CardsInPageWrapper margin>
										<Layout>
											<Layout.Section oneHalf>
												<Card sectioned>
													<FormLayout>
														<TextField label="Namn" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} required />

														<ResourcePicker
															placeholder="Sök referens"
															caption="Välj referens"
															resource="users"
															resourceName={{
																singular: "användare",
																plural: "användare",
															}}
															item={this.state.form.reference}
															onChange={this.updateForm.bind(this, "reference")}
															label="Vår referens"
															required
															disabled={locked}
															textAlign="left"
															label_handle="name"
														/>

														<ResourcePicker
															placeholder="Sök kund"
															caption="Välj kund"
															resource="customers"
															resourceName={{
																singular: "kund",
																plural: "kunder",
															}}
															item={this.state.form.customer}
															onChange={this.updateForm.bind(this, "customer")}
															label="Kund"
															required
															label_handle="name"
															textAlign="left"
															disabled={
																locked ||
																(this.props.location &&
																	this.props.location.state &&
																	this.props.location.state.project &&
																	this.props.location.state.project.customer)
															}
														/>
														<SearchField
															label="Adress"
															resource="google_address"
															resourceName={{
																singular: " adress",
																plural: " adresser",
															}}
															onSelect={this.selectAddress.bind(this)}
															onChange={this.updateForm.bind(this, "address")}
															value={this.state.form.address}
															disabled={locked}
															required
														/>

														{this.state.form && this.state.form.address && (
															<div>
																{this.state.form && (this.state.form.latitude || this.state.form.longitude) && (
																	<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} />
																)}
															</div>
														)}
													</FormLayout>
												</Card>
											</Layout.Section>
											<Layout.Section oneHalf>
												<Card sectioned>
													<FormLayout>
														<Select
															label="Status"
															options={[{ label: "Utkast", value: "draft" }]}
															onChange={this.updateForm.bind(this, "status")}
															value={this.state.form.status}
															disabled
														/>
														<ResourcePicker
															placeholder="Sök kontaktperson"
															caption="Välj kontaktperson"
															resource={`contacts.json${this.state.form.customer ? `?customer_id=${this.state.form.customer.id}` : ""}`}
															resourceName={{
																singular: "kund",
																plural: "kunder",
															}}
															item={this.state.form.contact}
															onChange={this.updateForm.bind(this, "contact")}
															label="Kontaktperson"
															disabled={locked || !this.state.form.customer}
															textAlign="left"
															label_handle="name"
														/>

														<TextField
															label="Beskrivning"
															multiline
															value={this.state.form.note}
															onChange={this.updateForm.bind(this, "note")}
															disabled={locked}
														/>

														<TextField
															label="Giltighetstid"
															value={this.state.form.valid_time}
															onChange={this.updateForm.bind(this, "valid_time")}
															required
															suffix="dagar"
														/>
														<TextField
															label="Betalningsvillkor"
															value={this.state.form.payment_terms}
															onChange={this.updateForm.bind(this, "payment_terms")}
															required
															suffix="dagar"
														/>
														<TextField
															label="Extraarbete timkostnad"
															value={this.state.form.extra_hour_cost}
															onChange={this.updateForm.bind(this, "extra_hour_cost")}
															required
															type="number"
															suffix="kr"
														/>
														<TextField
															label="Påslag inköp (%)"
															value={this.state.form.extra_percentage}
															onChange={this.updateForm.bind(this, "extra_percentage")}
															required
															type="number"
															max="100"
															suffix="%"
														/>
													</FormLayout>
												</Card>
											</Layout.Section>
										</Layout>
									</CardsInPageWrapper>
								)}
								{selectedTabId === "rader" && (
									<>
										<div style={{ paddingBlock: "var(--p-space-5)" }}>
											<Button
												primary
												onClick={() => {
													this.setState({ rowModalIsOpen: true });
												}}
											>
												Lägg till artikel
											</Button>
										</div>
										<QuoteRowEditor
											open={this.state.rowModalIsOpen}
											onClose={() => {
												this.setState({ rowModalIsOpen: false, data: { row: {}, index: null } });
											}}
											data={this.state.data}
											onOnSaveRow={(row, index) => {
												if (this.state.form && !this.state.form.rows) {
													this.state.form.rows = [];
												}
												if (!Number.isInteger(index)) {
													this.state.form.rows.push(row);
												} else {
													this.state.form.rows[index] = row;
												}
												this.setState({ form: this.state.form, data: { row: {}, index: null }, rowModalIsOpen: false }, () =>
													this.refreshPdfBounced.bind(this)
												);
											}}
											// removeRow={this.removeRow.bind(this)}
										/>

										<Card title="Offertrader" sectioned background>
											<QuoteTable
												rows={((this.state.form && this.state.form.rows) || []).filter((row) =>
													Object.keys(row).some((key) => row[key] && Object.keys(row).length)
												)}
												onRowEdit={(row, index) => {
													this.setState({ data: { row, index }, rowModalIsOpen: true });
												}}
												removeRow={this.removeRow.bind(this)}
											/>
										</Card>
									</>
								)}

								{selectedTabId === "uploads" && (
									<Card sectioned>
										<Uploads
											files={this.state.form.uploads || []}
											hidden={false}
											onUpload={this.addUploads.bind(this)}
											onRemove={this.removeUpload.bind(this)}
											disabled={locked}
										/>
									</Card>
								)}
								{selectedTabId === "inledning/avslutning" && (
									<Card sectioned>
										<TextField
											multiline={5}
											label="Inledning"
											value={this.state.form.introduction}
											onChange={this.updateForm.bind(this, "introduction")}
											disabled={locked}
										/>
										<TextField
											multiline={5}
											label="Avslutning"
											value={this.state.form.closure}
											onChange={this.updateForm.bind(this, "closure")}
											disabled={locked}
										/>
									</Card>
								)}
								<TotalCost
									vat={this.state.vat}
									oresavrundning={this.state.oresavrundning}
									grand_total={this.state.grand_total}
									subtotal={this.state.subtotal}
								/>
								{selectedTabId != "rader" && (
									<div style={{ textAlign: "right" }}>
										<Button
											onClick={() => {
												this.setState({ selectedTab: 1 });
											}}
										>
											Ändra rader
										</Button>
									</div>
								)}
							</div>
						</Layout.Section>
						{/* {this.state.form.pdf_template && ( */}
						{!fortnox_order_module && (
							<PreviewPdfModal open={this.state.previewPdf} form={this.state.form} onClose={() => this.setState({ previewPdf: false })} />
						)}
					</Layout>
					<PageActions primaryAction={primaryAction} />
				</Page>
			</div>
		);
	}
}
export default QuoteCreate;

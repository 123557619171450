import { Popover, Button, ActionList } from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class PopupMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleClose() {
		this.setState({ open: false });
	}

	handleToggle() {
		this.setState((c) => ({ open: !c.open }));
	}

	render() {
		// const items = this.props.items.map(() => {

		// })
		return (
			<Popover
				active={this.state.open}
				onClose={this.handleClose.bind(this)}
				activator={
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<Button disabled={this.props.disabled} plain icon={MobileVerticalDotsMajor} onClick={this.handleToggle.bind(this)} />
					</div>
				}
				preferredAlignment="right"
			>
				<div onClick={this.handleClose.bind(this)}>
					<ActionList items={this.props.items} />
				</div>
			</Popover>
		);
	}
}
export default PopupMenu;

import { Component } from "react";
import { Modal, Badge, TextStyle, ProgressBar, Select, LegacyStack, VerticalStack } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import TimeRangeModal from "../../components/TimeRangeModal.js";
import IndexTable from "../../components/IndexTable";
import API from "../../API";
import { Title } from "../../components/IndexTable/styledComponents";

class WorkOrderIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changeStatusModalOpen: false,
			timeRangeModalOpen: false,
			newStatus: "",
			saving: false,
			params: {},
		};
	}

	showTime(minutes) {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60 + " tim";
			} else {
				var mins = minutes % 60;
				var hours = (minutes - mins) / 60;
				return hours + " tim, " + mins + " min";
			}
		} else {
			return minutes + " min";
		}
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printReports() {
		this.setState({ timeRangeModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	printRange(fromDate, toDate) {
		this.closeTimeRangeModal();
		var projectIds = this.state.selectedItems;
		var query = {
			ids: projectIds,
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
	}

	changeStatus() {
		this.setState({ changeStatusModalOpen: true });
	}

	doUpdateStatus() {
		this.setState({ saving: true });
		var projectIds = this.state.selectedItems;
		API.put("/api/work_orders/bulk_edit.json", { status: this.state.newStatus }, { params: { ids: projectIds } })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				this.refresh();
				this.setState({ changeStatusModalOpen: false });
				toastr.success("Arbetsorder ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	exportCurrentWorkOrders(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		window.open("/api/work_orders/export.xls?" + httpBuildQuery(params));
	}

	getStatus(item) {
		if (item.status == "done") {
			return (
				<Badge key="status" progress="partiallyComplete" status="attention">
					Färdig
				</Badge>
			);
		} else if (item.status == "active") {
			return (
				<Badge key="status" status="info">
					Aktiv
				</Badge>
			);
		} else if (item.status == "pending") {
			return <Badge key="status">Kommande</Badge>;
		} else if (item.status == "sleep") {
			return <Badge key="status">Vilande</Badge>;
		} else if (item.status == "invoiced") {
			return (
				<Badge key="status" status="success">
					Fakturerad
				</Badge>
			);
		} else if (item.status == "quote") {
			return (
				<Badge key="quote" status="warning">
					Offert
				</Badge>
			);
		}
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		var filters = [
			{
				key: "status",
				label: "Status",
				operatorText: "är",
				type: "select",
				options: [
					{ label: "Offert", value: "quote" },
					{ label: "Vilande", value: "sleep" },
					{ label: "Kommande", value: "pending" },
					{ label: "Aktiv", value: "active" },
					{ label: "Färdig", value: "done" },
					{ label: "Fakturerad", value: "invoiced" },
				],
			},
			{
				key: "personell_ledger",
				label: "Har personalliggare",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "tagged",
				label: "Taggad",
				type: "textField",
			},
			{
				key: "ata",
				label: "ÄTA",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "dont_have_areas",
				label: "Saknar koordinater",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const promotedBulkActions = store.getState().user.read_only
			? []
			: [
					{
						content: "Skriv ut rapporter",
						onAction: this.printReports.bind(this),
					},
					{
						content: "Ändra status",
						onAction: this.changeStatus.bind(this),
					},
			  ];

		const columns = [
			{
				label: "Titel",
				handle: "TITLE",
				sortable: true,
				render: (item) => {
					return (
						<Title>
							<TextStyle variation="subdued">{item.reference} - </TextStyle> {item.title}
						</Title>
					);
				},
			},
			{
				label: "Status",
				handle: "STATUS",
				sortable: true,
				render: (item) => {
					return (
						<LegacyStack>
							{this.getStatus(item)}
							{item.ata ? <Badge>ÄTA</Badge> : null}
							{item.project && item.project.label ? <Badge>{item.project.label}</Badge> : <Badge status="warning">Projekt saknas</Badge>}
						</LegacyStack>
					);
				},
			},
			{
				label: "Tid",
				handle: "TIME",
				render: (item) => {
					return (
						<VerticalStack spacing="tight">
							<div>{item.total_minutes ? this.showTime(item.total_minutes) : "–"}</div>
							{item.estimated_hours && item.total_minutes ? (
								<div>
									<ProgressBar progress={(item.total_minutes / (item.estimated_hours * 60)) * 100} />{" "}
									<sup>{((item.total_minutes / (item.estimated_hours * 60)) * 100).toFixed(1) + "%"}</sup>
								</div>
							) : null}
						</VerticalStack>
					);
				},
			},
			{
				label: "Företag",
				handle: "COMPANY",
				render: (item) => {
					return item.company_name != store.getState().account.company ? <div>{item.company_name}</div> : null;
				},
			},
			{
				label: "Kund",
				handle: "CUSTOMER",
				render: (item) => {
					if (item.customer_name && item.company_name == store.getState().account.company) {
						return item.customer_name;
					}
				},
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					history={this.props.history}
					columns={columns}
					onClickRow={(item) => {
						this.props.history.push("/admin/work_orders/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title="Arbetsordrar"
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Ny arbetsorder",
									onAction: () => {
										this.props.history.push("/admin/work_orders/new");
									},
							  }
					}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{
										content: "Exportera arbetsordrar",
										onAction: () => {
											this.setState({ changeTimePeriodModalOpen: true });
										},
									},
							  ]
							: []
					}
					defaultSavedSearches={[
						{
							title: "Aktiv",
							filters: [
								{
									key: "status",
									value: "active",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_active",
						},
						{
							title: "Kommande",
							filters: [
								{
									key: "status",
									value: "pending",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_pending",
						},
						{
							title: "Vilande",
							filters: [
								{
									key: "status",
									value: "sleep",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_sleep",
						},
						{
							title: "Fakturerade",
							filters: [
								{
									key: "status",
									value: "invoiced",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_invoiced",
						},
						{
							title: "Färdiga",
							filters: [
								{
									key: "status",
									value: "done",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_done",
						},
					]}
					savedSearchHandle="work_orders"
					resourceUrl="/api/work_orders.json"
					resourceHandle="work_orders"
					resourceName={{
						singular: "arbetsorder",
						plural: "arbetsordrar",
					}}
					filters={filters}
					promotedBulkActions={promotedBulkActions}
				/>
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
				/>
				<Modal
					title="Ändra status"
					open={this.state.changeStatusModalOpen}
					onClose={() => {
						this.setState({ changeStatusModalOpen: false });
					}}
					primaryAction={{
						content: "Uppdatera",
						loading: this.state.saving,
						disabled: !this.state.newStatus,
						onAction: this.doUpdateStatus.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ changeStatusModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<Select
							label="Status"
							options={[
								{ label: "Välj ny status", value: "" },
								{ label: "Offert", value: "quote" },
								{ label: "Vilande", value: "sleep" },
								{ label: "Kommande", value: "pending" },
								{ label: "Aktiv", value: "active" },
								{ label: "Färdig", value: "done" },
								{ label: "Fakturerad", value: "invoiced" },
							]}
							onChange={(value) => {
								this.setState({ newStatus: value });
							}}
							value={this.state.newStatus}
						/>
					</Modal.Section>
				</Modal>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentWorkOrders.bind(this)}
				/>
			</div>
		);
	}
}

export default WorkOrderIndex;

import React, { Component } from "react";
import { Modal, DatePicker, Stack, ChoiceList, Checkbox, FormLayout, TextField, Select, Breadcrumbs, HorizontalStack } from "@shopify/polaris";
import moment from "moment";
import { store } from "../store";
import ResourcePicker from "./resource_picker.js";
import TimePicker from "./time_picker.js";
import ExpenseReportModal from "./ExpenseReportModal.js";
import { toastr } from "./toastr";
import API from "../API";
import { getTypeLabel } from "../Utilities";

class TimeReportModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.getDefaultState(props),
		};
	}

	getDefaultState(props = this.props) {
		var now = props.timereport?.checkin_at ? new Date(props.timereport?.checkin_at) : props.date ? new Date(props.date) : new Date();

		var extraValues =
			store.getState().account.favorite_expenses?.reduce((acc, expense) => {
				acc[expense.id] = { id: null, value: "0", expsene_id: expense.id };
				return acc;
			}, {}) || {};

		const project = props.timereport?.work_order?.project || props.project;
		const workOrder = props.timereport?.work_order || props.work_order;

		return {
			comment: "",
			extraValues,
			hours: props.timereport ? props.timereport.minutes / 60 : "8",
			ob_hours: "0",
			overtime_hours: "0",
			view: props.view ? props.view : "work_order",
			whole_day: props.timereport?.whole_day || !props.work_order,
			from_time: props.timereport?.checkin_at ? moment(props.timereport?.checkin_at).format("HH:mm") : store.getState().account.workday_start,
			lock_user: props.lock_user,
			to_time: props.timereport?.checkout_at ? moment(props.timereport?.checkout_at).format("HH:mm") : store.getState().account.workday_end,
			user_id: props.timereport?.user?.id || props.user_id,
			user_name: props.timereport?.user?.name || props.user_name,
			saving: false,
			month: now.getMonth(),
			year: now.getFullYear(),
			selected: {
				start: now,
				end: now,
			},
			project,
			hourly_invoicable_rate: project?.hourly_rate,
			amount_to_invoice: Number(project?.hourly_rate) * Number(props.timereport ? props.timereport.minutes / 60 : "8"),
			work_order: workOrder,
			work_order_id: workOrder && workOrder.id,
			work_order_title: workOrder && workOrder.label,
			allow_range: false,
			work_type_id: workOrder && workOrder.work_types && workOrder.work_types.length == 1 && workOrder.work_types[0].id,
		};
	}

	getViewTitle() {
		var items = [
			{ label: getTypeLabel("work_order"), value: "work_order" },
			{ label: getTypeLabel("personell_ledger"), value: "personell_ledger" },
			{ label: getTypeLabel("photo"), value: "photo" },
			{ label: getTypeLabel("divergence"), value: "divergence" },
			{ label: getTypeLabel("expense"), value: "expense" },
			{ label: getTypeLabel("sick"), value: "sick" },
			{ label: getTypeLabel("vab"), value: "vab" },
			{ label: getTypeLabel("shorttime"), value: "shorttime" },
			{ label: getTypeLabel("parenting"), value: "parenting" },
			{ label: getTypeLabel("vacation"), value: "vacation" },
			{ label: getTypeLabel("service_free"), value: "service_free" },
			{ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" },
			{ label: getTypeLabel("birth_free"), value: "birth_free" },
			{ label: getTypeLabel("comp_free"), value: "comp_free" },
			{ label: getTypeLabel("weekend_salary"), value: "weekend_salary" },
			{ label: getTypeLabel("mileage"), value: "mileage" },
			{ label: getTypeLabel("covid"), value: "covid" },
		];

		// for (var i = 0; i < items.length; i++) {
		// 	if (items[i].value == this.state.view) {
		// 		return items[i].label;
		// 	}
		// }

		const foundItem = items.find((i) => i.value == this.state.view);
		return foundItem && foundItem.label;
	}

	componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			this.setState({
				...this.getDefaultState(props),
			});
		}
	}

	updateExtraValue(id, value) {
		this.state.extraValues[id].value = value;
		this.setState({ extraValues: this.state.extraValues });
	}

	saveReport() {
		this.setState({ saving: true });

		if (this.state.view == "mileage") {
			API.post("/api/mileage_reports.json", {
				distance: this.state.distance,
				user_id: this.state.user_id,
				date: moment(this.state.selected.start).format("YYYY-MM-DD"),
			})
				.then((result) => {
					if (result.data.error) {
						return;
					}
					toastr.success("Körsträcka registrerad");
					if (this.props.onComplete) this.props.onComplete(result.data.mileage_reports);
				})
				.catch((error) => {
					toastr.error(error);
				})
				.finally(() => {
					this.setState({ saving: false });
				});
			return;
		}
		var start = new Date(this.state.selected.start);
		start.setHours(0, 0, 0, 0);
		var end = new Date(this.state.selected.end);
		end.setHours(23, 59, 59, 0);

		const data = {
			values: this.state.extraValues,
			project_id: this.state.project_id,
			work_order_id: this.state.work_order_id,
			skip_weekends: !!this.state.skip_weekends,
			admin: 1,
			type: this.state.work_order_id && this.state.view === "work_order" ? "project" : this.state.view,
			whole_day: this.state.whole_day,
			user_id: this.state.user_id,
			hours: this.state.hours,
			area_id: this.state.area && this.state.work ? this.state.area.id : null,
			ob_hours: this.state.ob_hours,
			hourly_invoicable_rate: this.state.hourly_invoicable_rate,
			amount_to_invoice: this.state.amount_to_invoice,
			overtime_hours: this.state.overtime_hours,
			tid_eller_pengar: this.state.tid_eller_pengar,
			comment: this.state.comment,
			checkin_at: moment(this.state.selected.start).format("YYYY-MM-DD ") + this.state.from_time,
			checkout_at: moment(this.state.selected.end).format("YYYY-MM-DD ") + this.state.to_time,
			report_type: store.getState().account.input_type,
			work_type_id:
				this.state.work_type_id ||
				(this.state.work_order && this.state.work_order.work_types && this.state.work_order.work_types[0] && this.state.work_order.work_types[0].id),
		};

		API.post("/api/" + (this.state.view == "personell_ledger" ? "ledger_checkins" : "time_reports") + ".json", data)
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					return;
				}
				toastr.success("Tidsrapport skapad");

				const refreshEvent = new CustomEvent("refreshProjectStats");
				window.dispatchEvent(refreshEvent);

				if (this.props.onComplete) this.props.onComplete(result.data.time_report);
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	changeUser(user) {
		this.setState({ user_id: user.id, user_name: user.name });
	}

	validView() {
		if (this.state.view == "work_order" && this.state.work_order_id) {
			return true;
		} else if (this.state.view == "project" && this.state.project) {
			return true;
		} else if (this.state.view == "personell_ledger" && this.state.work_order_id) {
			return true;
		} else if (this.state.view == "expense" && this.state.project) {
			return true;
		} else if (this.state.view != "work_order" && this.state.view != "personell_ledger" && this.state.view != "expense") {
			return true;
		}
		return false;
	}

	changeProject(project) {
		this.setState({ project, whole_day: false });
		if (project && project.work_orders && project.work_orders.length === 1) {
			this.changeWorkOrder(project.work_orders[0]);
		}
	}

	changeWorkOrder(workOrder) {
		this.setState({ work_order: workOrder, work_order_id: workOrder.id, work_order_title: workOrder.label, whole_day: false, hours: "8" });
	}

	getTimeOptions() {
		const options = Array.from(Array(24)).flatMap((i, index) => {
			return [
				`${String(index).padStart(2, "0")}:00`,
				`${String(index).padStart(2, "0")}:15`,
				`${String(index).padStart(2, "0")}:30`,
				`${String(index).padStart(2, "0")}:45`,
			];
		});
		options.push("23:59");

		return options;
	}

	convertToHours(string) {
		if (!string.includes(".")) return Number(string);
		const [hours, minutes] = string.split(".");
		return Number(hours) + Number(minutes) / 60;
	}

	getChangeHandler(key) {
		const hours = this.convertToHours(this.state.hours) + this.convertToHours(this.state.ob_hours) + this.convertToHours(this.state.overtime_hours);

		if (key === "hourly_invoicable_rate" || key === "hours" || key === "ob_hours" || key == "overtime_hours") {
			this.state.amount_to_invoice = this.state.hourly_invoicable_rate ? parseFloat(this.state.hourly_invoicable_rate * hours).toFixed(0) : 0;
		} else if (key === "amount_to_invoice") {
			this.state.hourly_invoicable_rate = parseFloat(this.state.amount_to_invoice / hours).toFixed(0);
		}

		this.setState({ ...this.state });
	}

	render() {
		var disableDatesBefore = null;
		/*
      if ((store.getState().user.roles.indexOf('ROLE_ADMIN') < 0) && (this.state.view == 'project')) {
        disableDatesBefore = new Date();
        var days = store.getState().account.days_back;
        disableDatesBefore.setDate(disableDatesBefore.getDate()-(days+1));
      }
      */

		if (this.state.view == "expense" && this.validView()) {
			return (
				<ExpenseReportModal
					open={this.props.open}
					onComplete={this.props.onComplete}
					onClose={this.props.onClose}
					project={this.state.project}
					// work_order_id={this.state.work_order_id}
					user_id={store.getState().user.id}
					user_name={store.getState().user.name}
					lock_user={this.state.lock_user}
				/>
			);
		}

		var choices = [
			{
				label: getTypeLabel("work_order"),
				value: "work_order",
				renderChildren: (isSelected) =>
					isSelected && (
						<React.Fragment>
							<ResourcePicker
								resourceHandle="projects"
								label_handle="full_label"
								placeholder="Sök projekt"
								caption="Välj projekt"
								resource="projects"
								resourceName={{
									singular: "projekt",
									plural: "projekt",
								}}
								item={this.state.project}
								onChange={this.changeProject.bind(this)}
							/>
							<br />
							{this.state.project && (
								<ResourcePicker
									placeholder="Sök Arbetsorder"
									caption="Välj Arbetsorder"
									resource="work_orders.json"
									resourceHandle="work_orders"
									label_handle="full_label"
									resourceName={{
										singular: "arbetsorder",
										plural: "arbetsorder",
									}}
									params={{ project_id: this.state.project.id }}
									item={this.state.work_order}
									onChange={this.changeWorkOrder.bind(this)}
								/>
							)}
						</React.Fragment>
					),
			},
		];

		if (!this.props.project) {
			if (store.getState().account.has_personell_ledger) {
				choices.push({
					label: getTypeLabel("personell_ledger"),
					value: "personell_ledger",
					renderChildren: (isSelected) =>
						isSelected && (
							<ResourcePicker
								placeholder="Sök Arbetsorder"
								caption="Välj Arbetsorder"
								resource="work_orders.json"
								resourceHandle="work_orders"
								label_handle="full_label"
								resourceName={{
									singular: "arbetsorder",
									plural: "arbetsorder",
								}}
								params={{ project_id: this.props.project && this.props.project.id }}
								item={this.state.work_order}
								onChange={this.changeWorkOrder.bind(this)}
							/>

							// <ResourcePicker
							// 	placeholder="Sök projekt"
							// 	caption="Välj projekt"
							// 	resource="work_orders.json?"
							// 	resourceName={{
							// 		singular: "projekt",
							// 		plural: "projekt",
							// 	}}
							// 	item={null}
							// 	onChange={this.changeProject.bind(this)}
							// />
						),
				});
			}

			if (store.getState().account.expenses_count) {
				choices.push({
					label: getTypeLabel("expense"),
					value: "expense",
					renderChildren: (isSelected) =>
						isSelected && (
							<ResourcePicker
								placeholder="Sök projekt"
								caption="Välj projekt"
								resource="projects"
								resourceName={{
									singular: "projekt",
									plural: "projekt",
								}}
								item={this.state.project}
								onChange={this.changeProject.bind(this)}
							/>
						),
				});
			}

			choices.push({ label: getTypeLabel("sick"), value: "sick" });
			choices.push({ label: getTypeLabel("vab"), value: "vab" });
			choices.push({ label: getTypeLabel("shorttime"), value: "shorttime" });
			choices.push({ label: getTypeLabel("parenting"), value: "parenting" });
			choices.push({ label: getTypeLabel("vacation"), value: "vacation" });
			choices.push({ label: getTypeLabel("service_free"), value: "service_free" });
			choices.push({ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" });
			choices.push({ label: getTypeLabel("birth_free"), value: "birth_free" });
			choices.push({ label: getTypeLabel("comp_free"), value: "comp_free" });
			choices.push({ label: getTypeLabel("weekend_salary"), value: "weekend_salary" });
			choices.push({ label: getTypeLabel("mileage"), value: "mileage" });
			choices.push({ label: getTypeLabel("covid"), value: "covid" });
		}
		var disableDatesAfter = null;
		/*
      if ((this.state.view == 'vacation') || (this.state.view == 'parenting') || (this.state.view == 'service_free') || (this.state.view == 'permission') || (this.state.view == 'comp_free')) {
        disableDatesAfter.setYear(5000);
      }
      */

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={
					<HorizontalStack>
						{this.validView() && (
							<Breadcrumbs
								backAction={{
									content: "Tillbaka",
									onAction: () => {
										this.setState({ ...this.getDefaultState(this.props) });
									},
								}}
							/>
						)}
						{this.validView() ? this.getViewTitle() + (this.state.work_order_title ? " - " + this.state.work_order_title : "") : "Rapportera tid"}
					</HorizontalStack>
				}
				secondaryActions={[
					{
						content: "Close",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: "Rapportera",
					disabled: !this.validView(),
					loading: this.state.saving,
					onAction: this.saveReport.bind(this),
				}}
			>
				{!this.validView() ? (
					<Modal.Section>
						<ChoiceList
							choices={choices}
							selected={this.state.view}
							onChange={(view) => {
								this.setState({ view: view[0] });
							}}
						/>
					</Modal.Section>
				) : null}
				{this.validView() ? (
					<Modal.Section>
						<DatePicker
							month={this.state.month}
							year={this.state.year}
							weekStartsOn={1}
							onChange={(value) => {
								this.setState({ selected: value, allow_range: !this.state.allow_range });
							}}
							onMonthChange={(month, year) => {
								this.setState({ month, year });
							}}
							selected={this.state.selected}
							multiMonth={false}
							allowRange={!this.state.allow_range ? false : !this.props.single || this.state.view != "project"}
							disableDatesAfter={disableDatesAfter}
							disableDatesBefore={disableDatesBefore}
						/>
					</Modal.Section>
				) : null}
				{this.validView() && this.state.selected.start < this.state.selected.end ? (
					<Modal.Section>
						<Checkbox
							checked={this.state.skip_weekends}
							onChange={(val) => {
								this.setState({ skip_weekends: !this.state.skip_weekends });
							}}
							label="Gäller inte lördag och söndag"
						/>
					</Modal.Section>
				) : null}
				{!this.state.lock_user && this.validView() ? (
					<Modal.Section>
						<ResourcePicker
							label="Person"
							placeholder="Sök person"
							caption="Välj person"
							resource="users.json?enabled=1"
							resourceHandle="users"
							resource_handle="users"
							resourceName={{
								singular: "användare",
								plural: "användare",
							}}
							item={this.state.user_id ? { id: this.state.user_id, name: this.state.user_name } : null}
							onChange={this.changeUser.bind(this)}
							label_handle="name"
						/>
					</Modal.Section>
				) : null}
				{this.validView() &&
					(this.state.view === "work_order" || this.state.view === "project") &&
					this.state.work_order &&
					this.state.work_order.work_types &&
					this.state.work_order.work_types.length > 1 && (
						<Modal.Section>
							<Select
								label="Välj arbetstyp"
								options={this.state.work_order.work_types.map((workType) => {
									return { value: workType.id + "", label: workType.title };
								})}
								value={this.state.work_type_id}
								onChange={(value) => {
									this.setState({ work_type_id: value });
								}}
							/>
						</Modal.Section>
					)}

				{this.validView() &&
				(this.state.view == "personell_ledger" ||
					(store.getState().account.input_type != "hours" && store.getState().account.input_type != "hours_free")) ? (
					<Modal.Section>
						{this.state.view != "project" && this.state.view != "personell_ledger" ? (
							<Checkbox
								checked={this.state.whole_day}
								onChange={(val) => {
									this.setState({ whole_day: !this.state.whole_day });
								}}
								label="Hela dagen"
							/>
						) : null}
						{!this.state.whole_day ? (
							<Stack distribution="fillEvenly">
								<TimePicker
									options={this.getTimeOptions()}
									label="Från tid"
									placeholder="Välj klockslag"
									value={this.state.from_time}
									onChange={(value) => {
										this.setState({ from_time: value });
									}}
								/>
								<TimePicker
									options={this.getTimeOptions()}
									label="Till tid"
									placeholder="Välj klockslag"
									value={this.state.to_time}
									onChange={(value) => {
										this.setState({ to_time: value });
									}}
								/>
							</Stack>
						) : null}
					</Modal.Section>
				) : null}
				{this.validView() &&
				this.state.view != "personell_ledger" &&
				(store.getState().account.input_type == "hours" || store.getState().account.input_type == "hours_free") ? (
					<>
						<Modal.Section>
							{this.state.view != "mileage" ? (
								<TimePicker
									label={ this.state.view == "work_order" || this.state.view == "project" ? "Normala timmar" : "Timmar" }
									value={this.state.hours}
									onChange={(value) => {
										this.state.hours = value;
										this.getChangeHandler("hours");
									}}
									editing={store.getState().account.input_type != "hours"}
									hours
								/>
							) : (
								<TextField
									label="Körsträcka"
									suffix="km"
									type="number"
									value={this.state.distance}
									onChange={(km) => {
										this.setState({ distance: km });
									}}
								/>
							)}
							{this.state.view == "work_order" || this.state.view == "project" ? (
								<>
									<br />
									<Stack distribution="fillEvenly">
										<TimePicker
											label="OB timmar"
											value={this.state.ob_hours}
											onChange={(value) => {
												this.state.ob_hours = value;
												this.getChangeHandler("ob_hours");
											}}
											editing={store.getState().account.input_type != "hours"}
											hours
										/>
										<TimePicker
											label="Övertidstimmar"
											value={this.state.overtime_hours}
											onChange={(value) => {
												this.state.overtime_hours = value;
												this.getChangeHandler("overtime_hours");
											}}
											editing={store.getState().account.input_type != "hours"}
											hours
										/>
									</Stack>
								</>
							) : null}
						</Modal.Section>
					</>
				) : null}

				{this.validView() && this.state.view == "project" && store.getState().account.favorite_expenses.length ? (
					<Modal.Section>
						<FormLayout>
							{store.getState().account.favorite_expenses.map((field, index) => (
								<TextField
									key={index}
									label={field.title + " " + field.unit}
									value={this.state.extraValues[field.id].value}
									onChange={this.updateExtraValue.bind(this, field.id)}
								/>
							))}
						</FormLayout>
					</Modal.Section>
				) : null}

				{this.validView() && this.state.view != "personell_ledger" && this.state.view != "mileage" ? (
					<Modal.Section>
						<FormLayout>
							<TextField
								multiLine
								label="Kommentar"
								value={this.state.comment}
								onChange={(value) => {
									this.setState({ comment: value });
								}}
							/>
						</FormLayout>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}

export default TimeReportModal;

import { Icon } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";

/**
 * @param {Object} [style] - inline style
 * @param {String} [className] - classname
 * @param {Number} [size = 50] - size value in number
 * @param {Icon} [icon] - icon
 */
class IconWithBackround extends Component {
	render() {
		const { style, icon, size, className, color, backgroundColor } = this.props;

		return (
			<Wrapper
				className={`${className || ""}`}
				style={Object.assign(
					style || {},
					size ? { height: size + "px", width: size + "px" } : {},
					color ? { backgroundColor: color, fill: color } : {},
					backgroundColor ? { backgroundColor } : {}
				)}
			>
				{React.isValidElement(icon) ? icon : <Icon source={icon} />}
			</Wrapper>
		);
	}
}
export default IconWithBackround;

const Wrapper = styled.span`
	border-radius: 50%;
	height: 50px;
	width: 50px;
	background: $main1;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: var(--color-primary);

	.Polaris-Icon {
		width: 50%;
		height: 50%;
		fill: inherit;

		svg {
			fill: inherit;
			filter: saturate(0.8);
		}
	}
	svg {
		fill: inherit;
		filter: saturate(0.8);
	}
`;

import { Avatar, Icon, Tooltip } from "@shopify/polaris";
import React, { Component } from "react";

class ProfileAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick() {
		if (this.props.onClick) this.props.onClick(this.props.user);
	}

	render() {
		const { user, children, size = "medium", tooltip, icon, onClick } = this.props;
		if (!user) return <Avatar name="--" size={["small", "medium", "large"].includes(size) ? size : "medium"} initials="--" />;

		if (!user.initials && user.name) {
			const name = user.name;
			const nameArray = name && name.split(" ");
			const firstName = (nameArray && nameArray.shift()) || "";
			const lastName = (nameArray && nameArray.pop()) || "";

			user.initials = ((firstName || "").charAt(0) + (lastName || "").charAt(0)).toUpperCase();
		}

		const avatar = icon ? (
			<Icon source={icon} />
		) : (
			<Avatar source={user.avatar} name={user.name || "--"} initials={user.initials || "--"} size={typeof size === "string" ? size : "medium"} />
		);

		if ((size && !["small", "medium", "large"].includes(size)) || icon) {
			const content = (
				<span
					onClick={this.onClick.bind(this)}
					className="profile-icon"
					style={Object.assign(size ? { width: size + "px", height: size + "px" } : {}, {
						cursor: onClick ? "pointer" : "initial",
						display: "block",
					})}
				>
					{avatar}
					<div className="children">{children}</div>
				</span>
			);
			if (tooltip || tooltip === undefined) {
				return (
					<Tooltip
						content={
							tooltip === undefined ? (
								<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
									{icon ? <Icon source={icon} /> : <Avatar source={user.avatar} name={user.name} initials={user.initials} size="large" />}
									<div style={{ display: "flex", flexDirection: "column" }}>
										{user.name}
										<span style={{ opacity: 0.75, fontSize: "0.9em" }}>{user.email}</span>
									</div>
								</div>
							) : (
								tooltip
							)
						}
					>
						{content}
					</Tooltip>
				);
			}

			return content;
		}
		return (
			<Tooltip
				content={
					tooltip === undefined ? (
						<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
							{icon ? (
								<Icon source={icon} />
							) : (
								<Avatar
									source={user.avatar}
									name={user.name || "--"}
									initials={user.initials || "--"}
									size={typeof size === "string" ? size : "medium"}
								/>
							)}
							<div style={{ display: "flex", flexDirection: "column" }}>
								{user.name}
								<span style={{ opacity: 0.75, fontSize: "0.9em" }}>{user.email}</span>
							</div>
						</div>
					) : (
						tooltip
					)
				}
			>
				<span
					onClick={this.onClick.bind(this)}
					style={{ cursor: onClick ? "pointer" : "initial", position: "relative", display: "flex", gap: "1rem", alignItems: "center" }}
				>
					{avatar}
					{children}
				</span>
			</Tooltip>
		);
	}
}
export default ProfileAvatar;

import { Component } from "react";
import { Card, Page, Button, Stack, Subheading, List, Caption } from "@shopify/polaris";
import "moment-timezone";
import { toastr } from "src/js/components/toastr.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import { store } from "../../store";

import BillingServiceModal from "../../components/billingServiceModal.js";
import API from "../../API";

class SubscriptionProviderIndex extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: true, saving: false, selectedProvider: null, showAddCreditCardModal: false, providers: [] };
	}

	componentDidMount() {
		this.setState({ loading: true });
		API.get("/api/subscriptions/providers.json")
			.then((result) => {
				this.setState({ loading: false, providers: result.data.providers, selectedProvider: result.data.providers[0] });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	showAddCreditCardModal() {
		this.setState({ showAddCreditCardModal: true });
	}

	closeCreditCardModal() {
		this.setState({ showAddCreditCardModal: false });
	}

	selectPlan(providerId) {
		this.setState({ saving: true, selectedProvider: providerId });
		API.post("/api/subscriptions.json", { provider_id: providerId })
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false, showAddCreditCardModal: true });
					return true;
				}
				this.setState({ saving: false });
				store.dispatch({ type: "SET_SUBSCRIPTION", subscription: result.data.subscription });
				this.props.history.push("/admin/account");
				toastr.success("You are now using " + result.data.subscription.provider.title + " plan");
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	addedBilling(billing) {
		this.setState({ showAddCreditCardModal: false });
		this.selectPlan(this.state.selectedProvider);
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		return (
			<Page title="Select plan" backAction={{ content: "Account & Users", url: "/admin/account" }}>
				<Stack distribution="fillEvenly">
					{this.state.providers.map((item, key) => {
						return (
							<Stack.Item key={key}>
								<Card title={item.title}>
									<Card.Section>
										<Subheading>
											{item.price} {item.currency} / month
										</Subheading>
									</Card.Section>
									<Card.Section subdued>
										<div style={{ marginTop: 20, marginBottom: 20 }}>
											<List>
												<List.Item>
													Order #1001 <Caption>Received April 21, 2017</Caption>
												</List.Item>
												<List.Item>
													Order #1002 <Caption>Received April 22, 2017</Caption>
												</List.Item>
											</List>
										</div>
									</Card.Section>
									<Card.Section>
										<Button loading={this.state.saving} primary fullWidth onClick={this.selectPlan.bind(this, item.id)}>
											Select plan
										</Button>
									</Card.Section>
								</Card>
							</Stack.Item>
						);
					})}
				</Stack>
				<BillingServiceModal
					open={this.state.showAddCreditCardModal}
					onComplete={this.addedBilling.bind(this)}
					onClose={this.closeCreditCardModal.bind(this)}
				/>
			</Page>
		);
	}
}

export default SubscriptionProviderIndex;

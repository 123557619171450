/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
	ProfileMajor,
	CircleChevronRightMinor,
	ProductsMajor,
	CalendarMajor,
	ConversationMinor,
	HorizontalDotsMinor,
	DuplicateMinor,
	PrintMajor,
	ChecklistAlternateMajor,
	PlusMinor,
	AlertMinor,
} from "@shopify/polaris-icons";
import { Icon, Navigation } from "@shopify/polaris";
import { connect } from "react-redux";
import httpBuildQuery from "http-build-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { store } from "../store";
import IntersectionObserverElement from "./IntersectionObserverElement.js";
import MainMenuSection from "./MainMenuSection.js";
import routes from "../routes.js";

import { CogWheelIcon, ExportIcon, AlarmClock, ClipboardIcon } from "../icons";
import TimeReportModal from "./TimeReportModal";
import TimeRangeModal from "./TimeRangeModal";
import AttestModal from "./AttestModal";

class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	componentDidMount() {
		// eslint-disable-next-line no-unused-vars
		const userAgentStoreUrl = this.checkForMobileBrowser();
		// if (userAgentStoreUrl) {
		// 	window.location.href = userAgentStoreUrl;
		// }
	}

	componentWillReceiveProps() {
		this.setState({});
	}

	checkForMobileBrowser() {
		if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
			// Send to Apps Webview
			return;
		}
		const agent = navigator.userAgent || navigator.vendor || window.opera;
		const appId = "se.t24.app";

		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				agent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				agent.substr(0, 4)
			)
		) {
			if (/windows phone/i.test(agent)) return false;

			if (/android/i.test(agent)) return `http://play.google.com/store/apps/details?id=${appId}`;

			if (/iPad|iPhone|iPod/.test(agent) && !window.MSStream) return "itms-apps://apps.apple.com/se/app/kund24/id1510394757";

			return false;
		}

		return false;
	}

	gotoMap() {
		this.props.history.push("/admin/dashboard#map");
	}

	closeDrawerAndDeselct() {
		document.querySelector("body").classList.remove("nav-active");
		document.querySelector("body").classList.remove("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));
	}

	addAnimating(id) {
		if (id) {
			document.querySelector(`#${id}`).classList.add("animating");

			clearTimeout(this.animatingTimer);
			this.animatingTimer = setTimeout(() => {
				document.querySelector(`#${id}`).classList.remove("animating");
			}, 250);
		} else {
			const activeEle = document.querySelector(".mainmenu-item.active");
			if (activeEle) activeEle.classList.add("animating");
			clearTimeout(this.animatingTimer);
			setTimeout(() => {
				const animatingEle = document.querySelector(".mainmenu-item.animating");
				if (animatingEle) animatingEle.classList.remove("animating");
			}, 250);
		}
	}

	onSelect({ id, url, onClick } = {}) {
		document.querySelector("#AppFrameNav").classList.remove("have_active");
		if (url && url === this.props.location.pathname) {
			this.closeDrawerAndDeselct();
			return null;
		}

		if (url) {
			this.addAnimating();
			this.closeDrawerAndDeselct();
			// this.props.history.push(url);
			return;
		}
		if (onClick) {
			this.addAnimating();
			this.closeDrawerAndDeselct();
			// this.props.history.push(url);
			onClick();
			return;
		}

		this.addAnimating();

		if (document.querySelector(`#${id}`).classList.contains("active") && document.querySelector("body").classList.contains("nav-active")) {
			cancelAnimationFrame(this.animationFrame);
			this.animationFrame = requestAnimationFrame(() => {
				document.querySelector("body").classList.remove("nav-active");
				document.querySelector("body").classList.remove("nav-selected");
				document.querySelector(`#${id}`).classList.remove("active");
			});
			return;
		}

		document.querySelector("body").classList.add("nav-active");
		document.querySelector("body").classList.add("nav-selected");
		const elements = Array.from(document.querySelectorAll(".mainmenu-item"));
		elements.forEach((e) => e.classList.remove("active"));

		cancelAnimationFrame(this.animationFrame);
		this.animationFrame = requestAnimationFrame(() => {
			document.querySelector(`#${id}`).classList.add("active");
			document.querySelector("#AppFrameNav").classList.add("have_active");
		});

		// if (onClick) onClick();
	}

	toggleMenu(e) {
		e.preventDefault();
		e.stopPropagation();
		this.addAnimating();
		document.querySelector("body").classList.toggle("nav-active");
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	activeRoute(routeName, handle, level) {
		if (handle && level == 0) {
			for (var i = 0; i < routes.length; i++) {
				if (routes[i].parent == handle) {
					var active = this.props.location.pathname.indexOf(routes[i].path) > -1;
					if (active) {
						return true;
					}
				}
			}
		}
		return this.props.location.pathname.indexOf(routeName) > -1;
	}

	reportTime() {
		this.setState((c) => ({ showTimeReportModal: !c.showTimeReportModal }));
	}

	closeTimeReportModal() {
		// sessionStorage.removeItem('selectedUser');
		this.setState({ showTimeReportModal: false });
	}

	printProjectReports() {
		this.setState((c) => ({ projectTimeRangeModalOpen: !c.projectTimeRangeModalOpen, salaryTimeRangeModalOpen: false }));
	}

	closeProjectTimeRangeModal() {
		this.setState({ projectTimeRangeModalOpen: false });
	}

	closeSalaryTimeRangeModal() {
		this.setState({ salaryTimeRangeModalOpen: false });
	}

	printFlexReports() {
		this.setState((c) => ({ flexTimeRangeModalOpen: !c.flexTimeRangeModalOpen }));
	}

	closeFlexTimeRangeModal() {
		this.setState({ flexTimeRangeModalOpen: false });
	}

	printTimestampReports() {
		this.setState({ timestampRangeModalOpen: true });
	}

	closeTimestampRangeModal() {
		this.setState({ timestampRangeModalOpen: false });
	}

	printProjectRange(projects, fromDate, toDate, extraReports, includeUE, attest, showUserCost) {
		if (!projects.length) {
			return;
		}
		this.closeProjectTimeRangeModal();
		var query = {
			ids: projects.map((project) => project.id),
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
			showUserCost,
		};
		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
		// var projectIds = projects.map((project) => project.id);
		// var query = {
		// 	ids: projectIds,
		// 	from: this.getYmd(fromDate),
		// 	to: this.getYmd(toDate),
		// 	ue: includeUE ? "1" : "0",
		// };

		// window.open("/api/projects/report.xls?" + httpBuildQuery(query) + "&extras=" + extraReports.join(","));
	}

	printSalaryRange(groups, users, subcontractors, fromDate, toDate, extraReports, exportOnlyNew, attest) {
		this.closeSalaryTimeRangeModal();

		var query = {
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		let url = null;

		if (groups.length > 0) {
			query.ids = groups.map((group) => group.id);

			url = "/api/groups/report.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		} else if (users.length > 0) {
			query.ids = users.map((user) => user.id);

			url = "/api/users/report.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		} else if (subcontractors.length > 0) {
			query.ids = subcontractors.map((subcontractor) => subcontractor.id);

			url = "/api/subcontractors/report.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		} else {
			url = "/api/users/report.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		}

		if (attest) {
			this.setState({
				attestGroups: groups,
				attestUsers: users,
				attestFromDate: moment(fromDate).format("YYYY-MM-DD"),
				attestToDate: moment(toDate).format("YYYY-MM-DD"),
				showAttest: attest,
				attestUrl: url,
			});
		} else {
			window.open(url);
		}
	}

	printFlexRange(groups, users, fromDate, toDate, extraReports, exportOnlyNew, attest) {
		this.closeFlexTimeRangeModal();

		var query = {
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		let url = null;

		if (groups.length > 0) {
			query.ids = groups.map((group) => group.id);

			url = "/api/groups/report_flex.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		} else if (users.length > 0) {
			query.ids = users.map((user) => user.id);

			url = "/api/users/report_flex.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		} else {
			url = "/api/users/report_flex.pdf?" + httpBuildQuery(query) + "&extras=" + extraReports.join(",");
		}

		if (attest) {
			this.setState({
				attestGroups: groups,
				attestUsers: users,
				attestFromDate: moment(fromDate).format("YYYY-MM-DD"),
				attestToDate: moment(toDate).format("YYYY-MM-DD"),
				showAttest: true,
				attestUrl: url,
			});
		} else {
			window.open(url, url);
		}
	}

	printTimestampRange(groups, users, fromDate, toDate, extraReports, exportOnlyNew, attest) {
		this.closeFlexTimeRangeModal();

		var query = {
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		let url = null;
		if (groups.length > 0) {
			query.ids = groups.map((group) => group.id);

			url = "/api/groups/report_timestamp.pdf?" + httpBuildQuery(query);
		} else if (users.length > 0) {
			query.ids = users.map((user) => user.id);

			url = "/api/users/report_timestamp.pdf?" + httpBuildQuery(query);
		} else {
			url = "/api/users/report_timestamp.pdf?" + httpBuildQuery(query);
		}

		if (attest) {
			this.setState({
				attestGroups: groups,
				attestUsers: users,
				attestFromDate: moment(fromDate).format("YYYY-MM-DD"),
				attestToDate: moment(toDate).format("YYYY-MM-DD"),
				showAttest: true,
				attestUrl: url,
			});
		} else {
			window.open(url);
		}
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		const fortnox_connected = store.getState().account.fortnox_connection;

		const fortnox_order_module = fortnox_connected && store.getState().account.fortnox_order_module;

		const mainLinks = routes.reduce((acc, route) => {
			if (((route.mainMenu && !route.disabled) || (route.name === "Hem" && routes.some((r) => r.parent == route.handle))) && !route.settings) {
				if (
					(route.name != "Arbetsordrar" && route.name != "Projekt") ||
					store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 ||
					store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0
				) {
					var path = route.path;
					if (path == "/admin/projects") {
						path += "?status=active";
					}
					if (path == "/admin/quotes" && fortnox_connected && fortnox_order_module) {
						path += "?filter=ordercreated";
					} else if (path == "/admin/quotes") {
						path += "?status=sent";
					}
					var mainLink = {
						label: route.name,
						title: route.name,
						icon: route.icon,
						onClick: route.handle == "aokarta" && this.gotoMap.bind(this),
						url: path,
					};

					if (route.handle) {
						for (var s = 0; s < routes.length; s++) {
							var sub = routes[s];
							// if (sub.parent == route.handle || true) {
							if (sub.parent == route.handle) {
								const itself = Object.assign({ style: { fontWeight: "700", fontSize: "1.15em" } }, mainLink);
								const subMenuItem = {
									label: sub.name,
									title: sub.name,
									icon: sub.icon,
									url: sub.path,
								};
								delete mainLink.url;
								if (!mainLink.items) {
									mainLink.items = [itself, subMenuItem];
								} else {
									mainLink.items.push(subMenuItem);
								}
							}
						}
					}

					acc.push(mainLink);
				}
			}
			return acc;
		}, []);

		let regItems = [];

		if (
			!store.getState().account.ue &&
			(store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0)
		) {
			regItems = [
				{
					title: "Tider",
					icon: CalendarMajor,
					url: "/admin/time_reports",
				},
				// {
				// 	title: "Avvikelser",
				// 	icon: CircleInformationMajor,
				// 	url: "/admin/deviation_reporting",
				// },
				// {
				// 	title: "Tillbud",
				// 	icon: CircleInformationMajor,
				// 	url: "/admin/incident_reporting",
				// },
				// {
				// 	title: "Olyckor",
				// 	icon: CircleInformationMajor,
				// 	url: "/admin/accident_reporting",
				// },
			];
		} else if (store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0) {
			regItems = [
				{
					title: "Tider",
					icon: CalendarMajor,
					url: "/admin/time_reports",
				},
			];
		}

		if (
			store.getState().account.has_personell_ledger &&
			(store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0)
		) {
			if (store.getState().account.ue) {
				regItems.push({
					title: "Tider",
					icon: CalendarMajor,
					url: "/admin/time_reports",
				});
			}
			regItems.push({
				title: "Personalliggare",
				icon: CalendarMajor,
				url: "/admin/ledger_checkins",
			});
		}
		regItems.push({
			title: "Avvikande registreringar",
			icon: AlertMinor,
			url: "/admin/deviating_registrations",
		});

		let adminItems = [];

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue) {
			adminItems = [
				{
					label: "Artikelregister",
					icon: ProductsMajor,
					url: "/admin/expenses",
				},
			];
		}

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue) {
			if (store.getState().account.enable_special_times) {
				adminItems.push({
					label: "Specialtider",
					icon: ProductsMajor,
					url: "/admin/special_times",
				});
			}
		}
		if (
			store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 ||
			store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0 ||
			store.getState().account.ue
		) {
			adminItems.push({
				label: "Användare",
				icon: ProfileMajor,
				url: "/admin/users",
			});
			if (!store.getState().account.ue) {
				adminItems.push({
					label: "Underentreprenörer",
					icon: DuplicateMinor,
					url: "/admin/underentreprenorer",
				});
			}
		}

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue) {
			adminItems.push({
				label: "Grupper",
				icon: ConversationMinor,
				url: "/admin/groups",
			});

			adminItems.push({
				label: "Inställningar",
				icon: HorizontalDotsMinor,
				url: "/admin/settings",
			});
		}

		adminItems.push({
			label: "PDFs",
			icon: ChecklistAlternateMajor,
			url: "/admin/pdf_templates",
		});

		adminItems.push({
			title: "Exportera",
			icon: <ExportIcon />,
			url: "/admin/exports",
			secondaryItemsStyle: { marginTop: "-0.75rem" },
		});

		var reportActions = [];

		// if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
		// 	reportActions.push({
		// 		label: "Veckorapporter",
		// 		icon: PrintMajor,
		// 		onClick: this.printSalaryReports.bind(this),
		// 	});
		// }

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			reportActions.unshift({
				label: "In- & utstämplingar",
				icon: PrintMajor,
				onClick: this.printTimestampReports.bind(this),
			});
		}

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			reportActions.unshift({
				label: "Tidrapporter",
				icon: PrintMajor,
				onClick: this.printFlexReports.bind(this),
			});
		}

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			reportActions.unshift({
				label: "Projektrapporter",
				icon: PrintMajor,
				onClick: this.printProjectReports.bind(this),
			});
		}

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			if (store.getState().account.reject_weeks) {
				reportActions.push({
					label: "Nekade tider",
					icon: PrintMajor,
					url: "/admin/rejected_weeks",
				});
			}
		}

		const sections = [
			// {
			// 	title: "Rapportera",
			// 	icon: <PenIcon />,
			// 	onClick: this.reportTime.bind(this),
			// },
		].concat(mainLinks, [
			{
				title: "Registreringar",
				icon: <AlarmClock />,
				items: regItems,
				favorites: [
					{
						title: "Ny tidregistrering",
						icon: PlusMinor,
						onClick: this.reportTime.bind(this),
					},
				],
				secondaryItemsStyle: { marginTop: "-0.75rem" },
			},
			{
				title: "Inköp",
				icon: <ClipboardIcon />,
				url: "/admin/supplier_invoices",
				// items: [
				// 	{
				// 		title: "Leverantörsfakturor",
				// 		icon: <ClipboardIcon />,
				// 		url: "/admin/supplier_invoices",
				// 	},
				// ],
				secondaryItemsStyle: { marginTop: "-0.75rem" },
				enabled: store.getState().account.fortnox_connection, // ? Remove when list of supplier invoices in CRM is ready
			},
			// {
			// 	title: "Fakturor",
			// 	icon: <ClipboardIcon />,
			// 	url: "/admin/invoices",
			// 	secondaryItemsStyle: { marginTop: "-0.75rem" },
			// },
			{
				title: "Rapporter",
				icon: <ClipboardIcon />,
				items: reportActions,
				secondaryItemsStyle: { marginTop: "-0.75rem" },
			},
		]);

		const bottomSections = [
			{
				title: "Admin",
				icon: <CogWheelIcon />,
				items: adminItems,
			},
		];

		var navigationMarkup = (
			<Navigation location={this.props.location.pathname}>
				<div ref={this.ref} className="navigation-sidebar">
					<div className="menu-items noscroll">
						<div>
							<div className={`mainmenu-item ${this.props.location.pathname.indexOf("admin/dashboard") >= 0 ? "url-active" : ""}`}>
								<Link
									className="mainmenu-item-trigger"
									to="/admin/dashboard/mine"
									onClick={this.onSelect.bind(this, { url: "/admin/dashboard/mine" })}
								>
									<img className="navigation-sidebar__logo" src={`${process.env.PUBLIC_URL}/assets/android-chrome-512x512.png`} alt="logo" />
								</Link>
							</div>

							{sections
								.filter((i) => i.component || !("enabled" in i) || i.enabled)
								.map((i, key) => {
									const id = `${i.title || i.label}-${key}`;

									const url = i.url && new URL((!window.location.host.includes("http") ? "http://" : "") + window.location.host + i.url);
									const className = url && this.props.location.pathname.indexOf(url.pathname) >= 0 ? "url-active" : "";

									return (
										<MainMenuSection
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...i}
											key={id}
											id={id}
											className={className}
											history={this.props.history}
											location={this.props.location}
											onClick={this.onSelect.bind(this, { id, url: i.url, onClick: i.onClick })}
										/>
									);
								})}
						</div>
						{bottomSections
							.filter((i) => !("enabled" in i) || i.enabled)
							.map((i, key) => {
								// if (i.component) return i.component;
								const id = `${i.title}-${key}`;
								return (
									<MainMenuSection
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...i}
										key={id}
										id={id}
										history={this.props.history}
										location={this.props.location}
										onClick={this.onSelect.bind(this, { id, url: i.url })}
									/>
								);
							})}
					</div>
					<IntersectionObserverElement
						hideShadow
						onChange={(intersecting) => {
							// const shadow = this.ref.current.closest("bottom-scroll-shadow");
							const shadow = document.querySelector("#mainmenu-shadow");
							if (shadow) {
								if (intersecting) {
									shadow.classList.remove("visible");
									shadow.classList.add("invisible");
								} else {
									shadow.classList.remove("invisible");
									shadow.classList.add("visible");
								}
							}
						}}
						parent={this.ref.current}
						shadowStyle={{ width: this.ref.current && this.ref.current.clientWidth }}
					/>
				</div>
			</Navigation>
		);

		const modals = (
			<React.Fragment>
				<TimeReportModal
					open={this.state.showTimeReportModal}
					onClose={this.closeTimeReportModal.bind(this)}
					onComplete={(item) => {
						this.closeTimeReportModal();
					}}
					user_id=""
					// user_id={store.getState().user.id}
					user_name={store.getState().user.name}
					lock_user={store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0}
				/>
				<TimeRangeModal
					open={this.state.projectTimeRangeModalOpen}
					onClose={this.closeProjectTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printProjectRange.bind(this)}
					project
					weeks
				/>
				<TimeRangeModal
					attest
					open={this.state.salaryTimeRangeModalOpen}
					onClose={this.closeSalaryTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printSalaryRange.bind(this)}
					usergroup
					weeks
				/>
				<TimeRangeModal
					attest
					open={this.state.flexTimeRangeModalOpen}
					onClose={this.closeFlexTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printFlexRange.bind(this)}
					usergroup
				/>
				<TimeRangeModal
					attest
					open={this.state.timestampRangeModalOpen}
					onClose={this.closeTimestampRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.printTimestampRange.bind(this)}
					usergroup
				/>

				<AttestModal
					open={this.state.showAttest}
					groups={this.state.attestGroups}
					users={this.state.attestUsers}
					from={this.state.attestFromDate}
					to={this.state.attestToDate}
					onClose={() => {
						this.setState({ showAttest: false });
					}}
					url={this.state.attestUrl}
				/>
			</React.Fragment>
		);

		return (
			<React.Fragment>
				{navigationMarkup}
				{modals}
				{ReactDOM.createPortal(
					<div className="mainmenu-item__secondary__collapse-btn" onClick={this.toggleMenu.bind(this)}>
						<Icon source={CircleChevronRightMinor} />
					</div>,
					document.querySelector("body")
				)}
				<div id="mainmenu-shadow" className="bottom-scroll-shadow" style={{ width: "100px" }} />
			</React.Fragment>
		);
	}
}

export default connect(
	(state) => ({
		account: state.account,
		user: state.user,
	}),
	{}
)(MainMenu);

import { Component } from "react";
import { Badge } from "@shopify/polaris";
import Moment from "react-moment";
import httpBuildQuery from "http-build-query";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";
import TimeRangeModal from "../../components/TimeRangeModal.js";

class AccidentIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			params: {},
		};
	}

	renderCell(item, column) {
		if (column.handle === "USER") {
			return (
				<div className="CustomerListItem__Profile">
					<h3 className="CustomerListItem__Title">{item.user.name}</h3>
				</div>
			);
		}
		if (column.handle === "AREA") {
			return item.area ? item.area.full_label : item.work_order.full_labe;
		}
		if (column.handle === "STATUS") {
			switch (item.status) {
				case "pending":
					return <Badge>Ej hanterad</Badge>;
				case "noticed":
					return <Badge status="info">Uppmärksammad</Badge>;
				case "ongoing":
					return <Badge status="info">Pågående</Badge>;
				case "done":
					return <Badge status="success">Avslutad</Badge>;
				default:
					return null;
			}
		}
		if (column.handle === "DATE") {
			return (
				<Moment locale="sv" format="YYYY-MM-DD">
					{item.occured_at}
				</Moment>
			);
		}
	}

	updateParams(params) {
		this.setState({ params });
	}

	exportCurrentProjects(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		params.accident = 1;
		params.avvikelse = 0;
		window.open("/api/incidents/export.xls?" + httpBuildQuery(params));
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		const columns = [
			{
				handle: "USER",
				label: "Användare",
			},
			{
				handle: "AREA",
				label: "Plats",
			},
			{
				handle: "STATUS",
				label: "Status",
			},
			{
				handle: "DATE",
				label: "Datum",
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					history={this.props.history}
					columns={columns}
					renderCell={this.renderCell.bind(this)}
					onClickRow={(id) => {
						this.props.history.push("/admin/accident_reporting/" + id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{
										content: "Exportera",
										onAction: () => {
											this.setState({ changeTimePeriodModalOpen: true });
										},
									},
							  ]
							: []
					}
					title="Registrerade olyckor"
					savedSearchHandle="accidents"
					resourceUrl="/api/incidents.json?accident=1"
					resourceHandle="incidents"
					resourceName={{
						singular: "olycka",
						plural: "olyckor",
					}}
					onUpdateParams={this.updateParams.bind(this)}
				/>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentProjects.bind(this)}
				/>
			</div>
		);
	}
}

export default AccidentIndex;

import React, { Component } from "react";
import { fabric } from "fabric";
import CanvasButtonSidebar from "./CanvasButtonSidebar";

class Canvas extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	componentDidMount() {
		this.setup();
		this.setSelection();
	}

	setup(props = this.props) {
		const height = props.height;
		const width = props.width;

		this.canvas = new fabric.Canvas(`c${props.index}`, { height, width, backgroundColor: "#ffffff", preserveObjectStacking: true });
		if (props.page && props.page.raw_data) {
			this.canvas.loadFromJSON(
				props.page.raw_data,
				() => {},
				(o, object) => {
					if (object.type === "textbox" && (object.left < 0 || object.left + object.width > this.canvas.getWidth())) {
						object.set({ width: this.canvas.getWidth() - object.left });
					}
				}
			);
		}

		this.setEvents();
	}

	componentWillReceiveProps(props) {
		this.setSelection();
		if (this.props.page.raw_data != props.page.raw_data) {
			this.setup(props);
		}
	}

	componentWillUnmount() {
		if (this.observer) this.observer.disconnect();
		if (this.props.onUnmount) this.props.onUnmount();
	}

	handleIntersection(entries) {
		if (entries && entries[0].isIntersecting) {
			this.props.setActivePage();
		}
	}

	getAndSetActiveObjects() {
		this.props.setActiveObject(this.canvas.getActiveObjects());
	}

	resetActiveObjects() {
		this.props.setActiveObject(null);
	}

	fitTextboxToContent(e) {
		const text = e.target;
		const rect = text.getBoundingRect();
		if (rect.left < 0 || rect.left + rect.width > this.canvas.getWidth()) {
			const textLinesMaxWidth = this.canvas.getWidth() - rect.left;
			text.set({ width: textLinesMaxWidth });
			return;
		}

		const textLinesMaxWidth = text.textLines.reduce((max, _, i) => Math.max(max, text.getLineWidth(i)), 0);
		text.set({ width: textLinesMaxWidth });
	}

	setEvents() {
		if (!this.props.disableEditing) {
			this.canvas.on("selection:created", this.getAndSetActiveObjects.bind(this));
			this.canvas.on("selection:updated", this.getAndSetActiveObjects.bind(this));
			this.canvas.on("selection:cleared", this.resetActiveObjects.bind(this));
			this.canvas.on("text:changed", this.fitTextboxToContent.bind(this));
			setTimeout(() => {
				if (this.ref.current) {
					this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
						root: document,
						threshold: 0.51,
					});

					this.observer.observe(this.ref.current);
				}
			}, 0);
		} else {
			this.canvas.off("selection:created", this.getAndSetActiveObjects.bind(this));
			this.canvas.off("selection:updated", this.getAndSetActiveObjects.bind(this));
			this.canvas.off("selection:cleared", this.resetActiveObjects.bind(this));
			if (this.observer) this.observer.disconnect();
		}
	}

	setSelection() {
		if (this.props.disableEditing) {
			this.canvas.selection = false;
			this.canvas.forEachObject((o) => {
				o.selectable = false;
			});
		} else if (!this.canvas.selection) {
			this.canvas.selection = true;
			this.canvas.forEachObject((o) => {
				o.selectable = true;
			});
		}
	}

	render() {
		return (
			<div
				ref={this.ref}
				className={`canvas-wrapper${this.props.disableEditing ? " disabled" : ""}`}
				id={`cw${this.props.index}`}
				onClick={this.props.setActivePage}
			>
				<span>Sida: {this.props.index + 1}</span>
				<div className="canvas-container-wrapper">
					<canvas id={`c${this.props.index}`} />
				</div>
				<CanvasButtonSidebar
					removePage={this.props.removePage}
					clearCanvas={this.props.clearCanvas}
					duplicatePage={this.props.duplicatePage}
					movePageUp={this.props.movePageUp}
					movePageDown={this.props.movePageDown}
				/>
			</div>
		);
	}
}
export default Canvas;

import { Component } from "react";
import Moment from "react-moment";
import { Badge, Link } from "@shopify/polaris";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { store } from "../../store";
import IndexTable from "../../components/IndexTable/IndexTable";

class LedgerCheckinIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	showTime(minutes) {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60 + " tim";
			} else {
				const mins = minutes % 60;
				const hours = (minutes - mins) / 60;
				return hours + " tim, " + mins + " min";
			}
		} else {
			return minutes + " min";
		}
	}

	handleBulkRemove(ids) {
		this.setState({ loadingRemoving: true });

		API.delete("/api/ledger_checkins/delete.json", { data: { ids } })
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				toastr.success(`${ids?.length} Tids registreringar borttagna`);
				this.refresh();
				this.indexTableRef?.clearSelection();
			})
			.finally(() => {
				this.setState({ loadingRemoving: false });
			});
	}

	render() {
		const filters = [
			{
				key: "active",
				label: "Incheckad",
				type: "select",
				options: [{ label: "Ja", value: "1" }],
			},
			{
				key: "checkin_at",
				label: "Starttid",
				minKey: "checkin_at_min",
				maxKey: "checkin_at_max",
				dateOptionType: "past",
				type: "dateSelector",
			},
			{
				label: "Starttid",
				key: "checkin_at_min",
				type: "date",
			},
			{
				label: "Starttid",
				key: "checkin_at_max",
				type: "date",
			},
		];

		const columns = [
			{
				label: "Incheckad",
				handle: "CHECKIN_AT",
				render: (item) => {
					return <Moment format="YYYY-MM-DD">{item.checkin_at}</Moment>;
				},
			},
			{
				label: "Arbetsorder",
				handle: "WORK_ORDER",
				sortable: true,
				render: (item) => {
					if (store.getState().account.ue) return item.work_order.full_label || "-";

					return item.work_order ? (
						<div
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<Link url={"/admin/work_orders/" + item.work_order.id}>{item.work_order.full_label}</Link>
						</div>
					) : (
						"–"
					);
				},
			},
			{
				label: "Företag",
				handle: "COMPANY",

				render: (item) => {
					return `${item.company_name}`;
				},
			},
			{
				label: "Användare",
				handle: "USER",
				sortable: true,
				render: (item) => {
					return item.user.name;
				},
			},
			{
				label: "Utchecked",
				handle: "CHECKOUT_AT",
				render: (item) => {
					return item.checkout_at ? this.showTime(item.total_minutes) : <Badge status="attention">incheckad</Badge>;
				},
			},
		];

		const promotedBulkActions = [
			{
				content: `Ta bort`,
				loading: this.state.loadingRemoving,
				onAction: this.handleBulkRemove.bind(this),
				destructive: true,
			},
		];

		return (
			<div>
				<IndexTable
					columns={columns}
					defaultSort="ID_DESC"
					history={this.props.history}
					onClickRow={(item) => {
						this.props.history.push("/admin/ledger_checkins/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Personalliggare"
					savedSearchHandle="ledger_checkins"
					resourceUrl="/api/ledger_checkins.json"
					resourceHandle="ledger_checkins"
					filters={filters}
					resourceName={{
						singular: "registrering",
						plural: "registreringar",
					}}
					promotedBulkActions={promotedBulkActions}
				/>
			</div>
		);
	}
}

export default LedgerCheckinIndex;

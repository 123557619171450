import { Component } from "react";
import { Badge, Avatar } from "@shopify/polaris";
import IndexTable from "../../components/IndexTable";
import { Title } from "../../components/IndexTable/styledComponents";

class UnderentreprenorIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getStatus(status) {
		if (status == "pending") {
			return <Badge>inbjuden</Badge>;
		}
		if (status == "accepted") {
			return <Badge status="success">godkänd</Badge>;
		}
		if (status == "declined") {
			return <Badge status="warning">nekad</Badge>;
		}
		return status;
	}

	render() {
		const columns = [
			{
				label: "Namn",
				handle: "TITLE",
				sortable: true,
				render: (item) => {
					return (
						<Title>
							<Avatar customer size="medium" name={item.name} /> <b>{item.name}</b>
						</Title>
					);
				},
			},
			{
				label: "Status",
				handle: "STATUS",
				sortable: true,
				render: (item) => {
					return this.getStatus(item.status);
				},
			},
			{
				label: "E-post",
				handle: "EMAIL",
				sortable: true,
				render: (item) => {
					return item.email;
				},
			},
			{
				label: "Konto-id",
				handle: "ACCOUNT_ID",
				render: (item) => {
					return item.account_id;
				},
			},
		];

		return (
			<div>
				<IndexTable
					history={this.props.history}
					defaultSort="ID_DESC"
					columns={columns}
					onClickRow={(item) => {
						this.props.history.push("/admin/underentreprenorer/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Underentreprenörer"
					primaryAction={{
						content: "Bjud in UE",
						onAction: () => {
							this.props.history.push("/admin/underentreprenorer/new");
						},
					}}
					savedSearchHandle="underentreprenorer"
					resourceUrl="/api/underentreprenorer.json"
					resourceHandle="underentreprenorer"
					resourceName={{
						singular: "underentreprenör",
						plural: "underentreprenörer",
					}}
				/>
			</div>
		);
	}
}

export default UnderentreprenorIndex;

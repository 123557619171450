import React, { Component } from "react";
import { Page, PageActions, Layout, Card, FormLayout, TextField, Select, Stack, OptionList, Checkbox } from "@shopify/polaris";
import { toastr } from "../../components/toastr";
import { store } from "../../store";

import SearchField from "../../components/search_field.js";
import API from "../../API";

class UserCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			form: {
				company_code: store.getState().account.id,
				shared_expenses: null,
				hourly_employee: 0,
				first_name: "",
				checkin_code: "",
				days_of_no_notification: {},
				id_number: "",
				last_name: "",
				username: "",
				email: "",
				phone: "",
				password: "",
				employee_number: "",
				hourly_wage: "",
				accounts: [store.getState().account.id],
				groups: [],
				roles: ["ROLE_USER"],
			},
			languages: [],
		};
	}

	componentDidMount() {
		this.fetchLanguages();
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/users.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				if (result.data.old_t24_user) {
					toastr.success(
						"Varning!",
						"Användaren fanns redan i gamla T24. Vänligen säkerställ att det är korrekt annars kommer timmarna in på fel person."
					);
				} else {
					toastr.success("Användaren skapades");
				}
				this.props.history.replace("/admin/users/" + result.data.user.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	toggleAdmin() {
		var formState = this.state.form;
		if (this.state.form.roles.indexOf("ROLE_ADMIN") >= 0) {
			formState.roles = [];
		} else {
			formState.roles = ["ROLE_ADMIN"];
		}
		this.setState({ form: formState });
	}

	toggleGroup(group) {
		var found = false;
		for (var i = 0; i < this.state.form.groups.length; i++) {
			if (group.id == this.state.form.groups[i].id) {
				found = i;
				break;
			}
		}

		if (found === false) {
			group.admin = false;
			if (this.state.form.groups.length < 1) {
				group.main = true;
			} else {
				group.main = false;
			}
			this.state.form.groups.push(group);
		} else {
			this.state.form.groups.splice(found, 1);
		}
		this.setState({ form: this.state.form });
	}

	fetchLanguages() {
		API.get("/api/languages.json").then((response) => {
			this.setState({ languages: response.data.languages });
		});
	}

	render() {
		const accountOptions = store
			.getState()
			.accounts.map((acc, key) => ({ value: acc.id, disabled: acc.id == store.getState().account.id, label: acc.company }));

		const roleOptions = [{ value: "USERS", label: "Manage Users" }];

		return (
			<Page
				title="Ny användare"
				backAction={{ content: "Användare", url: "/admin/users" }}
				primaryAction={{ content: "Lägg till", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection
						title="Grunduppgifter"
						description="Här anges grundläggande information om personen. Ange gärna ett mobilnummer till användaren eftersom vi kommer skicka ett SMS till användaren med länken till appen samt dess inloggningsuppgifter."
					>
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Användarnamn *" value={this.state.form.username} onChange={this.updateForm.bind(this, "username")} />
									<Stack distribution="fillEvenly">
										<TextField label="Förnamn *" value={this.state.form.first_name} onChange={this.updateForm.bind(this, "first_name")} />
										<TextField label="Efternamn *" value={this.state.form.last_name} onChange={this.updateForm.bind(this, "last_name")} />
									</Stack>
									<TextField label="E-mail" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} />
									<TextField label="Mobilnummer" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} />
									<TextField
										type="password"
										label="Lösenord ( för app och adminsystem )"
										value={this.state.form.password}
										onChange={this.updateForm.bind(this, "password")}
									/>
									<Select
										label="Språk"
										value={this.state.form.language ? `${this.state.form.language.id}` : null}
										options={this.state.languages.map((item) => ({
											label: item.language,
											value: `${item.id}`,
										}))}
										placeholder="Välj språk"
										onChange={(value) => {
											const language = this.state.languages.find((language) => language.id == value);
											this.state.form.language = language;
											this.setState({ form: this.state.form });
										}}
									/>
								</FormLayout>
							</Card.Section>
							{!store.getState().account.ue ? (
								<Card.Section title="Rättigheter">
									<Checkbox
										checked={this.state.form.roles.indexOf("ROLE_ADMIN") >= 0}
										label="Denna användaren är admin"
										onChange={this.toggleAdmin.bind(this)}
									/>
									{/* eslint-disable-next-line no-constant-condition */}
									{this.state.form.roles.indexOf("ROLE_ADMIN") < 0 && false ? (
										<OptionList onChange={this.updateForm.bind(this, "roles")} options={roleOptions} selected={this.state.form.roles} allowMultiple />
									) : null}
								</Card.Section>
							) : null}
						</Card>
					</Layout.AnnotatedSection>

					{!store.getState().account.ue ? (
						<Layout.AnnotatedSection title="Övriga uppgifter" description="Här anges övriga inställningar för personen">
							<Card sectioned>
								<FormLayout>
									<TextField label="Timlön inkl avgifter" value={this.state.form.hourly_rate} onChange={this.updateForm.bind(this, "hourly_rate")} />
									<TextField
										label="OB vardag lön inkl avgifter"
										value={this.state.form.hourly_ob_rate}
										onChange={this.updateForm.bind(this, "hourly_ob_rate")}
									/>
									<TextField
										label="OB helg lön inkl avgifter"
										value={this.state.form.hourly_ob2_rate}
										onChange={this.updateForm.bind(this, "hourly_ob2_rate")}
									/>
									<TextField
										label="Timlön övertid inkl avgifter"
										value={this.state.form.hourly_overtime_rate}
										onChange={this.updateForm.bind(this, "hourly_overtime_rate")}
									/>
									<TextField
										label="Anställningsnummer"
										value={this.state.form.employee_number}
										onChange={this.updateForm.bind(this, "employee_number")}
									/>
									<Stack distribution="fillEvenly">
										<Select
											label="Timanställd"
											options={[
												{ label: "Nej", value: "0" },
												{ label: "Ja", value: "1" },
											]}
											onChange={this.updateForm.bind(this, "hourly_employee")}
											value={this.state.form.hourly_employee + ""}
										/>
									</Stack>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>
					) : null}

					{store.getState().account.has_personell_ledger ? (
						<Layout.AnnotatedSection
							title="Personalliggare"
							description="För att föra personalliggare till skatteverket krävs ett id-nummer för personalen"
						>
							<Card sectioned>
								<FormLayout>
									<TextField
										label="Personnr/Samordnnr/Annat nr"
										value={this.state.form.id_number}
										onChange={this.updateForm.bind(this, "id_number")}
									/>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{!store.getState().account.ue ? (
						<Layout.AnnotatedSection title="Grupper" description="En användare kan tillhöra flera grupper">
							<Card sectioned title="Grupper">
								<SearchField
									placeholder="Sök efter grupper att lägga till"
									resource="groups"
									resourceName={{ singular: "grupp", plural: "grupper" }}
									label_handle="name"
									onSelect={this.toggleGroup.bind(this)}
								/>
								{this.state.form.groups.map((group, key) => (
									<div key={key}>{group.name}</div>
								))}
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					{accountOptions.length > 1 ? (
						<Layout.AnnotatedSection title="Accounts" description="Users can be connected to multiple accounts">
							<Card sectioned>
								<OptionList
									onChange={this.updateForm.bind(this, "accounts")}
									options={accountOptions}
									selected={this.state.form.accounts}
									allowMultiple
								/>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
				</Layout>
				<PageActions
					primaryAction={{
						content: "Lägg till",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default UserCreate;

import { Button, Checkbox, Collapsible, Heading, Icon, Modal, Spinner, Stack } from "@shopify/polaris";
import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import QuoteRowsTable from "../Quotes/QuoteRowsTable";
import { toastr } from "../../components/toastr";
import API from "../../API";

class InvoiceCreateModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				project: {},
				quotes: [],
				rows: [],
				showAdditionalRows: false,
				additionalRows: [{}],
			},
		};

		this.updateForm = (field, value) => {
			this.state.form[field] = value;
			this.setState({ form: this.state.form });
		};

		this.updateRows = this.updateForm.bind(this, "rows");
	}

	componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			if (props.open) {
				this.state.form.project = props.project;
				this.fetch(props.project.id);
			} else {
				this.setState({
					form: {
						rows: [],
						additionalRows: [{}],
						project: {},
						quotes: [],
						showAdditionalRows: false,
					},
				});
			}
		}
	}

	fetch(id) {
		this.setState({ loading: true });
		API.get(`/api/projects/${id}/quotes/rows.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					return;
				}
				this.state.form.quotes = result.data.quotes;
				if (!result.data?.quotes?.length) {
					this.state.showAdditionalRows = true;
				}
				this.setState({ form: this.state.form });
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	componentDidMount() {
		this.setState({ form: { project: this.props.project, rows: [], quotes: [], additionalRows: [{}] } });
	}

	saveForm() {
		if (
			this.state.form &&
			((this.state.form.rows && this.state.form.rows.length) ||
				(this.state.form.additionalRows &&
					this.state.form.additionalRows?.filter((i) => i && (typeof i === "object" ? Object.keys(i)?.length : true)).length)) &&
			this.state.form.project &&
			this.state.form.project.id
		) {
			this.setState({ saving: true });
			API.post(`/api/projects/${this.state.form.project.id}/invoices.json`, {
				rows: this.state.form.rows,
				additionalRows: this.state.form.additionalRows,
			})
				.then((result) => {
					if (result.data.error) {
						this.setState({ saving: false });
						return;
					}

					this.setState({ saving: false });
					if (this.props.onComplete) this.props.onComplete(result.data.invoice);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			toastr.warning("Måste ha minst en rad och ett projekt kopplat");
		}
	}

	setRow(row, index) {
		if (!this.state.form.additionalRows) {
			this.state.form.additionalRows = [];
		}
		// const position = typeof index === "number" ? index : this.state.form.additionalRows.length;

		this.state.form.additionalRows[index] = row;
		// if (this.state.form.additionalRows.every((r) => Object.keys(r).length)) {
		// if (this.state.form.additionalRows.filter((r) => !Object.keys(r).length).length < 2) {
		// 	this.state.form.additionalRows.push({});
		// }

		if (index === this.state.form.additionalRows.length - 1) {
			this.state.form.additionalRows.push({});
		}

		this.setState({ form: this.state.form });
	}

	addRow(field, index, value) {
		if (!this.state.form.additionalRows) {
			this.state.form.additionalRows = [];
		}
		this.state.form.additionalRows[index] = Object.assign(this.state.form.additionalRows[index] || {}, { [field]: value });

		if (index === this.state.form.additionalRows.length - 1) {
			this.state.form.additionalRows.push({});
		}
		this.setState({ form: this.state.form });
	}

	removeRow(index) {
		this.state.form.additionalRows.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	render() {
		const { handleClose, open } = this.props;
		const { saving } = this.state;
		// eslint-disable-next-line no-unused-vars
		const { project, quotes } = this.state.form || {};

		const primaryAction = {
			content: "Skapa",
			loading: saving,
			onAction: this.saveForm.bind(this),
		};

		return (
			<Modal
				title="Skapa faktura"
				size="Full"
				large
				open={open}
				onClose={handleClose}
				primaryAction={primaryAction}
				secondaryActions={[{ content: "Avbryt", onAction: handleClose, disabled: this.state.loading }]}
			>
				<Modal.Section>
					<Stack vertical spacing="extraLoose">
						{this.state.loading ? (
							<Stack vertical alignment="center">
								<Spinner />
							</Stack>
						) : (
							<Stack.Item>
								<QuoteTable
									quotes={quotes || []}
									columns={[
										{
											header: "Art.nr",
											handle: "article",
											style: {},
										},
										{
											header: "Benämning",
											handle: "description",
											style: {
												width: "100%",
											},
										},
										{
											header: "Apris",
											handle: "a_price",
											style: {},
										},
										{
											header: "Antal",
											handle: "count",
											style: {},
										},
										{
											header: "Enhet",
											handle: "unit",
											style: {},
										},
									]}
									onSelectionChange={this.updateRows}
									selected={this.state.form.rows || []}
								/>
							</Stack.Item>
						)}
						<Stack.Item>
							<Stack vertical>
								<Stack.Item>
									<Button monochrome plain onClick={() => this.setState({ showAdditionalRows: !this.state.showAdditionalRows })}>
										<Stack spacing="tight" alignment="center">
											<Heading>Lägg till ytterligare rader</Heading>
											<Icon source={this.state.showAdditionalRows ? ChevronUpMinor : ChevronDownMinor} />
										</Stack>
									</Button>
								</Stack.Item>
								<Collapsible open={this.state.showAdditionalRows}>
									<Stack.Item>
										<QuoteRowsTable
											form={{ rows: this.state.form.additionalRows }}
											setRow={this.setRow.bind(this)}
											addRow={this.addRow.bind(this)}
											removeRow={this.removeRow.bind(this)}
										/>
									</Stack.Item>
								</Collapsible>
							</Stack>
						</Stack.Item>
					</Stack>
				</Modal.Section>
			</Modal>
		);
	}
}
export default InvoiceCreateModal;

const QuoteTable = ({ quotes, columns, onSelectionChange, selected }) => {
	const [selectedRows, setSelectedRows] = useState([].concat(selected || []));

	const allSelected = (rows) => (rows || []).every((row) => selectedRows.includes(row));

	const updateSelection = (row) => {
		const index = selectedRows.indexOf(row);
		if (index > -1) {
			setSelectedRows((curr) => curr.filter((_, i) => i !== index));
		} else {
			setSelectedRows((curr) => [].concat(curr, [row]));
		}
	};

	const updateMultipleRows = (rows) => {
		if (allSelected(rows)) {
			setSelectedRows((curr) => curr.filter((item) => !rows.includes(item)));
		} else {
			setSelectedRows((curr) =>
				[].concat(
					curr.filter((i) => !rows.includes(i)),
					rows
				)
			);
		}
	};

	useEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selectedRows);
		}
	}, [selectedRows, onSelectionChange]);

	return (
		<Stack vertical>
			{quotes.map((quote, index) => (
				<Stack.Item key={index}>
					<Stack vertical>
						<Stack.Item>
							<Heading>{`Offert ${quote.title || "-"}`}</Heading>
						</Stack.Item>
						<Table>
							<thead>
								<tr>
									<th>
										<Checkbox checked={allSelected(quote.rows)} onChange={updateMultipleRows.bind(this, quote.rows)} />
									</th>
									{columns.map((column, index) => (
										<th key={index} style={column.style}>
											{column.header}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{(quote.rows || []).map((row, index) => (
									<tr key={index}>
										<td>
											<Checkbox checked={selectedRows.find((i) => i.id == row.id)} onChange={updateSelection.bind(this, row)} />
										</td>
										{columns.map((column, index) => (
											<td key={index}>{row[column.handle]}</td>
										))}
									</tr>
								))}
							</tbody>
						</Table>
					</Stack>
				</Stack.Item>
			))}
		</Stack>
	);
};

const Table = styled.table`
	width: 100%;
	padding: 0 1rem;

	th,
	td {
		text-align: start;
		padding: 0 1rem;
	}
`;

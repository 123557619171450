import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Frame, ContextualSaveBar, TopBar, Loading, Toast, Card, ActionList, HorizontalStack } from "@shopify/polaris";
import { LogOutMinor, ProfileMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import GithubVersionChecker from "./components/GithubVersionChecker.js";
import { store } from "./store";
import { getRoutes } from "./routes.js";
import MainMenu from "./components/MainMenu.js";
import "./Properties";
import "./language";
import "pdfjs";
import API from "./API";
import { toastr } from "./components/toastr.js";
import FolderOpenIcon from "./icons/FolderOpenIcon.js";
import DocumentIcon from "./icons/DocumentIcon.js";
import People4Icon from "./icons/People4Icon.js";
import People2Icon from "./icons/People2Icon.js";
import ProfileAvatar from "./components/ProfileAvatar.js";
import SendMessageModal from "./components/sendMessage/SendMessage";
import RecommendModal from "./components/Recommend/Recommend";
import TimeReportOpenModalButton from "./views/TimeReports/TimeReportOpenModalButton";
import { TimeReportContextProivder } from "./views/TimeReports/TimeReportContext";
import { FortnoxGlobalContextProvider } from "./views/Fortnox/ContextProvider/FortnoxGlobalContext";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: null,
			cancelToken: null,
			searchResults: [],
			search: "",
			showToast: false,
			searchActive: false,
			userMenuOpen: false,
		};
	}

	componentDidMount() {
		// if (store.getState().account.disabled) {
		// 	window.location.href = "/admin/authentication/logout";
		// }
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	updateSearch(value) {
		if (!value) {
			this.setState({ search: value, searchActive: false, searchResults: [] });
		}
		clearTimeout(this.state.timeout);
		if (value) {
			this.setState({ search: value, timeout: setTimeout(this.doSearch.bind(this, value), 300) });
		}
	}

	doSearch(value) {
		store.dispatch({ type: "IS_LOADING", loading: true });
		this.cancelRequest();
		const CancelToken = axios.CancelToken;
		API.get("/api/search.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params: { q: value },
		})
			.then((result) => {
				store.dispatch({ type: "IS_LOADING", loading: false });
				this.setState({ searchActive: true, searchResults: result.data.results });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	gotoLink(url) {
		this.props.history.push(url);
		this.setState({ searchActive: false, search: "", showMobileNavigation: false });
	}

	getIcon(item) {
		switch (item.type) {
			case "projects":
				return FolderOpenIcon;
			case "quotes":
				return DocumentIcon;
			case "customers":
				return People4Icon;
			case "contacts":
				return People2Icon;
			// handled in getContent instead to be able to render <ProfileAvatar />
			// case "users": {
			// 	return ProfileMajor;
			// }
			default:
				return undefined;
		}
	}

	getContent(item) {
		switch (item.type) {
			case "users": {
				const user = store.getState().users.find((user) => user.id == item.id);
				if (user)
					return (
						<HorizontalStack gap="4">
							<ProfileAvatar user={user} size={20} />
							<span>{item.title}</span>
						</HorizontalStack>
					);
				return ProfileMajor;
			}
			default:
				return item.title;
		}
	}

	render() {
		const searchFieldMarkup =
			store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && !store.getState().account.ue ? (
				<TopBar.SearchField
					onChange={(value) => {
						this.updateSearch(value);
					}}
					value={this.state.search}
					placeholder="Sök..."
				/>
			) : null;

		const userMenuActions = [
			{
				items: [
					{
						content: "Logga ut",
						icon: LogOutMinor,
						onAction: () => {
							window.location.href = "/admin/authentication/logout";
						},
					},
				],
			},
		];

		const userMenuMarkup = (
			<TopBar.UserMenu
				actions={userMenuActions}
				name={store.getState().user.username}
				detail={"Kontoid: " + store.getState().account.id}
				open={this.state.userMenuOpen}
				avatar={store.getState().user.avatar}
				onToggle={(state) => {
					this.setState({ userMenuOpen: state });
				}}
			/>
		);

		const searchResultsMarkup = (
			<Card>
				<ListWrapper>
					{this.state.searchResults.length > 0 ? (
						<ActionList
							items={this.state.searchResults.map((item, key) => ({
								content: this.getContent(item),
								onAction: this.gotoLink.bind(this, item.url),
								icon: this.getIcon(item),
							}))}
						/>
					) : (
						<div style={{ padding: 20 }}>No results found</div>
					)}
				</ListWrapper>
			</Card>
		);

		const topBarMarkup = (
			<TopBar
				showNavigationToggle
				userMenu={userMenuMarkup}
				searchResultsVisible={this.state.searchActive}
				searchField={searchFieldMarkup}
				searchResults={searchResultsMarkup}
				onSearchResultsDismiss={() => {
					this.setState({ searchActive: !this.state.searchActive, search: "" });
				}}
				onNavigationToggle={(state) => {
					this.setState({ showMobileNavigation: !this.state.showMobileNavigation });
				}}
				secondaryMenu={
					<div style={{ marginInline: "1rem", width: "max-content" }}>
						<HorizontalStack gap="3">
							<RecommendModal />
							<SendMessageModal />
							<TimeReportOpenModalButton />
						</HorizontalStack>
					</div>
				}
			/>
		);

		var isDirty = store.getState().savable;

		const contextualSaveBarMarkup = isDirty ? (
			<ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: this.handleSave,
				}}
				discardAction={{
					onAction: this.handleDiscard,
				}}
			/>
		) : null;

		const loadingMarkup = store.getState().loading ? <Loading /> : null;

		const toastMarkup = this.state.showToast ? (
			<Toast
				onDismiss={(showToast) => {
					this.setState({ showToast });
				}}
				content="Changes saved"
			/>
		) : null;

		// const navigationUserMenuMarkup = (
		// 	<TopBar.UserMenu
		// 		actions={userMenuActions}
		// 		name={store.getState().user.username}
		// 		detail={"Kontoid: " + store.getState().account.id}
		// 		avatarSource={store.getState().user.avatar}
		// 	/>
		// );

		const navigationMarkup = (
			<MainMenu
				onCloseMenu={() => {
					this.setState({ showMobileNavigation: false });
				}}
				location={this.props.location}
				history={this.props.history}
			/>
		);

		return (
			<FortnoxGlobalContextProvider>
				<TimeReportContextProivder>
					<Frame
						topBar={topBarMarkup}
						navigation={navigationMarkup}
						showMobileNavigation={this.state.showMobileNavigation}
						onNavigationDismiss={() => {
							this.setState({ showMobileNavigation: !this.state.showMobileNavigation });
						}}
					>
						{contextualSaveBarMarkup}
						{loadingMarkup}
						<GithubVersionChecker />
						<Switch>
							{getRoutes().map((prop, key) => {
								if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
								return <Route path={prop.path} exact={prop.exact} component={prop.component} key={key} />;
							})}
						</Switch>
						{toastMarkup}
					</Frame>
				</TimeReportContextProivder>
			</FortnoxGlobalContextProvider>
		);
	}
}

export default connect(
	(state) => ({
		savable: state.savable,
		loading: state.loading,
		account: state.account,
	}),
	{}
)(App);

const ListWrapper = styled.div`
	svg {
		width: 20px;
		height: 20px;
	}

	svg:not([filltype="stroke"]) {
		fill: var(--color-primary);
	}

	svg[filltype="stroke"] path {
		stroke: var(--color-primary);
	}

	svg[fill="none"] {
		fill: none;
	}
`;

import { Component } from "react";
import { Modal, Select } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import moment from "moment";
import IndexTable from "../../components/IndexTable";
import { toastr } from "../../components/toastr";
import Badge from "../../components/Badge";
import { store } from "../../store";

import TimeRangeModal from "../../components/TimeRangeModal.js";
import API from "../../API";

class ProjectIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changeStatusModalOpen: false,
			timeRangeModalOpen: false,
			selectedItems: [],
			newStatus: "",
			saving: false,
			params: {},
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	gotoProject(item) {
		this.props.history.push("/admin/projects/" + item.id);
	}

	updateParams(params) {
		this.setState({ params });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printReports() {
		this.setState({ timeRangeModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	printRange(fromDate, toDate) {
		this.closeTimeRangeModal();
		var projectIds = this.state.selectedItems;
		var query = {
			ids: projectIds,
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
	}

	changeStatus() {
		this.setState({ changeStatusModalOpen: true });
	}

	doUpdateStatus() {
		this.setState({ saving: true });
		var projectIds = this.state.selectedItems;
		API.put("/api/projects/bulk_edit.json", { status: this.state.newStatus }, { params: { ids: projectIds } })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				this.refresh();
				this.setState({ changeStatusModalOpen: false });
				toastr.success("Projekt ändrat");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	exportCurrentProjects(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		window.open("/api/projects/export.xls?" + httpBuildQuery(params));
	}

	renderCell(item, column) {
		const status = (() => {
			if (item.status == "done") {
				return (
					<Badge key="status" status="attention">
						Färdig
					</Badge>
				);
			} else if (item.status == "active") {
				return (
					<Badge key="status" progress={50} status="new">
						Aktiv
					</Badge>
				);
			} else if (item.status == "pending") {
				return (
					<Badge key="status" progress={50}>
						Kommande
					</Badge>
				);
			} else if (item.status == "sleep") {
				return (
					<Badge key="status" progress={0}>
						Vilande
					</Badge>
				);
			} else if (item.status == "invoiced") {
				return (
					<Badge key="status" status="success">
						Fakturerad
					</Badge>
				);
			}
		})();

		if (column.handle == "TITLE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						{/* <span className="CustomerListItem__Location">{status}</span> */}
					</div>
				</div>
			);
		}
		if (column.handle == "STATUS") {
			return status;
		}
		if (column.handle == "PROJECT_NR") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.reference}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "CUSTOMER") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.customer && item.customer.name}</h3>{" "}
						<span className="CustomerListItem__OrderCount">{item.public ? <Badge status="info">Synlig för alla</Badge> : null}</span>
					</div>
				</div>
			);
		}
		if (column.handle == "START_AT") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{moment(item.start_at).format("YYYY-MM-DD")}</h3>
					</div>
				</div>
			);
		}

		return column.handle;
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		var filters = [
			{
				key: "status",
				label: "Status",
				operatorText: "är",
				type: "select",
				options: [
					{ label: "Vilande", value: "sleep" },
					{ label: "Kommande", value: "pending" },
					{ label: "Aktiv", value: "active" },
					{ label: "Färdig", value: "done" },
					{ label: "Fakturerad", value: "invoiced" },
				],
			},
			{
				key: "personell_ledger",
				label: "Har personalliggare",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "tagged",
				label: "Taggad",
				type: "textField",
			},
			{
				key: "ata",
				label: "ÄTA",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "start_at_period",
				label: "Förväntat startdatum",
				type: "select",
				options: [
					{ label: "Nästa månad", value: "next_month" },
					{ label: "Denna månad", value: "this_month" },
				],
			},
			{
				key: "end_at_period_max",
				label: "Slutdatum senast",
				type: "date",
			},
		];

		let promotedBulkActions = [];

		if (!store.getState().user.read_only) {
			promotedBulkActions = [
				{
					content: "Skriv ut rapporter",
					onAction: this.printReports.bind(this),
				},
				{
					content: "Ändra status",
					onAction: this.changeStatus.bind(this),
				},
			];
		}

		const columns = [
			{
				handle: "TITLE",
				label: "Benämning",
				sortable: true,
			},
			{
				handle: "STATUS",
				label: "Status",
				sortable: true,
			},
			{
				handle: "PROJECT_NR",
				label: "Projektnummer",
				sortable: true,
			},
			{
				handle: "CUSTOMER",
				label: "Kund",
				sortable: true,
			},
			{
				handle: "START_AT",
				label: "Startar",
			},
		];

		return (
			<div>
				<IndexTable
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					history={this.props.history}
					title="Projekt"
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Nytt projekt",
									onAction: () => {
										this.props.history.push("/admin/projects/new", {
											customer: this.props.customer,
										});
									},
							  }
					}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{
										content: "Exportera projekt",
										onAction: () => {
											this.setState({ changeTimePeriodModalOpen: true });
										},
									},
							  ]
							: []
					}
					defaultSavedSearches={[
						{
							title: "Aktiv",
							filters: [
								{
									key: "status",
									value: "active",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_active",
						},
						{
							title: "Kommande",
							filters: [
								{
									key: "status",
									value: "pending",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_pending",
						},
						{
							title: "Vilande",
							filters: [
								{
									key: "status",
									value: "sleep",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_sleep",
						},
						{
							title: "Fakturerade",
							filters: [
								{
									key: "status",
									value: "invoiced",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_invoiced",
						},
						{
							title: "Färdiga",
							filters: [
								{
									key: "status",
									value: "done",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_done",
						},
					]}
					savedSearchHandle="projects"
					params={{
						customer_id: this.props.customer && this.props.customer.id,
					}}
					resourceUrl="/api/projects.json"
					resourceHandle="projects"
					resourceName={{
						singular: "projekt",
						plural: "projekt",
					}}
					onSelectionChange={this.onSelectionChange.bind(this)}
					selectedItems={this.state.selectedItems}
					onUpdateParams={this.updateParams.bind(this)}
					renderCell={this.renderCell.bind(this)}
					filters={filters}
					columns={columns}
					defaultSort="LAST_ACTIVITY_DESC"
					promotedBulkActions={promotedBulkActions}
					onClickRow={this.gotoProject.bind(this)}
				/>
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
				/>
				<Modal
					title="Ändra status"
					open={this.state.changeStatusModalOpen}
					onClose={() => {
						this.setState({ changeStatusModalOpen: false });
					}}
					primaryAction={{
						content: "Uppdatera",
						loading: this.state.saving,
						disabled: !this.state.newStatus,
						onAction: this.doUpdateStatus.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ changeStatusModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<Select
							label="Status"
							options={[
								{ label: "Välj ny status", value: "" },
								{ label: "Vilande", value: "sleep" },
								{ label: "Kommande", value: "pending" },
								{ label: "Aktiv", value: "active" },
								{ label: "Färdig", value: "done" },
								{ label: "Fakturerad", value: "invoiced" },
							]}
							onChange={(value) => {
								this.setState({ newStatus: value });
							}}
							value={this.state.newStatus}
						/>
					</Modal.Section>
				</Modal>

				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentProjects.bind(this)}
				/>
			</div>
		);
	}
}

export default ProjectIndex;

import { FormLayout, Layout } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import { toastr } from "src/js/components/toastr";

type SendAsEmailModalProps = {
	type: "order" | "offer" | "invoice" | "contract";
	open: boolean;
	onClose: () => void;
	onSend: (form: EmailInformation) => void;
	EmailInformation: EmailInformation | null | undefined;
	loading?: boolean;
	offer?: FortnoxOfferType | null;
	order?: FortnoxOrderType | null;
	invoice?: FortnoxInvoiceType | null;
} & (
	| {
			type: "order";
			order: FortnoxOrderType | null;
	  }
	| {
			type: "offer";
			offer: FortnoxOfferType | null;
	  }
	| {
			type: "invoice";
			invoice: FortnoxInvoiceType | null;
	  }
);

const SendAsEmailModal = ({ type, open, onClose, onSend, EmailInformation, loading, order, offer, invoice }: SendAsEmailModalProps) => {
	const [form, setForm] = useState<EmailInformation>(EmailInformation as EmailInformation);
	const [isSending, setIsSending] = useState(false);

	useEffect(() => {
		setForm(EmailInformation as EmailInformation);
	}, [EmailInformation]);

	const singular = (() => {
		switch (type) {
			case "order":
				return "order";
			case "offer":
				return "offer";
			case "invoice":
				return "invoice";
			default:
				return null;
		}
	})();

	const handleChange = (field: string) => (value: any) => {
		const newForm = { ...form };
		newForm[field] = value;
		setForm(newForm);
	};

	const handleSend = async () => {
		try {
			setIsSending(true);
			return await onSend(form);
		} catch (error) {
			toastr.error(error);
		} finally {
			setIsSending(false);
		}
	};

	const disabled = (() => {
		switch (type) {
			case "order":
				return order?.Cancelled;
			case "offer":
				return offer?.Cancelled;
			case "invoice":
				return invoice?.Cancelled;
			default:
				return false;
		}
	})();

	return (
		<Modal
			large
			open={open}
			onClose={onClose}
			title={`Skicka ${singular} som e-post`}
			primaryAction={{
				content: "Skicka",
				onAction: handleSend,
				loading: isSending || loading,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: onClose,
				},
			]}
			sectioned
		>
			<Layout>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField disabled={disabled} label={"Svarsadress"} value={form?.EmailAddressFrom} onChange={handleChange("EmailAddressFrom")} />
						<TextField disabled={disabled} label={"Mottagare"} value={form?.EmailAddressTo} onChange={handleChange("EmailAddressTo")} />
					</FormLayout>
				</Layout.Section>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField disabled={disabled} label={"Kopia"} value={form?.EmailAddressCC} onChange={handleChange("EmailAddressCC")} />
						<TextField disabled={disabled} label={"Hemlig kopia"} value={form?.EmailAddressBCC} onChange={handleChange("EmailAddressBCC")} />
					</FormLayout>
				</Layout.Section>
				<Layout.Section>
					<FormLayout>
						<TextField disabled={disabled} label={"Ämne"} value={form?.EmailSubject} onChange={handleChange("EmailSubject")} multiline />
						<TextField disabled={disabled} label={"Text"} value={form?.EmailBody} onChange={handleChange("EmailBody")} multiline={3} />
					</FormLayout>
				</Layout.Section>
			</Layout>
		</Modal>
	);
};
export default SendAsEmailModal;

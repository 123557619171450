import { Component } from "react";
import { Modal, Badge } from "@shopify/polaris";
import Moment from "react-moment";
import httpBuildQuery from "http-build-query";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";
import ResourcePicker from "../../components/resource_picker.js";
import TimeRangeModal from "../../components/TimeRangeModal.js";
import { toastr } from "../../components/toastr.js";
import API from "../../API";

class IncidentIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConnectProjectModal: false,
			project: null,
			saving: false,
			params: {},
		};
	}

	gotoIncident(incidentId) {
		this.props.history.push("/admin/incident_reporting/" + incidentId, { workOrder: this.props.workOrder });
	}

	closeConnectProjectModal() {
		this.setState({ showConnectProjectModal: false });
	}

	updateParams(params) {
		this.setState({ params });
	}

	exportCurrentProjects(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		params.accident = 0;
		params.avvikelse = 0;
		window.open("/api/incidents/export.xls?" + httpBuildQuery(params));
	}

	setProject(project) {
		this.setState({ project });
	}

	doConnectProject() {
		this.setState({ saving: true });
		API.put("/api/documents/" + this.state.currentDocument.id + ".json", { project_id: this.state.project.id })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Kopplat till projekt");
				this.closeConnectProjectModal();
				this.refresh();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		const columns = [
			{
				label: "Användare",
				handle: "USER",
				sortable: true,
				render: (item) => {
					return <b> {item.user.name}</b>;
				},
			},
			{
				label: "Plats",
				handle: "AREA",
				render: (item) => {
					return item.area ? item.area.full_label : item.work_order.full_label;
				},
			},
			{
				label: "Status",
				handle: "STATUS",
				sortable: true,
				render: (item) => {
					if (item.status == "pending") return <Badge>Ej hanterad</Badge>;
					if (item.status == "noticed") return <Badge status="info">Uppmärksammad</Badge>;
					if (item.status == "ongoing") return <Badge status="info">Pågående</Badge>;
					if (item.status == "done") return <Badge status="success">Avslutad</Badge>;
				},
			},
			{
				label: "Skapad",
				handle: "CREATED_AT",
				sortable: true,
				render: (item) => {
					return (
						<Moment locale="sv" format="YYYY-MM-DD">
							{item.occured_at}
						</Moment>
					);
				},
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					columns={columns}
					history={this.props.history}
					onClickRow={(item) => {
						this.gotoIncident(item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title={!this.props.workOrder && "Registrerade tillbud"}
					savedSearchHandle="incidents"
					resourceUrl="/api/incidents.json?accident=0"
					resourceHandle="incidents"
					resourceName={{
						singular: "tillbud",
						plural: "tillbud",
					}}
					secondaryActions={
						store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0
							? [
									{
										content: "Exportera",
										onAction: () => {
											this.setState({ changeTimePeriodModalOpen: true });
										},
									},
							  ]
							: []
					}
					params={{ workorder_id: this.props.workOrder && this.props.workOrder.id }}
					onUpdateParams={this.updateParams.bind(this)}
				/>
				<Modal
					title="Koppla till projekt"
					open={this.state.showConnectProjectModal}
					onClose={() => {
						this.setState({ showConnectProjectModal: false });
					}}
					primaryAction={{
						content: "Koppla",
						loading: this.state.saving,
						disabled: !this.state.project,
						onAction: this.doConnectProject.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ showConnectProjectModal: false });
							},
						},
					]}
				>
					{this.state.showConnectProjectModal ? (
						<Modal.Section>
							<ResourcePicker
								resource="projects"
								resourceName={{
									singular: "projekt",
									plural: "projekt",
								}}
								label_handle="title"
								onChange={this.setProject.bind(this)}
							/>
						</Modal.Section>
					) : null}
				</Modal>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentProjects.bind(this)}
				/>
			</div>
		);
	}
}

export default IncidentIndex;

import PreApp from "./PreApp.js";
import SplashLoadingScreen from "./SplashLoadingScreen.tsx";
import Login from "./login/Login.js";
import Logout from "./logout/index.js";
import UploadView from "./views/Uploads/index.tsx";

// const PreApp = React.lazy(() => import("./PreApp.js"));
// const UploadView = React.lazy(() => import("./views/Uploads/index.tsx"));

const parentRoutes = [
	{
		path: "/admin/authentication/login",
		exact: true,
		component: Login,
	},
	{
		path: "/admin/authentication/logout",
		exact: true,
		component: Logout,
	},
	{
		path: "/admin/uploads/:hash",
		exact: true,
		component: UploadView,
	},
	{ path: "/admin", name: "Home", component: PreApp },
	{ path: "/api", name: "Loading", component: SplashLoadingScreen },
	{ path: "/", redirect: "/admin", enabled: process.env.NODE_ENV === "development", component: SplashLoadingScreen },
].filter((route) => !("enabled" in route) || route.enabled);

export default parentRoutes;

import { OptionList, Stack, TextField } from "@shopify/polaris";
import React, { useState } from "react";
import { store } from "src/js/store";

const UsersFilter = ({ value, onChange }) => {
	const [search, setSearch] = useState("");
	const options = store
		.getState()
		.users?.filter((user) => {
			const filterRegex = new RegExp(search, "i");
			return (filterRegex.test(user.name) || filterRegex.test(user.email) || filterRegex.test(user.phone)) && user.enabled && !user.removed;
		})
		?.map((user) => ({
			label: user.name,
			value: user.id.toString(),
		}));

	return (
		<Stack vertical>
			<TextField placeholder="Sök.." labelHidden label="" autoComplete="false" value={search} onChange={setSearch} />
			<OptionList allowMultiple options={options} selected={value || []} onChange={onChange} />
		</Stack>
	);
};
export default UsersFilter;

export default (state = [], action) => {
	switch (action.type) {
		case "ADD_SALES_CHANNEL":
			var newState = state.slice();
			newState.push(action.salesChannel);
			return newState;
		default:
			return state;
	}
};

import { Modal } from "@shopify/polaris";
import React from "react";

const FortnoxReconnectModal = ({ open, onClose, handleReconnect }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			title={"Återkoppla Fortnox"}
			primaryAction={{
				content: "Återkoppla Fortnox",
				onAction: handleReconnect,
			}}
			secondaryActions={[
				{
					content: "Cancel",
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<p>{"Fortnox har tappat anslutningen. Du måste återkoppla för att fortsätta använda Fortnox."}</p>
			</Modal.Section>
		</Modal>
	);
};
export default FortnoxReconnectModal;

import { FormLayout, Modal } from "@shopify/polaris";
import moment from "moment";
import React, { useState } from "react";
import TimeReportModal from "src/js/components/TimeReportModal";
import SimpleTimeReportEditModal from "src/js/components/SimpleTimeReportEditModal";
import styled from "styled-components";
import Badge from "src/js/components/Badge";

type DeviatingRegistrationsModalProps = {
	open?: boolean;
	onClose: () => void;
	onComplete: (timereport?: any) => void;
	onDelete: (timereport?: any) => void;
	user: any;
	item?: any;
};

const DeviatingRegistrationsModal = ({ open, onClose, onComplete, onDelete, user, item }: DeviatingRegistrationsModalProps) => {
	const [selected, setSelected] = useState<any>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [editIsOpen, setEditIsOpen] = useState(false);

	const minutesToHours = (minutes: number | any) => {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60;
			} else {
				const mins = minutes % 60;
				const hours = (minutes - mins) / 60;
				return hours + "." + mins;
			}
		} else {
			return "0." + minutes;
		}
	};

	const totalMinutes = item?.time_reports?.reduce((acc, curr) => acc + (curr.total_minutes || 0), 0) || 0;

	return (
		<>
			<Modal
				open={!!open}
				onClose={onClose}
				title={`Avvikelser för ${user?.name} den ${moment(item?.date).format("YYYY-MM-DD")}`}
				secondaryActions={[{ content: "Stäng", onAction: onClose }]}
				primaryAction={{
					content: "Ny registrering",
					onAction: () => {
						setIsOpen(true);
					},
				}}
			>
				<Modal.Section>
					<InenrWrapper>
						<FormLayout>
							<p>
								Planerad arbetstid: {item?.work_minutes} min ({minutesToHours(item?.work_minutes)}h)
							</p>

							<p>
								Rapporterad arbetstid: {totalMinutes} min ({minutesToHours(totalMinutes)}h)
							</p>
						</FormLayout>
					</InenrWrapper>
					<Table>
						<thead>
							<tr>
								<th>Projekt</th>
								<th>Arbetsorder</th>
								<th>Timmar</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{item?.time_reports?.map((timereport) => {
								const project = timereport?.work_order?.project;
								return (
									<Item
										key={timereport.id}
										onClick={() => {
											setSelected(timereport);
											setEditIsOpen(true);
										}}
									>
										<td>{project?.title}</td>
										<td>{timereport?.work_order?.title}</td>

										<td>{minutesToHours(timereport?.minutes)} tim</td>
										<td>{timereport.attest ? <Badge status="success">Attesterad</Badge> : ""}</td>
									</Item>
								);
							})}
						</tbody>
					</Table>
				</Modal.Section>
			</Modal>

			<SimpleTimeReportEditModal
				timereport={selected}
				open={editIsOpen}
				onDelete={() => {
					setEditIsOpen(false);
					setSelected(null);
					onDelete?.(selected);
				}}
				onComplete={(timereport) => {
					setEditIsOpen(false);
					setSelected(null);
					if (timereport) onComplete?.(timereport);
				}}
				onClose={() => {
					setEditIsOpen(false);
					setSelected(null);
				}}
			/>

			<TimeReportModal
				date={item?.date}
				timereport={selected}
				open={isOpen}
				onClose={() => {
					setIsOpen(false);
					setSelected(null);
				}}
				onComplete={(timereport) => {
					setIsOpen(false);
					setSelected(null);
					onComplete?.(timereport);
				}}
				user_id={item?.user?.id}
				// user_id={store.getState().user.id}
				user_name={item?.user?.name}
				lock_user
			/>
		</>
	);
};
export default DeviatingRegistrationsModal;

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 0.5rem;
	margin-left: 0;
	background-color: var(--primary);
	border-radius: 6px;
	margin-top: 1.5rem;
	/* margin-left: -1rem; */

	td,
	th {
		transition: background-color 0.2s ease-in-out;
		text-align: left;

		padding: 1rem;
	}
`;

const Item = styled.tr`
	cursor: pointer;
`;

// &:hover {
// 	background-color: var(--primary);
// }

const InenrWrapper = styled.div`
	/* padding-inline: 1rem; */
`;

export default (state = {}, action) => {
	switch (action.type) {
		case "SET_USER":
			return { ...action.user };
		case "ADD_USER_ROLE":
			state.roles.push(action.role);
			return { ...state };
		case "UPDATE_ME":
			return { ...state, ...action.user };
		default:
			return state;
	}
};

import React from "react";
import styled from "styled-components";
import ProjectInvoiceRow from "./ProjectInvoiceRow";

interface InvoiceRowsTableProps {
	rows: any[];
	locked?: boolean;
	setRows: (rows: any[]) => void;
	highlights?: any;
	supplierInvoice?: FortnoxSupplierInvoice;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvoiceRowsTable = ({ rows, locked, setRows, highlights, supplierInvoice }: InvoiceRowsTableProps) => {
	const onChange = (index: number) => {
		return (row) => {
			// if (typeof row === "function" ) {
			// 	const newRow = row(rows[index]);
			// 	rows[index] = newRow;
			// 	setRows([...rows]);
			// }
			rows[index] = row;
			setRows([...rows]);
			// onChange(key, value);
		};
	};

	const onRemove = (index: number) => {
		rows.splice(index, 1);

		setRows([...rows]);
	};

	return (
		<TableWrapper>
			<thead>
				<tr>
					<th>Benämning</th>
					<th>Antal</th>
					<th>Enhet</th>
					<th>Styckpris</th>
					<th>Belopp (exkl. moms)</th>
					<th>Påslag %</th>
					<th>Att vidarefakturera</th>
					<th />
				</tr>
			</thead>

			<tbody>
				{rows?.map((row: any, index) => {
					return (
						<ProjectInvoiceRow
							key={index}
							index={index}
							row={row}
							onChange={onChange(index)}
							onRemove={() => onRemove(index)}
							hightlight={highlights?.includes(row?.id)}
							supplierInvoice={supplierInvoice}
						/>
					);
				})}
			</tbody>
		</TableWrapper>
	);
};
export default InvoiceRowsTable;

const TableWrapper = styled.table`
	width: 100%;

	.Polaris-TextField {
		--p-color-bg-disabled: var(--subdued);

		&.--readOnly {
			--p-color-bg: var(--subdued);
		}
	}
`;

import React from "react";

const AlarmClock = ({ color = "var(--textColor)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.0684 9.77393V16.7739H23.0684" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M16.0684 27.7739C22.1435 27.7739 27.0684 22.8491 27.0684 16.7739C27.0684 10.6988 22.1435 5.77393 16.0684 5.77393C9.99323 5.77393 5.06836 10.6988 5.06836 16.7739C5.06836 22.8491 9.99323 27.7739 16.0684 27.7739Z"
			stroke={color}
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path d="M24.5557 4.04893L28.7932 8.28643" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3.34326 8.28643L7.58076 4.04893" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default AlarmClock;

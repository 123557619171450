import { Component } from "react";
import { Button, Modal, ResourceList, Spinner, TextField } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { toastr } from "../../components/toastr.js";
import SearchField from "../../components/search_field.js";
import MapWithKml from "./MapWithKml";
import API from "../../API";

class MapWithKmlAndSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areas: [],
			latitude: null,
			longitude: null,
			show: props.show,
		};
	}

	componentWillReceiveProps(props) {
		if (props.show != this.state.show) {
			this.setState({ show: props.show });
		}
	}

	selectAddress(address) {
		this.setState({ loadingAreas: true });
		API.get("/api/work_orders/area_suggestions.json", {
			params: { latitude: address.geometry.location.lat, longitude: address.geometry.location.lng },
		})
			.then((result) => {
				result.data.areas.forEach((area) => {
					this.state.areas.push(
						Object.assign(
							{},
							{
								coordinates: area,
								title: address.formatted_address,
								longitude: address.geometry.location.lng,
								latitude: address.geometry.location.lat,
							}
						)
					);
				});

				this.setState({
					latitude: address.geometry.location.lat,
					longitude: address.geometry.location.lng,
					areas: this.state.areas,
					loadingAreas: false,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	renderArea(item, index) {
		return (
			<ResourceList.Item id={index}>
				<TextField
					label="Title"
					value={item.title}
					onChange={(value) => {
						this.state.areas[index].title = value;
						this.setState({ areas: this.state.areas });
					}}
					type="text"
					connectedRight={
						<Button
							destructive
							icon={DeleteMajor}
							onClick={() => {
								this.state.areas.splice(index, 1);
								this.setState({ areas: this.state.areas });
							}}
						/>
					}
				/>
			</ResourceList.Item>
		);
	}

	render() {
		return (
			<Modal
				title="Lägg till Fastighetsområden"
				open={this.state.show}
				onClose={() => {
					this.setState({ show: false, areas: [] });
				}}
				primaryAction={{
					content: "Använd dessa områdena",
					onAction: () => {
						this.props.addArea(this.state.areas);
						this.setState({ show: false, areas: [] });
					},
					loading: this.state.saving || this.state.loadingAreas,
				}}
				secondaryActions={[
					{
						content: "Använd inte dessa områdena",
						onAction: () => {
							this.setState({ show: false, areas: [] });
						},
					},
				]}
			>
				<Modal.Section>
					<SearchField
						placeholder="Sök address"
						resource="google_address"
						resourceName={{
							singular: "adress",
							plural: "adresser",
						}}
						onSelect={this.selectAddress.bind(this)}
					/>
				</Modal.Section>

				{this.state.areas && this.state.areas.length && this.state.latitude && this.state.longitude ? (
					!this.state.loadingAreas ? (
						<div style={{ height: 400 }}>
							<MapWithKml
								myLat={this.state.latitude}
								myLng={this.state.longitude}
								areas={this.state.areas.map((area, index) => ({ id: index, color: "0000ff", coordinates: area.coordinates }))}
								height="100%"
								zIndexValue="1"
							/>
						</div>
					) : (
						<Spinner size="small" color="teal" />
					)
				) : null}

				<ResourceList resourceName={{ singular: "provider", plural: "providers" }} items={this.state.areas} renderItem={this.renderArea.bind(this)} />
			</Modal>
		);
	}
}

export default MapWithKmlAndSearch;

import { Icon, Page, Stack } from "@shopify/polaris";
import { ExportMinor } from "@shopify/polaris-icons";
import httpBuildQuery from "http-build-query";
import moment from "moment";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import API from "src/js/API";
import Card from "src/js/components/Card";
import TimeRangeModal from "src/js/components/TimeRangeModal";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";

type ExportLinksProps = any;
type ExportLinksState = {
	agressoModalOpen: boolean;
	vismaModalOpen: boolean;
	briljantModalOpen: boolean;
};

class ExportLinks extends Component<ExportLinksProps, ExportLinksState> {
	constructor(props: ExportLinksProps) {
		super(props);
		this.state = {
			agressoModalOpen: false,
			vismaModalOpen: false,
			briljantModalOpen: false,
		};
	}

	closeAgressoModal() {
		this.setState({ agressoModalOpen: false });
	}

	exportVisma() {
		this.setState({ vismaModalOpen: true });
	}

	exportAgresso() {
		this.setState((c) => ({ agressoModalOpen: !c.agressoModalOpen }));
	}

	exportBriljant() {
		this.setState((c) => ({ briljantModalOpen: !c.briljantModalOpen }));
	}

	getYmd(date) {
		return moment(date).format("YYYY-MM-DD");
	}

	doExportAgresso(fromDate, toDate, exportOnlyNew) {
		this.closeAgressoModal();
		API.get("/api/agresso/export.json", {
			params: {
				from: this.getYmd(fromDate),
				to: this.getYmd(toDate),
				export_only_new: exportOnlyNew ? "1" : "0",
			},
		})
			.then((result) => {
				if (result.data.error) {
					return;
				}
				toastr.success("Mail skickades");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	doExportVisma(groups, users, fromDate, toDate, exportOnlyNew) {
		this.closeVismaModal();

		const query = {
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		} as any;

		if (groups.length > 0) {
			query.ids = groups.map((group) => group.id);

			window.open("/api/visma/export.xml?" + httpBuildQuery(query));
		} else if (users.length > 0) {
			query.ids = users.map((user) => user.id);

			window.open("/api/visma/export.xml?" + httpBuildQuery(query));
		} else {
			window.open("/api/visma/export.xml?" + httpBuildQuery(query));
		}
	}

	closeVismaModal() {
		this.setState({ vismaModalOpen: false });
	}

	closeBriljantModal() {
		this.setState({ briljantModalOpen: false });
	}

	doExportBriljant(groups, users, fromDate, toDate, exportOnlyNew) {
		this.closeBriljantModal();

		const query = {
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
			export_only_new: exportOnlyNew ? "1" : "0",
		} as any;

		if (groups.length > 0) {
			query.ids = groups.map((group) => group.id);

			window.open("/api/briljant/export.csv?" + httpBuildQuery(query));
		} else if (users.length > 0) {
			query.ids = users.map((user) => user.id);

			window.open("/api/briljant/export.csv?" + httpBuildQuery(query));
		} else {
			window.open("/api/briljant/export.csv?" + httpBuildQuery(query));
		}
	}

	render(): React.ReactNode {
		const exportLinks: any[] = [];
		const fromDate = new Date();
		const toDate = new Date();

		if (store.getState().account.visma_enabled) {
			exportLinks.push({
				label: "Visma lön 300/600",
				icon: ExportMinor,
				onClick: this.exportVisma.bind(this),
				description: "Exportera till Visma lön 300/600",
			});
		}

		if (store.getState().account.briljant_enabled) {
			exportLinks.push({
				label: "Briljant",
				icon: ExportMinor,
				onClick: this.exportBriljant.bind(this),
				description: "Exportera till Briljant",
			});
		}

		if (store.getState().account.agresso_enabled) {
			exportLinks.push({
				label: "Agresso",
				icon: ExportMinor,
				onClick: this.exportAgresso.bind(this),
				description: "Exportera till Agresso",
			});
		}

		if (exportLinks.length < 1) {
			exportLinks.push({
				label: "Ej konfigurerat",
				icon: ExportMinor,
				url: "/admin/settings/integrations",
				description: "Konfigurera integrationer",
			});
		}

		return (
			<Page title="Exportera">
				<Card sectioned>
					<Stack distribution="fillEvenly">
						{exportLinks.map((prop, key) => {
							if (prop.url) {
								return (
									<NavLink to={prop.url} key={key} className="settings-item">
										<div className="icon-wrapper">
											<Icon source={prop.icon} />
										</div>
										<div>
											<p>{prop.label}</p>
											<span>{prop.description}</span>
										</div>
									</NavLink>
								);
							}

							return (
								<div key={key} className="settings-item" onClick={prop.onClick}>
									<div className="icon-wrapper">
										<Icon source={prop.icon} />
									</div>
									<div>
										<p>{prop.label}</p>
										<span>{prop.description}</span>
									</div>
								</div>
							);
						})}
					</Stack>
				</Card>

				<TimeRangeModal
					noreport
					open={this.state.agressoModalOpen}
					onClose={this.closeAgressoModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.doExportAgresso.bind(this)}
					weeks
				/>

				<TimeRangeModal
					noreport
					open={this.state.vismaModalOpen}
					onClose={this.closeVismaModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.doExportVisma.bind(this)}
					usergroup
				/>

				<TimeRangeModal
					noreport
					open={this.state.briljantModalOpen}
					onClose={this.closeBriljantModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.doExportBriljant.bind(this)}
					usergroup
					exportonlynew
				/>
			</Page>
		);
	}
}
export default ExportLinks;

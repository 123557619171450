/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { colorToRgba } from "src/js/Colors";
import styled, { css } from "styled-components";

import React, { useCallback, useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import { Button, ButtonGroup, ChoiceList, FormLayout, Icon, Modal } from "@shopify/polaris";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";

//! NOT FINISHED
const Schedule = ({ id }) => {
	const [viewFrom, setViewFrom] = useState();
	const [viewTo, setViewTo] = useState();
	const [events, setEvents] = useState([]);
	const [weekdays, setWeekdays] = useState([]);
	const [options, setOptions] = useState({});
	const [dates, setDates] = useState([]);
	const [open, setOpen] = useState(false);
	const [form, setForm] = useState();
	const [times, setTimes] = useState({
		from_time: store.getState().account.workday_start,
		to_time: store.getState().account.workday_end,
	});

	const leadingZero = (val) => {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	};

	const parseEvents = (events) => {
		for (var i = 0; i < events.length; i++) {
			if (events[i].type == "lunch") {
				events[i].title = "L";
			} else if (events[i].type == "work") {
				events[i].title = "A";
			} else if (events[i].type == "flex") {
				events[i].title = "F";
			}
			events[i].start = new Date(events[i].event_start_at);
			events[i].end = new Date(events[i].event_end_at);

			console.debug("events[i]:", events[i]);
			console.debug("events[i].type:", events[i].type);
			const color = (() => {
				switch (events[i].type) {
					case "lunch":
						return "#8B5CF6";
					case "work":
					default:
						return "#0EA5E9";
				}
			})();
			events[i].color = color;
		}
		return events;
	};

	const fetchEvents = useCallback(
		(id, fromDate, toDate) => {
			setViewFrom(fromDate);
			setViewTo(toDate);

			var from = fromDate.getFullYear() + "-" + leadingZero(fromDate.getMonth() + 1) + "-" + leadingZero(fromDate.getDate());
			var to = toDate.getFullYear() + "-" + leadingZero(toDate.getMonth() + 1) + "-" + leadingZero(toDate.getDate());
			API.get("/api/users/" + id + "/work_times.json", {
				params: { from, to },
			})
				.then((result) => {
					setEvents(parseEvents(result.data.work_times));
				})
				.catch((error) => {
					toastr.error(error);
				});
		},
		[setViewFrom, setEvents, setViewTo]
	);

	const rangeChange = useCallback(
		(range) => {
			let fromDate = null;
			let toDate = null;
			if (range.start) {
				fromDate = range.start;
				toDate = range.end;
			} else {
				fromDate = null;
				toDate = null;
				for (var i = 0; i < range.length; i++) {
					if (!fromDate) {
						fromDate = range[i];
					}
					toDate = range[i];
				}
			}

			fetchEvents(id, fromDate, toDate);
		},
		[fetchEvents, id]
	);

	const getWeekdayName = (weekDay) => {
		if (weekDay == 0) {
			return "Söndag";
		} else if (weekDay == 1) {
			return "Måndag";
		} else if (weekDay == 2) {
			return "Tisdag";
		} else if (weekDay == 3) {
			return "Onsdag";
		} else if (weekDay == 4) {
			return "Torsdag";
		} else if (weekDay == 5) {
			return "Fredag";
		} else if (weekDay == 6) {
			return "Lördag";
		}
	};

	const handleSelect = (selection) => {
		var dates = [];
		var weekDays = [];
		for (var i = 0; i < selection.slots.length; i++) {
			var ymd =
				selection.slots[i].getFullYear() + "-" + leadingZero(selection.slots[i].getMonth() + 1) + "-" + leadingZero(selection.slots[i].getDate());
			if (dates.indexOf(ymd) < 0) {
				dates.push(ymd);
			}
			if (weekDays.indexOf(getWeekdayName(selection.slots[i].getDay())) < 0) {
				weekDays.push(getWeekdayName(selection.slots[i].getDay()));
			}
		}
		let toTime = null;
		let fromTime = null;
		if (dates.length > 1) {
			toTime = store.getState().account.workday_end;
			fromTime = store.getState().account.workday_start;
		} else {
			toTime = leadingZero(selection.end.getHours()) + ":" + leadingZero(selection.end.getMinutes());
			fromTime = leadingZero(selection.start.getHours()) + ":" + leadingZero(selection.start.getMinutes());
		}

		setOpen(true);
		setTimes({
			to_time: toTime,
			from_time: fromTime,
		});
		setForm({});
		setDates(dates);
		setWeekdays(weekDays);

		// this.setState({
		// 	to_time: toTime,
		// 	from_time: fromTime,
		// 	weekdays: weekDays,
		// 	dates,
		// 	showCreateEventModal: true,
		// 	editingEvent: null,
		// });
	};

	const editEvent = (item) => {
		var dates = [];
		var weekDays = [];
		var start = new Date(item.start_at);
		var end = new Date(item.end_at);
		var ymd = start.getFullYear() + "-" + leadingZero(start.getMonth() + 1) + "-" + leadingZero(start.getDate());
		if (dates.indexOf(ymd) < 0) {
			dates.push(ymd);
		}
		if (weekDays.indexOf(getWeekdayName(start.getDay())) < 0) {
			weekDays.push(getWeekdayName(start.getDay()));
		}

		var toTime = leadingZero(end.getHours()) + ":" + leadingZero(end.getMinutes());
		var fromTime = leadingZero(start.getHours()) + ":" + leadingZero(start.getMinutes());

		console.debug("item:", item);
		setForm(item);
		setOpen(true);
		setDates(dates);
		setWeekdays(weekDays);
		setOptions({
			selectedRemoveOption: "all",
			editingEvent: item,
			repeat_holidays: item.repeat_holidays,
			recurring: item.recurring ? item.recurring : "single",
			type: item.type,
		});
		setTimes({
			to_time: toTime,
			from_time: fromTime,
		});
	};

	useEffect(() => {
		const getMonday = (d) => {
			var day = d.getDay();
			var diff = d.getDate() - day + (day == 0 ? -6 : 1);
			return new Date(d.setDate(diff));
		};

		const getSunday = (d) => {
			return nextDate(0);
		};

		const nextDate = (dayIndex) => {
			var today = new Date();
			today.setDate(today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1);
			return today;
		};

		var monday = getMonday(new Date());
		var sunday = getSunday(new Date());
		fetchEvents(id, monday, sunday);
	}, [fetchEvents, id]);

	moment.locale("sv");
	const localizer = momentLocalizer(moment);

	return (
		<Calendar
			components={{
				week: {
					header: (props) => {
						return (
							<ColumnHeader>
								<p className="date-name">{moment(props.date).format("dddd").capitalize()}</p>

								<p className="date">{moment(props.date).format("DD")}</p>
							</ColumnHeader>
						);
					},
				},
				eventWrapper: ({ style, event, ...props }) => {
					return (
						<Event
							style={{
								...style,
								top: `${style.top}%`,
								height: `${style.height}%`,
							}}
							color={event.color}
						>
							chidlren
						</Event>
					);
				},

				// dateCellWrapper: (props) => {
				// 	return (
				// 		<div>
				// 			{moment(props.date).format("dddd").capitalize()}

				// 			{moment(props.date).format("DD")}
				// 		</div>
				// 	);
				// 	// return <div>dateCellWrapper</div>;
				// },

				toolbar: ({ onNavigate, date, label }) => {
					return (
						<Wrapper>
							<h3>
								Vecka {moment(date).isoWeek()} {moment(date).year()}
							</h3>
							<p className="period">Period: {label}</p>

							<ButtonGroup spacing="extraTight">
								<Button
									icon={ChevronLeftMinor}
									onClick={() => {
										onNavigate("PREV", date);
									}}
								/>
								<Button>Vecka</Button>
								<Button
									icon={ChevronRightMinor}
									onClick={() => {
										onNavigate("NEXT", date);
									}}
								/>
							</ButtonGroup>
						</Wrapper>
					);
				},
			}}
			selectable
			step={30}
			defaultView={Views.WEEK}
			onRangeChange={rangeChange}
			localizer={localizer}
			events={events}
			onSelectEvent={editEvent}
			onSelectSlot={handleSelect}
			views={{ week: true }}
			messages={{ today: false, previous: <Icon source={ChevronLeftMinor} />, next: <Icon source={ChevronRightMinor} /> }}
		/>
	);
};

export default Schedule;

export const Wrapper = styled.div`
	padding-block: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	h3 {
		font-size: 22px;
		font-weight: 700;
		line-height: 16px;
		text-transform: capitalize;
	}

	p.period {
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;
	}

	.Polaris-ButtonGroup {
		--p-space-1: 0.15rem;

		.Polaris-ButtonGroup__Item:not(:first-of-type):not(:last-of-type) {
			.Polaris-Button {
				--p-border-radius-1: 0;
				/* pointer-events: none; */

				&[aria-disabled="true"] {
					background: var(--p-color-bg);
					color: var(--p-color-text);
				}
			}
		}

		.Polaris-ButtonGroup__Item:first-of-type {
			.Polaris-Button {
				--p-border-radius-1: 6px;

				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}

		.Polaris-ButtonGroup__Item:last-of-type {
			.Polaris-Button {
				--p-border-radius-1: 6px;

				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}
		}
	}
`;

export const ColumnHeader = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px;
	text-align: start;

	.date-name {
		font-size: 10px;
		font-weight: 700;
		line-height: 12px;
	}

	.date {
		font-size: 22px;
		font-weight: 500;
		line-height: 32px;
	}
`;

export const Event = styled.div`
	position: absolute;
	border-radius: 3px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	background-color: ${({ color }) => colorToRgba(color, 0.75)};
	border-left: ${({ color }) => `3px solid ${color}`};
	${({ view, type }) => {
		if (view !== "day") {
			return css`
				padding: 3px;
			`;
		}
	}}

	.time {
		font-size: 10px;

		white-space: pre-line;
		/* word-break: break-word; */
		overflow: hidden;
	}

	.title {
		white-space: pre-line;
		/* word-break: break-word; */
		overflow: hidden;
	}

	&:hover {
		background-color: ${({ color }) => colorToRgba(color, 1)};
		/* z-index: 1; */
	}
`;

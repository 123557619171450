import { Button, Heading, Icon, Sheet, Spinner, Tooltip } from "@shopify/polaris";
import { MobileCancelMajor, SearchMajor } from "@shopify/polaris-icons";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import API from "src/js/API";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import TextField from "src/js/components/TextField";
import { toastr } from "src/js/components/toastr";
import styled from "styled-components";

const UpcomingProjectsSheet = ({ activator, openPlanning }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [projects, setProjects] = useState([]);
	const [count, setCount] = useState(0);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const ref = useRef<any | null>(null);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const handleSearch = (value: string) => {
		setSearch(value);

		const params = {
			q: value,
		};

		fetchUpcomingProjectsDelayed(params);
	};

	const fetchUpcomingProjects = useCallback(async (propsParams = {}) => {
		setLoading(true);
		const params = {
			start_at_period_min: moment().format("YYYY-MM-DD"),
			limit: 1000,
			status: ["active", "pending"],
			...propsParams,
		};
		await API.get("/api/projects.json", {
			params,
		})
			.then((result) => {
				setProjects(result.data.projects);
				setCount(result.data.count);
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
		setLoading(false);
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchUpcomingProjectsDelayed = useCallback(debounce(fetchUpcomingProjects, 500, { maxWait: 3000, leading: false, trailing: true }), []);

	useEffect(() => {
		if (isOpen && ref.current) {
			const sheet = ref.current.closest("[data-portal-id]");
			sheet.classList.add("sheet-600");
		}
	}, [isOpen]);

	useEffect(() => {
		fetchUpcomingProjects();
	}, [fetchUpcomingProjects]);

	return (
		<>
			{activator === undefined ? <Button onClick={handleOpen}>Lista över projekt {`(${count})`}</Button> : activator}
			<Sheet accessibilityLabel="" open={isOpen} onClose={handleClose}>
				<div
					ref={ref}
					style={{
						alignItems: "center",
						display: "flex",
						justifyContent: "space-between",
						padding: "1.6rem",
						width: "100%",
					}}
				>
					<Heading>Förväntade kommande projekt</Heading>
					<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={handleClose} plain />
				</div>
				<TextField
					style={{ paddingInline: "1.6rem" }}
					onChange={handleSearch}
					value={search}
					placeholder="Sök efter projekt.."
					prefix={loading ? <Spinner accessibilityLabel="Spinner" size="small" /> : <Icon source={SearchMajor} />}
				/>
				<ListWrapper>
					{!projects?.length && <p style={{ padding: "1.6rem" }}>Hittade inga aktiva/kommande projekt</p>}
					{projects?.map((project: any) => {
						return (
							<Item key={project.id} onClick={() => openPlanning(project)}>
								<Date>{moment(project.start_at).format("YYYY-MM-DD")}</Date>
								<Title>{project.title}</Title>
								{project.planning_events_count && (
									<Tooltip content={`${project.planning_events_count}st planeringar`}>
										<Badge alpha={0.8} color={Colors.green} style={{ color: "#ffffff" }}>
											{project.planning_events_count}
										</Badge>
									</Tooltip>
								)}
							</Item>
						);
					})}
				</ListWrapper>
			</Sheet>
		</>
	);
};
export default UpcomingProjectsSheet;

const ListWrapper = styled.div`
	overflow: auto;
	height: calc(100% - 120px);
`;
const Item = styled.button`
	display: flex;
	gap: 1rem;
	padding: 0.75rem 1.6rem;
	cursor: pointer;
	border: none;
	background: none;
	outline: none;
	transition: background 0.2s ease-in-out;
	color: var(--color-primary);
	width: 100%;

	&:hover {
		background: var(--tertiary);
	}
`;

const Date = styled.p`
	font-weight: 600;
`;
const Title = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
`;

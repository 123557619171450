import React, { useCallback, useEffect } from "react";
import { currencyFormatter } from "src/js/Utilities.js";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import StatsBoxWrapper from "../../components/statsBoxes";
import IconWithBackround from "../../components/IconWithBackround.js";
import ListIcon from "../../icons/List.js";
import Colors, { colorToRgba } from "../../Colors.js";
import CheckmarkIcon from "../../icons/CheckmarkIcon.js";
import DollarIcon from "../../icons/DollarIcon.js";
import { ClockIcon } from "src/js/icons";
import { toastr } from "src/js/components/toastr";

/* eslint-disable @typescript-eslint/no-unused-vars */
const ProjectStatBoxes = ({ project, id }) => {
	const queryKey = ["projects", id];
	const {
		data: stats,
		isFetching,
		refetch,
		error,
		isError,
	} = useQuery({
		queryFn: async () => {
			const res = await API.get(`/api/projects/${id}/stats.json`);
			return res?.data?.statistics || {};
		},
		enabled: !!id,
		queryKey,
		initialData: {},
	});

	useEffect(() => {
		console.debug("ProjectStatBoxes error:", error);
		console.debug("ProjectStatBoxes isError:", isError);
		if (isError && error) toastr.error(error);
	}, [error, isError]);

	const refresh = useCallback(() => {
		refetch();
	}, [refetch]);

	useEffect(() => {
		window.addEventListener("refreshProjectStats", refresh);

		return () => {
			window.removeEventListener("refreshProjectStats", refresh);
		};
	}, [refresh]);

	const convertMinutesToHours = (minutes: number): string => {
		if (typeof minutes !== "number") return "";
		const hours = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		return `${hours}h ${minutesLeft}m`;
	};

	return (
		<StatsBoxWrapper
			style={{ justifyContent: "flex-start" }}
			loading={isFetching}
			items={[
				{
					title: currencyFormatter({ value: stats.total_value?.total }),
					text: "Totalt värde",
					icon: <IconWithBackround icon={<ListIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
					helpText: (
						<div>
							<p>
								Fakturerat: <b>SEK {stats.total_value?.invoiced}</b>
								<br />
								Kvar att fakturera: <b>SEK {stats.total_value?.not_invoiced_and_should_be_invoiced}</b>
								<br />
								<br />
							</p>
							<p>
								Totalt värde: <b>Fakturerat + kvar att fakturera</b>
							</p>
						</div>
					),
				},
				{
					title: currencyFormatter({ value: stats.cost?.total }),
					text: "Kostnad",
					icon: <IconWithBackround icon={<DollarIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
					helpText: (
						<div>
							<p>
								Kostnad tidrapporter: <b>SEK {stats.cost?.time_reports}</b>
								<br />
								Ugifter: <b>SEK {stats.cost?.expense_reports}</b>
								<br />
								Leverantörsfakturor: <b>SEK {stats.cost?.supplier_invoices}</b>
								<br />
								<br />
							</p>
							<p>
								Totala kostnader: <b>Kostnader tidrapporter + utgifter + leverantörsfakturor</b>
							</p>
						</div>
					),
				},
				{
					title: currencyFormatter({ value: stats.result?.total }),
					text: "Resultat",
					icon: <IconWithBackround icon={<CheckmarkIcon color={Colors.white} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
					helpText: (
						<div>
							<p>
								Totalt värde: <b>SEK {stats.result?.total_value}</b>
								<br />
								Kostnader: <b>SEK {stats.result?.cost}</b>
								<br />
								<br />
							</p>
							<p>
								Resultat: <b>Totalt värde - kostnader</b>
							</p>
						</div>
					),
				},
				{
					title: currencyFormatter({ value: stats.invoiced?.total }),
					text: "Fakturerat",
					icon: <IconWithBackround icon={<ListIcon color={Colors.white} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
					helpText: stats.invoiced?.includes_fortnox ? (
						<div>
							<p>
								Fakturerat i Fortnox: <b>SEK {stats.invoiced?.fortnox_total}</b>
								<br />
								Manuellt markerat som fakturerat: <b>SEK {stats.invoiced?.manually_invoiced}</b>
								<br />
								<br />
							</p>
							<p>
								Totalt fakturerat: <b>Fakturerat i Fortnox + manuellt markerat som fakturerat</b>
							</p>
						</div>
					) : (
						<div>
							<p>
								Fakturerade tidrapporter: <b>SEK {stats.invoiced?.time_reports}</b>
								<br />
								Fakturerade ugifter: <b>SEK {stats.invoiced?.expense_reports}</b>
								<br />
								Fakturerade leverantörsfakturor: <b>SEK {stats.invoiced?.supplier_invoices}</b>
								<br />
								Fakturerade offerter: <b>SEK {stats.invoiced?.quotes}</b>
								<br />
								<br />
							</p>
							<p>
								Totalt fakturerat: <b>Fakturerade tidrapporter + fakturerade utgifter + fakturerade leverantörsfakturor + fakturerade offerter</b>
							</p>
						</div>
					),
				},
				{
					title: currencyFormatter({ value: stats.not_invoiced_and_should_be_invoiced?.total }),
					text: "Kvar att fakturera",
					icon: <IconWithBackround icon={<ListIcon color={Colors.white} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
					helpText: (
						<div>
							<p>
								Tidrapporter: <b>SEK {stats.not_invoiced_and_should_be_invoiced?.time_reports}</b>
								<br />
								Ugifter: <b>SEK {stats.not_invoiced_and_should_be_invoiced?.expense_reports}</b>
								<br />
								Leverantörsfakturor: <b>SEK {stats.not_invoiced_and_should_be_invoiced?.supplier_invoices}</b>
								<br />
								Offerter: <b>SEK {stats.not_invoiced_and_should_be_invoiced?.quotes}</b>
								<br />
								<br />
							</p>
							<p>
								Totala kostnader:{" "}
								<b>
									Tidrapporter kvar att fakturera + utgifter kvar att fakturera + leverantörsfakturor kvar att fakturera + offerter kvar att fakturera
								</b>
							</p>
						</div>
					),
				},
				{
					title: convertMinutesToHours(stats.time_reports?.total),
					text: "Totalt registrerade timmar",
					icon: <IconWithBackround icon={<ClockIcon color={Colors.white} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
					helpText: (
						<div>
							<p>
								Ordinarie: <b>{convertMinutesToHours(stats.time_reports?.not_ob)}</b>
							</p>

							<p>
								OB: <b>{convertMinutesToHours(stats.time_reports?.ob)}</b>
							</p>

							<p>
								Totalt: <b>Ordinarie</b> + <b>OB</b>
							</p>
						</div>
					),
				},
			]}
		/>
	);
};
export default ProjectStatBoxes;

import { Component } from "react";
import { Page, Layout, Banner, Card, FormLayout, TextField, Stack } from "@shopify/polaris";

import ResourcePicker from "src/js/components/resource_picker.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class ExpenseReportCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {},
			loading: false,
		};
		if (props?.location?.state?.project) {
			this.state.form.project = props.location.state.project;
			this.state.form.surcharge =
				props.location.state.project?.is_marked_as_should_invoice_expense_reports === true
					? props.location.state.project.purcharge_price_surcharge
					: "";
		}
	}

	saveForm() {
		this.setState({ loading: true });
		API.post("/api/expense_reports.json", this.state.form)
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					return;
				}
				this.setState({ loading: false, form: result.data.expense_report });
				toastr.success("Utgift skapad");
				this.props.history.goBack();
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	changeProject(project) {
		this.state.form.project = project;
		this.state.form.surcharge = project?.purcharge_price_surcharge;
		this.setState({ form: this.state.form });
	}

	changeExpense(expense) {
		this.state.form.expense = expense;
		this.state.form.unit_price = expense.purchase_price;
		this.setState({ form: this.state.form });
	}

	getChangeHandler(key) {
		if ((key === "quantity" || key === "unit_price") && this.state.form.quantity && this.state.form.unit_price) {
			this.state.form.amount = this.state.form.quantity * this.state.form.unit_price;
			if (this.state.form.surcharge !== "") this.state.form.amount_to_invoice = this.state.form.amount * (this.state.form.surcharge / 100 + 1);
		} else if (key === "amount" && this.state.form.surcharge !== "") {
			this.state.form.amount_to_invoice = (parseFloat(this.state.form.amount) * (this.state.form.surcharge / 100 + 1)).toFixed(0);
		} else if (key === "surcharge") {
			this.state.form.amount_to_invoice = this.state.form.surcharge
				? ((parseFloat(this.state.form.surcharge) / 100 + 1) * this.state.form.amount).toFixed(0)
				: "";
		} else if (key === "amount_to_invoice" && this.state.form.amount_to_invoice !== "") {
			this.state.form.surcharge = ((parseFloat(this.state.form.amount_to_invoice) / this.state.form.amount - 1) * 100).toFixed(0);
		}

		// this.state.form.surcharge = Math.max(parseFloat(this.state.form.surcharge.toFixed(0)), 0);

		this.setState({ ...this.state.form });
	}

	render() {
		var disabled = false;

		return (
			<Page
				title="Ny utgift"
				primaryAction={{ content: "Skapa", disabled, loading: this.state.loading, onAction: this.saveForm.bind(this) }}
				backAction={{
					content: "Tillbaka",
					onAction: () => {
						this.props.history.goBack();
					},
				}}
			>
				{disabled ? (
					<div style={{ marginBottom: 20 }}>
						<Banner title="Denna rapportering går inte längre att ändra, be din arbetsledare att göra det åt dig" status="warning" />
					</div>
				) : null}
				<Layout>
					<Layout.AnnotatedSection description="Välj vilket projekt denna utigft ska tillhöra">
						<Card sectioned>
							<FormLayout>
								<ResourcePicker
									placeholder="Sök projekt"
									caption="Välj projekt"
									label="Projekt"
									resourceName={{
										singular: "projekt",
										plural: "projekt",
									}}
									resource="projects"
									item={this.state.form.project}
									onChange={this.changeProject.bind(this)}
									label_handle="title"
								/>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Registrerad utgift" description="Detta är tid rapporterad av användaren">
						<Card sectioned title={this.state.form.expense?.title || "Välj artikel"}>
							<FormLayout>
								<ResourcePicker
									placeholder="Sök artiklar"
									caption="Välj artiklar"
									label="Artikel"
									resourceName={{
										singular: "artiklar",
										plural: "artikel",
									}}
									resource="expenses"
									item={this.state.form.expense}
									onChange={this.changeExpense.bind(this)}
									label_handle="title"
								/>
								<Stack distribution="fillEvenly">
									<TextField
										label="Antal"
										disabled={disabled}
										type="number"
										value={this.state.form.quantity}
										onChange={(value) => {
											this.state.form.quantity = value;
											this.getChangeHandler("quantity");
										}}
									/>
									<TextField
										label={"Pris per " + (this.state.form.expense?.unit || "")}
										disabled={disabled}
										type="currency"
										suffix="kr"
										value={this.state.form.unit_price}
										onChange={(value) => {
											this.state.form.unit_price = value;
											this.getChangeHandler("unit_price");
										}}
									/>
								</Stack>
								<Stack distribution="fillEvenly">
									<TextField
										label="Total kostnad"
										value={this.state.form.amount}
										onChange={(value) => {
											this.state.form.amount = value;
											this.getChangeHandler("amount");
										}}
										suffix="kr"
									/>

									<TextField
										label="Påslag %"
										value={this.state.form.surcharge}
										suffix="%"
										onChange={(value) => {
											this.state.form.surcharge = value;
											this.getChangeHandler("surcharge");
										}}
									/>

									<TextField
										label="Att vidarefakturera"
										type="currency"
										suffix="kr"
										value={this.state.form.amount_to_invoice}
										onChange={(value) => {
											this.state.form.amount_to_invoice = value;
											this.getChangeHandler("amount_to_invoice");
										}}
									/>
								</Stack>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
			</Page>
		);
	}
}

export default ExpenseReportCreate;

import { Component } from "react";
import { Badge, ResourceList } from "@shopify/polaris";
import Moment from "react-moment";

import "./item.css";

class TimeReportRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: props.item,
		};
	}

	showTime(minutes) {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60 + " tim";
			} else {
				var mins = minutes % 60;
				var hours = (minutes - mins) / 60;
				return hours + " tim, " + mins + " min";
			}
		} else {
			return minutes + " min";
		}
	}

	render() {
		const profile = (
			<div className="CustomerListItem__Profile">
				<h3 className="CustomerListItem__Title">
					<Moment format="YYYY-MM-DD">{this.state.item.checkin_at}</Moment>
				</h3>
				<span className="CustomerListItem__Location">
					{this.state.item.checkout_at && this.props.showTime ? (
						this.showTime(this.state.item.total_minutes)
					) : (
						<Badge status="attention">incheckad</Badge>
					)}
				</span>
				{!!(this.state.item.work_type && this.props.showType) && (
					<span className="CustomerListItem__Location">
						<Badge status="warning">{this.state.item.work_type && this.state.item.work_type.title}</Badge>
					</span>
				)}
				{!!(this.state.item && this.state.item.user && this.state.item.user.ue && this.props.showCompany) && (
					<span className="CustomerListItem__Location">
						<Badge status="attention">{this.state.item.user.company}</Badge>
					</span>
				)}
				{!!(this.state.item.work_order && this.props.showAo) && (
					<span className="CustomerListItem__Location">
						<Badge status="info">Arbetsorder: {this.state.item.work_order.label}</Badge>
					</span>
				)}

				{!!(this.state.item && this.state.item.work_order && this.state.item.work_order.project && this.props.showWorkorderProject) && (
					<span className="CustomerListItem__Location">
						<Badge status="info">{this.state.item.work_order.project.title}</Badge>
					</span>
				)}
				{!!(this.state.item && this.state.item.cost && this.props.showCost) && (
					<span className="CustomerListItem__Location">
						<Badge>{`Kostnad: ${this.state.item.cost}kr`}</Badge>
					</span>
				)}
				{!!(this.state.item && this.state.item.comment && this.props.showComment) && (
					<span className="CustomerListItem__Location">
						<Badge>{`Kommentar: ${this.state.item.comment}`}</Badge>
					</span>
				)}
			</div>
		);

		const orders = (
			<div className="CustomerListItem__Orders">
				{!!(this.props.showUser || this.props.ue) && (
					<span className="CustomerListItem__OrderCount">
						{this.props.showUser && this.state.item.user.name}
						<div>{this.props.ue ? this.state.item.company_name : null}</div>
					</span>
				)}
				{this.props.showType ? <span className="CustomerListItem__OrderCount">{this.state.item.type}</span> : null}
				{this.props.showProject ? (
					<span className="CustomerListItem__TotalSpent">{this.state.item.project ? this.state.item.project.title : "–"}</span>
				) : null}
			</div>
		);

		const content = (
			<>
				{profile}
				{orders}
			</>
		);

		if (this.props.bare) return content;

		return (
			<ResourceList.Item onClick={this.props.onClick}>
				<div className="CustomerListItem__Main">{content}</div>
			</ResourceList.Item>
		);
	}
}

export default TimeReportRow;

TimeReportRow.defaultProps = {
	showAo: true,
	showProject: true,
	showTime: true,
	showType: true,
	showUser: true,
	showCost: true,
	showCompany: true,
	showWorkorderProject: true,
	showComment: true,
};

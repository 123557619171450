import { Component } from "react";
import { Icon, Link, Button, Caption, TextField, Truncate } from "@shopify/polaris";

import { AttachmentMajor, CircleCancelMajor, EditMajor } from "@shopify/polaris-icons";
import styled from "styled-components";

class Attachment extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false };
		// this.onChange = () => {
		// 	console.log("onChange:");
		// 	clearTimeout(this.timer);
		// 	this.timer = setTimeout(() => {
		// 		console.log("timeout onChange:");
		// 		if (props.onChange) props.onChange(props.file);
		// 	}, 750);
		// };
	}

	gotoUrl(url) {
		window.open(url);
	}

	removeFile() {
		// this.setState({ removeModalOpen: false });
		if (this.props.removeFile) this.props.removeFile(this.props.index);
	}

	render() {
		let file;
		if (this.state.editing) {
			file = (
				<TextField
					placeholder="Skriv in namn på filen..."
					value={this.props.file.title}
					onChange={(value) => {
						this.props.file.title = value;
						// this.onChange();
						if (this.props.onChange) this.props.onChange(this.props.file);
					}}
				/>
			);
		} else if (!this.props.editable) {
			file = (
				<Link onClick={this.gotoUrl.bind(this, this.props.file.url)}>
					<Truncate>{this.props.file.title ? this.props.file.title : this.props.file.filename}</Truncate>
				</Link>
			);
		} else {
			file = <Truncate>{this.props.file.title ? this.props.file.title : this.props.file.filename}</Truncate>;
		}
		return (
			<Wrapper
				className="attachment_files"
				onClick={(event) => {
					if (!this.props.clickThrough) {
						event.stopPropagation();
					}
				}}
				width={this.props.width}
			>
				<Icon source={AttachmentMajor} />

				<Header>
					<Title>
						{file}
						<Caption>
							{this.props.file.size > 1048576 ? (
								<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{(this.props.file.size / 1048576).toFixed(1) || "-"} MB</span>
							) : (
								<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{Math.ceil(this.props.file.size / 1024) || "-"} kB</span>
							)}
						</Caption>
					</Title>

					{this.props.children}
				</Header>

				<RightSideWrapper>
					{this.props.editable && this.props.onChange && (
						<Button
							plain
							onClick={(e) => {
								e.stopPropagation();
								this.setState({ editing: !this.state.editing });
							}}
						>
							<Icon source={EditMajor} />
						</Button>
					)}

					{this.props.onOpen && (
						<Button primary onClick={this.props.onOpen}>
							Välj
						</Button>
					)}
				</RightSideWrapper>
				{this.props.removeFile && (
					<RemoveButton>
						<Button plain destructive icon={CircleCancelMajor} onClick={this.removeFile.bind(this)} />
					</RemoveButton>
				)}
			</Wrapper>
		);
	}
}
export default Attachment;

Attachment.defaultProps = {
	width: "max-content",
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	position: relative;
	/* min-height: 40px; */

	width: ${({ width }) => width};
	margin: 5px;

	.Polaris-Link {
		width: 100%;
	}
	.Polaris-Icon {
		margin: auto 0;
	}
`;

const Title = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 1rem;
	/* flex: 1; */
	.Polaris-Link {
		width: unset;
	}
`;

const Header = styled.div.attrs({ className: "attachment-title" })`
	flex: 1;
	gap: 0.75rem;
	display: flex;
	flex-direction: column;
`;

const RightSideWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const RemoveButton = styled.div.attrs({ className: "removeButton" })`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(40%, -50%);

	.Polaris-Icon {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

import { Button, Popover, Tooltip } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor, DeleteMajor, DuplicateMinor, StoreStatusMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class CanvasButtonSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	openModal(name) {
		this.setState({ [name]: true });
	}

	closeModal(name) {
		this.setState({ [name]: false });
	}

	render() {
		return (
			<div className="canvas-button-sidebar">
				<Tooltip content="Flytta sidan uppåt">
					<Button plain onClick={this.props.movePageUp} icon={ChevronUpMinor} />
				</Tooltip>
				<Tooltip content="Flytta sidan ner">
					<Button plain onClick={this.props.movePageDown} icon={ChevronDownMinor} />
				</Tooltip>
				<Tooltip content="Duplicera sida">
					<Button plain onClick={this.props.duplicatePage} icon={DuplicateMinor} />
				</Tooltip>
				<Popover
					activator={
						<Tooltip content="Rensa sidan">
							<Button plain icon={StoreStatusMajor} onClick={this.openModal.bind(this, "clearModalOpen")} />
						</Tooltip>
					}
					active={this.state.clearModalOpen}
					onClose={this.closeModal.bind(this, "clearModalOpen")}
				>
					<div className="remove-page__button-wrapper">
						<Button onClick={this.closeModal.bind(this, "clearModalOpen")}>Avbryt</Button>
						<Button onClick={this.props.clearCanvas} destructive>
							Rensa
						</Button>
					</div>
				</Popover>
				<Popover
					activator={
						<Tooltip content="Ta bort sidan">
							<Button
								destructive
								plain
								onClick={this.openModal.bind(this, "removeMoldalOpen")}
								icon={DeleteMajor}
								className="canvas-wrapper__remove-page"
							/>
						</Tooltip>
					}
					active={this.state.removeMoldalOpen}
					onClose={this.closeModal.bind(this, "removeMoldalOpen")}
				>
					<div className="remove-page__button-wrapper">
						<Button onClick={this.closeModal.bind(this, "removeMoldalOpen")}>Avbryt</Button>
						<Button onClick={this.props.removePage} destructive>
							Ta bort
						</Button>
					</div>
				</Popover>
			</div>
		);
	}
}
export default CanvasButtonSidebar;

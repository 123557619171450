import { TextField as PolarisTextField } from "@shopify/polaris";
import React, { Component } from "react";

class TextField extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillReceiveProps() {
		if (!this.props.required || (this.props.required && this.props.value && this.state.error)) {
			clearTimeout(this.timer);
			// this.setState({ error: "" });
		}
	}

	onChange(value) {
		if (this.props.onChange) this.props.onChange(value);
		this.setState({ error: "" });
		clearTimeout(this.timer);
	}

	onBlur() {
		this.timer = setTimeout(() => {
			if (this.props.onBlur) this.props.onBlur();
			// !!BUG: This causes the popup to close, FIX
			// if (this.props.required && !this.props.value) {
			// 	this.setState({ error: "Måste fylla i fältet" });
			// }
		}, 500);
	}

	render() {
		const label = `${this.props.label || ""}${this.props.label && this.props.required ? " *" : ""}`;
		return (
			<div style={this.props.style} required={this.props.required}>
				<PolarisTextField
					showCharacterCount={!!this.props.maxLength}
					maxLength={this.props.maxLength}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...this.props}
					error={this.props.error || this.state.error}
					label={label || ""}
					onChange={this.onChange.bind(this)}
					onBlur={this.onBlur.bind(this)}
					value={this.props.value ? String(this.props.value) : ""}
				/>
			</div>
		);
	}
}
export default TextField;

/* eslint-disable quotes */
import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button, ButtonGroup, Stack, ModalProps as PolarisModalProps, Modal as PolarisModal } from "@shopify/polaris";
import { store } from "../../store";
import useMountTransition from "../../hooks/useMountTransition";
import { getPortalsMaxZIndex } from "../../Utilities";

interface ModalProps extends PolarisModalProps {
	noTransition?: boolean;
}

const ANIMATION_IN = 100;
const ANIMATION_OUT = 250;
const DEFAULT_ZINDEX = 519;

// type ModalReturn = (ReactElement | null) & { Section: ReactElement | null };

export default PolarisModal;

//! The html strcture and classes here need to be refactored to fit the new plaris classes.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Modal = ({ children, onClose, open, title, primaryAction, noTransition, secondaryActions, large, ...rest }: ModalProps) => {
	const hasTransitionedIn = useMountTransition(open, ANIMATION_OUT);
	const ref = useRef<any>();
	const id = useRef<number | undefined>();
	const zIndex = useRef<number | undefined>(DEFAULT_ZINDEX);

	const handleOnClose = useCallback(() => {
		if ([2, 419, 418].includes(store.getState().user.id)) {
			onClose();
		}
	}, [onClose]);

	const onKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Escape") handleOnClose();
		},
		[handleOnClose]
	);

	const getElementIndex = () => {
		if (!ref.current) return 0;
		return Math.max(Array.from(document.querySelector("#PolarisPortalsContainer")?.children || []).indexOf(ref.current), 0);
	};

	useEffect(() => {
		const dataId = getElementIndex();
		id.current = dataId;
	}, []);

	useEffect(() => {
		if (open) {
			zIndex.current = Math.max(DEFAULT_ZINDEX, getPortalsMaxZIndex()) + 2;
		}
	}, [open]);

	const show = open || hasTransitionedIn;
	const container: Element | null = document.querySelector("#PolarisPortalsContainer") || document.querySelector("body");

	if (!container) return null;

	return (
		<>
			{createPortal(
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Wrapper ref={ref} data-id={id.current} data-zindex={(open && zIndex.current) || null} {...rest}>
					<div>
						{show && (
							<InnerContainer
								data-zindex={zIndex.current}
								onKeyDown={onKeyDown}
								data-notransition={noTransition}
								data-hastransitionedin={hasTransitionedIn}
								data-open={open}
							>
								<div>
									<InnerWrapper>
										<InnerWrapper2 data-large={large}>
											<HeaderWrapper>
												<TitleWrapper>
													<Title>{title}</Title>
												</TitleWrapper>
												<CloseButton onClick={onClose}>
													<CloseButtonSpan>
														<span className="Polaris-VisuallyHidden" />
														<CloseButtonSvg>
															<CloseButtonPath />
														</CloseButtonSvg>
													</CloseButtonSpan>
												</CloseButton>
											</HeaderWrapper>
											<BodyWrapper>{children}</BodyWrapper>
											<FooterWrapper>
												<FooterWrapperContent>
													<Stack>
														<Stack.Item fill />
														<Stack.Item>
															<ButtonGroup>
																{secondaryActions?.map(({ content, onAction, loading, disabled, destructive, icon }, index) => {
																	return (
																		<Button
																			key={index}
																			onClick={onAction}
																			icon={icon}
																			loading={loading}
																			disabled={disabled}
																			destructive={destructive}
																		>
																			{content}
																		</Button>
																	);
																})}
																{primaryAction && (
																	<Button
																		primary={!primaryAction.destructive}
																		onClick={primaryAction.onAction}
																		icon={primaryAction.icon}
																		loading={primaryAction.loading}
																		disabled={primaryAction.disabled}
																		destructive={primaryAction.destructive}
																	>
																		{primaryAction.content}
																	</Button>
																)}
															</ButtonGroup>
														</Stack.Item>
													</Stack>
												</FooterWrapperContent>
											</FooterWrapper>
										</InnerWrapper2>
									</InnerWrapper>
								</div>
							</InnerContainer>
						)}
					</div>

					{open && <Backdrop data-zindex={(zIndex.current || DEFAULT_ZINDEX) - 1} onClick={handleOnClose} />}
				</Wrapper>,
				container
			)}
		</>
	);
};
// export default Modal;

const Wrapper = styled.div.attrs((props) => ({ "data-portal-id": `modal-Polarisportal${props["data-id"]}`, className: "Polaris-Portal-Custom" }))``;

const InnerWrapper = styled.div.attrs({ className: "Polaris-Modal-Dialog", role: "dialog", "aria-modal": "true", tabindex: "-1" })``;
const InnerWrapper2 = styled.div.attrs((props) => {
	return {
		className: `Polaris-Modal-Dialog__Modal ${props["data-large"] ? "Polaris-Modal-Dialog--sizeLarge" : ""}`,
	};
})``;
const HeaderWrapper = styled.div.attrs({ className: "Polaris-Modal-Header" })``;

const BodyWrapper = styled.div.attrs({ className: "Polaris-Modal__BodyWrapper" })`
	flex-direction: column;
`;
const FooterWrapper = styled.div.attrs({ className: "Polaris-Modal-Footer" })``;
const FooterWrapperContent = styled.div.attrs({ className: "Polaris-Modal-Footer__FooterContent" })``;

const TitleWrapper = styled.div.attrs({ className: "Polaris-Modal-Header__Title" })``;
const Title = styled.h2.attrs({ className: "Polaris-DisplayText Polaris-DisplayText--sizeSmall" })``;

type CloseButtonProps = {
	onClick: () => void;
};
const CloseButton = styled.button.attrs<CloseButtonProps>({ className: "Polaris-Modal-CloseButton" })``;
const CloseButtonSpan = styled.span.attrs({ className: "Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor" })``;
const CloseButtonSvg = styled.svg.attrs({ viewBox: "0 0 20 20", className: "Polaris-Icon__Svg", focusable: "false", "aria-hidden": "true" })``;
const CloseButtonPath = styled.path.attrs({
	d: "m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z",
})``;

type BackdropProps = {
	onClick: () => void;
	"data-zindex"?: number | null | undefined;
};
const Backdrop = styled.div.attrs<BackdropProps>({
	className: "Polaris-Backdrop",
})`
	z-index: ${(props) => props["data-zindex"] || 518};
`;

type InnerContainerProps = {
	"data-hastransitionedin"?: boolean | undefined | null;
	"data-open"?: boolean;
	"data-notransition"?: boolean;
	"data-zindex"?: number | null | undefined;
};
const InnerContainer = styled.div.attrs<InnerContainerProps>(() => ({
	className: "Polaris-Modal-Dialog__Container custom",
	"data-polaris-layer": "true",
	"data-polaris-overlay": "true",
}))`
	transition: ${(props) => {
		if (props["data-notransition"]) return null;

		if (props["data-open"]) {
			return `transform ${ANIMATION_IN}ms, opacity ${ANIMATION_IN / 2}ms;`;
		}
		return `transform ${ANIMATION_OUT}ms, opacity ${ANIMATION_OUT * 2}ms;`;
	}};
	transform: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? "translateY(0)" : "translateY(100%)")};
	z-index: ${(props) => props["data-zindex"] || 519};
`;

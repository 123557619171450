import { Button, FormLayout, Page, Spinner, TextField, Stack } from "@shopify/polaris";
import { PrintMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { toastr } from "../../components/toastr";
import PdfTemplateSheet from "../../components/PdfTemplateSheet";
import API from "../../API";

class EditPDF extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: (props.location &&
				props.location.state &&
				props.location.state.stacks &&
				props.location.state.stacks[0] &&
				props.location.state.stacks[0].item) || { variables: [] },
			id: props.id || props.match.params.id,
			// eslint-disable-next-line react/no-unused-state
			variables: [],
		};
	}

	componentDidMount() {
		this.fetch();
	}

	handleChange(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	fetch() {
		this.setState({ loading: true });
		API.get(`/api/pdf_templates/${this.state.id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					return;
				}

				this.setState({ form: result.data.pdf_template, loading: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	onSave() {
		this.setState({ saving: true });

		API.put(`/api/pdf_templates/${this.state.id}.json`, this.state.form)
			.then((result) => {
				this.setState({ saving: false });

				if (result.data.error) {
					return;
				}

				toastr.success("Uppderade PDF");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	remove() {
		this.setState({ removing: true });
		API.delete(`/api/pdf_templates/${this.state.id}.json`)
			.then((result) => {
				this.setState({ removing: false });

				if (result.data.error) {
					return;
				}

				this.props.history.push("/admin/pdf_templates/");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	gotoEditor(index) {
		this.props.history.push(`/admin/pdf_templates/editor/${this.state.id}${index !== null && index !== undefined ? `?page=${index}` : ""}`);
	}

	openModal(name) {
		this.setState({ [name]: true });
	}

	closeModal(name) {
		this.setState({ [name]: false });
	}

	render() {
		const form = this.state.form || {};
		const disabled = this.state.loading || this.state.removing;
		return (
			<Page
				title={<span>Redigera PDF {this.state.loading && <Spinner size="small" />}</span>}
				backAction={{ content: "Alla mallar", url: "/admin/pdf_templates" }}
				primaryAction={{ content: "Spara", onAction: this.onSave.bind(this), loading: this.state.saving }}
				secondaryActions={[
					{
						content: <span>Ta bort {this.state.removing && <Spinner size="small" />}</span>,
						loading: this.state.removing,
						onAction: this.remove.bind(this),
						destructive: true,
					},
				]}
			>
				<FormLayout>
					<TextField disabled={disabled} type="text" label="Titel" onChange={this.handleChange.bind(this, "title")} value={form.title} />

					<div className="pdf-pages-overview">
						{form.pages &&
							form.pages.map((page = {}, index) => {
								return (
									<div key={page.id || index} onClick={() => this.gotoEditor(index + 1)} className="page-tumbnail">
										<img src={page.thumbnail_url} alt="" />
									</div>
								);
							})}
					</div>
					<Stack>
						<Button disabled={this.state.loading} onClick={this.openModal.bind(this, "printModalOpen")} icon={PrintMajor}>
							Skriv ut
						</Button>
						<Button disabled={this.state.saving} onClick={() => this.gotoEditor()}>
							se alla sidor
						</Button>
						<Button primary loading={this.state.saving} onClick={this.onSave.bind(this)}>
							Spara
						</Button>
						<Button destructive loading={this.state.removing} onClick={this.remove.bind(this)}>
							Ta bort
						</Button>
					</Stack>
				</FormLayout>
				<PdfTemplateSheet template={this.state.form} open={this.state.printModalOpen} onClose={this.closeModal.bind(this, "printModalOpen")} />
			</Page>
		);
	}
}
export default EditPDF;

import React, { Component } from "react";
import {
	Page,
	Layout,
	Card,
	Tag,
	Button,
	Icon,
	ChoiceList,
	FormLayout,
	Select,
	Modal,
	ResourceList,
	Stack,
	Checkbox,
	Spinner,
	Tabs,
} from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import Moment from "react-moment";
import Banner from "src/js/components/banner/Banner";
import RemoveModal from "src/js/components/modal/RemoveModal.tsx";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import TimeReportRow from "../TimeReportRow.js";
import SearchField from "../../components/search_field.js";
import TimeRangeModal from "../../components/TimeRangeModal.js";
import ResourcePicker from "../../components/resource_picker.js";
import MapWithKml from "../Dashboard/MapWithKml";
import MapWithKmlAndSearch from "../Dashboard/MapWithKmlAndSearch";
import Upload from "../../components/Upload.js";
import ImageThumbnail from "../../components/ImageThumbnail.js";
import { ImageThumbnailsFlexWrapper } from "../../components/StyledComponents.js";
import TimeLine from "../../components/TimeLine.js";
import QuoteEdit from "../Quotes/edit.js";
import TextField from "../../components/TextField.js";
import ExpenseReportsIndex from "../ExpenseReports/index.js";
import DeviationIndex from "../Deviation/index.js";
import TimeReportModal from "../../components/TimeReportModal.js";
import API from "../../API";

class WorkOrderEdit extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign(
			{
				id: props.match ? props.match.params.id : null,
				areas: [],
				work_types: store.getState().work_types,
				time_reports: [],
				changeAddress: false,
				saving: false,
				fetchingCoordinates: true,
				myLat: null,
				myLng: null,
				invoice: {},
				sums: [],
				form: {
					title: "",
					work_types: [],
					color: "#ff0000",
					personell_ledger: !!(store.getState().account.ledger_linked && store.getState().account.auto_activate_checkin),
					estimated_hours: "0",
					personell_ledger_id: "",
					reference: "",
					description: "",
					checkin: !!store.getState().account.auto_activate_checkin,
					checkin_screen: false,
					checkin_require_gps: false,
					checkout_require_gps: false,
					expenses: [],
					users: [],
					ata: "",
					machine: "",
					groups: [],
					underentreprenorer: [],
					tags: [],
					customer_id: null,
					customer_name: null,
					status: "active",
					fixed_price: "",
					hourly_rate: "",
					hourly_ob_rate: "",
					address: null,
					latitude: null,
					longitude: null,
					work_order_invoices: [],
					public: false,
				},
				selectedTab: 0,
				showRemoveModal: false,
				// selectedTab: (props.location && props.location.state && props.location.state.selectedTab) || 0,
			},
			(props.location && props.location.state) || {}
		);
	}

	componentWillReceiveProps(props) {
		if (props.match && props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	showTime(minutes) {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60 + " tim";
			} else {
				var mins = minutes % 60;
				var hours = (minutes - mins) / 60;
				return hours + " tim, " + mins + " min";
			}
		} else {
			return minutes + " min";
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		this.fetchReports();
		this.fetchCoordinates();
	}

	fetchCoordinates() {
		const success = (position) => {
			const latitude = position.coords.latitude;
			const longitude = position.coords.longitude;
			this.setState({
				myLat: latitude,
				myLng: longitude,
				fetchingCoordinates: false,
			});
			console.debug("myLat:", this.state.myLat);
			console.debug("myLng:", this.state.myLng);
		};

		const error = () => {
			console.debug("Unable to retrieve your location");
			this.setState({
				fetchingCoordinates: false,
			});
		};
		navigator.geolocation.getCurrentPosition(success, error);

		if (store.getState().account.id == 61) {
			console.debug("Setting coordinates to Linköping");
			this.setState({
				myLat: 58.410809,
				myLng: 15.621373,
				fetchingCoordinates: false,
			});
		}
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	fetchReports(silent) {
		if (!silent) this.setState({ loadingReports: true });
		API.get("/api/time_reports.json", {
			params: { work_order_id: this.state.id, limit: 10 },
		})
			.then((result) => {
				this.setState({
					loadingReports: false,
					totalMinutes: result.data.sum,
					totalObMinutes: result.data.sum_ob,
					reportCount: result.data.count,
					time_reports: result.data.time_reports,
					// totalCost: result.data.sum_cost,
					sums: result.data.sums ? result.data.sums : [],
				});
			})
			.catch((error) => {
				toastr.error(error);
			});

		if (store.getState().account.has_underentreprenorer) {
			API.get("/api/time_reports.json", {
				params: { ue: 1, work_order_id: this.state.id },
			})
				.then((result) => {
					this.setState({
						/* eslint-disable react/no-unused-state */
						loadingUeReports: false,
						ueTotalMinutes: result.data.sum,
						ueTotalObMinutes: result.data.sum_ob,
						UeReportCount: result.data.count,
						ue_time_reports: result.data.time_reports,
						/* eslint-enable react/no-unused-state */
					});
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	renderTimeReport(item) {
		return (
			<TimeReportRow
				onClick={() => {
					this.props.history.push("/admin/time_reports/" + item.id, {
						work_order: this.state.form,
					});
				}}
				item={item}
			/>
		);
	}

	renderWorkOrderInvoice(item) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignContent: "center",
					alignItems: "center",
					paddingBlock: "1rem",
				}}
			>
				<div className="CustomerListItem__Main" style={{ gap: "1rem" }}>
					<h3 className="CustomerListItem__Title">
						<Moment locale="sv" format="YYYY-MM-DD">
							{item.invoice_date}
						</Moment>
					</h3>

					{`${item.invoice_amount}kr`}
				</div>
				<Button destructive onClick={this.removeWorkOrderInvoice.bind(this, item)}>
					Ta bort
				</Button>
			</div>
		);
	}

	removeWorkOrderInvoice(item) {
		if (this.state.form && this.state.form.work_order_invoices && this.state.form.work_order_invoices.length) {
			this.state.form.work_order_invoices = this.state.form.work_order_invoices.filter((invoice) => invoice.id !== item.id);
			this.setState({ form: this.state.form }, this.saveForm.bind(this, true));
		}
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/work_orders/" + id + ".json")
			.then((result) => {
				result.data.work_order.work_types = result.data.work_order.work_types.map((t) => t.id + "");
				this.setState({
					loading: false,
					saving: false,
					form: result.data.work_order,
				});
				// this.fetchComments();
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	updateInvoice(field, value) {
		this.state.invoice[field] = value;
		this.setState({ invoice: this.state.invoice });
	}

	showAllReports() {
		this.props.history.push("/admin/time_reports", {
			work_order: this.state.form,
		});
	}

	maila(fromDate, toDate) {
		this.setState({
			mailListModalOpen: true,
			mailTimeRangeOpen: false,
			mailFromDate: fromDate,
			mailToDate: toDate,
		});
	}

	sendEmail() {
		this.setState({ saving: true });
		API.post(
			"/api/work_orders/" + this.state.id + "/ledger_checkins/email.json",
			{ email: this.state.email },
			{
				params: {
					checkin_at_min: this.getYmd(this.state.mailFromDate),
					checkin_at_max: this.getYmd(this.state.mailToDate),
				},
			}
		)
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("E-mail har skickats till " + this.state.email);
				this.setState({ mailListModalOpen: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	onMarkerClick(area) {
		this.setState({ editingArea: Object.assign({}, area) });
	}

	saveArea() {
		this.setState({ saving: true });
		API.put("/api/work_order_areas/" + this.state.editingArea.id + ".json", this.state.editingArea)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Område sparades");
				this.setState({ editingArea: false });
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeArea() {
		this.setState({ saving: true });
		API.delete("/api/work_order_areas/" + this.state.editingArea.id + ".json", this.state.editingArea)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Område borttaget");
				this.setState({ editingArea: false });
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	saveForm(silent = false) {
		if (!silent) {
			this.setState({ saving: true, changeAddress: false });
		} else {
			this.setState({ changeAddress: false });
		}
		API.put("/api/work_orders/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				result.data.work_order.work_types = result.data.work_order.work_types.map((t) => t.id + "");
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Arbetsorder sparades");
				this.props.history.replace("/admin/work_orders/" + result.data.work_order.id);
				this.setState({ form: result.data.work_order });
				// this.fetchItem(this.state.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({
			form: this.state.form,
			latitude: this.state.form.latitude,
			longitude: this.state.form.longitude,
		});
		console.debug("this.state.form.longitude:", this.state.longitude);
		console.debug("this.state.form.latitude:", this.state.latitude);
		this.saveForm();
	}

	addAreaSuggestions() {
		this.setState({ saving: true });
		API.post("/api/work_orders/" + this.state.form.id + "/areas.json", {
			address: this.state.form.address,
			areas: this.state.areas,
		})
			.then((result) => {
				this.setState({
					saving: false,
					showAddAreaModal: false,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	addAreas(areas) {
		this.setState({ saving: true });
		API.post("/api/work_orders/" + this.state.form.id + "/areas.json", {
			areas,
		})
			.then((result) => {
				this.setState({
					saving: false,
					showNewAddAreaModal: false,
				});
				this.fetchItem(this.state.id);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	addTag(item) {
		if (this.state.form.tags && !this.state.form.tags.find((i) => i.title === item.title)) {
			this.state.form.tags.push(item);
			this.setState({ form: this.state.form });
		}
	}

	removeTag(index) {
		this.state.form.tags.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	displayProjectStatus(status) {
		let currentStatus = null;
		if (status == "sleep") {
			currentStatus = "Vilande";
		} else if (status == "pending") {
			currentStatus = "Kommande";
		} else if (status == "active") {
			currentStatus = "Aktiv";
		} else if (status == "done") {
			currentStatus = "Färdig";
		} else if (status == "invoiced") {
			currentStatus = "Fakturerad";
		} else {
			currentStatus = status;
		}

		return currentStatus;
	}

	addUpload(upload) {
		upload.title = "Ny fil";
		upload.private = true;
		if (this.state.form.uploads) {
			this.state.form.uploads.push(upload);
		} else {
			this.state.form.uploads = [upload];
		}
		this.setState({ form: this.state.form });
	}

	changeAddress() {
		this.state.changeAddress = !this.state.changeAddress;
		this.setState({ changeAddress: this.state.changeAddress });
	}

	openProject() {
		if (!this.state.form.project) {
			return;
		}
		this.props.history.push("/admin/projects/" + this.state.form.project.id);
	}

	updateUpload(index, field, value) {
		this.state.form.uploads[index][field] = value;
		this.setState({ form: this.state.form });
	}

	removeUpload(index) {
		this.state.form.uploads.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	openUpload() {
		this.setState({ openUpload: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		if (!store.getState().user.read_only) {
			var actions = [
				{
					content: "Ta bort arbetsorder",
					onAction: () => {
						this.setState({ showRemoveModal: true });
					},
					destructive: true,
					enabled: store.getState().user.roles.includes("ROLE_ADMIN"),
				},

				{
					content: (
						<Stack spacing="extraTight">
							<Icon source={CirclePlusMajor} />
							<div>Kopiera arbetsorder</div>
						</Stack>
					),
					onAction: () => {
						this.props.history.push("/admin/work_orders/new", {
							project: this.state.form,
						});
					},
				},
			];
		}

		const tabs = [
			{
				id: "oversikt",
				content: "Översikt",
				accessibilityLabel: "oversikt",
				panelID: "oversikt",
			},
			{
				id: "tidregistreringar",
				content: "Tidregistreringar",
				panelID: "tidregistreringar",
			},
			{
				id: "documents",
				content: "Dokument",
				panelID: "documents",
			},
			{
				id: "anslagstavla",
				content: "Anslagstavla",
				panelID: "anslagstavla",
			},
			{
				id: "economy",
				content: "Ekonomi",
				panelID: "economy",
			},
			{
				id: "avvikelser",
				content: "Avvikelser",
				panelID: "avvikelser",
			},
			{
				id: "utgifter",
				content: "Utgifter",
				panelID: "utgifter",
			},
		];
		if (this.state.form.quote) {
			tabs.push({ id: "quote", content: "Offert", panelID: "quote" });
		}

		const project = this.state.form.project || (this.props.location && this.props.location.state && this.props.location.state.project);
		return (
			<Page
				fullWidth
				title={"Arbetsorder: " + (this.state.form && this.state.form.title ? this.state.form.title : null)}
				backAction={
					project
						? {
								content: `Projekt - ${project.label}`,
								onAction: () => this.props.history.push(`/admin/projects/${project.id}`, {}),
						  }
						: {
								content: "Tillbaka",
								onAction: () => this.props.history.push("/admin/work_orders", {}),
						  }
				}
				primaryAction={
					store.getState().user.read_only
						? null
						: {
								content: "Spara",
								loading: this.state.saving,
								onAction: this.saveForm.bind(this, false),
						  }
				}
				secondaryActions={actions}
			>
				{!this.state.loading && this.state.form.project && this.state.form.project.status != "active" ? (
					<Banner title="Arbetsordern är inte aktiv för tidrapportering" status="critical" style={{ marginBottom: "1rem" }}>
						Denna arbetsorder är inte tillgänglig för tidrapportering eftersom statusen för <u>projektet</u> är{" "}
						{this.displayProjectStatus(this.state.form.project.status)}. Vänligen ändra statusen till &quot;Aktiv&quot; för att möjliggöra
						tidrapportering.{" "}
						<span style={{ cursor: "pointer" }} onClick={this.openProject.bind(this)}>
							<u>Öppna projektet genom att klicka här</u>
						</span>
					</Banner>
				) : null}
				<Tabs
					tabs={tabs}
					selected={this.state.selectedTab}
					onSelect={(v) => {
						this.setState({ selectedTab: v });
					}}
				/>
				<Layout>
					<Layout.Section>
						{this.state.selectedTab == 0 && (
							<React.Fragment>
								<Card sectioned>
									<FormLayout>
										<ResourcePicker
											disabled
											placeholder="Sök projekt"
											caption="Välj projekt"
											resource="projects"
											label="Projekt"
											resourceName={{
												singular: "projekt",
												plural: "projekt",
											}}
											item={this.state.form.project}
											onChange={this.updateForm.bind(this, "project")}
											label_handle="label"
										/>
										<TextField label="Arbetsorder (AO nr)" value={this.state.form.reference} onChange={this.updateForm.bind(this, "reference")} />
										<TextField label="Benämning" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />

										<Select
											label="Status"
											options={[
												{ label: "Vilande", value: "sleep" },
												{ label: "Kommande", value: "pending" },
												{ label: "Aktiv", value: "active" },
												{ label: "Färdig", value: "done" },
												{ label: "Fakturerad", value: "invoiced" },
											]}
											onChange={this.updateForm.bind(this, "status")}
											value={this.state.form.status}
										/>
										<TextField
											multiline
											label="Beskrivning"
											value={this.state.form.description}
											onChange={this.updateForm.bind(this, "description")}
										/>
										<Checkbox
											label={!store.getState().account.ledger_linked ? "Stämpelklocka" : "Stämpelklocka & Personalliggare"}
											checked={this.state.form.checkin}
											onChange={(value) => {
												this.updateForm("checkin", value);
												if (store.getState().account.ledger_linked) {
													this.updateForm("personell_ledger", value);
												}
											}}
										/>
										{this.state.form.checkin && (
											<Checkbox
												label="Incheck.skärm"
												checked={this.state.form.checkin_screen}
												onChange={this.updateForm.bind(this, "checkin_screen")}
											/>
										)}
										{this.state.form.checkin && (
											<Checkbox
												label="Kontrollera GPS för incheckning"
												checked={this.state.form.checkin_require_gps}
												onChange={this.updateForm.bind(this, "checkin_require_gps")}
											/>
										)}
										{this.state.form.checkin && (
											<Checkbox
												label="Kontrollera GPS för utcheckning"
												checked={this.state.form.checkout_require_gps}
												onChange={this.updateForm.bind(this, "checkout_require_gps")}
											/>
										)}
										{this.state.form.status != "quote" && (
											<FormLayout>
												<Checkbox
													label={!store.getState().account.ledger_linked ? "Stämpelklocka" : "Stämpelklocka & Personalliggare"}
													checked={this.state.form.checkin}
													onChange={(value) => {
														this.updateForm("checkin", value);
														if (store.getState().account.ledger_linked) {
															this.updateForm("personell_ledger", value);
														}
													}}
												/>
												{this.state.form.checkin && (
													<Checkbox
														label="Incheck.skärm"
														checked={this.state.form.checkin_screen}
														onChange={this.updateForm.bind(this, "checkin_screen")}
													/>
												)}
												{this.state.form.checkin && (
													<Checkbox
														label="Kontrollera GPS för incheckning"
														checked={this.state.form.checkin_require_gps}
														onChange={this.updateForm.bind(this, "checkin_require_gps")}
													/>
												)}
												{this.state.form.checkin && (
													<Checkbox
														label="Kontrollera GPS för utcheckning"
														checked={this.state.form.checkout_require_gps}
														onChange={this.updateForm.bind(this, "checkout_require_gps")}
													/>
												)}
											</FormLayout>
										)}
										{this.state.form.created_by ? <small>Arbetsordern skapades av: {this.state.form.created_by.name}</small> : null}
									</FormLayout>
								</Card>
								{this.state.form && this.state.form.areas && this.state.form.areas.length > 0 ? (
									<Card>
										<div style={{ height: 300 }}>
											{this.state.form && this.state.form.id && !this.state.loading && !this.state.fetchingCoordinates ? (
												<MapWithKml
													myLat={this.state.form.latitude}
													myLng={this.state.form.longitude}
													workOrder={this.state.form}
													showMarker
													onMarkerClick={this.onMarkerClick.bind(this)}
													height="100%"
													zIndexValue="1"
												/>
											) : (
												<Spinner size="small" color="teal" />
											)}
										</div>
									</Card>
								) : null}
							</React.Fragment>
						)}

						{this.state.selectedTab == 1 && (
							<Card
								title="Tidregistreringar"
								actions={
									this.state.loadingReports
										? null
										: [
												{
													content: "Visa alla " + this.state.reportCount + " registreringar",
													onAction: this.showAllReports.bind(this),
												},
												store.getState().user.roles.includes("ROLE_ADMIN") && {
													content: <div className="Polaris-Button Polaris-Button--primary">Registrera tid</div>,
													onAction: () => this.setState({ adminRegisterTimeModalIsOpen: true }),
												},
										  ]
								}
							>
								<ResourceList
									resourceName={{
										singular: "rapportering",
										plural: "rapporteringar",
									}}
									items={this.state.time_reports}
									renderItem={this.renderTimeReport.bind(this)}
									loading={this.state.loadingReports}
								/>
								<Card.Section subdued>
									<Stack>
										<div>
											Totalt:
											{this.state.totalMinutes ? this.showTime(this.state.totalMinutes) : "–"}
										</div>
										<div>
											Varav OB:
											{this.state.totalObMinutes ? this.showTime(this.state.totalObMinutes) : "–"}
										</div>
									</Stack>
								</Card.Section>
								{this.state.sums && this.state.sums.length ? (
									<Card.Section subdued title="Summering">
										<Stack>
											{this.state.sums.map((time, index) => (
												<Stack key={index}>
													<div>
														{time.title}:{this.showTime(time.sum)}
													</div>
												</Stack>
											))}
										</Stack>
									</Card.Section>
								) : null}
							</Card>
						)}
						{this.state.selectedTab == 2 && (
							<Card title="Dokument" sectioned actions={[{ content: "Ladda upp", onAction: this.openUpload.bind(this) }]}>
								<Upload
									files={[]}
									width={400}
									height={200}
									open={this.state.openUpload}
									onUpload={this.addUpload.bind(this)}
									onClose={() => {
										this.setState({ openUpload: false });
									}}
								/>
								<ImageThumbnailsFlexWrapper>
									{this.state.form.uploads &&
										this.state.form.uploads.map((upload, index) => (
											<ImageThumbnail
												key={upload.url || index}
												upload={upload}
												onChange={this.updateUpload.bind(this, index)}
												onDelete={this.removeUpload.bind(this, index)}
											/>
										))}
								</ImageThumbnailsFlexWrapper>
								<Button onClick={this.openUpload.bind(this)} primary>
									Ladda up ny
								</Button>
							</Card>
						)}
						{this.state.selectedTab === 3 && (
							<TimeLine handle={"workorder" + this.state.id} tasks={[]} opportunities={[]} object={this.state.form} resource="workorder" />
						)}
						{this.state.selectedTab === 4 && (
							<Stack vertical>
								<Card sectioned title="Lägg till fakturor">
									<FormLayout>
										<div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
											<div style={{ display: "flex", gap: "1rem" }}>
												<TextField
													label="Datum"
													type="date"
													value={this.state.invoice.invoice_date}
													onChange={this.updateInvoice.bind(this, "invoice_date")}
													max="9999-12-31"
												/>
												<TextField
													label="Summa"
													type="number"
													suffix="kr"
													step={1}
													value={parseInt(this.state.invoice.invoice_amount)}
													onChange={this.updateInvoice.bind(this, "invoice_amount")}
												/>
											</div>
											<div style={{ display: "flex", gap: "1rem" }}>
												<Button onClick={() => this.setState({ invoice: {} })}>Avbryt</Button>
												<Button
													primary
													disabled={!this.state.invoice.invoice_date || !this.state.invoice.invoice_amount}
													onClick={() => {
														this.state.form.work_order_invoices.push(this.state.invoice);
														this.setState({ form: this.state.form, invoice: {} }, () => this.saveForm(true));
													}}
												>
													Lägg till
												</Button>
											</div>
										</div>
									</FormLayout>
								</Card>
								{this.state.form && this.state.form.work_order_invoices && this.state.form.work_order_invoices.length > 0 && (
									<Card sectioned title="Fakturor">
										<ResourceList
											resourceName={{
												singular: "faktura",
												plural: "fakturor",
											}}
											items={this.state.form.work_order_invoices}
											renderItem={this.renderWorkOrderInvoice.bind(this)}
										/>

										<div style={{ paddingBlock: "1rem" }}>
											{`Totalt: ${
												this.state.form.work_order_invoices
													.reduce((prev, curr) => prev + parseFloat(curr.invoice_amount), 0)
													.toFixed(2)
													.replace(".00", "") || "-"
											}kr`}
										</div>
									</Card>
								)}
							</Stack>
						)}
						{this.state.selectedTab === 5 && <DeviationIndex history={this.props.history} workOrder={this.state.form} />}
						{this.state.selectedTab === 6 && <ExpenseReportsIndex history={this.props.history} workOrder={this.state.form} />}
						{this.state.selectedTab === 7 && <QuoteEdit quote={this.state.form.quote} />}
					</Layout.Section>

					<Layout.Section secondary>
						<Card
							title="Adress"
							sectioned
							actions={
								this.state.form && this.state.form.address
									? [
											{
												content: this.state.changeAddress ? "Avbryt ändring" : "Ändra",
												onAction: this.changeAddress.bind(this),
											},
									  ]
									: null
							}
						>
							{this.state.changeAddress || !this.state.form.address ? (
								<SearchField
									resource="google_address"
									resourceName={{
										singular: "adress",
										plural: "adresser",
									}}
									onSelect={this.selectAddress.bind(this)}
								/>
							) : null}
							{this.state.form.address && !this.state.changeAddress ? (
								<div>{this.state.form.address}</div>
							) : (
								<small>Ange en adress för att få arbetsordern att visas på kartan</small>
							)}
						</Card>

						<Card title="Attribut" subdued sectioned>
							<FormLayout>
								<Checkbox label="ÄTA" checked={this.state.form.ata} onChange={this.updateForm.bind(this, "ata")} />
							</FormLayout>
						</Card>

						<Card title="Taggar" subdued sectioned>
							<SearchField
								resource="work_orders/tags"
								resourceName={{
									singular: "tagg",
									plural: "taggar",
								}}
								onSelect={this.addTag.bind(this)}
								tags
							/>
							{this.state.form.tags.length ? (
								<Stack>
									{this.state.form.tags.map((tag, index) => (
										<Tag key={index} onRemove={this.removeTag.bind(this, index)}>
											{tag.title}
										</Tag>
									))}
								</Stack>
							) : null}
						</Card>

						{store.getState().account.has_personell_ledger && this.state.form.status != "quote" ? (
							<Card title="Personalliggare" sectioned>
								<FormLayout>
									{!store.getState().account.ledger_linked ? (
										<Checkbox
											label="Arbetsordern har personalliggare"
											checked={this.state.form.personell_ledger}
											onChange={this.updateForm.bind(this, "personell_ledger")}
										/>
									) : this.state.form.checkin ? null : (
										<div>Incheckning är avaktiverad</div>
									)}
									{this.state.form.personell_ledger ? (
										<div>
											<TextField
												label="Skatteverkets identifikationsnr"
												value={this.state.form.personell_ledger_id}
												onChange={this.updateForm.bind(this, "personell_ledger_id")}
											/>
											<Button
												plain
												onClick={() => {
													this.setState({ mailTimeRangeOpen: true });
												}}
											>
												Maila rapport
											</Button>
										</div>
									) : null}
								</FormLayout>
							</Card>
						) : null}
						{/* <Card title="Tidrapportering" sectioned>
                <FormLayout>
                  <Checkbox
                    label={((!store.getState().account.ledger_linked) ? 'Stämpelklocka':'Stämpelklocka & Personalliggare')}
                    checked={this.state.form.checkin}
                    onChange={(value) => {this.updateForm('checkin', value);if (store.getState().account.ledger_linked) {this.updateForm('personell_ledger', value);}}}
                  />
                  {((this.state.form.checkin) ? <Checkbox
                    label="Incheck.skärm"
                    checked={this.state.form.checkin_screen}
                    onChange={this.updateForm.bind(this, 'checkin_screen')}
                  />:null)}
                  {((this.state.form.checkin) ? <Checkbox
                    label="Kontrollera GPS för incheckning"
                    checked={this.state.form.checkin_require_gps}
                    onChange={this.updateForm.bind(this, 'checkin_require_gps')}
                  />:null)}
                  {((this.state.form.checkin) ? <Checkbox
                    label="Kontrollera GPS för utcheckning"
                    checked={this.state.form.checkout_require_gps}
                    onChange={this.updateForm.bind(this, 'checkout_require_gps')}
                  />:null)}
                </FormLayout>
              </Card> */}
						{this.state.work_types.length > 1 && (
							<Card title="Arbetstyper" subdued sectioned>
								<ChoiceList
									title=""
									titleHidden
									allowMultiple
									choices={this.state.work_types.map((typ) => ({
										value: typ.id + "",
										label: typ.title + " (" + typ.code + ")",
									}))}
									selected={this.state.form.work_types}
									onChange={this.updateForm.bind(this, "work_types")}
								/>
							</Card>
						)}
					</Layout.Section>
				</Layout>
				<TimeRangeModal
					open={this.state.mailTimeRangeOpen}
					onClose={() => this.setState({ mailTimeRangeOpen: false })}
					from={fromDate}
					noreport
					to={toDate}
					onChange={this.maila.bind(this)}
				/>
				<Modal
					title="Maila lista"
					open={this.state.mailListModalOpen}
					onClose={() => {
						this.setState({ mailListModalOpen: false });
					}}
					primaryAction={{
						content: "Skicka",
						onAction: () => {
							this.sendEmail();
						},
						loading: this.state.saving,
						disabled: !this.state.email,
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ mailListModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<TextField
							label="E-post"
							value={this.state.email}
							onChange={(value) => {
								this.setState({ email: value });
							}}
						/>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					url={"/api/work_orders/" + this.state.form.id + ".json"}
					title="Ta bort arbetsorder?"
					type="Arbetsorder"
					name={this.state.form.title}
					history={this.props.history}
					item={this.state.form}
				/>
				<Modal
					title="Ändra area"
					open={this.state.editingArea}
					onClose={() => {
						this.setState({ editingArea: false });
					}}
					primaryAction={{
						content: "Spara",
						onAction: this.saveArea.bind(this),
						loading: this.state.saving,
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ editingArea: false });
							},
						},
						{
							content: "Ta bort",
							destructive: true,
							loading: this.state.saving,
							onAction: () => {
								// eslint-disable-next-line no-alert
								if (window.confirm("Är du säker på att du vill ta bort detta område?")) {
									this.removeArea();
								}
							},
						},
					]}
				>
					{this.state.editingArea ? (
						<Modal.Section>
							<FormLayout>
								<TextField
									label="Områdesnamn"
									value={this.state.editingArea.title}
									onChange={(v) => {
										this.state.editingArea.title = v;
										this.setState({ editingArea: this.state.editingArea });
									}}
								/>
								<TextField
									label="Färg"
									value={this.state.editingArea.color}
									onChange={(v) => {
										this.state.editingArea.color = v;
										this.setState({ editingArea: this.state.editingArea });
									}}
								/>
							</FormLayout>
						</Modal.Section>
					) : null}
				</Modal>
				<Modal
					title="Fastighetsområden"
					open={this.state.showAddAreaModal}
					onClose={() => {
						this.setState({ showAddAreaModal: false });
					}}
					primaryAction={{
						content: "Använd dessa områdena",
						onAction: this.addAreaSuggestions.bind(this),
						loading: this.state.saving || this.state.loadingAreas,
					}}
					secondaryActions={[
						{
							content: "Använd inte dessa områdena",
							onAction: () => {
								this.setState({ showAddAreaModal: false });
							},
						},
					]}
				>
					{!this.state.loadingAreas ? (
						<div style={{ height: 400 }}>
							<MapWithKml
								myLat={this.state.form.latitude}
								myLng={this.state.form.longitude}
								areas={this.state.areas.map((area, index) => ({
									id: index,
									color: "0000ff",
									coordinates: area,
								}))}
								height="100%"
								zIndexValue="1"
							/>
						</div>
					) : (
						<Spinner size="small" color="teal" />
					)}
				</Modal>

				<TimeReportModal
					open={this.state.adminRegisterTimeModalIsOpen}
					onClose={() => {
						this.setState({ adminRegisterTimeModalIsOpen: false });
					}}
					work_order={this.state.form}
					onComplete={() => {
						this.setState({ adminRegisterTimeModalIsOpen: false });
						this.fetchReports(true);
					}}
					view="work_order"
				/>

				<MapWithKmlAndSearch show={this.state.showNewAddAreaModal} addArea={this.addAreas.bind(this)} height="100%" zIndexValue="1" />
				<div style={{ marginTop: "1rem" }} />
			</Page>
		);
	}
}

export default WorkOrderEdit;

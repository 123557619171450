import React from "react";
import styled from "styled-components";
import OfferUserItem from "./OfferUserItem";

const OfferUsersIndex = ({ offerUsers }) => {
	return (
		<Wrapper>
			{offerUsers?.map((offerUser) => {
				return <OfferUserItem key={offerUser.id} offerUser={offerUser} />;
			})}
		</Wrapper>
	);
};
export default OfferUsersIndex;

const Wrapper = styled.div``;

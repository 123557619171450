import { Button, HorizontalStack } from "@shopify/polaris";
import React from "react";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import ProfileAvatar from "./ProfileAvatar";

const UserTag = ({ user, onRemove }) => {
	return (
		<Wrapper>
			<HorizontalStack gap="2" align="space-between" blockAlign="center">
				<HorizontalStack gap="2" blockAlign="center">
					<ProfileAvatar user={user} size={25} />
					<div>{user.name}</div>
				</HorizontalStack>

				{onRemove && <Button icon={CircleCancelMinor} onClick={onRemove} plain destructive />}
			</HorizontalStack>
		</Wrapper>
	);
};
export default UserTag;

const Wrapper = styled.div`
	transition: background-color 100ms ease-in-out;
	padding: 0.25rem 0.5rem;
	border-radius: 6px;

	&:hover {
		background-color: var(--primary);
	}
`;

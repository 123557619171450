import { HorizontalStack, Icon } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import PopupMenu from "src/js/components/PopupMenu";
import TextField from "src/js/components/TextField";
import styled, { css } from "styled-components";

interface InvoiceRowProps {
	row: any;
	onChange: (row: any) => void;
	index?: number;
	onRemove: () => void;
	hightlight?: boolean;
	supplierInvoice?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvoiceRow = ({ row: propsRow, onChange, onRemove, index, hightlight, supplierInvoice }: InvoiceRowProps) => {
	const [row, setRow] = useState(propsRow);
	const ref = React.createRef<any>();

	useEffect(() => {
		setRow(propsRow);
	}, [propsRow]);

	const getChangeHandler = useCallback(
		(key: string) => {
			return (value) => {
				if (key === "amount") {
					row.amount_to_invoice = (parseFloat(value) * (row.surcharge / 100 + 1)).toFixed(0);
					row.unit_price = (parseFloat(value) / row.quantity).toFixed(0);
				} else if (key === "surcharge") {
					row.amount_to_invoice = value !== "" ? ((parseFloat(value) / 100 + 1) * row.amount).toFixed(0) : "";
				} else if (key === "amount_to_invoice") {
					row.surcharge = (parseFloat(value) / row.amount - 1) * 100;
				} else if (key === "quantity" && row.amount) {
					row.unit_price = value !== "" ? (row.amount / parseFloat(value)).toFixed(0) : 0;
				} else if (key === "unit_price") {
					row.unit_price = parseFloat(value);
				}

				row[key] = value;

				row.surcharge = Math.max(parseFloat(row.surcharge?.toFixed(2) || 0), 0);

				setRow({ ...row });
			};
		},
		[row]
	);

	const handleSubmit = (form = row) => {
		// if (!parseInt(String(row.Total)) && row.Quantity && row.Price) {
		// 	row.Total = parseInt(row.Quantity.toString()) * row.Price;
		// } else if (parseInt(String(row.Total)) && row.Quantity) {
		// 	row.Price = row.Total / row.Quantity;
		// }

		// setRow({ ...form });
		onChange({ ...form });

		// const activeElement = document.activeElement;
		// const td = activeElement?.closest("td");
		// const nextElement = td?.nextElementSibling?.querySelector("input, textarea") as any;
		// if (nextElement) nextElement.focus();
	};

	return (
		<Wrapper
			highlight={hightlight}
			ref={ref}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					// const tr = activeElement?.closest("tr");

					const prevActiveElement = document.activeElement as any;
					const td = prevActiveElement?.closest("td");
					const nextElement = td?.nextElementSibling?.querySelector("input:not([disabled],[readonly]), textarea:not([disabled],[readonly])") as any;
					if (nextElement) {
						nextElement.focus();
					} else {
						prevActiveElement?.blur?.();
					}
				}
			}}
			onBlur={() => handleSubmit()}
		>
			<td>
				<TextField placeholder="Benämning.." value={row.description} onChange={getChangeHandler("description")} />
			</td>
			<td>
				<TextField placeholder="Antal.." value={row.quantity} onChange={getChangeHandler("quantity")} />
			</td>
			<td>
				<TextField placeholder="Enhet.." value={row.unit} onChange={getChangeHandler("unit")} />
			</td>
			<td>
				<TextField
					placeholder="Styckpris.."
					value={row.unit_price}
					onChange={getChangeHandler("unit_price")}
					suffix={supplierInvoice?.unit || "kr"}
				/>
			</td>
			{/* <td>
				<SearchField
					onClearButtonClick={getChangeHandler("project")}
					clearButton
					placeholder="Sök projekt"
					caption="Välj project"
					labelHidden
					resource="projects.json"
					resourceName={{
						singular: "Projekt",
						plural: "Projekt",
					}}
					onChange={(project) => {
						row.project = project;
						row.surcharge = row.project.purcharge_price_surcharge;
						handleSubmit(row);
					}}
					label="Projekt"
					label_handle="full_label"
					textAlign="left"
					value={row.project?.full_label || row.project?.title}
					fullWidth={false}
					allowCreate
				/>
			</td> */}
			<td>
				<TextField
					placeholder="Belopp (exkl. moms).."
					value={row.amount}
					onChange={getChangeHandler("amount")}
					suffix={supplierInvoice?.unit || "kr"}
				/>
			</td>
			<td>
				<TextField placeholder="Påslag.." value={row.surcharge} onChange={getChangeHandler("surcharge")} suffix="%" />
			</td>
			<td>
				<TextField
					placeholder="Att vidarefakturera.."
					value={row.amount_to_invoice}
					suffix={supplierInvoice?.unit || "kr"}
					onChange={getChangeHandler("amount_to_invoice")}
				/>
			</td>

			<td>
				<PopupMenu
					// disabled={disabled}
					items={[
						{
							content: (
								<HorizontalStack gap="2">
									<Icon source={DeleteMajor} color="critical" />
									Ta bort
								</HorizontalStack>
							),
							onAction: onRemove,
						},
					]}
				/>
			</td>
		</Wrapper>
	);
};
export default InvoiceRow;

const Wrapper = styled.tr<{ highlight?: boolean }>`
	${({ highlight }) =>
		highlight &&
		css`
			box-shadow: 0px 0px 0px 2px var(--accent);
		`}
`;

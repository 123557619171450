/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from "react";
import IndexTable from "src/js/components/IndexTable";
import Badge from "src/js/components/Badge";
import BulkInvoiceModal from "../Invoices/BulkInvoiceModal";
import getInvoicedStatusColumn from "../Invoices/Fortnox/Utilities/getInvoicedStatusColumn";

type SupplierInvoicesRowsIndexProps = {
	extraHeader?: React.ReactNode;
	onSelectionChangeInclRow?: (items: any[]) => void;
	titleHidden?: boolean;
	backAction?: any;
	onClickRow?: (item: any) => void;
	history?: any;
	location?: any;
	project?: any;
	selectOnRow?: boolean;
	fixedParams?: any;
};

const SupplierInvoicesRowsIndex = ({
	extraHeader,
	onSelectionChangeInclRow,
	titleHidden,
	backAction,
	onClickRow,
	history,
	location,
	project,
	selectOnRow,
	fixedParams,
}: SupplierInvoicesRowsIndexProps) => {
	const refresh = useRef<any>(null);
	const ref = useRef<any>(null);

	const [isOpenInvoiceStatusModal, setIsOpenInvoiceStatusModal] = React.useState(false);

	const handleOpenInvoiceStatusModal = () => {
		setIsOpenInvoiceStatusModal(true);
	};

	const handleCloseInvoiceStatusModal = () => {
		setIsOpenInvoiceStatusModal(false);
	};

	const promotedBulkActions = [
		{
			content: "Ändra faktureringsstatus",
			onAction: handleOpenInvoiceStatusModal,
		},
	];
	const columns = [
		{
			handle: "DESCRIPTION",
			label: "Beskrivning",
			render: (item) => {
				return item.description;
			},
		},
		{
			handle: "SUPPLIER_INVOICE",
			label: "Leverantörsfaktura",
			render: (item) => {
				return item.supplier_invoice.id;
			},
		},
		{
			handle: "PROJECT",
			label: "Projekt",
			render: (item) => {
				return item.supplier_invoice?.project?.full_label;
			},
		},
		{
			handle: "QUANTITY",
			label: "Antal",
			render: (item) => {
				return item.quantity;
			},
		},
		{
			handle: "UNIT",
			label: "Enhet",
			render: (item) => {
				return item.unit;
			},
		},
		{
			handle: "amount_to_invoice",
			label: "Att fakturera",
			render: (item) => {
				return item.amount_to_invoice;
			},
		},
		getInvoicedStatusColumn(),
	];

	return (
		<>
			<IndexTable
				ref={ref}
				setRefreshHandler={(refreshHandler) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					refresh.current = refreshHandler;
				}}
				selectOnRow={selectOnRow}
				extraHeader={extraHeader}
				onSelectionChangeInclRow={onSelectionChangeInclRow}
				titleHidden={titleHidden}
				backAction={backAction}
				onClickRow={(item) => {
					if (onClickRow) {
						onClickRow(item);
					}
				}}
				history={history}
				title="Leverantörsfakturs rader"
				savedSearchHandle="supplier_invoice_rows"
				resourceUrl="/api/supplier_invoice_rows.json"
				params={{
					...(fixedParams || {}),
					project_id: project && project.id,
				}}
				resourceHandle="supplier_invoice_rows"
				// filters={filters}
				resourceName={{
					singular: "registrering",
					plural: "registreringar",
				}}
				columns={columns}
				promotedBulkActions={promotedBulkActions}
			/>

			<BulkInvoiceModal
				type="supplier_invoice_rows"
				ids={ref?.current?.getSelection()}
				open={isOpenInvoiceStatusModal}
				onClose={handleCloseInvoiceStatusModal}
				onSuccess={() => {
					handleCloseInvoiceStatusModal();
					refresh.current();
				}}
			/>
		</>
	);
};
export default SupplierInvoicesRowsIndex;

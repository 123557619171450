import styled, { css } from "styled-components";

// eslint-disable-next-line import/prefer-default-export
const StyledButton = styled.button<{ plain?: boolean }>`
	outline-color: transparent;
	background: var(--secondary);
	display: flex;
	align-content: center;
	cursor: pointer;

	${({ plain }) =>
		plain &&
		css`
			background: transparent;
			border: none;
		`}

	svg {
		height: 2em;
		width: 2em;
	}
`;

export default StyledButton;

import { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Circle } from "react-google-maps";
import { toastr } from "../../components/toastr.js";
import API from "../../API";

// const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

class MapWithKml extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: props.height,
			showMarker: false,
			polygons: props.areas || [],
			// loading: true,
			// myLat: props.myLat || 57.705249336641,
			// myLng: props.myLng || 11.933847354042,
		};
		this.circles = [];
	}

	componentDidMount() {
		// console.log("Props lat", this.props.myLat);
		// console.log("Props lng", this.props.myLng);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (JSON.stringify(nextProps.myLat) != JSON.stringify(this.props.myLat)) {
			return true;
		}
		if (JSON.stringify(nextProps.myLng) != JSON.stringify(this.props.myLng)) {
			return true;
		}
		if (JSON.stringify(nextState.polygons) != JSON.stringify(this.state.polygons)) {
			return true;
		}
		return false;
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	changeRegion() {
		if (this.map.getZoom() < 10) {
			this.setState({ showMarker: true });
		} else {
			this.setState({ showMarker: false });
		}
		if (this.state.polygons.length) {
			return;
		}
		var bounds = this.map.getBounds();

		var n = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (var i in bounds) {
			if (n == 0) {
				var latBounds = bounds[i];
			}
			if (n == 1) {
				var lngBounds = bounds[i];
				break;
			}
			n++;
		}
		var latitudeDelta = latBounds.j - latBounds.i;
		var longitudeDelta = lngBounds.j - lngBounds.i;

		var region = {
			latitude: latBounds.i + latitudeDelta,
			latitudeDelta,
			longitude: lngBounds.i + latitudeDelta,
			longitudeDelta,
		};

		this.fetchFeatures(region);
	}

	fetchFeatures(region) {
		clearTimeout(this.state.timeout);
		this.setState({ timeout: setTimeout(this.dofetchFeatures.bind(this, region), 500) });
	}

	dofetchFeatures(region) {
		if (this.props.areas) {
			return;
		}
		// var params = Object.assign({}, region);
		var params = {};
		// console.log("Fetching with", this.props.workOrder);
		if (this.props.workOrder) {
			params.work_order_id = this.props.workOrder.id;
		}
		this.cancelRequest();
		API.get("/api/work_orders/areas.json", { params })
			.then((result) => {
				this.setState({ polygons: result.data.areas });
			})
			.catch((error) => {
				toastr.error("Request failed");
			});
	}

	hasIntersections(center0, center1, radius) {
		// eslint-disable-next-line no-undef
		return 2 * radius >= google.maps.geometry.spherical.computeDistanceBetween(center0, center1);
	}

	render() {
		// console.log("Polygons", this.state.polygons);

		var MyMapComponent = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					ref={(map) => {
						this.map = map;
					}}
					defaultZoom={9}
					defaultCenter={{ lat: this.props.myLat, lng: this.props.myLng }}
					onBoundsChanged={this.changeRegion.bind(this)}
				>
					{this.state.showMarker &&
						this.state.polygons.map((polygon, index) => {
							// var paths = [polygon.coordinates.map((coord) => ({ lat: coord[1], lng: coord[0] }))];
							// if (polygon.holes) {
							// 	for (var i = 0; i < polygon.holes.length; i++) {
							// 		paths.push(polygon.holes[i].map((coord) => ({ lat: coord[1], lng: coord[0] })));
							// 	}
							// }
							// console.log("ply", polygon);
							return (
								<Circle
									key={index}
									onClick={(e) => {
										const areas = this.state.polygons.filter((item) =>
											this.hasIntersections({ lat: polygon.latitude, lng: polygon.longitude }, { lat: item.latitude, lng: item.longitude }, 100)
										);
										if (this.props.onSelectPolygons) {
											this.props.onSelectPolygons(areas);
										}
									}}
									ref={(ref) => {
										this.circles[index] = ref;
									}}
									radius={100}
									options={{
										strokeColor: "#2f6f79",
										fillColor: "#2f6f79",
									}}
									center={{ lat: polygon.latitude, lng: polygon.longitude }}
								/>
							);
						})}
				</GoogleMap>
			))
		);

		return (
			<MyMapComponent
				isMarkerShown
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCoDwig5ZiU2hMEGqBhIXU_w8-pKU4EFl8&v=3.exp&libraries=geometry,drawing,places"
				loadingElement={<div style={{ height: "100%" }} />}
				containerElement={<div style={{ height: this.state.height, position: "relative", zIndex: this.props.zIndexValue }} />}
				mapElement={<div style={{ height: "100%" }} />}
			/>
		);
	}
}

export default MapWithKml;

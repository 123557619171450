import React from "react";

const ExportIcon = ({ color = "black" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path d="M27.5156 13V5.5H20.0156" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M18.5156 14.5L27.5156 5.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M23.5156 18.5V26.5C23.5156 26.7652 23.4103 27.0196 23.2227 27.2071C23.0352 27.3946 22.7808 27.5 22.5156 27.5H6.51562C6.25041 27.5 5.99605 27.3946 5.80852 27.2071C5.62098 27.0196 5.51562 26.7652 5.51562 26.5V10.5C5.51562 10.2348 5.62098 9.98043 5.80852 9.79289C5.99605 9.60536 6.25041 9.5 6.51562 9.5H14.5156"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ExportIcon;

import React from "react";

const FolderIcon = ({ color = "black" }) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4 26V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11.6625C11.8787 7.00089 12.089 7.07098 12.2625 7.2L15.7375 9.8C15.911 9.92902 16.1213 9.99911 16.3375 10H25C25.2652 10 25.5196 10.1054 25.7071 10.2929C25.8946 10.4804 26 10.7348 26 11V14"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4 26L7.75 16.625C7.82313 16.4397 7.95062 16.2808 8.11571 16.1692C8.2808 16.0577 8.47576 15.9987 8.675 16H14.7C14.8949 15.9979 15.0853 15.9417 15.25 15.8375L17.75 14.1625C17.9147 14.0583 18.1051 14.0021 18.3 14H28.6125C28.7706 14 28.9265 14.0375 29.0673 14.1094C29.2082 14.1813 29.3299 14.2856 29.4227 14.4137C29.5154 14.5418 29.5764 14.69 29.6007 14.8463C29.625 15.0025 29.6119 15.1623 29.5625 15.3125L26 26H4Z"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default FolderIcon;

import { Component } from "react";
import { Page, PageActions, Layout, Card, FormLayout, TextField, Stack } from "@shopify/polaris";
import moment from "moment";
import { toastr } from "../../components/toastr.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import API from "../../API";

class RejectedEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			item: null,
			loading: true,
			saving: false,
		};
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem() {
		API.get("/api/reject/" + this.state.id + ".json")
			.then((response) => {
				if (response.data.rejected) {
					this.setState({ item: response.data.rejected });
				} else {
					toastr.error("Kunde inte hämta nekad tid!");
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				this.setState({ loading: false });
			});
	}

	remove() {
		this.setState({ saving: true });
		API.put("/api/reject/" + this.state.item.id + ".json", { done: true })
			.then((response) => {
				if (response.data.rejected) {
					toastr.success("Markerade nekade tider för vecka " + moment(this.state.item.from_date).isoWeek() + " som färdiga");
					this.props.history.push("/admin/rejected_weeks", {});
					return;
				}
				toastr.error("Något gick fel");
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				this.setState({ saving: false });
			});
	}

	save() {
		this.setState({ saving: true });

		API.put("/api/reject/" + this.state.item.id + ".json", { reason: this.state.item.reason, done: false })
			.then((response) => {
				if (response.data.rejected) {
					toastr.success("Ändringen lyckades!");
					this.props.history.push("/admin/rejected_weeks", {});
					return;
				}
				toastr.error("Något gick fel");
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				this.setState({ saving: false });
			});
	}

	render() {
		if (this.state.loading || !this.state.item) return <SkeletonProvider />;

		return (
			<Page
				title={"Nekad tid (Vecka " + moment(this.state.item.from_date).isoWeek() + ")"}
				backAction={{
					content: "Tillbaka",
					onAction: () => {
						this.props.history.push("/admin/rejected_weeks", {});
					},
				}}
				primaryAction={{
					content: "Spara",
					loading: this.state.saving,
					disabled: this.state.saving,
					onAction: this.save.bind(this),
				}}
			>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<FormLayout>
								<Stack distribution="fill">
									<TextField type="date" label="Från" value={moment(this.state.item.from_date).format("YYYY-MM-DD")} max="9999-12-31" disabled />
									<TextField type="date" label="Till" value={moment(this.state.item.to_date).format("YYYY-MM-DD")} max="9999-12-31" disabled />
								</Stack>
								<TextField disabled label="Gäller för" value={this.state.item.user.name} />
								<TextField
									label="Anledning"
									value={this.state.item.reason}
									onChange={(value) => {
										this.state.item.reason = value;
										this.setState({ item: this.state.item });
									}}
								/>
							</FormLayout>
						</Card>
					</Layout.Section>
				</Layout>

				<PageActions
					primaryAction={{
						content: "Markera som färdig",
						destructive: true,
						loading: this.state.saving,
						disabled: this.state.saving,
						onAction: () => {
							// eslint-disable-next-line no-alert
							if (window.confirm("Är du säker på att du vill markera denna nekad tid som färdig?")) {
								this.remove();
							}
						},
					}}
				/>
			</Page>
		);
	}
}

export default RejectedEdit;

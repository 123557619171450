/* eslint-disable camelcase */
/* eslint-disable func-names */
import axios from "axios";
import { toastr } from "../components/toastr";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "../Utilities";
import BASE_URL from "./BASE_URL";
import { ERROR_MESSAGES } from "src/constants/errors";
import retryFortnoxRequest from "./retryFortnoxRequest";

console.debug("BASE_URL:", BASE_URL);
const API = axios.create({
	timeout: 60000,
	// baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "https://itbmedia3.eu.ngrok.io" : "",
	// baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://192.168.10.225:8000" : "",
	// baseURL: "http://192.168.1.201:8000",
	baseURL: BASE_URL,
});

API.interceptors.request.use(
	async (config) => {
		try {
			const urlParams = new URLSearchParams(window.location.search);

			if (!config.params) {
				config.params = {};
			}

			const acc_id = urlParams.get("acc_id");
			if (acc_id) config.params.acc_id = acc_id;

			const account_id = urlParams.get("account_id");
			if (account_id) config.params.account_id = account_id;

			const token = urlParams.get("token") || getCookie("token");
			if (token) config.params.token = token;

			// if (!config.headers.cookie) {
			// 	config.headers.cookie = "";
			// }
			// config.headers.cookie += ` token=${token};`;

			const signed_token = urlParams.get("signed_token");
			if (signed_token) config.params.signed_token = signed_token;

			return config;
		} catch (e) {
			console.error("error", e);
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		if (response.data.error) {
			toastr.error(response.data.error);
			return response;
			// throw response.data.error;
		}

		return response;
	},
	async function (error) {
		if (axios.isCancel(error)) {
			console.debug("Request canceled");
			return Promise.reject(error);
		}

		console.error("error:", error);
		// eslint-disable-next-line no-unused-vars
		const { code, message } = error.response?.data || {};

		if ([ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN, ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN].includes(message)) {
			return await retryFortnoxRequest(error);
		}
		if (code === 401 || error?.headers?.location === "/admin/authentication/login") {
			window.location.assign(encodeURI("/admin/authentication/login?error_code=401&error_msg=Invalid login session"));
		}

		// console.log("API error:", error);
		return Promise.reject(error);
	}
);

export default API;

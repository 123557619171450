import { HorizontalStack, Pagination, useEventListener } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import Badge from "src/js/components/Badge";
import IndexTable from "src/js/components/IndexTable";
import TimeReportModal from "src/js/components/TimeReportModal";
import DeviatingRegistrationsModal from "./DeviatingRegistrationsModal";

const DeviatingRegistrationsIndex = ({ history, location }) => {
	const defaultParams = location?.state || {
		from: moment().locale("sv").startOf("week").format("YYYY-MM-DD"),
		to: moment().locale("sv").endOf("week").format("YYYY-MM-DD"),
	};
	const [params, setParams] = useState(defaultParams);
	const [selected, setSelected] = useState<any>(null);
	const refresh = useRef<any>(null);
	const indexTableRef = useRef<any>(null);
	const columns = [
		{
			handle: "NAME",
			label: "Namn",
			render: (item) => {
				return item.user.name;
			},
		},
		{
			handle: "DATE",
			label: "Datum",
			render: (item) => {
				return item.date;
			},
		},
		{
			handle: "REASON",
			label: "Anledning",
			render: (item) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { description, title, status } = getReason(item);
				if (!description) return null;

				return <Badge status={status}>{description}</Badge>;
			},
		},
	];

	const getReason = (item) => {
		const totalMinutes = item?.time_reports?.reduce((acc, curr) => acc + (curr.total_minutes || 0), 0) || 0;

		if (item.work_minutes && !totalMinutes) return { title: "Saknas", description: "Ingen registrering finns.", stats: "info" };

		if (item.work_minutes && totalMinutes && item.work_minutes !== totalMinutes) {
			return { title: "Avvikelse", description: "Timmar diffar mot schema.", status: "attention" };
		}

		if (!item.work_minutes && totalMinutes) return { title: "Felaktigt", description: "Registrering utan schema.", status: "critical" };

		return {};
	};

	const handleGoNext = useCallback(() => {
		// add 1 week
		const prms = {
			...(params || {}),
			from: moment(params.from).add(1, "week").format("YYYY-MM-DD"),
			to: moment(params.to).add(1, "week").format("YYYY-MM-DD"),
		};
		setParams(prms);

		indexTableRef.current?.onChangeFilters(
			Object.entries(prms)?.map(([key, value]) => ({
				key,
				value,
			}))
		);
	}, [params]);

	const handleGoPrevious = useCallback(() => {
		// add 1 week
		const prms = {
			...(params || {}),
			from: moment(params.from).subtract(1, "week").format("YYYY-MM-DD"),
			to: moment(params.to).subtract(1, "week").format("YYYY-MM-DD"),
		};
		setParams(prms);

		indexTableRef.current?.onChangeFilters(
			Object.entries(prms)?.map(([key, value]) => ({
				key,
				value,
			}))
		);
	}, [params]);

	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === "ArrowLeft") handleGoPrevious();
			if (e.key === "ArrowRight" && !moment(params.to).isAfter(moment())) handleGoNext();
		},
		[handleGoPrevious, handleGoNext, params?.to]
	);
	useEventListener("keydown", handleKeyDown);

	// useEffect(() => {
	// 	console.debug("params:", params);
	// 	history.replace(history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
	// }, [params, history]);

	return (
		<>
			<IndexTable
				ref={indexTableRef}
				defaultSort="ID_DESC"
				history={history}
				columns={columns}
				onClickRow={(item) => {
					setSelected({ ...item, title: getReason(item)?.title });

					// if (getReason(item)?.title === "Saknas") {
					// 	setTimeReportModalIsOpen(true);
					// }

					// history.push("/admin/deviating_registrations/" + id);
				}}
				setRefreshHandler={(refreshHandler) => {
					// eslint-disable-next-line react/no-unused-class-component-methods
					refresh.current = refreshHandler;
				}}
				title="Avvikande registreringar"
				savedSearchHandle="time_reports_deviant"
				resourceUrl="/api/time_reports_deviant.json"
				resourceHandle="events"
				resourceName={{
					singular: "Avvikande registering",
					plural: "Avvikande registreringar",
				}}
				filters={[
					{
						key: "user_ids",
						label: "Användare",
						type: "users",
					},
					{
						key: "from",
						label: "Från",
						type: "date",
					},
					{
						key: "to",
						label: "Till",
						type: "date",
					},
				]}
				params={params}
				onUpdateParams={(params) => {
					setParams((c: any) => ({ ...defaultParams, from: c.from, to: c.to, ...params }));
				}}
				footer={
					<>
						<HorizontalStack gap="3" align="center">
							{moment(params.from).format("YYYY-MM-DD")} - {moment(params.to).format("YYYY-MM-DD")}
						</HorizontalStack>
						<Pagination onNext={handleGoNext} onPrevious={handleGoPrevious} hasNext={!moment(params.to).isAfter(moment())} hasPrevious />
					</>
				}
			/>

			<DeviatingRegistrationsModal
				item={selected}
				open={selected && getReason(selected)?.title !== "Saknas"}
				onClose={() => {
					setSelected(null);
				}}
				onDelete={(timereport) => {
					setSelected((c) => {
						const index = c.time_reports.findIndex((ct) => ct.id === timereport.id);
						c.time_reports.splice(index, 1);
						return { ...c };
					});

					refresh.current?.();
				}}
				onComplete={(timereport) => {
					setSelected((c) => {
						const index = c.time_reports.findIndex((ct) => ct.id === timereport.id);
						if (index >= 0) {
							c.time_reports[index] = timereport;
						} else {
							c.time_reports.push(timereport);
						}
						return { ...c };
					});

					refresh.current?.();
				}}
				user={selected?.user}
			/>

			<TimeReportModal
				date={selected?.date}
				open={selected && getReason(selected)?.title === "Saknas"}
				onClose={() => {
					// setTimeReportModalIsOpen(false);
					setSelected(null);
				}}
				onComplete={() => {
					// setTimeReportModalIsOpen(false);
					setSelected(null);
					refresh.current?.();
				}}
				user_id={selected?.user?.id}
				// user_id={store.getState().user.id}
				user_name={selected?.user?.name}
				lock_user
			/>
		</>
	);
};
export default DeviatingRegistrationsIndex;

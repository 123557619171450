/* eslint-disable react/jsx-props-no-spreading */
import { Spinner as PolarisSpinner, SpinnerProps as PolarisSpinnerProps } from "@shopify/polaris";
import React from "react";

type SpinnerProps = PolarisSpinnerProps & {
	style?: React.CSSProperties;
};

const Spinner = ({ style, ...props }: SpinnerProps) => {
	if (style) {
		return (
			<span style={style}>
				<PolarisSpinner {...props} />
			</span>
		);
	}
	return <PolarisSpinner {...props} />;
};
export default Spinner;

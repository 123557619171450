import { Component } from "react";
import { Page, PageActions, Layout, Card, DatePicker, FormLayout, TextField, Stack, Modal, Text } from "@shopify/polaris";
import moment from "moment";

import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import SkeletonProvider from "../../components/skeleton_provider.js";
import { toastr } from "../../components/toastr.js";
import API from "../../API";

class LedgerCheckinEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			hours: "8",
			showRemoveModal: false,
			saving: false,
			loading: true,
			parent_state: null,
		};
		if (props.location.state) {
			this.state.parent_state = props.location.state;
		}
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/ledger_checkins/" + id + ".json")
			.then((result) => {
				const d = new Date(result.data.ledger_checkin.checkin_at);
				const minutes = result.data.ledger_checkin.minutes;
				if (minutes) {
					if (minutes > 60) {
						if (minutes % 60 == 0) {
							this.state.hours = minutes / 60;
						} else {
							const mins = minutes % 60;
							const hours = (minutes - mins) / 60;
							this.state.hours = hours + "." + mins;
						}
					} else {
						this.state.hours = "0." + minutes;
					}
				}

				const obminutes = result.data.ledger_checkin.ob_minutes;
				if (obminutes) {
					if (obminutes >= 60) {
						if (obminutes % 60 == 0) {
							this.state.ob_hours = obminutes / 60;
						} else {
							const mins = obminutes % 60;
							const hours = (obminutes - mins) / 60;
							this.state.ob_hours = hours + "." + mins;
						}
					} else {
						this.state.ob_hours = "0." + obminutes;
					}
				}

				this.setState({
					hours: this.state.hours + "",
					ob_hours: this.state.ob_hours + "",
					loading: false,
					selected: { start: new Date(result.data.ledger_checkin.checkin_at), end: new Date(result.data.ledger_checkin.checkin_at) },
					month: d.getMonth(),
					year: d.getFullYear(),
					from_time: moment(result.data.ledger_checkin.checkin_at).format("HH:mm"),
					to_time: moment(result.data.ledger_checkin.checkout_at).format("HH:mm"),
					saving: false,
					form: result.data.ledger_checkin,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		var start = new Date(this.state.selected.start);
		start.setHours(0, 0, 0, 0);
		var end = new Date(this.state.selected.end);
		end.setHours(23, 59, 59, 0);

		this.setState({ saving: true });
		API.put("/api/ledger_checkins/" + this.state.id + ".json", {
			whole_day: this.state.form.whole_day,
			hours: this.state.hours,
			ob_hours: this.state.ob_hours,
			comment: this.state.form.comment,
			checkin_at: start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " " + this.state.from_time,
			checkout_at: end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate() + " " + this.state.to_time,
		})
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				var d = new Date(result.data.ledger_checkin.checkin_at);
				this.setState({
					loading: false,
					selected: { start: new Date(result.data.ledger_checkin.checkin_at), end: new Date(result.data.ledger_checkin.checkin_at) },
					month: d.getMonth(),
					year: d.getFullYear(),
					from_time: moment(result.data.ledger_checkin.checkin_at).format("HH:mm"),
					to_time: moment(result.data.ledger_checkin.checkout_at).format("HH:mm"),
					saving: false,
					form: result.data.ledger_checkin,
				});
				toastr.success("Rapport ändrad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeTemplate() {
		this.setState({ saving: true });
		API.delete("/api/ledger_checkins/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Rapport borttagen");
				this.props.history.replace("/admin/ledger_checkins");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		var disableDatesBefore = null;

		var disabled = false;

		return (
			<Page
				fullWidth
				title={"Personliggarregistrering " + this.state.form.id}
				primaryAction={{
					content: "Uppdatera",
					disabled,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
				backAction={{ content: "Personalliggare", url: "/admin/ledger_checkins" }}
			>
				<CardsInPageWrapper>
					<Layout>
						<Layout.Section oneThird>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Allmänt om registrering
							</Text>
							<Text as="p" variant="bodyMd">
								Grundläggande information om registreringen
							</Text>
							<Card sectioned>
								<FormLayout>
									<TextField value={this.state.form.user.name} label="Användaren" disabled />
									<TextField value={this.state.form.work_order ? this.state.form.work_order.full_label : "–"} label="Arbetsorder" disabled />
									<TextField value={moment(this.state.form.created_at).format("YYYY-MM-DD HH:mm")} label="Skapad" disabled />
									{this.state.form.created_at != this.state.form.updated_at && (
										<TextField value={moment(this.state.form.updated_at).format("YYYY-MM-DD HH:mm")} label="Ändrad" disabled />
									)}
								</FormLayout>
							</Card>
						</Layout.Section>
						<Layout.Section oneThird>
							<Text as="h3" variant="headingMd" fontWeight="bold">
								Registrerad tid
							</Text>
							<Text as="p" variant="bodyMd">
								Detta är tid rapporterad av användaren
							</Text>
							<Card sectioned>
								<DatePicker
									disabled={disabled}
									month={this.state.month}
									year={this.state.year}
									weekStartsOn={1}
									onChange={(value) => {
										this.setState({ selected: value });
									}}
									onMonthChange={(month, year) => {
										this.setState({ month, year });
									}}
									selected={this.state.selected}
									multiMonth={false}
									allowRange={false}
									disableDatesAfter={new Date()}
									disableDatesBefore={disabled ? new Date() : disableDatesBefore}
								/>
							</Card>
							<Card sectioned>
								<Stack distribution="fillEvenly">
									<TextField
										disabled={disabled}
										value={this.state.from_time}
										onChange={(value) => {
											this.setState({ from_time: value });
										}}
										label="Från tid"
										type="time"
									/>
									<TextField
										disabled={disabled}
										value={this.state.to_time}
										onChange={(value) => {
											this.setState({ to_time: value });
										}}
										label="Till tid"
										type="time"
									/>
								</Stack>
							</Card>
						</Layout.Section>
						<Layout.Section oneThird />
					</Layout>
				</CardsInPageWrapper>

				<PageActions
					primaryAction={{
						content: "Uppdatera",
						disabled,
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
					secondaryActions={[
						{
							content: "Ta bort",
							destructive: true,
							disabled,
							loading: this.state.saving,
							onAction: this.removeModal.bind(this),
						},
					]}
				/>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort registrerad tid?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeTemplate.bind(this),
					}}
				>
					<Modal.Section>Är du säker?</Modal.Section>
				</Modal>
			</Page>
		);
	}
}

export default LedgerCheckinEdit;

import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { merge } from "lodash";
import { Spinner } from "@shopify/polaris";
import styled from "styled-components";
import Colors, { colorToRgba } from "../Colors";

const BarChart = ({ title, labels, series, options: propsOptions, loading, onClick, height: propsHeight }) => {
	const ref = useRef<any | null>(null);
	const wrapperRef = useRef<any | null>(null);
	const [height, setHeight] = useState<number | string>(propsHeight || 200);

	useEffect(() => {
		if (ref.current && !propsHeight) {
			const container = ref.current.container.current;
			const { height: containerHeight } = wrapperRef.current.getBoundingClientRect();

			container.style.height = "100%";
			container.style.width = "100%";
			ref.current.chart.container.style.height = "100%";
			ref.current.chart.container.style.width = "100%";
			setHeight(containerHeight);
			ref.current.chart.reflow();
		}

		return () => {
			setHeight(propsHeight || 200);
		};
	}, [propsHeight]);

	const options = merge(
		{
			chart: {
				type: "column",
				height,
			},
			title: {
				text: title,
			},
			xAxis: {
				categories: labels,
			},
			yAxis: {
				title: undefined,
			},
			series,
			plotOptions: {
				series: {
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
					},
					fillOpacity: 1,
					borderColor: "none",
					borderRadius: 3,
					events: {
						cursor: onClick ? "pointer" : null,
						click: onClick,
					},
				},
			},
		},
		propsOptions
	);

	return (
		<Wrapper className="area_chart" ref={wrapperRef}>
			{loading && (
				<div
					style={{
						inset: 0,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						background: colorToRgba(Colors.primary, 0.35),
						zIndex: 1,
					}}
				>
					<Spinner />
				</div>
			)}
			<HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
		</Wrapper>
	);
};
export default BarChart;
const Wrapper = styled.div`
	position: relative;
	/* height: calc(100% - 55px); */
	height: 100%;
	.highcharts-legend-item.highcharts-color-undefined text,
	.highcharts-title {
		fill: ${Colors["color-primary"]} !important;
		color: ${Colors["color-primary"]} !important;
	}
`;

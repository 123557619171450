import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

// eslint-disable-next-line no-unused-vars
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

class MapWithMultipleMarkers2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: props.height,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (JSON.stringify(nextProps.items) != JSON.stringify(this.props.items)) {
			return true;
		}
		return false;
	}

	onClick(item) {
		this.props.onMarkerClick(item);
	}

	render() {
		const MyMapComponent = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap defaultZoom={12} defaultCenter={{ lat: this.props.items.latitude, lng: this.props.items.longitude }}>
					{this.props.items.longitude && this.props.items.report_type != "inout" ? (
						<Marker
							key={this.props.items.id + "in"}
							position={{ lat: this.props.items.latitude, lng: this.props.items.longitude }}
							onClick={this.onClick.bind(this, this.props.items)}
							icon="/assets/images/positionflag1.png"
							title={this.props.items.user.name + " In: " + this.props.items.checkin_at}
						/>
					) : null}

					{this.props.items.checkout_longitude && this.props.items.report_type != "inout" ? (
						<Marker
							key={this.props.items.id + "out"}
							position={{ lat: this.props.items.checkout_latitude, lng: this.props.items.checkout_longitude }}
							onClick={this.onClick.bind(this, this.props.items)}
							icon="/assets/images/positionflag2.png"
							title={this.props.items.user.name + " Ut: " + this.props.items.checkout_at}
						/>
					) : null}
				</GoogleMap>
			))
		);

		return (
			<MyMapComponent
				isMarkerShown={this.props.showMarker}
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCoDwig5ZiU2hMEGqBhIXU_w8-pKU4EFl8&v=3.exp&libraries=geometry,drawing,places"
				loadingElement={<div style={{ height: "100%" }} />}
				containerElement={<div style={{ height: this.state.height, position: "relative", zIndex: this.props.zIndexValue }} />}
				mapElement={<div style={{ height: "100%" }} />}
			/>
		);
	}
}

export default MapWithMultipleMarkers2;

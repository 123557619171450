/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import {
	ResourceList,
	Card,
	Layout,
	Page,
	TextStyle,
	FormLayout,
	Badge,
	Select,
	ChoiceList,
	Checkbox,
	Stack,
	Modal,
	ButtonGroup,
	Button,
} from "@shopify/polaris";
import "moment-timezone";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";

import { getTypeLabel } from "src/js/Utilities.js";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import { colorToRgba } from "src/js/Colors.js";
import TimePicker from "../../components/time_picker.js";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import SettingToggle from "../../components/SettingToggle.tsx";
import API from "../../API";
import { ColumnHeader, Event, Wrapper } from "../Users/Schedule.jsx";
import { getViewHeaderTitle, getViewTitle } from "../Planning/Utilities";

class ObSettings extends Component {
	constructor(props) {
		super(props);
		var defaultObType = store.getState().account.ob_types[0].id;

		this.state = {
			extra_settings: store.getState().account.extra_settings,
			showCreateEventModal: false,
			ob_type: defaultObType + "",
			recurring: "weekly",
			whole_day: true,
			selectedRemoveOption: "all",
			weekdays: [],
			dates: [],
			showRemoveEventModal: false,
			allow_all_complete_project: store.getState().account.allow_all_complete_project,
			workday_start: store.getState().account.workday_start,
			allow_create_expenses: store.getState().account.allow_create_expenses,
			has_personell_ledger: store.getState().account.has_personell_ledger,
			auto_activate_checkin: store.getState().account.auto_activate_checkin,
			allow_time_reporting_yesterday: store.getState().account.allow_time_reporting_yesterday,
			has_underentreprenorer: store.getState().account.has_underentreprenorer,
			ledger_linked: store.getState().account.ledger_linked,
			workday_end: store.getState().account.workday_end,
			available_types: store.getState().account.available_types,
			lunch: store.getState().account.lunch + "",
			days_back: store.getState().account.days_back + "",
			input_type: store.getState().account.input_type,
			// checkin: store.getState().account.checkin,
			// checkin_screen: store.getState().account.checkin_screen,
			enable_special_times: store.getState().account.enable_special_times,
			disable_editing_group_leader: store.getState().account.disable_editing_group_leader,
			events: [],
			saving: false,
		};
	}

	renderItem(item) {
		return (
			<ResourceList.Item id={item.id}>
				<div>{item.title}</div>
			</ResourceList.Item>
		);
	}

	componentDidMount() {
		var date = new Date();
		var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		this.fetchEvents(this.state.id, firstDay, lastDay);
		this.fetchCauseCodes();
	}

	fetchEvents(id, fromDate, toDate) {
		this.setState({ viewFrom: fromDate, viewTo: toDate });
		var from = fromDate.getFullYear() + "-" + this.leadingZero(fromDate.getMonth() + 1) + "-" + this.leadingZero(fromDate.getDate());
		var to = toDate.getFullYear() + "-" + this.leadingZero(toDate.getMonth() + 1) + "-" + this.leadingZero(toDate.getDate());
		API.get("/api/ob_times/events.json", {
			params: { from, to },
		})
			.then((result) => {
				this.setState({ events: this.parseEvents(result.data.ob_times) });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	parseEvents(events) {
		for (var i = 0; i < events.length; i++) {
			events[i].start = new Date(events[i].event_start_at);
			events[i].end = new Date(events[i].event_end_at);
		}
		return events;
	}

	reloadEvents() {
		this.fetchEvents(this.state.id, this.state.viewFrom, this.state.viewTo);
	}

	updateWorkdayStart(value) {
		const diff = moment(moment(moment().format("YYYY-MM-DD ") + this.state.workday_end)).diff(moment().format("YYYY-MM-DD ") + value, "minutes");
		if (diff < 30) {
			this.state.workday_end = moment(moment().format("YYYY-MM-DD ") + value)
				.add(30, "minutes")
				.format("HH:mm");

			this.setState({ workday_end: this.state.workday_end });

			this.updateWorkdayEnd(this.state.workday_end);
		}

		this.setState({ workday_start: value, saving: true });
		API.put("/api/account.json", { workday_start: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_WORKDAY_START", workday_start: result.data.account.workday_start });
				toastr.success("Arbetsdag ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateWorkdayEnd(value) {
		this.setState({ workday_end: value, saving: true });
		API.put("/api/account.json", { workday_end: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_WORKDAY_END", workday_end: result.data.account.workday_end });
				toastr.success("Arbetsdag ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateHasUnderentreprenorer() {
		this.setState({ has_underentreprenorer: !store.getState().account.has_underentreprenorer, saving: true });
		API.put("/api/account.json", { has_underentreprenorer: !store.getState().account.has_underentreprenorer })
			.then((result) => {
				this.setState({ saving: false, has_underentreprenorer: result.data.account.has_underentreprenorer });
				store.dispatch({ type: "SET_HAS_UNDERENTREPRENORER", has_underentreprenorer: result.data.account.has_underentreprenorer });
				toastr.success("Inställning för personalliggare ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	async updateEnableSpecialTimes() {
		this.setState({ enable_special_times: !store.getState().account.enable_special_times });
		try {
			const result = await API.put("/api/account.json", { enable_special_times: !store.getState().account.enable_special_times });
			this.setState({ saving: false, enable_special_times: result.data.account.enable_special_times });
			store.dispatch({ type: "SET_ENABLE_SPECIAL_TIMES", enable_special_times: result.data.account.enable_special_times });
			toastr.success("Inställning för specialtider ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	async updateDisableEditingGroupLeader() {
		this.setState({ disable_editing_group_leader: !store.getState().account.disable_editing_group_leader });
		try {
			const result = await API.put("/api/account.json", { disable_editing_group_leader: !store.getState().account.disable_editing_group_leader });
			this.setState({ saving: false, disable_editing_group_leader: result.data.account.disable_editing_group_leader });
			store.dispatch({ type: "SET_DISABLE_EDITING_GROUP_LEADER", disable_editing_group_leader: result.data.account.disable_editing_group_leader });
			toastr.success("Inställning för redigeringsmöjlighet ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	async updateAllowerCreateExpense() {
		this.setState({ allow_create_expenses: !store.getState().account.allow_create_expenses });
		try {
			const result = await API.put("/api/account.json", { allow_create_expenses: !store.getState().account.allow_create_expenses });
			this.setState({ saving: false, allow_create_expenses: result.data.account.allow_create_expenses });
			store.dispatch({ type: "SET_ALLOW_CREATE_EXPENSES", allow_create_expenses: result.data.account.allow_create_expenses });
			toastr.success("Inställning för artikelregister ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	async updateAutoActivateCheckin() {
		this.setState({ auto_activate_checkin: !store.getState().account.auto_activate_checkin });
		try {
			const result = await API.put("/api/account.json", { auto_activate_checkin: !store.getState().account.auto_activate_checkin });
			this.setState({ saving: false, auto_activate_checkin: result.data.account.auto_activate_checkin });
			store.dispatch({ type: "SET_AUTO_ACTIVATE_CHECKIN", auto_activate_checkin: result.data.account.auto_activate_checkin });
			toastr.success("Inställning för automatisk stämpelklocka ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	async updateHasPersonellLedger() {
		this.setState({ has_personell_ledger: !store.getState().account.has_personell_ledger });
		try {
			const result = await API.put("/api/account.json", { has_personell_ledger: !store.getState().account.has_personell_ledger });
			this.setState({ saving: false, has_personell_ledger: result.data.account.has_personell_ledger });
			store.dispatch({ type: "SET_HAS_PERSONELL_LEDGER", has_personell_ledger: result.data.account.has_personell_ledger });
			toastr.success("Inställning för personalliggare ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	updateLedgerLinked() {
		this.setState({ ledger_linked: !store.getState().account.ledger_linked, saving: true });
		API.put("/api/account.json", { ledger_linked: !store.getState().account.ledger_linked })
			.then((result) => {
				this.setState({ saving: false, ledger_linked: result.data.account.ledger_linked });
				store.dispatch({ type: "SET_LEDGER_LINKED", ledger_linked: result.data.account.ledger_linked });
				toastr.success("Inställning för personalliggare ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateLunch(value) {
		this.setState({ lunch: value, saving: true });
		API.put("/api/account.json", { lunch: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_WORKDAY_LUNCH", lunch: result.data.account.lunch });
				toastr.success("Arbetsdag ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateDaysback(value) {
		this.setState({ days_back: value, saving: true });
		API.put("/api/account.json", { days_back: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_DAYS_BACK", days_back: result.data.account.days_back });
				toastr.success("Regel ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	async updateAllCompleteProject() {
		this.setState({ allow_all_complete_project: !store.getState().account.allow_all_complete_project });
		try {
			const result = await API.put("/api/account.json", { allow_all_complete_project: !store.getState().account.allow_all_complete_project });
			this.setState({ saving: false, allow_all_complete_project: result.data.account.allow_all_complete_project });
			store.dispatch({ type: "SET_ALLOW_ALL_COMPLETE_PROJECT", allow_all_complete_project: result.data.account.allow_all_complete_project });
			toastr.success("Regel ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	async updateAllowTimeReportingYesterday() {
		this.setState({ allow_time_reporting_yesterday: !store.getState().account.allow_time_reporting_yesterday });
		try {
			const result = await API.put("/api/account.json", { allow_time_reporting_yesterday: !store.getState().account.allow_time_reporting_yesterday });
			this.setState({ saving: false, allow_time_reporting_yesterday: result.data.account.allow_time_reporting_yesterday });
			store.dispatch({
				type: "SET_ALLOW_TIME_REPORTING_YESTERDAY",
				allow_time_reporting_yesterday: result.data.account.allow_time_reporting_yesterday,
			});
			toastr.success("Regel ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	updateInputType(value) {
		value = value[0];
		this.setState({ input_type: value, saving: true });
		API.put("/api/account.json", { input_type: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_INPUT_TYPE", input_type: result.data.account.input_type });
				toastr.success("Regel ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateAvailableTypes(value) {
		this.setState({ available_types: value, saving: true });
		API.put("/api/account.json", { available_types: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_AVAILABLE_TYPES", available_types: result.data.account.available_types });
				toastr.success("Regel ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	removeObTime() {
		this.setState({ saving: true });
		if (this.state.editingEvent.recurring && this.state.selectedRemoveOption != "all") {
			if (this.state.selectedRemoveOption == "this") {
				var skipDays = this.state.editingEvent.skip_days;
				var date =
					this.state.editingEvent.start.getFullYear() +
					"-" +
					this.leadingZero(this.state.editingEvent.start.getMonth() + 1) +
					"-" +
					this.leadingZero(this.state.editingEvent.start.getDate());
				skipDays.push(date);
				API.put("/api/ob_times/" + this.state.editingEvent.id + ".json", { skip_days: skipDays })
					.then((result) => {
						this.setState({ saving: false });
						if (result.data.error) {
							return;
						}
						toastr.success("Obekväm tid ändrades");
						this.closeRemoveEventModal();
						this.reloadEvents();
					})
					.catch((error) => {
						this.setState({ saving: false });
						toastr.error(error);
					});
			} else if (this.state.selectedRemoveOption == "future") {
				API.put("/api/ob_times/" + this.state.editingEvent.id + ".json", { expire_at: this.state.editingEvent.event_start_at })
					.then((result) => {
						this.setState({ saving: false });
						if (result.data.error) {
							return;
						}
						toastr.success("Obekväm tid ändrades");
						this.closeRemoveEventModal();
						this.reloadEvents();
					})
					.catch((error) => {
						this.setState({ saving: false });
						toastr.error(error);
					});
			}
		} else {
			API.delete("/api/ob_times/" + this.state.editingEvent.id + ".json")
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("Obekväm tid borttagen");
					this.closeRemoveEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	rangeChange(range) {
		let fromDate = range.start;
		let toDate = range.end;
		if (range.start) {
			fromDate = range.start;
			toDate = range.end;
		} else {
			fromDate = null;
			toDate = null;
			for (var i = 0; i < range.length; i++) {
				if (!fromDate) {
					fromDate = range[i];
				}
				toDate = range[i];
			}
		}
		this.setState({ viewFrom: fromDate, viewTo: toDate });
		this.fetchEvents(this.state.id, fromDate, toDate);
	}

	leadingZero(val) {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	}

	getWeekdayName(weekDay) {
		if (weekDay == 0) {
			return "Söndag";
		} else if (weekDay == 1) {
			return "Måndag";
		} else if (weekDay == 2) {
			return "Tisdag";
		} else if (weekDay == 3) {
			return "Onsdag";
		} else if (weekDay == 4) {
			return "Torsdag";
		} else if (weekDay == 5) {
			return "Fredag";
		} else if (weekDay == 6) {
			return "Lördag";
		}
	}

	editEvent(item) {
		var dates = [];
		var weekDays = [];
		var start = new Date(item.start_at);
		var end = new Date(item.end_at);
		var ymd = start.getFullYear() + "-" + this.leadingZero(start.getMonth() + 1) + "-" + this.leadingZero(start.getDate());
		if (dates.indexOf(ymd) < 0) {
			dates.push(ymd);
		}
		if (weekDays.indexOf(this.getWeekdayName(start.getDay())) < 0) {
			weekDays.push(this.getWeekdayName(start.getDay()));
		}

		var toTime = this.leadingZero(end.getHours()) + ":" + this.leadingZero(end.getMinutes());
		var fromTime = this.leadingZero(start.getHours()) + ":" + this.leadingZero(start.getMinutes());

		this.setState({
			selectedRemoveOption: "all",
			editingEvent: item,
			ob_type: item.ob_type + "",
			// day_name: item.title,
			whole_day: item.whole_day,
			recurring: item.recurring ? item.recurring : "single",
			to_time: toTime,
			from_time: fromTime,
			weekdays: weekDays,
			dates,
			showCreateEventModal: true,
		});
	}

	handleSelect(selection) {
		const dates = [];
		const weekDays = [];
		let wholeDay = false;
		for (var i = 0; i < selection.slots.length; i++) {
			var ymd =
				selection.slots[i].getFullYear() +
				"-" +
				this.leadingZero(selection.slots[i].getMonth() + 1) +
				"-" +
				this.leadingZero(selection.slots[i].getDate());
			if (dates.indexOf(ymd) < 0) {
				dates.push(ymd);
			}
			if (weekDays.indexOf(this.getWeekdayName(selection.slots[i].getDay())) < 0) {
				weekDays.push(this.getWeekdayName(selection.slots[i].getDay()));
			}
		}
		let toTime = store.getState().account.workday_end;
		let fromTime = store.getState().account.workday_start;
		if (dates.length > 1) {
			wholeDay = true;
			toTime = store.getState().account.workday_end;
			fromTime = store.getState().account.workday_start;
		} else {
			if (selection.start.getHours() < 1) {
				wholeDay = true;
			}
			toTime = this.leadingZero(selection.end.getHours()) + ":" + this.leadingZero(selection.end.getMinutes());
			fromTime = this.leadingZero(selection.start.getHours()) + ":" + this.leadingZero(selection.start.getMinutes());
		}

		this.setState({
			whole_day: wholeDay,
			to_time: toTime,
			from_time: fromTime,
			weekdays: weekDays,
			dates,
			showCreateEventModal: true,
			editingEvent: null,
		});
	}

	closeEventModal() {
		this.setState({ showCreateEventModal: false });
	}

	removeEventModal() {
		this.setState({ showCreateEventModal: false, showRemoveEventModal: true });
	}

	closeRemoveEventModal() {
		this.setState({ showRemoveEventModal: false });
	}

	saveObTime() {
		this.setState({ saving: true });
		if (this.state.editingEvent) {
			API.put("/api/ob_times/" + this.state.editingEvent.id + ".json", {
				ob_type: this.state.ob_type,
				recurring: this.state.recurring,
				time_start: this.state.from_time,
				whole_day: this.state.whole_day,
				time_end: this.state.to_time,
				dates: this.state.dates,
			})
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("ob-tid ändrades");
					this.closeEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/ob_times.json", {
				ob_type: this.state.ob_type,
				recurring: this.state.recurring,
				time_start: this.state.from_time,
				whole_day: this.state.whole_day,
				time_end: this.state.to_time,
				dates: this.state.dates,
			})
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					toastr.success("ob-tid skapades");
					this.closeEventModal();
					this.reloadEvents();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	updateExtraSettings(value) {
		this.setState({ extra_settings: value, saving: true });
		API.put("/api/account.json", { extra_settings: value })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({ type: "SET_EXTRA_SETTINGS", extra_settings: result.data.account.extra_settings });
				toastr.success("Inställning ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	eventStyleGetter(event, start, end, selected) {
		const style = {
			backgroundColor: selected ? event.color : colorToRgba(event.color, 0.75),
			borderRadius: "3px",
			opacity: 0.8,
			border: "0px",
			transition: "all 0.2s ease-in-out",
			borderLeft: `3px solid ${event.color}`,
			color: "#ffffff",
		};

		return {
			style,
		};
	}

	async fetchCauseCodes() {
		try {
			const result = await API.get("/api/accounts/cause_codes.json");
			this.setState({ 
				overtime_cause_code: result.data.overtime_cause_code, 
				ob_weekday_cause_code: result.data.ob_weekday_cause_code,
				ob_weekend_cause_code: result.data.ob_weekend_cause_code,
			})

		} catch (error) {
			toastr.error(error);
		}
	}


	async updateCauseCode(key, value) {
		this.setState({ [key]: value });
		try {
			const result = await API.put("/api/account.json", { [key]: value });
			toastr.success("Kod ändrad");
		} catch (error) {
			toastr.error(error);
		}
	}

	render() {
		var lunchOptions = [
			{ label: "Inget avdrag", value: "0" },
			{ label: "30 minuter", value: "30" },
			{ label: "60 minuter", value: "60" },
		];

		if (store.getState().account.id == 163) {
			lunchOptions.push({ label: "72 minuter", value: "72" });
		}
		let modalActions = [];
		if (this.state.editingEvent) {
			modalActions = [
				{
					destructive: true,
					content: "Ta bort",
					onAction: this.removeEventModal.bind(this),
				},
				{
					content: "Close",
					onAction: this.closeEventModal.bind(this),
				},
			];
		} else {
			modalActions = [
				{
					content: "Close",
					onAction: this.closeEventModal.bind(this),
				},
			];
		}

		moment.locale("sv");
		const localizer = momentLocalizer(moment);

		return (
			<Page title="Tidrapportering" backAction={{ content: "Inställningar", url: "/admin/settings" }}>
				<Layout>
					<Layout.AnnotatedSection title="Normala arbetstider" description="Schemat används när du skapar nya användare">
						<Card sectioned>
							<Stack distribution="fillEvenly">
								<TimePicker
									label="Från klockan"
									placeholder="Välj klockslag"
									onChange={this.updateWorkdayStart.bind(this)}
									value={this.state.workday_start}
									editing={this.state.editingEvent}
								/>
								<TimePicker
									min={
										this.state.workday_start &&
										moment(moment().format("YYYY-MM-DD ") + this.state.workday_start)
											.add(30, "minutes")
											.format("HH:mm")
									}
									label="Till klockan"
									placeholder="Välj klockslag"
									onChange={this.updateWorkdayEnd.bind(this)}
									value={this.state.workday_end}
									editing={this.state.editingEvent}
								/>
								<Select label="Lunchavdrag" options={lunchOptions} onChange={this.updateLunch.bind(this)} value={this.state.lunch} />
							</Stack>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Övriga inställningar" description="Övriga inställningar rörande tidrapportering">
						<SettingToggle
							action={{
								content: this.state.disable_editing_group_leader ? "Stäng av" : "Slå på",
								onAction: this.updateDisableEditingGroupLeader.bind(this),
							}}
							enabled={this.state.disable_editing_group_leader}
						>
							<div>
								Hindra gruppledare från att skapa och redigera tidsregistreringar är{" "}
								<TextStyle variation="strong">{this.state.disable_editing_group_leader ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>
						<SettingToggle
							action={{
								content: this.state.enable_special_times ? "Stäng av" : "Slå på",
								onAction: this.updateEnableSpecialTimes.bind(this),
							}}
							enabled={this.state.enable_special_times}
						>
							<div>
								Specialtider <TextStyle variation="strong">{this.state.enable_special_times ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>
						<SettingToggle
							action={{
								content: this.state.auto_activate_checkin ? "Stäng av" : "Slå på",
								onAction: this.updateAutoActivateCheckin.bind(this),
							}}
							enabled={this.state.auto_activate_checkin}
						>
							<div>
								Aktivering av stämpelklocka som standard för alla projekt är{" "}
								<TextStyle variation="strong">{this.state.auto_activate_checkin ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>

						<SettingToggle
							action={{
								content: this.state.allow_create_expenses ? "Stäng av" : "Slå på",
								onAction: this.updateAllowerCreateExpense.bind(this),
							}}
							enabled={this.state.allow_create_expenses}
						>
							<div>
								Tillåt anställda att skapa artiklar:{" "}
								<TextStyle variation="strong">{this.state.allow_create_expenses ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>
						<SettingToggle
							action={{
								content: this.state.allow_all_complete_project ? "Stäng av" : "Slå på",
								onAction: this.updateAllCompleteProject.bind(this),
							}}
							enabled={this.state.allow_all_complete_project}
						>
							<div>
								Tillåt anställda att avsluta projekt:{" "}
								<TextStyle variation="strong">{this.state.allow_all_complete_project ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>
						<SettingToggle
							action={{
								content: this.state.allow_time_reporting_yesterday ? "Stäng av" : "Slå på",
								onAction: this.updateAllowTimeReportingYesterday.bind(this),
							}}
							enabled={this.state.allow_time_reporting_yesterday}
						>
							<div>
								Tillåt anställda att registrera tid föregående dag:{" "}
								<TextStyle variation="strong">{this.state.allow_time_reporting_yesterday ? "aktiverat" : "avstängt"}</TextStyle> för detta konto.
							</div>
						</SettingToggle>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Personalliggare" description="Huruvida personalliggare är aktiverat för detta konto">
						<SettingToggle
							action={{
								content: this.state.has_personell_ledger ? "Stäng av" : "Slå på",
								onAction: this.updateHasPersonellLedger.bind(this),
							}}
							enabled={this.state.has_personell_ledger}
						>
							<div>
								Personalliggare är <TextStyle variation="strong">{this.state.has_personell_ledger ? "aktiverat" : "avstängt"}</TextStyle> för detta
								konto.
							</div>

							{this.state.has_personell_ledger ? (
								<div style={{ marginTop: 15 }}>
									<Checkbox
										checked={this.state.has_underentreprenorer}
										onChange={this.updateHasUnderentreprenorer.bind(this)}
										label="Kontot har underentreprenörer"
									/>
								</div>
							) : null}

							{this.state.has_personell_ledger ? (
								<div style={{ marginTop: 15 }}>
									<Checkbox
										checked={this.state.ledger_linked}
										onChange={this.updateLedgerLinked.bind(this)}
										label="Koppla ihop personalliggare med incheckning"
									/>
								</div>
							) : null}
						</SettingToggle>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Begränsningar" description="Bestäm hur de anställda rapporterar">
						<Card sectioned>
							<Stack distribution="fillEvenly">
								<Select
									label="Ändringar bakåt i tiden"
									options={[
										{ label: "Samma dag", value: "0" },
										{ label: "Dagen efter", value: "1" },
										{ label: "2 dagar efter", value: "2" },
										{ label: "3 dagar efter", value: "3" },
										{ label: "4 dagar efter", value: "4" },
										{ label: "5 dagar efter", value: "5" },
										{ label: "6 dagar efter", value: "6" },
										{ label: "En vecka efter", value: "7" },
										{ label: "Obegränsat", value: "-1" },
									]}
									onChange={this.updateDaysback.bind(this)}
									value={this.state.days_back}
								/>
								<div>
									<ChoiceList
										title="Inmatningstyp"
										choices={[
											{ label: "In- och uttid", value: "inout" },
											{ label: "Timmar", value: "hours" },
										]}
										selected={[this.state.input_type]}
										onChange={this.updateInputType.bind(this)}
									/>
									<ChoiceList
										title="Övrigt"
										choices={[{ label: "Endast hela timmar", value: "only_hours" }]}
										allowMultiple
										selected={this.state.extra_settings}
										onChange={this.updateExtraSettings.bind(this)}
									/>
								</div>
							</Stack>
							<ChoiceList
								title="Tillgängliga rapporter"
								selected={this.state.available_types}
								onChange={this.updateAvailableTypes.bind(this)}
								choices={[
									{ label: "Arbetad tid", disabled: true, value: "project" },
									{ label: getTypeLabel("photo"), value: "photo" },
									{ label: getTypeLabel("expense"), value: "expense" },
									// { label: getTypeLabel("divergence"),  value: "divergence" },
									{ label: getTypeLabel("sick"), value: "sick" },
									{ label: getTypeLabel("vab"), value: "vab" },
									{ label: getTypeLabel("national_day"), value: "national_day" },
									{ label: getTypeLabel("parenting"), value: "parenting" },
									{ label: getTypeLabel("vacation"), value: "vacation" },
									{ label: getTypeLabel("service_free"), value: "service_free" },
									{ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" },
									{ label: getTypeLabel("birth_free"), value: "birth_free" },
									{ label: getTypeLabel("comp_free"), value: "comp_free" },
									{ label: getTypeLabel("weekend_salary"), value: "weekend_salary" },
									{ label: getTypeLabel("shorttime"), value: "shorttime" },
								]}
								allowMultiple
							/>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Övertid">
						<Card sectioned>
							<Select
								label="Kod för övertid"
								options={[
									{ label: "OK0, Extratid/Komptid", value: "OK0" },
									{ label: "OK1, Övertid/Komptid", value: "OK1" },
									{ label: "OK2, Övertid/Komptid", value: "OK2" },
									{ label: "OK3, Övertid/Komptid", value: "OK3" },
									{ label: "OK4, Övertid/Komptid", value: "OK4" },
									{ label: "OK5, Övertid/Betalning", value: "OK5" },
									{ label: "OT1, Övertid/Betalning", value: "OT1" },
									{ label: "OT2, Övertid/Betalning", value: "OT2" },
									{ label: "OT3, Övertid/Betalning", value: "OT3" },
									{ label: "OT4, Övertid/Betalning", value: "OT4" },
									{ label: "OT5, Övertid/Betalning", value: "OT5" },
								]}
								onChange={(selectedValue) => this.updateCauseCode("overtime_cause_code", selectedValue)}
								value={this.state.overtime_cause_code}
							/>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Obekväma arbetstider & helgdagar" description="Lägg till hela datum för helgdagar och obekväma arbetstider">
						<Card title="Typer av OB">
							<ResourceList
								resourceName={{
									singular: "typ",
									plural: "typer",
								}}
								items={store.getState().account.ob_types}
								renderItem={this.renderItem.bind(this)}
							/>
						</Card>
						<Card sectioned>
							<Select
								label="OB kod för vardagar"
								options={[
									{ label: "OB1", value: "OB1" },
									{ label: "OB2", value: "OB2" },
									{ label: "OB3", value: "OB3" },
									{ label: "OB4", value: "OB4" },
									{ label: "OB5", value: "OB5" },
								]}
								onChange={(selectedValue) => this.updateCauseCode("ob_weekday_cause_code", selectedValue)}
								value={this.state.ob_weekday_cause_code}
							/>

							<div style={{ marginTop: 15 }} />
							<Select
								label="OB kod för helger"
								options={[
									{ label: "OB1", value: "OB1" },
									{ label: "OB2", value: "OB2" },
									{ label: "OB3", value: "OB3" },
									{ label: "OB4", value: "OB4" },
									{ label: "OB5", value: "OB5" },
								]}
								onChange={(selectedValue) => this.updateCauseCode("ob_weekend_cause_code", selectedValue)}
								value={this.state.ob_weekend_cause_code}
							/>
						</Card>
						<Card sectioned>
							<div style={{ height: "80vh", minHeight: 600 }}>
								<Calendar
									selectable
									step={30}
									onRangeChange={this.rangeChange.bind(this)}
									localizer={localizer}
									events={this.state.events}
									onSelectEvent={this.editEvent.bind(this)}
									onSelectSlot={this.handleSelect.bind(this)}
									allDayAccessor="whole_day"
									eventPropGetter={this.eventStyleGetter.bind(this)}
									messages={{ today: "Idag", previous: "Föregående", next: "Nästa" }}
									components={{
										week: {
											header: (props) => {
												return (
													<ColumnHeader>
														<p className="date-name">{moment(props.date).format("dddd").capitalize()}</p>

														<p className="date">{moment(props.date).format("DD")}</p>
													</ColumnHeader>
												);
											},
										},

										toolbar: ({ onNavigate, date, label, onView, views, view: activeView, ...rest }) => {
											return (
												<Wrapper>
													<h3>{getViewHeaderTitle({ view: activeView, date, start: this.state.viewFrom, end: this.state.viewTo })}</h3>
													<p className="period">Period: {}</p>

													<ButtonGroup spacing="extraTight">
														<Button
															icon={ChevronLeftMinor}
															onClick={() => {
																onNavigate("PREV", date);
															}}
														/>

														{views?.map((view) => {
															return (
																<Button
																	key={view}
																	primary={view === activeView}
																	onClick={() => {
																		// eslint-disable-next-line react/no-unused-state
																		this.setState({ view });
																		onView(view);
																	}}
																>
																	{getViewTitle(view)}
																</Button>
															);
														})}

														<Button
															icon={ChevronRightMinor}
															onClick={() => {
																onNavigate("NEXT", date);
															}}
														/>
													</ButtonGroup>
												</Wrapper>
											);
										},
									}}
								/>
							</div>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<Modal
					open={this.state.showCreateEventModal}
					onClose={this.closeEventModal.bind(this)}
					title={this.state.editingEvent ? "Ändra obekväm arbetstid" : "Lägg till obekväm arbetstid"}
					secondaryActions={modalActions}
					primaryAction={{
						content: this.state.editingEvent ? "Ändra" : "Lägg till",
						loading: this.state.saving,
						onAction: this.saveObTime.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<Select
								label="Typ av ob"
								value={this.state.ob_type}
								options={store.getState().account.ob_types.map((type) => ({ label: type.title, value: type.id + "" }))}
								onChange={(value) => {
									this.setState({ ob_type: value });
								}}
							/>
						</FormLayout>
					</Modal.Section>
					<Modal.Section>
						<div>
							{this.state.weekdays.map((weekday, key) => (
								<Badge key={key}>{weekday}</Badge>
							))}
						</div>
						<FormLayout>
							<Checkbox
								checked={this.state.whole_day}
								onChange={(val) => {
									this.setState({ whole_day: !this.state.whole_day });
								}}
								label="Hela dagen"
							/>
							{!this.state.whole_day ? (
								<Stack distribution="fillEvenly">
									<TimePicker
										label="Från klockan"
										placeholder="Välj klockslag"
										value={this.state.from_time}
										onChange={(value) => {
											this.setState({ from_time: value });
										}}
										editing={this.state.editingEvent}
									/>
									<TimePicker
										label="Till klockan"
										placeholder="Välj klockslag"
										value={this.state.to_time}
										onChange={(value) => {
											this.setState({ to_time: value });
										}}
										editing={this.state.editingEvent}
									/>
								</Stack>
							) : null}
							<ChoiceList
								choices={[
									{ label: "Upprepa inte", value: "single" },
									{ label: "Upprepa varje vecka", value: "weekly" },
									{ label: "Upprepa varannan vecka", value: "biweekly" },
								]}
								selected={this.state.recurring}
								onChange={(value) => {
									this.setState({ recurring: value[0] });
								}}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showRemoveEventModal}
					onClose={this.closeRemoveEventModal.bind(this)}
					title="Ta bort arbetstid"
					secondaryActions={[
						{
							content: "Close",
							onAction: this.closeRemoveEventModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeObTime.bind(this),
					}}
				>
					<Modal.Section>
						{this.state.editingEvent && this.state.editingEvent.recurring ? (
							<ChoiceList
								title=""
								titleHidden
								choices={[
									{ label: "Ta bort alla", value: "all" },
									{ label: "Ta endast bort denna", value: "this" },
									{ label: "Ta bort denna och kommande dagar", value: "future" },
								]}
								selected={this.state.selectedRemoveOption}
								onChange={(value) => {
									this.setState({ selectedRemoveOption: value });
								}}
							/>
						) : (
							"Är du säker på att du vill ta bort denna arbetstid?"
						)}
					</Modal.Section>
				</Modal>
			</Page>
		);
	}
}

export default ObSettings;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Popover, Tooltip } from "@shopify/polaris";
import React, { useState } from "react";
import Modal from "../modal";

const ActionButton = ({ item }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const confirm = {
		showButtons: true,
		sectioned: true,
		...(item.confirmation || item.confirm || {}),
	};

	const handleAction = async () => {
		setIsLoading(true);
		try {
			await item.onAction?.();
		} catch (error) {
			console.error("error:", error);
		}
		setIsLoading(false);
		setOpen(false);
	};

	const handlePreAction = () => {
		if (item.confirmation || item.confirm) {
			setOpen(true);
		} else {
			handleAction();
		}
	};

	const button = (
		<Button
			removeUnderline
			plain
			key={item.content}
			icon={item.icon}
			onClick={handlePreAction}
			loading={item.loading || isLoading}
			disabled={item.disabled}
			destructive={item.destructive}
		>
			{item.content}
		</Button>
	);

	const activator = item.tooltip ? <Tooltip content={item.tooltip}>{button}</Tooltip> : button;

	if (!item.confirmation && !item.confirm) return activator;
	const text = confirm?.content === undefined ? `${item.content}?` : confirm?.content || confirm?.text || confirm?.message;

	return (
		<>
			{confirm.type === "popup" ? (
				<Popover active={open} onClose={() => setOpen(false)} activator={activator} fixed sectioned={confirm.sectioned}>
					{text}
					{confirm.showButtons && (
						<div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
							<Button onClick={() => setOpen(false)}>{confirm?.cancel || "Avbryt"}</Button>

							<Button
								destructive={item.destructive}
								primary={item.primary || !item.destructive}
								onClick={handleAction}
								loading={item.loading || isLoading}
							>
								{confirm?.confirm || item.content}
							</Button>
						</div>
					)}
				</Popover>
			) : (
				<>
					{activator}
					<Modal
						open={open}
						onClose={() => setOpen(false)}
						title={confirm?.title || item.content}
						primaryAction={
							!confirm.showButtons
								? undefined
								: {
										...item,
										content: confirm?.confirm || item.content,
										onAction: handleAction,
										loading: item.loading || isLoading,
								  }
						}
						secondaryActions={[
							{
								content: confirm?.cancel || "Avbryt",
								onAction: () => setOpen(false),
							},
						]}
						sectioned={confirm.sectioned}
					>
						{text}
					</Modal>
				</>
			)}
		</>
	);
};

export default ActionButton;

export const convertBasisToFortnoxInvoiceRows = (type, items: any[], options: any): FortnoxInvoiceRowType[] => {
	switch (type) {
		case "time_reports": {
			let rows = items
				?.map((item: any) => {
					const hours = item.total_minutes / 60;
					return {
						DeliveredQuantity: String(hours),
						Description: item.title,
						Unit: "h",
						// VAT: item.vat, // item.hojdtillagg // project.vat??

						Price: item.amount_to_invoice / (item.total_minutes / 60), // Use item.to_bill when added in backend

						time_reports: [item],
					};
				})
				?.filter(Boolean);

			if (options?.group) {
				const { DeliveredQuantity, Price } = rows.reduce(
					(acc, row) => {
						acc.DeliveredQuantity += parseFloat(row.DeliveredQuantity);
						acc.Price += row.Price * parseFloat(row.DeliveredQuantity);

						return acc;
					},
					{
						DeliveredQuantity: 0,
						Price: 0,
					} as any
				);

				const groupedRow = {
					DeliveredQuantity,
					Price: Price / DeliveredQuantity,
					Description: "Arbetad tid",
					Unit: "h",
					time_reports: items,
				} as any;

				rows = [groupedRow];
			}

			return rows;
		}
		case "supplier_invoice_rows": {
			if (options?.displayRows) {
				return items.map((item) => {
					return {
						Description: item.description,
						Unit: item.unit,
						DeliveredQuantity: item.quantity,
						Price: item.amount_to_invoice,
						supplier_invoice_rows: [item],
					};
				});
			}

			return items.map((item) => {
				const rows = item.supplier_invoice_rows;
				const total = rows.reduce((acc, row) => {
					acc += row.amount_to_invoice;
					return acc;
				}, 0);

				return {
					Description: "Leverantörsfaktura",
					Unit: "st",
					DeliveredQuantity: "1",
					Price: total,
					supplier_invoice_rows: rows,
				};
			});
		}
		case "expense_reports": {
			let rows = items.map((item) => {
				return {
					Description: `${item.expense?.title} (${item.expense?.sku})`,
					Unit: item.unit,
					DeliveredQuantity: item.quantity,
					Price: item.amount_to_invoice || item.cost, // Use item.to_bill when added in backend
					expense_reports: [item],
				};
			});

			if (options?.group) {
				const groupedRow = rows.reduce(
					(acc, row) => {
						acc.DeliveredQuantity += parseFloat(row.DeliveredQuantity);
						acc.Price += row.Price;

						return acc;
					},
					{
						DeliveredQuantity: 0,
						Price: 0,
					} as any
				);

				groupedRow.Description = "Utgifter";
				groupedRow.Unit = "";

				groupedRow.expense_reports = items;
				rows = [groupedRow];
			}

			return rows;
		}
		case "quote_rows": {
			if (options?.displayRows) {
				return items.map((item) => {
					return {
						Description: item.description,
						Unit: item.unit,
						DeliveredQuantity: item.count,
						Price: item.subtotal,
						quote_rows: [item],
					};
				});
			}

			return items.map((item) => {
				const rows = item.rows;
				const total = rows.reduce((acc, row) => {
					acc += row.subtotal;
					return acc;
				}, 0);

				return {
					Description: `offert "${item.title}"`,
					Unit: "st",
					DeliveredQuantity: "1",
					Price: total,
					quote_rows: rows,
				};
			});
		}
		default: {
			return [] as any;
		}
	}
};

// AccountNumber?: number;
// ArticleNumber: string;
// ContributionPercent?: string;
// ContributionValue?: string;
// CostCenter?: string;
// DeliveredQuantity?: string;
// Description: string;
// Discount?: number;
// DiscountType?: string;
// HouseWork?: boolean;
// HouseWorkHoursToReport?: number;
// HouseWorkType?: string;
// Price: number;
// Project?: string;
// RowId?: number;
// StockPointCode?: string;
// Total: number;
// Unit: string;
// VAT?: number;

// sum?: number;

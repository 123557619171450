/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Title = styled.b`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const Wrapper = styled.div`
	box-shadow: var(--p-shadow-md);
	border-radius: var(--p-border-radius-2);
`;

export const StyledCard = styled.div`
	box-shadow: var(--p-shadow-md);
	border-radius: var(--p-border-radius-2);
`;

import React, { Component } from "react";
import { Modal, Button } from "@shopify/polaris";
import { toastr } from "./toastr";
import API from "../API";

class AttestModal extends Component {
	constructor(props) {
		super(props);

		this.state = { confirm: false };
	}

	componentWillReceiveProps(props) {
		if (this.props.open != props.open) {
			this.setState({ confirm: false });
		}
	}

	attestReports() {
		this.setState({ saving: true });
		var data = { from: this.props.from, to: this.props.to };
		if (this.props.groups.length > 0) {
			data.group_ids = this.props.groups.map((group) => group.id);
		} else if (this.props.users.length > 0) {
			data.user_ids = this.props.users.map((user) => user.id);
		}

		API.post("/api/time_reports/attest.json", data, { params: {} })
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Timmarna är nu attesterade");
				this.props.onClose();
			})
			.catch((error) => {
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title="Attestera tider"
				secondaryActions={[
					{
						content: "Close",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.confirm ? "Bekräfta" : "Attestera",
					loading: this.state.saving,
					onClick: this.state.confirm
						? this.attestReports.bind(this)
						: () => {
								this.setState({ confirm: true });
						  },
				}}
			>
				<Modal.Section>
					{!this.state.confirm ? (
						<div style={{ paddingTop: 50, paddingBottom: 50 }}>
							<p>Här attesterar ni timmarna, klicka på visa rapport om du önskar se rapporterna alternativt klicka på attestera</p>
							<br />
							<Button
								fullWidth
								onClick={() => {
									window.open(this.props.url);
								}}
							>
								Visa rapport
							</Button>
						</div>
					) : null}

					{this.state.confirm ? <div>Är du säker på att du vill attestera dessa tider?</div> : null}
				</Modal.Section>
			</Modal>
		);
	}
}

export default AttestModal;

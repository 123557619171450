import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Tag, ChoiceList, FormLayout, Stack, Checkbox } from "@shopify/polaris";
import { store } from "../../store";
import SearchField from "../../components/search_field.js";
import Map from "../../components/map.js";
import ResourcePicker from "../../components/resource_picker.js";
import MapWithKml from "../Dashboard/MapWithKml";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import API from "../../API";

class WorkOrderCreate extends Component {
	constructor(props) {
		super(props);
		const project = props.location?.state?.project || null;
		this.state = {
			work_types: store.getState().work_types,
			saving: false,
			form: Object.assign(
				{
					ref_id: project?.id || null,
					title: (props.project && props.project.title) || "",
					work_types: store.getState().work_types.length == 1 ? [store.getState().work_types[0].id + ""] : [],
					color: "#ff0000",
					personell_ledger: !!(store.getState().account.ledger_linked && store.getState().account.auto_activate_checkin),
					estimated_hours: "0",
					personell_ledger_id: "",
					reference: "",
					description: (props.project && props.project.description) || "",
					checkin: !!store.getState().account.auto_activate_checkin,
					checkin_screen: false,
					checkin_require_gps: false,
					checkout_require_gps: false,
					project:
						props.location && props.location.state && props.location.state.project && props.location.state.project.project
							? props.location.state.project.project
							: props.location && props.location.state && props.location.state.project
							? props.location.state.project
							: null,
					expenses: [],
					users: [],
					areas:
						props.location && props.location.state && props.location.state.project && props.location.state.project.areas
							? props.location.state.project.areas
							: null,
					groups: [],
					ata: false,
					machine: false,
					shared_expenses: false,
					underentreprenorer: [],
					tags: [],
					customer_id: null,
					customer_name: null,
					status: "active",
					fixed_price: "",
					hourly_rate: "",
					hourly_ob_rate: "",
					address: this.getAddress(props),
					latitude: this.getLatitude(props),
					longitude: this.getLongitude(props),
					public: true,
				},
				props.form || {},
				{
					title: (props.form && props.form.title) || (props.project && props.project.title) || "",
					description: (props.form && props.form.description) || (props.project && props.project.description) || "",
				}
			),
		};
	}

	getAddress(props) {
		const locationState = props && props.location && props.location.state;
		if (props) {
			if (props.address) return props.address;
			if (props.project) return props.project.address;
		}
		if (locationState) {
			if (locationState.address) return locationState.address;
			if (locationState.project) return locationState.project.address;
		}
		return null;
	}

	getLatitude(props) {
		const locationState = props && props.location && props.location.state;
		if (props) {
			if (props.latitude) return props.latitude;
			if (props.project) return props.project.latitude;
		}
		if (locationState) {
			if (locationState.latitude) return locationState.latitude;
			if (locationState.project) return locationState.project.latitude;
		}
		return null;
	}

	getLongitude(props) {
		const locationState = props && props.location && props.location.state;
		if (props) {
			if (props.longitude) return props.longitude;
			if (props.project) return props.project.longitude;
		}
		if (locationState) {
			if (locationState.longitude) return locationState.longitude;
			if (locationState.project) return locationState.project.longitude;
		}
		return null;
	}

	componentWillReceiveProps(props) {
		if (props.form) {
			this.state.form = Object.assign(this.state.form, props.form);
			this.setState({ form: this.state.form });
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;

		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	saveForm() {
		const project = Object.assign({}, this.state.form.project);
		delete project.work_orders;
		this.state.form.project = project;
		this.setState({ saving: true });
		API.post("/api/work_orders.json", JSON.parse(JSON.stringify(this.state.form)))
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				if (result.data.work_order) {
					toastr.success("Arbetsorder skapades");
					this.props.history.goBack();
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form, latitude: this.state.form.latitude, longitude: this.state.form.longitude }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
		console.debug("this.state.latitude:", this.state.latitude);
		console.debug("this.state.longitude:", this.state.longitude);
	}

	addTag(item) {
		if (this.state.form.tags && !this.state.form.tags.find((i) => i.title === item.title)) {
			this.state.form.tags.push(item);
			this.setState({ form: this.state.form }, () => {
				if (this.props.onChange) this.props.onChange(this.state.form);
			});
		}
	}

	removeTag(index) {
		this.state.form.tags.splice(index, 1);
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	render() {
		const project =
			(this.props.location && this.props.location.state && this.props.location.state.project && this.props.location.state.project) ||
			this.props.project;

		const backAction = (() => {
			// Dont show if used on /projects/new
			if (this.props.project) {
				return undefined;
			}
			if (project) return { content: `Projekt (${project.label})`, url: `/admin/projects/${project.id}` };
			return { content: "Arbetsordrar", url: "/admin/projects" };
		})();
		return (
			<Page
				fullWidth
				// Dont show if used on /projects/new
				title={this.props.project ? "" : "Ny arbetsorder"}
				backAction={backAction}
				primaryAction={
					// Dont show if used on /projects/new
					store.getState().user.read_only || this.props.project
						? null
						: { content: "Skapa arbetsorder", loading: this.state.saving, onAction: this.saveForm.bind(this) }
				}
			>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<FormLayout>
								<ResourcePicker
									placeholder="Sök projekt"
									caption="Välj projekt"
									resource="projects"
									resourceName={{
										singular: "projekt",
										plural: "projekt",
									}}
									item={this.props.project || this.state.form.project}
									onChange={this.updateForm.bind(this, "project")}
									label_handle="label"
									disabled={this.props.project}
								/>
								<TextField
									label="Arbetsordernummer"
									disabled
									placeholder="Genereras automatiskt"
									onChange={this.updateForm.bind(this, "reference")}
								/>
								<TextField label="Benämning" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
								{/* <TextField label="Benämning *" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
								<Select
									label="Status"
									options={[
										{ label: "Vilande", value: "sleep" },
										{ label: "Kommande", value: "pending" },
										{ label: "Aktiv", value: "active" },
										{ label: "Färdig", value: "done" },
										{ label: "Fakturerad", value: "invoiced" },
									]}
									onChange={this.updateForm.bind(this, "status")}
									value={this.state.form.status}
								/> */}
								<TextField multiline label="Beskrivning" value={this.state.form.description} onChange={this.updateForm.bind(this, "description")} />
							</FormLayout>
						</Card>
						{this.state.form.areas ? (
							<Card>
								<div style={{ height: 300 }}>
									<MapWithKml
										myLat={this.state.form.latitude}
										myLng={this.state.form.longitude}
										workOrder={this.props.location.state.project}
										showMarker
										// onMarkerClick={this.onMarkerClick.bind(this)}
										height="100%"
										zIndexValue="1"
									/>
								</div>
							</Card>
						) : null}
					</Layout.Section>
					<Layout.Section secondary>
						<Card title="Adress *" sectioned>
							<SearchField
								resource="google_address"
								resourceName={{
									singular: "adress",
									plural: "adresser",
								}}
								onSelect={this.selectAddress.bind(this)}
							/>
							{this.state.form.address ? (
								<div>
									<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} />
									<div>{this.state.form.address}</div>
								</div>
							) : (
								<small>Ange en adress för att få arbetsordern att visas på kartan</small>
							)}
						</Card>

						<Card title="Attribut" sectioned>
							<FormLayout>
								<Checkbox label="ÄTA" checked={this.state.form.ata} onChange={this.updateForm.bind(this, "ata")} />
							</FormLayout>
						</Card>

						<Card title="Taggar" subdued sectioned>
							<SearchField
								resource="work_orders/tags"
								resourceName={{
									singular: "tagg",
									plural: "taggar",
								}}
								onSelect={this.addTag.bind(this)}
								tags
							/>
							{this.state.form.tags.length ? (
								<Stack>
									{this.state.form.tags.map((tag, index) => (
										<Tag key={tag.id || index} onRemove={this.removeTag.bind(this, index)}>
											{tag.title}
										</Tag>
									))}
								</Stack>
							) : null}
						</Card>

						{store.getState().account.has_personell_ledger && this.state.form.status != "quote" ? (
							<Card title="Personalliggare" sectioned>
								<FormLayout>
									{!store.getState().account.ledger_linked ? (
										<Checkbox
											label="Arbetsordern har personalliggare"
											checked={this.state.form.personell_ledger}
											onChange={this.updateForm.bind(this, "personell_ledger")}
										/>
									) : this.state.form.checkin ? null : (
										<div>Incheckning är avaktiverad</div>
									)}
									{this.state.form.personell_ledger ? (
										<TextField
											label="Skatteverkets identifikationsnr"
											value={this.state.form.personell_ledger_id}
											onChange={this.updateForm.bind(this, "personell_ledger_id")}
										/>
									) : null}
								</FormLayout>
							</Card>
						) : null}
						{/* <Card title="Tidrapportering" sectioned>
                <FormLayout>
                  <Checkbox
                    label={((!store.getState().account.ledger_linked) ? 'Stämpelklocka':'Stämpelklocka & Personalliggare')}
                    checked={this.state.form.checkin}
                    onChange={(value) => {this.updateForm('checkin', value);if (store.getState().account.ledger_linked) {this.updateForm('personell_ledger', value);}}}
                  />
                  {((this.state.form.checkin) ? <Checkbox
                    label="Incheck.skärm"
                    checked={this.state.form.checkin_screen}
                    onChange={this.updateForm.bind(this, 'checkin_screen')}
                  />:null)}
                  {((this.state.form.checkin) ? <Checkbox
                    label="Kontrollera GPS för incheckning"
                    checked={this.state.form.checkin_require_gps}
                    onChange={this.updateForm.bind(this, 'checkin_require_gps')}
                  />:null)}
                  {((this.state.form.checkin) ? <Checkbox
                    label="Kontrollera GPS för utcheckning"
                    checked={this.state.form.checkout_require_gps}
                    onChange={this.updateForm.bind(this, 'checkout_require_gps')}
                  />:null)}
                </FormLayout>
              </Card> */}

						{this.state.work_types.length > 1 && (
							<Card title="Arbetstyper *" subdued sectioned>
								<ChoiceList
									title=""
									titleHidden
									allowMultiple
									choices={this.state.work_types.map((typ) => ({ value: typ.id + "", label: typ.title + " (" + typ.code + ")" }))}
									selected={this.state.form.work_types}
									onChange={this.updateForm.bind(this, "work_types")}
								/>
							</Card>
						)}
					</Layout.Section>
				</Layout>
				{!this.props.project && (
					<PageActions
						primaryAction={
							store.getState().user.read_only
								? null
								: {
										content: "Skapa arbetsorder",
										loading: this.state.saving,
										onAction: this.saveForm.bind(this),
								  }
						}
					/>
				)}
			</Page>
		);
	}
}

export default WorkOrderCreate;

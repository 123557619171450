import { Button, Icon, LegacyCard, Spinner, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CircleAlertMajor } from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import API from "../../../API";
import type { Stats, UnattestedStats } from "./types";
import { toastr } from "../../../components/toastr";
import Colors from "../../../Colors";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import waveImage from "./wave.png";

type StatsBoxesProps = {
	history: any;
};

const StatsBoxes = ({ history }: StatsBoxesProps) => {
	const [loadingStats, setLoadingStats] = useState<boolean>(false);
	const [loadingDeviatingStats, setLoadingDeviatingStats] = useState<boolean>(false);
	const [stats, setStats] = useState<Stats | null>(null);
	const [loadingUnattestedStats, setLoadingUnattestedStats] = useState<boolean>(false);
	const [unattestedStats, setUnattestedStats] = useState<UnattestedStats | null>(null);
	const [deviatingStats, setDeviatingStats] = useState<any | null>(null);

	const handleFetchProjectStats = async () => {
		setLoadingStats(true);
		API.get("/api/projects/stats.json")
			.then((res) => {
				if (res.data.error) {
					console.error("res.data.error:", res.data.error);
					toastr.error(res.data.error);
				}

				setStats(res.data.stats);
			})
			.catch((err) => {
				console.error("err:", err);
				toastr.error(err);
			})
			.finally(() => {
				setLoadingStats(false);
			});
	};

	const handleFetchUnattestedStats = async () => {
		setLoadingUnattestedStats(true);
		API.get("/api/time_reports/unattested_stats.json")
			.then((res) => {
				if (res.data.error) {
					console.error("res.data.error:", res.data.error);
					toastr.error(res.data.error);
				}

				setUnattestedStats(res.data.stats);
			})
			.catch((err) => {
				console.error("err:", err);
				toastr.error(err);
			})
			.finally(() => {
				setLoadingUnattestedStats(false);
			});
	};

	const handleFetchDevatingRegistrations = async () => {
		setLoadingDeviatingStats(true);
		API.get("/api/time_reports_deviant.json", {
			params: {
				from: moment().subtract(2, "month").format("YYYY-MM-DD"),
				to: moment().format("YYYY-MM-DD"),
			},
		})
			.then((res) => {
				if (res.data.error) {
					console.error("res.data.error:", res.data.error);
					toastr.error(res.data.error);
				}

				setDeviatingStats({ count: res.data.events?.length });
			})
			.catch((err) => {
				console.error("err:", err);
			})
			.finally(() => {
				setLoadingDeviatingStats(false);
			});
	};

	const handleFetch = useCallback(() => {
		handleFetchProjectStats();
		handleFetchUnattestedStats();
		handleFetchDevatingRegistrations();
	}, []);

	useEffect(() => {
		handleFetch();
		return () => {};
	}, [handleFetch]);

	return (
		<Wrapper>
			<LegacyCard sectioned>
				<InnerWrapper>
					<Box>
						<SmallboxTitle>
							Det finns {loadingUnattestedStats ? <Spinner size="small" /> : <b>{unattestedStats?.hours}</b>} oattesterade timmar
						</SmallboxTitle>
						<Button
							primary
							onClick={() => {
								history.push(
									`/admin/time_reports?${new URLSearchParams({
										offset: "0",
										attest: "0",
										q: "",
										sort: "PROJECT_DESC",
									})}`
									// {
									// 	tab: 1,
									// }
								);
							}}
						>
							Attestera
						</Button>
					</Box>
					<Box>
						<SmallboxTitle>
							Det finns {loadingDeviatingStats ? <Spinner size="small" /> : <b>{deviatingStats?.count}</b>} avvikande registreringar{" "}
						</SmallboxTitle>
						<HelpTextIcon>
							<Tooltip content="Senaste två månaderna">
								<Icon source={CircleAlertMajor} />
							</Tooltip>
						</HelpTextIcon>
						<Button
							primary
							onClick={() => {
								history.push(
									{
										pathname: `/admin/deviating_registrations`,
										state: {
											from: moment().subtract(2, "month").format("YYYY-MM-DD"),
											to: moment().format("YYYY-MM-DD"),
										},
									}
									// {
									// 	tab: 1,
									// }
								);
							}}
						>
							Visa lista
						</Button>
					</Box>
					<Box>
						<SmallboxTitle>Kommande projekt</SmallboxTitle>
						<SmallboxNumber>{loadingStats ? <Spinner size="small" /> : stats?.up_coming || "-"}</SmallboxNumber>
						<SmallboxBackground src={waveImage} alt="" />
						{/* <HelpTextIcon>
							<Tooltip content="Här listas det antal projekt som har status kommande">
								<Icon source={QuestionMarkMajor} color="base" />
							</Tooltip>
						</HelpTextIcon> */}
					</Box>
					<Box>
						<SmallboxTitle>
							Aktiva projekt
							{!!stats?.active_overdue && (
								<Tooltip content={`${stats?.active_overdue}st projekt där slutdatum är passerat`}>
									<AlertWrapper
										to={`/admin/projects?${new URLSearchParams({
											status: "active",
											end_at_period_max: moment().format("YYYY-MM-DD"),
										})}`}
									>
										<Icon source={CircleAlertMajor} color="critical" />
									</AlertWrapper>
								</Tooltip>
							)}
						</SmallboxTitle>
						<SmallboxNumber>{loadingStats ? <Spinner size="small" /> : stats?.active || "-"}</SmallboxNumber>
						<SmallboxBackground src={waveImage} alt="" style={{ filter: "hue-rotate(260deg)" }} />
						{/* <HelpTextIcon>
							<Tooltip content="Här listas antalet projekt som har status aktiv">
								<Icon source={QuestionMarkMajor} color="base" />
							</Tooltip>
						</HelpTextIcon> */}
					</Box>
					<Box>
						{/* <HelpTextIcon>
							<Tooltip content="Senaste 2 månaderna">
								<Icon source={CircleInformationMajor} color="base" />
							</Tooltip>
						</HelpTextIcon> */}
						<SmallboxTitle>Avslutade projekt</SmallboxTitle>
						<SmallboxNumber>{loadingStats ? <Spinner size="small" /> : stats?.finished || "-"}</SmallboxNumber>
						<SmallboxBackground src={waveImage} alt="" style={{ filter: "hue-rotate(120deg)" }} />
						{/* <SmallboxBackground /> */}
					</Box>
				</InnerWrapper>
			</LegacyCard>

			{/* <TimeRangeModal
				attest
				open={timeRangeRangeModalIsOpen}
				onClose={handleCloseTimeRangeRangeModal}
				// from={fromDate}
				// to={toDate}

				// onChange={}
				usergroup
			/> */}
		</Wrapper>
	);
};
export default StatsBoxes;

const Wrapper = styled.div.attrs({ className: "stats-boxes" })`
	.Polaris-LegacyCard {
		--p-color-bg: var(--primary);
	}
`;

const Box = styled.div`
	position: relative;
	border-radius: var(--p-border-radius-2);
	background-color: ${Colors.secondary};
	box-shadow: var(--p-shadow-md);
	outline: var(--p-border-width-1) solid transparent;
	padding: var(--p-space-5);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.Polaris-Button {
		margin: auto;
		.Polaris-Button__Content {
			font-weight: bold;
		}
	}
`;

const InnerWrapper = styled.div`
	display: grid;
	grid-template-rows: auto auto; /* Two rows with automatic height */
	grid-template-columns: repeat(6, 1fr); /* Five columns with equal width */
	gap: 1rem;
	height: 100%;

	${Box}:nth-child(-n + 2) {
		grid-column: span 3; /* First two items span across two columns */
	}

	${Box}:nth-child(n + 3):nth-child(-n + 5) {
		grid-column: span 2; /* Last three items span across one column each */
	}
`;

const SmallboxTitle = styled.p`
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	position: relative;
	z-index: 2;
`;

const SmallboxNumber = styled.p`
	font-weight: 700;
	font-size: 30px;
	line-height: 35px;
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;

const SmallboxBackground = styled.img`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 45%;
	z-index: 0;
`;

const AlertWrapper = styled(Link)`
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(125%);
	z-index: 2;
	background: transparent;
	outline-color: transparent;
	border: none;
	cursor: pointer;

	.Polaris-Icon {
		height: 1rem;
		width: 1rem;
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HelpTextIcon = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
	padding: 0.5rem;

	svg {
		fill: var(--color-primary);
	}
`;

/* eslint-disable camelcase */
import { FormLayout, Modal, Select } from "@shopify/polaris";
import React, { Component } from "react";
import Money from "../../components/Money";
import TextField from "../../components/TextField";
import { store } from "../../store";
import ArticleSelector from "./ArticleSelector";

export const calculateSubtotal = (row) => {
	if (!row || !row.count || !row.a_price) return 0;
	return row.count * row.a_price * ((100 - (row.discount || 0)) / 100);
};
class QuoteRowEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			row: (props.data && props.data.row) || {},
			index: props.data && Number.isInteger(props.data.index) ? props.data.index : null,
		};
		this.wrapperRef = React.createRef();
	}

	componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			this.setState({ row: (props.data && props.data.row) || {}, index: props.data && Number.isInteger(props.data.index) ? props.data.index : null });
			setTimeout(() => {
				this.wrapperRef.current?.querySelector("input")?.focus();
			}, 0);
		}
	}

	onAutoCompleteSelect(value) {
		this.state.row.article = value.sku;
		this.state.row.a_price = value.price;
		this.state.row.unit = value.unit;
		this.state.row.description = value.title;
		this.setState({ row: this.state.row });
	}

	onChange(field, v) {
		this.state.row[field] = v;
		this.setState({ row: this.state.row });
		// this.props.addRow(field, index, v);
	}

	onKeyDown(e) {
		if (e.key === "Enter") {
			const ownTd = e.target.closest(".Polaris-FormLayout__Item");
			const nextSibling = ownTd && ownTd.nextSibling;
			const newElement = nextSibling && nextSibling.querySelector("input, textarea, select");
			if (newElement) {
				if (newElement.tagName === "TEXTAREA") {
					e.preventDefault();
					e.stopPropagation();
				}
				newElement.focus();
			} else {
				const ownTr = e.target.closest(".Polaris-FormLayout__Item");
				const nextSibling = ownTr && ownTr.nextSibling;
				const newElement = nextSibling && nextSibling.querySelector("input, textarea");
				if (newElement) {
					if (newElement.tagName === "TEXTAREA") {
						e.preventDefault();
						e.stopPropagation();
					}
					newElement.focus();
				} else {
					if (Object.keys(this.state.row).length) {
						this.props.onOnSaveRow(this.state.row, this.state.index);
						this.targetFirstInput(e);
					}
				}
			}
		}
	}

	targetFirstInput(e) {
		this.wrapperRef.current?.querySelector("input")?.focus();
	}

	render() {
		const { article, description, unit, count, a_price, rotrut, discount, comment } = this.state.row || {};
		const row = this.state.row || {};
		const index = this.state.index;

		const isNotNewRow = this.props.data?.index !== undefined;
		return (
			<Modal
				title={isNotNewRow ? "Redigera artikel" : "Skapa ny rad"}
				open={this.props.open}
				onClose={this.props.onClose}
				footer={
					<>
						<b>Kostnad: </b>
						<Money style={{ whiteSpace: "nowrap", flex: 1 }} amount={(row.subtotal || calculateSubtotal(row)) * 100} currency="SEK" />
					</>
				}
				primaryAction={
					row &&
					typeof row === "object" &&
					Object.keys(row).some((i) => i) &&
					!this.props.disabled && {
						content: isNotNewRow ? "Spara" : "Lägg till rad",
						onAction: () => {
							this.props.onOnSaveRow(row, index);
						},
					}
				}
				secondaryActions={[
					{
						content: "Avbryt",
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<div onKeyDown={this.onKeyDown.bind(this)} ref={this.wrapperRef}>
						<FormLayout>
							<ArticleSelector
								value={article}
								fortnox={store.getState().account.fortnox_connection}
								onChange={this.onChange.bind(this, "article")}
								onSelect={(v, t) => {
									this.onAutoCompleteSelect(v);
									setTimeout(() => {
										const row = t.closest(".Polaris-FormLayout__Item");
										// const next = row?.nextSibling && row?.nextSibling.querySelector("input:not([value]), input[value='']");
										const next = row?.nextSibling && row?.nextSibling.querySelector("input, textarea, select");
										if (next) next.focus();
									}, 0);
								}}
								disabled={this.props.disabled}
							/>
							{store.getState().account.fortnox_connection ? (
								<ArticleSelector
									label="Beskrivning"
									placeholder="Beskrivning"
									value={description}
									fortnox={store.getState().account.fortnox_connection}
									description
									onChange={this.onChange.bind(this, "description")}
									onSelect={(v) => {
										this.onAutoCompleteSelect(v);
										setTimeout(() => {
											const row = document.querySelector(`#row-${index}`);
											const next = row?.nextSibling && row?.nextSibling.querySelector("input, textarea, select");
											if (next) next.focus();
										}, 0);
									}}
									disabled={this.props.disabled}
								/>
							) : (
								<TextField
									label="Beskrivning"
									disabled={this.props.disabled}
									value={description}
									onChange={this.onChange.bind(this, "description")}
									multiline
								/>
							)}
							<TextField label="Antal" disabled={this.props.disabled} value={count} type="number" onChange={this.onChange.bind(this, "count")} />

							<TextField label="Enhet" disabled={this.props.disabled} value={unit} onChange={this.onChange.bind(this, "unit")} />

							<TextField
								label="À-pris"
								disabled={this.props.disabled}
								value={a_price}
								type="number"
								onChange={this.onChange.bind(this, "a_price")}
								suffix="kr"
							/>

							<TextField
								label="Rabatt"
								disabled={this.props.disabled}
								type="number"
								suffix="%"
								value={discount}
								onChange={this.onChange.bind(this, "discount")}
							/>

							<Select
								label="Rot/Rut"
								disabled={this.props.disabled}
								options={[
									{
										value: "",
										label: "Inget",
									},
									{
										value: "rot",
										label: "Rot",
									},
									{
										value: "rut",
										label: "Rut",
									},
								]}
								value={rotrut}
								onChange={this.onChange.bind(this, "rotrut")}
							/>

							<TextField
								label="Kommentar"
								placeholder="Kommentar"
								fullWidth
								disabled={this.props.disabled}
								value={comment}
								onChange={this.onChange.bind(this, "comment")}
							/>
						</FormLayout>
					</div>
				</Modal.Section>
			</Modal>
		);
	}
}
export default QuoteRowEditor;

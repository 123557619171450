import { Tabs } from "@shopify/polaris";
import React from "react";
import Banner from "src/js/components/banner";
import { Wrapper } from "../styles";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import TextField from "src/js/components/TextField";
import { Link } from "react-router-dom";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	offer: Partial<FortnoxOfferType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
	history: any;
};

const Header = ({ offer, tabs, onSelect, selected, history }: HeaderProps) => {
	const [projectTitle, setProjectTitle] = React.useState<string | null>(null);
	const handleCreateProjectFromOffer = async () => {
		try {
			const result = await API.post(`/api/fortnox/offers/${offer?.DocumentNumber}/create_project.json`, {
				title: projectTitle,
			});
			const project = result?.data?.project;
			toastr.success("Projekt skapat");
			history.push(`/admin/projects/${project?.id}`);
		} catch (error) {
			toastr.error(error);
		}
	};

	const allOfferUsersHaveAccepted =
		!!offer?.offer_users?.length && offer?.offer_users?.filter((ou) => ou.is_signatory)?.every((ou) => ou.accepted_at);

	const banner = (() => {
		if (offer?.Project) {
			return (
				<Banner status="success" title={"Projekt kopplat till offert"}>
					<p>{allOfferUsersHaveAccepted && "Denna offert är accepterad."}</p>
					<span>
						{`Projekt`}

						<Link to={`/admin/projects/${offer?.project?.id}`} style={{ fontWeight: 600, color: "var(--color-primary)" }}>
							{` ${offer?.project?.title} `}
						</Link>

						{`är kopplat till denna offert.`}
					</span>
					<span />
				</Banner>
			);
		}

		if (offer?.OrderReference) {
			return <Banner status="info" title={"Order skapad"}>{`Order ${offer?.OrderReference} har skapats i Fortnox.`}</Banner>;
		}
		if (offer?.Cancelled) {
			return (
				<Banner status="warning" title="Offert makulerad">
					Denna offert är makulerad
				</Banner>
			);
		}
		if (allOfferUsersHaveAccepted) {
			const atleastOneSignatory = offer?.offer_users?.filter((ou) => ou.is_signatory)?.length;
			const actions = [
				{
					content: "Skapa projekt",
					primary: true,
					onAction: handleCreateProjectFromOffer,
					confirmation: {
						title: "Skapa projekt ifrån offert",
						content: <TextField label="Projektets benämning" value={projectTitle} onChange={(value) => setProjectTitle(value)} />,
						confirm: "Skapa projekt",
					},
					// enabled: !offer?.Project,
				},
			].filter((a) => !("enabled" in a) || a.enabled);

			if (!atleastOneSignatory) {
				return (
					<Banner status="info" title="Offert skickad utan undertecknare" actions={actions}>
						Denna offert är skickad utan undertecknare
					</Banner>
				);
			}
			return (
				<Banner status="success" title="Offert accepterad" actions={actions}>
					Denna offert är accepterad
				</Banner>
			);
		}
		if (offer?.Sent) {
			return (
				<Banner status="info" title="Offert skickad">
					Denna offert är skickad
				</Banner>
			);
		} else {
			return null;
		}
	})();

	return (
		<Wrapper>
			{banner}
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</Wrapper>
	);
};
export default Header;

import { Component } from "react";
import { Badge } from "@shopify/polaris";
import Moment from "react-moment";

import Money from "../../components/Money.js";
import IndexTable from "../../components/IndexTable";
import { Title } from "../../components/IndexTable/styledComponents";

class SpecialTimesIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		var filters = [
			{
				key: "favorite",
				label: "Favorit",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
		];

		const columns = [
			{
				label: "Titel",
				handle: "TITLE",
				sortable: true,
				render: (item) => {
					return (
						<Title>
							{" "}
							<b>{item.title}</b>
							{item.favorite && <Badge status="success">Favorit</Badge>}
						</Title>
					);
				},
			},
			{
				label: "Pris",
				handle: "PRICE",
				sortable: true,
				render: (item) => {
					return (
						<Title>
							<Money amount={item.price * 100} currency="SEK" />/ {item.unit}
						</Title>
					);
				},
			},
			{
				label: "Inköpspris",
				handle: "PURCHASE_PRICE",
				sortable: true,
				render: (item) => {
					return <Money amount={item.purchase_price * 100} currency="SEK" />;
				},
			},
			{
				label: "Skapad",
				handle: "CREATED_AT",
				sortable: true,
				render: (item) => {
					return (
						<Moment locale="sv" format="YYYY-MM-DD">
							{item.created_at}
						</Moment>
					);
				},
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="ID_DESC"
					history={this.props.history}
					columns={columns}
					onClickRow={(item) => {
						this.props.history.push("/admin/special_times/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Specialtider"
					primaryAction={{
						content: "Ny specialtid",
						onAction: () => {
							this.props.history.push("/admin/special_times/new");
						},
					}}
					savedSearchHandle="special_times"
					resourceUrl="/api/expenses.json?time=1"
					resourceHandle="expenses"
					resourceName={{
						singular: "specialtid",
						plural: "specialtider",
					}}
					filters={filters}
				/>
			</div>
		);
	}
}

export default SpecialTimesIndex;

import { Page, Spinner } from "@shopify/polaris";
import React, { Component } from "react";
import { toastr } from "../../components/toastr";
import TranslationRow from "./TranslationRow";
import API from "../../API";

export default class EditLanguage extends Component {
	constructor(props) {
		super(props);
		const { match } = props;
		this.state = {
			form: {
				id: match.params.id,
			},
		};
	}

	componentDidMount() {
		this.fetchLanguage();
	}

	fetchLanguage() {
		this.setState({ loading: true });
		API.get(`/api/languages/${this.state.form.id}.json`)
			.then((response) => {
				this.setState({ form: response.data.language });
			})
			.catch((err) => {
				toastr.error(err);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	updateTranslation(key, value) {
		this.state.form.translations[key].translation = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put(`/api/languages/${this.state.form.id}.json`, this.state.form)
			.then((response) => {
				this.setState({ form: response.data.language });
			})
			.catch((err) => {
				console.error(err);
				toastr.error(err);
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Page
				backAction={{ content: "Tillbaka", url: "/admin/settings/languages" }}
				title={this.state.form.language || ""}
				primaryAction={{
					content: "Spara",
					onAction: this.saveForm.bind(this),
					disabled: this.state.form.verified,
					loading: this.state.loading || this.state.saving,
				}}
			>
				{this.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Spinner />
					</div>
				) : (
					Object.entries(this.state.form.translations || {}).map(([key, values], index) => {
						return (
							<TranslationRow
								key={key}
								string={values.string}
								translation={values.translation}
								onChange={this.updateTranslation.bind(this, key)}
								disabled={this.state.saving || this.state.form.verified}
								error={(values.string == values.translation && !this.state.form.verified) || (!values.translation && this.state.error)}
							/>
						);
					})
				)}
			</Page>
		);
	}
}

/* eslint-disable no-return-await */
import { Button, FormLayout, Layout, Page, PageActions, Tabs, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import API from "src/js/API";
import Card from "src/js/components/Card";
import FortnoxPDFView from "src/js/views/Fortnox/FortnoxPDFView";
import TextField from "src/js/components/TextField";
import { toastr } from "src/js/components/toastr";
import SearchField from "src/js/components/SearchField";
import Banner from "src/js/components/banner";
import { currencyFormatter } from "src/js/Utilities";
import { ChevronRightMinor, CirclePlusMajor, NoteMajor } from "@shopify/polaris-icons";
import styled, { css } from "styled-components";
import Skeleton1Col from "src/js/components/skeleton_1col";
import InvoiceRowsTable from "./ProjectInvoiceRowsTable";

type SupplierInvoice = {
	id?: number;
	supplier_invoice_rows: any[];
	project: any;
	surcharge: number;
	external_supplier_invoice: any;

	removed?: boolean;
};

const FortnoxSupplierinvoicesEdit = ({ id: propsId, history, match }) => {
	const id = propsId || match?.params?.id || history?.location?.state?.invoice?.GivenNumber;
	const [form, setForm] = useState<FortnoxSupplierInvoice>(null as unknown as FortnoxSupplierInvoice);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
	const [pdfs, setPdfs] = useState<any[] | null>(null);
	const [loadingPdfs, setLoadingPdfs] = useState(true);
	const [invoices, setInvoices] = useState<SupplierInvoice[]>([]);
	const [isPdfOpen, setIsPdfOpen] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);

			try {
				const responses = await Promise.allSettled([
					API.get(`/api/fortnox/supplier_invoices/${id}.json`),
					// API.get(`/api/fortnox/supplier_invoices/${id}/files.json`),
				]);

				responses.forEach((res) => {
					if (res.status === "rejected") {
						toastr.error(res.reason);
					}
				});

				if (responses[0]?.status === "fulfilled" && "value" in responses[0] && responses[0].value.status === 200) {
					setForm(responses[0]?.value.data.SupplierInvoice);
				}
			} catch (error) {
				console.error("error:", error);
				toastr.error(error);
			}

			setLoading(false);
		})();
	}, [id]);

	const fetchPdfs = useCallback(async () => {
		setLoadingPdfs(true);
		try {
			const res = await API.get(`/api/fortnox/supplier_invoices/${id}/files.json`);

			if (res.data.error) {
				toastr.error(res.data.error);
				return;
			}

			const fileIds = res.data?.SupplierInvoiceFileConnections?.map((c) => c.FileId);
			if (!fileIds?.length) {
				// toastr.error("Ingen pdf hittades");
				return;
			}

			const responses = await Promise.allSettled(fileIds.map((fileId) => API.get(`/api/fortnox/files/${fileId}.json`)));
			const pdfs = responses
				?.filter((res) => res?.status === "fulfilled" && (res as PromiseFulfilledResult<any>)?.value)
				?.map((res) => (res as PromiseFulfilledResult<any>)?.value?.data);

			setPdfs(pdfs);
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
		} finally {
			setLoadingPdfs(false);
		}
	}, [id]);

	useEffect(
		() => {
			if (!id) return;
			fetchPdfs();
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id]
	);

	const tabs = [
		{ id: "general", content: "Info", accessibilityLabel: "Info" },
		// { id: "pdf", content: "PDF", accessibilityLabel: "pdf", enabled: !!pdfs?.length },
	].filter((t) => !("enabled" in t) || t.enabled);

	const getTotal = useCallback(
		(invoiceArray = invoices) => {
			const allRows = invoiceArray.filter((s) => !s.removed).flatMap((invoice) => invoice.supplier_invoice_rows || []);
			const rowsTotal = allRows.reduce((acc, row) => acc + parseInt(row.amount), 0);

			return rowsTotal;
		},
		[invoices]
	);

	const getTotalRemainigAmount = useCallback(
		(invoiceArray = invoices) => {
			const rowsTotal = getTotal(invoiceArray);
			const total = (form?.Total ? parseInt(form?.Total) : 0) - parseInt(form?.VAT);

			return total - rowsTotal;
		},
		[form?.Total, form?.VAT, invoices, getTotal]
	);

	const getUpdateInvoiceRows = useCallback(
		(invoiceIndex) => (propsRows) => {
			setInvoices((c) => {
				const invoice = c[invoiceIndex] || { supplier_invoice_rows: [] };
				const rows = propsRows || invoice?.supplier_invoice_rows || [];
				invoice.supplier_invoice_rows = rows;

				const surcharge = invoice?.surcharge || invoice.project?.purcharge_price_surcharge || 0;
				const remainingAmount = getTotalRemainigAmount(c);

				if (invoiceIndex === null) {
					invoice.supplier_invoice_rows.push({ description: "", project: null, amount: remainingAmount, surcharge });
				}
				//  else if (total - rowsTotal > 0) {
				// 	invoice.supplier_invoice_rows.push({
				// 		description: "",
				// 		project: null,
				// 		amount: total - rowsTotal,
				// 		surcharge,
				// 	});
				// }

				invoice.supplier_invoice_rows = invoice.supplier_invoice_rows.filter((r) => Object.values(r)?.some((v) => v || typeof v === "number"));

				if (invoiceIndex === null) {
					return [...c, invoice].filter((r) => r.supplier_invoice_rows?.length);
				}

				c.splice(invoiceIndex, 1, invoice);
				return [...c];
			});
		},
		[getTotalRemainigAmount]
	);

	useEffect(() => {
		(async () => {
			if (!form?.GivenNumber) return;
			try {
				const params = {
					fortnox_invoice_id: form?.GivenNumber,
				};

				setIsLoadingInvoices(true);
				const res = await API.get(`/api/supplier_invoices.json`, {
					params,
				});

				if (res.data.error) {
					toastr.error(res.data.error);
					return;
				}

				// setProjectInvoiceRows(res.data.workorder_invoices);

				setInvoices(res.data.supplier_invoices || []);
			} catch (error) {
				console.error("error:", error);
				toastr.error(error);
			}
			setIsLoadingInvoices(false);
		})();
	}, [form?.GivenNumber]);

	const handleTabChange = (selectedTabIndex) => {
		setSelectedTabIndex(selectedTabIndex);
	};

	const getStatus = useCallback(() => {
		const rowsTotal = getTotal();
		const total = parseFloat(form?.Total) - parseFloat(form?.VAT);

		if (rowsTotal < total) return "pending";
		if (rowsTotal >= total) return "completed";

		return null;
	}, [form?.Total, form?.VAT, getTotal]);

	const updateExternalInvoices = useCallback(() => {
		const newInvoices = invoices.map((i) => {
			return {
				...i,
				external_supplier_invoice: {
					...(i.external_supplier_invoice || {}),
					external_invoice_id: form.GivenNumber,
					status: getStatus(),
					source: "fortnox",
				},
			};
		});

		setInvoices(newInvoices);

		return newInvoices as SupplierInvoice[];
	}, [form?.GivenNumber, invoices, getStatus]);

	const handleSave = async () => {
		setSaving(true);

		const fixedInvoices = updateExternalInvoices();

		try {
			const responses = await Promise.all(
				fixedInvoices?.map(async (invoice) => {
					if (invoice.removed) {
						if (invoice?.id) {
							return await API.delete(`/api/supplier_invoices/${invoice.id}.json`);
						}
						return;
					}

					if (invoice?.id) {
						return await API.put(`/api/supplier_invoices/${invoice.id}.json`, invoice);
					}

					return await API.post(`/api/supplier_invoices.json`, invoice);
				}) || []
			);

			setInvoices(responses.map((res) => res?.data.supplier_invoice)?.filter(Boolean) || []);

			toastr.success("Faktura uppdaterad");
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
		}

		setSaving(false);
	};

	const getChangeHandler = (key: string, secondKey?: string) => {
		return (value) => {
			setForm((prev) => ({ ...(prev || {}), [key]: secondKey ? value?.[secondKey] : value } as FortnoxSupplierInvoice));
		};
	};

	const addSection = () => {
		getUpdateInvoiceRows(null)([]);
	};

	const removeSection = (index) => {
		setInvoices((c) => {
			// c.splice(index, 1);
			c[index].removed = true;
			return [...c];
		});
	};

	const getUpdateInvoiceHandler = (index, key) => {
		return (value) => {
			setInvoices((c) => {
				const invoice = c[index] || { supplier_invoice_rows: [] };
				invoice[key] = value;
				if (key === "project") {
					invoice.supplier_invoice_rows = invoice.supplier_invoice_rows.map((row) => ({ ...row, surcharge: value.purcharge_price_surcharge || 0 }));
				}
				c[index] = invoice;
				return [...c];
				// return [...c.slice(0, index), invoice, ...c.slice(index + 1)];
			});
		};
	};

	const getAddNewRowHandler = (index) => () => {
		setInvoices((c) => {
			const invoice = c[index] || { supplier_invoice_rows: [] };
			invoice.supplier_invoice_rows = [
				...invoice.supplier_invoice_rows,
				{ description: "", project: null, amount: Math.max(getTotalRemainigAmount(), 0), surcharge: invoice.project?.purcharge_price_surcharge || 0 },
			];

			c[index] = invoice;

			return [...c];
			// return [...c.slice(0, index), invoice, ...c.slice(index + 1)];
		});
	};

	const readOnly = true;
	const locked = false;
	const primaryAction = locked
		? undefined
		: {
				content: "Spara",
				loading: saving,
				onAction: handleSave,
				disabled: saving,
		  };
	const metadata = null;

	const content = (() => {
		const tag = tabs[selectedTabIndex];
		switch (tag?.id) {
			case "pdf": {
				const cont =
					!pdfs?.length && !loadingPdfs ? (
						<div>Ingen pdf hittades</div>
					) : (
						pdfs?.map((pdf) => <FortnoxPDFView history={history} key={pdf.Id} pdf={pdf} loading={loadingPdfs} />)
					);

				return (
					<Layout sectioned>
						<Card sectioned>{cont}</Card>
					</Layout>
				);
			}
			case "general":
			default:
				return (
					<>
						<Card sectioned>
							<Layout>
								<Layout.Section>
									<Layout>
										<Layout.Section oneThird>
											<FormLayout>
												<TextField
													readOnly={readOnly}
													label="Faktura nummer"
													value={form?.InvoiceNumber}
													onChange={getChangeHandler("InvoiceNumber")}
												/>
												<SearchField
													readOnly={readOnly}
													placeholder="Leverantör"
													caption="Välj leverantör"
													resource="fortnox/suppliers.json"
													resourceName={{
														singular: "leverantör",
														plural: "leverantörer",
													}}
													onChange={(c) => {
														form.SupplierNumber = c.SupplierNumber;
														form.SupplierName = c.Name;

														setForm({ ...form });
													}}
													value={form?.SupplierName}
													label="Leverantör"
													label_handle="SupplierNumber"
													id_handle="SupplierNumber"
													textAlign="left"
													renderLabel={(item) => item.SupplierNumber + "-" + item.Name}
												/>
												<TextField
													readOnly={readOnly}
													type="date"
													label="Fakturadatum"
													value={form?.InvoiceDate}
													onChange={getChangeHandler("InvoiceDate")}
													max="9999-12-31"
												/>
											</FormLayout>
										</Layout.Section>
										<Layout.Section oneThird>
											<FormLayout>
												<TextField readOnly={readOnly} label="Pris inkl. Moms" value={form?.Total} onChange={getChangeHandler("Total")} />
												<TextField readOnly={readOnly} label="Moms" value={form?.VAT} onChange={getChangeHandler("VAT")} />
												<TextField
													readOnly={readOnly}
													type="date"
													label="Förfallodatum"
													value={form?.DueDate}
													onChange={getChangeHandler("DueDate")}
													max="9999-12-31"
												/>
											</FormLayout>
										</Layout.Section>
										<Layout.Section oneThird>
											<FormLayout>
												<TextField readOnly={readOnly} label="OCR" value={form?.OCR} onChange={getChangeHandler("OCR")} />
												<SearchField
													readOnly={readOnly}
													placeholder="Sök referens"
													caption="Välj våran referens"
													resource="users"
													resourceName={{
														singular: "användare",
														plural: "användare",
													}}
													value={form?.YourReference}
													onChange={getChangeHandler("YourReference", "name")}
													label="Vår referens"
													required
													// disabled={locked}
													textAlign="left"
													label_handle="name"
												/>
												{/* <TextField readOnly={readOnly} label="" value={form.Comments} onChange={getHandleFromSort("Comments")} /> */}
												{/* <TextField readOnly={readOnly} value={form.Comments} onChange={getHandleFromSort("Comments")} /> */}
											</FormLayout>
										</Layout.Section>
									</Layout>
									<OpenPdfButton />
								</Layout.Section>
								{/* {pdfs?.length && (
									<Layout.Section secondary>
										<PdfWrapper>
											{pdfs?.map((pdf) => (
												<FortnoxPDFView history={history} key={pdf.Id} pdf={pdf} loading={loadingPdfs} width="100%" />
											))}
										</PdfWrapper>
									</Layout.Section>
								)} */}
							</Layout>
						</Card>
						<Layout>
							<Layout.Section>
								<div style={{ marginTop: "1rem" }}>
									<b>Fördela kostnaden på olika projekt</b>
								</div>
							</Layout.Section>

							{isLoadingInvoices && !invoices?.length && (
								<Layout.Section>
									<Skeleton1Col />
								</Layout.Section>
							)}

							{invoices?.map((invoice, index) => {
								if (invoice.removed) return null;

								return (
									<Layout.Section key={index}>
										<Card
											sectioned
											key={index}
											secondaryFooterActions={[
												{
													content: "Ta bort",
													// disabled: invoices?.length === 1,
													onAction: () => removeSection(index),
													destructive: true,
													// enabled: invoice.id,
												},
											].filter((i) => !("enabled" in i) || i.enabled)}
										>
											<FormLayout>
												<SearchField
													onClearButtonClick={getUpdateInvoiceHandler(index, "project")}
													clearButton
													placeholder="Sök projekt"
													caption="Välj project"
													resource="projects.json"
													resourceName={{
														singular: "Projekt",
														plural: "Projekt",
													}}
													onChange={getUpdateInvoiceHandler(index, "project")}
													label="Projekt"
													label_handle="full_label"
													textAlign="left"
													value={invoice.project?.full_label || invoice.project?.title}
													fullWidth={false}
												/>

												<InvoiceRowsTable
													setRows={getUpdateInvoiceRows(index)}
													rows={invoice.supplier_invoice_rows}
													highlights={[history?.location?.state?.work_order_purchase_invoice?.id].filter((i) => i)}
													supplierInvoice={form}
												/>

												<Button icon={CirclePlusMajor} onClick={getAddNewRowHandler(index)} />
											</FormLayout>
										</Card>
									</Layout.Section>
								);
							})}

							<Layout.Section>
								<Card sectioned>
									<Button fullWidth primary onClick={addSection}>
										{invoices?.length ? "Lägg till projekt" : "Koppla mot projekt"}
									</Button>
								</Card>
							</Layout.Section>
						</Layout>
					</>
				);
		}
	})();

	const rowsTotal = getTotal(invoices?.filter((i) => i?.project));
	const totalExclVat = parseInt(form?.Total) - parseInt(form?.VAT);

	const pdfsDisabled = !pdfs?.length;

	return (
		<Wrapper isPdfOpen={isPdfOpen} pdfsDisabled={pdfsDisabled}>
			<Page
				fullWidth
				title={`Leverantörsfaktura: ${form?.GivenNumber || ""} ${form?.InvoiceNumber && `(${form?.InvoiceNumber})`}`}
				backAction={{ content: "Tillbaka", onAction: () => history.goBack() }}
				primaryAction={primaryAction}
				// secondaryActions={[secondaryActions]}
				titleMetadata={metadata}
			>
				{!isLoadingInvoices && !loading && form?.Total && rowsTotal < totalExclVat && (
					<Banner
						title="Det finns artikelrader som inte är kopplade mot ett projekt ännu"
						type="warning"
						dismissible
						style={{ marginBottom: "1rem" }}
					>
						<p>
							{rowsTotal ? currencyFormatter({ value: rowsTotal }) : "SEK 0"} / {currencyFormatter({ value: totalExclVat }).replace(" ", "")} (exkl.
							moms)
						</p>
					</Banner>
				)}

				<Tabs selected={selectedTabIndex} tabs={tabs} onSelect={handleTabChange} />
				{content}

				<PageActions primaryAction={primaryAction} />
			</Page>

			<PdfWrapper
				disabled={!pdfs?.length}
				// onTransitionEnd={(e) => {
				// 	console.debug("onTransitionEnd e:", e);
				// }}
			>
				<PdfInnerWrapper>
					{pdfs?.length && (
						<OpenPdfButton>
							<Tooltip content={isPdfOpen ? "Dölj pdf" : "Visa pdf"}>
								<Button onClick={() => setIsPdfOpen((c) => !c)} icon={isPdfOpen ? ChevronRightMinor : NoteMajor} />
							</Tooltip>
						</OpenPdfButton>
					)}

					{isPdfOpen && pdfs?.map((pdf) => <FortnoxPDFView history={history} key={pdf.Id} pdf={pdf} loading={loadingPdfs} width="100%" />)}
				</PdfInnerWrapper>
			</PdfWrapper>
		</Wrapper>
	);
};
export default FortnoxSupplierinvoicesEdit;

const OpenPdfButton = styled.div`
	position: absolute;
	top: 50%;
	transition: transform 250ms ease-in-out;
`;

const PdfWrapper = styled.div<{ disabled: boolean }>`
	position: fixed;
	top: 0;
	right: 0;
	${({ disabled }) =>
		disabled &&
		css`
			display: none;
		`}
`;
const Wrapper = styled.div<{ isPdfOpen: boolean; pdfsDisabled: boolean }>`
	width: ${({ isPdfOpen, pdfsDisabled }) => (isPdfOpen ? "60%" : pdfsDisabled ? "100%" : "calc(100% - 50px)")};
	display: grid;

	.Polaris-Page.Polaris-Page--fullWidth {
		width: 100%;
	}

	&,
	${PdfWrapper} {
		transition: width 250ms ease-in-out;
	}

	${PdfWrapper} {
		width: ${({ isPdfOpen }) => (isPdfOpen ? "38%" : "50px")};
	}

	${OpenPdfButton} {
		transform: ${({ isPdfOpen }) => (isPdfOpen ? "translate(-50%, -50%)" : " translate(0%, -50%)")};
	}
`;

const PdfInnerWrapper = styled.div`
	position: relative;
	min-height: 100vh;
`;

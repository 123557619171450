import React, { useState } from "react";
import { SettingToggle as PolarisSettingToggle } from "@shopify/polaris";
import type { ComplexAction } from "@shopify/polaris";

interface Action extends ComplexAction {
	onAction(): () => Promise<any>;
}
type SettingToggleProps = {
	children?: React.ReactNode;
	enabled?: boolean;
	/** Card header actions */
	action?: Action;
};

const SettingToggle = ({ action, enabled, children }: SettingToggleProps) => {
	const [loading, setLoading] = useState(false);

	const onToggle = async () => {
		if (action?.onAction) {
			setLoading(true);
			await action.onAction();
			setLoading(false);
		}
	};

	return (
		<PolarisSettingToggle
			action={{
				...action,
				onAction: onToggle,
				loading,
			}}
			enabled={enabled}
		>
			{children}
		</PolarisSettingToggle>
	);
};
export default SettingToggle;

import { InlineError, Modal } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";

const RemoveModal = ({ open, onClose, title = "Ta bort?", type, name, url, item, history }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setError(null);
	}, [open]);

	const handleRemove = () => {
		setLoading(true);
		setError(null);
		API.delete(url)
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					setError(result.data.error);
					return;
				}
				toastr.success(item + " borttaget");
				history.goBack();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
				setError(error?.response?.data?.message || error?.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={title}
			secondaryActions={[
				{
					content: "Stäng",
					onAction: onClose,
				},
			]}
			primaryAction={{
				destructive: true,
				content: "Ta bort",
				loading,
				onAction: handleRemove,
			}}
		>
			<Modal.Section>
				Är du säker du vill ta bort { type } &quot;{name}&quot;?
				{error && <InlineError message={error} fieldID="error" />}  
            </Modal.Section>
		</Modal>
	);
};
export default RemoveModal;

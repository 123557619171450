import React, { Component, ReactElement } from "react";
import styled, { keyframes } from "styled-components";

type SplashLoadingScreenProps = { text?: string | ReactElement };
type SplashLoadingScreenState = any;

class SplashLoadingScreen extends Component<SplashLoadingScreenProps, SplashLoadingScreenState> {
	constructor(props: SplashLoadingScreenProps) {
		super(props);
		this.state = {};
	}

	render() {
		const content = (
			<Wrapper>
				<Loader>
					<LogoCenterWrapper>
						<img src="/assets/android-chrome-512x512.png" alt="" />
					</LogoCenterWrapper>
					<CircleLoader viewBox="25 25 50 50">
						<Circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#ffffff" strokeWidth="3" />
					</CircleLoader>

					{this.props.text && <Text>{this.props.text}</Text>}
				</Loader>
			</Wrapper>
		);

		return <CoverWrapper>{content}</CoverWrapper>;
	}
}
export default SplashLoadingScreen;

const rotate = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
`;
const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`;

const color = keyframes`
 0% {
    stroke: #ffffff;
  }
  40% {
    stroke: #ffffff;
  }
  66% {
    stroke: #ffffff;
  }
  80%, 90% {
    stroke: #ffffff;
  }
`;

const CoverWrapper = styled.div.attrs({ className: "splash-loading-screen-cover" })`
	position: fixed !important;
	width: 100%;
	height: 100%;
	background-color: #007982;
`;

const Wrapper = styled.div`
	left: 50% !important;
	margin-left: -100px;
	position: fixed !important;
	top: 50% !important;
	margin-top: -100px;
	width: 45px;
	z-index: 9000 !important;
`;
const Loader = styled.div`
	position: relative;
	margin: 0px auto;
	width: 200px;
	height: 200px;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;
	}
`;

const CircleLoader = styled.svg`
	-webkit-animation: ${rotate} 4s linear infinite;
	animation: ${rotate} 4s linear infinite;
	height: 100%;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
`;

const Circle = styled.circle`
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	-webkit-animation: ${dash} 2.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
	animation: ${dash} 2.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
	stroke-linecap: round;
`;

const LogoCenterWrapper = styled.div`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		height: 45%;
		aspect-ratio: 1/1;
	}
`;

const Text = styled.p`
	text-align: center;
`;

import { FormLayout, Modal } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import API from "../../API";
import TextField from "../TextField";
import { toastr } from "../toastr";

function SavedSearchModal({ open, view: propsView, onClose }) {
	const [view, setView] = useState(propsView);
	const [loading, setLoading] = useState(false);
	// const [overwriteOtherSavedSearch, setOverwriteOtherSavedSearch] = useState(false);

	const onSave = async () => {
		setLoading(true);
		API.put(`/api/saved_searches/${view.id}.json`, view)
			.then((result) => {
				onClose(result.data.saved_search);
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
			});

		return true;
	};

	const handleChangeTitle = useCallback((value) => {
		setView((view: any) => ({ ...view, title: value }));
	}, []);

	useEffect(() => {
		if (open) {
			setView(propsView);
		} else {
			setView(null);
		}
	}, [propsView, open]);

	return (
		<Modal
			title={`Redigera sparad sökning "${propsView?.title}"`}
			open={open}
			onClose={onClose}
			primaryAction={{ content: "Spara", onAction: onSave, loading }}
		>
			<Modal.Section>
				<FormLayout>
					<TextField placeholder="Title.." onChange={handleChangeTitle} value={view?.title} />

					{/* <Checkbox label="Skriv över befintlig sökning" checked={overwriteOtherSavedSearch} onChange={setOverwriteOtherSavedSearch} />
					{overwriteOtherSavedSearch && <p>asd</p>} */}
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
}
export default SavedSearchModal;

import moment from "moment";
import { store } from "./store";

export const chunk = (inputArray, perChunk) => {
	var result = inputArray.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / perChunk);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);

	return result;
};
export const getAdminPrice = (count, basePriceAdmins) => {
	var price = basePriceAdmins;
	var sum = 0;
	var inc = 0;
	for (var i = 0; i < count; i++) {
		var multiplier = (80 + inc * 5) / 100;
		if (i > 0 && i % 2 == 1) {
			inc++;
		}
		if (multiplier > 1) {
			multiplier = 1;
		}

		if (i % 2 == 0) {
			price = Math.ceil(Math.round(price * multiplier) / 25) * 25;
		}
		sum += price;
	}
	return sum;
};

export const getPackagePrice = (packagee, basePrice) => {
	if (basePrice) return basePrice;
	switch (packagee) {
		case 1:
			return 490;
		case 2:
			return 990;
		case 3:
			return 1490;
		default:
			return 0;
	}
};

export const getAdminOptions = (packagee) => {
	var options = [];
	let maxAdmins;
	if (packagee == 1) {
		maxAdmins = 2;
	}
	if (packagee == 2) {
		maxAdmins = 5;
	}
	if (packagee == 3) {
		maxAdmins = 10;
	}
	options.push({ label: `Ingen extra admin (1 ingår alltid) +0kr`, value: 0 });
	for (var i = 1; i < maxAdmins; i++) {
		options.push({
			label: `+${i} extra admin ${i > 1 ? "s" : ""} +${getAdminPrice(i, getPackagePrice(packagee))}kr`,
			value: i,
		});
	}
	return options;
};

export const getPackageOptions = () => {
	return [
		{ label: `Bas 1-2 anställda +${getPackagePrice(1)}kr`, value: 1 },
		{ label: `Plus 3-5 anställda +${getPackagePrice(2)}kr`, value: 2 },
		{ label: `Total 5+ anställda +${getPackagePrice(3)}kr`, value: 3 },
	];
};

export const msToHMS = (ms) => {
	if (!ms) return "-";
	const duration = moment.duration(ms || 0);

	const months = duration.months() ? `${duration.months()}M ` : "";
	const weeks = duration.weeks() ? `${duration.weeks()}w` : "";
	const days = duration.days() ? `${duration.days()}D ` : "";
	const hours = duration.hours() ? `${duration.hours()}h` : "";
	const minutes = duration.minutes() || duration.minutes() ? ` ${duration.minutes()}m` : "";
	const seconds = duration.seconds() ? ` ${duration.seconds()}s` : "";
	const HMS = `${months}${weeks}${days}${hours}${minutes}${seconds}`;
	return HMS.trim();
};
export const getElementIndex = (element) => {
	return Array.from(element.parentNode.children).indexOf(element);
};

export const getPortalsMaxZIndex = () => {
	// const elements = document.querySelectorAll("#PolarisPortalsContainer *[data-portal-id]");
	const sheets = document.querySelectorAll("#PolarisPortalsContainer .Polaris-Sheet__Container");
	const modals = document.querySelectorAll("#PolarisPortalsContainer .Polaris-Modal-Dialog__Container");
	const maxZ = Math.max(
		...Array.from([...sheets, ...modals], (el) => parseFloat(window.getComputedStyle(el).zIndex)).filter((zIndex) => !Number.isNaN(zIndex)),
		0
	);

	return maxZ;
};

export const RemoveCookie = (cookieName) => {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax`;
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const AddCookie = (cookieName, value, options = {}) => {
	const optionsEntries = Object.entries(options);
	const cookie = `${cookieName}=${value}; ${optionsEntries?.map((i) => `${i[0]}=${i[1]}`)?.join("; ")}${
		optionsEntries && optionsEntries.length ? ";" : ""
	}path=${"path" in options ? options.path : "/"};SameSite=${"sameSite" in options ? options.sameSite : "Lax"}`;

	document.cookie = cookie;
};

export const getCookie = (cname) => {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			if (c.substring(name.length, c.length) === "null") {
				return null;
			} else if (c.substring(name.length, c.length) === "false") {
				return false;
			} else if (c.substring(name.length, c.length) === "true") {
				return true;
			}

			return c.substring(name.length, c.length);
		}
	}
	return null;
};
export const getLocalStorage = (name) => {
	const item = localStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};

export const setLocalStorage = (name, data) => {
	try {
		localStorage.setItem(name, typeof finallValue === "string" ? data : JSON.stringify(data));
		return data;
	} catch (error) {
		console.warn(`setLocalStorage - ${name}: `, error);
	}
};

export const getSessionStorage = (name) => {
	const item = sessionStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};

export const currencyFormatter = ({
	value,
	currency = "SEK",
	locale = store.getState().user.locale || "se-SV",
	minimumFractionDigits = 0,
	maximumFractionDigits = 2,
	style = "currency",
}) => {
	if (typeof value === "number" && Number.isFinite(parseInt(value)) && !Number.isNaN(value)) {
		const formatedValue = new Intl.NumberFormat(locale, {
			style,
			currency,
			minimumFractionDigits,
			maximumFractionDigits,
		}).format(parseFloat(value));

		if (locale === "se-SV" && style === "currency") {
			return `${formatedValue
				.replace(/\bSkr\b/g, "")
				.replace(/\bSEK\b/g, "")
				.trim()
				.replace(/,/g, " ")} ${currency}`;
		}

		return formatedValue;
	}
	return "";
};

export const getTypeLabel = (type) => {
	switch (type) {
		case "work_order":
			return "Arbetad tid";
		case "personell_ledger":
			return "Personalliggare";
		case "expense":
			return "Utgift / kostnad";
		case "divergence":
			return "Avvikelse";
		case "photo":
			return "Ta foto";
		case "sick":
			return "Sjukdom";
		case "vab":
			return "VAB";
		case "shorttime":
			return "Arbetstidsförkortning";
		case "parenting":
			return "Föräldraledig";
		case "vacation":
			return "Semester";
		case "service_free":
			return "Tjänstledig";
		case "leave_of_abscence":
			return "Permission";
		case "birth_free":
			return "Ledig vid födsel";
		case "comp_free":
			return "Kompledig";
		case "weekend_salary":
			return "Helglön";
		case "mileage":
			return "Resor till/från jobb";
		case "covid":
			return "Smittbärarpenning";
		case "national_day":
			return "Nationaldagen";
		default:
			return type;
	}
};

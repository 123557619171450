import React, { Component } from "react";
import { Banner } from "@shopify/polaris";
import API from "../API";
import { toastr } from "./toastr";

class GithubVersionChecker extends Component {
	constructor(props) {
		super(props);
		this.interval = 60000;
		this.state = { version: null, updated: false };
	}

	componentDidMount() {
		this.checkVersion();
	}

	checkVersion() {
		API.get("/api/git_version.json")
			.then((result) => {
				if (this.state.version === null) {
					this.setState({ version: result.data.last_commit_id });
					setTimeout(this.checkVersion.bind(this), this.interval);
				} else {
					if (this.state.version != result.data.last_commit_id) {
						this.setState({ updated: true, last_commit_id: result.data.last_commit_id });
					} else {
						setTimeout(this.checkVersion.bind(this), this.interval);
					}
				}
			})
			.catch((error) => {
				toastr.error(error);
				setTimeout(this.checkVersion.bind(this), this.interval);
			});
	}

	render() {
		if (!this.state.updated) {
			return <span />;
		} else {
			return (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: "var(--navigation-sidebar-width)",
						right: 0,
						zIndex: 518,
						transition: "left var(--mainmenu-transition-time)",
					}}
				>
					<Banner
						title="Ny version"
						status="info"
						action={{
							content: "Uppdatera nu",
							onAction: () => {
								window.location.reload(true);
							},
						}}
						secondaryAction={{
							content: "Stäng",
							onAction: () => {
								this.setState({ updated: false, version: this.state.last_commit_id });
							},
						}}
						onDismiss={() => {
							this.setState({ updated: false, version: this.state.last_commit_id });
						}}
					>
						<p>Det finns en ny version av systemet ute, var vänlig ladda om webbläsaren för att uppdatera</p>
					</Banner>
				</div>
			);
		}
	}
}
export default GithubVersionChecker;

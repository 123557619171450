import React, { useState } from "react";
import { Icon, Spinner } from "@shopify/polaris";
import Modal from "src/js/components/modal";
import { SettingsItem as StyledSettingsItem } from "./styles";

const SettingsItem = ({ item }) => {
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const confirm = item.confirmation || item.confirm;

	const getHandleOnClick = () => {
		if (!item.onAction) return () => {};

		return async (e) => {
			e?.preventDefault();
			e?.stopPropagation();

			if (confirm && !open) {
				setOpen(true);
				return;
			}

			setIsLoading(true);
			try {
				await item.onAction();
			} catch (error) {
				console.error("error:", error);
			}
			setIsLoading(false);
			setOpen(false);
		};
	};

	return (
		<>
			<StyledSettingsItem backgroundColor={item.backgroundColor} to={item.path || ""} onClick={getHandleOnClick()}>
				<StyledSettingsItem.Icon>
					{isLoading ? <Spinner size="small" /> : React.isValidElement(item.icon) ? item.icon : <Icon source={item.icon} />}
				</StyledSettingsItem.Icon>
				<StyledSettingsItem.Title>{item.title}</StyledSettingsItem.Title>
				<StyledSettingsItem.Description>{item.description}</StyledSettingsItem.Description>
			</StyledSettingsItem>

			<Modal
				open={open}
				onClose={() => setOpen(false)}
				title={confirm?.title || item.content}
				primaryAction={{
					...item,
					content: confirm?.confirm || item.content,
					onAction: getHandleOnClick(),
					loading: item.loading || isLoading,
				}}
				secondaryActions={[
					{
						content: confirm?.cancel || "Avbryt",
						onAction: () => setOpen(false),
					},
				]}
				sectioned
			>
				{confirm?.content || confirm?.text || confirm?.message || `${item.content}?`}
			</Modal>
		</>
	);
};
export default SettingsItem;

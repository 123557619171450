import React, { Component } from "react";
import styled from "styled-components";
import QuotePDFFooter from "./QuotePDFFooter";
import QuotePDFHeader from "./QuotePDFHeader";
import QuotePDFSecondHeader from "./QuotePDFSecondHeader";
import QuotePDFTable from "./QuotePDFTable";
import { calculateTotal } from "../TotalCost";
import { toastr } from "../../../components/toastr";
import API from "../../../API";

// This is not used anymore, this was used to create the twig
class QuotePDF extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		document.title = "Offert 33";
		this.orginWidth = 2480;
		this.orginHeight = 3508;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.aspectRation = this.orginHeight / this.orginWidth;
	}

	getSize() {
		const devWidth = this.orginWidth / window.innerWidth;
		const devHeight = this.orginHeight / window.innerHeight;

		// return Math.max(1 / devHeight, 1 / devHeight);

		if (devHeight > devWidth) {
			const height = this.orginHeight * (1 / devHeight);
			const width = height / 1.414516129032258;
			return { width, height };
		}
		if (devWidth > devHeight) {
			const width = this.orginWidth * (1 / devHeight);
			const height = width * 1.414516129032258;
			return { width, height };
		}
	}

	componentDidMount() {
		if (this.props.quote) return null;
		API.get(`/api/quotes/${34}.json`)
			.then((result) => {
				if (result.data.error) {
					return;
				}
				this.setState({ quote: result.data.quote });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		// const size = this.getSize();
		const quote = this.props.quote || this.state.quote;
		if (!quote) return null;
		const { title, id, introduction, closure, rows } = quote || {};
		// eslint-disable-next-line no-unused-vars
		const { width, height } = this.getSize();
		document.title = `Offert ${id}`;
		const totalCost = calculateTotal(rows);

		return (
			// <Wrapper height={this.orginHeight} width={this.orginWidth} size={size}>
			<Wrapper
				style={{ backgroundColor: "#ffffff", display: "flex", flexDirection: "column", gap: "50px", position: "relative" }}
				width={width}
				id={this.props.id}
			>
				<QuotePDFHeader quote={quote} />
				<QuotePDFSecondHeader quote={quote} />
				<h2 style={{ fontSize: "40px", fontWeight: "bold", lineHeight: "40px" }}>{title}</h2>
				<p style={{ whiteSpace: "pre-wrap", fontSize: "16px", lineHeight: "16px", pageBreakInside: "avoid" }}>{introduction}</p>
				<QuotePDFTable rows={rows} totalCost={totalCost} />
				<p style={{ whiteSpace: "pre-wrap", fontSize: "16px", lineHeight: "16px", pageBreakInside: "avoid" }}>{closure}</p>
				<QuotePDFFooter quote={quote} />
			</Wrapper>
		);
	}
}
export default QuotePDF;

const Wrapper = styled.div`
	width: ${({ width = 2480 }) => width}px;
	/* transform: ${({ size = 1 }) => `scale(${size})`}; */
`;

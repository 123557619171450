import { toastr as t } from "react-redux-toastr";
import axios from "axios";
import { ERROR_MESSAGES } from "src/constants/errors";
// eslint-disable-next-line import/prefer-default-export
export class toastr {
	static success(propsTitle, propsMessage = "") {
		const title = propsMessage ? propsTitle : "Klar";
		const text = propsMessage || propsTitle;

		t.success(title, text);
	}

	static warning(message) {
		console.warn("warning: ", message);
		t.warning("Varning", message);
	}

	static error(object) {
		let message;
		if (axios.isCancel(object)) {
			console.debug("Request canceled");
			return;
		}

		if ([ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN, ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN].includes(object?.response?.data?.message)) {
			const event = new CustomEvent("fortnoxReconnect", { detail: { error: object.response.data } });
			window.dispatchEvent(event);
			return;
		}

		console.error("Toastr Error object:", object);

		if (typeof object == "object") {
			if ("response" in object && object.response) {
				if ((object.response.status == 401 || object.response.status == 403) && !object.response?.data?.message?.startsWith?.("Fortnox")) {
					// window.location.href = "/admin/authentication/login";
					return;
				} else {
					if ("message" in object.response.data) {
						message = object.response.data.message;
					} else if ("error" in object.response.data) {
						if (typeof object.response.data.error == "object") {
							if ("message" in object.response.data.error) {
								message = object.response.data.error.message;
							}
						} else {
							message = object.response.data.error;
						}
					}

					if (object.response.status == 302) {
						// This doesnt work anymore because suddenly axios/browser follow redirects
						window.location.href = message;
						return;
					}
				}
			} else {
				// eslint-disable-next-line no-console
				console.debug("No response or response is null in error", object);
			}
		} else {
			message = object;
		}
		console.error("Toastr Error:", message);
		t.error("Något blev fel!", message, { disableCloseButtonFocus: true });
	}
}

import { Component } from "react";
import { Modal, Badge, Select, HorizontalStack } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import Moment from "react-moment";
import { currencyFormatter } from "src/js/Utilities";
import { toastr } from "../../components/toastr";
import { store } from "../../store";

import TimeRangeModal from "../../components/TimeRangeModal.js";
import IndexTable from "../../components/IndexTable";
import API from "../../API";
import BulkInvoiceModal from "../Invoices/BulkInvoiceModal";
import getInvoicedStatusColumn from "../Invoices/Fortnox/Utilities/getInvoicedStatusColumn";

class QuoteIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changeStatusModalOpen: false,
			timeRangeModalOpen: false,
			selectedItems: [],
			saving: false,
			params: {},
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	updateParams(params) {
		this.setState({ params });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printReports() {
		this.setState({ timeRangeModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	printRange(fromDate, toDate) {
		this.closeTimeRangeModal();
		var projectIds = this.state.selectedItems;
		var query = {
			ids: projectIds,
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
	}

	changeStatus() {
		this.setState({ changeStatusModalOpen: true });
	}

	doUpdateStatus() {
		this.setState({ saving: true });
		var projectIds = this.state.selectedItems;
		API.put("/api/quotes/bulk_edit.json", { status: this.state.newStatus }, { params: { ids: projectIds } })
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					return;
				}

				this.refresh();
				this.setState({ changeStatusModalOpen: false, saving: false });
				toastr.success("Offert uppdaterad");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	async handleOpenInvoiceStatusModal() {
		this.setState({ invoiceModalIsOpen: true });
	}

	exportCurrentProjects(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		window.open("/api/projects/export.xls?" + httpBuildQuery(params));
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		var filters = [
			{
				key: "status",
				label: "Status",
				operatorText: "är",
				type: "select",
				options: [
					{ label: "Skickad", value: "sent" },
					{ label: "Ej skickad", value: "draft" },
					{ label: "Accepterad", value: "accepted" },
					{ label: "Nekad", value: "declined" },
				],
			},
			{
				key: "start_at_period",
				label: "Förväntat startdatum",
				type: "select",
				options: [
					{ label: "Nästa månad", value: "next_month" },
					{ label: "Denna månad", value: "this_month" },
				],
			},
		];

		let promotedBulkActions = [];
		if (!store.getState().user.read_only) {
			promotedBulkActions = [
				{
					content: "Skriv ut rapporter",
					onAction: this.printReports.bind(this),
				},
				{
					content: "Ändra status",
					onAction: this.changeStatus.bind(this),
				},
				{
					content: "Ändra faktureringsstatus",
					loading: this.state.loadingInvoicing,
					onAction: this.handleOpenInvoiceStatusModal.bind(this),
				},
			];
		}

		const sortOptions = [
			{ label: "Skapad (nyast till äldst)", value: "ID_DESC" },
			{ label: "Skapad (äldst till nyast)", value: "ID_ASC" },
			{ label: "Benämning (A-Ö)", value: "TITLE_ASC" },
			{ label: "Benämning (Ö-A)", value: "TITLE_DESC" },
		];

		const columns = [
			{
				handle: "CREATED_AT",
				label: "Datum",
				sortable: true,
				render: (item) => {
					return (
						<Moment locale="sv" format="YYYY-MM-DD">
							{item.created_at}
						</Moment>
					);
				},
			},
			{
				handle: "CUSTOMER",
				label: "Kund",
				sortable: true,
				render: (item) => item.company_name,
			},
			{
				handle: "TITLE",
				label: "Namn",
				sortable: true,
				render: (item) => item.title,
			},
			{
				handle: "STATUS",
				label: "Status",
				sortable: true,
				render: (item) => {
					let status = null;
					if (item.status == "draft") {
						status = (
							<Badge key="status" progress="partiallyComplete">
								Ej skickad
							</Badge>
						);
					} else if (item.status == "sent") {
						status = (
							<Badge key="status" status="info">
								Skickad
							</Badge>
						);
					} else if (item.status == "accepted") {
						status = (
							<Badge key="status" status="success">
								Accepterad
							</Badge>
						);
					} else if (item.status == "declined") {
						status = (
							<Badge key="status" status="critical">
								Nekad
							</Badge>
						);
					}
					const revision = item.revision > 1 && <Badge>Revision: {item.revision}</Badge>;

					return (
						<HorizontalStack gap="2">
							{status}
							{revision}
						</HorizontalStack>
					);
				},
			},
			{
				handle: "TOTAL_COST",
				label: "Belopp",
				render: (item) => currencyFormatter({ value: item.total_cost }),
			},
			getInvoicedStatusColumn(),
		];

		return (
			<div>
				<IndexTable
					ref={(ref) => {
						this.indexTableRef = ref;
					}}
					selectOnRow={this.props.selectOnRow}
					extraHeader={this.props.extraHeader}
					onSelectionChangeInclRow={this.props.onSelectionChangeInclRow}
					titleHidden={this.props.titleHidden}
					backAction={this.props.backAction}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					history={this.props.history}
					title="Offert"
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Ny offert",
									onAction: () => {
										this.props.history.push({
											pathname: "/admin/quotes/new",
											state: {
												customer: this.props.customer,
												project: this.props.project,
											},
										});
									},
							  }
					}
					defaultSavedSearches={[
						{
							title: "Skickade",
							filters: [
								{
									key: "status",
									value: "sent",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_sent",
						},
						{
							title: "Ej skickade",
							filters: [
								{
									key: "status",
									value: "draft",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_not_sent",
						},
						{
							title: "Accepterade",
							filters: [
								{
									key: "status",
									value: "accepted",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_accepted",
						},
						{
							title: "Nekade",
							filters: [
								{
									key: "status",
									value: "declined",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_declined",
						},
					]}
					// secondaryActions={[]}
					params={{
						...(this.props.fixedParams || {}),
						customer_id: this.props.customer && this.props.customer.id,
						project_id: this.props.project && this.props.project.id,
					}}
					savedSearchHandle="quotes"
					// resourceUrl={`/api/quotes.json${this.props.customer ? `?customer_id=${this.props.customer.id}` : ""}`}
					resourceUrl="/api/quotes.json"
					resourceHandle="quotes"
					resourceName={{
						singular: "offert",
						plural: "offerter",
					}}
					onUpdateParams={this.updateParams.bind(this)}
					selectedItems={this.state.selectedItems}
					onSelectionChange={this.onSelectionChange.bind(this)}
					filters={filters}
					onClickRow={(item) => {
						const url = "/admin/quotes/" + item.id;
						this.props.history.push(url, { project: this.props.project });
					}}
					columns={columns}
					sortOptions={sortOptions}
					promotedBulkActions={promotedBulkActions}
				/>
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
				/>
				<Modal
					title="Ändra status"
					open={this.state.changeStatusModalOpen}
					onClose={() => {
						this.setState({ changeStatusModalOpen: false });
					}}
					primaryAction={{
						content: "Uppdatera",
						loading: this.state.saving,
						disabled: !this.state.newStatus,
						onAction: this.doUpdateStatus.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ changeStatusModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<Select
							label="Status"
							options={[
								{ label: "Välj ny status", value: "" },
								{ label: "Ej skickad", value: "draft" },
								{ label: "Skickad", value: "sent" },
							]}
							onChange={(value) => {
								this.setState({ newStatus: value });
							}}
							value={this.state.newStatus}
						/>
					</Modal.Section>
				</Modal>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentProjects.bind(this)}
				/>
				<BulkInvoiceModal
					type="quotes"
					ids={this.indexTableRef?.getSelection()}
					open={this.state.invoiceModalIsOpen}
					onClose={() => {
						this.setState({ invoiceModalIsOpen: false });
					}}
					onSuccess={() => {
						this.setState({ invoiceModalIsOpen: false });
						this.refresh();
					}}
				/>
			</div>
		);
	}
}

export default QuoteIndex;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled, { css } from "styled-components";
import { Card as PolarisCard } from "@shopify/polaris";
import type { CardProps } from "@shopify/polaris";

interface Props extends CardProps {
	background?: boolean;
	style?: React.CSSProperties;
}

const Card = ({ children, background, style, ...props }: Props) => {
	return (
		<Wrapper background={background} style={style}>
			<PolarisCard {...props}>{children}</PolarisCard>
		</Wrapper>
	);
};
export default Card;

const Wrapper = styled.div<{ background?: boolean | null }>`
	${({ background }) => {
		if (background === undefined) return null;

		return css`
			.Polaris-Card {
				background-color: var(--secondary);

				.Polaris-TextField__Backdrop {
					--p-color-bg: var(--primary);
				}
			}
		`;
	}}
`;

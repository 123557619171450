import { CircleDisabledMajor, SendMajor } from "@shopify/polaris-icons";
import moment from "moment";
import React from "react";
import Colors from "src/js/Colors";
// import Badge from "src/js/components/Badge";
import Card from "src/js/components/Card";
import IntegrationProgress from "src/js/components/IntegrationProgress";
import { CheckmarkIcon } from "src/js/icons";
import styled from "styled-components";

const OfferUserItem = ({ offerUser }) => {
	const statuses = [
		{
			label: "Skickad",
			active: true,
			backgroundColor: Colors.blue,
			icon: SendMajor,
		},
		{
			label: "Nekad",
			active: !!offerUser.declined_at,
			backgroundColor: Colors.red,
			icon: CircleDisabledMajor,
			enabled: offerUser.is_signatory,
		},
		{
			label: "Accepterad",
			active: !!offerUser.accepted_at,
			backgroundColor: Colors.green,
			icon: CheckmarkIcon,
			enabled: offerUser.is_signatory,
		},
	].filter((t) => !("enabled" in t) || t.enabled);

	return (
		<Wrapper>
			<Card sectioned>
				<Header>
					<Email>
						{offerUser.email}
						{/* {offerUser.is_signatory && <Badge style={{ marginLeft: "1rem" }}>Signatär</Badge>} */}
					</Email>
					<p>{moment(offerUser.accepted_at).format("YYYY-MM-DD HH:mm")}</p>
				</Header>
				<IntegrationProgress statuses={statuses} style={{ marginBlock: "1rem" }} />
			</Card>
		</Wrapper>
	);
};
export default OfferUserItem;
const Wrapper = styled.div``;
const Email = styled.p`
	font-size: 1rem;
	font-weight: 600;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

import { Card, Page } from "@shopify/polaris";
import "moment-timezone";
import { connect } from "react-redux";
import { StyledFortnoxSettings, StyledFortnoxSettingsInnerCard } from "../Fortnox/Settings/styles";
import SettingsItem from "../Fortnox/Settings/SettingsItem";

const IntegrationSettings = ({ history }) => {
	const sections = [
		{
			title: "Integrationer",
			items: [
				{
					title: "Fortnox",
					description: "Fortnox är ett ekonomisystem som hjälper dig att sköta din bokföring.",
					icon: <img src="/assets/logos/fortnox.png" alt="Fortnox" />,
					path: "/admin/fortnox/settings",
				},
			],
		},
	];

	return (
		<Page
			title="Integrationer"
			backAction={{
				content: "Tillbaka",
				onAction: () => {
					history.goBack();
				},
			}}
			fullWidth
		>
			<StyledFortnoxSettings>
				{sections
					?.filter((s) => s.title || s.items?.length)
					.map((section, key) => {
						return (
							<Card key={key} title={section.title}>
								<StyledFortnoxSettingsInnerCard>
									{section?.items.map((item, key) => {
										return <SettingsItem key={key} item={item} />;
									})}
								</StyledFortnoxSettingsInnerCard>
							</Card>
						);
					})}
			</StyledFortnoxSettings>
		</Page>
	);
};

export default connect((state) => ({
	account: state.account,
}))(IntegrationSettings);

import { Icon, Popover } from "@shopify/polaris";
import type { IconSource } from "@shopify/polaris";
import { QuestionMarkInverseMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import styled from "styled-components";
import type { StatsBoxType } from "./types";
import Spinner from "../Spinner";

const StatsBox = ({ onClick, item }: StatsBoxType) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const onCloseTooltip = () => {
		setTooltipOpen(false);
	};

	const onOpenTooltip = () => {
		setTooltipOpen(true);
	};

	const { className = "", icon, title, text, link, helpText, loading } = item || {};

	return (
		<Wrapper>
			{helpText && (
				<Popover
					active={tooltipOpen}
					onClose={onCloseTooltip}
					activator={
						<HelpIcon onClick={onOpenTooltip}>
							<Icon source={QuestionMarkInverseMajor} />
						</HelpIcon>
					}
				>
					<Popover.Section>
						<p>{helpText}</p>
					</Popover.Section>
				</Popover>
			)}
			<InnerWrapper className={`${className} ${onClick ? "button" : ""}`} data-title={title} onClick={onClick as (() => void) | undefined}>
				{icon && <InnerIcon>{React.isValidElement(icon) ? icon : <Icon source={icon as IconSource} />}</InnerIcon>}
				<InnerContent icon={!!icon}>
					<InnerContentTitle>
						{loading && <Spinner size="small" style={{ marginRight: "0.5rem" }} />}
						{title || (!loading && "—")}
					</InnerContentTitle>
					<InnerContentText>{text}</InnerContentText>
					{link && <InnerContentLink onClick={link.onAction}>{link.content}</InnerContentLink>}
				</InnerContent>
			</InnerWrapper>
		</Wrapper>
	);
};
export default StatsBox;

const Wrapper = styled.div`
	position: relative;
`;

const HelpIcon = styled.span`
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;

	.Polaris-Icon {
		width: 1.5rem;
		height: 1.5rem;

		svg {
			fill: var(--color-primary);
		}
	}
`;
const InnerContentLink = styled.a`
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	display: none;
`;

const InnerIcon = styled.div`
	svg {
		width: 22px;
		height: 22px;
	}
`;
const InnerContent = styled.div<{ icon: boolean }>`
	color: var(--textColor);
	width: ${({ icon }) => (icon ? "calc(100% - 50px - 1.5rem)" : "calc(100% - 1.5rem)")};
	/* calc(100% - 50px - 1.5rem); */

	&:hover {
		${InnerContentLink} {
			display: block;
		}
	}
`;
const InnerContentTitle = styled.h1`
	font-weight: 600;
	font-size: 1.4em;

	text-overflow: ellipsis;
	white-space: pre;
	overflow: hidden;
`;
const InnerContentText = styled.span`
	opacity: 0.75;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
`;

const InnerWrapper = styled.div`
	display: flex;
	gap: 1.5rem;
	align-items: center;

	background-color: var(--tertiary);
	border-radius: 0.7rem;
	box-shadow: 1px 4px 12px 0px #00000030;
	transition: all 250ms;
	padding: 1.5rem;

	&.button {
		cursor: pointer;

		&:hover {
			filter: brightness(1.2);
		}
	}
`;

/* eslint-disable camelcase */
import { createStore, combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import user from "../reducers/user";
import users from "../reducers/users";
import account from "../reducers/account";
import accounts from "../reducers/accounts";
import locales from "../reducers/locales";
import currencies from "../reducers/currencies";
import countries from "../reducers/countries";
import sales_channels from "../reducers/sales_channels";
import activeLanguages from "../reducers/active_languages";
import pricelists from "../reducers/pricelists";
import templates from "../reducers/templates";
import translation from "../reducers/translation";
import fulfillment_services from "../reducers/fulfillment_services";
import metafield_groups from "../reducers/metafield_groups";
import pricelist_selection from "../reducers/pricelist_selection";
import selected_language from "../reducers/selected_language";
import groups from "../reducers/groups";
import savable from "../reducers/savable";
import loading from "../reducers/loading";
import column_selection from "../reducers/column_selection";
import work_types from "../reducers/work_types";
import holidays from "../reducers/holidays";

const reducers = {
	user,
	users,
	work_types,
	holidays,
	account,
	accounts,
	countries,
	activeLanguages,
	pricelists,
	templates,
	translation,
	sales_channels,
	fulfillment_services,
	metafield_groups,
	pricelist_selection,
	selected_language,
	groups,
	locales,
	currencies,
	savable,
	loading,
	column_selection,
	toastr: toastrReducer, // <- Mounted at toastr.
};
const combinedReducer = combineReducers(reducers);
initialState.column_selection = {};
initialState.savable = false;
initialState.loading = false;

// eslint-disable-next-line import/prefer-default-export
export const store = createStore(combinedReducer, initialState);

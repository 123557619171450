import { Card, Modal, Scrollable } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import { debounce } from "lodash";
import { Component, createRef } from "react";
import styled from "styled-components";
import API from "../API";
import { toastr } from "./toastr";

export default class PreviewPdfModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			form: props.form || {},
			open: props.open || false,
		};
		this.PreviewWrapperRef = createRef();
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.refreshPdfBounced = debounce(this.refreshPdf.bind(this), 1000, { maxWait: 3000, leading: false, trailing: true });
	}

	componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.form }, () => this.refreshPdf());
		}
	}

	refreshPdf() {
		this.setState({ previewLoading: true });
		if (this.state.form && this.state.form.id) {
			API.post(`/api/quotes/${this.state.form.id}/preview.json`, Object.assign(this.state.form, { page: this.state.page }))
				.then((result) => {
					if (result.data.error) {
						this.setState({ previewLoading: false });
						return;
					}
					if (this.PreviewWrapperRef.current) {
						this.PreviewWrapperRef.current.innerHTML =
							result.data.header_html +
							result.data.quote_html +
							'<div style="position:absolute;bottom:0px;left:0px;right:0px;">' +
							result.data.footer_html +
							"</div>";
					}
					/* eslint-disable react/no-unused-state */
					this.setState({
						vat: result.data.vat,
						grand_total: result.data.grand_total,
						subtotal: result.data.subtotal,
						oresavrundning: result.data.oresavrundning,
					});
					/* eslint-enable react/no-unused-state */
					this.setState({ previewLoading: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ previewLoading: false });
				});
		} else {
			this.setState({ previewLoading: true });
			if (this.state.form.rows)
				this.state.form.rows = this.state.form.rows.map((row) => {
					return row;
				});
			API.post(`/api/quotes/preview.json`, Object.assign(this.state.form, { page: this.state.page }))
				.then((result) => {
					if (result.data.error) {
						this.setState({ previewLoading: false });
						return;
					}
					if (this.PreviewWrapperRef.current) {
						this.PreviewWrapperRef.current.innerHTML = result.data.header_html + result.data.quote_html + result.data.footer_html;
					}
					/* eslint-disable react/no-unused-state */

					this.setState({
						vat: result.data.vat,
						grand_total: result.data.grand_total,
						subtotal: result.data.subtotal,
						oresavrundning: result.data.oresavrundning,
					});
					/* eslint-enabled */
					this.setState({ previewLoading: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ previewLoading: false });
				});
		}
	}

	render() {
		return (
			<Modal
				primaryAction={{
					content: "stäng",
					onAction: this.props.onClose,
				}}
				secondaryActions={[
					{
						icon: ChevronLeftMinor,
						onAction: () => this.setState((c) => ({ page: Math.max(0, c.page - 1) }), this.refreshPdf.bind(this)),
						disabled: this.state.page == 0 || this.state.previewLoading,
					},
					{
						icon: ChevronRightMinor,
						onAction: () => this.setState((c) => ({ page: Math.min(2, c.page + 1) }), this.refreshPdf.bind(this)),
						disabled: this.state.page == 2 || this.state.previewLoading,
					},
				]}
				open={this.state.open}
				onClose={this.props.onClose}
			>
				<Scrollable>
					<RelativeWrapper>
						<Card sectioned>
							<PreviewWrapper ref={this.PreviewWrapperRef} />
						</Card>
					</RelativeWrapper>
				</Scrollable>
			</Modal>
		);
	}
}

export const WidthWrapper = styled.div`
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;

	& > * {
		min-width: ${({ width }) => `${width}px`};
	}
`;
export const RelativeWrapper = styled.div`
	position: relative;

	.Polaris-Card {
		background-color: #ffffff;
	}
`;

export const PreviewHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		height: max-content;
	}
`;

export const PreviewWrapper = styled.div`
	overflow: auto;
	margin: -2rem;
	min-width: ${({ minWidth }) => (minWidth ? minWidth + "px" : "600px")};
	height: ${({ height }) => (height ? height + "px" : "100%")};
	min-height: ${({ minWidth }) => (minWidth ? `${minWidth * (297 / 210)}px` : `${600 * (297 / 210)}px`)};
	aspect-ratio: 210/297;
	/* transform: scale(0.5); */
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	color: #000000;

	iframe {
		width: 100%;
		height: 100%;
	}

	&&& {
		.editor {
			position: unset;
		}

		.editor-scroll,
		.embedded-editor {
			padding: 0 !important;
		}
		.editor__toolbar__rightButtons {
			position: relative;
		}
		.editor__toolbar {
			width: 100%;
			position: absolute;
			top: 0%;
			transform: translateY(-100%);
			backdrop-filter: none;
			box-shadow: none;
			background: transparent;

			.Polaris-ButtonGroup {
				flex-wrap: initial;
			}
		}
	}
`;

import { Component } from "react";
import { Avatar, TextStyle, ResourceList } from "@shopify/polaris";
import { connect } from "react-redux";
import IndexTable from "../../components/IndexTable";
import { Title } from "../../components/IndexTable/styledComponents";

class GroupsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderItem(item) {
		const url = "/admin/groups/" + item.id;
		const media = <Avatar customer size="medium" name={item.name} source={item.avatar} />;

		return (
			<ResourceList.Item id={item.id} media={media} url={url}>
				<div>{item.name}</div>
				<div>
					<TextStyle variation="subdued">{item.email}</TextStyle>
				</div>
			</ResourceList.Item>
		);
	}

	render() {
		const columns = [
			{
				label: "Namn",
				handle: "TITLE",
				sortable: true,
				render: (item) => {
					return (
						<Title>
							<Avatar customer size="medium" name={item.name} source={item.avatar} />
							<b>{item.name}</b>
						</Title>
					);
				},
			},
		];

		return (
			<div>
				<IndexTable
					defaultSort="TITLE_ASC"
					columns={columns}
					history={this.props.history}
					// renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.props.history.push("/admin/groups/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Grupper"
					primaryAction={{
						content: "Ny grupp",
						onAction: () => {
							this.props.history.push("/admin/groups/new");
						},
					}}
					savedSearchHandle="groups"
					resourceUrl="/api/groups.json"
					resourceHandle="groups"
					resourceName={{
						singular: "grupp",
						plural: "grupper",
					}}
					renderItem={this.renderItem.bind(this)}
				/>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		users: state.users,
	}),
	{}
)(GroupsIndex);

import React from "react";

const MapIcon = ({ color = "black" }) => (
	<svg width="32" height="33" viewBox="0 0 32 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 23.25L4 25.25V7.25L12 5.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 27.25L12 23.25V5.25L20 9.25V27.25Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 9.25L28 7.25V25.25L20 27.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default MapIcon;

import React, { Component } from "react";
import styled from "styled-components";
import Money from "../../components/Money";
import { calculateSubtotal } from "./QuoteRowsTable";

export const calculateTotal = (rows) => {
	return (rows && rows.reduce((acc, row = {}) => acc + (calculateSubtotal(row) || 0), 0)) || 0;
};
export const calculateTotalGross = (rows) => {
	const total = (rows && rows.reduce((acc, row = {}) => acc + (calculateSubtotal(row) || 0), 0)) || 0;
	return total * 1.25;
};

class TotalCost extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<MainWrapper>
				<Wrapper>
					<Wrapper2>
						<Item>
							<p>Totalt exkl. moms: </p>
							<Money amount={this.props.subtotal * 100 || 0} currency="SEK" />
							{/* <p>{totalSum}</p> */}
						</Item>
						<Item>
							<p>Moms 25%:</p>
							<Money amount={this.props.vat * 100 || 0} currency="SEK" />
						</Item>
					</Wrapper2>
					<Devider />
					<Wrapper2>
						<Item>
							<p>Total inkl. moms:</p>
							<Money amount={this.props.grand_total * 100 || 0} currency="SEK" />
						</Item>
						<Item>
							<p>Öresavrundning:</p>
							<Money amount={this.props.oresavrundning * 100 || 0} currency="SEK" />
						</Item>
						<Item>
							<p>Att betala (SEK):</p>
							<Money amount={(this.props.grand_total + this.props.oresavrundning) * 100 || 0} currency="SEK" />
						</Item>
					</Wrapper2>
				</Wrapper>
			</MainWrapper>
		);
	}
}
export default TotalCost;

const MainWrapper = styled.div`
	display: flex;
	justify-content: end;
	padding: 2rem;
	padding-right: 0;
`;

const Wrapper = styled.div`
	border-left: 1px solid grey;
	min-width: 250px;
	width: max-content;
	padding: 2rem;
`;
const Wrapper2 = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Devider = styled.div`
	height: 1px;
	background: grey;
	width: 100%;
	margin: 2rem 0;
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
`;

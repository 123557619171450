import React from "react";
import styled, { css } from "styled-components";
import { Icon } from "@shopify/polaris";

const FIRST_COLUMN_WIDTH = 50;

interface Status {
	label: string;
	tooltip?: string;
	active?: boolean;
	backgroundColor?: string;
	color?: string;
	icon?: React.ReactNode | string;
}

interface IntegrationProgressProps {
	title?: string;
	statuses: Status[];
	children?: React.ReactNode;
	onClick?: () => void;
	fill?: boolean;
	style?: React.CSSProperties;
}

const IntegrationProgress = ({ title, statuses = [], children, onClick, fill = false, style }: IntegrationProgressProps) => {
	const activeStatusesIndexes = statuses?.reduce((acc: number[], status, index) => {
		if (status.active) acc.push(index);
		return acc;
	}, []);

	const lastActiveIndex = activeStatusesIndexes && !!activeStatusesIndexes.length && Math.max(...activeStatusesIndexes);

	return (
		<React.Fragment>
			<Item onClick={onClick} style={style}>
				{title && <Title width={FIRST_COLUMN_WIDTH}>{title}</Title>}
				<ScriveProgressWrapper minWidth={statuses.length * 50}>
					{statuses.map((status, index) => (
						<ProgressStep
							label={status.label}
							tooltip={status.tooltip}
							zIndex={statuses.length - index}
							key={index}
							className={!fill ? (status.active ? "active" : "") : typeof lastActiveIndex === "number" && index <= lastActiveIndex ? "active" : ""}
							backgroundColor={status.backgroundColor}
							color={status.color}
						>
							{React.isValidElement(status.icon) ? status.icon : <Icon source={status.icon as any} />}
						</ProgressStep>
					))}
				</ScriveProgressWrapper>
			</Item>
			{children}
		</React.Fragment>
	);
};
export default IntegrationProgress;

const Item = styled.div`
	display: flex;
	gap: 1rem;
	padding: 1rem 0;
	align-items: center;

	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}
`;

const ScriveProgressWrapper = styled.div<{
	minWidth?: number | string;
}>`
	border-radius: 6px;
	background-color: var(--main3);
	display: flex;
	width: 100%;
	height: 35px;
	box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
	min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "400px")};

	div.active:last-of-type {
		/* background-color: red; */
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
`;

const ProgressStep = styled.div<{
	label: string;
	tooltip?: string;
	zIndex: number;
	backgroundColor?: string;
	color?: string;
}>`
	transition: background 500ms;
	background-color: var(--primary);
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	z-index: ${({ zIndex }) => zIndex};

	&::after {
		content: "${({ label, tooltip }) => tooltip || label}";
		text-transform: capitalize;
		/* content: "asdasd"; */
		position: absolute;
		bottom: 0;
		transform: translateY(50%);
		background: var(--main3);
		padding: 0.5rem 0.75rem;
		border-radius: 5px;
		font-size: 1rem;
		transition: opacity 100ms, transform 200ms;
		pointer-events: none;
		opacity: 0;
	}

	&:hover::after {
		transform: translateY(125%);
		opacity: 1;
		/* pointer-events: auto; */

		svg {
			opacity: 1;
		}
	}

	&:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	&::before {
		content: "";
		inset: 0;
		transition: all 500ms;
		position: absolute;
		transform: translateX(-100%);
	}

	&.active {
		&::before {
			background-color: ${({ backgroundColor, color }) => backgroundColor || color || "#5ac57d"};
			transform: translateX(0%);
		}
		/* &.last {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		} */

		svg {
			opacity: 1;
		}
	}

	.Polaris-Icon {
		height: 15px;
		width: 15px;
		z-index: 1;
	}

	svg {
		transition: opacity 250ms;
		opacity: 0.75;
		width: 15px;
		height: 15px;

		fill: #ffffff !important;
	}
`;

const Title = styled.span<{
	width: string | number;
}>`
	width: ${({ width }) => width};
	text-align: center;
	/* margin-right: 1rem; */
`;

import { Component } from "react";
import { store } from "../../store";
import API from "../../API";
import { toastr } from "../../components/toastr";
import IndexTable from "../../components/IndexTable";

class CustomerIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItems: [],
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	gotoCustomer(item) {
		var url = "/admin/customers/" + item.id;
		this.props.history.push(url);
	}

	syncCustomers(item) {
		this.setState({ syncingCustomers: true });

		// const url = new URL("/api/fortnox/customers", BASE_URL);
		// url.searchParams.set("token", getCookie("token"));

		// this.eventSource = new EventSource(url.href);
		// const eventSource = this.eventSource;

		// eventSource.onmessage = (event) => {
		// 	const data = event.data;
		// 	console.log("onmessage data:", data);

		// 	if (data === "COMPLETE") {
		// 		eventSource.close();
		// 		this.setState({ syncingCustomers: false });
		// 	}
		// };

		// eventSource.onerror = (event) => {
		// 	console.log("event:", event);
		// 	console.error("onerror event:", event);
		// 	console.log("e.readyState == EventSource.CLOSED:", event.readyState == EventSource.CLOSED);
		// 	this.setState({ syncingCustomers: false });
		// };

		// eventSource.onopen = (event) => {
		// 	console.log("onopen event:", event);
		// };

		API.get("/api/fortnox/customers", {
			timeout: 1000 * 60 * 5, // 5 minutes
		})
			.then((response) => {
				if (response.data.error) {
					toastr.error(response.data.error);
					console.error(response.data.error);
					return;
				}
				toastr.success("Kunder synkade");
				this.refresh();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error("Kunde inte synka kunder", "Testa att koppla Fortnox igen");
			})
			.finally(() => {
				this.setState({ syncingCustomers: false });
			});
	}

	bulkRemove() {
		this.setState({ bulkRemoving: true });
		API.put("/api/customers/bulk_remove.json", { ids: this.state.selectedItems })
			.then((response) => {
				if (response.data.error) {
					toastr.error(response.data.error);
					return;
				}
				toastr.success("Kunder synkade");
				this.refresh();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ bulkRemoving: false });
			});
	}

	renderCell(item, column) {
		if (column.handle == "NAME") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "CUSTOMER_NR") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.customer_id}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "PHONE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.phone}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "EMAIL") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.email}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "TYPE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.type == "company" ? "Företag" : "Privatperson"}</h3>
					</div>
				</div>
			);
		}
		return column.handle;
	}

	render() {
		const filters = [
			{
				key: "active",
				label: "Aktiv",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "type",
				label: "Typ",
				type: "select",
				options: [
					{ label: "Företag", value: "company" },
					{ label: "Privat person", value: "private_person" },
				],
			},
		];

		const bulkActions = [
			{
				content: "Ta bort",
				onAction: this.bulkRemove.bind(this),
				loading: this.state.bulkRemoving,
				disabled: false,

				promoted: false,
			},
		];

		const columns = [
			{
				handle: "NAME",
				label: "Namn",
				sortable: true,
			},
			{
				handle: "CUSTOMER_NR",
				label: "Kundnummer",
				sortable: true,
			},
			{
				handle: "PHONE",
				label: "Telefon",
				sortable: true,
			},
			{
				handle: "EMAIL",
				label: "Email",
				sortable: true,
			},
			{
				handle: "TYPE",
				label: "Kundtyp",
				sortable: true,
			},
		];

		return (
			<div>
				<IndexTable
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					secondaryActions={[
						{
							content: "Synka kunder från Fortnox",
							onAction: this.syncCustomers.bind(this),
							loading: this.state.syncingCustomers,
							enabled: store.getState().account.fortnox_connection,
						},
					].filter((i) => !("enabled" in i) || i.enabled)}
					history={this.props.history}
					title="Kunder"
					primaryAction={{
						content: "Ny kund",
						onAction: () => {
							this.props.history.push("/admin/customers/new");
						},
					}}
					savedSearchHandle="customers"
					resourceUrl="/api/customers.json"
					resourceHandle="customers"
					resourceName={{
						singular: "kund",
						plural: "kunder",
					}}
					onSelectionChange={this.onSelectionChange.bind(this)}
					selectedItems={this.state.selectedItems}
					renderCell={this.renderCell.bind(this)}
					filters={filters}
					columns={columns}
					defaultSort="ID_DESC"
					defaultSavedSearches={[
						{
							id: "default_type_company",
							title: "Företag",
							filters: [
								{
									key: "type",
									value: "company",
								},
							],
						},
						{
							id: "default_type_privateperson",
							title: "Privatpersoner",
							filters: [
								{
									key: "type",
									value: "private_person",
								},
							],
						},
					]}
					promotedBulkActions={bulkActions.filter((i) => i.promoted)}
					onClickRow={this.gotoCustomer.bind(this)}
					bulkActions={bulkActions.filter((i) => !i.promoted)}
				/>
			</div>
		);
	}
}

export default CustomerIndex;

import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Banner, FormLayout, Icon, TextField, Stack, Checkbox, TextStyle } from "@shopify/polaris";
import { DisputeMinor } from "@shopify/polaris-icons";
import { toastr } from "../../components/toastr";

import SkeletonProvider from "../../components/skeleton_provider.js";
import API from "../../API";

class UnderentreprenorEdit extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, saving: false, loading: true };
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/underentreprenorer/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.underentreprenor });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	toggleAllowTimeRegistration() {
		this.state.form.allow_time_registration = !this.state.form.allow_time_registration;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/underentreprenorer/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Uppdatera Underentreprenör");
				this.props.history.replace("/admin/underentreprenorer");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	sendInviteSms() {
		API.get("/api/ue/" + this.state.id + "/invite_link.json")
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("SMS skickat");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	loginAs() {
		window.open("https://ue.tidrapport24.se/admin/authentication/imp/" + this.state.form.username + "?tok=" + this.state.form.token);
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		var secondaryActions = [];

		if (this.state.form.status == "pending") {
			secondaryActions.push({
				content: (
					<Stack spacing="extraTight">
						<Icon source={DisputeMinor} />
						<div>Skicka inbjudan SMS</div>
					</Stack>
				),
				onAction: this.sendInviteSms.bind(this),
			});
		}

		if (this.state.form.account_id) {
			secondaryActions.push({
				content: <div>Logga in som</div>,
				onAction: this.loginAs.bind(this),
			});
		}

		return (
			<Page
				title={this.state.form.name}
				backAction={{ content: "Underentreprenörer", url: "/admin/underentreprenorer" }}
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={secondaryActions}
			>
				{this.state.form.status == "pending" ? (
					<TextStyle>
						VÄNTANDE = Ett SMS har skickats till {this.state.form.phone} där personen blivit ombedd att komplettera med nödvändiga uppgifter och
						godkänna din inbjudan att agera underentreprenör för dig
						<br />
						<br />
					</TextStyle>
				) : (
					<Banner title="UE Godkänd">
						<TextStyle>
							Underentreprenör loggar in på https://ue.tidrapport24.se/admin med konto id {this.state.form.account_id} och användarnamn{" "}
							{this.state.form.username.split("||")[0]} samt med sitt personliga lösenord
							<br />
							<br />
							GODKÄND = Ett SMS har skickats till {this.state.form.phone} och personen har godkänt och aktiverat sitt konto. Nästa steg är att han/hon
							ska skapa konto åt sina anställda.
							<br />
							<br />
						</TextStyle>
					</Banner>
				)}
				<Layout>
					<Layout.AnnotatedSection title="Inställningar" description="Hantera vad underentreprenören får göra">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField
										value={this.state.form.phone}
										onChange={(value) => {
											this.state.form.phone = value;
											this.setState({ form: this.state.form });
										}}
										label="Telefonnummer"
									/>
									<Checkbox checked disabled label="Tillåt in- och utcheckning i personalliggare" />
									<Checkbox
										checked={this.state.form.allow_time_registration}
										label="Tillåt vanlig tidrapportering"
										onChange={this.toggleAllowTimeRegistration.bind(this)}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: "Spara",
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default UnderentreprenorEdit;

/* eslint-disable react/no-unused-class-component-methods */
import { Component } from "react";
import Moment from "react-moment";
import { Stack, Modal, Spinner } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import Badge from "src/js/components/Badge";
import TimeReportModal from "src/js/components/TimeReportModal";
import { getTypeLabel } from "src/js/Utilities";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import IndexTable from "../../components/IndexTable";
import API from "../../API";
import BulkInvoiceModal from "../Invoices/BulkInvoiceModal";
import getInvoicedStatusColumn from "../Invoices/Fortnox/Utilities/getInvoicedStatusColumn";
import { ExportMinor } from "@shopify/polaris-icons";

class TimeReportsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project: props.project || props.location?.state?.project || null,
			work_order: props.work_order || props.location?.state?.work_order || null,
			machine: props.location?.state?.machine || null,
			user: props.location?.state?.user || null,
			params: {},
			groups: [],
			underentreprenorer: [],
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.project && this.props.project !== nextProps.project) {
			this.setState({ project: nextProps.project });
		}
		if (nextProps.work_order && this.props.work_order !== nextProps.work_order) {
			this.setState({ work_order: nextProps.work_order });
		}
		if (nextProps.machine && this.props.machine !== nextProps.machine) {
			this.setState({ machine: nextProps.machine });
		}
		if (nextProps.user && this.props.user !== nextProps.user) {
			this.setState({ user: nextProps.user });
		}
	}

	componentDidMount() {
		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			this.fetchGroups();
			this.fetchUes();
		}
	}

	showTime(minutes) {
		if (minutes >= 60) {
			if (minutes % 60 == 0) {
				return minutes / 60 + " tim";
			} else {
				var mins = minutes % 60;
				var hours = (minutes - mins) / 60;
				return hours + " tim, " + mins + " min";
			}
		} else {
			return minutes + " min";
		}
	}

	fetchGroups() {
		API.get("/api/groups.json", { params: {} })
			.then((result) => {
				var groups = [];

				for (var i = 0; i < result.data.groups.length; i++) {
					groups.push({ value: result.data.groups[i].id + "", label: result.data.groups[i].name });
				}

				this.setState({ groups });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchUes() {
		API.get("/api/underentreprenorer.json", { params: {} })
			.then((result) => {
				var underentreprenorer = [];

				for (var i = 0; i < result.data.underentreprenorer.length; i++) {
					underentreprenorer.push({ value: result.data.underentreprenorer[i].account_id + "", label: result.data.underentreprenorer[i].name });
				}

				underentreprenorer.unshift({ value: "not_ue", label: "Inte UE" });

				this.setState({ underentreprenorer });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateParams(params) {
		this.setState({ params });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updatePricing(pricelistId, productId, quantity, value) {
		API.post("/api/pricelists/" + pricelistId + "/prices/products/" + productId + ".json", { quantity, price: value })
			.then((result) => {
				if (result.data.error) {
					return;
				}
				toastr.success("Price updated");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	handleAttest(selectedIds) {
		this.setState({ loadingAttest: true });

		API.post("/api/time_reports/attest.json", { ids: selectedIds, from: this.state.params.checkin_at_min, to: this.state.params.checkin_at_max })
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				toastr.success("Tids registreringar attesterade");
				this.refresh();
				this.indexTableRef?.clearSelection();
			})
			.finally(() => {
				this.setState({ loadingAttest: false });
			});
	}

	getViewTitle(view) {
		var items = [
			{ label: "Maskin", value: "machine" },
			{ label: "Arbetad tid", value: "project" },
			{ label: getTypeLabel("photo"), value: "photo" },
			{ label: getTypeLabel("divergence"), value: "divergence" },
			{ label: getTypeLabel("expense"), value: "expense" },
			{ label: getTypeLabel("sick"), value: "sick" },
			{ label: getTypeLabel("vab"), value: "vab" },
			{ label: getTypeLabel("national_day"), value: "national_day" },
			{ label: getTypeLabel("shorttime"), value: "shorttime" },
			{ label: getTypeLabel("parenting"), value: "parenting" },
			{ label: getTypeLabel("vacation"), value: "vacation" },
			{ label: getTypeLabel("service_free"), value: "service_free" },
			{ label: getTypeLabel("leave_of_abscence"), value: "leave_of_abscence" },
			{ label: getTypeLabel("birth_free"), value: "birth_free" },
			{ label: getTypeLabel("comp_free"), value: "comp_free" },
			{ label: getTypeLabel("weekend_salary"), value: "weekend_salary" },
		];

		for (var i = 0; i < items.length; i++) {
			if (items[i].value == view) {
				return items[i].label;
			}
		}
	}

	getLocationName(item) {
		if (item.type == "project") {
			return <div>{item.work_order ? item.work_order.title : null}</div>;
		} else {
			return (
				<div>
					<Badge status="warning">{this.getViewTitle(item.type)}</Badge>
				</div>
			);
		}
	}

	exportExcel() {
		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			var params = Object.assign(this.state.params, {});
			if (this.state.project) {
				params.project_id = this.state.project.id;
			}
			if (this.state.work_order) {
				params.work_order_id = this.state.work_order.id;
			}
			if (this.state.machine) {
				params.machine_id = this.state.machine.id;
			}
			window.open("/api/time_reports/export.xls?" + httpBuildQuery(params));
		} else {
			toastr.error("Du är inte behörig att exportera till excel");
		}
	}

	async handleOpenInvoiceStatusModal() {
		this.setState({ invoiceModalIsOpen: true });
	}

	handleOpenTimeReportModal() {
		this.setState({ timeReportModalIsOpen: true });
	}

	handleCloseTimeReportModal() {
		this.setState({ timeReportModalIsOpen: false });
	}

	handleBulkRemove(ids) {
		this.setState({ loadingRemoving: true });

		API.delete("/api/time_reports/delete.json", { data: { ids } })
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				toastr.success(`${ids?.length} Tidregistreringar borttagna`);
				const refreshEvent = new CustomEvent("refreshProjectStats");
				window.dispatchEvent(refreshEvent);
				this.refresh();
				this.indexTableRef?.clearSelection();
			})
			.finally(() => {
				this.setState({ loadingRemoving: false });
			});
	}

	handleOpenExportFortnoxModal() {
		this.setState({ exportFortnoxModalLoading: true });

		API.get("/api/time_reports.json", {
			params: { ...this.state.params, limit: 1, attest: true, user_has_employee_number: true, is_exported: false },
		})
			.then((result) => {
				this.setState({ exportFortnoxModalLoading: false });
				this.setState({ timeReportsAvailableToExport: result.data.count });
				this.setState({ showExportFortnoxModal: true });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	handleExportToFortnox() {
		this.setState({ exportFortnoxLoading: true });

		API.get("/api/time_reports/export.json", {
			params: { ...this.state.params, limit: 999999999, attest: true, user_has_employee_number: true, is_exported: false },
		})
			.then((result) => {
				const failureCount = result.data.failure_count;
				this.setState({ exportFortnoxLoading: false });

				if (!failureCount) {
					this.setState({ showExportFortnoxModal: false });
					toastr.success(
						`Exporterade ${this.state.timeReportsAvailableToExport - result.data.failure_count} av ${
							this.state.timeReportsAvailableToExport
						} till Fortnox lön.`
					);
				} else {
					this.setState({ exportFortnoxFailureCount: failureCount });
					toastr.error(
						`Exporterade ${this.state.timeReportsAvailableToExport - result.data.failure_count} av ${
							this.state.timeReportsAvailableToExport
						} till Fortnox lön.`
					);
				}

				this.refresh();
			})
			.catch((error) => {
				this.setState({ exportFortnoxLoading: false });
				toastr.error(error);
			});
	}

	render() {
		const promotedBulkActions = this.props.titleHidden
			? []
			: [
					{
						content: "Attestera tider",
						loading: this.state.loadingAttest,
						onAction: this.handleAttest.bind(this),
					},
					{
						content: "Ändra faktureringsstatus",
						loading: this.state.loadingInvoicing,
						onAction: this.handleOpenInvoiceStatusModal.bind(this),
					},
					{
						content: `Ta bort`,
						loading: this.state.loadingRemoving,
						onAction: () => {
							this.setState({ showRemoveModal: true });
						},
						destructive: true,
					},
			  ];
		const columns = [
			{
				handle: "CHECKIN_AT",
				label: "Datum",
				sortable: true,
				type: "text",
				render: (item) => {
					return (
						<Stack>
							<Moment format="YYYY-MM-DD">{item.checkin_at}</Moment>

							{item.attest && <Badge status={item.is_exported ? "info" : "success"}>{item.is_exported ? "Exporterad" : "Attesterad"}</Badge>}
						</Stack>
					);
				},
			},
			{
				handle: "PROJECT",
				label: "Projekt",
				type: "text",

				render: (item) => {
					if (item.work_order) return <div>{item.work_order ? `${item.work_order.project.title} (${item.work_order.project.reference})` : "-"}</div>;

					if (item.type) {
						return <Badge status="critical">{getTypeLabel(item.type)}</Badge>;
					}

					return "-";
				},
			},
			{
				handle: "WORK_ORDER",
				label: "Arbetsorder",
				type: "text",
				render: (item) => {
					if (item.work_order) return <div>{item.work_order ? item.work_order.reference : "-"}</div>;

					if (item.type) {
						return <Badge status="critical">{getTypeLabel(item.type)}</Badge>;
					}

					return "-";
				},
			},
			/*
			{
				handle: "AREA",
				label: "Arbetsorder",
				sortable: true,
				type: "text",
				render: (item)=>{
	return (
				<div
					style={{
						maxWidth: 250,
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
				>
					{this.getLocationName(item)}
				</div>
			);
				}
			},
			*/
			{
				handle: "USER",
				label: "Användare",
				sortable: true,
				type: "text",
				render: (item) => {
					return (
						<div>
							{item.user?.name} {item.user?.ue ? <Badge status="attention">{item.user?.company}</Badge> : null}
						</div>
					);
				},
			},
			{
				handle: "TOTAL_MINUTES",
				label: "Tid",
				sortable: true,
				type: "text",
				render: (item) => {
					return <div>{item.checkout_at ? this.showTime(item.total_minutes) : <Badge status="attention">incheckad</Badge>}</div>;
				},
			},
			{
				handle: "WORK_TYPE",
				label: "Arbetstyp",
				type: "text",
				render: (item) => {
					if (item.work_type) {
						return <div>{item.work_type ? item.work_type.title + "(" + item.work_type.code + ")" : "-"}</div>;
					}
					if (item.type) {
						return <Badge status="critical">{getTypeLabel(item.type)}</Badge>;
					}
					return "-";
				},
			},
			getInvoicedStatusColumn(),
		];

		let filters = [];

		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || store.getState().user.roles.indexOf("ROLE_MANAGER") >= 0) {
			filters = [
				{
					key: "attest",
					label: "Attesterad",
					type: "select",
					options: [
						{ label: "Ja", value: "1" },
						{ label: "Nej", value: "0" },
					],
				},
				{
					key: "is_exported",
					label: "Exporterad till Fortnox lön",
					type: "select",
					options: [
						{ label: "Ja", value: "1" },
						{ label: "Nej", value: "0" },
					],
				},
				{
					key: "project_id",
					label: "Projekt",
					type: "project",
					shortcut: true,
					pinned: true,
				},
				{
					key: "invoiced",
					label: "Är fakturerad",
					type: "boolean",
				},
				{
					key: "should_be_invoiced",
					label: "Ska faktureras",
					type: "boolean",
				},
				{
					key: "group_id",
					label: "Grupp",
					type: "select",
					options: this.state.groups,
				},
				{
					key: "group_id",
					label: "Grupp",
					type: "select",
					options: this.state.groups,
				},
				{
					key: "user_id",
					label: "Användare",
					type: "users",
					pinned: true,
					shortcut: true,
				},
				{
					key: "ue_id",
					label: "Underentreprenör",
					type: "select",
					options: this.state.underentreprenorer,
					shortcut: true,
				},
				{
					key: "checkin_at_min",
					label: "Start datum",
					type: "date",
					pinned: true,
				},
				{
					key: "checkin_at_max",
					label: "Slut datum",
					type: "date",
					pinned: true,
				},
				{
					key: "checkin_at",
					label: "Datum",
					minKey: "checkin_at_min",
					maxKey: "checkin_at_max",
					dateOptionType: "past",
					type: "dateSelector",
				},
			];
		}

		return (
			<div>
				<IndexTable
					// stats={[
					// 	{
					// 		title: this.state.totalMinutes ? this.showTime(this.state.totalMinutes) : "–",
					// 		text: "Totalt antal timmar:",
					// 		loading: this.state.loadingReports,
					// 		color: Colors.green,
					// 		icon: <ClockIcon />,
					// 	},
					// 	{
					// 		text: "Totalt antal OB:",
					// 		title: this.state.totalObMinutes ? this.showTime(this.state.totalObMinutes) : "–",
					// 		loading: this.state.loadingReports,
					// 		color: Colors.orange,
					// 		icon: <ClockIcon />,
					// 	},
					// ]}
					selectOnRow={this.props.selectOnRow}
					extraHeader={this.props.extraHeader}
					onSelectionChangeInclRow={this.props.onSelectionChangeInclRow}
					titleHidden={this.props.titleHidden}
					backAction={this.props.backAction}
					ref={(ref) => {
						this.indexTableRef = ref;
					}}
					onClickRow={(item) => {
						if (this.props.onClickRow) {
							this.props.onClickRow(item);
							return;
						}
						if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 || !store.getState().account.disable_editing_group_leader) {
							this.props.history.push("/admin/time_reports/" + item.id);
						}
					}}
					primaryAction={{
						content: "Ny tidregistrering",
						onAction: this.handleOpenTimeReportModal.bind(this),
					}}
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Registrerade tider"
					savedSearchHandle="time_reports"
					resourceUrl="/api/time_reports.json"
					params={{
						...(this.props.fixedParams || {}),
						project_id: this.state.project && this.state.project.id,
						work_order_id: this.state.work_order && this.state.work_order.id,
						user_id: this.state.user_id || (this.state.user && this.state.user.id),
						type: this.props.type,
						types: this.props.types,
					}}
					resourceHandle="time_reports"
					filters={filters}
					resourceName={{
						singular: "registrering",
						plural: "registreringar",
					}}
					secondaryActions={
						store.getState().user.read_only
							? []
							: [
									{
										icon: this.state.exportFortnoxModalLoading ? <Spinner size="small" /> : ExportMinor,
										content: "Exportera till Fortnox lön",
										onAction: this.handleOpenExportFortnoxModal.bind(this),
										enabled: store.getState().account.fortnox_connection,
									},
									{ icon: ExportMinor, content: "Exportera till excel", onAction: this.exportExcel.bind(this) },
							  ].filter((i) => {
									return !("enabled" in i) || i.enabled;
							  })
					}
					onUpdateParams={this.updateParams.bind(this)}
					columns={columns}
					promotedBulkActions={promotedBulkActions}
					// defaultFilters={Object.entries(this.props.fixedParams || {})?.map(([key, value]) => ({
					// 	key,
					// 	value,
					// }))}
					defaultSavedSearches={[
						{
							title: "Ej attesterade",
							filters: [
								{
									key: "attest",
									value: 0,
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_unattested",
						},
					]}
					onResultFetched={this.props.onResultFetched}
				/>

				<TimeReportModal
					open={this.state.timeReportModalIsOpen}
					onClose={this.handleCloseTimeReportModal.bind(this)}
					onComplete={(item) => {
						this.handleCloseTimeReportModal();
						this.refresh();
					}}
					user_id={store.getState().user.id}
					user_name={store.getState().user.name}
					lock_user={store.getState().user.roles.indexOf("ROLE_USER_WEB") >= 0}
					project={this.props.project}
					work_order={this.props.project?.work_orders?.length === 1 && this.props.project.work_orders[0]}
				/>

				<Modal
					sectioned
					title={`Ta bort ${this.indexTableRef?.getSelection()?.length}st registreringar`}
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false, exportFortnoxFailureCount: 0 });
					}}
					primaryAction={{
						content: "Ta bort",
						onAction: () => {
							this.handleBulkRemove(this.indexTableRef?.getSelection());
							this.setState({ showRemoveModal: false });
						},
						loading: this.state.loadingRemoving,
					}}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: () => {
								this.setState({ showExportFortnoxModal: false });
							},
						},
					]}
				>
					<p>Säker du vill ta bort {this.indexTableRef?.getSelection().length}st registreringar?</p>
				</Modal>

				<Modal
					title="Exportera till Fortnox"
					loading={this.state.exportFortnoxLoading}
					open={this.state.showExportFortnoxModal}
					onClose={() => {
						this.setState({ showExportFortnoxModal: false, exportFortnoxFailureCount: 0 });
					}}
					primaryAction={
						this.state.timeReportsAvailableToExport && !this.state.exportFortnoxFailureCount
							? {
									content: `Exportera (${this.state.timeReportsAvailableToExport} st) till Fortnox lön`,

									onAction: this.handleExportToFortnox.bind(this),
									loading: this.state.loadingRemoving,
							  }
							: null
					}
					secondaryActions={[
						{
							content: "Avbryt",
							onAction: () => {
								this.setState({ showExportFortnoxModal: false });
							},
						},
					]}
				>
					{this.state.exportFortnoxFailureCount ? (
						<Modal.Section>
							<Badge fontSize={14} status="critical">
								{this.state.exportFortnoxFailureCount} av {this.state.timeReportsAvailableToExport} tidrapporter kunde inte exporteras.
							</Badge>
							<br />
							<br />
							<p>
								Vänligen se till att din Fortnox är kopplad, anställningsnummret för användarna matchar anställnings id i Fortnox och att det finns en
								löneperiod i Fortnox för tillfället då tidrapporten registrerats.
							</p>
						</Modal.Section>
					) : (
						<Modal.Section>
							{this.state.timeReportsAvailableToExport ? (
								<p>
									Är du säker på att du vill exportera till Fortnox? Med dessa filter kommer du{" "}
									<b>
										exportera {this.state.timeReportsAvailableToExport} tidrapport{this.state.timeReportsAvailableToExport > 1 ? "er " : " "}
									</b>
									till Fortnox.
								</p>
							) : (
								<p>Med det filtret du har nu kunde vi inte hitta några tidrapporter som du kan exportera till Fortox.</p>
							)}
							<br />
							<p>
								Tänk på att för en tidrapport ska kunna exporteras måste användaren ha ett angivet anställningsnummer som matchar det i Fortnox,
								tidrapporten behöver också vara attesterad men inte exporterad sen tidigare.
							</p>
						</Modal.Section>
					)}
				</Modal>

				<BulkInvoiceModal
					type="time_reports"
					ids={this.indexTableRef?.getSelection()}
					open={this.state.invoiceModalIsOpen}
					onClose={() => {
						this.setState({ invoiceModalIsOpen: false });
					}}
					onSuccess={() => {
						this.setState({ invoiceModalIsOpen: false });
						this.refresh();
					}}
				/>
			</div>
		);
	}
}

export default TimeReportsIndex;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import httpBuildQuery from "http-build-query";
import {
	Page,
	Card,
	Button,
	FormLayout,
	TextField,
	Select,
	Stack,
	Checkbox,
	ResourceList,
	Modal,
	Badge,
	Tabs,
	Spinner,
	Tag,
	Layout,
	Text,
} from "@shopify/polaris";

import CardsInPageWrapper from "src/js/styledComponents/CardsInPageWrapper";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Banner from "src/js/components/banner";
import RemoveModal from "src/js/components/modal/RemoveModal.tsx";
import IndexTable from "src/js/components/IndexTable/IndexTable";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import PlanningEventModal from "../Planning/PlanningEventModal";
import SkeletonProvider from "../../components/skeleton_provider.js";
import ResourcePicker from "../../components/resource_picker.js";
import TimeLine from "../../components/TimeLine.js";
import TimeRangeModal from "../../components/TimeRangeModal.js";

import ExpenseReportsIndex from "../ExpenseReports/index.js";
import WorkOrderUploadSection from "./WorkOrderUploadSection.js";
import WorkOrderPhotoReportSection from "./WorkOrderPhotoReportSection.js";

import InvoiceCreateModal from "./InvoiceCreateModal.js";
import FortnoxOrderIndex from "../Orders/FortnoxOrderIndex.js";
import FortnoxInvoiceCreateModal from "./FortnoxInvoiceCreateModal.js";
import SearchField from "../../components/search_field.js";
import Map from "../../components/map.js";
import API from "../../API";
// import WorkOrderPurchaseInvoiceIndex from "../WorkOrderPurchaseInvoiceIndex/index.js";
import EconomyView from "./EconomyView";
import TimeReportsIndex from "../TimeReports/index.js";
import SupplierInvoicesIndex from "../SupplierInvoices";
import ProjectStatBoxes from "./ProjectStatBoxes";
import OfferIndexShared from "../OfferIndexShared";
import StatsBoxWrapper from "../../components/statsBoxes/StatsBoxWrapper.tsx";
import IconWithBackround from "src/js/components/IconWithBackround.js";
import DollarIcon from "src/js/icons/DollarIcon.js";
import Colors, { colorToRgba } from "src/js/Colors.js";
import ListIcon from "src/js/icons/List.js";
import { currencyFormatter } from "src/js/Utilities.js";
import ClockIcon from "src/js/icons/Clock.js";

const moment = extendMoment(Moment);

class ProjectEdit extends Component {
	constructor(props) {
		super(props);
		// this.fortnox_connection = store.getState().account.fortnox_connection;
		this.tabs = [
			{
				id: "overview",
				content: "Översikt",
				accessibilityLabel: "oversikt",
				panelID: "oversikt",
			},
			{
				id: "planning",
				content: "Planering",
				panelID: "planning",
			},
			{
				id: "work_orders",
				content: "Arbetsordrar",
				panelID: "work_orders",
			},
			{
				id: "timereports",
				content: "Tidrapporter",
				panelID: "tidrapporter",
			},
			{
				id: "expenses",
				content: "Utgifter",
				panelID: "utgifter",
			},
			{
				id: "quotes",
				content: "Offerter",
				panelID: "quotes",
			},
			{
				id: "economy",
				content: "Ekonomi",
				panelID: "economy",
				// enabled: this.fortnox_connection,
			},
			{
				id: "purchase",
				content: "Leverantörsfakturor",
				panelID: "purchase",
			},
			{
				id: "address",
				content: "Adress",
				panelID: "address",
			},
			{
				id: "documents",
				content: "Dokument & bilder",
				panelID: "documents",
			},
			{
				id: "logg",
				content: "Logg",
				panelID: "logg",
			},
		].filter((m) => !("enabled" in m) || m.enabled);
		const [first] = this.tabs;

		this.state = Object.assign(
			{
				planning_events: [],
				uploads: [],
				work_orders: [],
				mailTimeRangeOpen: false,
				loadingUeReports: false,
				mailListModalOpen: false,
				showRemoveModal: false,
				showRemoveAreaModal: false,
				openUpload: false,
				loadingPhotos: false,
				loadingStates: [],
				bilder: [],
				projectInvoices: {
					invoices: [],
					count: null,
					page: null,
					pages: null,
				},
				invoices: [],
				showRemoveEventModal: false,
				timeRangeModalOpen: false,
				invoiceModalOpen: false,
				id: props.match.params.id,
				saving: false,
				loading: true,
				showTimeReportModal: false,
				loadingReports: true,
				loadingComments: true,
				loadingUtgifter: true,
				utgifter: [],
				time_reports: [],
				comments: [],
				selectedTab: first,
				invoice: {
					invoice_date: moment().format("YYYY-MM-DD"),
				},
				form: { users: [], groups: [], underentreprenorer: [], public: true, id: props.match.params.id },
			},
			props.location?.state || {}
		);

		this.onHashChange = this.handleHashChange.bind(this);
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		this.fetchPhotos(this.state.id);
		this.fetchUploads(this.state.id);

		window.addEventListener("hashchange", this.onHashChange);

		this.onHashChange();
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
			this.fetchPhotos(props.match.params.id);
			this.fetchUploads(props.match.params.id);
		}
	}

	handleHashChange() {
		const hash = new URL(document.URL).hash.replace("#", "");
		// const tabIndex = this.tabs.findIndex((t) => t.id === hash);
		const tab = this.tabs.find((t) => t.id === hash);
		const [first] = this.tabs.map((i) => i.id);

		this.setState({ selectedTab: (tab && tab.id) || first }, () => {
			if (!tab) {
				const url = new URL(document.URL);
				url.hash = `${first}`;
				window.history.replaceState(null, null, url);
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	// showTime(minutes) {
	// 	if (minutes >= 60) {
	// 		if (minutes % 60 == 0) {
	// 			return minutes / 60 + " tim";
	// 		} else {
	// 			var mins = minutes % 60;
	// 			var hours = (minutes - mins) / 60;
	// 			return hours + " tim, " + mins + " min";
	// 		}
	// 	} else {
	// 		return minutes + " min";
	// 	}
	// }

	maila(fromDate, toDate) {
		this.setState({
			mailListModalOpen: true,
			mailTimeRangeOpen: false,
			mailFromDate: fromDate,
			mailToDate: toDate,
		});
	}

	fetchUploads(id) {
		this.setState({ loadingUploads: true });

		API.get(`/api/projects/${id}/uploads.json`)
			.then((result) => {
				if (result.data.error) {
					return;
				}

				this.setState({ uploads: result.data.uploads });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loadingUploads: false });
			});
	}

	sendEmail() {
		this.setState({ saving: true });

		API.post(
			"/api/projects/" + this.state.id + "/ledger_checkins/email.json",
			{ email: this.state.email },
			{
				params: {
					checkin_at_min: moment(this.state.mailFromDate).format("YYYY-MM-DD"),
					checkin_at_max: moment(this.state.mailToDate).format("YYYY-MM-DD"),
				},
			}
		)
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("E-mail har skickats till " + this.state.email);
				this.setState({ mailListModalOpen: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	fetchItem(id = this.state.id, silent = false) {
		this.setState((c) => ({ id, loading: silent ? c.loading : true }));
		API.get("/api/projects/" + id + ".json")
			.then((result) => {
				this.setState(
					{ loading: false, saving: false, form: { users: [], groups: [], underentreprenorer: [], public: true, ...result.data.project } },
					() => {
						this.fetchWorkOrders();
						this.fetchPlanningEvents();
					}
				);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchPlanningEvents() {
		API.get("/api/planning_events.json", { params: { project_id: this.state.id, include_hours: true } })
			.then((result) => {
				this.setState({
					planning_events: result.data.planningEvents,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchWorkOrders() {
		this.setState({ loadingWorkOrders: true });
		API.get("/api/work_orders.json", { params: { project_id: this.state.id, sort: "REFERENCE_ASC" } })
			.then((result) => {
				this.setState({
					loadingWorkOrders: false,
					work_orders: result.data.work_orders,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchPhotos(id) {
		this.setState({ loadingPhotos: true });
		API.get("/api/photo_reports.json", { params: { project_id: id } })
			.then((result) => {
				this.setState({ loadingPhotos: false, photo_reports: result.data.photo_reports || [] });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		if (
			field === "is_marked_as_should_invoice_time_reports" ||
			field === "is_marked_as_should_invoice_expense_reports" ||
			field === "is_marked_as_should_invoice_supplier_invoices"
		)
			formState[field] = !value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/projects/" + this.state.id + ".json", Object.assign(this.state.form, { photo_reports: this.state.photo_reports }))
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}

				if (result.data.project) {
					this.setState({
						form: { users: [], groups: [], underentreprenorer: [], public: true, ...result.data.project },
					});
					toastr.success("Projektet sparat");
				}

				const refreshEvent = new CustomEvent("refreshProjectStats");
				window.dispatchEvent(refreshEvent);

				this.fetchWorkOrders();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	changeCustomer(customer) {
		this.state.form.customer = customer;

		if (!this.state.form.hourly_rate) {
			this.state.form.hourly_rate = customer.hourly_rate;
		}
		if (!this.state.form.hourly_ob_rate) {
			this.state.form.hourly_ob_rate = customer.hourly_ob_rate;
		}
		if (!this.state.form.address) {
			this.state.latitude = customer.latitude;
			this.state.longitude = customer.longitude;
			this.state.form.address = customer.address;
			this.state.form.latitude = customer.latitude;
			this.state.form.longitude = customer.longitude;
		}
		this.setState({
			form: this.state.form,
		});
	}

	selectAddress(address) {
		this.state.form.address = address.formatted_address;
		this.state.form.latitude = address.geometry.location.lat;
		this.state.form.longitude = address.geometry.location.lng;
		this.setState({ form: this.state.form, latitude: this.state.form.latitude, longitude: this.state.form.longitude });
	}

	printReport() {
		this.setState({ timeRangeModalOpen: true });
	}

	createInvoice() {
		this.setState({ invoiceModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		const year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printRange(fromDate, toDate, extraReports, includeUE, show_user_cost) {
		this.closeTimeRangeModal();
		// var projectIds = this.state.selectedItems;

		var query = {
			ids: [this.state.form.id],
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
			show_user_cost,
			extras: extraReports,
		};

		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
	}

	renderPlanningEvent(item) {
		return (
			<ResourceList.Item
				onClick={() => {
					this.setState({ planningModalOpen: true, planningEvent: item });
				}}
			>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.user.name}</h3>
						{/* <span className="CustomerListItem__Location"></span> */}
					</div>
					<div className="CustomerListItem__Orders">
						<Badge>
							{moment(item.start_at).format("YYYY-MM-DD HH:mm")} -{" "}
							{moment(item.end_at).format(moment(item.start_at).isSame(moment(item.end_at), "date") ? "HH:mm" : "YYYY-MM-DD HH:mm")}
						</Badge>
					</div>
					<span className="CustomerListItem__TotalSpent">{item.hours}tim</span>
				</div>
			</ResourceList.Item>
		);
	}

	sendMessage() {
		this.setState({ sending: true });
		API.post("/api/projects/" + this.state.id + "/notify.json", { message: this.state.message })
			.then((result) => {
				this.setState({ sending: false, showSendMessageModal: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Meddelande skickat");
			})
			.catch((error) => {
				this.setState({ sending: false });
				toastr.error(error);
			});
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	// toFixed2(value = 0) {
	// 	return 123;

	// 	// console.debug("value:", value);
	// 	// if (!value && typeof value !== "number") return "-";
	// 	// return `${(value || 0).toFixed(2).replace(".00", "")}kr`;
	// }

	addUser(user) {
		var found = false;
		for (var i = 0; i < this.state.form.users?.length; i++) {
			if (this.state.form.users[i].id == user.id) {
				this.state.form.users.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.users.push(user);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	addUnderentreprenor(underentreprenor) {
		var found = false;
		for (var i = 0; i < this.state.form.underentreprenorer?.length; i++) {
			if (this.state.form.underentreprenorer[i].id == underentreprenor.id) {
				this.state.form.underentreprenorer.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.underentreprenorer.push(underentreprenor);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	addGroup(group) {
		var found = false;
		for (var i = 0; i < this.state.form.groups?.length; i++) {
			if (this.state.form.groups[i].id == group.id) {
				this.state.form.groups.splice(i, 1);
				found = true;
				break;
			}
		}
		if (!found) {
			this.state.form.groups.push(group);
		}
		this.setState({ form: this.state.form }, () => {
			if (this.props.onChange) this.props.onChange(this.state.form);
		});
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		const mine = (this.state.form && this.state.form.company_name) == store.getState().account.company;

		const tabs = this.tabs;

		// actions.push({content: <Stack spacing="extraTight"><Icon source="import" /><div>Ladda upp KML</div></Stack>, onAction: () => {this.setState({openKmlUpload:true})} });

		const last_work_order = (this.state.work_orders && this.state.work_orders[0]) || {};

		const fortnox_connection = store.getState().account.fortnox_connection;
		const fortnox_order_module = fortnox_connection && store.getState().account.fortnox_order_module;

		const secondaryActions = [
			{
				content: "Ta bort projekt",
				onAction: () => {
					this.setState({ showRemoveModal: true });
				},
				destructive: true,
				enabled: store.getState().user.roles.includes("ROLE_ADMIN") && !store.getState().user.read_only,
			},
			{
				content: "Skriv ut rapport",
				onAction: this.printReport.bind(this),
			},
			{
				content: "Skapa Offert",
				onAction: () =>
					this.props.history.push("/admin/quotes/new", {
						project: this.state.form,
					}),
			},
			{
				content: "Skapa ÄTA",
				onAction: () => this.props.history.push("/admin/quotes/new", { variation: true, project: this.state.form }),
			},
		].filter((i) => !("enabled" in i) || i.enabled);
		// if (fortnox_connection) {
		secondaryActions.push({
			content: "Skapa Faktura",
			onAction: this.createInvoice.bind(this),
		});
		// }

		const convertMinutesToHours = (minutes) => {
			if (typeof minutes !== "number") return "";
			const hours = Math.floor(minutes / 60);
			const minutesLeft = minutes % 60;
			return `${hours}h ${minutesLeft}m`;
		};

		return (
			<Page
				fullWidth
				title={this.state.form.title}
				backAction={{ content: "Tillbaka", onAction: () => this.props.history.goBack() }}
				primaryAction={
					store.getState().user.read_only
						? null
						: {
								content: "Spara",
								disabled: !mine,
								loading: this.state.saving,
								onAction: this.saveForm.bind(this),
						  }
				}
				secondaryActions={secondaryActions}
			>
				<ProjectStatBoxes project={this.state.form} id={this.state.id} />
				<Tabs
					tabs={tabs}
					selected={tabs.findIndex((t) => t.id === this.state.selectedTab)}
					onSelect={(v) => {
						const tab = tabs[v];
						const hash = tab ? `#${tab.id}` : "";
						const url = new URL(document.URL);
						url.hash = `${hash}`;
						window.history.replaceState(null, null, url);

						this.setState({ selectedTab: tab.id });
					}}
				/>
				{this.state.selectedTab === "overview" ? (
					<CardsInPageWrapper margin>
						<Layout>
							<Layout.Section oneThird>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Projektinformation
								</Text>
								<Card sectioned>
									<FormLayout>
										<TextField
											disabled={!mine}
											label="Benämning"
											value={this.state.form.title}
											onChange={this.updateForm.bind(this, "title")}
											error={!this.state.form.title ? "Detta fält är obligatoriskt" : null}
											autoComplete="off"
											maxLength={100}
										/>
										<Checkbox
											checked={this.state.form.invoiceable}
											label="Klar för fakturering"
											onChange={(e) => {
												// eslint-disable-next-line no-unused-expressions
												this.state.form.invoiceable = e;
												this.setState({ form: this.state.form });
											}}
										/>
										<TextField label="Projektnummer" value={this.state.form.reference} onChange={this.updateForm.bind(this, "reference")} disabled />
										<Select
											label="Status"
											disabled={!mine}
											options={[
												{ label: "Vilande", value: "sleep" },
												{ label: "Kommande", value: "pending" },
												{ label: "Aktiv", value: "active" },
												{ label: "Färdig", value: "done" },
												{ label: "Fakturerad", value: "invoiced" },
											]}
											onChange={this.updateForm.bind(this, "status")}
											value={this.state.form.status}
										/>

										<TextField
											max="9999-12-31"
											type="date"
											label="Förväntat startdatum"
											value={this.state.form.start_at}
											onChange={this.updateForm.bind(this, "start_at")}
										/>
										<TextField
											max="9999-12-31"
											type="date"
											label="Förväntat slutdatum"
											value={this.state.form.end_at}
											onChange={this.updateForm.bind(this, "end_at")}
										/>
										<TextField
											type="number"
											label="Förväntat antal timmar"
											value={this.state.form.estimated_hours}
											onChange={this.updateForm.bind(this, "estimated_hours")}
										/>
										<TextField
											disabled={!mine}
											multiline={4}
											label="Beskrivning"
											value={this.state.form.description}
											onChange={this.updateForm.bind(this, "description")}
										/>

										<Checkbox
											label={!store.getState().account.ledger_linked ? "Stämpelklocka" : "Stämpelklocka & Personalliggare"}
											checked={this.state.form.checkin}
											onChange={(value) => {
												this.updateForm("checkin", value);
												if (store.getState().account.ledger_linked) {
													this.updateForm("personell_ledger", value);
												}
											}}
										/>
										{this.state.form.checkin && (
											<Checkbox
												label="Incheck.skärm"
												checked={this.state.form.checkin_screen}
												onChange={this.updateForm.bind(this, "checkin_screen")}
											/>
										)}
										{this.state.form.checkin && (
											<Checkbox
												label="Kontrollera GPS för incheckning"
												checked={this.state.form.checkin_require_gps}
												onChange={this.updateForm.bind(this, "checkin_require_gps")}
											/>
										)}
										{this.state.form.checkin && (
											<Checkbox
												label="Kontrollera GPS för utcheckning"
												checked={this.state.form.checkout_require_gps}
												onChange={this.updateForm.bind(this, "checkout_require_gps")}
											/>
										)}

										{store.getState().account.has_personell_ledger && (
											<React.Fragment>
												{!store.getState().account.ledger_linked ? (
													<Checkbox
														label="Projektet har personalliggare"
														checked={this.state.form.personell_ledger}
														onChange={this.updateForm.bind(this, "personell_ledger")}
													/>
												) : this.state.form.checkin ? null : (
													<div>Incheckning är avaktiverad</div>
												)}
												{this.state.form.personell_ledger && (
													<div>
														<TextField
															label="Skatteverkets identifikationsnr"
															value={this.state.form.personell_ledger_id}
															onChange={this.updateForm.bind(this, "personell_ledger_id")}
															maxLength={13}
														/>
														<Button plain onClick={() => this.setState({ mailTimeRangeOpen: true })}>
															Maila rapport
														</Button>
													</div>
												)}
											</React.Fragment>
										)}

										{this.state.form.created_by ? <small>Projektet skapades av: {this.state.form.created_by.name}</small> : null}
									</FormLayout>
								</Card>
							</Layout.Section>
							<Layout.Section oneThird>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Kund
								</Text>
								<Card sectioned>
									<FormLayout>
										<ResourcePicker
											placeholder="Sök kund"
											caption="Välj kund"
											resourceName={{
												singular: "kund",
												plural: "kunder",
											}}
											resource="customers"
											item={this.state.form.customer}
											onChange={this.changeCustomer.bind(this)}
											label_handle="name"
										/>
									</FormLayout>
								</Card>
								<Card sectioned title="Underentreprenörer">
									<FormLayout>
										<SearchField
											resource="underentreprenorer"
											resourceName={{
												singular: "underentreprenör",
												plural: "underentreprenörer",
											}}
											label_handle="name"
											onSelect={this.addUnderentreprenor.bind(this)}
										/>
									</FormLayout>
									{this.state.form.underentreprenorer.length > 0 ? (
										<Card.Section>
											<Stack>
												{this.state.form.underentreprenorer.map((item, index) => (
													<Tag key={item.id || index} onRemove={this.addUnderentreprenor.bind(this, item)}>
														{item.name}
													</Tag>
												))}
											</Stack>
										</Card.Section>
									) : null}
								</Card>
							</Layout.Section>
							<Layout.Section oneThird>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Faktureringsinställningar
								</Text>
								<Card sectioned>
									<FormLayout>
										<TextField
											type="number"
											placeholder={
												!this.state.form.is_marked_as_should_invoice_time_reports ? "Gäller endast om tidrapporter ska vidarefaktureras" : ""
											}
											label="Timpris"
											value={!this.state.form.is_marked_as_should_invoice_time_reports ? "0" : this.state.form.hourly_rate ?? "0"}
											onChange={this.updateForm.bind(this, "hourly_rate")}
											disabled={!this.state.form.is_marked_as_should_invoice_time_reports}
											suffix="SEK"
										/>
										<TextField
											type="number"
											placeholder={
												!this.state.form.is_marked_as_should_invoice_expense_reports && !this.state.form.is_marked_as_should_invoice_supplier_invoices
													? "Gäller vid vidarefakturering"
													: ""
											}
											label={
												"Påslag " +
												(this.state.form.is_marked_as_should_invoice_supplier_invoices ? "inköp " : "") +
												(this.state.form.is_marked_as_should_invoice_supplier_invoices && this.state.form.is_marked_as_should_invoice_expense_reports
													? "och "
													: "") +
												(this.state.form.is_marked_as_should_invoice_expense_reports ? "utgifter " : "") +
												"(%)"
											}
											value={
												!this.state.form.is_marked_as_should_invoice_expense_reports && !this.state.form.is_marked_as_should_invoice_supplier_invoices
													? ""
													: this.state.form.purcharge_price_surcharge ?? "0"
											}
											onChange={this.updateForm.bind(this, "purcharge_price_surcharge")}
											disabled={
												!this.state.form.is_marked_as_should_invoice_expense_reports && !this.state.form.is_marked_as_should_invoice_supplier_invoices
											}
											suffix="%"
											max="100"
										/>
										<Checkbox
											checked={!this.state.form.is_marked_as_should_invoice_time_reports}
											onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_time_reports")}
											label="Tidrapporter ska ej faktureras"
										/>
										<Checkbox
											checked={!this.state.form.is_marked_as_should_invoice_expense_reports}
											onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_expense_reports")}
											label="Utgifter ska ej faktureras"
										/>
										<Checkbox
											checked={!this.state.form.is_marked_as_should_invoice_supplier_invoices}
											onChange={this.updateForm.bind(this, "is_marked_as_should_invoice_supplier_invoices")}
											label="Leverantörsfakturor ska ej vidarefaktureras"
										/>
									</FormLayout>
								</Card>
								{this.state.form.status != "quote" ? (
									<Card sectioned title="Arbetsordrar">
										<FormLayout>
											<Checkbox
												checked={this.state.form.public}
												onChange={this.updateForm.bind(this, "public")}
												label="Synligt för alla egna användare"
											/>
										</FormLayout>

										{!this.state.form.public ? (
											<Card.Section title="Användare">
												<SearchField
													resource="users"
													resourceName={{
														singular: "användare",
														plural: "användare",
													}}
													label_handle="name"
													onSelect={this.addUser.bind(this)}
												/>
											</Card.Section>
										) : null}

										{!this.state.form.public && this.state.form.users?.length > 0 ? (
											<Card.Section>
												<Stack>
													{this.state.form.users.map((item, index) => (
														<Tag key={item.id || index} onRemove={this.addUser.bind(this, item)}>
															{item.name}
														</Tag>
													))}
												</Stack>
											</Card.Section>
										) : null}

										{!this.state.form.public ? (
											<Card.Section title="Grupper">
												<SearchField
													resource="groups"
													resourceName={{
														singular: "grupp",
														plural: "grupper",
													}}
													label_handle="name"
													onSelect={this.addGroup.bind(this)}
												/>
											</Card.Section>
										) : null}
										{!this.state.form.public && this.state.form.groups?.length > 0 ? (
											<Card.Section>
												<Stack>
													{this.state.form.groups.map((item, index) => (
														<Tag key={item.id || index} onRemove={this.addGroup.bind(this, item)}>
															{item.name}
														</Tag>
													))}
												</Stack>
											</Card.Section>
										) : null}
									</Card>
								) : null}
							</Layout.Section>
						</Layout>
					</CardsInPageWrapper>
				) : null}
				{this.state.selectedTab === "timereports" && (
					<TimeReportsIndex
						extraHeader={
							<StatsBoxWrapper
								style={{ justifyContent: "flex-start" }}
								perRow="auto-fill"
								items={[
									{
										title: convertMinutesToHours(this.state.time_reports?.total_minutes ? this.state.time_reports?.total_minutes : 0),
										text: "Totalt antal timmar",
										icon: <IconWithBackround icon={<ClockIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
										helpText: "Totalt antal timmar för all tidrapporter i listan nedan.",
									},
								]}
							/>
						}
						project={this.state.form}
						history={this.props.history}
						onResultFetched={(value) => {
							this.setState({
								time_reports: {
									total_minutes: value.sum,
								},
							});
						}}
					/>
				)}
				{this.state.selectedTab === "work_orders" && (
					<IndexTable
						title="Arbetsordrar"
						history={this.props.history}
						items={this.state.work_orders}
						loading={this.state.loadingWorkOrders}
						onClickRow={(workorder) => {
							this.props.history.push("/admin/work_orders/" + workorder.id);
						}}
						resourceName={{
							singular: "Arbetsorder",
							plural: "Arbetsordrar",
						}}
						columns={[
							{
								label: "Title",
								render: (workOrder) => `#${workOrder.reference} (${workOrder.title})`,
							},
							{
								label: "Timmar",
								render: (workOrder) => `${workOrder.total_minutes / 60} tim`,
							},
						]}
						noHeader
						noPagination
						primaryAction={
							!store.getState().user.read_only && {
								content: "Skapa ny arbetsorder",
								onAction: () => {
									this.props.history.push("/admin/work_orders/new", {
										project: this.state.form,
										address: last_work_order.address,
										latitude: last_work_order.latitude,
										longitude: last_work_order.longitude,
									});
								},
							}
						}
					/>
				)}
				{this.state.selectedTab === "documents" && (
					<CardsInPageWrapper>
						<Layout>
							<Layout.Section>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Bilder
								</Text>
								<Card sectioned>
									{(this.state.loadingWorkOrders || this.state.loadingPhotos) && <Spinner />}
									{this.state.work_orders.map((item, index) => {
										const photos = this.state.photo_reports?.filter((photo) => (photo.work_order && photo.work_order.id) === item.id) || [];

										return (
											<WorkOrderPhotoReportSection
												workOrder={item}
												project={this.state.form}
												uploads={photos}
												key={index}
												onAddUpload={(upload) => {
													this.state.photo_reports.push(upload);
													this.setState({ photo_reports: this.state.photo_reports });
												}}
												onChange={(upload) => {
													const index = this.state.photo_reports.findIndex((up) => up.id === upload.id);
													this.state.photo_reports.splice(index, 1, upload);
													this.setState({ photo_reports: this.state.photo_reports });
												}}
												onRemove={(upload) => {
													this.setState({ photo_reports: this.state.photo_reports.filter((up) => up.id !== upload.id) });
												}}
											/>
										);
									})}
								</Card>
							</Layout.Section>

							<Layout.Section>
								<Text as="h3" variant="headingMd" fontWeight="bold">
									Dokument
								</Text>
								<Card sectioned>
									{(this.state.loadingWorkOrders || this.state.loadingUploads) && <Spinner />}
									{this.state.work_orders.map((item, index) => (
										<WorkOrderUploadSection
											hideTitle={this.state.work_orders?.length <= 1}
											workOrder={item}
											project={this.state.form}
											uploads={this.state.uploads.filter((upload) => upload.work_order.id === item.id)}
											key={index}
											onAddUpload={(upload) => {
												this.state.uploads.push(upload);
												this.setState({ uploads: this.state.uploads });
											}}
											onChange={(upload) => {
												const index = this.state.uploads.findIndex((up) => up.id === upload.id);
												this.state.uploads.splice(index, 1, upload);
												this.setState({ uploads: this.state.uploads });
											}}
											onRemove={(upload) => {
												this.setState({ uploads: this.state.uploads.filter((up) => up.id !== upload.id) });
											}}
										/>
									))}
								</Card>
							</Layout.Section>
						</Layout>
					</CardsInPageWrapper>
				)}
				{this.state.selectedTab === "economy" && <EconomyView project={this.state.form} history={this.props.history} />}
				{this.state.selectedTab === "expenses" && (
					<ExpenseReportsIndex
						extraHeader={
							<StatsBoxWrapper
								style={{ justifyContent: "flex-start" }}
								perRow="auto-fill"
								items={[
									{
										title: currencyFormatter({
											value: this.state.expenses?.total_amount_to_invoice ? this.state.expenses?.total_amount_to_invoice : 0,
										}),
										text: "Att vidarefakturera",
										icon: <IconWithBackround icon={<ListIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
										helpText: "Totalt av utgifter att vidarefakturera",
									},
									{
										title: currencyFormatter({ value: this.state.expenses?.total_cost ? this.state.expenses?.total_cost : 0 }),
										text: "Kostnader",
										icon: <IconWithBackround icon={<DollarIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
										helpText: "Totala kostnader för utgifter",
									},
								]}
							/>
						}
						onResultFetched={(value) => {
							this.setState({
								expenses: {
									total_cost: value.total_cost,
									total_amount_to_invoice: value.total_amount_to_invoice,
								},
							});
						}}
						project={this.state.form}
						history={this.props.history}
					/>
				)}
				{this.state.selectedTab === "purchase" && (
					<SupplierInvoicesIndex
						project={this.state.form}
						history={this.props.history}
						fixedParams={{
							project_id: this.state.form?.id,
						}}
					/>
				)}
				{/* {this.state.selectedTab === "purchase" && (
					<CardsInPageWrapper>
						<WorkOrderPurchaseInvoiceIndex
							project={this.state.form}
							history={this.props.history}
							onComplete={(workOrder) => {
								for (let i = 0; i < this.state.work_orders?.length; i++) {
									if (this.state.work_orders[i].id == workOrder.id) {
										this.state.work_orders[i] = workOrder;
										break;
									}
								}

								this.fetchItem(this.state.id, true);
							}}
						/>
					</CardsInPageWrapper>
				)} */}
				{this.state.selectedTab === "quotes" && <OfferIndexShared project={this.state.form} history={this.props.history} />}
				{this.state.selectedTab === "orders" && <FortnoxOrderIndex project={this.state.form} history={this.props.history} />}
				{this.state.selectedTab === "planning" && (
					<React.Fragment>
						<Banner
							dismissible
							style={{ marginTop: "1rem" }}
							title="Planeringen visas endast här"
							status="info"
							action={{
								content: "Gå till planering",
								onAction: () => {
									this.props.history.push("/admin/planning");
								},
							}}
						>
							<p>För att ändra eller skapa planering gör du detta via "planering" i vänstermenyn</p>
						</Banner>
						<Card title="Planering">
							<ResourceList
								resourceName={{
									singular: "faktura",
									plural: "fakturor",
								}}
								items={this.state.planning_events}
								renderItem={this.renderPlanningEvent.bind(this)}
							/>

							<Card.Section>{`Totalt: ${this.state.planning_events.reduce((acc, curr) => acc + curr.hours, 0)}tim`}</Card.Section>
						</Card>
					</React.Fragment>
				)}
				{this.state.selectedTab === "address" && (
					<Page fullWidth title="Adress">
						<SearchField
							label=""
							resource="google_address"
							resourceName={{
								singular: " adress",
								plural: " adresser",
							}}
							onSelect={this.selectAddress.bind(this)}
							onChange={this.updateForm.bind(this, "address")}
							value={this.state.form.address}
						/>

						{this.state.form && this.state.form.address && (
							<div>
								{this.state.form && (this.state.form.latitude || this.state.form.longitude) && (
									<Map latitude={this.state.form.latitude} longitude={this.state.form.longitude} />
								)}
							</div>
						)}

						<p>Vid ändring sätts denna adress på alla projektets arbetsordrar.</p>
					</Page>
				)}
				{this.state.selectedTab === "logg" ? (
					<TimeLine
						heading="Logg"
						handle={"project" + this.state.id}
						tasks={[]}
						opportunities={[]}
						object={this.state.form}
						resource="project"
						infotext="Det som skrivs här skickas som pushnotis till alla personer som har åtkomst till projektet. Konversationen sparas och blir tillgänglig i appen."
					/>
				) : null}
				<Modal
					title="Maila lista"
					open={this.state.mailListModalOpen}
					onClose={() => {
						this.setState({ mailListModalOpen: false });
					}}
					primaryAction={{
						content: "Skicka",
						onAction: () => {
							this.sendEmail();
						},
						loading: this.state.saving,
						disabled: !this.state.email,
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ mailListModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<TextField
							label="E-post"
							value={this.state.email}
							onChange={(value) => {
								this.setState({ email: value });
							}}
						/>
					</Modal.Section>
				</Modal>
				<TimeRangeModal
					open={this.state.mailTimeRangeOpen}
					onClose={() => this.setState({ mailTimeRangeOpen: false })}
					from={fromDate}
					to={toDate}
					onChange={this.maila.bind(this)}
				/>
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
				/>
				<Modal
					title="Skicka meddelande"
					open={this.state.showSendMessageModal}
					onClose={() => {
						this.setState({ showSendMessageModal: false });
					}}
					primaryAction={{
						content: "Skicka",
						loading: this.state.sending,
						disabled: !this.state.message,
						onAction: this.sendMessage.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ showSendMessageModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<TextField label="Meddelande" value={this.state.message} onChange={(value) => this.setState({ message: value })} />
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort projekt"
					url={"/api/projects/" + this.state.form.id + ".json"}
					type="projektet"
					history={this.props.history}
					name={this.state.form.title}
				/>
				<PlanningEventModal
					handleClose={() => {
						this.setState({ planningModalOpen: false });
					}}
					open={this.state.planningModalOpen}
					event={this.state.planningEvent}
					onComplete={() => {
						this.setState({ planningModalOpen: false });
						this.fetchPlanningEvents();
					}}
				/>
				{/* <EditModal
					open={this.state.work_order_edit_id}
					workOrder={JSON.parse(
						JSON.stringify(
							Object.assign(
								(this.state.work_orders || []).find((item) => {
									return item.id == this.state.work_order_edit_id;
								}) || {},
								{
									project: JSON.parse(JSON.stringify(this.state.form)) || {},
								}
							)
						)
					)}
					onSave={(form) => {
						const index = (this.state.work_orders || []).findIndex((item) => item.id == this.state.work_order_edit_id);
						this.state.work_orders[index] = form;
						this.setState({ form: this.state.form, work_order_edit_id: null });
					}}
					onClose={() => {
						this.setState({ work_order_edit_id: null });
					}}
				/> */}
				{/* Skall vara om kontot har ordermodul */}
				{fortnox_order_module ? (
					<FortnoxInvoiceCreateModal
						project={this.state.form}
						open={this.state.invoiceModalOpen}
						handleClose={() => this.setState({ invoiceModalOpen: false })}
						onComplete={() => {
							this.setState({ invoiceModalOpen: false });
							this.fetchItem(this.state.id);
						}}
					/>
				) : (
					<InvoiceCreateModal
						project={this.state.form}
						open={this.state.invoiceModalOpen}
						handleClose={() => this.setState({ invoiceModalOpen: false })}
						onComplete={(invoice) => {
							this.setState({ invoiceModalOpen: false });
							this.fetchItem(this.state.id, true);

							if (this.state.selectedTab !== "economy") {
								const url = new URL(document.URL);
								url.hash = `#economy`;
								window.history.replaceState(null, null, url);
								this.setState({ selectedTab: "economy" });
							}

							toastr.success(`Skapade faktura (fakrnr: ${invoice.id})`);
							if (this.invoiceIndexRef) this.invoiceIndexRef.refresh();
						}}
					/>
				)}
			</Page>
		);
	}
}

export default ProjectEdit;

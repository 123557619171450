/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import FortnoxOffer from "../index";
import getMutableOffer from "../../Utilities/getMutableOffer";

const FortnoxOfferCreate = (props) => {
	const defaultOffer = getMutableOffer(null);

	defaultOffer.project = props.project || props.location?.state?.project;
	defaultOffer.customer = props.customer || props.location?.state?.customer || defaultOffer.project?.customer;

	return <FortnoxOffer {...props} initialData={defaultOffer} />;
};
export default FortnoxOfferCreate;

import moment from "moment";
import React, { Component } from "react";

// This is not used anymore, this was used to create the twig
class QuotePDFSecondHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { reference, sent_at: sentAt, created_at: createdAt, address, customer } = this.props.quote || {};

		const data = [
			{
				label: "Vår referens:",
				value: reference && reference.name,
			},
			{
				label: "Datum: ",
				value: moment(sentAt || createdAt).format("YYYY-MM-DD HH:mm:ss"),
			},
			{
				label: "Kund: ",
				value: customer && customer.name,
			},
			{
				label: "Adress: ",
				value: address,
			},
		];

		return (
			<div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "2rem" }}>
				<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
					{data.map(({ label, value }, key) => {
						return (
							<span key={key} style={{ fontSize: "16px", lineHeight: "16px" }}>
								{label}
							</span>
						);
					})}
				</div>
				<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
					{data.map(({ label, value }, key) => {
						return (
							<span key={key} style={{ fontSize: "16px", lineHeight: "16px" }}>
								{value || "-"}
							</span>
						);
					})}
				</div>
			</div>
		);
	}
}
export default QuotePDFSecondHeader;

import React from "react";

const SunIcon = ({ color = "#ffffff" }) => (
	<svg width="23" height="23" viewBox="0 0 23 23" filltype="strokeFill" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.29703 6.65673L6.71703 5.24673L4.91703 3.45673L3.50703 4.86673L5.29703 6.65673ZM0.957031 10.9067H3.95703V12.9067H0.957031V10.9067ZM10.957 0.956726H12.957V3.90673H10.957V0.956726ZM18.617 6.66173L17.207 5.25473L18.997 3.46473L20.403 4.87473L18.617 6.66173ZM17.197 18.5667L18.987 20.3667L20.397 18.9567L18.597 17.1667L17.197 18.5667ZM19.957 10.9067H22.957V12.9067H19.957V10.9067ZM11.957 5.90673C8.64703 5.90673 5.95703 8.59673 5.95703 11.9067C5.95703 15.2167 8.64703 17.9067 11.957 17.9067C15.267 17.9067 17.957 15.2167 17.957 11.9067C17.957 8.59673 15.267 5.90673 11.957 5.90673ZM11.957 15.9067C9.74703 15.9067 7.95703 14.1167 7.95703 11.9067C7.95703 9.69673 9.74703 7.90673 11.957 7.90673C14.167 7.90673 15.957 9.69673 15.957 11.9067C15.957 14.1167 14.167 15.9067 11.957 15.9067ZM10.957 19.9067H12.957V22.8567H10.957V19.9067ZM3.50703 18.9467L4.91703 20.3567L6.70703 18.5567L5.29703 17.1467L3.50703 18.9467Z"
			fill={color}
		/>
	</svg>
);

export default SunIcon;

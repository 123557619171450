import React from "react";
import styled from "styled-components";
import Skeleton1Col from "src/js/components/skeleton_1col";
import API from "src/js/API";
import useQuery from "src/js/hooks/useQuery";
import UploadOfferView from "./offers";

const UploadView = ({ match }) => {
	const hash = match.params.hash;

	const queryKey = ["uploads", hash];
	const { data: upload, isFetching } = useQuery({
		queryFn: async () => {
			const res = await API.get(`/api/public/uploads/${hash}.json`);
			return res?.data?.upload;
		},
		enabled: !!hash,
		queryKey,
	});

	if (isFetching && !upload) {
		return (
			<LoadingWrapper>
				<Skeleton1Col />
			</LoadingWrapper>
		);
	}

	if (!upload) return null;
	if (upload.fortnox_offer_id) {
		return <UploadOfferView upload={upload} />;
	}

	return null;
};
export default UploadView;

const LoadingWrapper = styled.div`
	width: 50%;
	margin: auto;
	margin-top: 100px;
`;

import { Page, Spinner, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import TranslationRow from "./TranslationRow";
import { toastr } from "../../components/toastr";
import API from "../../API";

export default class CreateLanguage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				label: "",
				translations: {},
			},
		};
	}

	componentDidMount() {
		this.fetchLanguage();
	}

	fetchLanguage() {
		this.setState({ loading: true });
		API.get(`/api/languages/base.json`)
			.then((response) => {
				this.setState({
					form: Object.assign(this.state.form, {
						translations: Object.entries(response.data.language.translations).reduce((acc, curr) => {
							acc[curr[0]] = {
								translation: "",
								string: curr[1].string,
							};
							return acc;
						}, {}),
					}),
				});
			})
			.catch((err) => {
				toastr.error(err);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	updateTranslation(key, value) {
		this.state.form.translations[key].translation = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		if (!this.state.form.language) {
			toastr.warning("Du kan inte spara", "du måste fylla i namn");
			this.setState({ error: true });
			return;
		}
		if (Object.values(this.state.form.translations).some((value) => value == "" || null)) {
			toastr.warning("Du kan inte spara", "du måste fylla i alla värden");
			this.setState({ error: true });
			return;
		}
		this.setState({ saving: false });
		API.post(`/api/languages.json`, this.state.form)
			.then((response) => {
				this.props.history.push(`/admin/settings/languages/${response.data.language.id}`);
			})
			.catch((err) => {
				console.error(err);
				toastr.error(err);
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Page
				backAction={{ content: "Tillbaka", url: "/admin/settings/languages" }}
				title={
					<TextField
						placeholder="Språk"
						value={this.state.form.language}
						onChange={(value) => {
							this.state.form.language = value;
							this.setState({ form: this.state.form });
						}}
					/>
				}
				primaryAction={{
					content: "Spara",
					onAction: this.saveForm.bind(this),
					loading: this.state.loading || this.state.saving,
				}}
			>
				{this.state.loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center",
						}}
					>
						<Spinner />
					</div>
				) : (
					Object.entries(this.state.form.translations || {}).map(([key, values], index) => {
						return (
							<TranslationRow
								key={key}
								string={values.string}
								translation={values.translation}
								onChange={this.updateTranslation.bind(this, key)}
								disabled={this.state.saving || this.state.form.verified}
								error={(values.string == values.translation && !this.state.form.verified) || (!values.translation && this.state.error)}
							/>
						);
					})
				)}
			</Page>
		);
	}
}

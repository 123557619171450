/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const Truncate = ({ numberOfLines = 1, children, ...rest }) => {
	return (
		<Wrapper numberOfLines={numberOfLines} {...(rest || {})}>
			{children}
		</Wrapper>
	);
};
export default Truncate;

const Wrapper = styled.span<{ numberOfLines: number }>`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
	line-clamp: ${({ numberOfLines }) => numberOfLines};
	-webkit-box-orient: vertical;
`;

import { Checkbox, FormLayout, Icon, Select, Spinner, Modal } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { getTypeLabel } from "src/js/Utilities";
// import Modal from "src/js/components/modal";
import BringModalToFront from "src/js/components/BringModalToFront";
import ResourcePicker from "../../components/resource_picker";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import SearchField from "../../components/search_field";
import { store } from "../../store";
import API from "../../API";

const moment = extendMoment(Moment);

class PlanningEventModal extends Component {
	constructor(props) {
		super(props);
		this.defaultForm = {
			type: "project",
			users: [],
			start_at: moment().format("YYYY-MM-DD HH:mm"),
			end_at: moment().add(1, "hour").format("YYYY-MM-DD HH:mm"),
			title: "",
		};
		this.state = { form: { ...this.defaultForm, ...(props.event || {}) } };
		this.absenseOptions = store.getState().account.available_types.map((i) => {
			return { label: this.getAbsenseLabel(i), value: i };
		});
	}

	getAbsenseLabel(absense) {
		switch (absense) {
			case "photo":
				return "Fotografering";
			default:
				return getTypeLabel(absense);
		}
	}

	// used if all data is fetched from list endpoint
	// static getDerivedStateFromProps(props, prevState) {
	// 	if (props.open !== prevState.open && props.open) {
	// 		if (props.event && !props.event.users && props.event.user) {
	// 			props.event.users = [props.event.user];
	// 		}
	// 		return { form: props.event };
	// 	}

	// 	if (props.open !== prevState.open && !props.open) {
	// 		return { form: { type: "project" } };
	// 	}

	// 	return null;
	// }

	// used if only the nessesary fields are returned in the list view
	componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			if (props.open && props.event && (!props.event?.id || (props.event.id !== this.state.form?.id && props.event?.id !== this.props.event?.id))) {
				this.state.form = { ...this.defaultForm, ...(props.event || {}) };
				this.fetch(props.event.id);
			}

			if (!props.open) {
				this.setState({ form: { ...this.defaultForm } });
			}
		}
	}

	fetch(id) {
		if (this.state.loading || !id) return;
		this.setState({ loading: true });
		API.get(`/api/planning_events/${id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					return;
				}

				if (!result.data.planningEvent) {
					this.setState({ loading: false });
					this.props.handleClose();
					this.props.history.replace(`/admin/planning`);
					return;
				}

				if (result.data.planningEvent && !result.data.planningEvent.users && result.data.planningEvent.user) {
					result.data.planningEvent.users = [result.data.planningEvent.user];
				}

				this.setState({ originalUserId: result.data.planningEvent?.users?.[0]?.id, form: result.data.planningEvent, loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	componentDidMount() {
		if (!this.props.users && this.props.user) {
			this.props.event.users = [this.props.user];
		}
		this.setState({ form: this.props.event || this.defaultForm }, () => {
			if (this.props.event?.id) this.fetch(this.props.event.id);
		});
	}

	updateForm(field, value) {
		if (field === "start_at") {
			if (moment(value).isAfter(moment(this.state.form.end_at))) {
				const diff = moment(this.state.form.end_at).diff(moment(this.state.form.start_at), "minutes");
				this.state.form.end_at = moment(value).add(diff, "minutes").format("YYYY-MM-DD HH:mm");
			}
		}

		if (["start_at", "end_at"].includes(field)) {
			this.state.form[field] = moment(value).format("YYYY-MM-DD HH:mm");
		} else if (field === "type" && value === "absense") {
			this.state.form[field] = value;
			this.state.form.absense_type = this.absenseOptions[0].value;
			this.state.form.project = null;
		} else if (field === "type" && value === "project") {
			this.state.form[field] = value;
			this.state.form.absense_type = null;
		} else if (field === "type" && value === "comment") {
			this.state.form[field] = value;
			this.state.form.absense_type = null;
			this.state.form.project = null;
		} else {
			this.state.form[field] = value;
		}
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		this.state.form.title = this.getSetTitle();

		if (this.state.form.id) {
			var newUsers = this.state.form.users.filter((user) => user.id != this.state.originalUserId);

			if (newUsers.length > 0) {
				API.post("/api/planning_events.json", Object.assign({}, this.state.form, { users: newUsers }))
					.then((result) => {
						if (result.data.error) {
							this.setState({ saving: false });
							return;
						}

						this.setState({ saving: false });
						if (this.props.onComplete) this.props.onComplete();
					})
					.catch((error) => {
						this.setState({ saving: false });
						toastr.error(error);
					});
			}
			if (!this.state.form.users.find((user) => user.id == this.state.originalUserId)) {
				this.removeEvent();
				return;
			}

			API.put(`/api/planning_events/${this.state.form.id}.json`, this.state.form)
				.then((result) => {
					if (result.data.error) {
						this.setState({ saving: false });
						return;
					}

					this.setState({ form: result.data.planningEvent, saving: false });
					if (this.props.onComplete) this.props.onComplete();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/planning_events.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						this.setState({ saving: false });
						return;
					}

					this.setState({ saving: false });
					if (this.props.onComplete) this.props.onComplete();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	toggleUser(user) {
		var curIndex = (this.state.form.users || []).findIndex((usr) => usr.id == user.id);
		if (curIndex >= 0) {
			this.removeUser(curIndex);
		} else {
			this.addUser(user);
		}
	}

	addUser(user) {
		if (this.state.form.users) {
			this.state.form.users.push(user);
		} else {
			this.state.form.users = [user];
		}
		this.setState({ users: this.state.users });
	}

	removeUser(index) {
		this.state.form.users.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	getSetTitle() {
		if (!this.state.form) return null;

		if (this.state.form.title) return this.state.form.title;
		const type = (() => {
			switch (this.state.form.type) {
				case "project":
					return (
						this.state.form.type === "project" &&
						this.state.form.project &&
						`${this.state.form.project.reference ? `${this.state.form.project.reference}-` : ""}${this.state.form.project.title}`
					);
				case "absense":
					return this.getAbsenseLabel(this.state.form.absense_type);
				case "comment":
					return "Kommentar";
				default:
					return null;
			}
		})();

		return type || this.state.form.title;
	}

	removeEvent() {
		this.setState({ removing: true });
		API.delete(`/api/planning_events/${this.state.form.id}.json`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ removing: false });
					return;
				}
				this.setState({ removing: false });
				if (this.props.onComplete) this.props.onComplete();
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	openConfirm() {
		this.setState({ confirmIsOpen: true });
	}

	closeConfirm() {
		this.setState({ confirmIsOpen: false });
	}

	render() {
		const { handleClose, open } = this.props;
		const { saving } = this.state;
		// eslint-disable-next-line no-unused-vars
		const { date, comment, start_at: startAt, end_at, project, type, users, absense_type: absenseType, user } = this.state.form || {};
		if (!type && this.state.form) {
			this.state.form.type = "project";
		}
		const title = this.getSetTitle();

		const primaryAction = {
			content: "Spara",
			loading: saving,
			onAction: this.saveForm.bind(this),
			disabled: saving || !users || !users.length || !type || (type === "project" && !project),
		};

		const range = moment.range(moment(this.state.form.start_at), moment(this.state.form.end_at));
		const dateSpanHaveWeekend = [...new Set(Array.from(range.by("days")))].some((day) => day.isoWeekday() > 5);
		const isSameDay = moment(this.state.form?.start_at).isSame(this.state.form?.end_at, "day");

		return (
			<>
				<Modal
					title={this.state.loading ? <Spinner size="small" /> : `${title || `Ny planering ${date || startAt}`}`}
					open={open}
					onClose={handleClose}
					primaryAction={primaryAction}
					secondaryActions={[
						{
							content: "Ta bort",
							loading: this.state.removing,
							destructive: true,
							onAction: isSameDay ? this.removeEvent.bind(this) : this.openConfirm.bind(this),
							enabled: this.state.form?.id,
						},

						{ content: "Avbryt", onAction: handleClose },
					].filter((i) => !("enabled" in i) || i.enabled)}
				>
					<Modal.Section>
						<FormLayout>
							<CheckBoxWrapper>
								<Checkbox disabled={saving} label="Projekt" checked={type === "project"} onChange={() => this.updateForm("type", "project")} />
								<Checkbox disabled={saving} label="Frånvaro" checked={type === "absense"} onChange={() => this.updateForm("type", "absense")} />
								<Checkbox disabled={saving} label="Anteckning" checked={type === "comment"} onChange={() => this.updateForm("type", "comment")} />
							</CheckBoxWrapper>
							{type === "project" && (
								<ResourcePicker
									// required={type === "project"}
									placeholder="Sök projekt"
									caption="Välj projekt"
									resource="projects"
									label="Projekt"
									resourceName={{
										singular: "projekt",
										plural: "projekt",
									}}
									params={{ status: ["active", "pending"], limit: 10000 }}
									item={project}
									onChange={this.updateForm.bind(this, "project")}
									label_handle="label"
									textAlign="left"
									disabled={saving}
								/>
							)}
							{type === "absense" && (
								<Select
									label="Frånvaro typ"
									value={absenseType}
									options={this.absenseOptions}
									onChange={this.updateForm.bind(this, "absense_type")}
								/>
							)}
							<TextField disabled={saving} label="Titel" onChange={this.updateForm.bind(this, "title")} value={title} />
							<SearchField
								label="Användare"
								placeholder="Sök efter användare att lägga till"
								resourceName={{
									singular: "användare",
									plural: "användare",
								}}
								params={{ active: 1 }}
								resource="users"
								label_handle="name"
								onSelect={this.toggleUser.bind(this)}
							/>

							{users &&
								users.map((u, index) => {
									return (
										<User key={u.id || index}>
											{u.name}
											<span className="btn" onClick={this.removeUser.bind(this, index)}>
												<Icon source={CircleCancelMajor} />
											</span>
										</User>
									);
								})}

							<TextField
								disabled={saving}
								label="Start datum"
								type="datetime-local"
								max="9999-12-31T23:59"
								onChange={this.updateForm.bind(this, "start_at")}
								value={moment(startAt).format("YYYY-MM-DD HH:mm")}
								error={moment(this.state.form.start_at).isAfter(this.state.form.end_at) && "Felaktigt datum"}
							/>
							<TextField
								disabled={saving}
								label="Slut datum"
								type="datetime-local"
								max="9999-12-31T23:59"
								onChange={this.updateForm.bind(this, "end_at")}
								value={moment(end_at).format("YYYY-MM-DD HH:mm")}
								error={moment(this.state.form.end_at).isBefore(this.state.form.start_at) && "Felaktigt datum"}
							/>

							{dateSpanHaveWeekend && (
								<Checkbox
									label="Exkludera lördagar och söndagar"
									checked={this.state.form.exclude_weekend}
									onChange={this.updateForm.bind(this, "exclude_weekend")}
								/>
							)}

							<TextField disabled={saving} label="Kommentar" multiline onChange={this.updateForm.bind(this, "comment")} value={comment} />
						</FormLayout>
					</Modal.Section>
				</Modal>

				<Modal
					title={`Ta bort "${title}"`}
					open={this.state.confirmIsOpen}
					onClose={this.closeConfirm.bind(this)}
					secondaryActions={[
						{
							content: "Ta bort",
							loading: this.state.removing,
							destructive: true,
							onAction: this.removeEvent.bind(this),
							enabled: this.state.form?.id,
						},
						{
							content: "Stäng",
							onAction: this.closeConfirm.bind(this),
						},
					].filter((i) => !("enabled" in i) || i.enabled)}
				>
					<Modal.Section>
						<BringModalToFront />
						<p>Denan planering varar över flera dagar, säker du vill ta bort denna planering?</p>
						<p>
							<b>{this.state.form.start_at}</b> - <b>{this.state.form.end_at}</b>
						</p>
					</Modal.Section>
				</Modal>
			</>
		);
	}
}
export default PlanningEventModal;

const CheckBoxWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
const User = styled.div`
	position: relative;
	padding: 0.25rem 0.35rem;
	width: max-content;

	span.btn {
		position: absolute;
		right: 0;
		transform: translate(80%, 0%);
		top: 0;
		cursor: pointer;

		.Polaris-Icon {
			fill: grey;
			width: 1.25rem;
			height: 1.25rem;
			transition: width 250ms, height 250ms, fill 250ms;
		}

		&:hover {
			.Polaris-Icon {
				fill: rgb(200, 50, 50);
				width: 1.25rem;
				height: 1.25rem;
			}
		}
	}
`;

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import styled from "styled-components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Button from "src/js/components/Button";
import { CancelMajor, EmailMajor, ImportMinor, ReceiptMajor, SendMajor } from "@shopify/polaris-icons";
import { Spinner } from "@shopify/polaris";
import Page from "src/js/components/page";
import FortnoxOfferContext, { FortnoxOfferContextProvider } from "./FortnoxOfferContext";
import FortnoxPDFView from "../FortnoxPDFView";
import Header from "./Header";
import { downloadPDF } from "../Utilities";
import FortnoxOfferInfoView from "./FortnoxOfferInfoView";
import { store } from "src/js/store";
import { CheckmarkIcon } from "src/js/icons";
import { OfferUsersIndex } from "../../OfferUsers";

const FortnoxOffer = ({ history }) => {
	const {
		form,
		pdf,
		id,
		handleSave,
		isFetching,
		handleSetAsSentOffer,
		createInvoiceFromOffer,
		handleCancelOffer,
		handleSendOfferAsEmail,
		isSaving,
		handleSendOfferAsEmailForConfirmation,
		handleSetAsAccepted,
	} = useContext(FortnoxOfferContext);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "pdf",
					content: "PDF",
					render: <FortnoxPDFView history={history} pdf={pdf} loading={isFetching} />,
					enabled: !!id,
				},
				{
					id: "info",
					content: "Info",
					render: <FortnoxOfferInfoView history={history} />,
				},
				{
					id: "offer_users",
					content: "Skickad till",
					render: <OfferUsersIndex offerUsers={form?.offer_users} />,
					enabled: !!(id && form?.Sent),
				},
				// {
				// 	id: "contact",
				// 	content: t("fortnox.tabs.contact", "Kontakt"),
				// 	render: (
				// 		<FortnoxContactView contact={contact} customer={customer} history={history} type="offer" EmailInformation={offer?.EmailInformation} />
				// 	),
				// enabled: !!contact || !!customer,
				// },
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, id, pdf, isFetching, form?.offer_users, form?.Sent]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as FortnoxOfferType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);

	const sendActions = [
		{
			content: "Skicka som e-post",
			onAction: handleSendOfferAsEmail,
			icon: EmailMajor,
			enabled: !!form?.DocumentNumber && !form?.Cancelled,
		},
		{
			content: "Skicka som e-post för signering",
			onAction: handleSendOfferAsEmailForConfirmation,
			icon: EmailMajor,
			enabled: !!form?.DocumentNumber && !form?.Cancelled,
		},

		{
			content: "Markera som skickad",
			icon: SendMajor,
			onAction: handleSetAsSentOffer,
			enabled: !!(form?.DocumentNumber && !form?.Cancelled),
			confirmation: {
				title: "Markera som skickad",
				content: "Är du säker på att du vill markera offerten som skickad?",
				confirm: "Markera som skickad",
				cancel: "Avbryt",
			},
			disabled: form?.Sent,
		},
	].filter((a) => !("enabled" in a) || a.enabled);

	return (
		<Page
			fullWidth
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: form?.DocumentNumber
					? `Fortnox offert: ${form?.DocumentNumber || "-"}`
					: "Ny Fortnox offert"
			}
			history={history}
			primaryAction={
				<Button
					primary
					onClick={handleSaveForm}
					connectedDisclosure={
						sendActions.length > 0
							? {
									actions: sendActions,
							  }
							: undefined
					}
					loading={isSaving}
					disabled={!form || !!form?.OrderReference || !!form?.Cancelled}
				>
					{form?.DocumentNumber ? "Spara" : "Skapa"}
				</Button>
			}
			secondaryActions={[
				{
					content: "Ladda ner",
					onAction: () => downloadPDF(pdf, `fortnox_offer_${form?.DocumentNumber}.pdf`),
					disabled: !pdf,
					icon: ImportMinor,
					enabled: !!form?.DocumentNumber,
				},
				{
					content: "Markera som accepterad",
					onAction: handleSetAsAccepted,
					icon: CheckmarkIcon as any,
					enabled: !!form?.DocumentNumber && store.getState().user.roles.includes("ROLE_ADMIN"),
				},

				// This requires a scope, but which scope??
				{
					content: "Skapa faktura",
					onAction: createInvoiceFromOffer,
					icon: ReceiptMajor,
					enabled: !!(form?.DocumentNumber && !form?.OrderReference && !form?.Cancelled),
					confirmation: {
						title: "Skapa faktura",
						content: "Är du säker på att du vill skapa en faktura?",
						confirm: "Skapa faktura",
						cancel: "Avbryt",
					},
				},
				{
					title: "Skicka",
					icon: SendMajor,
					actions: sendActions,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled),
				},
				{
					content: "Makulera Offert",
					icon: CancelMajor,
					onAction: handleCancelOffer,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled),
					destructive: true,
					confirmation: {
						title: "Makulera Offert",
						content: "Är du säker på att du vill makulera offerten?",
						confirm: "Makulera Offert",
						cancel: "Avbryt",
					},
				},
			]
				.filter((a) => !("enabled" in a) || a.enabled)
				?.map((a) => ({ ...a, disabled: isSaving || a.disabled }))}
		>
			<Wrapper loading={isFetching}>
				<Header offer={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} history={history} />
				{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
			</Wrapper>
		</Page>
	);
};

export default (props) => {
	return (
		<FortnoxOfferContextProvider {...props}>
			<FortnoxOffer {...props} />
		</FortnoxOfferContextProvider>
	);
};

const Wrapper = styled.div<{ loading: boolean }>`
	transition: opacity 250ms;

	opacity: ${({ loading }) => (loading ? 0.5 : 1)};
	pointer-events: ${({ loading }) => (loading ? "none" : "all")};
`;

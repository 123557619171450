import { store } from "./store/index.js";

const translation = store.getState().translation;

function trans(key) {
	var found = key.split(".").reduce(getTrans, translation);
	if (found) {
		return found;
	}
	return key;
}
function getTrans(obj, key) {
	if (!obj) {
		return null;
	}
	if (key in obj) {
		return obj[key];
	}
	return null;
}

export default trans;

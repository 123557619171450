import { Component } from "react";
import { Card, Layout, Select, FormLayout, RadioButton, Page, TextField, Stack } from "@shopify/polaris";
import "moment-timezone";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import API from "../../API";

class InvoiceSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customer_default_type: store.getState().account.customer_default_type,
			invoice_default_paslag: store.getState().account.invoice_default_paslag,
			invoice_default_method: store.getState().account.invoice_default_method,
			invoice_default_terms: store.getState().account.invoice_default_terms,
		};
	}

	componentDidMount() {
		this.fetchDefaultArticle();
	}

	fetchDefaultArticle() {
		API.get("/api/expenses.json?q=ARB&sort=ID_ASC&limit=1")
			.then((result) => {
				if (result.data.expenses && result.data.expenses[0]) {
					this.setState({ originalPrice: result.data.expenses?.[0]?.price, article: result.data.expenses?.[0] });
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.article && this.state.article.price != this.state.originalPrice) {
			// Update article
			API.put("/api/expenses/" + this.state.article.id + ".json", Object.assign({}, this.state.article, { price: this.state.article.price }))
				.then((result) => {})
				.catch((error) => {
					toastr.error(error);
				});
		}
		API.put("/api/account.json", {
			customer_default_type: this.state.customer_default_type,
			invoice_default_paslag: this.state.invoice_default_paslag,
			invoice_default_method: this.state.invoice_default_method,
			invoice_default_terms: this.state.invoice_default_terms,
		})
			.then((result) => {
				store.dispatch({ type: "SET_ACCOUNT", account: result.data.account });
				toastr.success("Inställningar sparades");
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Page
				primaryAction={{ content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				title="Faktureringsinställningar"
				backAction={{ content: "Inställningar", url: "/admin/settings" }}
			>
				<Layout>
					<Layout.AnnotatedSection title="Kundinställningar" description="Nya kunder som skapas får detta förifyllt">
						<Card sectioned>
							<FormLayout>
								<TextField
									suffix="kr/tim"
									label="Timpris"
									value={this.state.article && this.state.article.price + ""}
									onChange={(value) => {
										this.state.article.price = value;
										this.setState({ article: this.state.article });
									}}
								/>
								<Stack>
									<RadioButton
										label="Företag"
										checked={this.state.customer_default_type === "company"}
										id="company"
										name="type"
										onChange={(v) => {
											this.setState({ customer_default_type: "company" });
										}}
									/>
									<RadioButton
										label="Privatperson"
										id="optional"
										name="type"
										checked={this.state.customer_default_type === "private_person"}
										onChange={(v) => {
											this.setState({ customer_default_type: "private_person" });
										}}
									/>
								</Stack>
								<TextField
									suffix="%"
									label="Påslag inköp"
									value={this.state.invoice_default_paslag + ""}
									onChange={(value) => {
										this.setState({ invoice_default_paslag: value });
									}}
								/>
								<Stack distribution="fillEvenly">
									<Select
										label="Faktureringsmetod"
										options={[
											{ label: "E-post", value: "epost" },
											{ label: "Post", value: "post" },
										]}
										value={this.state.invoice_default_method}
										onChange={(value) => {
											this.setState({ invoice_default_method: value });
										}}
									/>
									<TextField
										suffix="dagar"
										type="number"
										label="Betalningsvillkor"
										value={this.state.invoice_default_terms + ""}
										onChange={(value) => {
											this.setState({ invoice_default_terms: value });
										}}
									/>
								</Stack>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
			</Page>
		);
	}
}

export default InvoiceSettings;

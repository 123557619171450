import { Button, FormLayout, Icon, Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";
import API from "src/js/API";
import { toastr } from "../toastr";
import { SocialPostMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { getLocalStorage, setLocalStorage } from "src/js/Utilities";

type Form = {
	company: string;
	contact_person: string;
	phone: string;
	email: string;
};

const RecommendModal = () => {
	const defaultForm: Form = { company: "", contact_person: "", phone: "", email: "" };
	const [open, setOpen] = useState(!getLocalStorage("hasSeenRecommendation"));
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState<Form>(defaultForm);

	const handleOpen = () => {
		setForm(defaultForm);
		setOpen(true);
	};
	const handleClose = () => {
		setForm(defaultForm);
		setLocalStorage("hasSeenRecommendation", true);
		setOpen(false);
	};
	const changeHandler = (key: string) => (value: string) => {
		setForm((currentForm) => ({
			...currentForm,
			[key]: value,
		}));
	};

	const anyEmptyField = (form: Form) => {
		return Object.values(form).some((field) => !field);
	};

	const handleSend = () => {
		setLoading(true);

		API.post("/api/recommendations.json", form)
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				handleClose();
				toastr.success("Vi har tagit emot din rekommendation!");
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Button onClick={handleOpen}>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				<InnerWrapper>
					Sprid & spara
					<Icon source={SocialPostMajor} />
				</InnerWrapper>
			</Button>
			<Modal
				title="Hej där!"
				open={open}
				onClose={handleClose}
				secondaryActions={[
					{
						content: "Stäng",
						onAction: handleClose,
					},
				]}
				primaryAction={{
					content: "Rekommendera",
					onAction: handleSend,
					disabled: anyEmptyField(form),
					loading,
				}}
			>
				<Modal.Section>
					<p style={{ marginBottom: "40px" }}>
						Vill du spara pengar på ditt abonnemang? Sprid ordet om vårt system till dina vänner och få 20% rabatt på ditt eget abonnemang i 12
						månader för varje rekommenderad vän som blir kund. Rekrytera 5 vänner och få ditt abonnemang helt kostnadsfritt i ett år!
					</p>
					<FormLayout>
						<TextField requiredIndicator value={form.company} onChange={changeHandler("company")} autoComplete="false" label="Företagsnamn" />
						<TextField
							requiredIndicator
							value={form.contact_person}
							onChange={changeHandler("contact_person")}
							autoComplete="false"
							label="Kontaktperson"
						/>
						<TextField requiredIndicator value={form.phone} onChange={changeHandler("phone")} autoComplete="false" label="Telefonnummer" />
						<TextField requiredIndicator value={form.email} onChange={changeHandler("email")} autoComplete="false" label="E-postadress" />
					</FormLayout>
				</Modal.Section>
			</Modal>
		</>
	);
};

export default RecommendModal;

const InnerWrapper = styled.div`
	display: flex;
	gap: 8px;
	width: max-content;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
`;

import { Button, Card } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../Colors";

class ProjectDashboardRankList extends Component {
	constructor(props) {
		super(props);
		this.state = { height: "100%" };
		this.ref = React.createRef();
	}

	componentWillReceiveProps() {}

	componentDidMount() {
		// setTimeout(() => {
		// 	this.setState({ height: this.ref.current?.clientHeight || "unset" });
		// }, 1000);
	}

	render() {
		const { stats } = this.props;

		const projects =
			stats.timereports &&
			stats.timereports.reduce((acc, stat) => {
				return Object.assign(acc, {
					[stat.project_id]: {
						name: stat.title,
						total_minutes: ((acc[stat.project_id] && acc[stat.project_id].total_minutes) || 0) + stat.total_minutes * 1,
						color: stat.color,
						project_id: stat.project_id,
					},
				});
			}, {});

		const projectList = projects && Object.values(projects) && Object.values(projects);

		const total = (projectList && projectList.reduce((acc, stat) => acc + stat.total_minutes, 0)) || 0;
		const showmoreable = (projectList && projectList.length) > Math.floor((this.state.height || 0) / 30);

		return (
			<div
				className="list"
				ref={this.ref}
				//  style={{ height: this.state.height }}
			>
				<Card sectioned>
					<table className="project_list">
						<thead>
							<tr>
								<th>Projekt</th>
								<th>Timmar</th>
								<th>Procent</th>
								{/* <th></th> */}
							</tr>
						</thead>
						<tbody>
							{projectList &&
								projectList
									.sort((a, b) => b.total_minutes - a.total_minutes)
									.map(({ name, total_minutes: totalMinutes, color, project_id: projectId }) => {
										const percentage = ((totalMinutes / total || 0) * 100).toFixed();
										return (
											<StyledTr
												onClick={() => {
													this.props.history.push(`/admin/projects/${projectId}#timereports`);
												}}
												key={projectId}
												className="rank_item"
												color={color}
											>
												<td className="name">
													<div style={{ display: "flex", alignItems: "center", gap: 3 }}>
														<div style={{ background: color, height: 7, width: 20, borderRadius: 3 }} />
														{name}
													</div>
												</td>
												<td className="minutes">{((totalMinutes * 1) / 60).toFixed() * 1} tim</td>
												{/* <td className="progress"> <ProgressBar progress={percentage} /></td> */}
												<td className="percentage">{percentage}%</td>
											</StyledTr>
										);
									})}
						</tbody>
					</table>
					{showmoreable && (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								position: "absolute",
								bottom: 0,
								background: "var(--p-surface, white)",
							}}
						>
							<Button
								plain
								onClick={() => {
									this.setState({ height: "unset" });
								}}
							>
								Visa mer
							</Button>
						</div>
					)}
				</Card>
			</div>
		);
	}
}

const StyledTr = styled.tr`
	cursor: pointer;
	height: 30px;

	.name {
		transition: font-weight 250ms;
	}

	/* &:hover {
		.name {
			font-weight: bold;
		}
	} */

	td {
		padding: 0.5rem;
	}

	.minutes {
	}

	.progress {
		width: 200px;

		.Polaris-ProgressBar__Indicator {
			background-color: ${({ color }) => color || Colors.sidebar};
		}
	}
`;
export default ProjectDashboardRankList;

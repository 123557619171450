import { Button, Card, FormLayout, Layout, Page, Spinner, Stack, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { DeleteMajor } from "@shopify/polaris-icons";
import { toastr } from "../../components/toastr";
import API from "../../API";

export default class WorkTypeEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			saving: false,
			form: {
				id: this.props && this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null,
				title: null,
				agresso_code: null,
			},
		};
	}

	componentDidMount() {
		this.fetchWorkType();
	}

	fetchWorkType() {
		this.setState({ loading: true });
		if (this.state.form.id) {
			API.get(`/api/work_types/${this.state.form.id}.json`)
				.then((response) => {
					if (response.data.work_type) {
						this.setState({ loading: false, form: response.data.work_type });
					}
					if (response.data.error) {
						toastr.error(response.data.error);
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ loading: false });
				});
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put(`/api/work_types/${this.state.form.id}.json`, this.state.form)
			.then((response) => {
				if (response.data.error) {
					toastr.error(response.data.error);
					this.setState({ saving: false });
				}
				if (response.data.work_type) {
					this.setState({ form: response.data.work_type, saving: false });
					toastr.success("Arbetstypen skapades!");
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			})
			.then(() => {
				this.setState({ saving: false });
			});
	}

	remove() {
		this.setState({ removing: true });
		API.put(`/api/work_types/${this.state.form.id}.json`, { removed: true })
			.then((result) => {
				if (result.data.error) {
					this.setState({ removing: false });
					return;
				}
				this.setState({ removing: false });
				if (result.data.work_type) toastr.success("Arbetstyp borttagen");
				this.props.history.replace("/admin/settings/work-type");
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<Page
				title="Ny arbetstyp"
				backAction={{
					content: "Arbetstyper",
					url: "/admin/settings/work-type",
				}}
				primaryAction={{ content: "Spara arbetstyp", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					{this.state.loading ? (
						<Layout.Section>
							<Card sectioned>
								<Stack vertical distribution="fill" alignment="center">
									<Spinner />
								</Stack>
							</Card>
						</Layout.Section>
					) : (
						<Layout.Section>
							<Card sectioned>
								<FormLayout>
									<TextField label="Benämning" value={this.state.form.title} onChange={this.updateForm.bind(this, "title")} />
									<TextField label="kod" value={this.state.form.code} onChange={this.updateForm.bind(this, "code")} />
								</FormLayout>
							</Card>
							<div style={{ margin: "1rem 0" }}>
								<Button destructive onClick={this.remove.bind(this)} icon={DeleteMajor} loading={this.state.removing}>
									Ta bort
								</Button>
							</div>
						</Layout.Section>
					)}
				</Layout>
			</Page>
		);
	}
}

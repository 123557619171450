import React from "react";

const ClipboardIcon = ({ color = "var(--textColor)" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" filltype="stroke" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.5684 19.7676H20.5684" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M12.5684 15.7676H20.5684" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M20.5684 5.76758H25.5684C25.8336 5.76758 26.0879 5.87294 26.2755 6.06047C26.463 6.24801 26.5684 6.50236 26.5684 6.76758V27.7676C26.5684 28.0328 26.463 28.2871 26.2755 28.4747C26.0879 28.6622 25.8336 28.7676 25.5684 28.7676H7.56836C7.30314 28.7676 7.04879 28.6622 6.86125 28.4747C6.67372 28.2871 6.56836 28.0328 6.56836 27.7676V6.76758C6.56836 6.50236 6.67372 6.24801 6.86125 6.06047C7.04879 5.87294 7.30314 5.76758 7.56836 5.76758H12.5684"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.5684 9.76758V8.76758C11.5684 7.4415 12.0951 6.16973 13.0328 5.23204C13.9705 4.29436 15.2423 3.76758 16.5684 3.76758C17.8944 3.76758 19.1662 4.29436 20.1039 5.23204C21.0416 6.16973 21.5684 7.4415 21.5684 8.76758V9.76758H11.5684Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ClipboardIcon;

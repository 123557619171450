import { Icon } from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import EventRow from "./EventRow";
import { getHoliday } from "./Utilities";

class EventSlot extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	openEvent(event, e) {
		e.preventDefault();
		e.stopPropagation();
		this.props.onClick(event);
	}

	openNewEvent() {
		const startAt = (() => {
			if (moment(this.props.date).isSame(moment(), "day")) {
				return moment().format("YYYY-MM-DD HH:mm");
			}
			return moment(this.props.date).format("YYYY-MM-DD") + " 08:00";
		})();
		const endAt = (() => {
			if (moment(this.props.date).isSame(moment(), "day")) {
				return moment().add(1, "h").format("YYYY-MM-DD HH:mm");
			}
			return moment(this.props.date).format("YYYY-MM-DD") + " 16:00";
		})();

		this.props.onClick({
			start_at: startAt,
			end_at: endAt,
			users: [this.props.user],
			type: "project",
		});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getWidth() {
		const tableRow = this.ref && this.ref.current && this.ref.current.closest("tbody");
		if (tableRow) return tableRow.clientWidth / (this.props.view === "month" ? moment(this.props.date).daysInMonth() : 7);
	}

	render() {
		const { events, date, view, user } = this.props;
		const isWeekend = moment(date).day() % 6 == 0;
		const holiday = getHoliday(date);

		return (
			<Droppable droppableId={`${user.id}__${date}`}>
				{(provided) => (
					<Td
						// className={weekend && "weekend"}
						isWeekend={String(isWeekend)}
						isHoliday={String(!!holiday)}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...provided.droppableProps}
						ref={provided.innerRef}
						// style={{ width: this.getWidth() }}
					>
						<div ref={this.ref}>
							<Wrapper>
								{Array.isArray(events) &&
									events
										.filter((event) => {
											return moment(date).isoWeekday() <= 5 || !event.exclude_weekend;
										})
										.map((evt, index) => {
											return <EventRow view={view} index={index} key={evt.id} event={evt} onClick={this.openEvent.bind(this, evt)} />;
										})}
								{provided.placeholder}
							</Wrapper>
						</div>

						<AddWrapper className="add" onClick={this.openNewEvent.bind(this)}>
							<Icon source={CirclePlusMajor} />
						</AddWrapper>
					</Td>
				)}
			</Droppable>
		);
	}
}
export default EventSlot;

const AddWrapper = styled.div`
	display: flex;
	padding: 0.25rem;
	justify-content: center;
	align-items: center;
	transition: opacity 250ms;
	opacity: 0;
	height: 25px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: pointer;

	svg {
		fill: var(--color-primary);
	}

	&:hover {
		opacity: 1;
	}
`;

const Td = styled.td`
	vertical-align: top;
	position: relative;
	width: ${({ width }) => (width ? width + "px" : "unset")};
	&& {
		background-color: ${({ isWeekend, isHoliday }) => {
			if (isHoliday === "true" && isWeekend !== "true") return "#720E0E";

			return isWeekend == "true" ? "var(--primary)" : "var(--secondary)";
		}};
	}
	&:hover {
		${AddWrapper} {
			opacity: 1;
		}
	}
`;
const Wrapper = styled.div`
	cursor: pointer;
	padding: 0.25rem;
	padding-bottom: 25px;
`;

export default (state = {}, action) => {
	switch (action.type) {
		case "SET_ACCOUNT":
			return Object.assign({}, action.account);
		case "SET_WORKDAY_START":
			state.workday_start = action.workday_start;
			return state;
		case "SET_WORKDAY_END":
			state.workday_end = action.workday_end;
			return state;
		case "SET_WORKDAY_LUNCH":
			state.lunch = action.lunch;
			return state;
		case "SET_HAS_PERSONELL_LEDGER":
			state.has_personell_ledger = action.has_personell_ledger;
			return state;
		case "SET_HAS_UNDERENTREPRENORER":
			state.has_underentreprenorer = action.has_underentreprenorer;
			return state;
		case "SET_PROJECT_NUMBER_FORMAT":
			state.project_number_format = action.project_number_format;
			return state;
		case "SET_QUOTE_INTRO":
			state.quote_intro = action.quote_intro;
			return state;
		case "SET_ENABLE_SPECIAL_TIMES":
			state.enable_special_times = action.enable_special_times;
			return state;
		case "SET_VISMA_ENABLED":
			state.visma_enabled = action.visma_enabled;
			return state;
		case "SET_BRILJANT_ENABLED":
			state.briljant_enabled = action.briljant_enabled;
			return state;
		case "SET_RISK_ENABLED":
			state.risk_enabled = action.risk_enabled;
			return state;
		case "SET_RISK_NAME":
			state.risk_name = action.risk_name;
			return state;
		case "SET_AUTO_ACTIVATE_CHECKIN":
			state.auto_activate_checkin = action.auto_activate_checkin;
			return state;
		case "SET_ALLOW_CREATE_EXPENSES":
			state.allow_create_expenses = action.allow_create_expenses;
			return state;
		case "SET_ALLOW_ALL_COMPLETE_PROJECT":
			state.allow_all_complete_project = action.allow_all_complete_project;
			return state;
		case "SET_ALLOW_TIME_REPORTING_YESTERDAY":
			state.allow_time_reporting_yesterday = action.allow_time_reporting_yesterday;
			return state;
		case "SET_LEDGER_LINKED":
			state.ledger_linked = action.ledger_linked;
			return state;
		case "SET_DAYS_BACK":
			state.days_back = action.days_back;
			return state;
		case "SET_INPUT_TYPE":
			state.input_type = action.input_type;
			return state;
		case "SET_CHECKIN":
			state.checkin = action.checkin;
			return state;
		case "SET_CHECKIN_SCREEN":
			state.checkin_screen = action.checkin_screen;
			return state;
		case "SET_LOGO_URL":
			state.logo_url = action.logo_url;
			return state;
		case "SET_BACKGROUND_URL":
			state.background_url = action.background_url;
			return state;
		case "SET_HEADER_COLOR":
			state.header_color = action.header_color;
			return state;
		case "SET_SUBSCRIPTION":
			state.subscription = action.subscription;
			return state;
		case "SET_EXTRA_SETTINGS":
			state.extra_settings = action.extra_settings;
			return state;
		case "SET_AVAILABLE_TYPES":
			state.available_types = action.available_types;
			return state;
		case "SET_EXTRA_FIELDS":
			state.extra_fields = action.extra_fields;
			return state;
		case "SET_FORTNOX_ARTICLE":
			state.fortnox_article = action.fortnox_article;
			return state;
		default:
			return state;
	}
};

/* eslint-disable camelcase */
import { HorizontalStack, Icon } from "@shopify/polaris";
import { DeleteMajor, EditMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import Money from "../../components/Money";
import PopupMenu from "../../components/PopupMenu";
import Table from "../../components/Table";

export const calculateSubtotal = (row) => {
	if (!row || !row.count || !row.a_price) return 0;
	return row.count * row.a_price * ((100 - (row.discount || 0)) / 100);
};
export default class QuoteTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: props.rows || [],
		};
		this.renderRow = (row = {}, index) => {
			const { article, description, unit, count, a_price, rotrut, discount, comment } = row || {};
			return [
				<tr
					key={`row-${index}-content`}
					id={`row-${index}-content`}
					className={`Quote-Table-Row Quote-Table-Row__${comment ? "With-Comment" : "Standard"}`}
				>
					<td>{article}</td>
					<td>{description}</td>
					<td>{count || "-"}</td>
					<td>{unit || "-"}</td>
					<td>{a_price || "-"}</td>
					<td width="105">{discount || "-"}</td>
					<td>{rotrut ? rotrut.capitalize() : "-"}</td>
					<td>
						<div style={{ display: "flex" }} className={this.props.disabled && "disabled"}>
							<Money style={{ whiteSpace: "nowrap", flex: 1 }} amount={(row.subtotal || calculateSubtotal(row)) * 100} currency="SEK" />
							{row && typeof row === "object" && Object.keys(row).some((i) => i) && !this.props.disabled && (
								<PopupMenu
									disabled={props.disabled}
									items={[
										{
											content: (
												<HorizontalStack gap="2">
													<Icon source={EditMajor} color="base" />
													Redigera
												</HorizontalStack>
											),
											onAction: () => this.props.onRowEdit(Object.assign({}, row), index),
										},
										{
											content: (
												<HorizontalStack gap="2">
													<Icon source={DeleteMajor} color="critical" />
													Ta bort
												</HorizontalStack>
											),
											onAction: () => this.props.removeRow(index),
										},
									]}
								/>
							)}
						</div>

						{/* <TextField multiline value={title5} onChange={()=>props.addRow("subtotal", index)} /> */}
					</td>
				</tr>,
				comment && (
					<tr key={`row-${index}-comment`} id={`row-${index}-comment`} className="Quote-Table-Row Quote-Table-Comment-Row">
						<td colSpan={1} />
						<td colSpan={7}>{comment}</td>
					</tr>
				),
			];
		};
	}

	componentWillReceiveProps(props) {
		this.setState({ rows: props.rows });
	}

	render() {
		return (
			<Table className="table_wrapper Quote__Rows-Display-Table">
				<thead>
					<tr>
						<th width="150">Artikel *</th>
						<th>Beskrivning</th>
						<th width="120">Antal</th>
						<th width="75">Enhet</th>
						<th width="150">Á-pris</th>
						{/* <th width="105">Rot/Rut</th> */}
						<th width="105">Rabatt</th>
						{/* <th>Delsumma</th> */}
						<th width="100">Rot/Rut</th>
						<th width="140">Radsumma</th>
					</tr>
				</thead>
				<tbody>
					{(this.state.rows || []).map((row, index) => {
						return this.renderRow(row, index);
					})}
				</tbody>
			</Table>
		);
	}
}

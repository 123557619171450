import { Checkbox, Heading, Modal, Stack } from "@shopify/polaris";
import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { toastr } from "../../components/toastr";
import QuoteRowsTable from "../Quotes/QuoteRowsTable";
import API from "../../API";

class FortnoxInvoiceCreateModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			form: {
				project: {},
				quotes: [],
				rows: [],
				additionalRows: [{}],
			},
		};
	}

	componentWillReceiveProps(props) {
		if (props.open && props.open !== this.state.open) {
			this.state.form.project = props.project;
			if (props.project && props.project.id) {
				this.fetch(props.project.id);
			}
		}
	}

	fetch(id) {
		this.setState({ loading: true });
		API.get(`/api/fortnox/${id}/orders`, {
			params: {
				filter: "invoicenotcreated",
			},
		})
			.then((result) => {
				this.state.form.orders = result.data.orders;
				this.setState({ form: this.state.form });
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	componentDidMount() {
		this.setState({ form: { project: this.props.project, rows: [], orders: [], additionalRows: [{}] } });
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		if (
			this.state.form &&
			this.state.form.additionalRows &&
			this.state.form.additionalRows.length &&
			this.state.form.project &&
			this.state.form.project.id
		) {
			API.post(`/api/fortnox/${this.state.form.project.id}/order-invoices`, {
				rows: this.state.form.rows,
				additionalRows: this.state.form.additionalRows.filter((i) => Object.keys(i).length),
			})
				.then((result) => {
					this.setState({ saving: false });
					if (this.props.onComplete) this.props.onComplete();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	setRow(row, index) {
		if (!this.state.form.additionalRows) {
			this.state.form.additionalRows = [];
		}
		// const position = typeof index === "number" ? index : this.state.form.additionalRows.length;

		this.state.form.additionalRows[index] = row;
		// if (this.state.form.additionalRows.every((r) => Object.keys(r).length)) {
		// if (this.state.form.additionalRows.filter((r) => !Object.keys(r).length).length < 2) {
		// 	this.state.form.additionalRows.push({});
		// }

		if (index === this.state.form.additionalRows.length - 1) {
			this.state.form.additionalRows.push({});
		}

		this.setState({ form: this.state.form });
	}

	addRow(field, index, value) {
		if (!this.state.form.additionalRows) {
			this.state.form.additionalRows = [];
		}
		this.state.form.additionalRows[index] = Object.assign(this.state.form.additionalRows[index] || {}, { [field]: value });

		if (index === this.state.form.additionalRows.length - 1) {
			this.state.form.additionalRows.push({});
		}
		this.setState({ form: this.state.form });
	}

	removeRow(index) {
		this.state.form.additionalRows.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	render() {
		const { handleClose, open } = this.props;
		const { saving } = this.state;
		const { project, orders } = this.state.form || {};

		const primaryAction = {
			content: "Skapa",
			loading: saving,
			onAction: this.saveForm.bind(this),
		};

		return (
			<Modal
				title={`${(project && project.title) || "Hämtar data..."}`}
				size="Full"
				large
				open={open}
				onClose={handleClose}
				primaryAction={primaryAction}
				secondaryActions={[{ content: "Avbryt", onAction: handleClose, disabled: this.state.loading }]}
			>
				<Modal.Section>
					<Stack vertical>
						<Stack.Item>
							<QuoteTable
								orders={orders || []}
								titleHandle="DocumentNumber"
								rowHandle="OfferRows"
								columns={[
									{
										header: "Order nummer",
										handle: "DocumentNumber",
										style: {
											whiteSpace: "nowrap",
										},
									},
									{
										header: "Kund nummer",
										handle: "CustomerNumber",
										style: {
											whiteSpace: "nowrap",
										},
									},
									{
										header: "Kund",
										handle: "CustomerName",
										style: {
											width: "100%",
										},
									},
									{
										header: "Total",
										handle: "Total",
										style: {},
									},
								]}
								onSelectionChange={this.updateForm.bind(this, "rows")}
								selected={this.state.form.rows || []}
							/>
						</Stack.Item>
						<Stack.Item>
							<Stack vertical>
								<Stack.Item>
									<Heading>Ny order</Heading>
								</Stack.Item>
								<Stack.Item>
									<QuoteRowsTable
										form={{ rows: this.state.form.additionalRows }}
										setRow={this.setRow.bind(this)}
										addRow={this.addRow.bind(this)}
										removeRow={this.removeRow.bind(this)}
									/>
								</Stack.Item>
							</Stack>
						</Stack.Item>
					</Stack>
				</Modal.Section>
			</Modal>
		);
	}
}
export default FortnoxInvoiceCreateModal;

const QuoteTable = ({ orders, columns, onSelectionChange, selected, titleHandle = "title", rowHandle = "rows" }) => {
	const [selectedRows, setSelectedRows] = useState([].concat(selected || []));

	const allSelected = (rows) => (rows || []).every((row) => selectedRows.includes(row));

	const updateSelection = (row) => {
		const index = selectedRows.indexOf(row);
		if (index > -1) {
			setSelectedRows((curr) => curr.filter((_, i) => i !== index));
		} else {
			setSelectedRows((curr) => [].concat(curr, [row]));
		}
	};

	const updateMultipleRows = (rows) => {
		if (allSelected(rows)) {
			setSelectedRows((curr) => curr.filter((item) => !rows.includes(item)));
		} else {
			setSelectedRows((curr) =>
				[].concat(
					curr.filter((i) => !rows.includes(i)),
					rows
				)
			);
		}
	};

	useEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selectedRows);
		}
	}, [selectedRows, onSelectionChange]);

	return (
		<Stack vertical>
			<Table>
				<thead>
					<tr>
						<th>
							<Checkbox checked={allSelected(orders)} onChange={updateMultipleRows.bind(this, orders)} />
						</th>
						{columns.map((column, index) => (
							<th key={index} style={column.style}>
								{column.header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{orders.map((order, index) => (
						<tr key={index}>
							<td>
								<Checkbox checked={selectedRows.indexOf(order) >= 0} onChange={updateSelection.bind(this, order)} />
							</td>
							{columns.map((column, index) => (
								<td key={index}>{order[column.handle]}</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</Stack>
	);
};

const Table = styled.table`
	width: 100%;
	padding: 0 1rem;

	th,
	td {
		text-align: start;
		padding: 0 1rem;
	}
`;

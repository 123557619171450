import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Provider } from "react-redux";
import "./css/app.scss";
import "./css/toastr/index.scss";
import { createRoot } from "react-dom/client";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { BrowserRouter, Link, Redirect, Switch, Route } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import { AppProvider } from "@shopify/polaris";
import { Buffer } from "buffer";
import parentRoutes from "./js/parent_routes.js";
import { store } from "./js/store";
import getPolarisTranslations from "./getPolarisTranslations";
import { getCookie } from "./js/Utilities";
import FrontendExceptionLogger from "./js/components/FrontendExceptionLogger";
import BASE_URL from "./js/API/BASE_URL";

import "moment/min/locales";
import { toastr } from "./js/components/toastr";

const queryClient = new QueryClient();

global.Buffer = Buffer;

window.open = (function (open) {
	return function (url, name, features) {
		if (!url) {
			toastr.error("Saknar url för att öppna fönster");
			return;
		}
		if (url?.startsWith("/api")) {
			if (process.env.NODE_ENV === "development") {
				const baseURL = BASE_URL;
				url = baseURL + url;
			} else {
				url = window.location.origin + url;
			}
		}

		name = name || "Tidrapport24";

		try {
			const finalUrl = new URL(url);
			finalUrl.searchParams.set("token", getCookie("token"));

			return open.call(window, finalUrl.href, name, features);
		} catch (e) {
			toastr.error(e.message);
			console.error("window.open Error", e);
		}
	};
})(window.open);

const root = createRoot(document.getElementById("root"));

const linkComponent = ({ children, url }) => <Link to={url}>{children}</Link>;

root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<div>
				<AppProvider i18n={getPolarisTranslations()} linkComponent={linkComponent}>
					<FrontendExceptionLogger>
						<BrowserRouter>
							<Switch>
								{parentRoutes.map((prop, key) => {
									if (prop.redirect) return <Redirect from={prop.path} to={prop.redirect} key={key} />;

									return <Route path={prop.path} component={prop.component} key={key} exact={prop.exact} />;
								})}
							</Switch>
						</BrowserRouter>
					</FrontendExceptionLogger>
				</AppProvider>
				<ReduxToastr
					timeOut={4000}
					newestOnTop={false}
					preventDuplicates
					position="bottom-center"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick
				/>
			</div>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</Provider>
);

/* eslint-disable no-constant-condition */
import React, { Component } from "react";
import { Modal, Select } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import Moment from "react-moment";
import { toastr } from "../../components/toastr";
import { store } from "../../store";

import TimeRangeModal from "../../components/TimeRangeModal.js";
import IndexTable from "../../components/IndexTable";
import Badge from "../../components/Badge";
import API from "../../API";

class FortnoxQuoteIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changeStatusModalOpen: false,
			timeRangeModalOpen: false,
			selectedItems: [],
			newStatus: "",
			saving: false,
			params: {},
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	renderCell(item, column) {
		if (column.handle == "DATE") {
			return (
				<Moment locale="sv" format="YYYY-MM-DD">
					{item.OfferDate}
				</Moment>
			);
		}
		if (column.handle == "DOCUMENTNUMBER") {
			return item.DocumentNumber;
		}
		if (column.handle == "OFFERNUMBER") {
			return item.title;
		}
		if (column.handle == "CUSTOMER") {
			return <div>{item.CustomerName}</div>;
		}
		if (column.handle == "STATUS") {
			if (item.Sent) {
				return (
					<Badge key="status" progress={50}>
						Skickad
					</Badge>
				);
			} else {
				return (
					<Badge key="status" status="critical">
						Ej skickad
					</Badge>
				);
			}
		}

		return column.handle;
	}

	updateParams(params) {
		this.setState({ params });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printReports() {
		this.setState({ timeRangeModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	printRange(fromDate, toDate) {
		this.closeTimeRangeModal();
		var projectIds = this.state.selectedItems;
		var query = {
			ids: projectIds,
			from: this.getYmd(fromDate),
			to: this.getYmd(toDate),
		};

		window.open("/api/projects/report.pdf?" + httpBuildQuery(query));
	}

	changeStatus() {
		this.setState({ changeStatusModalOpen: true });
	}

	doUpdateStatus() {
		this.setState({ saving: true });
		var projectIds = this.state.selectedItems;
		API.put("/api/orders/bulk_edit.json", { status: this.state.newStatus }, { params: { ids: projectIds } })
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					return;
				}

				this.refresh();
				this.setState({ changeStatusModalOpen: false, saving: false });
				toastr.success("Order ändrat");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	exportCurrentProjects(fromDate, toDate) {
		var params = Object.assign({}, this.state.params);
		params.from = this.getYmd(fromDate);
		params.to = this.getYmd(toDate);
		window.open("/api/projects/export.xls?" + httpBuildQuery(params));
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();

		var filters = [
			{
				key: "filter",
				label: "Status",
				operatorText: "är",
				type: "select",
				options: [
					// { label: "Skickad", value: "sent" },
					{ label: "ej accepterad", value: "ordernotcreated" },
					{ label: "accepterad", value: "ordercreated" },
				],
			},
			{
				key: "sent",
				label: "Status",
				operatorText: "är",
				type: "select",
				options: [
					// { label: "Skickad", value: "sent" },
					{ label: "ej skickad", value: 0 },
					{ label: "skickad", value: 1 },
				],
			},
			{
				key: "start_at_period",
				label: "Förväntat startdatum",
				type: "select",
				options: [
					{ label: "Nästa månad", value: "next_month" },
					{ label: "Denna månad", value: "this_month" },
				],
			},
		];

		let promotedBulkActions = [];

		if (!store.getState().user.read_only) {
			promotedBulkActions = [
				{
					content: "Skriv ut rapporter",
					onAction: this.printReports.bind(this),
				},
				{
					content: "Ändra status",
					onAction: this.changeStatus.bind(this),
				},
			];
		}

		const columns = [
			{
				handle: "DATE",
				label: "Datum",
			},
			{
				handle: "DOCUMENTNUMBER",
				label: "Ordernummer",
			},
			{
				handle: "CUSTOMER",
				label: "Kund",
			},
			{
				handle: "STATUS",
				label: "Status",
			},
		];

		return (
			<div>
				{this.props.project && this.props.project.id && (
					<IndexTable
						idHandle="DocumentNumber"
						setRefreshHandler={(refreshHandler) => {
							this.refresh = refreshHandler;
						}}
						history={this.props.history}
						title="Ordrar"
						primaryAction={
							true
								? null
								: {
										content: "Ny order",
										onAction: () => {
											this.props.history.push({
												pathname: "/admin/orders/new",
												state: {
													customer: this.props.customer,
													project: this.props.project,
												},
											});
										},
								  }
						}
						defaultSavedSearches={[]}
						savedSearchHandle="orders"
						// resourceUrl={`/api/orders.json${this.props.customer ? `?customer_id=${this.props.customer.id}` : ""}`}
						resourceUrl={`/api/fortnox/${this.props.project.id}/orders`}
						resourceHandle="orders"
						resourceName={{
							singular: "order",
							plural: "ordrar",
						}}
						onUpdateParams={this.updateParams.bind(this)}
						renderCell={this.renderCell.bind(this)}
						selectedItems={this.state.selectedItems}
						onSelectionChange={this.onSelectionChange.bind(this)}
						filters={filters}
						onClickRow={(_item) => {
							// const url = "/admin/orders/" + item.DocumentNumber;
							// this.props.history.push(url, { project: this.props.project });
						}}
						columns={columns}
						promotedBulkActions={promotedBulkActions}
					/>
				)}
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
				/>
				<Modal
					title="Ändra status"
					open={this.state.changeStatusModalOpen}
					onClose={() => {
						this.setState({ changeStatusModalOpen: false });
					}}
					primaryAction={{
						content: "Uppdatera",
						loading: this.state.saving,
						disabled: !this.state.newStatus,
						onAction: this.doUpdateStatus.bind(this),
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								this.setState({ changeStatusModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<Select
							label="Status"
							options={[
								{ label: "Välj ny status", value: "" },
								{ label: "Ej skickad", value: "draft" },
								{ label: "Skickad", value: "sent" },
							]}
							onChange={(value) => {
								this.setState({ newStatus: value });
							}}
							value={this.state.newStatus}
						/>
					</Modal.Section>
				</Modal>
				<TimeRangeModal
					open={this.state.changeTimePeriodModalOpen}
					onClose={() => {
						this.setState({ changeTimePeriodModalOpen: false });
					}}
					from={fromDate}
					to={toDate}
					noreport
					onChange={this.exportCurrentProjects.bind(this)}
				/>
			</div>
		);
	}
}

export default FortnoxQuoteIndex;

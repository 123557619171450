import { FormLayout, Layout, Modal, Page, Card, Stack } from "@shopify/polaris";
import React, { Component } from "react";
import TextField from "../../components/TextField";
import { toastr } from "../../components/toastr";
import ResourcePicker from "../../components/resource_picker";
import API from "../../API";

class ContactCreate extends Component {
	constructor(props) {
		super(props);
		this.state = { form: { parent: props.parent } };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.parent && props.parent !== this.state.form.parent) {
			this.setState({ form: { parent: props.parent } });
		}
	}

	saveForm() {
		this.setState({ saving: true });
		if (!this.state.form.parent) this.state.form.parent = this.props.parent;
		if (this.props.noCreate) {
			if (this.props.onCreate) this.props.onCreate(this.state.form);
			this.setState({ saving: false });
			this.onClose();
			return;
		}
		API.post("/api/contacts.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Kontakt skapad");
				this.onClose();
				if (this.props.onCreate) this.props.onCreate(result.data.contact);
				if (!this.props.asModal) this.props.history.replace("/admin/contacts/" + result.data.contact.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				console.error("error:", error);
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	onToggle() {
		this.setState((c) => ({ newContactModalIsOpen: !c.newContactModalIsOpen }));
	}

	onClose() {
		this.setState({ newContactModalIsOpen: false, form: {} });
	}

	render() {
		const { asModal } = this.props;
		const primaryAction = { content: this.props.noCreate ? "Lägg till" : "Skapa", loading: this.state.saving, onAction: this.saveForm.bind(this) };

		const content = (
			<Layout>
				<Layout.Section>
					<Card sectioned>
						<FormLayout>
							<Stack distribution="fillEvenly">
								<TextField required label="Förnamn" value={this.state.form.first_name} onChange={this.updateForm.bind(this, "first_name")} />
								<TextField required label="Efternamn" value={this.state.form.last_name} onChange={this.updateForm.bind(this, "last_name")} />
							</Stack>
							<Stack distribution="fillEvenly">
								<TextField label="Email" value={this.state.form.email} onChange={this.updateForm.bind(this, "email")} type="email" />
								<TextField label="Telefonnr" value={this.state.form.phone} onChange={this.updateForm.bind(this, "phone")} type="tel" />
							</Stack>
							<ResourcePicker
								placeholder="Sök kund"
								caption="Välj kund"
								resource="customers"
								resourceName={{
									singular: "kund",
									plural: "kunder",
								}}
								item={this.state.form.parent}
								onChange={this.updateForm.bind(this, "parent")}
								label="Kund"
								required
								disabled={this.props.parentDisabled}
								label_handle="name"
								textAlign="left"
							/>
						</FormLayout>
					</Card>
					<Card sectioned>
						<FormLayout>
							<TextField label="Adress" value={this.state.form.address} onChange={this.updateForm.bind(this, "address")} />
							<Stack distribution="fillEvenly">
								<TextField label="Postnummer" value={this.state.form.zip} onChange={this.updateForm.bind(this, "zip")} />
								<TextField label="Stad" value={this.state.form.city} onChange={this.updateForm.bind(this, "city")} />
							</Stack>
						</FormLayout>
					</Card>
				</Layout.Section>
			</Layout>
		);

		if (asModal) {
			return (
				<Modal open={this.state.newContactModalIsOpen} onClose={this.onClose.bind(this)} title="Ny kontakt" primaryAction={primaryAction}>
					<Modal.Section>{content}</Modal.Section>
				</Modal>
			);
		}

		return (
			<Page fullWidth title="Ny kontakt" backAction={{ content: "kontakter", url: "/admin/contacts" }} primaryAction={primaryAction}>
				<Layout>
					<Layout.Section>{content}</Layout.Section>
				</Layout>
			</Page>
		);
	}
}
export default ContactCreate;

import React from "react";
import Colors, { colorToRgba } from "src/js/Colors";
import styled from "styled-components";
import ActionButton from "../page/ActionButton";
import { BannerActionType } from "src/js/types/Banner/Banner";

type BannerActionProps = {
	action: BannerActionType;
};

const BannerAction = ({ action }: BannerActionProps) => {
	return (
		<StyledButton color={action.destructive ? Colors.red : action.color}>
			<ActionButton item={action} />
		</StyledButton>
	);
};
export default BannerAction;

const StyledButton = styled.div`
	&&& {
		button {
			border-width: 1px;
			border-style: solid;
			color: var(--textColor);
			transition: background 250ms, background-color 250ms, filter 250ms, border-color 250ms;

			&:not(.Polaris-Button--destructive) {
				background-color: ${({ color }) => colorToRgba(color, 0.15)};
				background: ${({ color }) => colorToRgba(color, 0.15)};
				border-color: ${({ color }) => colorToRgba(color, 0.15)};
			}
			&.Polaris-Button--destructive {
				background-color: ${({ color }) => colorToRgba(color, 0.75)};
				background: ${({ color }) => colorToRgba(color, 0.75)};
				border-color: ${({ color }) => colorToRgba(color, 0.75)};
			}

			&:hover {
				filter: brightness(1.2);

				&:not(.Polaris-Button--destructive) {
					background-color: ${({ color }) => colorToRgba(color, 0.25)};
					background: ${({ color }) => colorToRgba(color, 0.25)};
					border-color: ${({ color }) => colorToRgba(color, 0.25)};
				}

				&.Polaris-Button--destructive {
					background-color: ${({ color }) => colorToRgba(color, 0.1)};
					background: ${({ color }) => colorToRgba(color, 0.1)};
					border-color: ${({ color }) => colorToRgba(color, 0.1)};
				}
			}
		}
	}
`;

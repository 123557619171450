import { Component } from "react";
import { Badge, Avatar } from "@shopify/polaris";
import IndexTable from "../../components/IndexTable";
import { store } from "../../store";

class CustomerIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderCell(item, column) {
		if (column.handle == "TITLE") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<Avatar customer size="medium" name={item.name} source={item.avatar} />
						<h3 className="CustomerListItem__Title">{item.first_name + " " + item.last_name}</h3>
						{item.read_only ? <Badge status="warning">Läsrättighet</Badge> : null}
					</div>
				</div>
			);
		}

		if (column.handle == "EMPLOYEE_NUMBER") {
			return item.employee_number;
		}
		if (column.handle == "EMAIL") {
			return item.email;
		}
		if (column.handle == "STATUS") {
			return (
				<>
					{item.enabled ? <Badge status="success">Aktiv</Badge> : <Badge status="warning">Inaktiv</Badge>}
					{item.no_time_reporting ? <Badge status="warning">Behöver inte rapportera tid</Badge> : null}
				</>
			);
		}

		if (column.handle == "GROUPS") {
			return item.groups.map((group, index) => <Badge key={group.id || index}>{group.name}</Badge>);
		}
		if (column.handle == "READ_ONLY") {
			return item.read_only ? <Badge status="warning">Läsrättighet</Badge> : null;
		}
	}

	render() {
		const filters = [
			{
				key: "active",
				label: "Aktiv",
				type: "select",
				options: [
					{ label: "Ja", value: "1" },
					{ label: "Nej", value: "0" },
				],
			},
			{
				key: "employee_number_missing",
				label: "Utan Anställningsnummer",
				type: "select",
				options: [{ label: "Ja", value: "1" }],
			},
			{
				key: "group_id",
				label: "Grupp",
				type: "select",
				options: store.getState().groups.map((grp) => ({ label: grp.name, value: grp.id + "" })),
			},
		];

		const columns = [
			{
				label: "Namn",
				handle: "TITLE",
				sortable: true,
			},
			{
				label: "Ansällningsnummer",
				handle: "EMPLOYEE_NUMBER",
				sortable: true,
			},
			{
				label: "E-post",
				handle: "EMAIL",
				sortable: true,
			},
			{
				label: "Status",
				handle: "STATUS",
			},

			{
				label: "Grupper",
				handle: "GROUPS",
			},
		];

		return (
			<div>
				<IndexTable
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					defaultSort="ID_DESC"
					columns={columns}
					title="Användare"
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Ny användare",
									onAction: () => {
										this.props.history.push("/admin/users/new");
									},
							  }
					}
					savedSearchHandle="users"
					resourceUrl="/api/users.json"
					resourceHandle="users"
					resourceName={{
						singular: "användare",
						plural: "användare",
					}}
					defaultFilters={[{ key: "active", value: "1" }]}
					filters={filters}
					renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.props.history.push("/admin/users/" + item.id);
					}}
					defaultSavedSearches={[
						{
							title: "Inaktiva användare",
							filters: [{ key: "active", value: "0" }],
						},
					]}
				/>
			</div>
		);
	}
}

export default CustomerIndex;

import React from "react";
import styled from "styled-components";
import StatsBox from "./StatsBox";
import type { StatsBoxWrapperProps } from "./types";

const MIN_WIDTH = 250;
const MAX_WIDTH = "1fr";

const StatsBoxWrapper = ({
	children,
	items,
	onClick,
	loading,
	loadingNrOfBoxes = items?.length,
	minWidth,
	maxWidth,
	style,
	perRow,
}: StatsBoxWrapperProps) => {
	const boxes = items?.length ? items : Array.from(Array(loadingNrOfBoxes));

	return (
		<Wrapper style={style} minWidth={minWidth || MIN_WIDTH} maxWidth={maxWidth || MAX_WIDTH} perRow={perRow}>
			{boxes.map((item, index) => {
				if (loading) {
					item.className = "loading";
					item.loading = true;
				}

				return <StatsBox key={index} item={item} onClick={onClick ? () => onClick(item, index) : null} />;
			})}
			{children}
		</Wrapper>
	);
};

export default StatsBoxWrapper;

const Wrapper = styled.div<{ minWidth: number | string; maxWidth: number | string; perRow?: number | string }>`
	display: grid;
	gap: 30px;
	margin-bottom: 2rem;
	transition: 250ms;

	grid-template-columns: ${({ perRow, minWidth, maxWidth }) =>
		`repeat(${perRow || "auto-fit"}, minmax(${typeof minWidth === "number" ? `${minWidth}px` : minWidth}, ${
			typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth
		}))`};
`;

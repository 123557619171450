/* eslint-disable camelcase */
import React from "react";
import {
	HomeMajor,
	CirclePlusMajor,
	ProductsMajor,
	OrdersMajor,
	ProfileMajor,
	HorizontalDotsMinor,
	CalendarMajor,
	NoteMinor,
	ChecklistAlternateMajor,
	LanguageMinor,
} from "@shopify/polaris-icons";
import Dashboard from "./views/Dashboard";
import ExpenseIndex from "./views/Expenses/index.js";
import ExpenseCreate from "./views/Expenses/create.js";
import ExpenseEdit from "./views/Expenses/edit.js";
import SpecialTimeIndex from "./views/SpecialTimes/index.js";
import SpecialTimeCreate from "./views/SpecialTimes/create.js";
import SpecialTimeEdit from "./views/SpecialTimes/edit.js";
import CustomerIndex from "./views/Customers/index.js";
import CustomerCreate from "./views/Customers/create.js";
import CustomerEdit from "./views/Customers/edit.js";
import ProjectIndex from "./views/Projects/index.js";
import RejectedIndex from "./views/Rejected/index.js";
import ProjectCreate from "./views/Projects/create.js";
import ProjectEdit from "./views/Projects/edit.js";
import WorkOrderIndex from "./views/WorkOrders/index.js";
import QuoteCreate from "./views/Quotes/create.js";
import QuoteEdit from "./views/Quotes/edit.js";
import UnderentreprenorWorkOrderList from "./views/WorkOrders/index_ue.js";
import WorkOrderCreate from "./views/WorkOrders/create.js";
import WorkOrderEdit from "./views/WorkOrders/edit.js";
import AccountIndex from "./views/Account/index.js";
import ObSettings from "./views/ObSettings/ob_settings.js";
import InvoiceSettings from "./views/InvoiceSettings/InvoiceSettings.js";
import IntegrationSettings from "./views/IntegrationSettings/IntegrationSettings.js";
import SubscriptionProviderIndex from "./views/Account/subscription_selector.js";
import UserIndex from "./views/Users/index.js";
import UserCreate from "./views/Users/create.js";
import UserEdit from "./views/Users/edit.js";
import GroupIndex from "./views/Groups/index.js";
import GroupCreate from "./views/Groups/create.js";
import GroupEdit from "./views/Groups/edit.js";
import UnderentreprenorIndex from "./views/Underentreprenorer/index.js";
import UnderentreprenorCreate from "./views/Underentreprenorer/create.js";
import UnderentreprenorEdit from "./views/Underentreprenorer/edit.js";
import LedgerCheckinIndex from "./views/LedgerCheckins/index.js";
import LedgerCheckinEdit from "./views/LedgerCheckins/edit.js";
import TimeReportsIndex from "./views/TimeReports/index.js";
import TimeReportsEdit from "./views/TimeReports/edit.js";
import ExpenseReportsIndex from "./views/ExpenseReports/index.js";
import ExpenseReportsEdit from "./views/ExpenseReports/edit.js";
import ExpenseReportsCreate from "./views/ExpenseReports/create";
import SettingsIndex from "./views/Settings/index.js";
import IncidentIndex from "./views/Incident/index.js";
import IncidentEdit from "./views/Incident/edit.js";
import AccidentIndex from "./views/Accident/index.js";
import AccidentEdit from "./views/Accident/edit.js";
import DeviationIndex from "./views/Deviation/index.js";
import DeviationEdit from "./views/Deviation/edit.js";
import RejectedEdit from "./views/Rejected/edit.js";
import { store } from "./store/index.js";
import { FolderOpenIcon, OffertIcon, MapIcon, People2Icon, List2Icon, People4Icon, DocumentIcon, CalendarIcon2 } from "./icons";
import Pdf from "./views/Pdf/Pdf.js";
import NewPDF from "./views/Pdf/NewPDF.js";
import EditPDF from "./views/Pdf/EditPDF.js";
import EditorView from "./views/Pdf/Editor/EditorView.js";
import ContactCreate from "./views/Contacts/create.js";
import ContactEdit from "./views/Contacts/edit.js";
import ContactIndex from "./views/Contacts/index.js";
import PlanningIndex from "./views/Planning/PlanningIndex.js";
import WorkTypeIndex from "./views/WorkTypes/index.js";
import CreateWorkType from "./views/WorkTypes/create.js";
import WorkTypeEdit from "./views/WorkTypes/edit.js";
import LanguageSettings from "./views/LanguageSettings/LanguageSettings.js";
import EditLanguage from "./views/LanguageSettings/EditLanguage.js";
import CreateLanguage from "./views/LanguageSettings/CreateLanguage.js";
import FortnoxExpenseCreate from "./views/FortnoxExpenses/create.js";
import FortnoxExpenseEdit from "./views/FortnoxExpenses/edit.js";
import FortnoxExpenseIndex from "./views/FortnoxExpenses/index.js";
import DeviatingRegistrationsIndex from "./views/DeviatingRegistrations";
import ExportLinks from "./views/Export/ExportLinks";
import Supplierinvoices from "./views/Purchase/Supplierinvoices/index";
import FortnoxSupplierinvoices from "./views/Purchase/Supplierinvoices/Fortnox/index";

import SupplierinvoicesEdit from "./views/Purchase/Supplierinvoices/edit";
import FortnoxSupplierinvoicesEdit from "./views/Purchase/Supplierinvoices/Fortnox/edit";
import FortnoxInvoice from "./views/Invoices/Fortnox/FortnoxInvoice";
import FortnoxOfferCreate from "./views/Fortnox/Offers/Create";
import FortnoxOffer from "./views/Fortnox/Offers";
import OfferIndexShared from "./views/OfferIndexShared";
import FortnoxSettings from "./views/Fortnox/Settings";

export const getRoutes = () => {
	const fortnox_connected = store.getState().account.fortnox_connection;
	const fortnox_order_module = fortnox_connected && store.getState().account.fortnox_order_module;

	const routes = [
		{
			path: "/admin/dashboard",
			handle: "dashboard",
			name: "Hem",
			icon: HomeMajor,
			exact: true,
			component: Dashboard,
		},
		{
			path: "/admin/customers",
			mainMenu: true,
			handle: "customers",
			name: "Kunder",
			icon: <People4Icon />,
			exact: true,
			component: CustomerIndex,
		},
		{
			path: "/admin/customers/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: CustomerCreate,
		},
		{
			path: "/admin/customers/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: CustomerEdit,
		},
		{
			path: "/admin/contacts",
			handle: "contacts",
			name: "Kontakter",
			icon: <People2Icon />,
			exact: true,
			component: ContactIndex,
		},
		{
			path: "/admin/contacts/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: ContactCreate,
		},
		{
			path: "/admin/contacts/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: ContactEdit,
		},
		{
			path: "/admin/expenses/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: fortnox_connected ? FortnoxExpenseCreate : ExpenseCreate,
		},
		{
			path: "/admin/expenses/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: fortnox_connected ? FortnoxExpenseEdit : ExpenseEdit,
		},
		{
			path: "/admin/special_times",
			mainMenu: false,
			name: "Specialtider",
			icon: ProductsMajor,
			exact: true,
			component: SpecialTimeIndex,
		},
		{
			path: "/admin/special_times/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: SpecialTimeCreate,
		},
		{
			path: "/admin/special_times/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: SpecialTimeEdit,
		},
		{
			path: "/admin/quotes/new",
			handle: "quotes",
			name: "Ny offert",
			icon: <OffertIcon />,
			exact: true,
			component: fortnox_order_module ? FortnoxOfferCreate : QuoteCreate,
		},

		{
			path: "/admin/quotes/:id",
			handle: "quotes",
			name: "Offert",
			icon: <OffertIcon />,
			exact: true,
			component: fortnox_order_module ? FortnoxOffer : QuoteEdit,
		},
		{
			path: "/admin/quotes",
			mainMenu: true,
			handle: "quotes",
			name: "Offerter",
			icon: <DocumentIcon />,
			exact: true,
			component: OfferIndexShared,
		},
		{
			path: "/admin/projects",
			mainMenu: true,
			handle: "projects",
			name: "Projekt",
			icon: <FolderOpenIcon />,
			exact: true,
			component: ProjectIndex,
		},
		{
			path: "/admin/work_orders",
			mainMenu: false,
			handle: "work_orders",
			name: "Arbetsordrar",
			icon: <List2Icon />,
			exact: true,
			component: WorkOrderIndex,
		},

		{
			path: "/admin/expenses",
			mainMenu: false,
			name: "Artikelregister",
			icon: ProductsMajor,
			exact: true,
			component: fortnox_connected ? FortnoxExpenseIndex : ExpenseIndex,
		},
		{
			path: "/admin/expenses/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: ExpenseCreate,
		},
		{
			path: "/admin/expenses/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: ExpenseEdit,
		},
		{
			path: "/admin/dashboard#map",
			handle: "aokarta",
			name: "Arbetsorderkarta",
			icon: <MapIcon />,
			exact: true,
			component: Dashboard,
		},
		{
			path: "/admin/dashboard#timereports",
			handle: "timereport",
			name: "TimeReportDashboard",
			icon: <MapIcon />,
			exact: true,
			component: Dashboard,
		},
		/*
	{
	path: "/admin/projectpipe",
	mainMenu: true,
	handle: "projectpipe",
	name: "Projektpipe",
	icon: DuplicateMinor,
	exact:true,
	component: ProjectPipe
	},
	*/
		{
			path: "/admin/incident_reporting",
			mainMenu: false,
			handle: "tillbud",
			name: "Tillbudsrapporter",
			icon: NoteMinor,
			exact: true,
			component: IncidentIndex,
		},
		{
			path: "/admin/incident_reporting/:id",
			mainMenu: false,
			name: "Edit incident",
			icon: NoteMinor,
			exact: true,
			component: IncidentEdit,
		},
		{
			path: "/admin/accident_reporting",
			mainMenu: false,
			handle: "accident",
			name: "Olycksrapporter",
			icon: NoteMinor,
			exact: true,
			component: AccidentIndex,
		},
		{
			path: "/admin/accident_reporting/:id",
			mainMenu: false,
			name: "Edit accident",
			icon: NoteMinor,
			exact: true,
			component: AccidentEdit,
		},
		{
			path: "/admin/deviation_reporting",
			mainMenu: false,
			handle: "deviation",
			name: "Avvikelserapporter",
			icon: NoteMinor,
			exact: true,
			component: DeviationIndex,
		},
		{
			path: "/admin/deviation_reporting/:id",
			mainMenu: false,
			name: "Edit deviation",
			icon: NoteMinor,
			exact: true,
			component: DeviationEdit,
		},
		{
			path: "/admin/projects/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: ProjectCreate,
		},
		{
			path: "/admin/work_orders/new",
			mainMenu: false,
			name: "Create work order",
			icon: CirclePlusMajor,
			exact: true,
			component: WorkOrderCreate,
		},
		{
			path: "/admin/work_orders/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: WorkOrderEdit,
		},
		{
			path: "/admin/projects/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: ProjectEdit,
		},
		{
			path: "/admin/settings/reporting",
			settings: true,
			description: "Inställningar för tidrapportering och OB ersättning",
			name: "Tidrapportering",
			icon: CalendarMajor,
			exact: true,
			component: ObSettings,
		},
		{
			path: "/admin/settings/invoicing",
			settings: true,
			description: "Inställningar för fakturering",
			name: "Faktureringsinställningar",
			icon: CalendarMajor,
			exact: true,
			component: InvoiceSettings,
		},
		{
			path: "/admin/settings/integrations",
			settings: true,
			description: "Integrationer mot bokföringssystem",
			name: "Integrationer",
			icon: HorizontalDotsMinor,
			exact: true,
			component: IntegrationSettings,
		},
		{
			path: "/admin/account",
			settings: true,
			description: "Hantera fakturor och betalningar",
			name: "Mitt konto",
			icon: CirclePlusMajor,
			exact: true,
			component: AccountIndex,
		},
		{
			path: "/admin/settings/work-type",
			settings: true,
			description: "Redigera eller skapa en arbetstyp",
			name: "Arbetstyper",
			icon: HorizontalDotsMinor,
			exact: true,
			component: WorkTypeIndex,
		},
		{
			path: "/admin/settings/languages",
			settings: true,
			description: "Inställningar för språk",
			name: "Språk",
			icon: LanguageMinor,
			exact: true,
			component: LanguageSettings,
		},
		{
			path: "/admin/settings/languages/new",
			mainMenu: false,
			name: "Create new language",
			icon: CirclePlusMajor,
			exact: true,
			component: CreateLanguage,
		},
		{
			path: "/admin/settings/languages/:id",
			mainMenu: false,
			name: "Edit language",
			icon: LanguageMinor,
			exact: true,
			component: EditLanguage,
		},
		{
			path: "/admin/settings/work_type/new",
			mainMenu: false,
			name: "Create Work Type",
			icon: CirclePlusMajor,
			exact: true,
			component: CreateWorkType,
		},
		{
			path: "/admin/settings/work_type/:id",
			mainMenu: false,
			name: "Edit work type",
			icon: CirclePlusMajor,
			exact: true,
			component: WorkTypeEdit,
		},
		{
			path: "/admin/account/subscription",
			settings: false,
			icon: CirclePlusMajor,
			exact: true,
			component: SubscriptionProviderIndex,
		},
		{
			path: "/admin/users",
			mainMenu: false,
			handle: "users",
			name: "Användare",
			icon: ProfileMajor,
			exact: true,
			component: UserIndex,
		},
		{
			path: "/admin/users/new",
			mainMenu: false,
			name: "Create user",
			icon: CirclePlusMajor,
			exact: true,
			component: UserCreate,
		},
		{
			path: "/admin/users/:id",
			mainMenu: false,
			name: "Edit User",
			exact: true,
			component: UserEdit,
		},
		{
			path: "/admin/groups",
			parent: "users",
			name: "Grupper",
			icon: CirclePlusMajor,
			exact: true,
			component: GroupIndex,
		},
		{
			path: "/admin/groups/new",
			mainMenu: false,
			name: "Create user",
			icon: CirclePlusMajor,
			exact: true,
			component: GroupCreate,
		},
		{
			path: "/admin/groups/:id",
			mainMenu: false,
			name: "Edit User",
			exact: true,
			component: GroupEdit,
		},
		{
			path: "/admin/settings",
			mainMenu: true,
			settings: true,
			name: "Settings",
			icon: CirclePlusMajor,
			exact: true,
			component: SettingsIndex,
		},
		{
			path: "/admin/ledger_checkins",
			mainMenu: false,
			settings: false,
			name: "Personalliggare",
			icon: CirclePlusMajor,
			exact: true,
			component: LedgerCheckinIndex,
		},
		{
			path: "/admin/ledger_checkins/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: LedgerCheckinEdit,
		},
		{
			path: "/admin/time_reports",
			mainMenu: false,
			settings: false,
			name: "Tidrapporter",
			icon: CirclePlusMajor,
			exact: true,
			component: TimeReportsIndex,
		},
		{
			path: "/admin/time_reports/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: TimeReportsEdit,
		},
		{
			path: "/admin/expense_reports",
			mainMenu: false,
			settings: false,
			name: "Avgiftsrapporter",
			icon: CirclePlusMajor,
			exact: true,
			component: ExpenseReportsIndex,
		},
		{
			path: "/admin/expense_reports/new",
			mainMenu: false,
			settings: false,
			name: "Avgiftsrapporter",
			icon: CirclePlusMajor,
			exact: true,
			component: ExpenseReportsCreate,
		},
		{
			path: "/admin/expense_reports/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: ExpenseReportsEdit,
		},
		{
			path: "/admin/underentreprenorer",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: UnderentreprenorIndex,
		},
		{
			path: "/admin/underentreprenorer/new",
			mainMenu: false,
			name: "Create product",
			icon: CirclePlusMajor,
			exact: true,
			component: UnderentreprenorCreate,
		},
		{
			path: "/admin/underentreprenorer/:id",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: UnderentreprenorEdit,
		},
		{
			path: "/admin/ue_list_work_orders",
			mainMenu: false,
			name: "Edit product",
			icon: CirclePlusMajor,
			exact: true,
			component: UnderentreprenorWorkOrderList,
		},
		{
			path: "/admin/pdf_templates/new",
			mainMenu: false,
			name: "Ny PDF",
			icon: ChecklistAlternateMajor,
			exact: true,
			component: NewPDF,
		},
		{
			path: "/admin/pdf_templates",
			mainMenu: false,
			name: "PDFs",
			icon: ChecklistAlternateMajor,
			exact: true,
			component: Pdf,
		},
		{
			path: "/admin/pdf_templates/editor/:id",
			mainMenu: false,
			name: "PDFs",
			icon: ChecklistAlternateMajor,
			exact: true,
			component: EditorView,
		},
		{
			path: "/admin/pdf_templates/:id",
			mainMenu: false,
			name: "PDF",
			icon: ChecklistAlternateMajor,
			exact: true,
			component: EditPDF,
		},
		{
			path: "/admin/planning",
			mainMenu: true,
			name: "Planering",
			icon: <CalendarIcon2 />,
			exact: true,
			component: PlanningIndex,
		},
		{
			path: "/admin/planning/:id",
			name: "Planering",
			icon: <CalendarIcon2 />,
			exact: true,
			component: PlanningIndex,
		},
		{
			path: "/admin/deviating_registrations",
			name: "Avvikande registreringar",
			exact: true,
			component: DeviatingRegistrationsIndex,
		},
		{
			path: "/admin/exports",
			name: "Exportera",
			exact: true,
			component: ExportLinks,
		},
		{
			path: "/admin/invoices/new",
			name: "Inköp",
			exact: true,
			component: FortnoxInvoice,
		},
		{
			path: "/admin/invoices/:id",
			name: "Inköp",
			exact: true,
			component: FortnoxInvoice,
		},
		{
			path: "/admin/supplier_invoices",
			name: "Inköp",
			exact: true,
			component: fortnox_connected ? FortnoxSupplierinvoices : Supplierinvoices,
			enabled: fortnox_connected, // ? Remove when list of supplier invoices in CRM is ready
		},
		{
			path: "/admin/supplier_invoices/:id",
			name: "Inköp",
			exact: true,
			component: fortnox_connected ? FortnoxSupplierinvoicesEdit : SupplierinvoicesEdit,
			enabled: fortnox_connected, // ? Remove when list of supplier invoices in CRM is ready
		},
		{
			path: "/admin/fortnox/settings",
			exact: true,
			enabled: true,
			// enabled: store.getState().account.fortnox_integration,
			component: FortnoxSettings,
		},
	];

	if (store.getState().account.reject_weeks) {
		routes.push(
			{
				path: "/admin/rejected_weeks",
				mainMenu: false,
				handle: "rejected_weeks",
				name: "Nekade tider",
				icon: OrdersMajor,
				exact: true,
				component: RejectedIndex,
			},
			{
				path: "/admin/rejected_weeks/:id",
				mainMenu: false,
				name: "Redigera nekade tider",
				icon: CirclePlusMajor,
				exact: true,
				component: RejectedEdit,
			}
		);
	}

	routes.push({
		redirect: true,
		path: "/admin/",
		to: "/admin/dashboard",
		name: "Expenses",
	});

	return routes;
};
export default getRoutes();

import { ActionList, Icon, Popover } from "@shopify/polaris";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";

class MainMenuSectionActions extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { actions = [] } = this.props;
		if (!actions || !actions.length) return null;
		return (
			<span className="mainmenu-item__secondary__actions">
				<Popover
					active={this.state.showAddContext}
					activator={
						<button className="Polaris-Navigation__Action" onClick={() => this.setState({ showAddContext: true })}>
							<Icon source={CirclePlusOutlineMinor} />
						</button>
					}
					onClose={() => this.setState({ showAddContext: false })}
				>
					<ActionList
						items={actions.map((a) => {
							return Object.assign({}, a, {
								onAction: (e) => {
									a.onAction(e);
									this.setState({ showAddContext: false });
								},
							});
						})}
					/>
				</Popover>
			</span>
		);
	}
}

export default MainMenuSectionActions;

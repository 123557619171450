import React, { Component } from "react";
import { store } from "../../../store";

// This is not used anymore, this was used to create the twig
class QuotePDFFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { company, street, zip, city, phone } = store.getState().account;
		const fontSize = "14px";
		return (
			<div id="footer" style={{ borderTop: "2px solid rgb(0,0,0)", fontSize: "12px", height: "100px", width: "100%" }}>
				<div style={{ display: "flex", gap: "5px", padding: "5px", paddingTop: "3px" }}>
					<div style={{ flex: 1 }}>
						<b style={{ fontSize, lineHeight: fontSize }}>Postadress</b>
						<p style={{ fontSize, lineHeight: fontSize }}>{company}</p>
						<p style={{ fontSize, lineHeight: fontSize }}>{street}</p>
						<p style={{ fontSize, lineHeight: fontSize }}>{`${zip} ${city}`}</p>
					</div>
					<div style={{ flex: 1 }}>
						<b style={{ fontSize, lineHeight: fontSize }}>Telefon</b>
						<p style={{ fontSize, lineHeight: fontSize }}>{phone}</p>
					</div>
					{/* <div style={{ flex: 1 }}>
						<b>Bankgiro</b>
						<p>{bankgrio}</p>
						<b>Momsref.nr</b>
						<p>{momsref}</p>
					</div> */}
					<div style={{ flex: 1 }}>
						<p style={{ fontSize, lineHeight: fontSize }} />
						<p style={{ fontSize, lineHeight: fontSize }} />
						<p style={{ fontSize, lineHeight: fontSize }}>Offert skapad via T24</p>
						<p style={{ fontSize, lineHeight: fontSize }}>Tidrapport24.se</p>
					</div>
				</div>
			</div>
		);
	}
}

export default QuotePDFFooter;

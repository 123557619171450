/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { Select, TextField } from "@shopify/polaris";

class TimePicker extends Component {
	leadingZero(val) {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	}

	isAfter(hour, minute) {
		var pcs = this.props.min.split(":");
		var minHour = parseInt(pcs[0]);
		var minMinute = parseInt(pcs[1]);

		if (hour >= minHour) {
			if (hour != minHour || minute >= minMinute) {
				return true;
			}
		}
	}

	render() {
		let options = [];
		if (!this.props.options) {
			for (var hour = 0; hour < (this.props.hours ? 16 : 24); hour += this.props.hourInterval || 1) {
				for (var minute = 0; minute < 60; minute += this.props.minuteInterval || 30) {
					if (!this.props.min || this.isAfter(hour, minute)) {
						if (this.props.hours) {
							if (minute == 0) {
								options.push({ label: hour + " timmar", value: hour + "" });
							} else {
								if (hour == 8 && minute == 30 && this.props.label != "Övertid") {
									// Man ska bara kunna rapportera max 8 ordinarie timmar enligt mail från Mats den 19 Januari 11:36
								} else {
									options.push({ label: hour + " timmar, " + minute + " minuter", value: hour + "." + minute });
								}
							}
						} else {
							options.push({
								label: this.leadingZero(hour) + ":" + this.leadingZero(minute),
								value: this.leadingZero(hour) + ":" + this.leadingZero(minute),
							});
						}
					}
				}
			}
			if (!this.props.hours) {
				options.push({ label: "00:00", value: "23:59" });
			}
		} else {
			options = this.props.options;
		}
		if (this.props.editing) {
			return <TextField {...this.props} />;
		}
		return <Select {...this.props} placeholder={this.props.value || this.props.placeholder} options={options} />;
	}
}

export default TimePicker;

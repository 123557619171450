import React, { Component } from "react";
import { Page, PageActions, Layout, Card, Modal, FormLayout, Icon, TextField, Stack, Badge, ResourceList, Avatar, TextStyle } from "@shopify/polaris";
import axios from "axios";
import { PrintMinor } from "@shopify/polaris-icons";
import { store } from "../../store";

import SkeletonProvider from "../../components/skeleton_provider.js";
import SearchField from "../../components/search_field.js";
import TimeRangeModal from "../../components/TimeRangeModal.js";
import { toastr } from "../../components/toastr";
import API from "../../API";

class GroupEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			flexTimeRangeModalOpen: false,
			showRemoveModal: false,
			id: props.match.params.id,
			cancelToken: null,
			selectedItems: [],
			saving: false,
			count: 0,
			loading: true,
			offset: 0,
			limit: 10000,
			items: [],
		};
	}

	componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	componentWillUnmount() {
		this.cancelRequest();
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/groups/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, saving: false, form: result.data.group });
				this.doSearch(this.state.offset);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/groups/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Group created");
				this.props.history.replace("/admin/groups/" + result.data.group.id);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	doSearch(offset) {
		const CancelToken = axios.CancelToken;

		API.get("/api/users.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params: {
				group_id: this.state.id,
				limit: this.state.limit,
				offset,
			},
		})
			.then((result) => {
				this.setState({
					count: result.data.count,
					loading: false,
					items: result.data.users,
					cancelToken: null,
				});
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					console.debug("Request canceled");
				} else {
					toastr.error(error);
				}
			});
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	setGroupAdmin(user, value) {
		this.setState({ saving: true });
		API.put("/api/groups/" + this.state.id + "/users/" + user.id + ".json", { admin: value })
			.then((result) => {
				this.setState({ saving: false });
				this.doSearch(this.state.offset);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	setMainGroup(user, value) {
		this.setState({ saving: true });
		API.put("/api/groups/" + this.state.id + "/users/" + user.id + ".json", { main: true })
			.then((result) => {
				this.setState({ saving: false });
				this.doSearch(this.state.offset);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	renderItem(item) {
		const url = "/api/users/" + item.id;
		const media = <Avatar customer size="medium" name={item.name} source={item.avatar} />;

		var myGroup = null;

		for (var i = 0; i < item.groups.length; i++) {
			if (item.groups[i].id == this.state.id) {
				myGroup = item.groups[i];
				break;
			}
		}

		var shortcutActions = [];

		if (myGroup) {
			if (!myGroup.admin) {
				shortcutActions.push({ content: "Gruppledare", loading: this.state.saving, onAction: this.setGroupAdmin.bind(this, item, true) });
			} else {
				shortcutActions.push({ content: "Arbetare", loading: this.state.saving, onAction: this.setGroupAdmin.bind(this, item, false) });
			}

			if (!myGroup.main) {
				shortcutActions.push({ content: "Huvudgrupp", loading: this.state.saving, onAction: this.setMainGroup.bind(this, item, true) });
			}
		}

		shortcutActions.push({ content: "Ta bort", destructive: true, onAction: this.removeUser.bind(this, item) });

		return (
			<ResourceList.Item id={item.id} media={media} url={url} shortcutActions={store.getState().user.read_only ? null : shortcutActions}>
				<div>{item.first_name + " " + item.last_name}</div>
				<div>
					<TextStyle variation="subdued">{item.email}</TextStyle>
				</div>
				<Stack>
					{myGroup && myGroup.admin ? <Badge>Gruppledare</Badge> : null}
					{myGroup && !myGroup.main ? <Badge>Extra</Badge> : null}
				</Stack>
			</ResourceList.Item>
		);
	}

	addUser(user) {
		API.post("/api/groups/" + this.state.id + "/users.json", {
			user_ids: [user.id],
		})
			.then((result) => {
				this.state.form.user_ids.push(user.id);
				this.setState({ form: this.state.form });
				this.doSearch(this.state.offset);
				toastr.success("Added user");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeUser(user) {
		API.delete("/api/groups/" + this.state.id + "/users/" + user.id + ".json")
			.then((result) => {
				var userIds = [];
				for (var i = 0; i < this.state.form.user_ids.length; i++) {
					if (this.state.form.user_ids[i] != user.id) {
						userIds.push(this.state.form.user_ids[i]);
					}
				}
				this.state.form.user_ids = userIds;
				this.setState({ form: this.state.form });
				this.doSearch(this.state.offset);
				toastr.success("Removed user");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	toggleUser(user) {
		if (this.state.form.user_ids.indexOf(user.id) >= 0) {
			this.removeUser(user);
		} else {
			this.addUser(user);
		}
	}

	removeItem() {
		this.setState({ saving: true });
		API.delete("/api/groups/" + this.state.id + ".json")
			.then((result) => {
				this.setState({ saving: false });
				toastr.success("Grupp borttagen");
				this.props.history.replace("/admin/groups");
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	printRange(fromDate, toDate) {
		this.closeTimeRangeModal();
		window.open("/api/groups/" + this.state.id + "/report.pdf?from=" + this.getYmd(fromDate) + "&to=" + this.getYmd(toDate));
	}

	printFlexRange(fromDate, toDate) {
		this.closeFlexTimeRangeModal();
		window.open("/api/groups/" + this.state.id + "/report_flex.pdf?from=" + this.getYmd(fromDate) + "&to=" + this.getYmd(toDate));
	}

	printReport() {
		this.setState({ timeRangeModalOpen: true });
	}

	closeTimeRangeModal() {
		this.setState({ timeRangeModalOpen: false });
	}

	getYmd(date) {
		var month = date.getMonth() + 1; // months from 1-12
		var day = date.getDate();
		var year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	printFlexReport() {
		this.setState({ flexTimeRangeModalOpen: true });
	}

	closeFlexTimeRangeModal() {
		this.setState({ flexTimeRangeModalOpen: false });
	}

	removeModal() {
		this.setState({ showRemoveModal: true });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	render() {
		var fromDate = new Date();
		var toDate = new Date();
		if (this.state.loading) {
			return <SkeletonProvider />;
		}

		return (
			<Page
				title={this.state.form.name}
				backAction={{ content: "Grupper", url: "/admin/groups" }}
				primaryAction={store.getState().user.read_only ? null : { content: "Spara", loading: this.state.saving, onAction: this.saveForm.bind(this) }}
				secondaryActions={[
					{
						content: (
							<Stack spacing="extraTight">
								<Icon source={PrintMinor} />
								<div>Skriv ut veckorapport</div>
							</Stack>
						),
						onAction: this.printReport.bind(this),
					},
					{
						content: (
							<Stack spacing="extraTight">
								<Icon source={PrintMinor} />
								<div>Skriv ut tidrapport</div>
							</Stack>
						),
						onAction: this.printFlexReport.bind(this),
					},
				]}
			>
				<Layout>
					<Layout.AnnotatedSection title="Gruppdetaljer" description="Fyll i detaljer om gruppen">
						<Card>
							<Card.Section>
								<FormLayout>
									<TextField label="Namn" value={this.state.form.name} onChange={this.updateForm.bind(this, "name")} />
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection title="Medlemmar" description="Användare som tillhör gruppen">
						<Card title={"Medlemmar" + (this.state.count ? " (" + this.state.count + ")" : "")}>
							<Card.Section>
								<SearchField
									placeholder="Sök efter användare att lägga till"
									resourceName={{
										singular: "användare",
										plural: "användare",
									}}
									resource="users"
									label_handle="name"
									onSelect={this.toggleUser.bind(this)}
								/>
							</Card.Section>
							<ResourceList
								selectedItems={this.state.selectedItems}
								onSelectionChange={this.onSelectionChange.bind(this)}
								hasMoreItems={this.state.count > this.state.limit}
								showHeader
								resourceName={{
									singular: "användare",
									plural: "användare",
								}}
								items={this.state.items}
								renderItem={this.renderItem.bind(this)}
							/>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<Modal
					open={this.state.showRemoveModal}
					onClose={this.closeRemoveModal.bind(this)}
					title="Ta bort grupp?"
					secondaryActions={[
						{
							content: "Stäng",
							onAction: this.closeRemoveModal.bind(this),
						},
					]}
					primaryAction={{
						destructive: true,
						content: "Ta bort",
						loading: this.state.saving,
						onAction: this.removeItem.bind(this),
					}}
				>
					<Modal.Section>Är du säker?</Modal.Section>
				</Modal>
				<PageActions
					primaryAction={
						store.getState().user.read_only
							? null
							: {
									content: "Spara",
									loading: this.state.saving,
									onAction: this.saveForm.bind(this),
							  }
					}
					secondaryActions={
						store.getState().user.read_only
							? []
							: [
									{
										content: "Ta bort",
										destructive: true,
										loading: this.state.saving,
										onAction: this.removeModal.bind(this),
									},
							  ]
					}
				/>
				<TimeRangeModal
					open={this.state.timeRangeModalOpen}
					onClose={this.closeTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printRange.bind(this)}
					weeks
				/>
				<TimeRangeModal
					open={this.state.flexTimeRangeModalOpen}
					onClose={this.closeFlexTimeRangeModal.bind(this)}
					from={fromDate}
					to={toDate}
					onChange={this.printFlexRange.bind(this)}
				/>
			</Page>
		);
	}
}

export default GroupEdit;

/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { Button, Labelled } from "@shopify/polaris";

import SearchField from "./search_field";

class ResourcePicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showSearch: false,
			item: props.item,
			// eslint-disable-next-line react/no-unused-state
			resource: props.resource,
			// eslint-disable-next-line react/no-unused-state
			id_handle: props.id_handle ? props.id_handle : "id",
			default_caption: props.caption ? props.caption : "Välj " + props.resourceName.singular,
			label_handle: props.label_handle ? props.label_handle : "title",
		};
	}

	componentWillReceiveProps(props) {
		this.setState({
			// showSearch: true,
			item: props.item,
			// eslint-disable-next-line react/no-unused-state
			resource: props.resource,
			// eslint-disable-next-line react/no-unused-state
			id_handle: props.id_handle ? props.id_handle : "id",
			default_caption: props.caption ? props.caption : "Välj " + props.resourceName.singular,
			label_handle: props.label_handle ? props.label_handle : "title",
		});
	}

	showSearch() {
		this.setState({ showSearch: true });
	}

	selectItem(item) {
		this.setState({ item, showSearch: false });
		this.props.onChange(item);
	}

	onBlur() {
		if (this.props.onBlur) this.props.onBlur();
		if (this.props.required && !this.props.item) {
			// eslint-disable-next-line react/no-unused-state
			// this.setState({ error: "123Måste fylla i fältet", showSearch: false });
			this.setState({ showSearch: false });
		} else {
			this.setState({ showSearch: false });
		}
	}

	render() {
		let caption = null;
		if (!this.state.item) {
			caption = this.state.default_caption;
		} else {
			caption = this.props.renderLabel ? this.props.renderLabel(this.state.item) : this.state.item[this.state.label_handle];
		}
		const label = `${this.props.label || ""}${this.props.required ? " *" : ""}`;

		return (
			<div>
				{!this.state.showSearch ? (
					<Labelled {...this.props} label={label}>
						<Button
							fullWidth
							disabled={this.props.disabled}
							onClick={this.showSearch.bind(this)}
							disclosure={!this.props.disabled && !this.state.item ? (this.state.showSearch ? "up" : "down") : false}
							textAlign={this.props.textAlign}
						>
							{(!this.props.disabled || this.state.item || this.props.caption) && caption}
						</Button>
					</Labelled>
				) : (
					<SearchField
						{...this.props}
						required={false} // TEMOPORARY, causes the popup to close otherwise if true
						params={this.props.params}
						autoFocus
						onChange={() => {}}
						onBlur={this.onBlur.bind(this)}
						onSelect={this.selectItem.bind(this)}
					/>
				)}
				{/* {(this.state.error || this.props.error) && !this.state.showSearch ? <InlineError message={this.props.error} /> : null} */}
			</div>
		);
	}
}

export default ResourcePicker;

import { Button, FormLayout } from "@shopify/polaris";
import React, { useMemo } from "react";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import FortnoxInvoiceRow from "./FortnoxInvoiceRow";
import { ColumnHeaderWrapper, ColumnHeader } from "./styles";

type FortnoxInvoiceRowsProps = {
	invoice: FortnoxInvoiceType;
	onChange: (key: string) => (value: any) => void;
	isSaving: boolean;
	onRemove?: (row: FortnoxInvoiceRowType) => void;
};

const FortnoxInvoiceRows = ({ invoice, onChange, isSaving, onRemove }: FortnoxInvoiceRowsProps) => {
	const handleChangeForm = (index: number, row: FortnoxInvoiceRowType | null) => {
		const rows = [...(invoice.InvoiceRows || [])];
		if (!row) {
			onRemove?.(rows[index] as FortnoxInvoiceRowType);
			rows.splice(index, 1);
		} else {
			rows[index] = row;
		}

		onChange("InvoiceRows")(rows);
	};

	const totalRow = useMemo(() => {
		const sum = (invoice?.InvoiceRows as any)?.reduce((acc, row) => {
			return acc + parseFloat(String(row.DeliveredQuantity || 0)) * parseFloat(String(row.Price || 0));
		}, 0);

		return {
			ArticleNumber: "",
			Description: "",
			DeliveredQuantity: "",
			Unit: "",
			Price: 0,
			Total: sum,
			sum,
		};
	}, [invoice?.InvoiceRows]) as FortnoxInvoiceRowType;
	const disabled = isSaving || invoice?.Cancelled || invoice?.Booked;
	const rows = invoice?.InvoiceRows || [];

	return (
		<FormLayout>
			<ColumnHeaderWrapper>
				<ColumnHeader>Artikelnr</ColumnHeader>
				<ColumnHeader>Benämning</ColumnHeader>
				<ColumnHeader>Antal</ColumnHeader>
				<ColumnHeader>Enhet</ColumnHeader>
				<ColumnHeader>À-Pris</ColumnHeader>
				<ColumnHeader>Summa</ColumnHeader>
			</ColumnHeaderWrapper>
			{rows?.map((row, index: number, array: any[]) => (
				<FortnoxInvoiceRow disabled={disabled} invoice={invoice} key={index + array.length} row={row} index={index} onChange={handleChangeForm} />
			))}
			{!disabled && <FortnoxInvoiceRow key={rows?.length} invoice={invoice} row={null} index={rows?.length} onChange={handleChangeForm} />}{" "}
			<FortnoxInvoiceRow
				prefix={
					!disabled && (
						<div>
							<Button
								onClick={() => {
									handleChangeForm(rows?.length, {
										ArticleNumber: "",
										Description: "",
										DeliveredQuantity: 0,
										Unit: "",
										Price: 0,
										Total: 0,
										sum: 0,
									} as FortnoxOfferRowType);
								}}
								icon={CirclePlusMajor}
							/>
						</div>
					)
				}
				plain
				disabled
				invoice={invoice}
				row={totalRow as FortnoxInvoiceRowType}
			/>
		</FormLayout>
	);
};
export default FortnoxInvoiceRows;

/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import { Button, FormLayout, Icon, Popover, Select, Stack, TextField, Tooltip } from "@shopify/polaris";
import { MobileCancelMajor, FormsMajor } from "@shopify/polaris-icons";

class AddVariable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onFocus() {
		this.setState({ focused: true });
	}

	onBlur() {
		this.setState({ focused: false });
	}

	onOpen() {
		this.setState({ open: true });
	}

	onToggle() {
		this.setState((c) => ({ open: !c.open }));
	}

	onClose(force) {
		if (!this.state.focused || force) {
			this.setState({ open: false });
		}
	}

	addVariable(v, type, data) {
		this.props.addVariable(v, type, data);
		this.onClose(true);
	}

	render() {
		return (
			<Popover
				activator={
					<Tooltip content="Lägg till dynamisk text">
						<Button onClick={this.onToggle.bind(this)} icon={FormsMajor} />
					</Tooltip>
				}
				active={this.state.open}
				onClose={this.onClose.bind(this)}
			>
				<AddVariableContent
					focused={this.state.focused}
					onOpen={this.onOpen.bind(this)}
					onClose={this.onClose.bind(this)}
					addVariable={this.addVariable.bind(this)}
					onFocus={this.onFocus.bind(this)}
					onBlur={this.onBlur.bind(this)}
				/>
			</Popover>
		);
	}
}

class AddVariableContent extends Component {
	constructor(props) {
		super(props);
		this.state = { data: 0 };
		this.fixedVaribles = [
			{ label: "Eget värde", value: `` },
			{ label: "Dagens datum", value: `Dagens datum` },
			{ label: "Företagsnamn", value: `Företagsnamn` },
			{ label: "Företagets epost", value: `Företagets epost` },
			{ label: "Företagets telefon", value: `Företagets telefon` },
			{ label: "Organisationsnummer", value: `Organisationsnummer` },
			{ label: "Förnamn efternamn", value: `Kontaktperson namn` },
			{ label: "Epost", value: `Kontaktperson Epost` },
			{ label: "Telefon", value: `Kontaktperson Telefon` },
			{ label: "Användarens namn", value: `Användarens namn` },
			{ label: "Användarens profilbild", value: `PF`, type: "profile" },
			{ label: "Användarens telefonnr", value: `Användarens telefonnr` },
			{ label: "Användarens e-post", value: `Användarens e-post` },
		];
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKeyDown);
		setTimeout(() => {
			this.setState({ focused: true });
		}, 0);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyDown);
	}

	onKeyDown(e) {
		if (e.key === "Enter" && this.props.focused) {
			e.preventDefault();
			e.stopPropagation();
			this.addVariable();
		}
	}

	addVariable() {
		const option = this.fixedVaribles.find((v) => v.value == this.state.input);

		this.props.addVariable(this.state.input, option && option.type, this.state.data);
	}

	updateForm(field, value) {
		this.setState({ [field]: value });
	}

	render() {
		// const option = this.fixedVaribles.find((v) => v.value == this.state.input);

		return (
			<div className="variable-popup">
				<Popover.Section>
					<button className="close-button" onClick={this.props.onClose}>
						<Icon source={MobileCancelMajor} />
					</button>
					<FormLayout>
						<TextField
							onFocus={this.props.onFocus}
							onBlur={this.props.onBlur}
							label="Variabel namn"
							value={this.state.input}
							onChange={this.updateForm.bind(this, "input")}
							focused={this.state.focused}
							// autoFocus
						/>

						<Stack distribution="equalSpacing">
							{/* <Button onClick={this.closeModal.bind(this, "variable")}>Avbryt</Button> */}
							<Select options={this.fixedVaribles} onChange={this.updateForm.bind(this, "input")} value={this.state.input} />
							<Button primary onClick={this.addVariable.bind(this)}>
								Skapa
							</Button>
						</Stack>
					</FormLayout>
				</Popover.Section>
			</div>
		);
	}
}
export default AddVariable;

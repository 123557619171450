import { Button, ButtonGroup, Page, Select, Spinner, Tooltip } from "@shopify/polaris";
import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import MultiSelect from "src/js/components/multiSelect";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import ResourcePicker from "../../components/resource_picker";
import View from "./View";
import PlanningEventModal from "./PlanningEventModal";
import API from "../../API";
import UpcomingProjectsSheet from "./UpcomingProjectsSheet";

class PlanningIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			week: moment().isoWeek(),
			year: moment().isoWeekYear(),
			month: moment().month(),
			planningEvents: [],
			view: "week",
			event: props?.match?.params?.id ? { id: props?.match?.params?.id } : null,
			open: !!props?.match?.params?.id,
			groups: [],
		};
		this.absenseOptions = ["Arbetstidsförkortning", "Föräldraledig", "Helgdagsersättning", "Kompledig", "Semester", "Sjuk", "Tjänstledig", "VAB"];
	}

	componentDidMount() {
		this.fetch();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.match.params.id && nextProps.match.params.id !== this.props.match.params.id) {
			this.setState({ event: { id: nextProps.match.params.id }, open: true });
		} else {
			this.setState({ event: null, open: false });
		}
	}

	componentWillUnmount() {
		this.cancelRequests();
	}

	handleOpen(event) {
		this.setState({ open: true, event });
	}

	handleClose() {
		this.setState({ open: false, event: null });
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequests() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	sortEvents(events) {
		return events.sort((a, b) => moment(a.start_at).isAfter(b.start_at));
	}

	async fetch() {
		this.cancelRequests();
		const params =
			this.state.view === "week"
				? {
						start_at: moment().set("isoWeek", this.state.week).set("year", this.state.year).startOf("isoWeek").format("YYYY-MM-DD HH:mm"),
						end_at: moment().set("isoWeek", this.state.week).set("year", this.state.year).endOf("isoWeek").format("YYYY-MM-DD HH:mm"),
				  }
				: {
						start_at: moment()
							.set("isoWeek", this.state.week)
							.set("month", this.state.month)
							.set("year", this.state.year)
							.startOf("month")
							.format("YYYY-MM-DD HH:mm"),
						end_at: moment()
							.set("isoWeek", this.state.week)
							.set("month", this.state.month)
							.set("year", this.state.year)
							.endOf("month")
							.format("YYYY-MM-DD HH:mm"),
				  };

		if (this.state.type) {
			params.type = this.state.type;
		}

		if (this.state.type === "project" && this.state.project) {
			params.project_id = this.state.project.id;
		}

		if (this.state.type === "absense" && this.state.absense) {
			params.absense_type = this.state.absense;
		}

		if (this.state.groups) {
			params.group_ids = this.state.groups;
		}

		this.setState({ loading: true });
		return API.get("/api/planning_events.json", { params, cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)) })
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					return;
				}

				const planningEvents = this.sortEvents(result.data.planningEvents || []);

				this.setState({
					planningEvents,
					loading: false,
				});

				return planningEvents;
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	}

	updateState(field, value) {
		if (["year", "month", "week"].includes(field)) {
			this.state[field] = parseInt(value);
		} else {
			this.state[field] = value;
		}
		this.setState(this.state, this.fetch.bind(this));
	}

	updateView(view) {
		this.setState({ view }, this.fetch.bind(this));
	}

	getMomentDate(view) {
		const date = moment();

		if (view === "month") {
			return moment(date).set("year", this.state.year).set("month", this.state.month);
		}
		return moment(date).set("year", this.state.year).set("month", this.state.month).set("isoWeek", this.state.week);
	}

	next() {
		if (this.state.view === "week") {
			const week = this.state.week + 1;

			if (week > moment().set("year", this.state.year).set("isoWeek", this.state.week).isoWeeksInISOWeekYear()) {
				this.setState(
					{ week: 1, year: this.state.year + 1, month: moment().set("year", this.state.year).set("isoWeek", week).month() },
					this.fetch.bind(this)
				);
			} else {
				this.setState({ week }, this.fetch.bind(this));
			}
		}

		if (this.state.view === "month") {
			const month = this.state.month + 1;
			if (month > 11) {
				this.setState({ month: 1, week: 1, year: this.state.year + 1 }, this.fetch.bind(this));
			} else {
				this.setState({ month, week: moment().set("year", this.state.year).set("month", month).startOf("month").isoWeek() }, this.fetch.bind(this));
			}
		}
	}

	prev() {
		if (this.state.view === "week") {
			const week = this.state.week - 1;
			if (week < 1) {
				this.setState(
					{
						week: moment()
							.set("year", this.state.year - 1)
							.isoWeeksInISOWeekYear(),
						year: this.state.year - 1,
						month: moment().set("year", this.state.year).set("isoWeek", week).month(),
					},
					this.fetch.bind(this)
				);
			} else {
				this.setState({ week }, this.fetch.bind(this));
			}
		}
		if (this.state.view === "month") {
			const month = this.state.month - 1;
			if (month < 0) {
				this.setState(
					{
						week: moment().set("year", this.state.year).set("month", month).isoWeeksInISOWeekYear(),
						year: this.state.year - 1,
						month: 11,
					},
					this.fetch.bind(this)
				);
			} else {
				this.setState({ month, week: moment().set("year", this.state.year).set("month", month).isoWeeksInISOWeekYear() }, this.fetch.bind(this));
			}
		}
	}

	onDragEnd(e) {
		if (!e.destination || !e.destination.droppableId || !e.draggableId) return null;
		const [userId, date] = e.destination.droppableId.split("__");
		const event = this.state.planningEvents.find((event) => String(event.id) === String(e.draggableId));
		if (moment(date).isSame(moment(event.start_at), "D") && userId === event.user.id) return null;
		const time = moment(event.start_at).format("HH:mm");
		const startAt = moment(date).format("YYYY-MM-DD") + " " + time;
		const differenceInMs = moment(startAt).diff(moment(event.start_at), "milliseconds");
		const user = store.getState().users.find((u) => u.id == userId);
		const data = {
			start_at: startAt,
			end_at: moment(moment(event.end_at).add(differenceInMs, "milliseconds")).format("YYYY-MM-DD HH:mm"),
			user,
		};
		const index = this.state.planningEvents.findIndex((planningEvent) => planningEvent.id == event.id);
		this.state.planningEvents.splice(index, 1, Object.assign(event, data));
		this.setState({ planningEvents: this.state.planningEvents });

		API.put(`/api/planning_events/${e.draggableId}.json`, data)
			.then((result) => {
				if (result.data.error) {
					return;
				}
				if ((event.user && event.user.id) !== (result.data.planningEvent.user && result.data.planningEvent.user.id)) {
					const movedToUser = store.getState().users.find((u) => u.id === result.data.planningEvent.user.id);
					toastr.success(`Event uppdaterat och flyttat till användare ${movedToUser && movedToUser.name}`);
				} else {
					toastr.success("Event uppdaterat");
				}
				this.fetch();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	render() {
		const startYear = moment().subtract(3, "y").format("YYYY");

		const yearOptionsValues = Array.from(Array(7)).map((i, index) => {
			const v = moment(startYear).add(index, "y").format("YYYY");
			return parseInt(v);
		});
		yearOptionsValues.push(parseInt(this.state.year));

		const yearOptions = Array.from(new Set(yearOptionsValues))
			.sort((a, b) => a - b)
			.map((v) => {
				return { label: v, value: parseInt(v) };
			});

		const weekOptions = Array.from(Array(moment().isoWeeksInISOWeekYear())).map((o, index) => {
			const date = moment()
				.locale("sv")
				.set("year", this.state.year)
				.set("month", this.state.month)
				.set("isoWeek", index + 1);

			return {
				label: `${index + 1} (${date.startOf("isoWeek").format("D")} ${date.startOf("isoWeek").format("MMM")} - ${date
					.endOf("isoWeek")
					.format("D")} ${date.startOf("isoWeek").format("MMM")})`,
				value: index + 1,
			};
		});

		const groupOptions = store.getState().groups.map((group) => {
			return {
				label: group.name,
				value: group.id,
			};
		});

		const monthOptions = [
			"Januari",
			"Februari",
			"Mars",
			"April",
			"Maj",
			"Juni",
			"Juli",
			"Augusiti",
			"September",
			"Oktober",
			"November",
			"December",
		].map((month, index) => {
			return {
				label: month,
				value: index,
			};
		});
		return (
			<Page
				fullWidth
				title="Planering"
				primaryAction={{
					content: "Nytt inlägg",
					onAction: () =>
						this.handleOpen({
							start_at: moment().format("YYYY-MM-DD HH:mm"),
							end_at: moment().add(1, "h").format("YYYY-MM-DD HH:mm"),
							type: "project",
						}),
				}}
			>
				<Wrapper>
					<Header>
						<FlexDiv>
							<ButtonGroup segmented>
								<Tooltip content={`Tidigare ${this.state.view === "week" ? "Vecka" : "Månad"}`}>
									<Button onClick={this.prev.bind(this)} icon={ChevronLeftMinor} />
								</Tooltip>
								<Button primary={this.state.view === "week"} onClick={this.updateView.bind(this, "week")}>
									Vecka
								</Button>
								{/* <Button primary={this.state.view === "month"} onClick={this.updateView.bind(this, "month")}>
									Månad
								</Button> */}
								<Tooltip content={`Nästa ${this.state.view === "week" ? "Vecka" : "Månad"}`}>
									<Button onClick={this.next.bind(this)} icon={ChevronRightMinor} />
								</Tooltip>
							</ButtonGroup>
							<FlexDiv>
								<span>År:</span>
								<Select options={yearOptions} value={this.state.year} onChange={this.updateState.bind(this, "year")} />
							</FlexDiv>
							{this.state.view === "week" ? (
								<FlexDiv selectWidth={180}>
									<span>Vecka:</span>
									<Select options={weekOptions} value={this.state.week} onChange={this.updateState.bind(this, "week")} />
								</FlexDiv>
							) : (
								<FlexDiv selectWidth={135}>
									<span>Månad:</span>
									<Select options={monthOptions} value={this.state.month} onChange={this.updateState.bind(this, "month")} />
								</FlexDiv>
							)}
							<FlexDiv>
								<span>Typ:</span>
								<Select
									label=""
									onChange={this.updateState.bind(this, "type")}
									options={[
										{ label: "Alla", value: "" },
										{ label: "Projekt", value: "project" },
										{ label: "Frånvaro", value: "absense" },
										{ label: "Kommentar", value: "comment" },
									]}
									value={this.state.type}
								/>
								{this.state.type === "project" && (
									<>
										<span>Projekt:</span>
										<ResourcePicker
											placeholder="Sök projekt"
											caption="Välj projekt"
											resource="projects"
											label=""
											resourceName={{
												singular: "projekt",
												plural: "projekt",
											}}
											item={this.state.project}
											onChange={this.updateState.bind(this, "project")}
											label_handle="label"
											// textAlign={"left"}
										/>
									</>
								)}
								{this.state.type === "absense" && (
									<>
										<span>Frånvaro:</span>
										<Select
											onChange={this.updateState.bind(this, "absense")}
											options={[{ label: "Alla", value: "" }].concat(
												this.absenseOptions.map((i) => ({
													label: i,
													value: i,
												}))
											)}
											value={this.state.absense}
										/>
									</>
								)}
							</FlexDiv>
							{!!groupOptions?.length && (
								<FlexDiv selectWidth={135}>
									<span>Grupp:</span>
									<MultiSelect options={groupOptions} selected={this.state.groups} onChange={this.updateState.bind(this, "groups")} />
								</FlexDiv>
							)}
							{this.state.loading && <Spinner size="small" />}
						</FlexDiv>
						<FlexDiv>
							<UpcomingProjectsSheet
								openPlanning={(project) => {
									this.setState({ event: { project, type: "project" }, open: true });
								}}
							/>
						</FlexDiv>
					</Header>
					{this.state.view === "month" ? (
						<View
							start_at={this.getMomentDate("month").startOf("month").format("YYYY-MM-DD")}
							end_at={this.getMomentDate("month").endOf("month").format("YYYY-MM-DD")}
							planningEvents={this.state.planningEvents}
							onEventClick={this.handleOpen.bind(this)}
							view={this.state.view}
							onDragEnd={this.onDragEnd.bind(this)}
							selectedGroupIds={this.state.groups}
						/>
					) : (
						<View
							start_at={this.getMomentDate("week").startOf("isoWeek").format("YYYY-MM-DD")}
							end_at={this.getMomentDate("week").endOf("isoWeek").format("YYYY-MM-DD")}
							planningEvents={this.state.planningEvents}
							onEventClick={this.handleOpen.bind(this)}
							view={this.state.view}
							onDragEnd={this.onDragEnd.bind(this)}
							selectedGroupIds={this.state.groups}
						/>
					)}
					<PlanningEventModal
						handleClose={this.handleClose.bind(this)}
						open={this.state.open}
						event={this.state.event}
						history={this.props.history}
						onComplete={() => {
							this.props.history.replace(`/admin/planning`);
							this.handleClose();
							this.fetch();
						}}
					/>
				</Wrapper>
			</Page>
		);
	}
}
export default PlanningIndex;

const Wrapper = styled.div`
	overflow: auto;
`;
const Header = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	justify-content: space-between;
`;

const FlexDiv = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;

	.Polaris-Select {
		width: ${({ selectWidth }) => (selectWidth ? `${selectWidth}px` : "unset")};
	}
`;

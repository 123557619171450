/* eslint-disable import/order */
import React, { Component } from "react";
import { store } from "../../store";
import { ResourceList, Card, Layout, FormLayout, Page, Spinner, TextStyle, TextField, Button, Stack, Select } from "@shopify/polaris";
import Moment from "react-moment";
import "moment-timezone";
import Upload from "../../components/Upload.js";
import BillingServiceModal from "../../components/billingServiceModal.js";
import InfoView from "../../components/InfoView";
import { getAdminOptions, getAdminPrice, getPackageOptions, getPackagePrice } from "../../Utilities";
import moment from "moment";
import { connect } from "react-redux";
import { toastr } from "../../components/toastr";
import API from "../../API";

class AccountIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			originalQuoteIntro: store.getState().account.quote_intro,
			quote_intro: store.getState().account.quote_intro,
			originalProjectNumber: store.getState().account.project_number_format,
			project_number_format: store.getState().account.project_number_format,
			logo: store.getState().account.logo_url ? { url: store.getState().account.logo_url } : null,
			backgroundImage: store.getState().account.background_url ? { url: store.getState().account.background_url } : null,
			openLogoUpload: false,
			loading: false,
			loading_billings: true,
			billing_services: [],
			openBackgroundUpload: false,
			showAddCreditCardModal: false,
			accountCosts: {},
		};
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.fortnoxWindow = null;
	}

	componentDidMount() {
		if (window.opener) {
			window.opener.postMessage(Object.fromEntries(new URLSearchParams(window.location.search)));
			window.close();
		}
		// this.fetchBillingServices();
		this.getAccountCosts();
	}

	getAccountCosts() {
		this.setState({ accountCostLoading: true });
		API.get("/api/account/get_costs.json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ accountCostLoading: false });
					return;
				}
				this.setState({ accountCosts: result.data || {}, accountCostLoading: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ accountCostLoading: false });
			});
	}

	uploadLogo(item) {
		this.setState({ logo: item });
		API.post("/api/account/logo.json", { base64: item.url })
			.then((result) => {
				store.dispatch({ type: "SET_LOGO_URL", logo_url: result.data.account.logo_url });
				toastr.success("Logo ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	uploadBackground(item) {
		this.setState({ backgroundImage: item });
		API.post("/api/account/background_image.json", { base64: item.url })
			.then((result) => {
				store.dispatch({ type: "SET_BACKGROUND_URL", background_url: result.data.account.background_url });
				toastr.success("Bakgrundsbild ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchBillingServices() {
		this.setState({ loading_billings: true });
		API.get("/api/billing_services.json")
			.then((result) => {
				this.setState({ loading_billings: false, billing_services: result.data.billing_services });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	showAddCreditCardModal() {
		this.setState({ showAddCreditCardModal: true });
	}

	closeCreditCardModal() {
		this.setState({ showAddCreditCardModal: false });
	}

	makeDefault(id) {
		this.setState({ loading_billings: true });
		API.get("/api/billing_services/" + id + "/default.json")
			.then((result) => {
				this.fetchBillingServices();
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading_billings: false });
			});
	}

	renderBilling(item) {
		var shortcuts = [{ content: "Make default", onAction: this.makeDefault.bind(this, item.id) }];
		if (item.default) {
			shortcuts = [];
		}
		return (
			<ResourceList.Item id={item.id} shortcutActions={shortcuts}>
				{item.default ? "default" : null} {item.last4} {item.card_type}
			</ResourceList.Item>
		);
	}

	addedBilling(billing) {
		this.setState({ showAddCreditCardModal: false });
		this.fetchBillingServices();
	}

	updateProjectNumberFormat() {
		this.setState({ saving: true });
		API.put("/api/account.json", { project_number_format: this.state.project_number_format })
			.then((result) => {
				this.setState({
					saving: false,
					project_number_format: result.data.account.project_number_format,
					originalProjectNumber: result.data.account.project_number_format,
				});
				store.dispatch({ type: "SET_PROJECT_NUMBER_FORMAT", project_number_format: result.data.account.project_number_format });
				toastr.success("Projektnummer format ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateFortnoxSettings() {
		this.setState({ saving: true });
		API.put("/api/account.json", { fortnox_article: this.state.fortnox_article })
			.then((result) => {
				this.setState({
					saving: false,
					fortnox_article: result.data.account.fortnox_article,
				});
				store.dispatch({ type: "SET_FORTNOX_ARTICLE", fortnox_article: result.data.account.fortnox_article });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateQuoteIntro() {
		this.setState({ saving: true });
		API.put("/api/account.json", { quote_intro: this.state.quote_intro })
			.then((result) => {
				this.setState({ saving: false, quote_intro: result.data.account.quote_intro, originalQuoteIntro: result.data.account.quote_intro });
				store.dispatch({ type: "SET_QUOTE_INTRO", quote_intro: result.data.account.quote_intro });
				toastr.success("Offertens inledande text ändrad");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	onFinancialYearChange(v) {
		store.getState().account.financial_year = v * 1;
		store.dispatch({ type: "SET_ACCOUNT", account: store.getState().account });

		API.put("/api/account.json", { financial_year: v })
			.then((result) => {
				if (result.data.error) {
					return;
				}
				store.dispatch({ type: "SET_ACCOUNT", account: result.data.account });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	render() {
		const totalCost = (() => {
			if (this.state.accountCostLoading) return <Spinner size="small" />;
			const adminsPrice = getAdminPrice(
				this.state.accountCosts.admins - this.state.accountCosts.admins_count_included_in_base_price,
				this.state.accountCosts.base_price_admins
			);
			const packagePrice = getPackagePrice(store.getState().account.package, this.state.accountCosts.base_price);

			return adminsPrice + packagePrice;
		})();
		const adminsChooice = (() => {
			if (this.state.accountCostLoading) return <Spinner size="small" />;
			const options = getAdminOptions(store.getState().account.package);
			const selected = options.find(({ label, value }) => value === store.getState().account.admins - 1);
			return selected && selected.label;
		})();
		const packageChooice = (() => {
			if (this.state.accountCostLoading) return <Spinner size="small" />;
			const options = getPackageOptions();
			const selected = options.find(({ label, value }) => value === store.getState().account.package);
			return selected && selected.label;
		})();

		return (
			<Page title="Mitt konto" backAction={{ content: "Inställningar", url: "/admin/settings" }}>
				<Layout>
					<Layout.AnnotatedSection title="Kostnader" description="Se vad ditt konto kostar">
						<Card sectioned>
							{this.state.loading ? (
								<Spinner size="large" color="teal" />
							) : (
								<div>
									<InfoView
										details={[
											{ label: "Paket", value: packageChooice },
											{ label: "Antal admins", value: adminsChooice },
											{ label: "Total kostnad", value: React.isValidElement(totalCost) ? totalCost : `${totalCost}kr` },
										]}
									/>
								</div>
							)}
						</Card>
					</Layout.AnnotatedSection>
					{/* eslint-disable-next-line no-constant-condition */}
					{false ? (
						<Layout.AnnotatedSection
							title="Kontoinformation"
							description={
								<div>
									Se vilket abonnemang du har{" "}
									<Button
										plain
										onClick={() => {
											this.props.history.push("/admin/account/subscription");
										}}
									>
										Byt abonnemang
									</Button>
								</div>
							}
						>
							<Card sectioned>
								<Stack distribution="fill">
									<Stack.Item>
										Medlem sedan:
										<div>
											<Moment parse="YYYY-MM-DD HH:mm" format="YYYY-MM-DD">
												{store.getState().account.created_at}
											</Moment>
										</div>
									</Stack.Item>
									<Stack.Item>
										Abonnemang:
										<div>{store.getState().account.subscription.provider.title}</div>
									</Stack.Item>
								</Stack>
							</Card>
						</Layout.AnnotatedSection>
					) : null}
					<Layout.AnnotatedSection title="Projekt" description="Här kan du ändra hur projektnummer genereras">
						<Card sectioned>
							<TextField
								label="Format för projektnummer"
								helpText="Kod: #year# för år, #year-id# löpnummer som nollställs från årets start, #total-id# löpnummer för alla projekt"
								value={this.state.project_number_format}
								onChange={(value) => {
									this.setState({ project_number_format: value });
								}}
							/>
							{this.state.originalProjectNumber != this.state.project_number_format ? (
								<Button loading={this.state.saving} onClick={this.updateProjectNumberFormat.bind(this)} primary>
									Uppdatera
								</Button>
							) : null}
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Räkenskapsår" description="Välj start månad för ditt räkenskapsår">
						<Card sectioned>
							<Select
								value={store.getState().account.financial_year * 1}
								options={Array.from(Array(12)).map((_, index) => {
									return {
										value: index,
										label: moment().set("month", index).locale("sv").format("MMMM").capitalize(),
									};
								})}
								onChange={this.onFinancialYearChange.bind(this)}
							/>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Offerter" description="Inställningar för hur offerter presenteras">
						<Card sectioned>
							<TextField
								label="Inledande text"
								helpText="Kod: #company# för ditt företagsnamn, #project# för projektets namn"
								value={this.state.quote_intro}
								multiline
								onChange={(value) => {
									this.setState({ quote_intro: value });
								}}
							/>
							{this.state.originalQuoteIntro != this.state.quote_intro ? (
								<Button loading={this.state.saving} onClick={this.updateQuoteIntro.bind(this)} primary>
									Uppdatera
								</Button>
							) : null}
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Profilering" description="Ladda upp logotyp och annat">
						<Card sectioned>
							<FormLayout>
								<Upload
									label="Ladda upp logotyp"
									files={this.state.logo ? [this.state.logo] : []}
									width={200}
									height={70}
									open={this.state.openLogoUpload}
									onUpload={this.uploadLogo.bind(this)}
									onClose={() => {
										this.setState({ openLogoUpload: false });
									}}
								/>
								<Upload
									label="Ladda upp bakgrundsbild"
									files={this.state.backgroundImage ? [this.state.backgroundImage] : []}
									width={400}
									height={400}
									open={this.state.openBackgroundUpload}
									onUpload={this.uploadBackground.bind(this)}
									onClose={() => {
										this.setState({ openBackgroundUpload: false });
									}}
								/>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>

					{/* eslint-disable-next-line no-constant-condition */}
					{false ? (
						<Layout.AnnotatedSection title="Betalningsmedel" description="Dina fakturor betalas med din valda betalmetod.">
							<Card sectioned title="Kontokort">
								{this.state.loading_billings ? (
									<Spinner size="large" color="teal" />
								) : (
									<div>
										{this.state.billing_services.length < 1 ? (
											<TextStyle subdued>Lägg till ett kontokort för att betala dina fakturor.</TextStyle>
										) : (
											<ResourceList
												loading={this.state.loading_billings}
												resourceName={{
													singular: "kontokort",
													plural: "kontokort",
												}}
												items={this.state.billing_services}
												renderItem={this.renderBilling.bind(this)}
											/>
										)}
										<div style={{ marginTop: 20 }}>
											<Button onClick={this.showAddCreditCardModal.bind(this)}>Lägg till kontokort</Button>
										</div>
									</div>
								)}
							</Card>
						</Layout.AnnotatedSection>
					) : null}
				</Layout>
				<BillingServiceModal
					open={this.state.showAddCreditCardModal}
					onComplete={this.addedBilling.bind(this)}
					onClose={this.closeCreditCardModal.bind(this)}
				/>
			</Page>
		);
	}
}

export default connect((state) => ({
	account: state.account,
}))(AccountIndex);

import { Component } from "react";
import { LegacyStack } from "@shopify/polaris";
import Moment from "react-moment";
import moment from "moment";
import IndexTable from "../../components/IndexTable";

class RejectedIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const columns = [
			{
				label: "Vecka",
				handle: "FROM_DATE",
				sortable: true,
				render: (item) => {
					return <b>Vecka{moment(item.from_date).isoWeek()}</b>;
				},
			},
			{
				label: "User",
				handle: "USER",
				sortable: true,
				render: (item) => {
					return item.user?.name;
				},
			},
			{
				label: "Anledning",
				handle: "REASON",
				sortable: true,
				render: (item) => {
					return item.reason;
				},
			},
			{
				label: "Datum",
				render: (item) => {
					return (
						<LegacyStack spacing="extraTight">
							<Moment locale="sv" format="YYYY-MM-DD">
								{item.from_date}
							</Moment>
							<p> - </p>
							<Moment locale="sv" format="YYYY-MM-DD">
								{item.to_date}
							</Moment>
						</LegacyStack>
					);
				},
			},
		];

		return (
			<div>
				<IndexTable
					history={this.props.history}
					columns={columns}
					onClickRow={(item) => {
						this.props.history.push("/admin/rejected_weeks/" + item.id);
					}}
					setRefreshHandler={(refreshHandler) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.refresh = refreshHandler;
					}}
					title="Nekade tider"
					resourceUrl="/api/rejects.json"
					resourceHandle="rejected"
					resourceName={{
						singular: "nekad tid",
						plural: "nekade tider",
					}}
				/>
			</div>
		);
	}
}

export default RejectedIndex;

import React, { Component } from "react";
import styled from "styled-components";
import Moment from "moment";
import { extendMoment } from "moment-range";

import { DragDropContext } from "react-beautiful-dnd";
import EventSlot from "./EventSlot";
import { store } from "../../store";
import { getHoliday } from "./Utilities";

const moment = extendMoment(Moment);

moment.locale("sv");

class View extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { planningEvents, start_at, end_at, view, onDragEnd, selectedGroupIds } = this.props;

		const groups = store.getState().groups;
		const groupUserIds = groups.filter((g) => selectedGroupIds?.includes(g.id)).flatMap((g) => g.users_user_id);

		const range = moment.range(start_at, end_at);
		const dates = [...new Set([...range.by("day")].map((date) => date.format("YYYY-MM-DD")))];

		const userObject = store
			.getState()
			.users.filter(
				(usr) => usr.enabled && !usr.removed && !usr.exlude_from_planning && (groupUserIds?.length ? groupUserIds.includes(usr.id) : true)
			)
			.reduce((acc, user) => {
				acc[user.id] = {
					user,
					events: [],
				};
				return acc;
			}, {});

		const userEvents =
			(planningEvents &&
				planningEvents.reduce((acc, event) => {
					if (!acc[event.user.id]) {
						acc[event.user.id] = {
							events: [event],
							user: event.user,
						};
					} else {
						acc[event.user.id].events.push(event);
					}
					return acc;
				}, userObject)) ||
			{};

		return (
			<Wrapper className={view}>
				<Header>
					<tr>
						<th>Personal</th>
						{dates.map((date, index) => {
							const holiday = getHoliday(date);

							return (
								<th className={`${(date === moment().format("YYYY-MM-DD") && "current_date") || ""} heading`} key={`${date}-${index}`}>
									{moment(date)
										.locale("sv")
										.format(view === "month" ? "ddd DD" : "ddd D MMM")
										.capitalize()}

									{holiday && <span className="holiday"> ({holiday.title})</span>}
								</th>
							);
						})}
					</tr>
				</Header>
				<DragDropContext
					onDragEnd={onDragEnd}
					// onBeforeDragStart={(e) => {
					// 	setDragging(true);
					// }}
				>
					<Body>
						{userEvents &&
							Object.values(userEvents)
								.sort((a, b) => ((a.user && a.user.name) || "").localeCompare((b.user && b.user.name) || ""))
								.map(({ user = {}, events = [] } = {}, index) => {
									return (
										<tr key={`${user.id}-${index}`}>
											<UserTd>{user.name}</UserTd>
											{dates.map((date, index) => {
												const slotEvents = events.filter(
													(evt) =>
														moment(date).isSame(evt.start_at, "day") ||
														moment(date).isSame(evt.end_at, "day") ||
														moment(date).isBetween(moment(evt.start_at), moment(evt.end_at))
												);

												/* console.debug(
													"sluts event s",
													slotEvents.sort((a, b) => (moment(a.start_at).isAfter(moment(b.start_at)) ? 1 : -1))
												); */
												// console.debug('date in slot', date)
												return (
													<EventSlot
														view={view}
														dateIndex={index}
														key={`${date}-${index}`}
														user={user}
														events={slotEvents}
														onClick={this.props.onEventClick}
														date={date}
													/>
												);
											})}
										</tr>
									);
								})}
					</Body>
				</DragDropContext>
			</Wrapper>
		);
	}
}
export default View;

const Wrapper = styled.table`
	width: 100%;
	border-collapse: collapse;

	table-layout: fixed;

	th:first-child,
	td:first-child {
		width: 200px;
	}

	th,
	td {
		width: calc((100% - 1em) / 2);
	}

	&.week {
		thead {
		}
		tbody {
		}
	}
	&.month {
		thead {
		}
		tbody {
		}
	}
`;
const Header = styled.thead`
	background: var(--subdued);
	color: var(--color);
	th:not(:first-child) {
		border-left: thin solid var(--tertiary);
	}
	th {
		text-align: start;
		padding: 1rem;
		position: relative;

		/* padding: 3px; */
	}
	th.current_date {
		background-color: var(--tertiary);
	}
`;
const Body = styled.tbody`
	tr {
		min-height: 125px !important;
	}
	td {
		border: thin solid var(--tertiary);
		background-color: var(--secondary);
	}
`;

const UserTd = styled.td`
	text-align: left;
	font-size: small;
	font-weight: 400;
	white-space: nowrap;
	padding-inline: 1rem;
	width: fit-content;
	overflow-x: hidden;
	text-overflow: ellipsis;
`;

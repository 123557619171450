import { Component } from "react";
import { Modal, Stack, FormLayout, TextField, Select } from "@shopify/polaris";
import { store } from "../store";

import StripeForm from "./stripe/stripe_form.js";
import { toastr } from "./toastr";
import API from "../API";

class BillingServiceModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, saving: false, form: { first_name: "", last_name: "", street: "", city: "", zip: "", country: "SE" } };
	}

	componentWillReceiveProps(props) {
		this.setState({ open: props.open });
	}

	setTokenizerCallback(callback) {
		this.callback = callback;
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	addCard() {
		this.setState({ saving: true });
		this.callback();
	}

	stripeResponseHandler(response) {
		if (response.error) {
			toastr.error(response.error.message);
			this.setState({ saving: false });
		} else {
			// Post to server
			var form = this.state.form;
			form.token = response.source.id;
			API.post("/api/billing_services.json", form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						return;
					}
					this.props.onComplete(result.data.billing_service);
					toastr.success("Card added");
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	getName() {
		var name = this.state.form.first_name + " " + this.state.form.last_name;
		return name.trim();
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title="Add credit card"
				secondaryActions={[
					{
						content: "Close",
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: "Add",
					loading: this.state.saving,
					onAction: this.addCard.bind(this),
				}}
			>
				<Modal.Section>
					<StripeForm
						name={this.getName()}
						onResponse={this.stripeResponseHandler.bind(this)}
						setTokenizerCallback={this.setTokenizerCallback.bind(this)}
					/>
				</Modal.Section>
				<Modal.Section>
					<FormLayout>
						<Stack distribution="fill">
							<TextField label="First name" value={this.state.form.first_name} onChange={this.updateForm.bind(this, "first_name")} />
							<TextField label="Last name" value={this.state.form.last_name} onChange={this.updateForm.bind(this, "last_name")} />
						</Stack>
						<TextField label="Street address" value={this.state.form.street} onChange={this.updateForm.bind(this, "street")} />
						<Stack distribution="fill">
							<TextField label="Zip" value={this.state.form.zip} onChange={this.updateForm.bind(this, "zip")} />
							<TextField label="City" value={this.state.form.city} onChange={this.updateForm.bind(this, "city")} />
						</Stack>
						<Stack distribution="fill">
							<Select
								label="Country"
								options={store.getState().countries}
								onChange={this.updateForm.bind(this, "country")}
								value={this.state.form.country}
							/>
						</Stack>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default BillingServiceModal;

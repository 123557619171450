import React, { Component } from "react";
import styled from "styled-components";
import Money from "../../../components/Money";

// This is not used anymore, this was used to create the twig
class QuotePDFTable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const rows = this.props.rows.filter((row) => {
			return Object.keys(row).some((key) => row[key]);
		});
		const fontSize = "14px";
		const pageBreakStyle = {
			// pageBreakAfter: "always",
			pageBreakInside: "avoid",
			// pageBreakBefore: "always",
			// breakAfter: "always",
			// breakBefore: "always",
			breakInside: "avoid",
		};
		const thStyles = Object.assign(
			{
				fontSize,
				textAlign: "end",
				lineHeight: fontSize,
			},
			pageBreakStyle
		);
		const tdStyles = Object.assign(
			{
				fontSize,
				lineHeight: fontSize,
				textAlign: "end",
				wordWrap: "break-word",
			},
			pageBreakStyle
		);

		if (rows) {
			return (
				<div style={Object.assign({ width: "100%" }, pageBreakStyle)}>
					<TableWrapper
						style={Object.assign(
							{
								tableLayout: "fixed",
								maxWidth: "100%",
								width: "100%",
								padding: "0 20px",
							},
							pageBreakStyle
						)}
					>
						<thead style={Object.assign({ display: "table-row-group" }, pageBreakStyle)}>
							<tr style={Object.assign(pageBreakStyle)}>
								<th style={Object.assign(thStyles, { textAlign: "start" })}>Artikel</th>
								<th style={thStyles}>Beskrivning</th>
								<th style={thStyles}>Enhet</th>
								<th style={thStyles}>Antal</th>
								<th style={thStyles}>A-pris</th>
								<th style={thStyles}>Rabatt</th>
								<th style={thStyles}>Radsumma</th>
							</tr>
						</thead>

						<tbody style={Object.assign(pageBreakStyle)}>
							{rows &&
								rows.map((i, index) => {
									return (
										<tr style={Object.assign(pageBreakStyle)} key={index}>
											<td width="15%" style={Object.assign(tdStyles, { textAlign: "start" })}>
												{i.article}
											</td>
											<td style={Object.assign(tdStyles)} width="30%">
												{i.description}
											</td>
											<td style={Object.assign(tdStyles)} width="6%">
												{i.unit}
											</td>
											<td style={Object.assign(tdStyles)} width="7%">
												{i.count}
											</td>
											<td style={Object.assign(tdStyles)} width="20%">
												{i.a_price}
											</td>
											<td style={Object.assign(tdStyles)} width="7%">
												{i.discount}
											</td>
											<td style={Object.assign(tdStyles)} width="15%">
												<Money amount={i.subtotal * 100} currency="SEK" style={{ fontSize, lineHeight: fontSize }} />
											</td>
										</tr>
									);
								})}
						</tbody>
					</TableWrapper>

					<div
						style={Object.assign(
							{
								display: "flex",
								justifyContent: "space-between",
								justifyItems: "space-between",
								alignItems: "center",
								padding: "10px 20px",
							},
							pageBreakStyle
						)}
					>
						<b style={Object.assign({ fontSize, lineHeight: fontSize }, pageBreakStyle)}>Totalt exkl. moms</b>
						<b id="total-cost" style={Object.assign({ fontSize }, pageBreakStyle)}>
							<Money amount={this.props.totalCost * 100} currency="SEK" style={{ fontSize, lineHeight: fontSize }} />
						</b>
					</div>
				</div>
			);
		}
		return null;
	}
}
export default QuotePDFTable;

const TableWrapper = styled.table`
	table-layout: fixed;
	max-width: 100%;
	width: 100%;
	padding: 0 20px;
`;

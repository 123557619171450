import { UseQueryResult, useQuery as useReactQuery } from "@tanstack/react-query";

type useQueryProps = {
	queryKey: any;
	queryFn: any;
	refetchOnWindowFocus?: boolean;
	initialData?: any;
	enabled?: boolean;

	[key: string]: any;
};

const useQuery = ({ queryKey, queryFn, refetchOnWindowFocus = false, initialData, enabled: propsEnabled = true, ...rest }: useQueryProps) => {
	const filteredQueryKey = queryKey?.filter(Boolean);
	const enabled = propsEnabled && filteredQueryKey?.length > 0;

	const queryFnWrapper = async (i) => {
		if (!enabled) return initialData || null;

		const res = await queryFn(i);
		if (res === undefined) return initialData || null;
		return res;
	};

	return useReactQuery({
		queryKey: filteredQueryKey,
		queryFn: queryFnWrapper,
		refetchOnWindowFocus,
		initialData,
		enabled,
		...rest,
	}) as UseQueryResult<any, any>;
};

export default useQuery;

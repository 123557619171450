import { Card, FormLayout, Modal, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import ImageThumbnail from "../../components/ImageThumbnail";
import { ImageThumbnailsFlexWrapper } from "../../components/StyledComponents";
import { toastr } from "../../components/toastr";
import Upload from "../../components/Upload";
import API from "../../API";
import ImageCarousel from "../../components/ImageCarousel";
import { Wrapper } from "./WorkOrderUploadSection";

export default class WorkOrderPhotoReportSection extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onOpen() {
		this.setState({ open: true });
	}

	onClose() {
		this.setState({ open: false, upload: null, comment: "" });
	}

	openImageInCarousel(index) {
		this.setState({ carouselIsOpen: true, carouselIndex: index });
	}

	closeCarousel(index) {
		this.setState({ carouselIsOpen: false, carouselIndex: null });
	}

	onAddUpload(upload) {
		upload.title = upload.name;
		upload.private = true;
		upload.image = upload.url;
		upload.work_order = this.props.workOrder;
		upload.project_id = this.props.project && this.props.project.id;
		upload.work_order_id = this.props.workOrder && this.props.workOrder.id;

		this.setState({ upload });
	}

	onSave() {
		this.setState({ loading: true });
		if (this.state.upload.id) {
			API.put(`/api/photo_reports/${this.state.upload.id}.json`, Object.assign(this.state.upload, { comment: this.state.comment }))
				.then((result) => {
					if (result.data.error) {
						return;
					}

					toastr.success("Uppladning uppdaterad");
					if (this.props.onChange && result.data.photo_report) {
						this.props.onChange(result.data.photo_report);
						this.onClose();
					}
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		} else {
			API.post(`/api/photo_reports.json`, Object.assign(this.state.upload, { comment: this.state.comment }))
				.then((result) => {
					if (result.data.error) {
						return;
					}

					toastr.success("Uppladning klar");
					if (this.props.onAddUpload && result.data.photo_report) {
						this.props.onAddUpload(result.data.photo_report);
						this.onClose();
					}
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		}
	}

	onRemove() {
		this.setState({ removing: true });
		API.delete(`/api/photo_reports/${this.state.upload.id}.json`)
			.then((result) => {
				if (result.data.error) {
					return;
				}

				if (result.data.photo_report) {
					toastr.success("Bild borttagen");
				}
				if (this.props.onRemove) {
					this.props.onRemove(this.state.upload);
					this.onClose();
				}
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	openEdit(upload) {
		this.setState({ open: true, upload, comment: upload.comment });
	}

	render() {
		return (
			<Wrapper>
				<Card.Section
					title={
						!this.props.hideTitle && this.props.workOrder.label
						// <Link
						// 	to={{
						// 		pathname: `/admin/work_orders/${this.props.workOrder.id}`,
						// 		state: {
						// 			project: this.state.form,
						// 			selectedTab: 2,
						// 		},
						// 	}}
						// >
						// 	{this.props.workOrder.label}
						// </Link>
					}
					sectioned
					actions={this.props.onAddUpload ? [{ content: "Ladda upp", onAction: this.onOpen.bind(this), loading: this.state.loading }] : null}
				>
					<ImageThumbnailsFlexWrapper>
						{this.props.uploads &&
							this.props.uploads.map((upload, index) => {
								return (
									<ImageThumbnail
										onClick={this.openImageInCarousel.bind(this, index)}
										key={upload.url || index}
										upload={upload}
										onEdit={this.props.onChange ? this.openEdit.bind(this, upload) : null}
									/>
								);
							})}
						{(!this.props.uploads || !this.props.uploads.length) && <p>Inga bilder..</p>}
					</ImageThumbnailsFlexWrapper>
					<Modal
						title={this.state.upload && this.state.upload.id ? `Redigera ${this.state.upload.id}` : "Ladda upp ny bild"}
						open={this.state.open}
						onClose={this.onClose.bind(this)}
						primaryAction={{
							content: "Spara",
							onAction: this.onSave.bind(this),
							loading: this.state.loading,
							disabled: !this.state.upload,
						}}
						secondaryActions={[
							{
								content: "Avbryt",
								onAction: this.onClose.bind(this),
							},
						].concat(
							this.state.upload && this.state.upload.id
								? [
										{
											content: "Ta bort",
											onAction: this.onRemove.bind(this),
											loading: this.state.removing,
											destructive: true,
										},
								  ]
								: []
						)}
					>
						<Modal.Section>
							<FormLayout>
								<Upload
									hidden={false}
									files={this.state.upload ? [this.state.upload] : []}
									width="100%"
									// height={200}
									open={this.state.openUpload}
									onUpload={this.onAddUpload.bind(this)}
									onClose={() => {
										this.setState({ openUpload: false });
									}}
								/>

								{/* {(!this.state.upload || !this.state.upload.id) && <Button onClick={this.openUpload.bind(this)}>Välj bild</Button>} */}
								<TextField
									label="Kommentar"
									onChange={(comment) => {
										this.setState({ comment });
									}}
									value={this.state.comment}
								/>
							</FormLayout>
						</Modal.Section>
					</Modal>
					<ImageCarousel
						title={`${this.props.workOrder.label} - Bilder`}
						images={this.props.uploads}
						open={this.state.carouselIsOpen}
						index={this.state.carouselIndex}
						onClose={this.closeCarousel.bind(this)}
					/>
				</Card.Section>
			</Wrapper>
		);
	}
}

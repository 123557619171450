import { css } from "styled-components";

export const LoadingCss = css`
	@keyframes pulse {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	}
	animation: pulse 3s infinite;
`;

import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const ImageThumbnailsFlexWrapper = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	/* justify-content: space-between; */
	margin: 1rem 0;
`;

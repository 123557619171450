import moment from "moment";
import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../Colors";

class QuoteHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getStatus(s) {
		switch (s.toLowerCase()) {
			case "sent":
				return { label: "Skickad", color: Colors.blue };
			case "accepted":
				return { label: "Accepterad", color: Colors.green };
			case "declined":
				return { label: "Nekad", color: Colors.red };
			case "draft":
				return { label: "Utkast", color: Colors.grey };
			case "unlocked":
				return { label: "Upplåst", color: Colors.yellow };
			default:
				return {};
		}
	}

	render() {
		const { quote } = this.props;
		return (
			<div>
				<UL>
					{quote &&
						quote.logs &&
						quote.logs.map(({ action, created_at, revision, user, id } = {}, index) => {
							return (
								<LI key={id || index}>
									<Dot color={this.getStatus(action).color} />
									<div>
										<FirstRow>
											<b>{this.getStatus(action).label}</b>
											{user && (
												<React.Fragment>
													<span>by</span>
													<p>{user.name}</p>
												</React.Fragment>
											)}
										</FirstRow>
										<p>revision: {revision}</p>
										<Date>{moment(created_at).format("YYYY-MM-DD hh:mm")}</Date>
									</div>
								</LI>
							);
						})}
					{(!quote || !quote.logs || !quote.logs.length) && <p>Ingen historik</p>}
				</UL>
			</div>
		);
	}
}
export default QuoteHistory;

const LI = styled.li`
	position: relative;
	margin: 1rem 0;
	display: flex;
	gap: 1rem;
`;

const UL = styled.ul`
	list-style: none;
	padding: 0;
`;

const FirstRow = styled.div`
	display: flex;
	gap: 0.5ch;
`;

const Date = styled.p`
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.75;
	font-size: 0.9em;
`;

const DotSvg = styled.svg`
	width: 10px;
	height: 20px;
`;

const Dot = ({ color }) => {
	return (
		<DotSvg viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
			<circle cx="10" cy="10" r="10" />
		</DotSvg>
	);
};

import styled from "styled-components";

const CardsInPageWrapper = styled.div<{ margin?: string | number | boolean }>`
	.Polaris-Card {
		background-color: var(--secondary);

		.Polaris-TextField__Backdrop {
			--p-color-bg: var(--primary);
		}
	}

	h3.Polaris-Text--root.Polaris-Text--headingMd.Polaris-Text--bold {
		margin-block: 1rem;

		& + p:first-of-type {
			margin-top: -0.5rem;
		}
		& + p:last-of-type {
			margin-bottom: 2rem;
		}
	}

	margin-top: ${({ margin }) => {
		return typeof margin === "string" || typeof margin === "number" ? margin : margin !== undefined ? "var(--p-space-4)" : "";
	}};
	margin-bottom: var(--p-space-4);
`;
export default CardsInPageWrapper;

import React, { Component } from "react";
import styled from "styled-components";

/**
 * @param {Array} [details] - Array of objects with label and value [{label: "Namn", value: "Robin"}
 * @returns JSX
 */
class InfoView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { details } = this.props || {};

		return details && !!details.length ? (
			<InfoViewWrapper className={`info-view ${this.props.className || ""}`} style={this.props.style}>
				<div className="info-view__labels">
					{details.map(({ label, value }, key) => (
						<span key={key} className="info-view__row__label">
							{label}:
						</span>
					))}
				</div>
				<div className="info-view__values">
					{details.map(({ label, value, type, href }, key) => {
						if (href) {
							return (
								<a href={href} target="_blank" key={key} className="info-view__row__value" rel="noreferrer">
									{value}
								</a>
							);
						}
						if (type === "email") {
							return (
								<a href={`mailto:${value}`} target="_blank" key={key} className="info-view__row__value" rel="noreferrer">
									{value}
								</a>
							);
						}
						if (type === "phone") {
							return (
								<a href={`tel:${value}`} target="_blank" key={key} className="info-view__row__value" rel="noreferrer">
									{value}
								</a>
							);
						}
						if (type === "orgnr") {
							return (
								<a
									href={`https://www.allabolag.se/${value.replace("-", "")}`}
									target="_blank"
									key={key}
									className="info-view__row__value"
									rel="noreferrer"
								>
									{value}
								</a>
							);
						}
						return (
							<span key={key} className="info-view__row__value">
								{typeof value !== "number" && !value ? "-" : value}
							</span>
						);
					})}
				</div>
			</InfoViewWrapper>
		) : null;
	}
}
export default InfoView;
export const InfoViewWrapper = styled.div.attrs({ className: "info-view" })`
	display: flex;
	flex-direction: row;
	gap: 2rem;

	.info-view__labels {
		color: var(--color-primary);
		text-transform: capitalize;
		min-width: 125px;
	}
	.info-view__labels,
	.info-view__values {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}
	.info-view__values {
		color: var(--color-primary);
		text-decoration: none;
	}
`;

import { Component } from "react";
import { Avatar, Heading, Stack, TextField, Button, DropZone, Caption } from "@shopify/polaris";
import { store } from "../store";
import TimeLineEvent from "./TimeLineEvent";
import { toastr } from "./toastr";
import API from "../API";

class TimeLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			saving: false,
			comments: [],
			form: { comment: "", files: [], id: props.object.id, resource: props.resource },
			openFileDialog: false,
			// eslint-disable-next-line react/no-unused-state
			resource: props.resource,
			handle: props.handle,
		};
	}

	componentDidMount() {
		this.fetchItems();
		console.debug(this.state.loading);
	}

	addFile(file, event) {
		var data = event.target.result;
		var files = this.state.form.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.form.files = files;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/comments/" + this.state.handle + ".json", this.state.form, {
			params: {},
		})
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					return;
				}
				toastr.success("Kommentar sparad");
				this.state.form.files = [];
				this.state.form.comment = "";
				this.state.comments.push(result.data.comment);
				this.setState({ form: this.state.form, comments: this.state.comments });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	fetchItems() {
		this.setState({ loading: true });
		API.get("/api/comments/" + this.state.handle + ".json", { params: {} })
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					return;
				}
				this.setState({ comments: result.data.comments });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	onRemove(comment) {
		var comments = [];
		for (let i = 0; i < this.state.comments.length; i++) {
			if (this.state.comments[i].id != comment.id) {
				comments.push(this.state.comments[i]);
			}
		}
		this.setState({ comments });
	}

	editItem(event) {
		if (event.opportunity) {
			this.props.onEditOpportunity(event);
		} else if (event.task) {
			this.props.onEditTask(event);
		} else if (event.task_complete) {
			this.props.onEditTask(event.orig_task);
		}
	}

	render() {
		var submitDisabled = !(this.state.form.comment || this.state.form.files.length);
		const commentControls = (
			<Stack>
				<Button
					onClick={this.saveForm.bind(this)}
					size="slim"
					primary
					loading={this.state.saving}
					disabled={store.getState().user.read_only ? true : submitDisabled}
				>
					Skicka
				</Button>
			</Stack>
		);

		var events = this.state.comments.slice();

		for (let i = 0; i < this.props.opportunities.length; i++) {
			this.props.opportunities[i].other = true;
			this.props.opportunities[i].opportunity = true;
			events.push(this.props.opportunities[i]);
		}

		for (let i = 0; i < this.props.tasks.length; i++) {
			this.props.tasks[i].other = true;
			this.props.tasks[i].task = true;
			events.push(this.props.tasks[i]);

			if (this.props.tasks[i].complete_at) {
				events.push({
					id: this.props.tasks[i].id,
					other: true,
					task_complete: true,
					created_at: this.props.tasks[i].complete_at,
					comment: this.props.tasks[i].comment,
					orig_task: this.props.tasks[i],
				});
			}
		}

		events.sort((a, b) => (a.id < b.id ? 1 : -1));

		return (
			<div className="timeline">
				<div style={{ width: 50, height: 50, display: "none" }}>
					<DropZone
						openFileDialog={this.state.openFileDialog}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
						}}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (let i = 0; i < acceptedFiles.length; i++) {
								var reader = new FileReader();
								reader.onload = this.addFile.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
						}}
					>
						<DropZone.FileUpload actionTitle="Välj fil" actionHint="Eller dra och släpp en fil" />
					</DropZone>
				</div>
				<div className="timeline-header">
					<Heading>{this.props.heading || "Anslagstavla"}</Heading>
					{this.props.infotext ? <small>{this.props.infotext}</small> : null}
				</div>
				<div className="timeline-rows">
					<div className="timeline-new">
						<Stack distribution="fill">
							<div style={{ width: 40 }}>
								<Avatar />
							</div>
							<div>
								<TextField
									placeholder="Skriv ett meddelande här..."
									value={this.state.form.comment}
									onChange={(value) => {
										this.state.form.comment = value;
										this.setState({ form: this.state.form });
									}}
									suffix={commentControls}
									multiline
									disabled={!!store.getState().user.read_only}
								/>
								{this.state.form.files.map((file, key) => (
									<div key={file.id || key}>
										<Stack>
											<span>{file.name}</span> <Caption>{(file.size / 1048576).toFixed(2)} MB</Caption>
										</Stack>
									</div>
								))}
							</div>
						</Stack>
					</div>
					{events.map((event, key) => (
						<TimeLineEvent onRemove={this.onRemove.bind(this, event)} onEdit={this.editItem.bind(this, event)} event={event} key={event.id} />
					))}
				</div>
			</div>
		);
	}
}
export default TimeLine;

import Badge from "src/js/components/Badge";

const getInvoicedStatusColumn = () => {
	return {
		handle: "INVOICED",
		label: "Fakturerad",
		sortable: true,
		type: "text",
		render: (item) => {
			if (item.is_invoiced) {
				return <Badge status="success">Fakturerad</Badge>;
			}

			if (item.is_not_invoiced_and_should_be_invoiced) {
				return <Badge status="warning">Ej fakturerad</Badge>;
			}

			return <Badge>Ska EJ faktureras</Badge>;
		},
	};
};

export default getInvoicedStatusColumn;
